import { EMPTY_STRING } from 'constants/common';

export const createSelectItems = <T = string>(items: Record<string, string>) =>
  Object.entries(items).map(
    ([key, value]) =>
      ({
        label: value,
        value: key,
      }) as { label: string; value: T },
  );

export const formatNumber = (num: number): string =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const parseFormattedNumber = (str: string): number => {
  if (str === EMPTY_STRING) {
    return 0;
  }
  return parseInt(str.replace(/,/g, ''), 10);
};
