import dayjs from 'dayjs';

export class SingleDate {
  private _date: Date;

  constructor({ date, time }: { date: Date; time?: string }) {
    this._date = new Date(date);
    if (time) {
      this._date.setHours(Number(time.split(':')[0]));
      this._date.setMinutes(Number(time.split(':')[1]));
    }
  }

  get date(): Date {
    return this._date;
  }

  get dayjs(): dayjs.Dayjs {
    return dayjs(this._date);
  }

  get time(): string {
    return this.dayjs.format('HH:mm');
  }
}
