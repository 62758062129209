export default {
  title: {
    election: '새로운 선거를 만드시겠어요?',
    survey: '새로운 설문을 만드시겠어요?',
  },
  button: {
    add: '만들기',
  },
  card: {
    free: {
      title: {
        election: '테스트 선거 만들기',
        survey: '테스트 설문 만들기',
      },
      description: {
        election: '30명 이하 인원 대상으로 테스트 선거를 만들 수 있어요.',
        survey: '30명 이하 인원 대상으로 테스트 설문을 만들 수 있어요.',
      },
    },
    charged: {
      title: '유료 플랜으로 만들기',
    },
    pay: {
      description: {
        election:
          '이용 중인 유료 플랜이 없어요. 30명 이상 인원으로 선거 생성이 필요하면 플랜을 결제해주세요.',
        survey:
          '이용 중인 유료 플랜이 없어요. 30명 이상 인원으로 설문 생성이 필요하면 플랜을 결제해주세요.',
      },
      button: '플랜 결제하기',
    },
  },
  label: {
    unlimited: '무제한',
  },
};
