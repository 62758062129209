export default {
  title: {
    election: '선거 기간을 변경해주세요',
    survey: '설문 기간을 변경해주세요',
  },
  description: {
    election:
      '선거 기간이 과거로 설정되어 있어요. 현재 시각으로 수정 후 선거 만들기를 완료하시겠어요?',
    survey:
      '설문 기간이 과거로 설정되어 있어요. 현재 시각으로 수정 후 설문 만들기를 완료하시겠어요?',
  },
  actions: {
    cancel: '취소',
    submit: '변경 후 만들기',
  },
};
