import dayjs from 'libs/dayjs';
import { difference, every, includes, remove, union } from 'lodash-es';
import {
  CandidateElection,
  ElectionCategory,
  IssueElection,
  VoterAuthMethodType,
} from '@pickme/core';
import { Checkbox, Text, Tooltip, colors } from '@pickme/design-system';

import PollBadge from 'components/features/poll-v2/Badge';

import { ElectionTypeCase } from 'types/application-v2';

import { useIsEllipsis } from 'hooks/useIsEllipsis';

import { CandidateElectionResponse } from 'models/poll-result-v2/response/candidate-election';
import { IssueElectionResponse } from 'models/poll-result-v2/response/issue-election';

import style from '../index.css';

type Props = {
  responses: (CandidateElectionResponse | IssueElectionResponse)[];
  poll: CandidateElection | IssueElection;
  checkedIds: string[];
  setCheckedIds: (value: string[]) => void;
};

function ElectionResponseTable({ responses, poll, checkedIds, setCheckedIds }: Props) {
  const responseIds = responses.map((response) => response.id);
  const areAllIdsChecked = () => every(responseIds, (id) => includes(checkedIds, id));

  const isAnonymousPoll = poll.isAnonymousVoting;
  const isVoterBookPoll = poll.voterAuthMethod.type === VoterAuthMethodType.VoterBook;

  const checkboxCellWidth = document.getElementById('header-checkbox')?.offsetWidth || 0;
  const nameCellWidth = document.getElementById('header-name')?.offsetWidth || 0;
  const emailCellWidth = document.getElementById('header-email')?.offsetWidth || 0;

  const fixedCellStyles = [
    { left: 0 },
    { left: checkboxCellWidth },
    { left: checkboxCellWidth + nameCellWidth },
    {
      left: checkboxCellWidth + nameCellWidth + emailCellWidth,
      borderRight: `3px solid ${colors['blue-gray-200']}`,
    },
  ];

  return (
    <div className={style.container}>
      <table className={style.table}>
        <thead>
          <tr>
            <th className={style.stickyCell} style={fixedCellStyles[0]} id='header-checkbox'>
              <div className={style.checkboxCell}>
                <Checkbox
                  checked={areAllIdsChecked()}
                  onChange={(event) => {
                    if (event.target.checked) {
                      const addedIds = union(checkedIds, responseIds);
                      setCheckedIds(addedIds);
                    } else {
                      const removedIds = difference(checkedIds, responseIds);
                      setCheckedIds(removedIds);
                    }
                  }}
                  className={style.checkbox}
                />
              </div>
            </th>

            {!isAnonymousPoll && (
              <th className={style.stickyCell} style={fixedCellStyles[1]} id='header-name'>
                <Text size={14} fontWeight={500} className={style.dateCell}>
                  이름
                </Text>
              </th>
            )}

            {!isAnonymousPoll && (
              <th className={style.stickyCell} style={fixedCellStyles[2]} id='header-email'>
                <Text size={14} fontWeight={500} className={style.dateCell}>
                  이메일
                </Text>
              </th>
            )}

            {!isAnonymousPoll && isVoterBookPoll && (
              <th className={style.stickyCell} style={fixedCellStyles[3]} id='header-phone'>
                <Text size={14} fontWeight={500} className={style.dateCell}>
                  휴대폰번호
                </Text>
              </th>
            )}

            <th>
              <Text size={14} fontWeight={500} className={style.dateCell}>
                제출 일시
              </Text>
            </th>

            {poll.subElections.map((election, index) => {
              const badgeType = ELECTION_BADGE_TYPES[election.category];
              return (
                <th key={`response table header ${index}`}>
                  <div className={style.headerCell}>
                    {badgeType && <PollBadge type={badgeType} number={index + 1} />}
                    <Text size={14} fontWeight={500} className={style.headerText}>
                      {election.title}
                    </Text>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {responses.map((response) => (
            <tr key={`response table header ${response.id}`}>
              <td className={style.stickyCell} style={fixedCellStyles[0]}>
                <div className={style.checkboxCell}>
                  <Checkbox
                    checked={checkedIds.includes(response.id)}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setCheckedIds([...checkedIds, response.id]);
                      } else {
                        const removedIds = [...checkedIds];
                        remove(removedIds, (id) => id === response.id);
                        setCheckedIds(removedIds);
                      }
                    }}
                    className={style.checkbox}
                  />
                </div>
              </td>

              {!isAnonymousPoll && (
                <td className={style.stickyCell} style={fixedCellStyles[1]}>
                  <Text size={14} fontWeight={500} color='gray-800' className={style.dateCell}>
                    {response.name}
                  </Text>
                </td>
              )}

              {!isAnonymousPoll && (
                <td className={style.stickyCell} style={fixedCellStyles[2]}>
                  <Text
                    size={14}
                    fontWeight={500}
                    color={response.email ? 'gray-800' : 'gray-200'}
                    className={style.dateCell}
                  >
                    {response.email || '없음'}
                  </Text>
                </td>
              )}

              {!isAnonymousPoll && isVoterBookPoll && (
                <td className={style.stickyCell} style={fixedCellStyles[3]}>
                  <Text
                    size={14}
                    fontWeight={500}
                    color={response.phoneNumber ? 'gray-800' : 'gray-200'}
                    className={style.dateCell}
                  >
                    {response.phoneNumber || '없음'}
                  </Text>
                </td>
              )}

              <td>
                <Text size={14}>{dayjs(response.timestamp).format('YY.MM.DD (dd) HH:mm')}</Text>
              </td>

              {response.responses.map((resultResponse, resultIndex) => {
                const key = `response table body ${response.id} ${resultIndex}`;
                const isEllipsis = useIsEllipsis(key, []);

                if (!resultResponse) {
                  return <td key={key} aria-label='no response' />;
                }

                return (
                  <td key={key}>
                    <Tooltip
                      message={`${resultResponse.text}`}
                      placement='bottom'
                      className={style.tooltip}
                      disabled={!isEllipsis}
                    >
                      <div className={style.content}>
                        <Text id={key} size={14} className={style.bodyCellText}>
                          {resultResponse.text}
                        </Text>
                      </div>
                    </Tooltip>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ElectionResponseTable;

const ELECTION_BADGE_TYPES = {
  [ElectionCategory.선택투표]: ElectionTypeCase.선택투표,
  [ElectionCategory.찬반투표]: ElectionTypeCase.찬반투표,
  [ElectionCategory.점수투표]: ElectionTypeCase.점수투표,
  [ElectionCategory.순위투표]: ElectionTypeCase.순위투표,
};
