import { Input, Text } from '@pickme/ui';
import { FieldPath, useFormContext } from 'react-hook-form';

import FieldTitle from 'components/features/application/label/old_FieldTitle';

import { SubElectionFormBody } from 'types/application';

type Props = {
  title: string;
  tooltip?: string;
  unit: string;
  disabled?: boolean;
  fields: {
    title: string;
    min: number;
    max: number;
    fieldName: FieldPath<SubElectionFormBody>;
    validate: (value: any) => any;
  }[];
};

function KindValueInputField({ title, tooltip, unit, disabled, fields }: Props) {
  const { register } = useFormContext<SubElectionFormBody>();

  return (
    <div className='application-form__row application-form__row--justify'>
      <FieldTitle title={title} isRequired tooltip={tooltip} />
      <div className='election-form__dropdown-row'>
        {fields.map((field) => (
          <div className='election-form__dropdown-group'>
            <Text size='sm2'>{field.title}</Text>

            <div className='election-form__score-row'>
              <Input
                type='number'
                className='election-form__dropdown-button'
                disabled={disabled}
                {...register(field.fieldName, {
                  validate: (value) => field.validate(value),
                  valueAsNumber: true,
                })}
                min={field.min}
                max={field.max}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <Text size='sm1' color='gray-300'>
                {unit}
              </Text>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default KindValueInputField;
