export default {
  description: {
    default: '이 기능을 사용하려면 플랜을 업그레이드해 주세요',
    enterprise: '이 기능을 사용하려면 캠퍼스 플랜 혹은 엔터프라이즈 플랜으로 업그레이드해 주세요',
  },
  button: {
    default: '구독하기',
    enterprise: '문의하기',
  },
};
