/* eslint-disable jsx-a11y/control-has-associated-label */
import { OutlineButton, Radio, Text } from '@pickme/design-system';
import { useFormContext, useWatch } from 'react-hook-form';
import { t } from 'i18next';
import moment from 'moment-timezone';

import SendFieldRow from 'components/features/notification/fields/send/Row';
import EstimatedPointUsage from 'components/features/notification/EstimatedPointUsage';
import DdayBadge from 'components/features/poll/DdayBadge';

import { RecipientType, TargetField as TargetFieldType } from 'types/message';
import { Poll } from 'types/poll';

import AddIcon from 'resources/icons/notification/add.svg';
import CloseIcon from 'resources/icons/notification/close.svg';

import { container, radioGroup } from '../index.css';
import { pollItem, pollPeriod, pollsSection, button } from './index.css';

type Props = {
  polls: Poll[];
  isRecipientTypeDisabled: boolean;
  onClickAddPolls: () => void;
  pointPerVoter: number;
  hideEstimatedSubPoint?: boolean;
};

const PERIOD_FORMAT = 'YY.MM.DD(dd) HH:mm';

function TargetFields({
  polls,
  isRecipientTypeDisabled,
  onClickAddPolls,
  pointPerVoter,
  hideEstimatedSubPoint,
}: Props) {
  const methods = useFormContext<TargetFieldType>();
  const pollIds = useWatch({ control: methods.control, name: 'pollIds' });

  return (
    <div className={container}>
      <SendFieldRow
        title={t('admin:pages.notification.send.form.fields.target.title')}
        align='center'
      >
        <OutlineButton size='md' type='button' onClick={() => onClickAddPolls()} icon={AddIcon}>
          추가하기
        </OutlineButton>
      </SendFieldRow>

      <div className={pollsSection}>
        {polls.map((poll) => {
          const { startDate } = poll.periods[0];
          const endDate = poll.endDate.date;

          return (
            <div className={pollItem} key={poll._id}>
              <div>
                <Text size={16} fontWeight={600} color='gray-800'>
                  {poll.name}
                </Text>

                <div className={pollPeriod}>
                  <DdayBadge startDate={startDate} endDate={endDate} />

                  <Text size={14} fontWeight={400} color='gray-500'>
                    {moment(startDate).tz('Asia/Seoul').format(PERIOD_FORMAT)} ~{' '}
                    {moment(endDate).tz('Asia/Seoul').format(PERIOD_FORMAT)}
                  </Text>
                </div>
              </div>

              <button
                type='button'
                className={button}
                onClick={() => {
                  const newPollIds = pollIds.filter((pollId) => pollId !== poll._id);
                  methods.setValue('pollIds', newPollIds);
                }}
              >
                <img src={CloseIcon} alt='delete' />
              </button>
            </div>
          );
        })}
      </div>

      <SendFieldRow
        title={t('admin:pages.notification.send.form.fields.recipientType.title')}
        align='center'
      >
        <div className={radioGroup}>
          <Radio
            {...methods.register('recipientType')}
            value={RecipientType.All}
            disabled={isRecipientTypeDisabled}
          >
            {t('admin:pages.notification.send.form.fields.recipientType.radios.0')}
          </Radio>
          <Radio
            {...methods.register('recipientType')}
            value={RecipientType.NonVotedVoters}
            disabled={isRecipientTypeDisabled}
          >
            미참여자
          </Radio>
        </div>
      </SendFieldRow>

      {!hideEstimatedSubPoint && (
        <EstimatedPointUsage polls={polls} pointPerVoter={pointPerVoter} />
      )}
    </div>
  );
}

export default TargetFields;
