export default {
  title: {
    pending: '예약 내역',
    detail: '상세 내역',
  },
  buttons: {
    submit: '예약취소',
  },

  viewer: {
    pollName: '투표 제목',
    to: '전송 대상',
    all: '전체',
    'non-participant': '투표 미참여자',
    template: '템플릿',
  },

  sent: {
    organization: '스페이스',

    date: '전송 일시',
    try: '전송 시도 건수',

    table: {
      header: {
        talk: '알림톡',
        lms: 'LMS 전환 건수',
        fail: '전송 실패 건수',
      },
    },

    point: '사용 포인트',
  },

  reserve: {
    date: '전송 예정 일시',
    point: '예상 포인트',
  },
};
