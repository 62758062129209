export default {
  useApplyOrganization: {
    onSuccess: {
      modal: {
        title: '합류 요청 완료',
        message: '합류 요청중이에요. 스페이스 소유자의 검토 후 승인 완료시 참여 가능해요',
        buttonText: '메인 페이지로 이동',
      },
    },
    onError: {
      toast: '잘못된 합류 링크입니다.',
    },
  },

  useCreateOrganization: {
    onSuccess: {
      toast: '{{organization}} 스페이스를 생성했습니다.',
    },
  },

  useReplaceOrganizationName: {
    onSuccess: {
      toast: '스페이스 이름이 성공적으로 변경되었습니다.',
    },
  },

  useReplaceOrganizationLogo: {
    onSuccess: {
      toast: '로고 변경에 성공했습니다.',
    },
  },

  useReplaceOrganizationAlias: {
    modal: {
      title: '이미 사용중인 URL',
      message: '이미 사용중인 URL입니다. 다른 URL을 입력해 주세요.',
    },
    onSuccess: {
      toast: '투표 참여 URL 변경에 성공했습니다',
    },
  },
};
