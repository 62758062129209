import { Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { t } from 'i18next';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  onLeave: () => void;
};

function LeaveModal({ isVisible, onClose, onLeave }: Props) {
  return (
    <Modal isVisible={isVisible} onClose={onClose} size='md'>
      <ModalHeader
        title={t('admin:components.features.application.modal.leave.title')}
        onClose={onClose}
      />

      <ModalBody>
        <Text type='b3' fontWeight={400} color='gray-400'>
          {t('admin:components.features.application.modal.leave.description')}
        </Text>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t('admin:components.features.application.modal.leave.actions.cancel'),
            type: 'button',
            variant: 'gray',
            onClick: onClose,
          },
          {
            text: t('admin:components.features.application.modal.leave.actions.leave'),
            type: 'button',
            variant: 'error',
            onClick: () => onLeave(),
          },
        ]}
      />
    </Modal>
  );
}

export default LeaveModal;
