import integration from './integration';
import myPage from './my-page';
import notification from './notification';
import nav from './nav';
import poll from './poll';
import payment from './payment';

export default {
  integration,
  'my-page': myPage,
  notification,
  nav,
  poll,
  payment,
};
