import * as yup from 'yup';

import {
  ELECTION_TITLE_MAX_LENGTH,
  ELECTION_START_BUTTON_MAX_LENGTH,
  ELECTION_DESCRIPTION_MAX_LENGTH,
} from 'constants/application-v2';

import { CoverMediaType } from 'types/application-v2';
import { PollKindV2 } from 'types/poll-v2';

const TITLE_REQUIRED_MESSAGE = {
  [PollKindV2.CandidateElection]: '선거명을 입력해주세요',
  [PollKindV2.IssueElection]: '투표명 입력해주세요',
  [PollKindV2.Survey]: '설문명을 입력해주세요',
};

const DESCRIPTION_REQUIRED_MESSAGE = {
  [PollKindV2.CandidateElection]: `선거 설명은 최대 ${ELECTION_DESCRIPTION_MAX_LENGTH}자까지 입력 가능합니다`,
  [PollKindV2.IssueElection]: `투표 설명은 최대 ${ELECTION_DESCRIPTION_MAX_LENGTH}자까지 입력 가능합니다`,
  [PollKindV2.Survey]: `설문 설명은 최대 ${ELECTION_DESCRIPTION_MAX_LENGTH}자까지 입력 가능합니다`,
};

export const getIntroFormSchema = (kind: PollKindV2) =>
  yup.object().shape({
    title: yup
      .string()
      .required(TITLE_REQUIRED_MESSAGE[kind])
      .max(ELECTION_TITLE_MAX_LENGTH, `최대 ${ELECTION_TITLE_MAX_LENGTH}자까지 입력 가능합니다`),
    buttonText: yup
      .string()
      .max(
        ELECTION_START_BUTTON_MAX_LENGTH,
        `시작 버튼 텍스트는 최대 ${ELECTION_START_BUTTON_MAX_LENGTH}자까지 입력 가능합니다`,
      ),
    cover: yup.object().shape({
      type: yup.mixed<CoverMediaType>().oneOf([CoverMediaType.Image, CoverMediaType.Video]),
      url: yup.string().url(),
    }),
    description: yup
      .string()
      .max(ELECTION_DESCRIPTION_MAX_LENGTH, DESCRIPTION_REQUIRED_MESSAGE[kind]),
  });
