import { AuthMethodType, CommonAuthMethodResponse } from 'types/auth';

export abstract class CommonAuthMethod {
  abstract _type: AuthMethodType;

  private _email: string;

  private _isPrimary: boolean;

  constructor(response: CommonAuthMethodResponse) {
    this._email = response.email;
    this._isPrimary = response.isRoot;
  }

  get email() {
    return this._email;
  }

  get isPrimary() {
    return this._isPrimary;
  }

  isCredential() {
    return this._type === AuthMethodType.Credentials;
  }

  isSocial() {
    return this._type === AuthMethodType.Social;
  }
}
