import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Navbar from 'components/common/layout/Main/Navbar';
import Sidebar from 'components/common/layout/Main/Sidebar';
import PollLayout from 'components/common/layout/Poll';

import { sidebarState } from 'states/layout';

import { container, main } from './index.css';

type Props = {
  topSidePadded?: boolean;
};

function MainLayout({ topSidePadded }: Props) {
  const { isVisible } = useRecoilValue(sidebarState);

  return (
    <>
      <meta name='viewport' content='width=1920, height=1080, user-scalable=no' />

      <Navbar />
      <div className={container}>
        <Sidebar />

        <main
          className={`${main({
            leftPadded: isVisible,
            topPadded: topSidePadded,
          })}`}
        >
          <Outlet />
        </main>

        <PollLayout />
      </div>
    </>
  );
}

export default MainLayout;
