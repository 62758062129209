/* eslint-disable no-param-reassign */
import { useEffect } from 'react';

// XXX: style.height = "XXX" 같은 패턴으로 값을 바로 넣는 로직은
// react의 선언적 패러다임과 거리가 있는 절차적 로직입니다.
// 따라서, state를 사용하여, 선언적으로 스타일을 수정하게 끔 변경되어야 합니다.

const useAutoSizeTextArea = (textAreaRef: HTMLTextAreaElement | null, paddingSize?: number) => {
  useEffect(() => {
    if (!textAreaRef) {
      return () => {};
    }

    const resize = (event: Event | null) => {
      if (event) {
        event.preventDefault();
      }
      // 정확한 scrollHeight을 얻기 위해 처리합니다.
      textAreaRef.style.height = '0px';
      const { scrollHeight } = textAreaRef;
      textAreaRef.style.height = `${scrollHeight - (paddingSize ? paddingSize * 2 : 0)}px`;
    };

    resize(null);

    textAreaRef.addEventListener('input', resize);

    return () => {
      textAreaRef.removeEventListener('input', resize);
    };
  }, [textAreaRef]);
};

export default useAutoSizeTextArea;
