export default {
  title: '상세 내역',
  number: '결제 번호',
  date: '거래 일시',
  method: '거래 방법',
  card: '결제 카드',
  format: 'YYYY년 M월 D일',
  tax: '과세 금액',
  vat: '부가세',
  amount: '결제 금액',
  increaseLimit: '인원 추가 결제',
  buttons: {
    print: '영수증 출력',
  },
};
