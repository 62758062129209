export default {
  title: {
    election: {
      apply: '선거 만들기 완료',
      edit: '선거 수정 완료',
    },
    survey: {
      apply: '설문 만들기 완료',
      edit: '설문 수정 완료',
    },
  },
  description: {
    election: {
      apply: '축하합니다! 선거 만들기를 완료했어요.\n상세 페이지에서 작성한 내용을 확인해주세요.',
      edit: '축하합니다! 선거 수정을 완료했어요.\n상세 페이지에서 작성한 내용을 확인해주세요',
    },
    survey: {
      apply: '축하합니다! 설문 만들기를 완료했어요.\n상세 페이지에서 작성한 내용을 확인해주세요.',
      edit: '축하합니다! 설문 수정을 완료했어요.\n상세 페이지에서 작성한 내용을 확인해주세요',
    },
  },
  actions: {
    close: '확인',
  },
};
