import moment from 'moment-timezone';
import { Button, Modal, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { chunk } from 'lodash-es';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

import { useGetPaymentRecord } from 'query-hooks/payment';

import { CARD_NAME } from 'constants/payment';

import './index.scss';

type Props = {
  isVisible: boolean;
  id: string;
  onClose: () => void;
};

function PaymentRecordModal({ isVisible, id, onClose }: Props) {
  const { data, isLoading } = useGetPaymentRecord(id);

  const items = [
    {
      title: t('admin:components.features.payment.modal.paymentRecord.number'),
      content: data?._id,
    },
    {
      title: t('admin:components.features.payment.modal.paymentRecord.date'),
      content: moment(data?.createdAt).tz('Asia/Seoul').format('YY/MM/DD HH:mm'),
    },
    {
      title: t('admin:components.features.payment.modal.paymentRecord.method'),
      content: data?.methodName || '',
    },
  ];

  return (
    <Modal className='incoming-detail-modal' size='lg' isVisible={isVisible} onClose={onClose}>
      <ModalHeader
        title={t('admin:components.features.payment.modal.paymentRecord.title')}
        onClose={onClose}
      />
      <ModalBody>
        <div className='incoming-detail-modal__section'>
          {items.map((item) => (
            <div className='incoming-detail-modal__row' key={item.title}>
              <div className='incoming-detail-modal__row__title'>
                <Text type='b3' fontWeight={400}>
                  {item.title}
                </Text>
              </div>

              <div className='incoming-detail-modal__row__content'>
                <Text type='b3' fontWeight={400}>
                  {item.content}
                </Text>
              </div>
            </div>
          ))}

          {data?.method === 'creditCard' && (
            <div className='incoming-detail-modal__row'>
              <div className='incoming-detail-modal__row__title'>
                <Text type='b3' fontWeight={400}>
                  {t('admin:components.features.payment.modal.paymentRecord.card')}
                </Text>
              </div>

              <div className='incoming-detail-modal__row__content'>
                <Text type='b3' fontWeight={400}>
                  {CARD_NAME[data?.acquirerCode || ''] || ''}{' '}
                  {chunk(data?.number, 4)
                    .map((arr) => arr.join(''))
                    .join(' ')}
                </Text>
              </div>
            </div>
          )}
        </div>

        <div className='payment-record-modal__summary'>
          <Text type='b3' fontWeight={400} color='gray-400'>
            {data?.product?.planTypeName} /{' '}
            {(data?.product?.type === 'increaseLimit' && data?.product?.number) ||
              (data?.product?.type === 'subscription' && data?.product?.voterLimit)}
            {t('admin:terms.unit.people')}
          </Text>
          {data?.product?.type === 'subscription' ? (
            <Text type='b3' fontWeight={400} color='gray-400'>
              {moment(data?.product.period.start)
                .tz('Asia/Seoul')
                .format(t('admin:components.features.payment.modal.paymentRecord.format'))}{' '}
              ~{' '}
              {moment(data?.product?.period.end)
                .tz('Asia/Seoul')
                .format(t('admin:components.features.payment.modal.paymentRecord.format'))}
            </Text>
          ) : (
            <Text type='b3' fontWeight={400} color='gray-400'>
              {t('admin:components.features.payment.modal.paymentRecord.increaseLimit')}
            </Text>
          )}
        </div>

        <hr />

        <div className='incoming-detail-modal__section'>
          <div className='incoming-detail-modal__row incoming-detail-modal__row--justify'>
            <div className='incoming-detail-modal__row__title'>
              <Text type='b3' fontWeight={400}>
                {t('admin:components.features.payment.modal.paymentRecord.tax')}
              </Text>
            </div>

            <div className='incoming-detail-modal__row__content'>
              <Text type='b3' fontWeight={400}>
                {(((data?.amount ?? 0) * 10) / 11).toLocaleString('ko-KR')}
                {t('admin:terms.unit.won')}
              </Text>
            </div>
          </div>

          <div className='incoming-detail-modal__row incoming-detail-modal__row--justify'>
            <div className='incoming-detail-modal__row__title'>
              <Text type='b3' fontWeight={400}>
                {t('admin:components.features.payment.modal.paymentRecord.vat')}
              </Text>
            </div>

            <div className='incoming-detail-modal__row__content'>
              <Text type='b3' fontWeight={400}>
                {((data?.amount ?? 0) / 11).toLocaleString('ko-KR')}
                {t('admin:terms.unit.won')}
              </Text>
            </div>
          </div>

          <div className='incoming-detail-modal__row incoming-detail-modal__row--justify'>
            <div className='incoming-detail-modal__row__title'>
              <Text type='b2' fontWeight={500}>
                {t('admin:components.features.payment.modal.paymentRecord.amount')}
              </Text>
            </div>

            <div className='incoming-detail-modal__row__content'>
              <Text type='b2' fontWeight={500}>
                {(data?.amount ?? 0).toLocaleString('ko-KR')}
                {t('admin:terms.unit.won')}
              </Text>
            </div>
          </div>
        </div>
      </ModalBody>

      {data?.receiptUrl && (
        <div className='pickme-modal__actions'>
          <Button
            width='100%'
            size='md'
            variant='primary'
            onClick={() => window.open(data?.receiptUrl)}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              t('admin:components.features.payment.modal.paymentRecord.buttons.print')
            )}
          </Button>
        </div>
      )}
    </Modal>
  );
}

export default PaymentRecordModal;
