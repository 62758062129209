export default {
  kind: {
    all: '전체',
    election: '투표',
    survey: '설문',
  },
  rate: {
    election: '득표율',
    survey: '득표율',
  },
  count: {
    election: '득표수',
    survey: '득표수',
  },
  abstention: {
    election: '기권',
    survey: '무응답',
  },
  pros: '찬성',
  cons: '반대',
  totalScore: '총점',
  allVoters: {
    election: '총원',
    survey: '총 응답자 수',
  },
  average: '평균',
  includingMultipleAnswers: '복수 응답 포함',
};
