import { useNavigate } from 'react-router-dom';
import { Button, Text } from '@pickme/design-system';

import AddIcon from 'resources/icons/dashboard/add.svg';

import { container, textContainer } from './index.css';

type Props = {
  type: 'sent' | 'reserved';
};

function NotificationEmptyStateView({ type }: Props) {
  const navigate = useNavigate();

  return (
    <div className={container}>
      <div className={textContainer}>
        <Text size={20} fontWeight={400} color='gray-800'>
          {type === 'sent' && '전송 이력이 없어요.'}
          {type === 'reserved' && '예약 내역이 없어요.'}
        </Text>

        <Text size={16} fontWeight={400} color='gray-700'>
          “메시지 전송하기” 버튼을 클릭해 메시지를 보내보세요.
        </Text>
      </div>

      <Button
        size='md'
        icon={AddIcon}
        onClick={() => {
          navigate('/service/notification/send', {
            unstable_viewTransition: true,
          });
        }}
      >
        메시지 전송하기
      </Button>
    </div>
  );
}

export default NotificationEmptyStateView;
