import { PlainButton, Text } from '@pickme/design-system';

import { badge, container, header, image, title } from './index.css';

function BoxButton({
  item,
  disabled,
  selected,
  onSelect,
}: {
  item: { icon: string; title: string; showBetaIcon?: boolean; content: string };
  disabled?: boolean;
  selected: boolean;
  onSelect: () => void;
}) {
  return (
    <PlainButton
      className={container({
        isActive: selected,
      })}
      disabled={disabled}
      onClick={() => onSelect()}
    >
      <img className={image} src={item.icon} alt={item.title?.toString()} />

      <div className={header}>
        <div className={title}>
          <Text size={18} fontWeight={500}>
            {item.title}
          </Text>

          {item.showBetaIcon && (
            <Text className={badge} size={12} fontWeight={400} color='gray-500'>
              Beta
            </Text>
          )}
        </div>

        <Text size={16} fontWeight={400} color='gray-700'>
          {item.content}
        </Text>
      </div>
    </PlainButton>
  );
}

export default BoxButton;
