export default {
  title: {
    service: '이용약관',
    privacy: '개인정보처리방침',
    marketing: '마케팅 메시지 수신 동의',
  },
  actions: {
    agree: '동의',
    cancel: '취소',
  },
};
