export default {
  download: {
    pdf: 'PDF 결과 다운로드',
    excel: 'Excel 결과 다운로드',
  },
  link: {
    label: '투표 참여자 링크',
    copy: {
      success: '성공적으로 투표 참여자 링크가 복사되었습니다.',
      fail: '링크 복사에 실패했습니다.',
    },
  },
  preview: {
    label: '미리보기',
  },
  more: {
    share: '공유하기',
    edit: '수정하기',
    remove: '삭제하기',
  },
};
