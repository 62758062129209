export default {
  buttons: {
    account: '계정 관리하기',
    feedback: '피드백 보내기',
  },
  labels: {
    subscribe: '구독하기',
    transferSpace: '스페이스 소유자 변경',
    account: '계정 관리하기',
  },
};
