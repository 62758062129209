import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

import { spaceState } from 'states/space';
import { toast } from 'states/toast';

function Invite() {
  const navigate = useNavigate();

  const alias = useParams<{ alias: string }>()?.alias ?? '';

  const setToast = useSetRecoilState(toast);
  const setActiveSpaceId = useSetRecoilState(spaceState.activeSpaceId);
  const setInvitationAlias = useSetRecoilState(spaceState.invitationAlias);

  useEffect(() => {
    if (alias) {
      setInvitationAlias(alias);
      setActiveSpaceId('');
    } else {
      setToast({
        isVisible: false,
        type: 'error',
        message: t('admin:pages.organization.invite.errorMessages.notPermitted.message'),
      });
    }

    navigate('/auth/signin', { replace: true });
  }, [alias]);

  return <Spinner />;
}

export default Invite;
