import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { FormProvider, useForm } from 'react-hook-form';
import { Input, PlainButton } from '@pickme/design-system';

import OTPVerifyForm from 'components/features/my-page/form/OTPVerifyForm';

import { useSendOTPBySMS } from 'query-hooks/otp';
import { useReplaceUserPhoneNumber } from 'query-hooks/user';

import { toast } from 'states/toast';

import { phoneNumberValidator } from 'functions/validator/voter';

import { field, fields } from '../../common.css';

type Props = {
  onClose: () => void;
  phoneNumber: string;
  verified: boolean;
};

function PhoneChangeForm({ onClose, phoneNumber, verified }: Props) {
  const setToast = useSetRecoilState(toast);

  const phoneForm = useForm<{ newPhoneNumber: string; otp: string }>({
    mode: 'onTouched',
    defaultValues: { newPhoneNumber: '' },
  });

  const {
    register,
    handleSubmit,
    watch,
    reset: resetForm,
    formState: { errors },
  } = phoneForm;

  const { mutate: sendOTP, isSuccess: isSuccessSendOTP, reset: resetOTP } = useSendOTPBySMS();

  const onSendOTP = () => {
    if (verified && watch('newPhoneNumber').replace(/-/gi, '') === phoneNumber.replace(/-/gi, '')) {
      setToast({
        isVisible: true,
        type: 'error',
        message: '이미 등록된 휴대폰 번호입니다.',
      });
      return;
    }

    sendOTP(watch('newPhoneNumber'));
  };
  const { mutate: replacePhone, isLoading } = useReplaceUserPhoneNumber();

  useEffect(() => {
    if (isSuccessSendOTP) {
      setToast({
        isVisible: true,
        type: 'success',
        message: '인증번호를 발송했습니다.',
      });
    }
  }, [isSuccessSendOTP]);

  const onSubmit = () => {
    replacePhone(
      {
        phoneNumber: watch('newPhoneNumber'),
        otp: watch('otp'),
        alreadyVerified: verified,
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  useEffect(() => {
    resetForm();
    resetOTP();
  }, []);

  useEffect(() => {
    if (!verified && phoneNumber) {
      phoneForm.reset({
        newPhoneNumber: phoneNumber,
      });
    }
  }, [phoneNumber, verified]);

  return (
    <FormProvider {...phoneForm}>
      <form onSubmit={handleSubmit(isSuccessSendOTP ? onSubmit : onSendOTP)} className={fields}>
        <div className={field.container}>
          <Input
            {...register('newPhoneNumber', {
              validate: (value: string) => {
                if (value && !phoneNumberValidator(value)) {
                  return '올바른 휴대폰 번호를 입력해 주세요';
                }

                if (verified && value?.replace(/-/gi, '') === phoneNumber?.replace(/-/gi, '')) {
                  return '이미 등록된 휴대폰 번호입니다.';
                }

                return true;
              },
            })}
            placeholder='인증할 휴대폰 번호를 입력해 주세요'
            size='lg'
            width={322}
            isValid={!errors.newPhoneNumber || phoneForm.formState.isValid}
            errorMessage={phoneForm.formState.errors.newPhoneNumber?.message}
            maxLength={watch('newPhoneNumber') && !watch('newPhoneNumber')?.includes('-') ? 11 : 13}
            disabled={isSuccessSendOTP}
          />

          {!isSuccessSendOTP && (
            <>
              <PlainButton
                type='button'
                onClick={onSendOTP}
                disabled={
                  !watch('newPhoneNumber') || !phoneForm.formState.isValid || isSuccessSendOTP
                }
                className={field.button({ submit: true })}
              >
                인증번호발송
              </PlainButton>

              <PlainButton
                type='button'
                onClick={onClose}
                className={field.button({ hoverEffect: true })}
              >
                취소
              </PlainButton>
            </>
          )}
        </div>

        {isSuccessSendOTP && (
          <OTPVerifyForm
            resetOTP={resetOTP}
            onSendOTP={onSendOTP}
            kind='phone'
            isLoading={isLoading}
            onClose={onClose}
          />
        )}
      </form>
    </FormProvider>
  );
}

export default PhoneChangeForm;
