import { useEffect } from 'react';
import { Input, PlainButton } from '@pickme/design-system';
import { FormProvider, useForm } from 'react-hook-form';

import OTPVerifyForm from 'components/features/my-page/form/OTPVerifyForm';

import { useSendOTPByEmail } from 'query-hooks/otp';
import { useReplaceUserEmail } from 'query-hooks/user';

import { useSetRecoilState } from 'recoil';

import { toast } from 'states/toast';

import { useEmailDuplicated } from 'hooks/useEmailDuplicated';
import { emailValidator } from 'functions/validator/auth';

import { EmailChangeFormBody } from 'types/auth';
import { field, fields } from '../../common.css';

type Props = { onClose: () => void };

function EmailChangeForm({ onClose }: Props) {
  const setToast = useSetRecoilState(toast);

  const emailForm = useForm<EmailChangeFormBody>({
    mode: 'onTouched',
    defaultValues: { password: '', newEmail: '' },
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset: resetForm,
  } = emailForm;

  const { isEmailDuplicated, isCheckedEmail } = useEmailDuplicated(watch('newEmail'));
  const {
    mutate: sendOTP,
    isSuccess: isSuccessSendOTP,
    reset: resetOTP,
    isLoading: isLoadingSendOTP,
  } = useSendOTPByEmail();

  const onSendOTP = () => sendOTP(watch('newEmail'));

  useEffect(() => {
    if (isSuccessSendOTP) {
      setToast({
        isVisible: true,
        type: 'success',
        message: '인증 메일을 발송했습니다.',
      });
    }
  }, [isSuccessSendOTP]);

  const { mutate: replaceEmail, isLoading } = useReplaceUserEmail();

  const onSubmit = () => {
    replaceEmail(
      { email: watch('newEmail'), otp: watch('otp'), type: 'replace' },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  useEffect(() => {
    resetForm();
    resetOTP();
  }, []);

  return (
    <FormProvider {...emailForm}>
      <form
        onSubmit={handleSubmit(isSuccessSendOTP ? onSubmit : onSendOTP)}
        autoComplete='off'
        className={fields}
      >
        <div className={field.container}>
          <Input
            {...register('newEmail', {
              setValueAs: (value) => value?.trim().toLowerCase(),
              validate: (value: string) => {
                if (isEmailDuplicated) {
                  return '이미 등록된 이메일입니다.';
                }

                const isValidEmail = emailValidator(value);

                if (!isValidEmail) {
                  return '올바른 이메일을 입력해주세요';
                }

                return true;
              },
            })}
            placeholder='변경할 이메일을 입력해 주세요'
            width={322}
            size='lg'
            isValid={!errors.newEmail && !isEmailDuplicated}
            errorMessage={errors.newEmail?.message}
            disabled={isSuccessSendOTP}
            autoComplete='new-email'
          />

          {!isSuccessSendOTP && (
            <>
              <PlainButton
                className={field.button({ submit: true })}
                type='button'
                onClick={onSendOTP}
                disabled={
                  !emailValidator(watch('newEmail')) ||
                  !(isCheckedEmail && !isEmailDuplicated) ||
                  isLoadingSendOTP
                }
              >
                인증메일발송
              </PlainButton>

              <PlainButton className={field.button()} type='button' onClick={onClose}>
                취소
              </PlainButton>
            </>
          )}
        </div>

        {isSuccessSendOTP && (
          <OTPVerifyForm
            resetOTP={resetOTP}
            onSendOTP={onSendOTP}
            onClose={onClose}
            kind='email'
            isLoading={isLoading}
          />
        )}
      </form>
    </FormProvider>
  );
}

export default EmailChangeForm;
