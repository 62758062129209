import { t } from 'i18next';

import KakaoIcon from 'resources/icons/integration/kakao.svg';
import EmailIcon from 'resources/icons/integration/email.svg';
import SlackIcon from 'resources/icons/integration/slack.svg';

export const INTEGRATION_ITEMS = {
  kakao: {
    icon: KakaoIcon,
    title: t('admin:constants.integration.items.kakao.title'),
    description: [
      t('admin:constants.integration.items.kakao.description.0'),
      t('admin:constants.integration.items.kakao.description.1'),
    ],
  },
  email: {
    icon: EmailIcon,
    title: t('admin:constants.integration.items.email.title'),
    description: [
      t('admin:constants.integration.items.email.description.0'),
      t('admin:constants.integration.items.email.description.1'),
    ],
  },
  slack: {
    icon: SlackIcon,
    title: t('admin:constants.integration.items.slack.title'),
    description: [
      t('admin:constants.integration.items.slack.description.0'),
      t('admin:constants.integration.items.slack.description.1'),
    ],
  },
};

export const KAKAO_TABS = [
  {
    text: t('admin:constants.integration.kakao.tabs.0'),
    url: '/settings/integration/kakao/channel',
  },
];
