import { useFieldArray, useFormContext } from 'react-hook-form';
import moment from 'moment-timezone';
import { IconButton, Input, Text } from '@pickme/design-system';

import { usePollPeriods } from 'hooks/poll/usePollData';

import { checkNotOverlapped } from 'functions/validator/application';

import { BasicFormBody } from 'types/application';

import PlusIcon from 'resources/icons/application/circle-plus.svg';
import MinusIcon from 'resources/icons/application/circle-minus.svg';

import { buttonArea, container, dateArea, inputContainer, row, tilde } from './index.css';

type Props = {
  isEdit?: boolean;
  max: string;
};

function PeriodsField({ isEdit, max }: Props) {
  const initialValues = usePollPeriods();

  const methods = useFormContext<BasicFormBody>();
  const { append, remove, fields } = useFieldArray<BasicFormBody>({
    control: methods.control,
    name: 'periods',
    rules: {
      validate: {
        overlapped: (periods) => checkNotOverlapped(periods),
      },
    },
  });

  const periodsErrorMessage =
    methods.formState.errors.periods?.message || methods.formState.errors.periods?.root?.message;

  return (
    <div className={container}>
      {fields.map((field, index) => {
        const hasDatePassed = {
          startDate:
            initialValues?.[index] && moment(initialValues[index].startDate).isBefore(moment()),
          endDate:
            initialValues?.[index] && moment(initialValues[index].endDate).isBefore(moment()),
        };

        const isStartDateDisabled = isEdit && hasDatePassed.startDate;
        const isEndDateDisabled = isEdit && hasDatePassed.endDate;

        return (
          <div className={row} key={field.id}>
            <div className={dateArea}>
              <div className={inputContainer}>
                <Input
                  type='datetime-local'
                  size='lg'
                  width='100'
                  {...methods.register(`periods.${index}.startDate`, {
                    validate: (value) => {
                      if (isStartDateDisabled) {
                        return true;
                      }

                      if (!value) {
                        return '시작일을 입력해주세요.';
                      }

                      if (moment(value).isBefore(moment())) {
                        return '시작일은 현재 시간 이후여야 합니다.';
                      }

                      const endDate = methods.getValues(`periods.${index}.endDate`);
                      if (moment(value).isSameOrAfter(moment(endDate))) {
                        return '종료시간은 현재 시작시간 이후여야 합니다.';
                      }

                      if (max && moment(value).isAfter(moment(max))) {
                        return `현재 플랜으로 ${moment(max)
                          .tz('Asia/Seoul')
                          .format('YYYY년 MM월 DD일')}까지 설정할 수 있습니다.`;
                      }

                      return true;
                    },
                  })}
                  onChange={(event) => {
                    const startDate = event.target.value;
                    const endDate = methods.getValues(`periods.${index}.endDate`);

                    if (
                      !isStartDateDisabled &&
                      !isEndDateDisabled &&
                      endDate &&
                      moment(startDate).isAfter(moment(endDate))
                    ) {
                      methods.setValue(`periods.${index}.startDate`, endDate);
                      methods.setValue(`periods.${index}.endDate`, startDate);
                    } else {
                      methods.setValue(`periods.${index}.startDate`, startDate);
                    }

                    if (methods.formState.submitCount > 0) {
                      methods.trigger('periods');
                    }
                  }}
                  isValid={!methods.formState.errors.periods?.[index]?.startDate}
                  errorMessage={methods.formState.errors.periods?.[index]?.startDate?.message}
                  min={moment().tz('Asia/Seoul').format('YYYY-MM-DDTHH:mm')}
                  max={max && moment(max).tz('Asia/Seoul').format('YYYY-MM-DDTHH:mm')}
                  readOnly={isStartDateDisabled}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace' || event.key === 'Delete') {
                      event.preventDefault();
                    }
                  }}
                />
              </div>

              <Text className={tilde} size={14} fontWeight={400}>
                ~
              </Text>

              <div className={inputContainer}>
                <Input
                  type='datetime-local'
                  size='lg'
                  width='100%'
                  {...methods.register(`periods.${index}.endDate`, {
                    validate: (value) => {
                      if (isEndDateDisabled) {
                        return true;
                      }

                      if (!value) {
                        return '종료일을 입력해주세요.';
                      }

                      if (moment(value).isBefore(moment())) {
                        return '종료일은 현재 시간 이후여야 합니다.';
                      }

                      const startDate = methods.getValues(`periods.${index}.startDate`);
                      if (moment(value).isSameOrBefore(moment(startDate))) {
                        return '종료시간은 현재 시작시간 이후여야 합니다.';
                      }

                      if (max && moment(value).isAfter(moment(max))) {
                        return `현재 플랜으로 ${moment(max)
                          .tz('Asia/Seoul')
                          .format('YYYY년 MM월 DD일')}까지 설정할 수 있습니다.`;
                      }

                      return true;
                    },
                  })}
                  onChange={(event) => {
                    const startDate = methods.getValues(`periods.${index}.startDate`);
                    const endDate = event.target.value;

                    if (
                      !isStartDateDisabled &&
                      !isEndDateDisabled &&
                      startDate &&
                      moment(startDate).isAfter(moment(endDate))
                    ) {
                      methods.setValue(`periods.${index}.startDate`, endDate);
                      methods.setValue(`periods.${index}.endDate`, startDate);
                    } else {
                      methods.setValue(`periods.${index}.endDate`, endDate);
                    }

                    if (methods.formState.submitCount > 0) {
                      methods.trigger('periods');
                    }
                  }}
                  isValid={!methods.formState.errors.periods?.[index]?.endDate}
                  errorMessage={methods.formState.errors.periods?.[index]?.endDate?.message}
                  min={moment().tz('Asia/Seoul').format('YYYY-MM-DDTHH:mm')}
                  max={max && moment(max).tz('Asia/Seoul').format('YYYY-MM-DDTHH:mm')}
                  readOnly={isEndDateDisabled}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace' || event.key === 'Delete') {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
            </div>

            <div className={buttonArea}>
              {!(isStartDateDisabled || isEndDateDisabled) && (
                <IconButton
                  onClick={() => {
                    if (fields.length === 1) {
                      return;
                    }

                    remove(index);
                  }}
                  disabled={false}
                  icon={MinusIcon}
                  alt='minus'
                />
              )}

              {index === fields.length - 1 && (
                <IconButton
                  onClick={() => append({ startDate: '', endDate: '' })}
                  disabled={false}
                  icon={PlusIcon}
                  alt='add'
                />
              )}
            </div>
          </div>
        );
      })}

      {!!periodsErrorMessage && (
        <Text size={12} fontWeight={400} color='red-500'>
          {periodsErrorMessage}
        </Text>
      )}
    </div>
  );
}

export default PeriodsField;
