import cancelHandSpaceOver from './modal/cancelHandSpaceOver';
import handSpaceOver from './modal/handSpaceOver';
import handSpaceOverConfirm from './modal/handSpaceOverConfirm';
import leaveSpace from './modal/leaveSpace';
import moveToHandSpaceOverBeforeRemoveSpace from './modal/moveToHandSpaceOverBeforeRemoveSpace';
import removeSpace from './modal/removeSpace';

export default {
  modal: {
    cancelHandSpaceOver,
    handSpaceOver,
    handSpaceOverConfirm,
    leaveSpace,
    moveToHandSpaceOverBeforeRemoveSpace,
    removeSpace,
  },
};
