import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { t } from 'i18next';

import { userRemovalModal } from 'states/modal';

function UserRemovalModal() {
  const { isVisible } = useRecoilValue(userRemovalModal);
  const setMessageModal = useSetRecoilState(userRemovalModal);

  const onClose = () => {
    setMessageModal({ isVisible: false });
  };

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <ModalHeader
        title={t('admin:components.features.modal.userRemoval.title')}
        onClose={onClose}
      />
      <ModalBody>
        <Text type='b3' fontWeight={300}>
          {t('admin:components.features.modal.userRemoval.description')}
        </Text>
      </ModalBody>
      <ModalActions
        buttons={[
          {
            text: t('admin:components.features.modal.userRemoval.actions.ok'),
            onClick: () => window.open(import.meta.env.VITE_INTRO_URL, '_self'),
          },
        ]}
      />
    </Modal>
  );
}

export default UserRemovalModal;
