import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, GhostButton, Text } from '@pickme/design-system';
import { t } from 'i18next';

import IntegrationCard from 'components/features/integration/Card';
import ChannelConnectModal from 'components/features/notification/modal/ChannelConnect';

import { useKakaoChannel } from 'pages/integration/Home/useKakaoChannel';

import { INTEGRATION_ITEMS } from 'constants/integration';

import { IntegrationService, IntegrationStatus } from 'types/integration';

import SuccessIcon from 'resources/icons/integration/success.svg';
import ErrorIcon from 'resources/icons/integration/error.svg';

import { container } from 'styles/page.css';
import { cards, enableButtonStyle, notLaunchedButton } from './index.css';

function Home() {
  const navigate = useNavigate();
  const [isChannelConnectModalVisible, setChannelConnectModalVisible] = useState(false);

  const kakaoStatus = useKakaoChannel();

  return (
    <div className={container({ gap: 32 })}>
      <Text size={32} fontWeight={600} tag='h3'>
        {t('admin:pages.integration.home.title')}
      </Text>

      <div className={cards}>
        <IntegrationCard
          service={IntegrationService.Kakao}
          icon={INTEGRATION_ITEMS.kakao.icon}
          title={INTEGRATION_ITEMS.kakao.title}
          description={INTEGRATION_ITEMS.kakao.description}
        >
          <IntegrationButton
            status={kakaoStatus}
            service={IntegrationService.Kakao}
            onClick={() => {
              if ([IntegrationStatus.Complete, IntegrationStatus.Pending].includes(kakaoStatus)) {
                navigate('/settings/integration/kakao/channel');
              }

              if (kakaoStatus === IntegrationStatus.Enabled) {
                setChannelConnectModalVisible(true);
              }
            }}
          />
        </IntegrationCard>

        <IntegrationCard
          service={IntegrationService.Email}
          icon={INTEGRATION_ITEMS.email.icon}
          title={INTEGRATION_ITEMS.email.title}
          description={INTEGRATION_ITEMS.email.description}
        >
          <IntegrationButton
            status={IntegrationStatus.NotLaunchedYet}
            service={IntegrationService.Email}
          />
        </IntegrationCard>

        <IntegrationCard
          service={IntegrationService.Slack}
          icon={INTEGRATION_ITEMS.slack.icon}
          title={INTEGRATION_ITEMS.slack.title}
          description={INTEGRATION_ITEMS.slack.description}
        >
          <IntegrationButton
            status={IntegrationStatus.NotLaunchedYet}
            service={IntegrationService.Slack}
          />
        </IntegrationCard>
      </div>

      <ChannelConnectModal
        isVisible={isChannelConnectModalVisible}
        onClose={() => setChannelConnectModalVisible(false)}
      />
    </div>
  );
}

export default Home;

function IntegrationButton({
  status,
  service,
  onClick,
}: {
  status: IntegrationStatus;
  service: IntegrationService;
  onClick?: () => void;
}) {
  const { text, variant } = getButtonProperties(status, service);

  if (status === IntegrationStatus.Enabled || status === IntegrationStatus.Disabled) {
    return (
      <Button
        className={enableButtonStyle}
        disabled={status === IntegrationStatus.Disabled}
        variant='primary'
        size='md'
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {text}
      </Button>
    );
  }

  return (
    <GhostButton
      variant={variant}
      className={status === IntegrationStatus.NotLaunchedYet ? notLaunchedButton : ''}
      size={status === IntegrationStatus.NotLaunchedYet ? 'md' : 'lg'}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {status === IntegrationStatus.Complete && <img src={SuccessIcon} alt='success' />}
      {status === IntegrationStatus.Pending && <img src={ErrorIcon} alt='pending' />}

      {text}
    </GhostButton>
  );
}

function getButtonProperties(status: IntegrationStatus, service: IntegrationService) {
  let disabledButtonText = t('admin:pages.integration.home.buttons.disabled.common');
  if (service === IntegrationService.Kakao) {
    disabledButtonText = t('admin:pages.integration.home.buttons.disabled.kakao');
  }

  let enabledButtonText = t('admin:pages.integration.home.buttons.enabled.common');
  if (service === IntegrationService.Kakao) {
    enabledButtonText = t('admin:pages.integration.home.buttons.enabled.kakao');
  }

  let pendingButtonText = t('admin:pages.integration.home.buttons.pending.common');
  if (service === IntegrationService.Kakao) {
    pendingButtonText = t('admin:pages.integration.home.buttons.pending.kakao');
  }

  switch (status) {
    case IntegrationStatus.Disabled:
      return { text: disabledButtonText, variant: 'blue' as const };
    case IntegrationStatus.Enabled:
      return { text: enabledButtonText, variant: 'blue' as const };
    case IntegrationStatus.Pending:
      return { text: pendingButtonText, variant: 'red' as const };
    case IntegrationStatus.Complete:
      return {
        text: t('admin:pages.integration.home.buttons.complete'),
        variant: 'green' as const,
      };
    case IntegrationStatus.NotLaunchedYet:
      return {
        text: t('admin:pages.integration.home.buttons.notLaunchedYet'),
        variant: 'yellow' as const,
      };
    default:
      return {
        text: t('admin:pages.integration.home.buttons.notLaunchedYet'),
        variant: 'yellow' as const,
      };
  }
}
