export default {
  title: '충전하기',

  method: {
    title: '결제 방식',
    transfer: '계좌이체',
    card: '카드',
    virtual: '가상계좌',
    depositWithoutBankbook: '무통장 입금',
  },

  point: '픽미 포인트',

  error: {
    title: '결제에 실패했습니다.',
    message: '실패 사유: {{text}}',
    wrongRequest: '잘못된 요청입니다.',
  },

  buttons: {
    cancel: '취소',
    submit: '결제하기',
  },

  cardLimitation: {
    title: '카드사별 충전 한도',
    header: {
      company: '카드사',
      limit: '결제한도',
    },
    bc: {
      label: '비씨, 하나 (구외환)',
      limit: '없음',
    },
    kb: {
      label: '국민',
      limit: '월 100만원 한도',
    },
    samsung: {
      label: '삼성',
      limit: '월 50만원 한도',
    },
    nh: {
      label: '농협 NH',
      limit: '없음\n1회 10만원 / 1일 10회 /\n1일 50만원 한도',
    },
    lotte: {
      label: '롯데',
      limit: '없음\n1회 5만원 한도',
    },
    shinhan: {
      label: '신한',
      limit: '없음\n1회 5만원 한도',
    },
    hyundai: {
      label: '현대',
      limit: '없음\n1회 10만원 / 1일 10회 /\n1일 50만원 한도',
    },
  },

  common: {
    title: '픽미 포인트 이용 안내',
    texts: [
      '픽미 포인트는 부가세 (VAT) 포함 가격으로 결제할 수 있습니다.',
      '10포인트로 알림톡 1건 전송이 가능합니다.',
      '신용카드 결제 시 각 카드사의 결제 한도가 적용되며 결제 수단 선택 후 확인할 수 있습니다.',
      '픽미 포인트 유효기간은 충전일로부터 1년이며 유효기간 만료일이 가까운 순서대로 사용됩니다.',
      '환불 가능한 기간은 충전일로부터 1년까지이며 충전 포인트의 일부를 사용한 경우 환불이 불가능합니다. 이 경우 고객센터로 문의주세요.',
      '총 환불 가능 금액 전체를 대상으로 환불이 진행되며, 부분 환불은 불가능합니다.',
    ],
  },
};
