import { Space } from '@pickme/core';
import { PlainButton, Text } from '@pickme/design-system';

import SpacePlaceholderIcon from 'resources/icons/nav/space_placeholder.svg';

import { image, item, itemTitle } from './index.css';

function SpaceItem({ space, onSelect }: { space: Space; onSelect: (spaceId: string) => void }) {
  const isActive = space.isAvailable();

  return (
    <PlainButton
      className={item({ active: isActive })}
      disabled={!isActive}
      onClick={() => {
        onSelect(space._id);
      }}
    >
      <img className={image} src={space.logo || SpacePlaceholderIcon} alt={space.name} />

      <Text className={itemTitle} size={16} fontWeight={400}>
        {space.name}
      </Text>
    </PlainButton>
  );
}

export default SpaceItem;
