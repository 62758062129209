import { Modal, ModalActions, ModalBody, ModalHeader, Table, Text } from '@pickme/ui';
import { t } from 'i18next';

import CategoryIcon from 'resources/icons/table/category.png';
import PeopleIcon from 'resources/icons/table/people.png';
import SearchIcon from 'resources/icons/table/search.png';

import './index.scss';

type Props = {
  isVisible: boolean;
  categories?: { name: string; count: number }[];
  total?: number;
  unset?: number;
  onClose: () => void;
};

function CategoryModal({ isVisible, categories = [], total = 0, unset = 0, onClose }: Props) {
  return (
    <Modal isVisible={isVisible} onClose={onClose} size='lg'>
      <ModalHeader
        title={t('admin:components.features.application.form.voter.modals.category.title')}
        onClose={onClose}
      />

      <ModalBody>
        <div className='voter-form__voters__title'>
          <Text type='b3' fontWeight={400}>
            {t(
              'admin:components.features.application.form.voter.modals.category.labels.categorizedVoters',
            )}
          </Text>
          <Text type='b3' fontWeight={600}>
            {(total - unset).toLocaleString('ko-KR')}
          </Text>
        </div>

        <div className='voter-form__category'>
          <Table
            className='voter-form__category__table'
            headers={tableHeaders}
            id='voter-category'
            rows={categories.map(({ name, count }, index) => ({
              id: name,
              items: [
                {
                  data: (
                    <Text type='b3' fontWeight={300}>
                      {index + 1}
                    </Text>
                  ),
                  name: 'number',
                },
                {
                  data: (
                    <Text type='b3' fontWeight={300}>
                      {name}
                    </Text>
                  ),
                  name: 'name',
                },
                {
                  data: (
                    <Text type='b3' fontWeight={300}>
                      {count}
                      {t('admin:terms.unit.people')}
                    </Text>
                  ),
                  name: 'count',
                },
              ],
            }))}
            placeholder={{
              icon: SearchIcon,
              text: t(
                'admin:components.features.application.form.voter.modals.category.labels.blank',
              ),
            }}
          />
        </div>

        <div className='voter-form__voters__title'>
          <Text type='b3' fontWeight={400}>
            {t(
              'admin:components.features.application.form.voter.modals.category.labels.uncategorizedVoters',
            )}
          </Text>
          <Text type='b3' fontWeight={600}>
            {unset.toLocaleString('ko-KR')}
          </Text>
        </div>

        <div className='voter-form__voters__description'>
          <Text type='lab1' fontWeight={400} color='gray-400'>
            {t(
              'admin:components.features.application.form.voter.modals.category.description.uncategorizedVoters',
            )}
          </Text>
        </div>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t(
              'admin:components.features.application.form.voter.modals.category.actions.close',
            ),
            onClick: onClose,
          },
        ]}
      />
    </Modal>
  );
}

const tableHeaders = [
  { title: '', name: 'number' },
  {
    title: t(
      'admin:components.features.application.form.voter.modals.category.table.header.category',
    ),
    icon: CategoryIcon,
    name: 'name',
  },
  {
    title: t('admin:components.features.application.form.voter.modals.category.table.header.count'),
    icon: PeopleIcon,
    name: 'count',
  },
];

export default CategoryModal;
