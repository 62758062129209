import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';
import { ElectionFormBody, ElectionFormBodyForEdit, SurveyFormBody } from 'types/application';
import {
  Poll,
  PollKind,
  PollQuery,
  PollResult,
  PollRate,
  PollStatisticsResponse,
  Voter,
  Population,
  PollInHistoryRequest,
  PollAnalysisResult,
  PollSearchKind,
} from 'types/poll';

const APIS: Apis = {
  applyPoll: { method: 'POST', url: '/api/poll' },
  editPoll: { method: 'PUT', url: '/api/poll/:id' },
  getPolls: { method: 'GET', url: '/api/poll' },
  getPollStatistics: { method: 'GET', url: '/api/poll/statistic' },
  getPoll: { method: 'GET', url: '/api/poll/:id' },
  getPollRate: { method: 'GET', url: '/api/poll/:id/rate' },
  getPollResult: { method: 'GET', url: '/api/poll/:id/result' },
  getVoters: { method: 'GET', url: '/api/poll/:pollId/voterBook?type=json&targetType=all' },
  getPopulation: { method: 'GET', url: '/api/poll/:pollId/population' },
  getCreationStatistics: { method: 'GET', url: '/api/poll/statistic/creation' },
  getAnalysis: { method: 'GET', url: '/api/poll/:pollId/result/analysis' },
  applyAnalysis: { method: 'POST', url: '/api/poll/:pollId/result/analysis' },
};

export const createApplyElectionRequest = (
  body:
    | Omit<ElectionFormBody, 'incorrectVoters' | 'subscription'>
    | Omit<SurveyFormBody, 'incorrectVoters' | 'subscription'>,
) =>
  createHttpRequest<Poll>({
    ...APIS.applyPoll,
    data: { poll: body },
  });

export const createEditElectionRequest = (id: string, body: ElectionFormBodyForEdit) =>
  createHttpRequest<{
    message: 'ok';
  }>({
    ...APIS.editPoll,
    data: { poll: body },
    params: { id },
  });

export const createGetPollsRequest = (query: PollQuery) =>
  createHttpRequest<{ endPage: number; polls: Poll[] }>({
    ...APIS.getPolls,
    query: { ...query, size: 12 },
  });

export const createGetPollsWithoutPagingRequest = (query: Omit<PollQuery, 'page'>) =>
  createHttpRequest<Poll[]>({
    ...APIS.getPolls,
    query,
  });

export const createGetPollRequest = (id: string) =>
  createHttpRequest<Poll>({
    ...APIS.getPoll,
    params: { id },
  });

export const createGetPollRateRequest = (id: string) =>
  createHttpRequest<PollRate>({
    ...APIS.getPollRate,
    params: { id },
  });

export const createGetPollStatistics = (query: { kind?: PollKind | PollSearchKind }) =>
  createHttpRequest<PollStatisticsResponse>({
    ...APIS.getPollStatistics,
    query,
  });

export const createGetPollResult = (id: string) =>
  createHttpRequest<PollResult>({
    ...APIS.getPollResult,
    params: { id },
  });

export const createGetVotersResult = (pollId: string) =>
  createHttpRequest<Voter[]>({
    ...APIS.getVoters,
    params: { pollId },
  });

export const createGetPopulation = (pollId: string) =>
  createHttpRequest<Population>({
    ...APIS.getPopulation,
    params: { pollId },
  });

export const createGetPollsHistoryRequest = (page: number) =>
  createHttpRequest<{ endPage: number; polls: PollInHistoryRequest[] }>({
    ...APIS.getPolls,
    query: {
      size: 10,
      includeAuthorInfo: true,
      statuses: 'approved,canceled,deleted',
      stages: 'in,post',
      page,
    },
  });

export const createGetPollCreationStatisticsRequest = () =>
  createHttpRequest<{
    election: number;
    survey: number;
    total: number;
  }>({
    ...APIS.getCreationStatistics,
  });

export const createGetPollAnalysis = (pollId: string) =>
  createHttpRequest<PollAnalysisResult[] | []>({
    ...APIS.getAnalysis,
    params: { pollId },
  });

export const createApplyPollAnalysis = (pollId: string) =>
  createHttpRequest({
    ...APIS.applyAnalysis,
    params: { pollId },
    data: {
      analysisType: 'summary',
    },
  });
