import { SocialTitleV2 } from '@pickme/core';

export type ChannelQueryType = 'alias' | 'objectId';

export type ChannelEditForm = {
  name: string;
  alias: string;
  logo: string;
  cover: string;
  description: string;
  socials: {
    platform: SocialTitleV2;
    url: string;
  }[];
};

export type ChannelCreateForm = {
  name: string;
  alias: string;
  logo?: string;
  cover?: string;
};

export enum ChannelFormStep {
  Name = 'name',
  Alias = 'alias',
  Image = 'image',
  Complete = 'complete',
}
