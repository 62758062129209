import { useEffect } from 'react';
import { ObjectSchema, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Input } from '@pickme/design-system';
import { SocialTitleV2 } from '@pickme/core';

import EditButtons from 'components/features/channel-v2/EditButtons';

import {
  facebookLinkValidator,
  instagramLinkValidator,
  youtubeLinkValidator,
} from 'functions/validator/channel-v2';

import editStyle from '../../../edit.css';
import style from './index.css';

type Props = {
  icon: string;
  title: SocialTitleV2;
  url: string;
  onSubmit: (value: { url: string }) => void;
  isLoading: boolean;
  setIsEditMode: (value: boolean) => void;
};

function SnsLinkChangeForm({ icon, title, url, onSubmit, isLoading, setIsEditMode }: Props) {
  const schema: ObjectSchema<{ url: string | undefined }> = object().shape({
    url: string().test('format', '올바른 URL을 입력해주세요', (value) =>
      value ? linkValidators[title](value) : true,
    ),
  });

  const methods = useForm<{ url: string }>({
    defaultValues: { url: '' },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (url) {
      methods.setValue('url', url);
    }
  }, [url]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit((value) => {
          onSubmit(value);
          setIsEditMode(false);
        })}
        className={editStyle.form}
        autoComplete='off'
      >
        <img src={icon} alt={title} className={style.icon} />

        <Input
          {...methods.register('url')}
          size='lg'
          placeholder='링크를 입력해 주세요'
          isValid={!methods.watch('url') || methods.formState.isValid}
          errorMessage={methods.formState.errors.url?.message}
          className={style.inputContainer}
        />

        <EditButtons
          isLoading={isLoading}
          isEditDisabled={!methods.formState.isValid}
          setIsEditMode={setIsEditMode}
          isEditMode
        />
      </form>
    </FormProvider>
  );
}

export default SnsLinkChangeForm;

const linkValidators: {
  [key in SocialTitleV2]: (value: string) => boolean;
} = {
  [SocialTitleV2.Instagram]: instagramLinkValidator,
  [SocialTitleV2.Facebook]: facebookLinkValidator,
  [SocialTitleV2.Youtube]: youtubeLinkValidator,
  [SocialTitleV2.Blog]: () => false,
  [SocialTitleV2.ETC]: () => false,
};
