import { Button, Text } from '@pickme/design-system';
import { t } from 'i18next';
import { useDropzone } from 'react-dropzone';
import { useSetRecoilState } from 'recoil';

import ImageCloseButton from 'components/features/application-v2/button/ImageCloseButton';

import { useUploadFile } from 'query-hooks/file';

import { toast } from 'states/toast';

import ImageIcon from 'resources/icons/application/image.svg';

import { container, outerContainer, coverImage, card, icon, removeButton } from './index.css';

type Props = {
  imageUrl: string;
  setImageUrl: (imageUrl: string) => void;
};

function FileUpload({ imageUrl, setImageUrl }: Props) {
  const setToast = useSetRecoilState(toast);
  const { mutate: fileUploadMutate, isLoading: isFileUploadLoading } = useUploadFile();

  const onDrop = (files: File[]) => {
    fileUploadMutate(files[0], {
      onSuccess: setImageUrl,
    });
  };

  const onDropRejected = () => {
    setToast({
      isVisible: true,
      type: 'error',
      message: t('admin:components.features.application.fields.cover.errorMessages.common'),
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    maxFiles: 1,
    multiple: false,
    minSize: 50,
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png'],
    },
  });

  return (
    <div className={outerContainer}>
      <div
        className={`${container({ isActive: isDragActive || isFileUploadLoading })}`}
        {...getRootProps()}
      >
        <input
          {...getInputProps({
            type: 'file',
            hidden: true,
            disabled: isFileUploadLoading,
            accept: 'image/jpeg, image/jpg, image/png',
          })}
        />

        {imageUrl ? (
          <img
            className={`${coverImage({ isActive: isDragActive || isFileUploadLoading })}`}
            src={imageUrl}
            alt='cover'
          />
        ) : (
          <div className={card}>
            <img className={icon} src={ImageIcon} alt='upload' />

            <Text size={14} fontWeight={400} color='gray-700'>
              이미지 파일을 드래그해주세요.
            </Text>

            <Button variant='gray' size='sm'>
              이미지 파일 선택
            </Button>
          </div>
        )}

        {imageUrl && (
          <ImageCloseButton
            className={removeButton}
            onClick={(event) => {
              event.stopPropagation();
              setImageUrl('');
            }}
          />
        )}
      </div>

      <Text size={12} fontWeight={400} color='gray-400'>
        {t('admin:components.features.application.fields.cover.description')}
      </Text>
    </div>
  );
}

export default FileUpload;
