import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

import { useQueryString } from 'hooks/useQueryString';

import { messageModal } from 'states/modal';

function PaymentFailureCallback() {
  const navigate = useNavigate();
  const { message, product } = useQueryString({ message: '', product: '' });

  const setMessageModal = useSetRecoilState(messageModal);

  useEffect(() => {
    setTimeout(() => {
      setMessageModal({
        isVisible: true,
        title: t('admin:pages.payment.callback.errorMessages.failure.title'),
        message: t('admin:pages.payment.callback.errorMessages.failure.message', {
          message: `${message || '잘못된 요청입니다.'}`,
        }),
      });

      if (product === 'point') {
        navigate('/service/notification/point', { replace: true });
      } else {
        navigate('/', { replace: true });
      }
    }, 1000);
  }, [message]);

  return (
    <div className='payment-waiting'>
      <Spinner />
    </div>
  );
}

export default PaymentFailureCallback;
