import { Checkbox, Radio } from '@pickme/design-system';

import { SurveyTypeCase } from 'types/application-v2';

import { container, checkbox } from './index.css';

type Props = {
  type: 'checkbox' | 'radio';
  surveyType: SurveyTypeCase;
};

const SURVEY_TYPES_TO_ALIGN_TOP: SurveyTypeCase[] = [SurveyTypeCase.이미지선택];

function CheckboxOrRadio({ type, surveyType }: Props) {
  return (
    <div
      className={container({
        alignTop: SURVEY_TYPES_TO_ALIGN_TOP.includes(surveyType),
      })}
    >
      {type === 'checkbox' ? (
        <Checkbox className={checkbox} checked={false} onChange={() => {}} />
      ) : (
        <Radio checked={false} onChange={() => {}} />
      )}
    </div>
  );
}

export default CheckboxOrRadio;
