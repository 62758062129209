import { ThemeNames } from 'types/application-v2';
import { ThemeColors } from 'types/poll-v2';

export const POLL_THEMES: {
  [key in ThemeNames]: {
    colors: ThemeColors;
  };
} = {
  [ThemeNames.White]: {
    colors: {
      primary: '#067DFD',
      secondary: '#F7F8FA',
      tertiary: '#EEF7FF',
      inactive: '#E0E0E0',
    },
  },
  [ThemeNames.Yellow]: {
    colors: {
      primary: '#FFCC1F',
      secondary: '#FFFBD8',
      tertiary: '#FCF5BA',
      inactive: '#E0E0E0',
    },
  },
  [ThemeNames.Orange]: {
    colors: {
      primary: '#FF710A',
      secondary: '#FDEDDD',
      tertiary: '#FEE0C3',
      inactive: '#FFFFFF',
    },
  },
  [ThemeNames.Red]: {
    colors: {
      primary: '#D70C19',
      secondary: '#FBE4E5',
      tertiary: '#FDCACD',
      inactive: '#FFFFFF',
    },
  },
  [ThemeNames.Pink]: {
    colors: {
      primary: '#F173C6',
      secondary: '#FFF0FA',
      tertiary: '#FFDAF2',
      inactive: '#FFFFFF',
    },
  },
  [ThemeNames.Violet]: {
    colors: {
      primary: '#9164C9',
      secondary: '#EAE2F4',
      tertiary: '#E2D0F8',
      inactive: '#FFFFFF',
    },
  },
  [ThemeNames.Lime]: {
    colors: {
      primary: '#92DC65',
      secondary: '#F0FCDE',
      tertiary: '#E2D0F8',
      inactive: '#E0E0E0',
    },
  },
  [ThemeNames.Green]: {
    colors: {
      primary: '#39B04A',
      secondary: '#ECF6EE',
      tertiary: '#D2EDD8',
      inactive: '#E0E0E0',
    },
  },
  [ThemeNames.Brown]: {
    colors: {
      primary: '#A17245',
      secondary: '#F9F0E0',
      tertiary: '#ECDDC4',
      inactive: '#FFFFFF',
    },
  },
  [ThemeNames.Indigo]: {
    colors: {
      primary: '#067DFD',
      secondary: '#EEF7FF',
      tertiary: '#D3E9FD',
      inactive: '#E0E0E0',
    },
  },
  [ThemeNames.Blue]: {
    colors: {
      primary: '#2320CA',
      secondary: '#EEF1FF',
      tertiary: '#D3DBFF',
      inactive: '#E0E0E0',
    },
  },
  [ThemeNames.Gray]: {
    colors: {
      primary: '#4E5968',
      secondary: '#E5E8EB',
      tertiary: '#D2D5D8',
      inactive: '#FFFFFF',
    },
  },
};
