import { useState } from 'react';
import { Text, Button, OutlineButton } from '@pickme/design-system';
import { Outlet } from 'react-router-dom';
import { t } from 'i18next';

import Tabs from 'components/common/Tabs';
import PointBillModal from 'components/features/payment/modal/PointBill';
import TransferPointModal from 'components/features/payment/modal/TransferPoint';
import TransferPointConfirmModal from 'components/features/payment/modal/TransferPoint/Confirm';
import DisabledFeatureBanner from 'components/features/banner/DisabledFeature';
import PointSummary from 'components/features/payment/PointSummary';

import { useGetAccount } from 'query-hooks/payment';

import { useGetFeatures } from 'hooks/useGetFeatures';
import { useTabWithPath } from 'hooks/useTabWithPath';

import { MESSAGE_POINT_TABS } from 'constants/notification';

import { Manager } from 'types/manager';

import ChargeIcon from 'resources/icons/payment/charge.svg';
import GiftIcon from 'resources/icons/payment/gift.svg';

import { container } from 'styles/page.css';
import { buttons, card } from './index.css';

function Point() {
  const [isBillModalVisible, setBillModalVisible] = useState(false);
  const [isTransferPointModalVisible, setTransferPointModalVisible] = useState(false);
  const [transferPointConfirmModal, setTransferPointConfirmModal] = useState<{
    isVisible: boolean;
    receiver?: Manager;
    point?: number;
  }>({
    isVisible: false,
  });

  const { enableToUseNotification } = useGetFeatures();

  const { data: accountData } = useGetAccount();

  const { setTab, selectedTabIndex } = useTabWithPath({
    urlPrefix: '/service/notification/point',
    tabItems: MESSAGE_POINT_TABS,
  });

  const tabs = MESSAGE_POINT_TABS.map(({ text, value }) => ({
    text,
    onClick: () => setTab(value),
  }));

  return (
    <>
      {!enableToUseNotification && <DisabledFeatureBanner type='subscribe' />}

      <div className={container({ gap: 32 })}>
        <Text size={32} fontWeight={600}>
          {t('admin:pages.payment.point.title')}
        </Text>

        <div className={card}>
          <PointSummary
            total={accountData?.balance.print() || ''}
            charge={accountData?.point.print() || ''}
            save={accountData?.bonus.print() || ''}
          />

          <div className={buttons.container}>
            <Button
              className={buttons.charge}
              size='lg'
              onClick={() => setBillModalVisible(true)}
              disabled={!enableToUseNotification}
            >
              <img src={ChargeIcon} alt='charge' />
              충전하기
            </Button>

            <OutlineButton
              className={buttons.transaction}
              size='lg'
              onClick={() => setTransferPointModalVisible(true)}
            >
              <img src={GiftIcon} alt='charge' />
              선물하기
            </OutlineButton>
          </div>
        </div>

        <div className={container({ gap: 16 })}>
          <Tabs tabs={tabs} selectedIndex={selectedTabIndex} />

          <Outlet />
        </div>

        <PointBillModal isVisible={isBillModalVisible} onClose={() => setBillModalVisible(false)} />

        <TransferPointModal
          isVisible={isTransferPointModalVisible}
          onClose={() => setTransferPointModalVisible(false)}
          onSubmitButtonClick={(receiver, point) => {
            setTransferPointModalVisible(false);
            setTransferPointConfirmModal({
              isVisible: true,
              receiver,
              point,
            });
          }}
        />

        {transferPointConfirmModal.receiver && transferPointConfirmModal.point && (
          <TransferPointConfirmModal
            isVisible={transferPointConfirmModal.isVisible}
            receiver={transferPointConfirmModal.receiver}
            point={transferPointConfirmModal.point}
            onClose={() =>
              setTransferPointConfirmModal({
                isVisible: false,
              })
            }
            onPrevious={() => {
              setTransferPointConfirmModal({ isVisible: false });
              setTransferPointModalVisible(true);
            }}
          />
        )}
      </div>
    </>
  );
}

export default Point;
