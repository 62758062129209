import { useEffect, useState } from 'react';
import { Input, Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { t } from 'i18next';

import ManagerSelector from 'components/features/manager/ManagerSelector';

import { emailValidator } from 'functions/validator/auth';

import { Manager } from 'types/manager';

import './index.scss';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  onSubmitButtonClick: (manager: Manager | string) => void;
};

function HandSpaceOverModal({ isVisible, onClose, onSubmitButtonClick }: Props) {
  const [type, setType] = useState<'pick' | 'manual'>('pick');
  const [email, setEmail] = useState('');
  const [receiver, setReceiver] = useState<Manager>();

  useEffect(() => {
    if (!isVisible) {
      setTimeout(() => {
        setType('pick');
        setEmail('');
      }, 500);
    }
  }, [isVisible]);

  return (
    <Modal size='lg' isVisible={isVisible} onClose={onClose} className='hand-space-over-modal'>
      <ModalHeader
        title={t('admin:components.features.space.modal.handSpaceOver.title')}
        onClose={onClose}
      />

      <ModalBody>
        <Text className='hand-space-over-modal__description' type='b3' color='gray-400'>
          {t('admin:components.features.space.modal.handSpaceOver.message')}
        </Text>

        <ManagerSelector
          onSelectManager={(manager) => {
            setType('pick');
            setReceiver(manager);
          }}
          selectedManager={receiver}
          addManualInputItem
          isManualMode={type === 'manual'}
          onSelectManualInputItem={() => {
            setReceiver(undefined);
            setType('manual');
          }}
        />

        {type === 'manual' && (
          <Input
            className='hand-space-over-modal__input'
            placeholder={t(
              'admin:components.features.space.modal.handSpaceOver.manual.placeholder',
            )}
            width='100%'
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            isValid={!email || emailValidator(email)}
            errorMessage={t(
              'admin:components.features.space.modal.handSpaceOver.manual.errorMessage',
            )}
          />
        )}
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t('admin:components.features.space.modal.handSpaceOver.buttons.cancel'),
            variant: 'gray',
            onClick: onClose,
          },
          {
            text: t('admin:components.features.space.modal.handSpaceOver.buttons.next'),
            onClick: () => {
              if (type === 'pick' && receiver) {
                onSubmitButtonClick(receiver);
              } else if (type === 'manual' && email) {
                onSubmitButtonClick(email.trim().toLowerCase());
              }
            },
            disabled: !(
              (type === 'pick' && !!receiver) ||
              (type === 'manual' && emailValidator(email))
            ),
          },
        ]}
      />
    </Modal>
  );
}

export default HandSpaceOverModal;
