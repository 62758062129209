import { Button, Text } from '@pickme/design-system';
import { t } from 'i18next';

import AddIcon from 'resources/icons/dashboard/add.svg';

import { container, title } from './index.css';

type Props = {
  onClick: () => void;
};

function ManualStartCard({ onClick }: Props) {
  return (
    <div className={container}>
      <div className={title}>
        <Text size={20} fontWeight={600}>
          {t('admin:components.features.template.cards.manualStart.title')}
        </Text>
        <Text size={16} fontWeight={400} color='gray-700'>
          {t('admin:components.features.template.cards.manualStart.description')}
        </Text>
      </div>

      <Button size='lg' onClick={() => onClick()}>
        <img src={AddIcon} alt='add' />
        {t('admin:components.features.template.cards.manualStart.button')}
      </Button>
    </div>
  );
}

export default ManualStartCard;
