export default {
  title: '새로운 스페이스 만들기',
  description: '새로운 스페이스를 만들어 투표를 진행해 보세요',
  form: {
    fields: {
      name: {
        placeholder: '스페이스명은 {{length}}자 이하로 작성해주세요',
      },
      category: {
        default: '분류',
      },
    },
    errorMessages: {
      name: '스페이스 이름을 입력하세요',
      category: '분류를 선택하세요',
    },
  },
  labels: {
    middleHighSchool: '중/고등학교',
    university: '대학교',
    company: '기업',
    religiousGroup: '종교 단체',
    association: '조합',
    apartment: '아파트',
    other: '기타',
  },
  actions: {
    submit: '만들기',
  },
};
