import { DraftTemplate, DraftTemplateCategory } from 'types/template';

import Thumbnail1 from 'resources/images/templates/survey/labor1.png';
import Thumbnail2 from 'resources/images/templates/survey/labor2.png';

const DRAFT_1 = {
  title: '노동조합 임금 협상 사전 조사',
  _id: '64b89e1b95059000479a18f0',
  content: {
    name: '노동조합 임금 협상 사전 조사',
    periods: [
      {
        startDate: '2023-07-20T13:32',
        endDate: '2023-07-28T11:33',
      },
    ],
    description:
      '<p>본 투표는 노동조합 임금 협상 사전 조사입니다.</p><p>여러분의 소중한 한 표 부탁드립니다.</p><p>감사합니다.</p>',
    type: 'Survey',
    voterType: 'voterBook',
    hasOpenVotes: false,
    subscriptionType: 'enterprise',
    questions: [
      {
        kind: 'RadioQuestion',
        title: '기본급 3% 인상에 찬성하시나요?',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '찬성',
            fileUrl: '',
          },
          {
            description: '반대',
            number: 2,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'RadioQuestion',
        title: '성과상여금 기본급 50%에 찬성하시나요?',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '찬성',
            fileUrl: '',
          },
          {
            description: '반대',
            number: 2,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'RadioQuestion',
        title: '특별상여금 200만원에 찬성하시나요?',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '찬성',
            fileUrl: '',
          },
          {
            description: '반대',
            number: 2,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    allowRealTimeResult: false,
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-07-20T02:38:19.337Z',
  __v: 0,
};

const DRAFT_2 = {
  _id: '65dd8d406af7bf00d12f0717',
  title: '근로 환경 안전 조사',
  content: {
    name: '근로 환경 안전 조사',
    description:
      '<p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">현재 근무하고 있는 근로지의 작업 환경과 안전 조치에 대한 관리 등을 조사하여 개선시킬 목적이오니 많은 참여 부탁드립니다.</span><br><br><span style="white-space: pre-wrap;">감사합니다.</span></p>',
    questions: [
      {
        min: {
          value: 1,
          description: '불편하다',
        },
        max: {
          value: 5,
          description: '만족스럽다',
        },
        videoUrl: {
          youtube: '',
        },
        isRequired: true,
        kind: 'ScaleQuestion',
        _id: '65dd8d406af7bf00d12f0718',
        title: '노동 환경 만족도',
        description: '',
        fileUrl: '',
      },
      {
        videoUrl: {
          youtube: '',
        },
        isRequired: false,
        kind: 'TextQuestion',
        _id: '65dd8d406af7bf00d12f0719',
        title: '안전 사고를 경험했었나요?',
        description: '',
        responseValidation: {
          _id: '65dd8d406af7bf00d12f071a',
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
      },
      {
        videoUrl: {
          youtube: '',
        },
        isRequired: false,
        kind: 'TextQuestion',
        _id: '65dd8d406af7bf00d12f071b',
        title: '위험한 작업 환경이 있나요?',
        description: '',
        responseValidation: {
          _id: '65dd8d406af7bf00d12f071c',
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
      },
      {
        videoUrl: {
          youtube: '',
        },
        isRequired: false,
        kind: 'TextQuestion',
        _id: '65dd8d406af7bf00d12f071d',
        title: '개선 혹은 보수가 필요한 작업 환경지가 있나요?',
        description: '',
        responseValidation: {
          _id: '65dd8d406af7bf00d12f071e',
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
      },
    ],
    periods: [
      {
        startDate: '2024-02-27T09:13:00.000Z',
        endDate: '2024-02-29T07:13:00.000Z',
      },
    ],
    type: 'Survey',
    voterType: 'voterBook',
    hasOpenVotes: false,
    subscriptionType: 'enterprise',
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-06-26T06:41:40.686Z',
  __v: 0,
};

export default [
  {
    title: '노동조합 임금 협상 조사',
    description: '노동조합 임금 협상 조사를 위한 템플릿입니다.',
    thumbnail: Thumbnail1,
    draft: DRAFT_1,
    category: DraftTemplateCategory.Labor,
    kind: 'Survey',
  },
  {
    title: '근로 환경 안전 조사',
    description: `${DRAFT_2.title}를 위한 템플릿입니다.`,
    thumbnail: Thumbnail2,
    draft: DRAFT_2,
    category: DraftTemplateCategory.Labor,
    kind: 'Survey',
  },
] as DraftTemplate[];
