import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

export function usePollPeriodErrorModal(error: AxiosError | null) {
  const [isPeriodErrorModalVisible, setPeriodErrorModalVisible] = useState(false);
  useEffect(() => {
    if (error?.code === 'POLL-001') {
      setPeriodErrorModalVisible(true);
    }
  }, [error?.code === 'POLL-001']);

  return [isPeriodErrorModalVisible, setPeriodErrorModalVisible] as const;
}
