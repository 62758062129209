import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { Text, Button, PlainButton, OutlineButton } from '@pickme/design-system';
import { PLANS, Plan, Space, ContractType } from '@pickme/core';
import { chunk } from 'lodash-es';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { t } from 'i18next';

import SubscriptionBadge from 'components/features/badge/SubscriptionBadge';
import DiscardSubscriptionModal from 'components/features/payment/modal/DiscardSubscription';
import ReplaceEmailModal from 'components/features/payment/modal/ReplaceEmail';
import PurchaseMoreVotersModal from 'components/features/payment/modal/PurchaseMoreVoters';
import SubscriptionCard from 'components/features/payment/card/Subscription';
import PaymentRecordsTable from 'components/features/payment/table/PaymentRecords';

import {
  useCancelPlanReplace,
  useCancelReplaceVoterLimit,
  useGetPayment,
} from 'query-hooks/payment';
import { useGetSessionAsMember } from 'query-hooks/auth';

import { useGetFeatures } from 'hooks/useGetFeatures';
import { useGetActiveSpace } from 'hooks/useGetActiveSpace';

import { messageModal } from 'states/modal';

import { CARD_NAME } from 'constants/payment';

import {
  callout,
  calloutRow,
  card,
  cards,
  container,
  description,
  planContainer,
  textMarginLeft,
} from './index.css';

const clientKey = import.meta.env.VITE_TOSS_BILLING_CLIENT_KEY || '';

function SubscriptionPage() {
  const [isDiscardModalVisible, setDiscardModalVisible] = useState(false);
  const [isReplaceEmailModalVisible, setReplaceEmailModalVisible] = useState(false);
  const [isPurchaseMoreVotersModalVisible, setPurchaseMoreVotersModalVisible] = useState(false);

  const setMessageModal = useSetRecoilState(messageModal);

  const organizationData = useGetActiveSpace();
  const { data: paymentData } = useGetPayment();
  const { data: memberSessionData } = useGetSessionAsMember();
  const { isPaymentFailed } = useGetFeatures();

  const { mutate: mutateCancelReplacePlan } = useCancelPlanReplace();
  const { mutate: mutateCancelReplaceVoterLimit } = useCancelReplaceVoterLimit();

  const pricingType = organizationData?.subscription?.type ?? Plan.Free;

  const onClickReplaceCardButton = async () => {
    try {
      const tossPayments = await loadTossPayments(clientKey);
      tossPayments.requestBillingAuth('카드', {
        customerKey: memberSessionData?.id || '',
        successUrl: `${
          import.meta.env.VITE_INDEX_URL
        }/payment/callback/success?product=replaceCard`,
        failUrl: `${import.meta.env.VITE_INDEX_URL}/payment/callback/failure?product=replaceCard`,
      });
    } catch (error: any) {
      setMessageModal({
        isVisible: true,
        title: t('admin:pages.payment.subscription.errorMessages.card.title'),
        message: t('admin:pages.payment.subscription.errorMessages.card.message', {
          message: `${error.message || '잘못된 요청입니다.'}`,
        }),
      });
    }
  };

  const isSupposedToDiscardSubscription =
    organizationData?.subscription?.nextSubscription?.type === Plan.Free;

  const isAbleToReplacePlan =
    organizationData?.subscription?.type !== Plan.Free &&
    organizationData?.subscription?.contractType !== ContractType.Custom;

  const isAbleToPurchaseMoreVoterLimit =
    organizationData?.subscription?.type !== Plan.Free &&
    organizationData?.subscription?.contractType !== 'custom' &&
    memberSessionData?.level === 'OrganAdmin';

  const isAbleToSubscribe = organizationData?.subscription?.type === Plan.Free;

  const isAbleToDiscard =
    organizationData?.subscription?.nextSubscription?.type !== Plan.Free &&
    organizationData?.subscription?.contractType !== 'custom' &&
    !isSupposedToDiscardSubscription &&
    memberSessionData?.level === 'OrganAdmin';

  const isPaymentMethodCardVisible =
    memberSessionData?.level === 'OrganAdmin' &&
    organizationData?.subscription?.contractType !== 'custom' &&
    pricingType !== Plan.Free;

  return (
    <div className={container}>
      <Text tag='h1' size={32} fontWeight={600}>
        {t('admin:pages.payment.subscription.title')}
      </Text>

      <div className={planContainer}>
        {organizationData?.subscription?.planChangeScheduled && isSupposedToDiscardSubscription && (
          <PlanDiscardPreview
            organizationData={organizationData}
            onClick={() => {
              mutateCancelReplacePlan({ isDiscard: true });
              mutateCancelReplaceVoterLimit({ isDiscard: true });
            }}
            hideButton={memberSessionData?.level !== 'OrganAdmin'}
          />
        )}

        {organizationData?.subscription?.planChangeScheduled &&
          !isSupposedToDiscardSubscription && (
            <PlanChangePreview
              organizationData={organizationData}
              previousPlan={pricingType ? PLANS[pricingType].name.kor : ''}
              onClick={() => {
                mutateCancelReplacePlan({ isDiscard: false });
              }}
              hideButton={memberSessionData?.level !== 'OrganAdmin'}
            />
          )}

        <div className={cards}>
          <SubscriptionCard>
            <div className={card.header}>
              <Text size={14} fontWeight={400} color='gray-800'>
                플랜
              </Text>

              <SubscriptionBadge type={pricingType} />
            </div>

            {(isAbleToSubscribe && (
              <Button
                size='sm'
                disabled={memberSessionData?.level !== 'OrganAdmin'}
                onClick={() =>
                  window.open(
                    `${
                      import.meta.env.VITE_PAYMENT_URL
                    }/guide?organizationId=${organizationData?._id}`,
                    '_self',
                  )
                }
              >
                {t('admin:pages.payment.subscription.cards.subscription.button.subscribe')}
              </Button>
            )) ||
              (isAbleToReplacePlan && (
                <div className={description}>
                  <Text size={12} fontWeight={400} color='gray-600'>
                    {t(
                      'admin:pages.payment.subscription.cards.subscription.description.replacePlan.forPaidPlan',
                    )}
                  </Text>

                  <Link
                    to={`${
                      import.meta.env.VITE_PAYMENT_URL
                    }/plan/replace?organizationId=${organizationData?._id}`}
                  >
                    <Text size={12} fontWeight={400} color='primary-500'>
                      {t('admin:pages.payment.subscription.cards.subscription.button.replacePlan')}
                    </Text>
                  </Link>
                </div>
              ))}
          </SubscriptionCard>

          <SubscriptionCard>
            <div className={card.header}>
              <Text size={14} fontWeight={400} color='gray-800'>
                {t('admin:pages.payment.subscription.labels.voterLimit')}
              </Text>

              <Text size={14} fontWeight={500}>
                {(organizationData?.subscription?.voterLimit || 0).toLocaleString('ko-KR')}
                {t('admin:terms.unit.people')}
              </Text>
            </div>

            {isAbleToPurchaseMoreVoterLimit && (
              <Button onClick={() => setPurchaseMoreVotersModalVisible(true)} size='md'>
                {t('admin:pages.payment.subscription.cards.subscription.button.purchaseMoreVoters')}
              </Button>
            )}
          </SubscriptionCard>

          {pricingType !== Plan.Free && (
            <SubscriptionCard>
              <div className={card.header}>
                <Text size={14} fontWeight={400} color='gray-800'>
                  {t('admin:pages.payment.subscription.labels.period')}
                </Text>
                <Text size={14} fontWeight={500}>
                  {`${moment(organizationData?.subscription?.contractPeriod?.start)
                    .tz('Asia/Seoul')
                    .format('YYYY. MM. DD')} ~ ${moment(
                    organizationData?.subscription?.contractPeriod?.end,
                  )
                    .tz('Asia/Seoul')
                    .format('YYYY. MM. DD')}`}
                </Text>
              </div>

              {isAbleToDiscard && (
                <div className={description}>
                  <Text size={12} fontWeight={400} color='gray-600'>
                    {t(
                      'admin:pages.payment.subscription.cards.subscription.description.discardSubscription',
                    )}
                  </Text>

                  <PlainButton onClick={() => setDiscardModalVisible(true)}>
                    <Text size={12} color='red-500' fontWeight={400}>
                      {t(
                        'admin:pages.payment.subscription.cards.subscription.button.discardSubscription',
                      )}
                    </Text>
                  </PlainButton>
                </div>
              )}
            </SubscriptionCard>
          )}

          {isPaymentMethodCardVisible && (
            <SubscriptionCard>
              <div className={card.header}>
                <Text size={14} fontWeight={400} color='gray-800'>
                  {t('admin:pages.payment.subscription.labels.card')}
                </Text>

                <Text size={14} fontWeight={500} color={isPaymentFailed ? 'red-400' : 'black'}>
                  {CARD_NAME?.[paymentData?.card.acquirerCode || ''] || ''}{' '}
                  {chunk(paymentData?.card.number, 4)
                    .map((arr) => arr.join(''))
                    .join(' ')}
                </Text>
              </div>

              <div className={card.body}>
                <OutlineButton fullWidth onClick={() => onClickReplaceCardButton()}>
                  <Text
                    size={14}
                    fontWeight={400}
                    color={isPaymentFailed ? 'red-500' : 'primary-500'}
                  >
                    {t('admin:pages.payment.subscription.cards.subscription.button.replaceCard')}
                  </Text>
                </OutlineButton>

                <OutlineButton fullWidth onClick={() => setReplaceEmailModalVisible(true)}>
                  <Text size={14} fontWeight={400} color='primary-500'>
                    {t('admin:pages.payment.subscription.cards.subscription.button.replaceEmail')}
                  </Text>
                </OutlineButton>
              </div>
            </SubscriptionCard>
          )}
        </div>
      </div>

      <PaymentRecordsTable />

      <DiscardSubscriptionModal
        isVisible={isDiscardModalVisible}
        dueDate={
          organizationData?.subscription?.contractPeriod?.end ||
          organizationData?.subscription?.period?.end ||
          ''
        }
        onClose={() => setDiscardModalVisible(false)}
      />

      <ReplaceEmailModal
        isVisible={isReplaceEmailModalVisible}
        defaultValue={paymentData?.receiptEmail || ''}
        onClose={() => setReplaceEmailModalVisible(false)}
      />

      {isAbleToPurchaseMoreVoterLimit && (
        <PurchaseMoreVotersModal
          plan={pricingType}
          isVisible={isPurchaseMoreVotersModalVisible}
          onClose={() => setPurchaseMoreVotersModalVisible(false)}
        />
      )}
    </div>
  );
}

export default SubscriptionPage;

function PlanDiscardPreview({
  organizationData,
  onClick,
  hideButton,
}: {
  organizationData: Space;
  onClick: () => void;
  hideButton: boolean;
}) {
  return (
    <div className={callout}>
      <div className={calloutRow({ gap: 0 })}>
        <Text size={16} fontWeight={600}>
          {t(
            'admin:pages.payment.subscription.cards.subscription.description.scheduledToDiscard.discardAt.0',
            {
              date: moment(
                organizationData?.subscription?.contractPeriod?.end ||
                  organizationData?.subscription?.period?.end,
              )
                .tz('Asia/Seoul')
                .format('YYYY년 M월 D일'),
            },
          )}
        </Text>

        <Text className={textMarginLeft} size={16} fontWeight={600} color='red-500'>
          {t(
            'admin:pages.payment.subscription.cards.subscription.description.scheduledToDiscard.discardAt.1',
          )}
        </Text>

        <Text size={16} fontWeight={600}>
          {t(
            'admin:pages.payment.subscription.cards.subscription.description.scheduledToDiscard.discardAt.2',
          )}
        </Text>
      </div>

      <div className={calloutRow({ gap: 0.5 })}>
        <Text size={12} fontWeight={400} color='gray-600'>
          {`${t(
            'admin:pages.payment.subscription.cards.subscription.description.scheduledToDiscard.cancel',
          )}`}
        </Text>

        {!hideButton && (
          <PlainButton
            onClick={() => {
              onClick();
            }}
          >
            <Text size={12} color='red-500'>
              {t('admin:pages.payment.subscription.cards.subscription.button.cancelToDiscard')}
            </Text>
          </PlainButton>
        )}
      </div>
    </div>
  );
}

function PlanChangePreview({
  organizationData,
  previousPlan,
  onClick,
  hideButton,
}: {
  organizationData: Space;
  previousPlan: string;
  onClick: () => void;
  hideButton: boolean;
}) {
  return (
    <div className={callout}>
      <div className={calloutRow({ gap: 0 })}>
        <Text size={16} fontWeight={600}>
          {t(
            'admin:pages.payment.subscription.cards.subscription.description.scheduledToReplacePlan.preview.0',
            {
              plan: previousPlan,
            },
          )}
        </Text>
        <Text size={16} fontWeight={600}>
          {t(
            'admin:pages.payment.subscription.cards.subscription.description.scheduledToReplacePlan.preview.1',
          )}
        </Text>
        <Text className={textMarginLeft} size={16} fontWeight={600}>
          {t(
            'admin:pages.payment.subscription.cards.subscription.description.scheduledToReplacePlan.preview.0',
            {
              plan: organizationData?.subscription?.nextSubscription?.type
                ? PLANS[organizationData?.subscription?.nextSubscription?.type].name.kor
                : '',
            },
          )}
        </Text>
        <Text size={16} fontWeight={500}>
          {t(
            'admin:pages.payment.subscription.cards.subscription.description.scheduledToReplacePlan.preview.3',
          )}
        </Text>
      </div>

      <div className={calloutRow({ gap: 0.5 })}>
        <Text size={12} fontWeight={400} color='gray-600'>
          {t(
            'admin:pages.payment.subscription.cards.subscription.description.scheduledToReplacePlan.replaceAt',
            {
              date: moment(organizationData?.subscription?.nextSubscription?.date)
                .tz('Asia/Seoul')
                .format('YYYY년 M월 D일'),
            },
          )}
        </Text>

        {!hideButton && (
          <PlainButton onClick={() => onClick()}>
            <Text size={12} color='red-500'>
              {t('admin:pages.payment.subscription.cards.subscription.button.cancelToReplace')}
            </Text>
          </PlainButton>
        )}
      </div>
    </div>
  );
}
