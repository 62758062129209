import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';
import { Spinner } from '@pickme/ui';

import MessageModal from 'components/features/modal/MessageModal';
import CreateOrganizationModal from 'components/features/modal/CreateOrganizationModal';
import PollActionModal from 'components/features/modal/PollActionModal';
import FaceMessageModal from 'components/features/modal/FaceMessageModal';
import UserRemovalModal from 'components/features/modal/UserRemovalModal';
import Toast from 'components/common/Toast';

import Navigator from './Navigator';
import NavigatorV2 from './NavigatorV2';

import 'styles/global.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 2 * 60 * 1000,
      cacheTime: 2 * 60 * 1000,
      retry: (failureCount, error: any) => {
        // organization-oid가 필요하지만, header에 포함 하지 않은 경우.
        if (error?.code === 'NEED_ORGANIZATION_OID') {
          return failureCount <= 2;
        }

        return false;
      },
      retryDelay: 100,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <Suspense fallback={<Fallback />}>
          {import.meta.env.VITE_USE_V2 ? <NavigatorV2 /> : <Navigator />}
        </Suspense>
        <MessageModal />
        <Toast />
        <CreateOrganizationModal />
        <PollActionModal />
        <FaceMessageModal />
        <UserRemovalModal />
      </RecoilRoot>
    </QueryClientProvider>
  );
}

function Fallback() {
  return (
    <main className='auth-layout'>
      <Spinner />
    </main>
  );
}

export default App;
