import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

export function useResetRelatedQueries(organizationId: string) {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (organizationId) {
      queryClient.clear();
    }
  }, [organizationId]);
}
