import moment from 'moment-timezone';
import { ModalBody, Text } from '@pickme/ui';
import { ChargeTransaction } from '@pickme/core';
import { t } from 'i18next';

import IncomingDetailRow from 'components/features/payment/modal/IncomingDetail/IncomingDetailRow';
import PriceViewer from 'components/features/payment/modal/IncomingDetail/PriceViewer';

import { parseChargeTransactionToBadge } from 'functions/payment';

const setIncomingRows = (chargeTransaction: ChargeTransaction) => {
  const items = [
    {
      title: t('admin:components.features.payment.modal.incomingDetail.chargeTransaction.number'),
      content: chargeTransaction._id || '',
    },
    {
      title: t('admin:components.features.payment.modal.incomingDetail.chargeTransaction.date'),
      content: chargeTransaction.createdAt
        ? moment(chargeTransaction.createdAt).tz('Asia/Seoul').format('YY/MM/DD HH:mm')
        : '',
    },
    {
      title: t('admin:components.features.payment.modal.incomingDetail.chargeTransaction.method'),
      content: chargeTransaction.details.methodName || '',
    },
  ];

  if (chargeTransaction.details.method === 'virtualAccount') {
    items.push(
      {
        title: t('admin:components.features.payment.modal.incomingDetail.chargeTransaction.bank'),
        content: chargeTransaction.details.bankName || '',
      },
      {
        title: t(
          'admin:components.features.payment.modal.incomingDetail.chargeTransaction.account',
        ),
        content: `${chargeTransaction.details.number}(${t(
          'admin:components.features.payment.modal.incomingDetail.chargeTransaction.depositor',
        )})`,
      },
      {
        title: t('admin:components.features.payment.modal.incomingDetail.chargeTransaction.due'),
        content: `${
          chargeTransaction.details.dueDate
            ? moment(chargeTransaction.details.dueDate).tz('Asia/Seoul').format('YY/MM/DD HH:mm')
            : ''
        }`,
      },
    );
  }

  return items;
};

function ChargeTransactionViewer({ chargeTransaction }: { chargeTransaction: ChargeTransaction }) {
  return (
    <ModalBody>
      <Text
        type='b2'
        fontWeight={500}
        color={
          (chargeTransaction.status === 'completed' && 'primary-700') ||
          (chargeTransaction.status === 'canceled' && 'error-700') ||
          'black'
        }
      >
        {chargeTransaction ? parseChargeTransactionToBadge(chargeTransaction).text : ''}
      </Text>

      <hr />

      <div className='incoming-detail-modal__section'>
        {setIncomingRows(chargeTransaction).map((item) => (
          <IncomingDetailRow title={item.title} content={item.content} key={item.title} />
        ))}
      </div>

      <hr />

      <PriceViewer amount={chargeTransaction.amount} />
    </ModalBody>
  );
}

export default ChargeTransactionViewer;
