import { useEffect, useState } from 'react';

import MainMenu from 'components/common/layout/Main/Sidebar/Menu/MainMenu';
import SubMenu from 'components/common/layout/Main/Sidebar/Menu/SubMenu';

import { useSidebar } from 'hooks/useSidebar';

import { NavAccordionMenuItem } from 'types/menu';

import { container } from './index.css';

type Props = {
  items: NavAccordionMenuItem[];
};

function Menu({ items }: Props) {
  const [openedMenu, setOpenedMenu] = useState<string | null>(null);

  const sidebar = useSidebar();
  useEffect(() => {
    if (sidebar?.main !== openedMenu) {
      setOpenedMenu(sidebar?.main ?? null);
    }
  }, [sidebar?.main]);

  return (
    <ul className={container}>
      {items.map(({ title, icon, link, sub, linkType }) => (
        <li key={`sidebar-${title}`}>
          <MainMenu
            title={title}
            icon={icon}
            link={link}
            linkType={linkType}
            alternativeUrl={sub?.[0].link}
            expandable={(sub && sub.length > 0) ?? false}
            expanded={openedMenu === title}
            onToggle={(toggled) => {
              setOpenedMenu(toggled ? title : null);
            }}
          />

          {sub && <SubMenu isVisible={openedMenu === title} subMenuList={sub} />}
        </li>
      ))}
    </ul>
  );
}

export default Menu;
