/* eslint-disable jsx-a11y/no-autofocus */
import { FormEvent, useEffect, useState } from 'react';
import { Spinner } from '@pickme/ui';
import { Text, Button, PlainButton } from '@pickme/design-system';
import { useSetRecoilState } from 'recoil';
import { t } from 'i18next';

import { useSendOTPByEmail, useVerifyEmail } from 'query-hooks/otp';

import useDigitInput from 'hooks/useDigitInput';

import { messageModal } from 'states/modal';

import { submitButton } from 'pages/auth/common.css';
import { emailStyle, otpStyle, retry } from './index.css';

type Props = {
  email: string;
  onOTPVerify: (code: string) => void;
};

function SignUpOTPForm({ email, onOTPVerify }: Props) {
  const setMessageModal = useSetRecoilState(messageModal);
  const [otp, setOtp] = useState('');
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 6,
    value: otp,
    onChange: setOtp,
  });

  const { isSuccess: isSendSuccess, mutate: sendMailMutate } = useSendOTPByEmail();
  const {
    isLoading,
    isSuccess: isVerifySuccess,
    isError: isVerifyFailure,
    mutate: verifyMutate,
    data,
  } = useVerifyEmail();

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoading) {
      return;
    }

    verifyMutate({
      email,
      code: otp,
    });
  };

  const onResendButtonClick = () => {
    sendMailMutate(email);
  };

  useEffect(() => {
    if (isSendSuccess) {
      setMessageModal({
        isVisible: true,
        title: t('admin:components.features.auth.form.otp.modal.retry.title'),
        message: t('admin:components.features.auth.form.otp.modal.retry.message', {
          email,
        }),
      });
    }
  }, [isSendSuccess]);

  useEffect(() => {
    if (isVerifySuccess && data?.token) {
      onOTPVerify(data?.token);
    }
  }, [isVerifySuccess, data?.token]);

  return (
    <form onSubmit={onSubmit}>
      <Text size={24} fontWeight={500} className={emailStyle}>
        {email}
      </Text>

      <div className={otpStyle.container}>
        <div className={otpStyle.inputs}>
          {digits.map((digit, index) => (
            <input
              key={index}
              className={otpStyle.input({ isInvalid: isVerifyFailure })}
              autoFocus={index === 0}
              inputMode='decimal'
              {...digit}
            />
          ))}
        </div>

        {isVerifyFailure && (
          <Text size={12} color='red-400' className={otpStyle.error}>
            올바른 인증번호를 입력해 주세요
          </Text>
        )}
      </div>

      <Button
        fullWidth
        size='xl'
        variant='primary'
        type='submit'
        disabled={!/^[0-9]{6}$/gi.test(otp) || isLoading}
        className={submitButton}
      >
        {isLoading ? <Spinner /> : '인증하기'}
      </Button>

      <div className={retry.container}>
        <Text fontWeight={400} color='gray-600'>
          인증번호를 받지 못하셨나요?
        </Text>

        <PlainButton
          onClick={() => {
            onResendButtonClick();
          }}
        >
          <Text className={retry.underlined} fontWeight={400} color='primary-400'>
            다시 시도해 주세요
          </Text>
        </PlainButton>
      </div>
    </form>
  );
}

export default SignUpOTPForm;
