import { AccountResponse, Plan, TransferTransactionResponse } from '@pickme/core';

import { createHttpRequest } from 'apis/common';

import {
  Incoming,
  ChargeCancelForm,
  PaymentInfo,
  PaymentRecord,
  SubscriptionHistory,
  Usage,
} from 'types/payment';
import { Apis } from 'types/api';

const APIS: Apis = {
  getAccount: { method: 'GET', url: '/api/account' },
  getUsages: { method: 'GET', url: '/api/account/transaction' },
  getIncoming: { method: 'GET', url: '/api/account/transaction/:transactionId' },
  getIncomings: { method: 'GET', url: '/api/account/transaction' },
  getOrderId: { method: 'POST', url: '/api/payment/common' },
  createPurchasePoint: { method: 'POST', url: '/api/payment/common/confirm' },
  cancelCharge: { method: 'POST', url: '/api/payment/common/:paymentOid/cancel' },
  transferPoint: { method: 'POST', url: '/api/account/transfer' },
  createSubscriptionPoint: { method: 'POST', url: '/api/admin/card' },
  getPayment: { method: 'GET', url: '/api/admin/payment' },
  getPaymentRecords: { method: 'GET', url: '/api/payment' },
  getPaymentRecord: { method: 'GET', url: '/api/payment/:id' },
  replacePlan: { method: 'PUT', url: '/api/subscription/plan/reservation' },
  cancelReplacePlan: { method: 'PUT', url: '/api/subscription/plan/reservation/cancel' },
  replaceEmail: { method: 'PUT', url: '/api/admin/card/receiptEmail' },
  replaceCard: { method: 'PUT', url: '/api/admin/card' },
  purchaseMoreVoters: { method: 'POST', url: '/api/subscription/:spaceId/voter-limit/purchase' },
  cancelReplaceVoterLimit: {
    method: 'PUT',
    url: '/api/subscription/voter-limit/reservation/cancel',
  },
  getSubscriptionHistory: { method: 'GET', url: '/api/subscription/history' },
};

export const createGetAccountRequest = () =>
  createHttpRequest<AccountResponse>({ ...APIS.getAccount });

export const createGetIncomingRequest = (transactionId: string) =>
  createHttpRequest<Incoming>({ ...APIS.getIncoming, params: { transactionId } });

export const createGetUsagesRequest = (page: number) =>
  createHttpRequest<{ endPage: number; transactions: Usage[] }>({
    ...APIS.getUsages,
    query: { page, size: 10, group: 'debit' },
  });

export const createGetIncomingsRequest = (page: number) =>
  createHttpRequest<{ endPage: number; transactions: Incoming[] }>({
    ...APIS.getIncomings,
    query: { page, size: 10, group: 'credit' },
  });

export const createGetOrderId = () =>
  createHttpRequest<{ orderId: string }>({ ...APIS.getOrderId });

export const createPurchasePointRequest = (
  orderId: string,
  paymentKey: string,
  amount: number,
  point: number,
) =>
  createHttpRequest<{ message: 'ok' }>({
    ...APIS.createPurchasePoint,
    data: {
      orderId,
      paymentKey,
      amount,
      product: {
        type: 'point',
        amount: point,
      },
    },
  });

export const createCancelChargeRequest = (form: ChargeCancelForm) => {
  const body: {
    reason: string;
    refundAccount?: {
      bank?: string;
      accountNumber?: string;
      holderName?: string;
    };
  } = {
    reason: form.reason || '',
  };
  if (form.bank && form.accountNumber && form.holderName) {
    body.refundAccount = {
      bank: form.bank,
      accountNumber: form.accountNumber,
      holderName: form.holderName,
    };
  }
  return createHttpRequest<{ message: 'ok' }>({
    ...APIS.cancelCharge,
    data: body,
    params: { paymentOid: form.paymentOid },
  });
};

export const createTransferPointRequest = ({
  adminId,
  amount,
}: {
  adminId: string;
  amount: number;
}) => {
  const body = {
    __comment: 'receiverId: admin.id',
    receiverId: adminId,
    amount,
  };

  return createHttpRequest<TransferTransactionResponse<'sent'>>({
    ...APIS.transferPoint,
    data: body,
  });
};

export const createGetPaymentRequest = () => createHttpRequest<PaymentInfo>({ ...APIS.getPayment });

export const createGetPaymentRecordsRequest = (page: number) =>
  createHttpRequest<{ payments: PaymentRecord[]; endPage: number }>({
    ...APIS.getPaymentRecords,
    query: { page, size: 10, productType: 'subscription' },
  });

export const createGetPaymentRecordRequest = (id: string) =>
  createHttpRequest<PaymentRecord>({
    ...APIS.getPaymentRecord,
    params: { id },
  });

export const createReplacePlanRequest = (plan: Plan) =>
  createHttpRequest({
    ...APIS.replacePlan,
    data: { planType: plan },
  });

export const createCancelPlanReplacementRequest = () =>
  createHttpRequest({
    ...APIS.cancelReplacePlan,
  });

export const createReplacePaymentEmailRequest = (email: string) =>
  createHttpRequest({
    ...APIS.replaceEmail,
    data: { email },
  });

export const createReplaceCardEmailRequest = (authKey: string) =>
  createHttpRequest({
    ...APIS.replaceCard,
    data: { authKey },
  });

export const createPurchaseMoreVotersRequest = (spaceId: string, voterLimit: number) =>
  createHttpRequest({
    ...APIS.purchaseMoreVoters,
    params: { spaceId },
    data: { number: voterLimit },
  });

export const createCancelReplaceVoterLimitRequest = () =>
  createHttpRequest({
    ...APIS.cancelReplaceVoterLimit,
  });

export const createGetSubscriptionHistoryRequest = () =>
  createHttpRequest<SubscriptionHistory[]>({
    ...APIS.getSubscriptionHistory,
  });
