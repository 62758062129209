import SubPollCard from 'components/features/poll/PollDetail/SubPollCard';
import CandidateCard from 'components/features/poll/PollDetail/CandidateCard';

import { makeElectionBadges } from 'functions/poll';

import { ELECTION_KIND_BADGES } from 'constants/poll';

import { SubElection } from 'types/poll';

import './index.scss';

type Props = {
  election: SubElection;
};

function ElectionCard({ election }: Props) {
  const badges: { variant: string; text: string }[] = makeElectionBadges(election);

  const memberImages: string[] =
    election?.candidates?.map(({ members }) => members?.[0]?.fileUrl || '') || [];

  return (
    <SubPollCard
      kindBadge={{
        variant: ELECTION_KIND_BADGES[election?.kind].variant,
        text: ELECTION_KIND_BADGES[election?.kind].text,
      }}
      title={election?.title}
      badges={badges}
      avatars={memberImages}
    >
      <div className='poll-detail-election-card__candidates'>
        {election?.candidates?.map((candidate) => (
          <CandidateCard key={candidate._id} candidate={candidate} />
        ))}
      </div>
    </SubPollCard>
  );
}

export default ElectionCard;
