import { Outlet } from 'react-router-dom';

import Navbar from 'components/common/layout/SpaceSelector/Navbar';

import './index.scss';

function SpaceSelectorLayout() {
  return (
    <>
      <meta
        name='viewport'
        content='initial-scale=1, minimum-scale=1.0, maximum-scale=3.0, width=device-width, height=device-height, user-scalable=no'
      />
      <div className='space-selector-layout'>
        <Navbar />

        <main className='space-selector-layout__main'>
          <Outlet />
        </main>
      </div>
    </>
  );
}

export default SpaceSelectorLayout;
