import { Text, Radio } from '@pickme/design-system';
import { useFormContext, Controller } from 'react-hook-form';
import { t } from 'i18next';
import { isEqual } from 'lodash-es';

import { AdditionalFormBody, VoterAuthMethod } from 'types/application';

import { container, row } from './index.css';

type Props = {
  readyOnly?: boolean;
  disabled?: boolean;
};

function VoterAuthMethodsField({ readyOnly, disabled }: Props) {
  const methods = useFormContext<AdditionalFormBody>();

  return (
    <div className={container}>
      <Text size={16} fontWeight={600} color='gray-800'>
        {t('admin:components.features.application.form.additional.fields.voterAuthMethods.title')}
      </Text>

      <Controller
        control={methods.control}
        name='voterAuthMethods'
        disabled={disabled}
        render={({ field }) => (
          <div className={row}>
            <Radio
              {...field}
              readOnly={readyOnly}
              onChange={() => field.onChange([VoterAuthMethod.Phone])}
              checked={isEqual(field.value, [VoterAuthMethod.Phone])}
            >
              {t(
                'admin:components.features.application.form.additional.fields.voterAuthMethods.items.phoneNumber',
              )}
            </Radio>
            <Radio
              {...field}
              readOnly={readyOnly}
              onChange={() => field.onChange([VoterAuthMethod.Email])}
              checked={isEqual(field.value, [VoterAuthMethod.Email])}
            >
              {t(
                'admin:components.features.application.form.additional.fields.voterAuthMethods.items.email',
              )}
            </Radio>
            <Radio
              {...field}
              readOnly={readyOnly}
              onChange={() => field.onChange([VoterAuthMethod.Email, VoterAuthMethod.Phone])}
              checked={
                !field.value ||
                (field.value.includes(VoterAuthMethod.Email) &&
                  field.value.includes(VoterAuthMethod.Phone))
              }
            >
              {t(
                'admin:components.features.application.form.additional.fields.voterAuthMethods.items.all',
              )}
            </Radio>
          </div>
        )}
      />
    </div>
  );
}

export default VoterAuthMethodsField;
