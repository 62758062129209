export default {
  title: '카카오 채널 연동',
  field: {
    id: {
      label: '검색용 아이디',
      tooltip: '비즈니스 인증이 필수이며 카카오 채널 관리자 센터에서 인증받을 수 있어요',
      placeholder: '카카오 플러스 친구 검색용 아이디를 입력해주세요',
      validate: '아이디를 입력해주세요',
    },
    phone: {
      label: '관리자 전화번호',
      tooltip: '인증번호 수신이 가능한 관리자의 연락처를 입력해주세요',
      placeholder: '관리자 전화번호를 입력해주세요',
      validate: '전화번호를 확인해주세요',
    },
    category: {
      label: '사업자 카테고리',
      placeholder: '분류',
      validate: '카테고리를 선택해 주세요',
    },
  },
  buttons: {
    cancel: '취소',
    submit: '신청하기',
  },

  verify: {
    title: '인증번호 입력',
    label: '관리자 연락처로 전송된 인증번호를 입력해주세요',
    placeholder: '인증번호를 입력해주세요',
    buttons: {
      cancel: '취소',
      submit: '인증하기',
    },
  },
};
