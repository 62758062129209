export default {
  main: {
    dashboard: {
      title: '대시보드',
    },
    election: {
      title: '선거',
      sub: {
        application: '선거 만들기',
        dashboard: '선거 대시보드',
      },
    },
    survey: {
      title: '설문',
      sub: {
        application: '설문 만들기',
        dashboard: '설문 대시보드',
      },
    },
    notification: {
      title: '메시지',
      sub: {
        point: '포인트 충전',
        send: '알림톡',
      },
    },
    settings: {
      title: '설정',
      sub: {
        space: '스페이스 관리',
        manager: '매니저 관리',
        poll: '투표 관리',
        integration: '연동',
        payment: '결제',
        resources: '리소스',
      },
    },
  },
  system: {
    feedback: { title: '피드백 보내기' },
    help: { title: '헬프센터' },
  },
  myPage: '마이페이지',
};
