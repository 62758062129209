import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { t } from 'i18next';

import OTPVerificationForm from 'components/features/auth/form/OTPVerification';
import SendRegisterMailForm from 'components/features/auth/form/SendRegisterMail';

import { useSendOTPByEmail } from 'query-hooks/otp';
import { useReplaceUserEmail } from 'query-hooks/user';

import { messageModal } from 'states/modal';

function ResetPassword() {
  const setMessageModal = useSetRecoilState(messageModal);
  const [step, setStep] = useState<'email' | 'verify'>('email');

  const emailSend = useSendOTPByEmail();
  const replaceEmail = useReplaceUserEmail();

  useEffect(() => {
    if (emailSend.isSuccess) {
      setStep('verify');
    }
  }, [emailSend.isSuccess]);

  const resendEmail = () => {
    if (emailSend.isLoading) {
      return;
    }

    if (emailSend.data?.email) {
      emailSend.mutate(emailSend.data?.email, {
        onSuccess: () => {
          setMessageModal({
            isVisible: true,
            title: t('admin:pages.auth.otp.email.resend.title'),
            message: t('admin:pages.auth.otp.email.resend.message', {
              email: emailSend.data?.email,
            }),
          });
        },
      });
    }
  };

  return (
    <div className='auth'>
      {step === 'email' && (
        <SendRegisterMailForm
          isLoading={emailSend.isLoading}
          sendMail={(email) => {
            emailSend.mutate(email);
          }}
        />
      )}

      {step === 'verify' && (
        <OTPVerificationForm
          type='email'
          isLoading={replaceEmail.isLoading}
          target={emailSend.data?.email || ''}
          resend={() => resendEmail()}
          onSubmitCode={(code) => {
            if (emailSend.data?.email) {
              replaceEmail.mutate({ email: emailSend.data?.email, otp: code, type: 'register' });
            }
          }}
        />
      )}
    </div>
  );
}

export default ResetPassword;
