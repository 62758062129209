export default {
  kakao: {
    tabs: ['채널 연동'],
  },
  items: {
    kakao: {
      title: '카카오 알림톡',
      description: [
        '카카오 비즈니스 계정을 연동하여 알림톡 발신 프로필을 설정해보세요.',
        '비즈니스 인증을 받은 카카오 계정만 연동 가능해요.',
      ],
    },
    email: {
      title: '이메일',
      description: [
        '이메일 계정을 연동하여 메일 발신 주소를 설정해보세요.',
        '투표 관련 안내 메일을 픽미 공식 메일이 아닌 연결된 이메일 주소로 보낼 수 있어요.',
      ],
    },
    slack: {
      title: '슬랙',
      description: [
        '슬랙을 연동하여 다양한 알림을 받아보세요.',
        '실시간 투표율, 개표 결과를 포함한 픽미의 다양한 알림을 확인할 수 있어요.',
      ],
    },
  },
};
