import { ManagerRoleTitleV2, PlanTitleV2 } from '@pickme/core';

import {
  FeatureAction,
  RoleToActionMap,
  FeatureTitleV2,
  SubscriptionAction,
  PlanToFeatureMap,
  MessagingAction,
  ManagerAction,
  VoterSeatAction,
  IntegrationAction,
  CollaboratorAction,
} from 'types/features-v2';

const ROLE_TO_FEATURE_MAP: RoleToActionMap = {
  [FeatureTitleV2.구독및결제]: {
    [ManagerRoleTitleV2.Admin]: Object.values(SubscriptionAction),
    [ManagerRoleTitleV2.Editor]: Object.values(SubscriptionAction),
    [ManagerRoleTitleV2.Viewer]: Object.values(SubscriptionAction),
  },
  [FeatureTitleV2.메시지]: {
    [ManagerRoleTitleV2.Admin]: Object.values(MessagingAction),
    [ManagerRoleTitleV2.Editor]: Object.values(MessagingAction),
    [ManagerRoleTitleV2.Viewer]: Object.values(MessagingAction),
  },
  [FeatureTitleV2.매니저]: {
    [ManagerRoleTitleV2.Admin]: Object.values(ManagerAction),
    [ManagerRoleTitleV2.Editor]: Object.values(ManagerAction),
    [ManagerRoleTitleV2.Viewer]: Object.values(ManagerAction),
  },
  [FeatureTitleV2.참여자명부인증]: {
    [ManagerRoleTitleV2.Admin]: Object.values(VoterSeatAction),
    [ManagerRoleTitleV2.Editor]: Object.values(VoterSeatAction),
    [ManagerRoleTitleV2.Viewer]: Object.values(VoterSeatAction),
  },
  [FeatureTitleV2.연동]: {
    [ManagerRoleTitleV2.Admin]: Object.values(IntegrationAction),
    [ManagerRoleTitleV2.Editor]: Object.values(IntegrationAction),
    [ManagerRoleTitleV2.Viewer]: Object.values(IntegrationAction),
  },
  [FeatureTitleV2.공동작업자]: {
    [ManagerRoleTitleV2.Admin]: Object.values(CollaboratorAction),
    [ManagerRoleTitleV2.Editor]: Object.values(CollaboratorAction),
    [ManagerRoleTitleV2.Viewer]: Object.values(CollaboratorAction),
  },
};

const PLAN_TO_FEATURE_MAP: PlanToFeatureMap = {
  [FeatureTitleV2.구독및결제]: {
    [PlanTitleV2.Basic]: Object.values(SubscriptionAction),
    [PlanTitleV2.Pro]: Object.values(SubscriptionAction),
    [PlanTitleV2.Custom]: Object.values(SubscriptionAction),
  },
  [FeatureTitleV2.메시지]: {
    [PlanTitleV2.Basic]: [],
    [PlanTitleV2.Pro]: Object.values(MessagingAction),
    [PlanTitleV2.Custom]: Object.values(MessagingAction),
  },
  [FeatureTitleV2.매니저]: {
    [PlanTitleV2.Basic]: [],
    [PlanTitleV2.Pro]: Object.values(ManagerAction),
    [PlanTitleV2.Custom]: Object.values(ManagerAction),
  },
  [FeatureTitleV2.참여자명부인증]: {
    [PlanTitleV2.Basic]: [],
    [PlanTitleV2.Pro]: Object.values(VoterSeatAction),
    [PlanTitleV2.Custom]: [],
  },
  [FeatureTitleV2.연동]: {
    [PlanTitleV2.Basic]: [],
    [PlanTitleV2.Pro]: Object.values(IntegrationAction),
    [PlanTitleV2.Custom]: Object.values(IntegrationAction),
  },
  [FeatureTitleV2.공동작업자]: {
    [PlanTitleV2.Basic]: [],
    [PlanTitleV2.Pro]: Object.values(CollaboratorAction),
    [PlanTitleV2.Custom]: Object.values(CollaboratorAction),
  },
};

export class Permissions {
  static isRoleAllowedToAction<T extends FeatureTitleV2>({
    role,
    featureName,
    actionName,
  }: {
    role: ManagerRoleTitleV2;
    featureName: T;
    actionName: FeatureAction[T];
  }): boolean {
    const actions = ROLE_TO_FEATURE_MAP[featureName][role] as FeatureAction[T][];
    return actions ? actions.includes(actionName) : false;
  }

  static isPlanAllowedToFeature<T extends FeatureTitleV2>({
    plan,
    featureName,
    actionName,
  }: {
    plan: PlanTitleV2;
    featureName: T;
    actionName: FeatureAction[T];
  }): boolean {
    const actions = PLAN_TO_FEATURE_MAP[featureName][plan] as FeatureAction[T][];
    return actions ? actions.includes(actionName) : false;
  }
}
