import { useGetDrafts } from 'query-hooks/draft';

import DividerIcon from 'resources/icons/draft/divider.svg';

import { container, divider, modalButton, saveButton } from './index.css';

type Props = {
  onSaveButtonClick: () => void;
  onDraftModalButtonClick: () => void;
};

function DraftButton({ onSaveButtonClick, onDraftModalButtonClick }: Props) {
  const { data } = useGetDrafts('all', 1);
  const totalCount = data?.total || 0;

  return (
    <div className={container}>
      <button
        className={saveButton}
        type='button'
        onClick={() => {
          onSaveButtonClick();
        }}
      >
        임시저장
      </button>

      <img className={divider} src={DividerIcon} alt='divider' />

      <button
        className={modalButton}
        type='button'
        onClick={() => {
          onDraftModalButtonClick();
        }}
      >
        {totalCount}
      </button>
    </div>
  );
}

export default DraftButton;
