import { InputHTMLAttributes, Ref, forwardRef, useImperativeHandle, useRef } from 'react';
import { Text } from '@pickme/design-system';

import { inputContainer, inputBody, input } from './index.css';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  labels: {
    title: string;
    unit: string;
  };
  isValid?: boolean;
  errorMessage?: string;
};

function Input({ isValid, errorMessage, labels, ...props }: Props, ref: Ref<HTMLInputElement>) {
  const inputRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => inputRef.current!);

  return (
    <div>
      <div
        className={inputContainer({
          isValid,
          disabled: props.disabled,
        })}
        role='button'
        onClick={() => {
          inputRef.current?.focus();
        }}
        onKeyDown={() => {
          inputRef.current?.focus();
        }}
        tabIndex={0}
      >
        <Text size={14} fontWeight={500} color='gray-800'>
          {labels.title}
        </Text>

        <div className={inputBody}>
          <input {...props} ref={inputRef} className={input} />
          <Text size={14} fontWeight={500} color='gray-800'>
            {labels.unit}
          </Text>
        </div>
      </div>

      {errorMessage && (
        <Text size={12} fontWeight={400} color='red-500'>
          {errorMessage}
        </Text>
      )}
    </div>
  );
}

const LabelledBoxInput = forwardRef(Input);
export default LabelledBoxInput;
