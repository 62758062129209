import { Text } from '@pickme/design-system';

import ProfileImage from 'resources/icons/notification/profile.png';

import { container, image } from './index.css';

type Props = {
  sender: string;
};

function Sender({ sender }: Props) {
  return (
    <div className={container}>
      <img src={ProfileImage} alt='profile' className={image} />
      <Text size={16} fontWeight={600} color='gray-800'>
        {sender}
      </Text>
    </div>
  );
}

export default Sender;
