import { Checkbox, Text } from '@pickme/ui';
import { t } from 'i18next';

import NameIcon from 'resources/icons/auth/name.png';
import IdIcon from 'resources/icons/application/id.png';
import PhoneIcon from 'resources/icons/application/phone.png';
import EmailIcon from 'resources/icons/auth/email.png';
import CategoryIcon from 'resources/icons/application/category.png';
import ShareIcon from 'resources/icons/application/share.png';

import './index.scss';

function VoterFormHeader({ onCheck }: { onCheck: (checked: boolean) => void }) {
  return (
    <div className='voter-form__voters__header'>
      <div className='voter-form__voters__header__number voter-form__voters__number'>
        <Checkbox
          checked={false}
          onChange={(event) => {
            onCheck(event.target.checked);
          }}
        />
      </div>

      <div className='voter-form__voters__header__name voter-form__voters__name'>
        <img src={NameIcon} alt='name' className='voter-form__voters__header__icon' />
        <Text size='sm2'>
          {t('admin:components.features.application.form.voter.table.header.name')}
        </Text>
      </div>

      <div className='voter-form__voters__header__id voter-form__voters__id'>
        <img src={IdIcon} alt='id' className='voter-form__voters__header__icon' />
        <Text size='sm2'>
          {t('admin:components.features.application.form.voter.table.header.id')}
        </Text>
      </div>

      <div className='voter-form__voters__header__phone voter-form__voters__phone'>
        <img src={PhoneIcon} alt='phone' className='voter-form__voters__header__icon' />
        <Text size='sm2'>
          {t('admin:components.features.application.form.voter.table.header.phone')}
        </Text>
      </div>

      <div className='voter-form__voters__header__email voter-form__voters__email'>
        <img src={EmailIcon} alt='email' className='voter-form__voters__header__icon' />
        <Text size='sm2'>
          {t('admin:components.features.application.form.voter.table.header.email')}
        </Text>
      </div>

      <div className='voter-form__voters__header__category voter-form__voters__category'>
        <img src={CategoryIcon} alt='category' className='voter-form__voters__header__icon' />
        <Text size='sm2'>
          {t('admin:components.features.application.form.voter.table.header.category')}
        </Text>
      </div>

      <div className='voter-form__voters__header__open voter-form__voters__open'>
        <img src={ShareIcon} alt='share' className='voter-form__voters__header__icon' />
        <Text size='sm2'>
          {t('admin:components.features.application.form.voter.table.header.share')}
        </Text>
      </div>
    </div>
  );
}

export default VoterFormHeader;
