import { isUndefined } from 'lodash-es';
import { useState } from 'react';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { Button } from '@pickme/ui';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { t } from 'i18next';

import FieldTitleWithButton from 'components/features/application/label/FieldTitleWithButton';
import SubElectionForm from 'components/features/application/form/SubElections/SubElection';
import PollCard from 'components/features/application/card/SubElection';
import ElectionKindSelect from 'components/features/application/modal/ElectionKindSelect';

import { usePollStarted } from 'hooks/poll/usePollData';

import { toast } from 'states/toast';

import { ElectionKind, SubElectionFormBody, SubElectionsFormBody } from 'types/application';

type Props = {
  onSubmit: (data: SubElectionsFormBody) => void;
};

function SubElectionsForm({ onSubmit }: Props) {
  const [isElectionKindSelectorVisible, setElectionKindSelectorVisible] = useState(false);

  const isPollStarted = usePollStarted();
  const setToast = useSetRecoilState(toast);
  const methods = useFormContext<SubElectionsFormBody>();
  const [electionForm, setElectionForm] = useState<{
    isVisible: boolean;
    selectedIndex?: number;
    election?: SubElectionFormBody;
  }>({ isVisible: false });

  const { fields, append, remove, swap, update } = useFieldArray({
    control: methods.control,
    name: 'subElections',
    rules: {
      validate: (elections) => elections.length > 0,
    },
  });

  const onSelectElectionType = (kind: ElectionKind) => {
    setElectionForm({ isVisible: true });
    subElectionForm.reset({
      kind: 'ChoiceElection' as ElectionKind,
      title: '',
      shuffle: false,
      candidates: [],
      responseValidation: {
        allowAbstentionVote: false,
      },
    });
    subElectionForm.setValue('kind', kind);

    setElectionKindSelectorVisible(false);
  };

  const subElectionForm = useForm<SubElectionFormBody>({
    mode: 'onBlur',
    defaultValues: {
      kind: 'ChoiceElection' as ElectionKind,
      title: '',
      shuffle: false,
      candidates: [],
      responseValidation: {
        allowAbstentionVote: false,
      },
    },
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 8,
        distance: 8,
      },
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const selectedIndex = active?.data?.current?.sortable.index;
      const changedIndex = over?.data?.current?.sortable.index;

      swap(selectedIndex, changedIndex);
    }
  };

  const onError = () => {
    setToast({
      isVisible: true,
      type: 'error',
      message: t('admin:components.features.application.form.subElection.errorMessages.required'),
    });
  };

  return (
    <div>
      <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
        <div className='application-form'>
          <FieldTitleWithButton
            title={t('admin:components.features.application.form.subElection.title')}
            description={t('admin:components.features.application.form.subElection.description')}
            button={{
              text: t('admin:components.features.application.form.subElection.buttons.add'),
              disabled: isPollStarted,
              onClick: () => {
                setElectionKindSelectorVisible(true);
              },
            }}
          />

          <div className='application-form__poll-cards'>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={fields.map((field) => field.id)}
                strategy={verticalListSortingStrategy}
                disabled={isPollStarted}
              >
                {fields.map((field, index) => (
                  <PollCard
                    id={field.id}
                    key={field.id}
                    poll={field}
                    disabled={isPollStarted}
                    onRemove={() => remove(index)}
                    onClick={() => {
                      setElectionForm({
                        isVisible: true,
                        selectedIndex: index,
                        election: field,
                      });
                    }}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </div>

          <Button width='100%' size='lg' variant='primary' type='submit'>
            {t('admin:components.features.application.form.subElection.buttons.submit')}
          </Button>
        </div>
      </form>

      <ElectionKindSelect
        isVisible={isElectionKindSelectorVisible}
        onClose={() => setElectionKindSelectorVisible(false)}
        onSubmit={(kind) => onSelectElectionType(kind)}
      />

      {electionForm.isVisible && (
        <FormProvider {...subElectionForm}>
          <SubElectionForm
            initialValue={electionForm.election}
            onElectionSubmit={(election) => {
              if (isUndefined(electionForm?.selectedIndex)) {
                append(election);
              } else {
                update(electionForm.selectedIndex, election);
              }

              setElectionForm({ isVisible: false });
            }}
            onClose={() => setElectionForm({ isVisible: false })}
          />
        </FormProvider>
      )}
    </div>
  );
}

export default SubElectionsForm;
