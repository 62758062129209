export default {
  form: {
    fields: {
      phoneNumber: {
        label: '새로운 전화번호',
        placeholder: '새 전화번호를 입력해주세요',
      },
    },
  },
  buttons: {
    verified: '인증완료',
    verify: '인증하기',
  },
  messages: {
    sendOTP: '인증번호를 발송했어요 받으신 인증번호를 입력해주세요',
    phoneNumberFormat: '올바른 전화번호를 입력해주세요',
    duplicated: '이미 등록된 전화번호입니다',
  },
};
