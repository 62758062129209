export default {
  tabs: ['투표 기록', '개인정보 파기'],
  stats: {
    ready: '예정',
    ongoing: '진행',
    ended: '종료',
    counted: '개표',
    canceled: '무효',
  },
  election: {
    kind: {
      choice: '선택 투표',
      prosOrCons: '찬반 투표',
      rank: '순위 투표',
      score: '점수 투표',
    },
  },
  survey: {
    question: {
      kind: {
        radio: '객관식 질문 (단일 선택)',
        checkbox: '객관식 질문 (복수 선택)',
        text: '주관식 질문',
        rank: '선호도 평가 질문',
        score: '점수 평가 질문',
        scale: '선형 배율 질문',
      },
    },
  },
};
