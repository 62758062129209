import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useSetRecoilState } from 'recoil';
import axios from 'axios';

import { toast } from 'states/toast';

export const useExpiredSessionErrorInterceptor = () => {
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  useEffect(() => {
    const interceptors = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.code === 'AUTH-005') {
          const authData = queryClient.getQueryData(['auth', 'session', 'user']);
          if (authData) {
            queryClient.resetQueries('auth');

            setToast({
              isVisible: true,
              type: 'warning',
              message: error.message,
            });
          }
        }

        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.response.eject(interceptors);
    };
  }, []);
};
