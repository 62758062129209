import moment from 'moment-timezone';
import { useSetRecoilState } from 'recoil';
import { Button, Spinner, Text } from '@pickme/ui';
import { t } from 'i18next';

import { useGetTakingOverSpaceStatus, useTakeOverSpaceWithoutPayment } from 'query-hooks/space';
import { useGetOrganization } from 'query-hooks/organization';

import { useQueryString } from 'hooks/useQueryString';

import { messageModal } from 'states/modal';

import { registerBillingForTakingOverSpace } from 'functions/payment';

import './Accept.scss';

function OrganizationSelector() {
  const setMessageModal = useSetRecoilState(messageModal);
  const { transferRequestOid } = useQueryString<{ transferRequestOid: string }>({
    transferRequestOid: '',
  });
  const { data } = useGetTakingOverSpaceStatus(transferRequestOid);
  const withPaymentMethod = data?.isCardRegistrationRequired;
  const submitButtonText = withPaymentMethod
    ? t('admin:pages.organization.accept.submitButtonText.payment')
    : t('admin:pages.organization.accept.submitButtonText.default');

  const { data: organization } = useGetOrganization('objectId', data?.organizationOid || '');

  const takeOverSpaceWithoutPayment = useTakeOverSpaceWithoutPayment();
  const { isLoading } = takeOverSpaceWithoutPayment;

  const onClickSubmitButton = () => {
    if (isLoading || !data?.organizationOid) {
      return;
    }

    if (withPaymentMethod) {
      requestToRegisterBilling();
    } else {
      takeOverSpaceWithoutPayment.mutate({
        organizationOid: data?.organizationOid,
      });
    }
  };

  const items = [
    { title: t('admin:pages.organization.accept.items.space.title'), content: organization?.name },
    {
      title: t('admin:pages.organization.accept.items.plan.title'),
      content: data?.subscription?.planTypeName,
    },
  ];

  if (withPaymentMethod) {
    items.push(
      {
        title: t('admin:pages.organization.accept.items.contractType.title'),
        content: data?.subscription.contractTypeName,
      },
      {
        title: t('admin:pages.organization.accept.items.contractPeriod.title'),
        content: `${moment(data?.subscription.contractPeriod?.start)
          .tz('Asia/Seoul')
          .format('YYYY년 M월 D일')} ~ ${moment(data?.subscription.contractPeriod?.end)
          .tz('Asia/Seoul')
          .format('YYYY년 M월 D일')}`,
      },
      {
        title: t('admin:pages.organization.accept.items.nextPaymentDate.title'),
        content: moment(data?.subscription.nextSubscription?.date)
          .tz('Asia/Seoul')
          .format('YYYY년 M월 D일'),
      },
    );
  } else if (data?.subscription.type === 'enterprise') {
    items.push({
      title: t('admin:pages.organization.accept.items.contractPeriod.title'),
      content: `${moment(data?.subscription.contractPeriod?.start)
        .tz('Asia/Seoul')
        .format('YYYY년 M월 D일')} ~ ${moment(data?.subscription.contractPeriod?.end)
        .tz('Asia/Seoul')
        .format('YYYY년 M월 D일')}`,
    });
  }

  const requestToRegisterBilling = async () => {
    if (!withPaymentMethod) {
      return;
    }

    registerBillingForTakingOverSpace(
      {
        customerKey: data.customerKey,
        organizationOid: data.organizationOid,
      },
      (errorMessage) => {
        setMessageModal({
          isVisible: true,
          title: t('admin:pages.organization.accept.errorMessages.failed.title'),
          message: t('admin:pages.organization.accept.errorMessages.failed.message', {
            errorMessage: errorMessage || '잘못된 요청입니다.',
          }),
        });
      },
    );
  };

  return (
    <div className='accept-space'>
      <Text type='h3' fontWeight={600}>
        {t('admin:pages.organization.accept.title')}
      </Text>

      <div className='accept-space__card'>
        {items.map((item) => (
          <div className='accept-space__card__row'>
            <div className='accept-space__card__row__title'>
              <Text type='b1' fontWeight={500} color='gray-600'>
                {item.title}
              </Text>
            </div>

            <div className='accept-space__card__row__content'>
              <Text type='b2' fontWeight={400} color='gray-400'>
                {item.content}
              </Text>
            </div>
          </div>
        ))}

        <hr />

        {withPaymentMethod && (
          <Text type='b1' fontWeight={500} color='gray-600'>
            {t('admin:pages.organization.accept.messages.payment')}
          </Text>
        )}

        <Button size='md' disabled={isLoading} onClick={() => onClickSubmitButton()}>
          {isLoading ? <Spinner /> : submitButtonText}
        </Button>
      </div>
    </div>
  );
}

export default OrganizationSelector;
