import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { PollGroup } from '@pickme/core';
import { OutlineButton } from '@pickme/design-system';

import Spinner from 'components/common/Spinner';

import { useOrderResult } from 'query-hooks/poll-actions';

import { pollActionModal } from 'states/modal';

import { Poll } from 'types/poll';

import { rightSection } from 'components/features/home/common.css';

type Props = {
  poll: Poll;
};

function DynamicButtonByPollGroup({ poll }: Props) {
  const navigate = useNavigate();
  const setPollActionModal = useSetRecoilState(pollActionModal);
  const { mutate: orderResult, isLoading, isSuccess } = useOrderResult();

  const isElection = poll.kind === 'Election';

  const canNavigateToProgress = poll.group === PollGroup.Paused || poll.group === PollGroup.Ongoing;
  const canNavigateToResult =
    (isElection && poll.group === PollGroup.Counted) ||
    (!isElection && (poll.group === PollGroup.Ended || poll.group === PollGroup.Counted));
  const canCountPoll = isElection && poll.group === PollGroup.Ended;

  const navigateToPollViewer = () => {
    const formattedPollKind = poll.kind.toLowerCase();
    navigate(`/poll/${formattedPollKind}/${poll._id}`, { unstable_viewTransition: true });
  };

  useEffect(() => {
    if (isSuccess) {
      navigateToPollViewer();
    }
  }, [isSuccess]);

  return (
    <>
      {canNavigateToProgress && (
        <OutlineButton
          variant='gray'
          className={rightSection.progressButton}
          size='tiny'
          onClick={(e) => {
            e.stopPropagation();
            navigateToPollViewer();
          }}
        >
          현황보기
        </OutlineButton>
      )}

      {canNavigateToResult && (
        <OutlineButton
          variant='primary'
          className={rightSection.outlineButton}
          size='tiny'
          onClick={(e) => {
            e.stopPropagation();
            const isCountable = !isElection && poll.group !== PollGroup.Counted;

            if (isCountable) {
              orderResult({ id: poll._id, kind: poll.kind, showToast: false });
              return;
            }

            navigateToPollViewer();
          }}
        >
          {isLoading ? <Spinner size='sm' /> : '결과보기'}
        </OutlineButton>
      )}

      {canCountPoll && (
        <OutlineButton
          variant='success'
          className={rightSection.outlineButton}
          size='tiny'
          onClick={(e) => {
            e.stopPropagation();
            setPollActionModal({ id: poll._id, kind: poll.kind, isVisible: true, type: 'count' });
          }}
        >
          개표하기
        </OutlineButton>
      )}
    </>
  );
}

export default DynamicButtonByPollGroup;
