import { ReactNode } from 'react';

import Navbar from 'components/common/layout/Application/Navbar';
import StepNavbar from 'components/common/layout/Application/StepNavbar';

import './index.scss';

// Survey나, Draw에서의 변경을 대비해 제네릭 사용.
function ApplicationLayout<T>({
  title,
  onClose,
  hideStepNavbar,
  children,
  items,
  rightComponent,
  onSelect,
}: {
  title: string;
  onClose: () => void;
  hideStepNavbar?: boolean;
  children: ReactNode;
  items: { title: string; value: T; active?: boolean; selected?: boolean; disabled?: boolean }[];
  rightComponent?: ReactNode;
  onSelect: (step: T) => void;
}) {
  return (
    <>
      <meta name='viewport' content='width=1920, height=1080, user-scalable=no' />
      <main className='application-layout'>
        <Navbar title={title} onClose={onClose}>
          {rightComponent}
        </Navbar>

        {!hideStepNavbar && <StepNavbar<T> items={items} onSelect={onSelect} />}

        <div className='application-layout__body'>
          <main className='application-layout__main'>{children}</main>
        </div>
      </main>
    </>
  );
}

export default ApplicationLayout;
