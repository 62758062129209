import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlainButton, Text } from '@pickme/design-system';
import { Modal, ModalActions, ModalBody, ModalHeader } from '@pickme/ui';
import { t } from 'i18next';

import { PollKind } from 'types/poll';

import ElectionImage from 'resources/images/templates/election/manual.png';
import SurveyImage from 'resources/images/templates/survey/manual.png';

import { body, button, container, image } from './index.css';

const IMAGES = {
  Election: ElectionImage,
  Survey: SurveyImage,
};

type Props = {
  isVisible: boolean;
  onClose: () => void;
};

function ManualStartModal({ isVisible, onClose }: Props) {
  const navigate = useNavigate();
  const [kind, setKind] = useState<PollKind>('Election');

  return (
    <Modal isVisible={isVisible} onClose={onClose} size='xl'>
      <ModalHeader
        title={t('admin:components.features.template.modals.manualStart.title')}
        onClose={onClose}
        hideCloseButton
      />

      <ModalBody>
        <div className={container}>
          <KindButton
            kind='Election'
            isSelected={kind === 'Election'}
            onSelect={() => setKind('Election')}
          />

          <KindButton
            kind='Survey'
            isSelected={kind === 'Survey'}
            onSelect={() => setKind('Survey')}
          />
        </div>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t('admin:components.features.template.modals.manualStart.button'),
            onClick: () => {
              navigate(
                `/poll/${kind === 'Election' ? 'election' : 'survey'}/application?type=free`,
                {
                  unstable_viewTransition: true,
                },
              );
              onClose();
            },
          },
        ]}
      />
    </Modal>
  );
}

export default ManualStartModal;

function KindButton({
  isSelected,
  onSelect,
  kind,
}: {
  isSelected: boolean;
  onSelect: () => void;
  kind: PollKind;
}) {
  return (
    <PlainButton className={button({ selected: isSelected })} onClick={() => onSelect()}>
      <img className={image} src={IMAGES[kind]} alt='kind' />
      <div className={body}>
        <Text size={20} fontWeight={600}>
          {BUTTON_TEXT[kind].title}
        </Text>
        <Text size={16} fontWeight={400} color='gray-700'>
          {BUTTON_TEXT[kind].description}
        </Text>
      </div>
    </PlainButton>
  );
}

const BUTTON_TEXT = {
  Election: {
    title: '투표',
    description: '조직의 대표자 선출을 위한 투표를 만들어보세요',
  },
  Survey: {
    title: '설문',
    description: t('다양한 의견을 수집하기 위한 설문을 만들어보세요'),
  },
};
