import { PLANS, Plan } from '@pickme/core';

import { useGetActiveSpace } from 'hooks/useGetActiveSpace';

export const useGetFeatures = () => {
  let enableToSetLogo = false;
  let enableToSetURL = false;
  let enableToUseNotification = false;
  let enableToUseNotificationChannel = false;
  let managerLimit = 0;
  let remainingPollCount = 0;

  const organizationData = useGetActiveSpace();

  const voterLimit = organizationData?.subscription?.voterLimit ?? 30;
  const type = organizationData?.subscription?.type ?? Plan.Free;
  remainingPollCount = organizationData?.subscription?.creatablePollCount ?? 0;

  enableToSetLogo = PLANS[type].features.enableLogo;
  enableToSetURL = PLANS[type].features.enableCustomURL;
  managerLimit =
    organizationData?._id === '64a3734956fc87004753fc10' ? 2 : PLANS[type].features.managerCounts;
  remainingPollCount = PLANS[type].features.ignorePollCounts
    ? Infinity
    : organizationData?.subscription?.creatablePollCount ?? 0;
  enableToUseNotification = PLANS[type].features.enableNotification;
  enableToUseNotificationChannel = PLANS[type].features.enableNotificationChannel;

  const period =
    organizationData?.subscription?.contractPeriod || organizationData?.subscription?.period;

  const isPaymentFailed = (organizationData?.subscription?.nextSubscription?.failedCount || 0) > 0;

  return {
    type,
    voterLimit,
    managerLimit,
    remainingPollCount,
    enableToSetLogo,
    enableToSetURL,
    enableToUseNotification,
    enableToUseNotificationChannel,
    period,
    isPaymentFailed,
  };
};
