import { Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { t } from 'i18next';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  onSubmitButtonClick: () => void;
};

function MoveToHandSpaceOverBeforeRemoveSpaceModal({
  isVisible,
  onClose,
  onSubmitButtonClick,
}: Props) {
  return (
    <Modal size='lg' isVisible={isVisible} onClose={onClose} className='remove-space-modal'>
      <ModalHeader
        title={t(
          'admin:components.features.space.modal.moveToHandSpaceOverBeforeRemoveSpace.title',
        )}
        onClose={onClose}
      />

      <ModalBody>
        <Text type='b3' color='gray-400'>
          {t('admin:components.features.space.modal.moveToHandSpaceOverBeforeRemoveSpace.message')}
        </Text>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t(
              'admin:components.features.space.modal.moveToHandSpaceOverBeforeRemoveSpace.buttons.cancel',
            ),
            variant: 'gray',
            onClick: onClose,
          },
          {
            text: t(
              'admin:components.features.space.modal.moveToHandSpaceOverBeforeRemoveSpace.buttons.submit',
            ),
            onClick: onSubmitButtonClick,
          },
        ]}
      />
    </Modal>
  );
}

export default MoveToHandSpaceOverBeforeRemoveSpaceModal;
