import { useNavigate } from 'react-router-dom';
import { Button, Text } from '@pickme/design-system';
import { t } from 'i18next';

import EmptyPollSearchIcon from 'resources/icons/empty/poll_search.svg';
import AddIcon from 'resources/icons/dashboard/add.svg';

import { PollKindForSearchV2 } from 'types/poll-v2';

import { container, textContainer } from './index.css';

type Props = {
  type: 'default' | 'search';
  kind?: PollKindForSearchV2;
};

const POLL_EMPTY_STATE_TEXT = {
  [PollKindForSearchV2.All]: {
    title: '생성된 투표가 없어요',
    description:
      '“새로 만들기” 버튼을 클릭해 새로운 투표를 만들어보세요.\n누구든지 활용할 수 있는 다양한 템플릿을 제공하고 있어요.',
  },
  [PollKindForSearchV2.CandidateElection]: {
    title: '생성된 선거가 없어요',
    description:
      '“새로 만들기” 버튼을 클릭해 새로운 선거를 만들어보세요.\n누구든지 활용할 수 있는 다양한 템플릿을 제공하고 있어요.',
  },
  [PollKindForSearchV2.IssueElection]: {
    title: '생성된 투표가 없어요',
    description:
      '“새로 만들기” 버튼을 클릭해 새로운 투표를 만들어보세요.\n누구든지 활용할 수 있는 다양한 템플릿을 제공하고 있어요.',
  },
  [PollKindForSearchV2.Survey]: {
    title: '생성된 설문이 없어요',
    description:
      '“새로 만들기” 버튼을 클릭해 새로운 설문을 만들어보세요.\n누구든지 활용할 수 있는 다양한 템플릿을 제공하고 있어요.',
  },
};

function PollEmptyStateViewV2({ type, kind = PollKindForSearchV2.All }: Props) {
  const navigate = useNavigate();

  return (
    <div className={container}>
      {type === 'search' && <img src={EmptyPollSearchIcon} alt='empty-poll-search' />}

      <div className={textContainer}>
        <Text size={20} fontWeight={400} color='gray-800'>
          {type === 'default' && POLL_EMPTY_STATE_TEXT[kind].title}
          {type === 'search' && t('admin:components.features.poll.emptyStateView.search.title')}
        </Text>

        <Text size={16} fontWeight={400} color='gray-700'>
          {type === 'default' && POLL_EMPTY_STATE_TEXT[kind].description}
          {type === 'search' &&
            t('admin:components.features.poll.emptyStateView.search.description')}
        </Text>
      </div>

      {type === 'default' && (
        <Button
          size='md'
          icon={AddIcon}
          onClick={() => {
            navigate('/poll/init', {
              unstable_viewTransition: true,
            });
          }}
        >
          {t('admin:components.features.poll.emptyStateView.default.button')}
        </Button>
      )}
    </div>
  );
}

export default PollEmptyStateViewV2;
