export default {
  title: '이메일 등록하기',
  description: '서비스 이용을 위해 이메일 등록이 필요합니다.',
  fields: {
    email: {
      placeholder: '이메일',
    },
  },
  errorMessages: {
    wrongEmail: '올바른 이메일을 입력해주세요',
  },
  buttons: {
    submit: '인증번호 받기',
  },
};
