import { DraftTemplate, DraftTemplateCategory } from 'types/template';

import Thumbnail1 from 'resources/images/templates/survey/religion1.png';
import Thumbnail2 from 'resources/images/templates/survey/religion2.png';

const DRAFT_1 = {
  title: '온라인 예배 만족도 조사',
  _id: '64992c4fa5f0ec00830beb02',
  content: {
    name: '온라인 예배 만족도 조사',
    periods: [
      {
        startDate: '2023-07-26T14:47',
        endDate: '2023-11-30T14:47',
      },
    ],
    description:
      '<p>본 설문은 온라인 예배 만족도 설문조사입니다.</p><p>여러분들의 다양한 의견을 자유롭게 들려주세요.</p><p>감사합니다.</p>',
    type: 'Survey',
    voterType: 'voterBook',
    hasOpenVotes: false,
    subscriptionType: 'enterprise',
    questions: [
      {
        kind: 'RadioQuestion',
        title: '다음 보기중 선호하는 예배 방식을 선택해주세요.',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '온라인 예배',
            fileUrl: '',
          },
          {
            description: '오프라인 예배',
            number: 2,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'RadioQuestion',
        title: '온라인 예배가 오프라인 예배에 비해서 갖는 가장 큰 장점은 무엇이라 생각하시나요?',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '비대면으로 이뤄져 안전하다.',
            fileUrl: '',
          },
          {
            description: '시간과 공간의 제약으로부터 자유로워졌다.',
            number: 2,
            fileUrl: '',
          },
          {
            description: '가족 또는 지인들과 함께 예배를 드릴 수 있다.',
            number: 3,
            fileUrl: '',
          },
          {
            description: '다양한 예배중 나와 맞는 예배를 선택하여 들을 수 있다.',
            number: 4,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'RankQuestion',
        title: '온라인 예배에서 가장 만족한 3가지 요소를 선택해주세요.',
        description: '',
        isRequired: true,
        selections: [],
        subQuestions: [
          {
            description: '설교',
            fileUrl: '',
          },
          {
            description: '찬양',
            fileUrl: '',
          },
          {
            description: '기도',
            fileUrl: '',
          },
          {
            description: '만남과 교제',
            fileUrl: '',
          },
          {
            description: '성찬',
            fileUrl: '',
          },
          {
            description: '헌금',
            fileUrl: '',
          },
          {
            description: '기타',
            fileUrl: '',
          },
        ],
        responseValidation: {
          rankLimit: 3,
        },
        fileUrl: '',
        id: '1e057259-880a-4185-a4ba-909959f61653',
      },
      {
        kind: 'ScaleQuestion',
        title: '온라인 예배에 대한 만족도를 선택해주세요.',
        description: '',
        isRequired: true,
        selections: [],
        subQuestions: [],
        min: {
          value: 1,
          description: '불만족',
        },
        max: {
          value: 5,
          description: '만족',
        },
        fileUrl: '',
      },
      {
        kind: 'TextQuestion',
        title: '온라인 예배 관련하여 여러분들의 자유로운 의견을 작성해주세요.',
        description: '',
        isRequired: true,
        selections: [],
        subQuestions: [],
        responseValidation: {
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-06-26T06:12:31.356Z',
  __v: 0,
};

const DRAFT_2 = {
  title: '여름성경학교 만족도 조사',
  _id: '64b4c93975c32e0047480787',
  content: {
    name: '여름성경학교 만족도 조사',
    periods: [
      {
        startDate: '2023-07-21T13:34',
        endDate: '2023-07-24T15:33',
      },
    ],
    description:
      '<p>본 설문은 최근 주최했던 여름성경학교 만족도 설문조사입니다.</p><p>여러분들의 다양한 의견을 자유롭게 들려주세요.</p><p>감사합니다.</p>',
    type: 'Survey',
    voterType: 'voterBook',
    hasOpenVotes: false,
    subscriptionType: 'enterprise',
    questions: [
      {
        kind: 'RadioQuestion',
        title: '여름성경학교에 참여하게 된 이유는 무엇인가요?',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '교회 선생님 추천',
            fileUrl: '',
          },
          {
            description: '부모님 추천',
            number: 2,
            fileUrl: '',
          },
          {
            description: '친구 추천',
            number: 3,
            fileUrl: '',
          },
          {
            description: '자발적 참여 희망',
            number: 4,
            fileUrl: '',
          },
          {
            description: '기타',
            number: 5,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'ScaleQuestion',
        title: '이번 여름성경학교를 진행한 장소에 대해 만족하시나요?',
        description: '',
        isRequired: true,
        selections: [],
        subQuestions: [],
        min: {
          value: 1,
          description: '불만족',
        },
        max: {
          value: 5,
          description: '만족',
        },
        fileUrl: '',
        id: 'fc397ed7-6335-45ff-8194-fa6f23e514e2',
      },
      {
        kind: 'RankQuestion',
        title:
          '이번 여름성경학교에서 진행한 프로그램 중 가장 흥미로웠던 프로그램부터 순서대로 선택해주세요.',
        description: '',
        isRequired: true,
        selections: [],
        subQuestions: [
          {
            description: '찬양과 율동',
            fileUrl: '',
          },
          {
            description: '기도와 말씀',
            fileUrl: '',
          },
          {
            description: '예수님을 찾아요!',
            fileUrl: '',
          },
          {
            description: '성경 속에서 예수님을 만나요!',
            fileUrl: '',
          },
        ],
        responseValidation: {
          rankLimit: 4,
        },
        fileUrl: '',
      },
      {
        kind: 'ScaleQuestion',
        title: '이번 여름성경학교 전체적인 만족도를 선택해주세요.',
        description: '',
        isRequired: true,
        selections: [],
        subQuestions: [],
        min: {
          value: 1,
          description: '불만족',
        },
        max: {
          value: 10,
          description: '만족',
        },
        fileUrl: '',
      },
      {
        kind: 'RadioQuestion',
        title: '내년 여름성경학교에도 참여할 의향이 있으신가요?',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '예 (참여 의향이 있습니다.)',
            fileUrl: '',
          },
          {
            description: '아니오 (참여 의향이 없습니다.)',
            number: 2,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'TextQuestion',
        title: '여름성경학교 참여 후기 또는 아쉬웠던 점 등 의견을 자유롭게 적어주세요.',
        description: '',
        isRequired: true,
        selections: [],
        subQuestions: [],
        responseValidation: {
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
        id: 'ae334faf-4165-4a64-99d3-2b6621a787a1',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    hasInstantVoter: false,
    allowRealTimeResult: false,
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-07-17T04:53:13.371Z',
  __v: 0,
};

export default [
  {
    title: '교회 온라인 예배 만족도 조사',
    description: '교회 온라인 예배 만족도 조사를 위한 템플릿입니다.',
    thumbnail: Thumbnail1,
    draft: DRAFT_1,
    category: DraftTemplateCategory.Religion,
    kind: 'Survey',
  },
  {
    title: '여름성경학교 만족도 조사',
    description: '여름성경학교 만족도 조사를 위한 템플릿입니다.',
    thumbnail: Thumbnail2,
    draft: DRAFT_2,
    category: DraftTemplateCategory.Religion,
    kind: 'Survey',
  },
] as DraftTemplate[];
