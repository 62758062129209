import { parseErrorMessage } from '@pickme/error';
import axios from 'axios';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const combinedError = {
      ...error,
      networkStatusCode: error.code,
      ...parseErrorMessage(error),
    };

    return Promise.reject(combinedError);
  },
);
