import { DraftTemplate, DraftTemplateCategory } from 'types/template';

import Thumbnail1 from 'resources/images/templates/election/school1.png';
import Thumbnail2 from 'resources/images/templates/election/school2.png';
import Thumbnail3 from 'resources/images/templates/election/school3.png';

const DRAFT_1 = {
  title: '픽미대학교 총학생회 선출 선거',
  _id: '6450dd6536123104cfcbf7e5',
  content: {
    name: '픽미대학교 총학생회 선출 선거',
    periods: [
      {
        startDate: '2023-05-25T16:22',
        endDate: '2023-06-30T16:22',
      },
    ],
    description:
      '<p>안녕하세요. 픽미대학교 선거관리위원회입니다.</p><p>2023년 픽미대학교를 이끌어갈 총학생회 선출 선거입니다.</p><p>학우 여러분의 많은 참여와 관심 부탁드립니다.</p><p><br></p><p>감사합니다.</p>',
    type: 'Election',
    voterType: 'voterBook',
    subscriptionType: 'enterprise',
    subElections: [
      {
        kind: 'ChoiceElection',
        title: '픽미대학교 총학생회 선출 선거',
        shuffle: false,
        candidates: [
          {
            title: '선거운동본부 A',
            description:
              '<p>안녕하세요. 픽미대학교 학우여러분.</p><p>2023년 픽미대학교 총학생회 선거에 출마한 선거인 본부 A입니다.</p><p><br></p><p><strong>공약</strong></p><p><br></p><p><br></p><ol><li>픽미대학교 교육 환경을 개선하겠습니다.</li><li>학생 휴게실을 상시 개방하고 개선하겠습니다.</li><li>교내 정보 제공 박람회를 개최하겠습니다.</li></ol>',
            introductionVideoUrl: '',
            members: [
              {
                name: '선거운동본부 A',
                fileUrl: 'https://api.ohpick.me/api/file/644783a57bc5dd032df57435',
              },
              {
                name: '김성현 (회장)',
                fileUrl: 'https://api.ohpick.me/api/file/644783757bc5dd032df57427',
              },
              {
                name: '나지현 (부회장)',
                fileUrl: 'https://api.ohpick.me/api/file/644783797bc5dd032df5742e',
              },
            ],
            tags: ['#교육환경개선', '#휴게실개방및운영', '#박람회개최'],
            id: '2dafcc31-e22c-46ea-87fc-5ff582c33cd1',
          },
          {
            title: '선거운동본부 B',
            description:
              '<p>안녕하세요. 픽미대학교 학우여러분.</p><p>2023년 픽미대학교 총학생회 선거에 출마한 선거인 본부 B입니다.</p><p><br></p><p><strong>공약</strong></p><p><br></p><p><br></p><p><br></p><p><br></p><ol><li>예비군 버스 지원 및 조식을 제공하겠습니다.</li><li>교양 과목 및 시간표를 다양화하겠습니다.</li><li>전공 및 교양서적 구매 및 지원 사업을 확대하겠습니다.</li></ol>',
            introductionVideoUrl: '',
            members: [
              {
                name: '선거운동본부 B',
                fileUrl: 'https://api.ohpick.me/api/file/644784a21a3d83033dab34fd',
              },
              {
                name: '서정빈',
                fileUrl: 'https://api.ohpick.me/api/file/644784a71a3d83033dab3504',
              },
              {
                name: '진하림',
                fileUrl: 'https://api.ohpick.me/api/file/644784ac1a3d83033dab350b',
              },
            ],
            tags: ['#예비군지원', '#교과목다양화', '#교육지원'],
            id: '59684f07-656b-43f5-9db5-2dedef3f1fe0',
          },
        ],
        responseValidation: {
          allowAbstentionVote: false,
          minResponse: 1,
          maxResponse: 1,
        },
        id: '6708ad00-e4d3-47f4-9390-a73de464ca05',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'election',
  createdAt: '2023-05-02T09:52:37.996Z',
  __v: 0,
};

const DRAFT_2 = {
  title: '픽미고등학교 전교회장 선거',
  _id: '6459b20d5d32320142f13f3d',
  content: {
    name: '픽미고등학교 전교회장 선거',
    periods: [
      {
        startDate: '2023-05-25T16:22',
        endDate: '2023-06-30T16:22',
      },
    ],
    description:
      '<p>안녕하십니까.</p><p><br></p><p>2023학년도 제28대 픽미고등학교 전교회장 선거입니다.</p><p>픽미인 여러분의 많은 관심과 참여 부탁드립니다.</p><p><br></p><p>감사합니다.</p>',
    type: 'Election',
    voterType: 'voterBook',
    subscriptionType: 'enterprise',
    subElections: [
      {
        kind: 'ChoiceElection',
        title: '2023 픽미고등학교 학생회장 선거',
        shuffle: false,
        candidates: [
          {
            title: '기호1. 김지나',
            description:
              '<h2>"준비된 후보! 일하는 후보!"</h2><p><br></p><p>안녕하세요.</p><p>2023년 픽미고등학교 학생회장 선거에 출마한 김지나입니다.</p><p>여러분들의 소중한 한표 부탁드립니다.</p><p><br></p><p><strong>공약</strong></p><ol><li>화장실 방향제 및 거치대를 설치하겠습니다.</li><li>급식실 추가 테이블을 설치하겠습니다.</li><li>고장난 컴퓨터를 수리 및 교체하겠습니다.</li></ol>',
            introductionVideoUrl: '',
            members: [
              {
                name: '김지나',
                fileUrl: 'https://api.ohpick.me/api/file/644786241a3d83033dab35b1',
              },
            ],
            tags: ['#2학년', '#8반'],
            id: '2f6b41e7-90b8-4da4-b3b6-37be382b469e',
          },
          {
            title: '기호2. 송나리',
            description:
              '<h2>"행동하는 학생회장, 실천하는 송나리!"</h2><p><br></p><p>안녕하세요.</p><p>2023년 픽미고등학교 학생회장 선거에 출마한 송나리입니다.</p><p>여러분들의 소중한 한표 부탁드립니다.</p><p><br></p><p><strong>공약</strong></p><ol><li>수학여행 사진 공모전을 개최하겠습니다.</li><li>화장실에 방향제를 배치하도록 하겠습니다.</li><li>우산 대여 시스템을 운영하겠습니다.</li></ol>',
            introductionVideoUrl: '',
            members: [
              {
                name: '송나리',
                fileUrl: 'https://api.ohpick.me/api/file/6447871a1a3d83033dab35bc',
              },
            ],
            tags: ['#2학년', '#3반'],
            id: 'cdccb0ec-6778-4746-b725-25f7b48716f3',
          },
        ],
        responseValidation: {
          allowAbstentionVote: false,
          minResponse: 1,
          maxResponse: 1,
        },
        id: '9b73922e-850d-4c87-a77e-9c82251a0985',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'election',
  createdAt: '2023-05-09T02:38:05.014Z',
  __v: 0,
};

const DRAFT_3 = {
  title: '초등학교 학부모회 회장 선출 투표',
  _id: '64b774724e2743004875c7b7',
  content: {
    name: '초등학교 학부모회 회장 선출 투표',
    periods: [
      {
        startDate: '2023-07-24T16:17',
        endDate: '2023-07-28T14:17',
      },
    ],
    description:
      '<p>본 투표는 초등학교 학부모회 회장 선출 투표입니다.</p><p>여러분의 소중한 한 표 부탁드립니다.</p><p>감사합니다.</p>',
    type: 'Election',
    voterType: 'voterBook',
    subscriptionType: 'enterprise',
    subElections: [
      {
        kind: 'ChoiceElection',
        title: '초등학교 학부모회 회장 선출 투표',
        shuffle: false,
        candidates: [
          {
            title: '후보 1. 박상아',
            description:
              '<h2>후보 1. 박상아 (37세)</h2><p><br></p><p>[ 자녀 ]</p><p>3학년 5반 김아랑</p><p><br></p><p>[ 경력 ]</p><p>2022 픽미초등학교 운영위원장</p><p>2020-2021 픽미초등학교 운영위원</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '박상아 (37세)',
                fileUrl: 'https://api.ohpick.me/api/file/64b7726e4e2743004875c748',
              },
            ],
            tags: [],
          },
          {
            title: '후보 2. 김명지',
            description:
              '<h2>후보 2. 김명지 (43세)</h2><p><br></p><p>[ 자녀 ]</p><p>4학년 6반 권건우</p><p><br></p><p>[ 경력 ]</p><p>2021-2022 픽미초등학교 운영위원</p><p>2018 픽미중학교 운영위원장</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '김명지 (43세)',
                fileUrl: 'https://api.ohpick.me/api/file/64b773264e2743004875c784',
              },
            ],
            tags: [],
            id: 'c0c570ec-d252-4528-bee4-dcb1a9171de1',
          },
          {
            title: '후보 3. 한윤',
            description:
              '<h2>후보 3. 한윤 (34세)</h2><p><br></p><p>[ 자녀 ]</p><p>4학년 2반 권건우</p><p><br></p><p>[ 경력 ]</p><p>2020 퀘스초등학교 학부모회장</p><p>2019 퀘스초등학교 학부모부회장</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '한윤 (34세)',
                fileUrl: 'https://api.ohpick.me/api/file/64b773cd4e2743004875c7a9',
              },
            ],
            tags: [],
            id: '53583acd-a472-48f6-80c1-3763cb8e5107',
          },
        ],
        responseValidation: {
          allowAbstentionVote: true,
          minResponse: 1,
          maxResponse: 1,
        },
        id: '548e3cd9-5f75-40f8-8165-75e2c4f3a868',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'election',
  createdAt: '2023-07-19T05:28:18.219Z',
  __v: 0,
};

export default [
  {
    title: '대학교 총학생회 선거',
    description: '대학교 총학생회 선거를 위한 템플릿입니다.',
    thumbnail: Thumbnail1,
    draft: DRAFT_1,
    category: DraftTemplateCategory.School,
    kind: 'Election',
  },
  {
    title: '고등학교 학생회장 선거',
    description: '고등학교 학생회장 선거를 위한 템플릿입니다.',
    thumbnail: Thumbnail2,
    draft: DRAFT_2,
    category: DraftTemplateCategory.School,
    kind: 'Election',
  },
  {
    title: '초등학교 학부모회 회장 선거',
    description: '초등학교 학부모회 회장 선거를 위한 템플릿입니다.',
    thumbnail: Thumbnail3,
    draft: DRAFT_3,
    category: DraftTemplateCategory.School,
    kind: 'Election',
  },
] as DraftTemplate[];
