import moment from 'moment-timezone';
import { ModalBody, Text } from '@pickme/ui';
import { TransferTransaction } from '@pickme/core';
import { t } from 'i18next';

import IncomingDetailRow from 'components/features/payment/modal/IncomingDetail/IncomingDetailRow';

import { parseTransferTransactionToBadge } from 'functions/payment';

const setIncomingRows = (transferTransaction: TransferTransaction<'received'>) => {
  const items = [
    {
      title: t('admin:components.features.payment.modal.incomingDetail.transferTransaction.number'),
      content: transferTransaction.paymentOid || '',
    },
    {
      title: t('admin:components.features.payment.modal.incomingDetail.transferTransaction.date'),
      content: transferTransaction.createdAt
        ? moment(transferTransaction.createdAt).tz('Asia/Seoul').format('YY/MM/DD HH:mm')
        : '',
    },
    {
      title: t(
        'admin:components.features.payment.modal.incomingDetail.transferTransaction.category.label',
      ),
      content: t(
        'admin:components.features.payment.modal.incomingDetail.transferTransaction.category.content',
      ),
    },
    {
      title: t('admin:components.features.payment.modal.incomingDetail.transferTransaction.sender'),
      content: transferTransaction.senderEmail || transferTransaction.senderName || '',
    },
    {
      title: t('admin:components.features.payment.modal.incomingDetail.transferTransaction.point'),
      content: transferTransaction.amount.print(),
    },
  ];

  return items;
};

function TransferTransactionViewer({
  transferTransaction,
}: {
  transferTransaction: TransferTransaction<'received'>;
}) {
  const badge = parseTransferTransactionToBadge();

  return (
    <ModalBody>
      <Text type='b2' fontWeight={500} color='primary-700'>
        {badge.text}
      </Text>

      <hr />

      <div className='incoming-detail-modal__section'>
        {setIncomingRows(transferTransaction).map((item) => (
          <IncomingDetailRow title={item.title} content={item.content} key={item.title} />
        ))}
      </div>
    </ModalBody>
  );
}

export default TransferTransactionViewer;
