import { t } from 'i18next';
import moment from 'moment-timezone';

import { Period } from 'types/application';

export function checkNotOverlapped(periods: Period[]) {
  const copiedPeriods = periods.slice();
  copiedPeriods.sort((a, b) => (moment(a.startDate).isBefore(moment(b.startDate)) ? -1 : 1));

  for (let i = 1; i < copiedPeriods.length; i += 1) {
    if (moment(copiedPeriods[i - 1].endDate).isAfter(moment(copiedPeriods[i].startDate))) {
      return t('admin:functions.validator.poll.period.duplicated');
    }
  }

  return true;
}
