import { PollGroup } from '@pickme/core';

export const makeQueryString = (query: object) => {
  const result = Object.entries(query)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  return result;
};

export const parseSearchUrlWithPollGroup = (url: string, pollGroup: PollGroup) => {
  const isUrlWithQuery = url.includes('?');
  const isUrlWithPollGroupQuery = url.includes('groups=');

  if (!isUrlWithQuery) {
    return `${url}?groups=${pollGroup}`;
  }

  const parsedUrl = parseUrlWithPage(url, 1);

  if (!isUrlWithPollGroupQuery) {
    return `${parsedUrl}&groups=${pollGroup}`;
  }

  const replacedUrl = parsedUrl.replace(/groups=[a-zA-Z][^&]*/, `groups=${pollGroup}`);
  return replacedUrl;
};

export const parseUrlWithPage = (url: string, page: number) => {
  const isUrlWithQuery = url.includes('?');
  const isUrlWithPageQuery = url.includes('page=');

  if (!isUrlWithQuery) {
    return `${url}?page=${page}`;
  }

  if (!isUrlWithPageQuery) {
    return `${url}&page=${page}`;
  }

  const replacedUrl = url.replace(/page=[0-9][^&]*/, `page=${page.toString()}`);
  return replacedUrl;
};
