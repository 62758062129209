import { useNavigate } from 'react-router-dom';
import { Button, Text } from '@pickme/design-system';

import { box, buttons, container } from './index.css';

type Props = {
  name: string;
  email: string;
};

function SignUpComplete({ name, email }: Props) {
  const navigate = useNavigate();

  return (
    <>
      <div className={container}>
        <div className={box}>
          <Text size={14} color='gray-600'>
            이름
          </Text>
          <Text size={14} color='gray-800'>
            {name}
          </Text>
        </div>

        <div className={box}>
          <Text size={14} color='gray-600'>
            이메일
          </Text>
          <Text size={14} color='gray-800'>
            {email}
          </Text>
        </div>
      </div>

      <div className={buttons.container}>
        <Button
          variant='gray'
          size='xl'
          className={buttons.main}
          onClick={() => window.open(import.meta.env.VITE_INTRO_URL, '_self')}
        >
          메인 화면으로
        </Button>

        <Button
          size='xl'
          className={buttons.start}
          onClick={() => navigate('/pickme', { replace: true })}
        >
          바로 시작하기
        </Button>
      </div>
    </>
  );
}

export default SignUpComplete;
