import { t } from 'i18next';

import { NavAccordionMenuItem } from 'types/menu';

import DashboardIcon from 'resources/icons/nav/menu/dashboard.svg';
import DashboardHoverIcon from 'resources/icons/nav/menu/dashboard_active.svg';
import ServiceIcon from 'resources/icons/nav/menu/message.svg';
import ServiceHoverIcon from 'resources/icons/nav/menu/message_active.svg';
import SettingIcon from 'resources/icons/nav/menu/settings.svg';
import SettingHoverIcon from 'resources/icons/nav/menu/settings_active.svg';

import FeedbackIcon from 'resources/icons/nav/menu/feedback.svg';
import FeedbackHoverIcon from 'resources/icons/nav/menu/feedback_active.svg';
import HelpIcon from 'resources/icons/nav/menu/help.svg';
import HelpHoverIcon from 'resources/icons/nav/menu/help_active.svg';

export const MAIN_NAV_MENU: NavAccordionMenuItem[] = [
  {
    title: t('admin:constants.nav.main.dashboard.title'),
    link: '/',
    linkType: 'internal',
    icon: { normal: DashboardIcon, hover: DashboardHoverIcon },
  },
  {
    title: t('admin:constants.nav.main.notification.title'),
    icon: { normal: ServiceIcon, hover: ServiceHoverIcon },
    linkType: 'internal',
    sub: [
      {
        title: t('admin:constants.nav.main.notification.sub.point'),
        link: '/service/notification/point',
        linkType: 'internal',
      },
      {
        title: '메시지 보내기',
        link: '/service/notification/send',
        linkType: 'internal',
      },
      {
        title: '전송 내역',
        link: '/service/notification/history',
        linkType: 'internal',
      },
    ],
  },
  {
    title: t('admin:constants.nav.main.settings.title'),
    icon: { normal: SettingIcon, hover: SettingHoverIcon },
    link: '/settings',
    linkType: 'internal',
    sub: [
      {
        title: t('admin:constants.nav.main.settings.sub.space'),
        link: '/settings/organization',
        linkType: 'internal',
      },
      {
        title: t('admin:constants.nav.main.settings.sub.manager'),
        link: '/settings/manager',
        linkType: 'internal',
      },
      {
        title: t('admin:constants.nav.main.settings.sub.poll'),
        link: '/settings/poll/history',
        linkType: 'internal',
      },
      {
        title: t('admin:constants.nav.main.settings.sub.integration'),
        link: '/settings/integration',
        linkType: 'internal',
      },
      {
        title: t('admin:constants.nav.main.settings.sub.payment'),
        link: '/settings/payment/subscription',
        linkType: 'internal',
      },
      {
        title: t('admin:constants.nav.main.settings.sub.resources'),
        link: '/settings/resource',
        linkType: 'internal',
      },
    ],
  },
];

export const SYSTEM_NAV_MENU: NavAccordionMenuItem[] = [
  {
    title: t('admin:constants.nav.system.feedback.title'),
    link: '/feedback',
    linkType: 'internal',
    icon: { normal: FeedbackIcon, hover: FeedbackHoverIcon },
  },
  {
    title: t('admin:constants.nav.system.help.title'),
    link: `${import.meta.env.VITE_INTRO_URL}/help-center`,
    linkType: 'external',
    icon: { normal: HelpIcon, hover: HelpHoverIcon },
  },
];
