import { isUndefined } from 'lodash-es';
import { ReactNode, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Spinner } from '@pickme/ui';
import { Text, Input, Switch, Button } from '@pickme/design-system';
import { t } from 'i18next';

import VoterAuthMethodsField from 'components/features/application/form/Additional/VoterAuthMethods';

import { usePollStarted } from 'hooks/poll/usePollData';

import { AdditionalFormBody, VoterAuthMethod } from 'types/application';

import { container, fieldStyle, fieldsCardStyle, openThresholdInputStyle } from './index.css';

type Props = {
  kind: 'election' | 'survey';
  isLoading: boolean;
  hasInstantVoter?: boolean;
  isEdit?: boolean;
  isPublic?: boolean;
  onSubmit: (data: AdditionalFormBody) => void;
};

function AdditionalForm({ kind, isLoading, hasInstantVoter, isEdit, isPublic, onSubmit }: Props) {
  const [isOpenThresholdToggled, toggleOpenThreshold] = useState(false);
  const methods = useFormContext<AdditionalFormBody>();
  const openThreshold = methods.watch('openThreshold');
  const isPollStarted = usePollStarted();

  useEffect(() => {
    if (!!openThreshold && !isOpenThresholdToggled) {
      toggleOpenThreshold(true);
    }
  }, [openThreshold, isOpenThresholdToggled]);

  useEffect(() => {
    if (isPublic) {
      methods.setValue('voterAuthMethods', [VoterAuthMethod.Email, VoterAuthMethod.Phone]);
      methods.setValue('isOpenPollResult', true);
      methods.setValue('allowRealTimeResult', true);
    }
  }, [isPublic]);

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <div className={container}>
        <FieldsCard
          title={
            kind === 'election'
              ? t(
                  'admin:components.features.application.form.additional.title.participate.election',
                )
              : t('admin:components.features.application.form.additional.title.participate.survey')
          }
        >
          <VoterAuthMethodsField readyOnly={isPublic} disabled={isPollStarted} />
        </FieldsCard>

        <FieldsCard
          title={t('admin:components.features.application.form.additional.title.participants')}
        >
          <Field
            title={
              hasInstantVoter
                ? t(
                    'admin:components.features.application.form.additional.fields.openPollRate.label.instantVoter',
                  )
                : t(
                    'admin:components.features.application.form.additional.fields.openPollRate.label.default',
                  )
            }
            description={
              hasInstantVoter
                ? t(
                    'admin:components.features.application.form.additional.fields.openPollRate.tooltip.instantVoter',
                  )
                : t(
                    'admin:components.features.application.form.additional.fields.openPollRate.tooltip.default',
                  )
            }
          >
            <Controller
              control={methods.control}
              name='isOpenPollRate'
              render={({ field }) => (
                <Switch
                  onBlur={field.onBlur}
                  id={field.name}
                  ref={field.ref}
                  onChange={(event) => {
                    if (event.target.checked) {
                      field.onChange(true);
                    } else {
                      field.onChange(false);
                    }
                  }}
                  checked={field.value === true}
                />
              )}
            />
          </Field>

          <Field
            title={t(
              'admin:components.features.application.form.additional.fields.openPollResult.title',
            )}
            description={
              kind === 'election'
                ? t(
                    'admin:components.features.application.form.additional.fields.openPollResult.description.election',
                  )
                : t(
                    'admin:components.features.application.form.additional.fields.openPollResult.description.survey',
                  )
            }
          >
            <Controller
              control={methods.control}
              name='isOpenPollResult'
              render={({ field }) => (
                <Switch
                  onBlur={field.onBlur}
                  id={field.name}
                  ref={field.ref}
                  readOnly={isPublic}
                  onChange={(event) => {
                    if (event.target.checked) {
                      field.onChange(true);
                    } else {
                      field.onChange(false);
                    }
                  }}
                  checked={field.value === true}
                />
              )}
            />
          </Field>
        </FieldsCard>

        <FieldsCard title={t('admin:components.features.application.form.additional.title.admin')}>
          {kind === 'survey' && (
            <Field
              title={t(
                'admin:components.features.application.form.additional.fields.realtime.title',
              )}
              description={t(
                'admin:components.features.application.form.additional.fields.realtime.description',
              )}
            >
              <Controller
                control={methods.control}
                name='allowRealTimeResult'
                render={({ field }) => (
                  <Switch
                    onBlur={field.onBlur}
                    id={field.name}
                    ref={field.ref}
                    readOnly={isPublic}
                    onChange={(event) => {
                      if (event.target.checked) {
                        field.onChange(true);
                      } else {
                        field.onChange(false);
                      }
                    }}
                    checked={field.value === true}
                  />
                )}
              />
            </Field>
          )}

          {kind === 'survey' && (
            <Field
              title={t(
                'admin:components.features.application.form.additional.fields.hasOpenVotes.title',
              )}
              description={t(
                'admin:components.features.application.form.additional.fields.hasOpenVotes.description',
              )}
            >
              <Controller
                control={methods.control}
                name='hasOpenVotes'
                disabled={isPollStarted}
                render={({ field }) => (
                  <Switch
                    onBlur={field.onBlur}
                    id={field.name}
                    ref={field.ref}
                    disabled={field.disabled}
                    onChange={(event) => {
                      if (event.target.checked) {
                        field.onChange(true);
                      } else {
                        field.onChange(false);
                      }
                    }}
                    checked={field.value === true}
                  />
                )}
              />
            </Field>
          )}

          {kind === 'election' && (
            <Field
              title={t(
                'admin:components.features.application.form.additional.fields.openThreshold.label',
              )}
              description={t(
                'admin:components.features.application.form.additional.fields.openThreshold.tooltip',
              )}
            >
              <Switch
                id='toggle-open-threshold'
                onChange={(event) => {
                  if (event.target.checked) {
                    toggleOpenThreshold(true);
                  } else {
                    methods.setValue('openThreshold', 0, { shouldDirty: true });
                    toggleOpenThreshold(false);
                  }
                }}
                checked={!!methods.watch('openThreshold') || isOpenThresholdToggled === true}
              />
            </Field>
          )}

          {isOpenThresholdToggled && (
            <div className={openThresholdInputStyle}>
              <Input
                type='number'
                width={270}
                placeholder={t(
                  'admin:components.features.application.form.additional.fields.openThreshold.placeholder',
                )}
                {...methods.register('openThreshold', {
                  validate: (value) => {
                    if (isUndefined(value)) {
                      return false;
                    }

                    if (value < 0) {
                      return false;
                    }

                    if (value > 100) {
                      return false;
                    }

                    return true;
                  },
                  valueAsNumber: true,
                })}
                min={0}
                max={100}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />

              <Text size={16} fontWeight={400} color='gray-700'>
                %
              </Text>
            </div>
          )}
        </FieldsCard>

        <Button
          fullWidth
          size='md'
          variant='primary'
          type='submit'
          disabled={!methods.formState.isValid || isLoading}
        >
          {isLoading ? <Spinner /> : getSubmitButtonText(kind, isEdit)}
        </Button>
      </div>
    </form>
  );
}

export default AdditionalForm;

function getSubmitButtonText(kind: 'election' | 'survey', isEdit?: boolean) {
  if (kind === 'election') {
    if (isEdit) {
      return t(
        'admin:components.features.application.form.additional.buttons.submit.election.edit',
      );
    }

    return t('admin:components.features.application.form.additional.buttons.submit.election.apply');
  }

  if (isEdit) {
    return t('admin:components.features.application.form.additional.buttons.submit.survey.edit');
  }

  return t('admin:components.features.application.form.additional.buttons.submit.survey.apply');
}

function FieldsCard({ title, children }: { title: string; children: ReactNode }) {
  return (
    <div className={fieldsCardStyle.container}>
      <Text size={18} fontWeight={600} color='gray-800'>
        {title}
      </Text>

      <div className={fieldsCardStyle.body}>{children}</div>
    </div>
  );
}

function Field({
  title,
  description,
  children,
}: {
  title: string;
  description: string;
  children: ReactNode;
}) {
  return (
    <div className={fieldStyle.container}>
      <div className={fieldStyle.header}>
        <Text size={16} fontWeight={600} color='gray-800'>
          {title}
        </Text>

        <Text size={16} fontWeight={400} color='gray-700'>
          {description}
        </Text>
      </div>

      <div>{children}</div>
    </div>
  );
}
