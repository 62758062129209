import { atom } from 'recoil';

const initialState: {
  isVisible: boolean;
  type: 'notification' | 'success' | 'error' | 'warning';
  message: string;
} = {
  isVisible: false,
  type: 'notification',
  message: '',
};

export const toast = atom({
  key: 'toast',
  default: initialState,
});
