import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { t } from 'i18next';

import {
  createGetChannelCategoriesRequest,
  createGetRegistrationTokenRequest,
  createRegisterChannelRequest,
  createGetChannelRequest,
  createRemoveChannelRequest,
} from 'apis/channel';

import { toast } from 'states/toast';

import { ChannelRegistrationFormBody } from 'types/channel';

export const useGetChannel = (disabled?: boolean) =>
  useQuery(
    'channel',
    async () => {
      const { data } = await createGetChannelRequest();

      return data;
    },
    {
      enabled: !disabled,
      cacheTime: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10,
    },
  );

export const useGetChannelCategories = () =>
  useQuery(
    'channel-categories',
    async () => {
      const { data } = await createGetChannelCategoriesRequest();

      return data;
    },
    {
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60 * 60,
    },
  );

export const useGetRegisterToken = () => {
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ searchId, phoneNumber }: { searchId: string; phoneNumber: string }) => {
      const { data } = await createGetRegistrationTokenRequest({
        searchId,
        phoneNumber,
      });

      return data;
    },
    {
      onSuccess: () => {
        setToast({
          isVisible: true,
          type: 'success',
          message: t('admin:query-hooks.channel.useGetRegisterToken.onSuccess.toast'),
        });
      },
      onError: () => {
        setToast({
          isVisible: true,
          type: 'error',
          message: t('admin:query-hooks.channel.useGetRegisterToken.onError.toast'),
        });
      },
    },
  );
};

export const useRegisterChannel = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ form }: { form: ChannelRegistrationFormBody }) => {
      const { data } = await createRegisterChannelRequest(form);

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('channel');
        setToast({
          isVisible: true,
          type: 'success',
          message: t('admin:query-hooks.channel.useRegisterChannel.onSuccess.toast'),
        });
      },
      onError: () => {
        setToast({
          isVisible: true,
          type: 'error',
          message: t('admin:query-hooks.channel.useRegisterChannel.onError.toast'),
        });
      },
    },
  );
};

export const useRemoveChannel = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const { data } = await createRemoveChannelRequest();

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('channel');
      },
    },
  );
};
