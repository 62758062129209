import { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Text } from '@pickme/design-system';

import SocialIconButton from 'components/features/auth/SocialIconButton';
import SignUpTerms from 'components/features/auth/form/signup/UserInfo/Terms';

import { useSocialSignUp } from 'query-hooks/auth';

import { SocialProvider, UserInfoForm } from 'types/auth';

import { title } from 'pages/auth/common.css';
import { container, social, submit } from './index.css';

function SocialSignUp() {
  const methods = useForm<UserInfoForm>();

  const [agreeTerms, setAgreeTerms] = useState(true);
  const [agreePrivacy, setAgreePrivacy] = useState(true);

  const { mutate } = useSocialSignUp();

  const { search } = useLocation();
  const [searchParams] = useSearchParams(search);

  const provider = searchParams.get('provider') as SocialProvider;
  const name = searchParams.get('name');
  const email = searchParams.get('email');
  const socialKey = searchParams.get('socialKey');
  const accessToken = searchParams.get('accessToken');
  const refreshToken = searchParams.get('refreshToken') || undefined;

  return (
    <form
      className={container}
      onSubmit={methods.handleSubmit((data) => {
        if (!provider || !name || !email || !socialKey || !accessToken) {
          return;
        }

        mutate({
          provider,
          name,
          email,
          socialKey,
          accessToken,
          refreshToken,
          marketingAgreements: data.mailing ? ['email', 'sms'] : [],
        });
      })}
    >
      <Text size={30} fontWeight={500} color='black' className={title}>
        필수 약관 동의 후
        <br />
        회원가입이 완료됩니다.
      </Text>

      <div className={social.container}>
        <SocialIconButton provider={provider} disabled />

        <div>
          <Text size={24} fontWeight={600} className={social.name}>
            {name}
          </Text>
          <Text size={16}>{email}</Text>
        </div>
      </div>

      <FormProvider {...methods}>
        <SignUpTerms
          agreeTerms={agreeTerms}
          setAgreeTerms={setAgreeTerms}
          agreePrivacy={agreePrivacy}
          setAgreePrivacy={setAgreePrivacy}
        />
      </FormProvider>

      <Button fullWidth size='xl' type='submit' className={submit}>
        가입하기
      </Button>
    </form>
  );
}

export default SocialSignUp;
