import type { ChargeStatus } from '@pickme/core';

export const METHODS_AVAILABLE_FOR_PURCHASE = ['계좌이체', '카드', '가상계좌'];
export const AMOUNTS_AVAILABLE_FOR_PURCHASE = [1100, 5500, 11000, 55000, 110000];

export const POINT_AMOUNT_LIMITATION = {
  MIN: 1000,
  MAX: 500000,
};

export const CARD_NAME: { [key: string]: string } = {
  '3K': '기업 BC',
  '46': '광주은행',
  '71': '롯데카드',
  '30': 'KDB산업은행',
  '31': 'BC카드',
  '51': '삼성카드',
  '38': '새마을금고',
  '41': '신한카드',
  '62': '신협',
  '36': '씨티카드',
  '33': '우리BC카드(BC 매입)',
  W1: '우리카드(우리 매입)',
  '37': '우체국예금보험',
  '39': '저축은행중앙회',
  '35': '전북은행',
  '42': '제주은행',
  '15': '카카오뱅크',
  '3A': '케이뱅크',
  '24': '토스뱅크',
  '21': '하나카드',
  '61': '현대카드',
  '11': 'KB국민카드',
  '91': 'NH농협카드',
  '34': 'Sh수협은행',
  '': '',
};

export const BANKS = {
  경남은행: '39',
  광주은행: '34',
  KB국민은행: '06',
  IBK기업은행: '03',
  NH농협은행: '11',
  단위농협: '12',
  DGB대구은행: '31',
  부산은행: '32',
  KDB산업은행: '02',
  새마을금고: '45',
  산림조합: '64',
  Sh수협은행: '07',
  신한은행: '88',
  신협: '48',
  씨티은행: '27',
  우리은행: '20',
  우체국예금보험: '71',
  저축은행중앙회: '50',
  전북은행: '37',
  제주은행: '35',
  카카오뱅크: '90',
  케이뱅크: '89',
  토스뱅크: '92',
  하나은행: '81',
  SC제일은행: '23',
  홍콩상하이은행: '54',
};

export const VOTER_LIMIT = {
  MAX: 5000,
  MIN: 31,
};

export const TRANSFER_TRANSACTION_BADGES = {
  common: {
    variant: 'vivid-orange',
    text: '수락 완료',
  },
};

export const EXTRA_TRANSACTION_BADGES = {
  common: {
    variant: 'success',
    text: '지급 완료',
  },
};

export const CHARGE_TRANSACTION_BADGES: {
  [key in 'system-completed' | 'system-canceled' | ChargeStatus]: {
    variant: string;
    text: string;
  };
} = {
  'system-completed': {
    variant: 'success',
    text: '지급 완료',
  },
  'system-canceled': {
    variant: 'success',
    text: '환수 완료',
  },
  pending: {
    variant: 'gray',
    text: '결제 대기',
  },
  completed: {
    variant: 'primary',
    text: '결제 완료',
  },
  expired: {
    variant: 'gray',
    text: '입금 기한 만료',
  },
  canceled: {
    variant: 'error',
    text: '결제 취소 완료',
  },
  canceling: {
    variant: 'warning',
    text: '취소 처리중',
  },
};

export const PAY_METHOD_SELECT_ITEMS = [
  {
    label: '계좌이체',
    value: '계좌이체',
  },
  {
    label: '신용카드',
    value: '카드',
  },
  {
    label: '무통장 입금 (가상계좌)',
    value: '가상계좌',
  },
] as const;
