import moment from 'moment-timezone';
import { LineGraph, Text } from '@pickme/ui';
import { t } from 'i18next';
import { PollGroup } from '@pickme/core';

import { calculateYAxisHeight, setColors } from 'functions/poll-progress';
import { parseHourlyRates, makeLineChartLabels } from 'functions/poll-rate';

type Props = {
  group?: PollGroup;
  rateByHour?: { date: string; count: number; rate: number }[];
  endDate?: string;
  noAnimation?: boolean;
};

function InstantVoterProgressGraphSection({ group, rateByHour, endDate, noAnimation }: Props) {
  const { mainColor, lightColor } = setColors(group);
  const hourlyRates = parseHourlyRates(rateByHour || [], endDate || '');
  const labels = makeLineChartLabels(hourlyRates);

  return (
    <div className='poll-progress__section'>
      <div className='poll-progress__section__title'>
        <Text type='b1' fontWeight={600}>
          {t('admin:components.features.poll.progress.instantVoterProgressGraphSection.title')}
        </Text>
      </div>

      <div className='poll-progress__graph-container'>
        <div className='poll-progress__time-series-graph'>
          <LineGraph
            XLabels={labels}
            YLabels={{
              min: 0,
              max: calculateYAxisHeight(hourlyRates.map(({ count }) => count) || []),
            }}
            items={
              hourlyRates?.map(({ date, count }) => ({
                dataX: moment(date)
                  .tz('Asia/Seoul')
                  .format(
                    t(
                      'admin:components.features.poll.progress.instantVoterProgressGraphSection.format',
                    ),
                  ),
                dataY: Number(count),
                tooltipItems: [
                  {
                    label: t(
                      'admin:components.features.poll.progress.instantVoterProgressGraphSection.label',
                    ),
                    value: `${count}${t('admin:terms.unit.people')}`,
                  },
                ],
              })) || []
            }
            lightColor={lightColor}
            mainColor={mainColor}
            animation={!noAnimation}
          />
        </div>
      </div>
    </div>
  );
}

export default InstantVoterProgressGraphSection;
