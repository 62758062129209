export default {
  title: {
    application: '선거 만들기',
    draft: '선거 만들기',
    edit: '선거 수정하기',
  },
  stages: ['기본 정보', '투표 만들기', '대상자 설정', '고급 설정'],
  errorMessages: {
    payment: '결제 오류로 인해 구독 결제가 처리되지 못했어요',
  },
};
