import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PollFormStep } from 'types/application';

export function usePollFormStepFromHashRouter({
  isForthStepAccessible,
  isThirdStepAccessible,
  isSecondStepAccessible,
}: {
  isForthStepAccessible: boolean;
  isThirdStepAccessible: boolean;
  isSecondStepAccessible: boolean;
}) {
  const navigate = useNavigate();
  const { pathname, hash, search } = useLocation();
  const [step, setStep] = useState<PollFormStep>(0);

  useEffect(() => {
    if (hash === '#4' && isForthStepAccessible) {
      setStep(3);
    } else if (hash === '#3' && isThirdStepAccessible) {
      setStep(2);
    } else if (hash === '#2' && isSecondStepAccessible) {
      setStep(1);
    } else if (hash === '#1') {
      setStep(0);
    } else {
      navigate(`${pathname}${search}#1`, { replace: true });
    }
  }, [hash]);

  return step;
}
