import deletion from './deletion';
import email from './email';
import enrolledPhone from './enrolledPhone';
import enrollPhone from './enrollPhone';
import name from './name';
import password from './password';
import phone from './phone';
import phoneChange from './phoneChange';
import otpVerify from './otpVerify';

export default {
  deletion,
  email,
  enrolledPhone,
  enrollPhone,
  name,
  password,
  phone,
  phoneChange,
  otpVerify,
};
