import { useQueryParams } from 'hooks/useQueryParams';

import { PollSearchQuery } from 'types/poll';

export type HomePollSearchQuery = Required<Pick<PollSearchQuery, 'kind' | 'groups' | 'page'>>;

export const useHomePollSearchQueryParams = (query: HomePollSearchQuery) => {
  const parsedQuery = parseQuery(query);
  const [params, setParams] = useQueryParams(parsedQuery);

  const setPollSearchParams = (newQuery: HomePollSearchQuery) => {
    const parsedNewQuery = parseQuery(newQuery);
    setParams(parsedNewQuery);
  };

  const parsedParams = {
    ...params,
    page: Number(params.page),
  };

  return [parsedParams, setPollSearchParams] as const;
};

const parseQuery = (query: HomePollSearchQuery) => {
  const parsedQuery = {
    kind: query.kind,
    groups: query.groups,
    page: String(query.page),
  };

  return parsedQuery;
};
