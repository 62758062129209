import { OutlineButton, Text } from '@pickme/ui';
import { Link, useNavigate } from 'react-router-dom';
import { t } from 'i18next';

import Logo from 'resources/icons/nav/pickme_logo.png';

import './index.scss';

function Navbar() {
  const navigate = useNavigate();

  return (
    <header className='space-selector'>
      <div className='space-selector__container'>
        <Link to={import.meta.env.VITE_INTRO_URL}>
          <img className='space-selector__logo' src={Logo} alt='back' />
        </Link>

        <OutlineButton size='md' onClick={() => navigate('/account')}>
          <Text type='b3' fontWeight={600}>
            {t('admin:components.common.navbar.buttons.account')}
          </Text>
        </OutlineButton>
      </div>
    </header>
  );
}

export default Navbar;
