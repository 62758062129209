import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Text } from '@pickme/design-system';

import SocialIconButton from 'components/features/auth/SocialIconButton';
import SocialDisconnectModal from 'components/features/my-page/modal/SocialDisconnect';

import { useGetSessionAsUser } from 'query-hooks/auth';

import { socialSignIn } from 'functions/auth';

import { AuthMethodType, SocialProvider } from 'types/auth';

import { messageModal } from 'states/modal';

import { field } from '../common.css';
import { container, socialIcons } from './index.css';

function SocialIntegration() {
  const setMessageModal = useSetRecoilState(messageModal);
  const { data: userSessionData } = useGetSessionAsUser({ suspense: true });
  const primaryAuthMethod = userSessionData?.primaryAuthMethod;

  const [socialDisconnectModal, setSocialDisconnectModal] = useState<{
    provider: SocialProvider;
    isVisible: boolean;
  }>({
    provider: SocialProvider.Google,
    isVisible: false,
  });

  return (
    <>
      <div className={field.container}>
        <div className={field.label} />

        <div className={container}>
          <Text fontWeight={600}>SNS 연결</Text>
          <Text>연결된 SNS 계정으로 로그인할 수 있습니다</Text>

          <div className={socialIcons}>
            {Object.entries(SocialProvider).map(([_, provider]) => {
              const disableToDisconnect =
                primaryAuthMethod?.type === AuthMethodType.Social &&
                primaryAuthMethod.provider === provider;
              const hasSocialProviderInAuthMethods =
                userSessionData?.hasSocialProviderInAuthMethods(provider);

              return (
                <SocialIconButton
                  provider={provider}
                  onClick={() => {
                    if (!hasSocialProviderInAuthMethods) {
                      socialSignIn(provider);
                      return;
                    }

                    if (disableToDisconnect) {
                      setMessageModal({
                        isVisible: true,
                        title: '해제할 수 없어요',
                        message: '회원가입 시 연결한 SNS 계정은 연결을 해제할 수 없어요',
                        type: 'error',
                      });
                      return;
                    }

                    setSocialDisconnectModal({ isVisible: true, provider });
                  }}
                  inactive={!hasSocialProviderInAuthMethods}
                />
              );
            })}
          </div>
        </div>
      </div>

      <SocialDisconnectModal
        isVisible={socialDisconnectModal.isVisible}
        onClose={() => setSocialDisconnectModal({ ...socialDisconnectModal, isVisible: false })}
        provider={socialDisconnectModal.provider}
      />
    </>
  );
}

export default SocialIntegration;
