import balance from './modal/balance';
import channelConnect from './modal/channelConnect';
import channelRemove from './modal/channelRemove';
import channelVerify from './modal/channelVerify';
import notificationDetail from './modal/notificationDetail';
import pollSelect from './modal/pollSelect';
import emptyStateView from './emptyStateView';
import template from './template';

export default {
  modal: {
    balance,
    channelConnect,
    channelRemove,
    channelVerify,
    notificationDetail,
    pollSelect,
  },
  template,
  emptyStateView,
};
