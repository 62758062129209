import { button, container } from './index.css';

function Sidebar<T>({
  items,
  onSelect,
}: {
  items: { title: string; value: T; active?: boolean; selected?: boolean; disabled?: boolean }[];
  onSelect: (step: T) => void;
}) {
  return (
    <nav className={container}>
      {items.map((item) => (
        <button
          key={item.title}
          type='button'
          onClick={() => {
            if (item?.disabled) {
              return;
            }

            onSelect(item.value);
          }}
          // TODO: 아래 조건 변경하기.
          className={button({
            active: item.active,
            selected: item.selected,
          })}
        >
          {item.title}
        </button>
      ))}
    </nav>
  );
}

export default Sidebar;
