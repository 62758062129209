import SubPollCard from 'components/features/application/card/SubPoll';

import { QUESTION_KIND_BADGES } from 'constants/poll';
import { makeQuestionBadges } from 'functions/poll';

import { QuestionFormBody } from 'types/application';

type Props = {
  id: string;
  question: QuestionFormBody;
  disabled?: boolean;
  onClick: () => void;
  onRemove: () => void;
};

function SubQuestionCard({ id, question, disabled, onClick, onRemove }: Props) {
  const questionBadges = makeQuestionBadges(question);

  return (
    <SubPollCard
      id={id}
      disabled={disabled}
      onClick={onClick}
      onRemove={onRemove}
      kindBadge={{
        variant: QUESTION_KIND_BADGES[question?.kind].variant,
        text: QUESTION_KIND_BADGES[question?.kind].text,
      }}
      title={question?.title}
      badges={questionBadges}
    />
  );
}

export default SubQuestionCard;
