import { useGetOrganization } from 'query-hooks/organization';
import { useRecoilValue } from 'recoil';
import { spaceState } from 'states/space';

export const useGetActiveSpace = () => {
  const activeSpaceId = useRecoilValue(spaceState.activeSpaceId);
  const { data: space } = useGetOrganization('objectId', activeSpaceId);

  return space;
};
