import { useRecoilState, useSetRecoilState } from 'recoil';
import { Button, Modal, ModalHeader } from '@pickme/ui';
import { ChargeTransaction, ExtraTransaction, TransferTransaction } from '@pickme/core';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

import ChargeTransactionViewer from 'components/features/payment/modal/IncomingDetail/ChargeTransaction';
import ExtraTransactionViewer from 'components/features/payment/modal/IncomingDetail/ExtraTransaction';
import TransferTransactionViewer from 'components/features/payment/modal/IncomingDetail/TransferTransaction';

import { useGetIncoming } from 'query-hooks/payment';

import { cancelPointPurchaseModal, incomingDetailModal } from 'states/modal';

import './index.scss';

function IncomingDetailModal() {
  const [{ isVisible, chargeId, paymentOid }, setIncomingDetailModal] =
    useRecoilState(incomingDetailModal);
  const setCancelPointPurchaseModal = useSetRecoilState(cancelPointPurchaseModal);
  const { data, isLoading } = useGetIncoming(chargeId);

  const onClose = () => {
    setIncomingDetailModal({ isVisible: false, chargeId, paymentOid });
    setTimeout(() => {
      setIncomingDetailModal({ isVisible: false, chargeId: '', paymentOid: '' });
    }, 500);
  };

  return (
    <Modal className='incoming-detail-modal' size='lg' isVisible={isVisible} onClose={onClose}>
      <ModalHeader
        title={t('admin:components.features.payment.modal.incomingDetail.title')}
        onClose={onClose}
      />

      {/* TODO: 스켈레톤 추가 */}
      {data instanceof ChargeTransaction && (
        <>
          <ChargeTransactionViewer chargeTransaction={data} />
          <IncomingDetailModalActions
            data={data}
            isLoading={isLoading}
            onCancelButtonClick={() => {
              setCancelPointPurchaseModal({
                isVisible: true,
                chargeId,
                paymentOid,
              });
              onClose();
            }}
          />
        </>
      )}
      {data instanceof ExtraTransaction && <ExtraTransactionViewer extraTransaction={data} />}
      {data instanceof TransferTransaction && (
        <TransferTransactionViewer transferTransaction={data} />
      )}
      {!data && <div className='incoming-detail-modal__body--dummy' />}
    </Modal>
  );
}

export default IncomingDetailModal;

function IncomingDetailModalActions({
  data,
  isLoading,
  onCancelButtonClick,
}: {
  data: ChargeTransaction;
  isLoading: boolean;
  onCancelButtonClick: () => void;
}) {
  return (
    <div className='pickme-modal__actions'>
      {data?.details.receiptUrl && (
        <Button
          width='100%'
          size='md'
          variant='primary'
          onClick={() => window.open(data.details.receiptUrl)}
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            t('admin:components.features.payment.modal.incomingDetail.buttons.print')
          )}
        </Button>
      )}

      {data?.isCancelable && (
        <Button
          width='100%'
          size='md'
          variant='gray'
          onClick={() => {
            onCancelButtonClick();
          }}
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            t('admin:components.features.payment.modal.incomingDetail.buttons.cancel')
          )}
        </Button>
      )}
    </div>
  );
}
