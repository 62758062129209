export default {
  title: '새로 만들기',
  items: {
    election: {
      title: '선거',
      description: '조직의 대표자 선출을 위한 선거를 만들어보세요',
    },
    survey: {
      title: '설문',
      description: '구성원의 의견수렴을 위한 설문조사를 만들어보세요',
    },
  },
  button: '만들기',
};
