export default {
  title: '포인트 충전',
  table: {
    header: {
      incoming: {
        date: '일시',
        payment: '금액',
        point: '포인트',
        kind: '분류',
        status: '상태',
        detail: '내역',
      },
      usage: {
        date: '일시',
        category: '분류',
        change: '포인트 변동',
        point: '변동 후 포인트',
      },
    },
  },
  labels: {
    incoming: {
      charge: '충전 포인트',
      extra: '적립 포인트',
      transfer: '선물 포인트',
    },
    usage: {
      transfer: '포인트 선물',
      refund: '실패건 환급',
      sending: '알림톡 전송',
    },
    detail: '상세 내역',
  },
  search: {
    result: {
      blank: {
        incoming: '포인트 충전 내역이 없어요',
        usage: '포인트 사용 내역이 없어요',
      },
    },
  },
};
