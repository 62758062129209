import { range } from 'lodash-es';
import { PlainButton, Text } from '@pickme/ui';

import PreviousIcon from 'resources/icons/application/previous.png';
import NextIcon from 'resources/icons/application/next.png';

import './index.scss';

type Props = {
  page: number;
  endPage: number;
  setPage: (page: number) => void;
};

function pageRange(page: number, pageCount: number) {
  let start = page - 2;
  let end = page + 2;

  if (end > pageCount) {
    start -= end - pageCount;
    end = pageCount;
  }
  if (start <= 0) {
    end += (start - 1) * -1;
    start = 1;
  }

  end = end > pageCount ? pageCount : end;

  return range(start, end + 1);
}

function Pagination({ page, endPage, setPage }: Props) {
  return (
    <div className='voter-form__pagination'>
      <PlainButton
        className='voter-form__pagination__button'
        type='button'
        disabled={page <= 1}
        onClick={() => setPage(page - 1)}
      >
        <img src={PreviousIcon} alt='previous' />
      </PlainButton>
      {pageRange(page, endPage).map((pageNumber) => (
        <PlainButton
          className={`voter-form__pagination__button ${page === pageNumber ? 'active' : ''}`}
          key={pageNumber}
          type='button'
          onClick={() => setPage(pageNumber)}
        >
          <Text
            type='lab1'
            color={page === pageNumber ? 'primary-500' : 'gray-400'}
            fontWeight={400}
          >
            {pageNumber}
          </Text>
        </PlainButton>
      ))}

      <PlainButton
        className='voter-form__pagination__button'
        type='button'
        disabled={page >= endPage}
        onClick={() => setPage(page + 1)}
      >
        <img src={NextIcon} alt='next' />
      </PlainButton>
    </div>
  );
}

export default Pagination;
