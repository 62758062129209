import { Controller, useFormContext } from 'react-hook-form';
import { Editor } from '@pickme/ui';
import { Input, Select } from '@pickme/design-system';

import SendFieldRow from 'components/features/notification/fields/send/Row';

import {
  EDITOR_TEMPLATES,
  EDITOR_TEMPLATE_VARIABLES,
  MESSAGING_DEFAULT_SENDER,
  TEMPLATE_TYPE_SELECT_ITEMS,
} from 'constants/notification';

import { EmailForm, TemplateType } from 'types/message';

import { container } from 'styles/page.css';
import { sender, titleInput } from '../common.css';

function EmailEditorField() {
  const methods = useFormContext<EmailForm>();

  return (
    <div className={container({ gap: 16 })}>
      <SendFieldRow title='발신 이메일' align='center'>
        <div className={sender}>{MESSAGING_DEFAULT_SENDER.email}</div>
      </SendFieldRow>

      <SendFieldRow title='템플릿 선택' align='center'>
        <Controller
          control={methods.control}
          name='templateType'
          render={({ field }) => (
            <Select
              {...field}
              width='md'
              placeholder='템플릿 선택'
              items={TEMPLATE_TYPE_SELECT_ITEMS}
              selected={field.value}
              onSelect={(templateType) => {
                const template = EDITOR_TEMPLATES[templateType as TemplateType];
                methods.setValue('subject', `[제목] ${template.subject}`);
                methods.setValue('template', template.contents);
                field.onChange(templateType);
              }}
            />
          )}
        />
      </SendFieldRow>

      <SendFieldRow title='제목' align='center'>
        <Input
          {...methods.register('subject')}
          placeholder='제목을 작성해 주세요'
          size='md'
          className={titleInput}
        />
      </SendFieldRow>

      <Controller
        control={methods.control}
        name='template'
        render={({ field }) => (
          <Editor
            hideMediaMenu
            templates={EDITOR_TEMPLATE_VARIABLES}
            mode='update'
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
    </div>
  );
}

export default EmailEditorField;
