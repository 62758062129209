export default {
  title: '결제 취소 신청',
  message: '결제 취소 신청 후 환불까지 영업일 기준 1 ~ 3일 정도 소요될 수 있습니다.',
  field: {
    bank: {
      label: '은행',
      validate: '은행을 선택해주세요',
    },
    depositor: {
      label: '예금주',
      validate: '예금주명을 입력해주세요',
      placeholder: '예금주명을 입력해주세요',
    },
    account: {
      label: '계좌번호',
      validate: '계좌번호를 입력해주세요',
      placeholder: '계좌번호를 입력해주세요',
    },
    reason: {
      label: '결제 취소 사유',
      validate: '결제 취소 사유를 입력해주세요',
    },
  },
  buttons: {
    cancel: '취소',
    submit: '신청하기',
  },
};
