export default {
  labels: {
    total: '사용 가능 포인트',
    charge: '충전 포인트',
    save: '적립 받은 포인트',
    gift: '선물 가능 포인트',
  },
  buttons: {
    charge: '충전하기',
    save: '포인트 받기',
    gift: '선물하기',
  },
};
