import { debounce } from 'lodash-es';
import { useEffect, useState } from 'react';
import { breakpoints } from '@pickme/design-system';

export const useWindowSize = (delayTime = 100) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize(window.innerWidth);
    }, delayTime);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [delayTime]);

  const isMobileSize = windowSize <= breakpoints.mobile;
  const isDesktopSize = windowSize > breakpoints.mobile;

  return { isMobileSize, isDesktopSize };
};
