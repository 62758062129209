import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Callout, Table, Pagination, PlainButton, Text } from '@pickme/design-system';

import Spinner from 'components/common/Spinner';
import MessagingMethodSelect from 'components/features/notification/history/MessagingMethodSelect';
import NotificationEmptyStateView from 'components/features/notification/EmptyStateView';
import ReservedMessagingDetailModal from 'components/features/notification/modal/messaging-detail/Reserved';

import { useGetMessages } from 'query-hooks/message';

import { MESSAGING_METHOD_LABELS } from 'constants/notification';

import { MessagingSearchForm, MessagingStatus, PollMessaging } from 'types/message';

import { useMessagingSearchParams } from '../useMessagingSearchParams';

import { container, search, alignCenter, tableRow } from '../common.css';

function Reserved() {
  const [detailModal, setDetailModal] = useState<{
    isVisible: boolean;
    messaging: PollMessaging | null;
  }>({
    isVisible: false,
    messaging: null,
  });

  const methods = useForm<MessagingSearchForm>({
    defaultValues: DEFAULT_VALUES,
  });

  const [params, setParams] = useMessagingSearchParams(DEFAULT_VALUES);
  const { data: messagesData, isLoading } = useGetMessages(params);
  const isTableVisible = isLoading || (messagesData && messagesData.results.length > 0);

  const onFormSubmit = methods.handleSubmit((data) => setParams({ ...data, page: 1 }));

  useEffect(() => {
    methods.reset(params);
  }, [params.page, params.methodType, params.month]);

  return (
    <div className={container}>
      <Callout>
        <Callout.Sentence>전송 예약 설정한 목록을 확인할 수 있어요.</Callout.Sentence>
        <Callout.Sentence>투표를 삭제하면 예약이 자동으로 취소돼요.</Callout.Sentence>
      </Callout>

      <form className={search} onSubmit={onFormSubmit}>
        <Controller
          control={methods.control}
          name='methodType'
          render={({ field }) => (
            <MessagingMethodSelect
              {...field}
              selected={field.value}
              onSelect={(methodType) => {
                field.onChange(methodType);
                onFormSubmit();
              }}
            />
          )}
        />
      </form>

      {isTableVisible ? (
        <>
          <Table>
            <colgroup>
              <col />
              <col width={108} />
              <col width={156} />
              <col width={96} />
              <col width={96} />
            </colgroup>

            <Table.Header>
              <Table.Tr>
                <Table.Th textAlign='start'>제목</Table.Th>
                <Table.Th>타입</Table.Th>
                <Table.Th>전송 예정 일시</Table.Th>
                <Table.Th>예상 사용 포인트</Table.Th>
                <Table.Th>내역</Table.Th>
              </Table.Tr>
            </Table.Header>

            <Table.Body>
              {isLoading ? (
                <Table.Tr>
                  <Table.Td colSpan={6}>
                    <Spinner />
                  </Table.Td>
                </Table.Tr>
              ) : (
                messagesData?.results.map((messaging) => (
                  <Table.Tr key={messaging._id} className={tableRow}>
                    <Table.Td textAlign='start'>{messaging.pollName}</Table.Td>

                    <Table.Td>{MESSAGING_METHOD_LABELS[messaging.detail.type]}</Table.Td>

                    <Table.Td>
                      {dayjs(messaging.sendRequestDate).format('YY.MM.DD (dd) HH:mm')}
                    </Table.Td>

                    <Table.Td>{messaging.usageAmount}P</Table.Td>

                    <Table.Td className={alignCenter}>
                      <PlainButton onClick={() => setDetailModal({ isVisible: true, messaging })}>
                        <Text color='primary-500'>상세 내역</Text>
                      </PlainButton>
                    </Table.Td>
                  </Table.Tr>
                ))
              )}
            </Table.Body>
          </Table>

          {!isLoading && (
            <Pagination
              currentPage={params.page}
              totalPage={messagesData?.endPage || 1}
              onMove={(newPage) => setParams({ ...params, page: newPage })}
            />
          )}
        </>
      ) : (
        <NotificationEmptyStateView type='reserved' />
      )}

      <ReservedMessagingDetailModal
        messaging={detailModal.messaging}
        isVisible={detailModal.isVisible}
        onClose={() => setDetailModal({ ...detailModal, isVisible: false })}
      />
    </div>
  );
}

export default Reserved;

const DEFAULT_VALUES: MessagingSearchForm = {
  page: 1,
  month: 'all',
  methodType: 'all',
  status: [MessagingStatus.Pending],
};
