import { Text, IconInput, Button, Spinner } from '@pickme/ui';
import { FormProvider, useForm } from 'react-hook-form';
import { t } from 'i18next';

import { emailValidator } from 'functions/validator/auth';

import { SendResetMailBody } from 'types/auth';

import EmailIcon from 'resources/icons/auth/email.png';

import './index.scss';

type Props = {
  isLoading: boolean;
  sendMail: (email: string) => void;
};

function SendRegisterMailForm({ isLoading, sendMail }: Props) {
  const methods = useForm<SendResetMailBody>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const onSubmit = (formData: SendResetMailBody) => {
    sendMail(formData.email);
  };

  return (
    <FormProvider {...methods}>
      <form className='register-mail-form' onSubmit={handleSubmit(onSubmit)}>
        <Text size='md1'>{t('admin:components.features.auth.form.sendRegisterMail.title')}</Text>
        <div className='auth-form__description'>
          <Text type='b3'>
            {t('admin:components.features.auth.form.sendRegisterMail.description')}
          </Text>
        </div>
        <div className='auth-form__input-area'>
          <IconInput
            width='100%'
            size='lg'
            icon={EmailIcon}
            placeholder={t(
              'admin:components.features.auth.form.sendRegisterMail.fields.email.placeholder',
            )}
            type='email'
            id='email'
            {...register('email', {
              validate: emailValidator,
              required: true,
              setValueAs: (value) => value?.trim().toLowerCase(),
            })}
            isValid={!errors.email}
            errorMessage={
              errors.email
                ? t('admin:components.features.auth.form.sendRegisterMail.errorMessages.wrongEmail')
                : ''
            }
          />
        </div>

        <Button
          width='100%'
          size='xl'
          variant='primary'
          type='submit'
          disabled={!isValid || isLoading}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            t('admin:components.features.auth.form.sendRegisterMail.buttons.submit')
          )}
        </Button>
      </form>
    </FormProvider>
  );
}

export default SendRegisterMailForm;
