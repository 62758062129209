import { useForm } from 'react-hook-form';
import { Button, Text, Textarea } from '@pickme/design-system';

import Spinner from 'components/common/Spinner';

import { useSendFeedback } from 'query-hooks/feedback';
import { useGetSessionAsMember } from 'query-hooks/auth';

import { useGetActiveSpace } from 'hooks/useGetActiveSpace';

import { container, submitButton } from './index.css';

function FeedBack() {
  const methods = useForm<{ text: string }>();
  const { mutate, isLoading } = useSendFeedback();
  const { data: member } = useGetSessionAsMember();
  const activeSpace = useGetActiveSpace();

  const onSubmit = (data: { text: string }) => {
    if (activeSpace && activeSpace.subscription) {
      mutate({
        email: member?.email ?? member?.phoneNumber ?? '',
        spaceName: activeSpace.name,
        plan: activeSpace.subscription.type,
        text: data.text,
      });
    }
  };

  return (
    <div className={container}>
      <Text size={32} fontWeight={700}>
        피드백
      </Text>

      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Textarea
          {...methods.register('text', {
            required: true,
          })}
          placeholder='불편했던 점, 개선했으면 하는 점이 있다면 얼마든지 적어주세요!'
        />

        <Button type='submit' size='md' className={submitButton} fullWidth>
          {isLoading ? <Spinner /> : '제출'}
        </Button>
      </form>
    </div>
  );
}

export default FeedBack;
