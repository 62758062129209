import clsx from 'clsx';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { PollGroup } from '@pickme/core';
import { Dropdown, Text } from '@pickme/design-system';

import { PollActionType } from 'components/features/modal/PollActionModal';

import { useGetSessionAsMember } from 'query-hooks/auth';

import { pollActionModal } from 'states/modal';

import {
  GROUPS_ABLE_TO_CANCEL,
  GROUPS_ABLE_TO_EARLY_EXIT,
  GROUPS_ABLE_TO_STOP,
} from 'constants/poll';

import { Poll } from 'types/poll';

import MoreIcon from 'resources/icons/dashboard/vertical_dots.svg';

import { trigger } from '../common.css';

type Props = {
  poll: Poll;
  triggerClassName?: string;
};

export const DROPDOWN_CLASS = 'click-event-disabled-dropdown';

function PollMoreDropdown({ triggerClassName, poll }: Props) {
  const navigate = useNavigate();
  const setPollActionModal = useSetRecoilState(pollActionModal);
  const { data: memberSessionData } = useGetSessionAsMember();

  const isUserManageable =
    memberSessionData?.level === 'OrganAdmin' ||
    (memberSessionData?.level === 'SubAdmin' && memberSessionData?.id === poll?.authorId);

  const disableToCancel = GROUPS_ABLE_TO_CANCEL.includes(poll.group) === false;
  const disableToEarlyExit = GROUPS_ABLE_TO_EARLY_EXIT.includes(poll.group) === false;
  const disableToRemove = !isUserManageable || poll.group === PollGroup.Ongoing;
  const disableToRestart = GROUPS_ABLE_TO_STOP.includes(poll.group) === false;

  const openPollActionModal = (type: PollActionType) => {
    setPollActionModal({ type, id: poll._id, kind: poll.kind, isVisible: true });
  };

  return (
    <Dropdown className={DROPDOWN_CLASS}>
      <Dropdown.Trigger hideArrow className={clsx(trigger, triggerClassName)}>
        <img src={MoreIcon} alt='open poll menu' />
      </Dropdown.Trigger>

      {/* TODO: 무효, 개표가 없어지고 복제 기능이 추가될 예정입니다. */}
      <Dropdown.Menu direction='right' directionBase='top'>
        {poll.group === PollGroup.Paused ? (
          <Dropdown.Item onClick={() => openPollActionModal('restart')} disabled={disableToRestart}>
            재시작
          </Dropdown.Item>
        ) : (
          <Dropdown.Item onClick={() => openPollActionModal('pause')} disabled={disableToRestart}>
            일시 중지
          </Dropdown.Item>
        )}

        <Dropdown.Item
          onClick={() => openPollActionModal('early-exit')}
          disabled={disableToEarlyExit}
        >
          강제 종료
        </Dropdown.Item>

        <Dropdown.Item onClick={() => openPollActionModal('cancel')} disabled={disableToCancel}>
          무효 처리
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() =>
            navigate(`/service/notification/send/alimTalk?pollId=${poll._id}`, {
              unstable_viewTransition: true,
            })
          }
        >
          메시지 보내기
        </Dropdown.Item>

        <Dropdown.Item onClick={() => openPollActionModal('remove')} disabled={disableToRemove}>
          <Text size={14} fontWeight={500} color='red-500'>
            삭제
          </Text>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default PollMoreDropdown;
