import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import moment from 'moment-timezone';
import {
  Amount,
  Plan,
  PointAmountConverter,
  Account,
  TransferTransaction,
  ChargeTransaction,
  ExtraTransaction,
  MessagingTransaction,
} from '@pickme/core';

import { t } from 'i18next';

import {
  createCancelChargeRequest,
  createGetAccountRequest,
  createGetIncomingRequest,
  createGetIncomingsRequest,
  createGetUsagesRequest,
  createGetOrderId,
  createPurchasePointRequest,
  createTransferPointRequest,
  createGetPaymentRequest,
  createGetPaymentRecordsRequest,
  createGetPaymentRecordRequest,
  createReplacePlanRequest,
  createCancelPlanReplacementRequest,
  createReplacePaymentEmailRequest,
  createPurchaseMoreVotersRequest,
  createCancelReplaceVoterLimitRequest,
  createReplaceCardEmailRequest,
  createGetSubscriptionHistoryRequest,
} from 'apis/payment';

import { incomingDetailModal, messageModal } from 'states/modal';
import { toast } from 'states/toast';

import { ChargeCancelForm, Incoming, Usage } from 'types/payment';

export const useGetPayment = () =>
  useQuery('payment', async () => {
    const { data } = await createGetPaymentRequest();

    return data;
  });

export const useGetAccount = () =>
  useQuery('account', async () => {
    const { data } = await createGetAccountRequest();

    const account = new Account(data);
    return account;
  });

export const useGetIncoming = (incomingId: string) =>
  useQuery(
    ['incoming', incomingId],
    async () => {
      const { data } = await createGetIncomingRequest(incomingId);

      if (data?.kind === 'TransferTransaction') {
        return new TransferTransaction(data);
      }

      if (data?.kind === 'ChargeTransaction') {
        return new ChargeTransaction(data);
      }

      if (data?.kind === 'ExtraTransaction') {
        return new ExtraTransaction(data);
      }

      return data;
    },
    {
      enabled: !!incomingId,
    },
  );

export const useGetIncomings = (page: number) =>
  useQuery(
    ['incomings', page],
    async () => {
      const { data } = await createGetIncomingsRequest(page);

      const incomings = data.transactions.map((incoming: Incoming) => {
        if (incoming?.kind === 'TransferTransaction') {
          return new TransferTransaction(incoming);
        }

        if (incoming?.kind === 'ChargeTransaction') {
          return new ChargeTransaction(incoming);
        }

        return new ExtraTransaction(incoming);
      });

      return { ...data, transactions: incomings };
    },
    {
      staleTime: 30 * 1000,
      cacheTime: 30 * 1000,
    },
  );

export const useGetUsages = (page: number) =>
  useQuery(
    ['usages', page],
    async () => {
      const { data } = await createGetUsagesRequest(page);
      const usages = data.transactions.map((usage: Usage) => {
        if (usage.kind === 'MessagingTransaction') {
          return new MessagingTransaction(usage);
        }

        return new TransferTransaction(usage);
      });

      return { ...data, transactions: usages };
    },
    {
      staleTime: 30 * 1000,
      cacheTime: 30 * 1000,
    },
  );

export const useGetOrderId = () =>
  useQuery(
    'orderId',
    async () => {
      const { data } = await createGetOrderId();

      return data;
    },
    {
      onError: () => {
        alert(t('admin:query-hooks.payment.useGetOrderId.onError.toast'));
      },
      staleTime: 30 * 1000,
      cacheTime: 30 * 1000,
      enabled: false,
    },
  );

export const useCreatePurchasePoint = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const setMessageModal = useSetRecoilState(messageModal);

  return useMutation(
    async ({
      orderId,
      paymentKey,
      amount,
    }: {
      orderId: string;
      paymentKey: string;
      amount: number;
    }) => {
      const point = PointAmountConverter.convert(new Amount(amount));
      const { data } = await createPurchasePointRequest(orderId, paymentKey, amount, point.value);

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('incomings');
        queryClient.invalidateQueries('orderId');
        queryClient.invalidateQueries('account');

        navigate('/service/notification/point', { replace: true });
      },
      onError: (error: any) => {
        queryClient.invalidateQueries('incomings');
        queryClient.invalidateQueries('orderId');
        queryClient.invalidateQueries('account');

        navigate('/service/notification/point', { replace: true });

        setMessageModal({
          isVisible: true,
          title: t('admin:query-hooks.payment.useCreatePurchasePoint.onError.modal.title'),
          // TODO: Fix error message;
          message: t('admin:query-hooks.payment.useCreatePurchasePoint.onError.modal.message', {
            text: error.message,
          }),
        });
      },
    },
  );
};

export const useCancelCharge = () => {
  const queryClient = useQueryClient();
  const setIncomingDetailModal = useSetRecoilState(incomingDetailModal);

  return useMutation(
    async (form: ChargeCancelForm) => {
      const { data } = await createCancelChargeRequest(form);

      return data;
    },
    {
      onSuccess: (_, { chargeId, paymentOid }) => {
        queryClient.invalidateQueries('incomings');
        queryClient.invalidateQueries('incoming');
        queryClient.invalidateQueries('orderId');
        queryClient.invalidateQueries('account');

        setIncomingDetailModal({ isVisible: true, chargeId, paymentOid });
      },
      onError: () => {
        queryClient.invalidateQueries('incomings');
        queryClient.invalidateQueries('incoming');
        queryClient.invalidateQueries('orderId');
        queryClient.invalidateQueries('account');
      },
    },
  );
};

export const useTransferPoint = () => {
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    async ({ adminId, point }: { adminId: string; point: number }) => {
      const { data } = await createTransferPointRequest({
        adminId,
        amount: point,
      });

      return data;
    },
    {
      onSuccess: () => {
        setToast({
          isVisible: true,
          type: 'success',
          message: t('admin:query-hooks.payment.useTransferPoint.onSuccess.toast'),
        });

        navigate('/service/notification/point/usage');
        queryClient.invalidateQueries('incomings');
        queryClient.invalidateQueries('usages');
        queryClient.invalidateQueries('incoming');
        queryClient.invalidateQueries('orderId');
        queryClient.invalidateQueries('account');
      },
      onError: (error: AxiosError) => {
        setToast({
          type: 'error',
          isVisible: true,
          message: error.message,
        });

        queryClient.invalidateQueries('incomings');
        queryClient.invalidateQueries('incoming');
        queryClient.invalidateQueries('orderId');
        queryClient.invalidateQueries('account');
      },
    },
  );
};

export const useReplaceCard = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const setMessageModal = useSetRecoilState(messageModal);
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ authKey }: { authKey: string }) => {
      const { data } = await createReplaceCardEmailRequest(authKey);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.resetQueries('auth');
        queryClient.resetQueries('organization');
        queryClient.resetQueries('organizations');
        queryClient.resetQueries('payment');

        setToast({
          isVisible: true,
          type: 'success',
          message: t('admin:query-hooks.payment.useReplaceCard.onSuccess.toast'),
        });

        navigate('/settings/payment/subscription', { replace: true });
      },
      onError: (error: any) => {
        navigate('/settings/payment/subscription', { replace: true });
        setMessageModal({
          isVisible: true,
          title: t('admin:query-hooks.payment.useReplaceCard.onError.modal.title'),
          // TODO: Fix error message;
          message: t('admin:query-hooks.payment.useReplaceCard.onError.modal.message', {
            text: error.message,
          }),
        });
      },
    },
  );
};

export const useGetPaymentRecords = (page: number) =>
  useQuery(
    ['payment-records', page],
    async () => {
      const { data } = await createGetPaymentRecordsRequest(page);

      return data;
    },
    {
      staleTime: 30 * 1000,
      cacheTime: 30 * 1000,
    },
  );

export const useGetPaymentRecord = (id: string) =>
  useQuery(
    ['payment-record', id],
    async () => {
      const { data } = await createGetPaymentRecordRequest(id);

      return data;
    },
    {
      enabled: !!id,
    },
  );

export const useReplacePlan = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ plan }: { plan: Plan }) => {
      const { data } = await createReplacePlanRequest(plan);
      return data;
    },
    {
      onSuccess: (_, { plan }) => {
        queryClient.resetQueries('auth');
        queryClient.resetQueries('payment-records');
        queryClient.resetQueries('payment');
        queryClient.resetQueries('organization');

        setToast({
          isVisible: true,
          type: 'notification',
          message:
            plan === Plan.Free
              ? t('admin:query-hooks.payment.useReplacePlan.onSuccess.toast.pause')
              : t('admin:query-hooks.payment.useReplacePlan.onSuccess.toast.replace'),
        });

        navigate('/settings/payment/subscription', { replace: true });
      },
    },
  );
};

export const useCancelPlanReplace = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    // TODO: 해결할 것.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (_: { isDiscard?: boolean }) => {
      const { data } = await createCancelPlanReplacementRequest();
      return data;
    },
    {
      onSuccess: (_, { isDiscard }) => {
        queryClient.resetQueries('auth');
        queryClient.resetQueries('payment-records');
        queryClient.resetQueries('payment');
        queryClient.resetQueries('organization');

        if (isDiscard) {
          setToast({
            isVisible: true,
            type: 'notification',
            message: t('admin:query-hooks.payment.useCancelPlanReplace.onSuccess.toast.pause'),
          });
        } else {
          setToast({
            isVisible: true,
            type: 'notification',
            message: t('admin:query-hooks.payment.useCancelPlanReplace.onSuccess.toast.replace'),
          });
        }
      },
    },
  );
};

export const useReplacePaymentEmail = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async (email: string) => {
      const { data } = await createReplacePaymentEmailRequest(email);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.resetQueries('payment-records');
        queryClient.resetQueries('auth');
        queryClient.resetQueries('payment');
        queryClient.resetQueries('organization');

        setToast({
          isVisible: true,
          type: 'success',
          message: t('admin:query-hooks.payment.useReplacePaymentEmail.onSuccess.toast'),
        });
      },
    },
  );
};

export const usePurchaseMoreVoters = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ voterLimit, spaceId }: { voterLimit: number; spaceId: string }) => {
      const { data } = await createPurchaseMoreVotersRequest(spaceId, voterLimit);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.resetQueries('payment-records');
        queryClient.resetQueries('payment');
        queryClient.resetQueries('organization');

        setToast({
          isVisible: true,
          type: 'notification',
          message: t('admin:query-hooks.payment.usePurchaseMoreVoters.onSuccess.toast'),
        });
      },
    },
  );
};

export const useCancelReplaceVoterLimit = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    // TODO: 해결할 것.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (_: { isDiscard?: boolean }) => {
      const { data } = await createCancelReplaceVoterLimitRequest();
      return data;
    },
    {
      onSuccess: (_, { isDiscard }) => {
        queryClient.resetQueries('payment-records');
        queryClient.resetQueries('payment');
        queryClient.resetQueries('organization');

        if (!isDiscard) {
          setToast({
            isVisible: true,
            type: 'notification',
            message: t('admin:query-hooks.payment.useCancelReplaceVoterLimit.onSuccess.toast'),
          });
        }
      },
    },
  );
};

export const useGetSubscriptionHistory = () =>
  useQuery(
    ['subscription', 'history'],
    async () => {
      const { data } = await createGetSubscriptionHistoryRequest();

      const sortedSubscriptionHistory = data
        .slice()
        .sort((prev, next) => (moment(next.period.start).isAfter(prev.period.start) ? 1 : -1));
      return sortedSubscriptionHistory;
    },
    {
      staleTime: 10 * 1000,
      cacheTime: 10 * 1000,
    },
  );
