export default {
  title: '리소스',
  cards: [
    { title: '이용 가이드', description: '픽미팀이 제공하는 서비스 이용 가이드를 확인해보세요' },
    { title: 'FAQ', description: '사용자 분들이 자주 묻는 질문들 위주로 정리했어요' },
    {
      title: '블로그',
      description: '업데이트 소식, 인사이트, 투표 사례 등 유용한 최신 소식을 전해드려요',
    },
    { title: '홈페이지', description: '픽미의 메인! 홈페이지를 확인할 수 있어요' },
  ],
};
