export default {
  buttons: {
    submit: '인증하기',
    retry: '다시 시도해주세요',
  },
  labels: {
    retry: '인증번호를 받지 못하셨나요?',
  },
  description: '인증번호를 발송했어요\n\n\n받으신 인증번호를 입력해주세요',
  modal: {
    retry: {
      title: '인증번호 재전송',
      message: '{{email}}로 새로운 인증번호를 전송했어요',
    },
  },
};
