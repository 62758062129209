import { useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, Spinner } from '@pickme/ui';
import { Text, OutlineButton, Button } from '@pickme/design-system';

import { useTransferPoint } from 'query-hooks/payment';

import { formatNumber } from 'functions/utils';

import { Manager } from 'types/manager';

import ArrowIcon from 'resources/icons/payment/prev.svg';

import { buttons, card, pointInput } from '../common.css';
import { notice, receiverLabel } from './index.css';

type Props = {
  isVisible: boolean;
  receiver: Manager;
  point: number;
  onClose: () => void;
  onPrevious: () => void;
};

function TransferPointConfirmModal({ isVisible, receiver, point, onClose, onPrevious }: Props) {
  const { isLoading, mutate, isSuccess } = useTransferPoint();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return (
    <Modal size='xl' isVisible={isVisible} onClose={onClose} className='transfer-point-confirm'>
      <ModalHeader title='선물하기' onClose={onClose} hideCloseButton />

      <ModalBody>
        <Text size={16} fontWeight={500} className={notice}>
          선물할 정보를 한번 더 확인해주세요 :)
        </Text>

        <div className={card.container}>
          <div className={card.left}>
            <Text>선물할 포인트</Text>

            <div className={pointInput.container}>
              <Text size={26} fontWeight={500} color='primary-500'>
                {formatNumber(point)}
              </Text>
              <Text color='primary-500'>P</Text>
            </div>
          </div>

          <div className={card.right}>
            <Text>받는 사람</Text>

            <div className={receiverLabel}>
              <Text size={26} fontWeight={500} color='primary-500'>
                {receiver.name}
              </Text>
              <Text color='gray-600'>/</Text>
              <Text color='gray-600'>{receiver.email}</Text>
            </div>
          </div>
        </div>
      </ModalBody>

      <div className={buttons.container}>
        <OutlineButton
          size='lg'
          variant='gray'
          onClick={onPrevious}
          width={108}
          className={buttons.button}
        >
          <img src={ArrowIcon} alt='previous' />
          이전
        </OutlineButton>

        <div className={buttons.rightSection}>
          <OutlineButton
            size='lg'
            variant='gray'
            onClick={onClose}
            width={108}
            className={buttons.button}
          >
            닫기
          </OutlineButton>

          <Button
            size='lg'
            onClick={() => mutate({ adminId: receiver.id, point })}
            width={144}
            disabled={isLoading}
            className={buttons.button}
          >
            {isLoading ? <Spinner /> : '선물하기'}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default TransferPointConfirmModal;
