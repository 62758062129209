export default {
  title: '투표 관리',
  table: {
    header: {
      plan: '플랜',
      kind: '분류',
      name: '투표 제목',
      period: '투표 기간',
      count: '총 대상자 수',
      author: '관리자',
    },
  },
  search: {
    result: {
      blank: '표시할 내용이 없습니다.',
    },
  },
  callout: [
    '투표 진행 기록을 확인할 수 있어요',
    '유료 플랜을 구독하고 있는 경우 생성 가능한 투표 개수를 확인할 수 있어요.',
  ],
  card: {
    stat: {
      title: '누적 투표 수',
    },
    subscription: {
      title: {
        before: '완료된 투표 수',
        now: '진행한 투표 수',
      },
    },
  },
};
