import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody, Text, Input, ModalActions, Spinner } from '@pickme/ui';
import { t } from 'i18next';

import './index.scss';

type Props = {
  isVisible: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (code: string) => void;
};

function ChannelVerifyModal({ isVisible, isLoading, onClose, onSubmit }: Props) {
  const methods = useForm<{ code: string }>();

  useEffect(() => {
    if (isVisible) {
      methods.reset();
    }
  }, [isVisible]);

  return (
    <Modal
      size='md'
      isVisible={isVisible}
      onClose={() => onClose()}
      className='channel-connect-verify-modal'
    >
      <ModalHeader
        title={t('admin:components.features.notification.modal.channelVerify.title')}
        onClose={onClose}
      />

      <ModalBody>
        <div className='channel-connect-verify-modal__body'>
          <Text type='b3' fontWeight={400} color='gray-400'>
            {t('admin:components.features.notification.modal.channelVerify.message')}
          </Text>

          <Input
            placeholder={t(
              'admin:components.features.notification.modal.channelVerify.placeholder',
            )}
            {...methods.register('code')}
          />
        </div>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t('admin:components.features.notification.modal.channelVerify.buttons.cancel'),
            type: 'button',
            variant: 'gray',
            onClick: () => onClose(),
          },
          {
            text: isLoading ? (
              <Spinner />
            ) : (
              t('admin:components.features.notification.modal.channelVerify.buttons.submit')
            ),
            type: 'button',
            variant: 'primary',
            disabled: isLoading,
            onClick: () => {
              onSubmit(methods.getValues('code'));
            },
          },
        ]}
      />
    </Modal>
  );
}

export default ChannelVerifyModal;
