import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { Button, Dropdown, Input, OutlineButton, Text, QuestionTooltip } from '@pickme/ui';
import { t } from 'i18next';

import {
  useReplaceOrganizationAlias,
  useReplaceOrganizationLogo,
  useReplaceOrganizationName,
} from 'query-hooks/organization';
import { useUploadFile } from 'query-hooks/file';
import { useGetSessionAsMember } from 'query-hooks/auth';

import { useGetFeatures } from 'hooks/useGetFeatures';
import { useGetActiveSpace } from 'hooks/useGetActiveSpace';

import { toast } from 'states/toast';

import { SPACE_NAME_MAX_LENGTH } from 'constants/rules';

import MoreIcon from 'resources/icons/application/more.png';
import UploadIcon from 'resources/icons/application/upload.png';
import EditIcon from 'resources/icons/application/edit.png';
import RemoveIcon from 'resources/icons/application/remove.png';
import SpacePlaceholderIcon from 'resources/icons/nav/space_placeholder.svg';

import './Settings.scss';

function Resource() {
  const setToast = useSetRecoilState(toast);

  const nameForm = useForm<{ name: string }>();
  const aliasForm = useForm<{ alias: string }>({
    mode: 'onBlur',
  });
  const logoForm = useForm<{ fileUrl: string }>();

  const { data: memberSessionData } = useGetSessionAsMember();
  const space = useGetActiveSpace();

  const { enableToSetLogo, enableToSetURL } = useGetFeatures();

  const { isLoading: isNameLoading, mutate: mutateName } = useReplaceOrganizationName();
  const { isLoading: isLogoLoading, mutate: mutateLogo } = useReplaceOrganizationLogo();
  const { isLoading: isAliasLoading, mutate: mutateAlias } = useReplaceOrganizationAlias();

  const isLoading = isNameLoading || isLogoLoading || isAliasLoading;

  const { mutate: fileUploadMutate, isLoading: isFileUploadLoading } = useUploadFile();

  const onSubmitName: SubmitHandler<{ name: string }> = (data) => {
    if (!space?._id) {
      return;
    }

    mutateName(
      { name: data.name, spaceId: space?._id },
      {
        onSuccess: () => {
          nameForm.reset();
        },
      },
    );
  };

  useEffect(() => {
    if (space?.name) {
      nameForm.setValue('name', space?.name, { shouldValidate: true });
    }
  }, [space?.name]);

  const onSubmitAlias: SubmitHandler<{ alias: string }> = (data) => {
    if (!space?._id) {
      return;
    }

    if (!enableToSetURL) {
      setToast({
        isVisible: true,
        type: 'error',
        message: t('admin:pages.organization.settings.errorMessages.upgrade.premium'),
      });
      return;
    }

    mutateAlias(
      { alias: data.alias, organizationId: space?._id },
      {
        onSuccess: () => {
          aliasForm.reset();
        },
      },
    );
  };

  useEffect(() => {
    if (space?.alias) {
      aliasForm.setValue('alias', space?.alias);
    }
  }, [space?.alias]);

  const onSubmitLogo: SubmitHandler<{ fileUrl: string }> = (data) => {
    if (!space?._id) {
      return;
    }

    if (!enableToSetLogo) {
      setToast({
        isVisible: true,
        type: 'error',
        message: t('admin:pages.organization.settings.errorMessages.upgrade.standard'),
      });
      return;
    }

    mutateLogo(
      { fileUrl: data.fileUrl, organizationId: space?._id },
      {
        onSuccess: () => {
          logoForm.reset();
        },
      },
    );
  };

  useEffect(() => {
    if (space?.logo) {
      logoForm.setValue('fileUrl', space?.logo);
    }
  }, [space?.logo]);

  return (
    <div className='organization-settings'>
      <div className='organization-settings__title'>
        <Text type='h3' fontWeight={600}>
          {t('admin:pages.organization.settings.title')}
        </Text>
      </div>

      <FormProvider {...nameForm}>
        <form
          className='organization-settings__form'
          onSubmit={nameForm.handleSubmit(onSubmitName)}
        >
          <div className='organization-settings__form__row'>
            <div className='organization-settings__form__row__title'>
              <Text type='b3' fontWeight={400}>
                {t('admin:pages.organization.settings.form.spaceName.title')}
              </Text>
            </div>

            <div className='organization-settings__form__row__field'>
              <Input
                width='100%'
                {...nameForm.register('name', {
                  maxLength: SPACE_NAME_MAX_LENGTH,
                  validate: (value) => {
                    if (!value) {
                      return t('admin:pages.organization.settings.errorMessages.space.name');
                    }

                    return true;
                  },
                })}
                maxLength={SPACE_NAME_MAX_LENGTH}
                disabled={memberSessionData?.level !== 'OrganAdmin'}
                placeholder={t(
                  'admin:pages.organization.settings.form.spaceName.fields.name.placeholder',
                  {
                    length: SPACE_NAME_MAX_LENGTH,
                  },
                )}
                isValid={
                  !nameForm.watch('name') ||
                  nameForm.formState.isValid ||
                  nameForm.watch('name') === space?.name
                }
                errorMessage={nameForm.formState.errors.name?.message}
              />
            </div>
          </div>

          {nameForm.formState.isDirty && (
            <div className='organization-settings__form__row flex-end'>
              <Button
                size='md'
                type='submit'
                disabled={isLoading || memberSessionData?.level !== 'OrganAdmin'}
              >
                {t('admin:pages.organization.settings.form.spaceName.submitButtonText')}
              </Button>
            </div>
          )}
        </form>
      </FormProvider>

      <FormProvider {...logoForm}>
        <form
          className='organization-settings__form'
          onSubmit={logoForm.handleSubmit(onSubmitLogo)}
        >
          <div className='organization-settings__form__row'>
            <div className='organization-settings__form__row__title'>
              <Text type='b3' fontWeight={400}>
                {t('admin:pages.organization.settings.form.spaceLogo.title')}
              </Text>
              <QuestionTooltip
                message={t('admin:pages.organization.settings.form.spaceLogo.tooltip')}
              />
            </div>

            <div className='organization-settings__form__row__field'>
              <div
                className={`organization-settings__form__row__field__image-container ${
                  enableToSetLogo ? '' : 'disabled'
                }`}
              >
                {logoForm.watch('fileUrl') ? (
                  <img
                    className='organization-settings__form__row__field__image-container__image'
                    src={logoForm.watch('fileUrl')}
                    alt='logo'
                  />
                ) : (
                  <img
                    className='organization-settings__form__row__field__image-container__image'
                    src={SpacePlaceholderIcon}
                    alt='logo'
                  />
                )}
                <input
                  hidden
                  id='logo-upload'
                  type='file'
                  disabled={!enableToSetLogo}
                  accept='image/jpeg, image/jpg, image/png'
                  onChange={(event) => {
                    if (isFileUploadLoading || !event.target.files || !event.target.files?.[0]) {
                      return;
                    }

                    fileUploadMutate(event.target.files[0], {
                      onSuccess: (data) => {
                        logoForm.setValue('fileUrl', data, { shouldDirty: true });
                      },
                    });
                  }}
                />

                <div className='organization-settings__form__row__field__image-container__dropdown'>
                  <Dropdown
                    directionBase='bottom'
                    direction='down'
                    disabled={memberSessionData?.level !== 'OrganAdmin'}
                    button={
                      <img
                        className='organization-settings__form__row__field__image-container__dropdown__icon'
                        src={MoreIcon}
                        alt='more'
                      />
                    }
                    items={
                      !logoForm.getValues('fileUrl')
                        ? [
                            {
                              text: t(
                                'admin:pages.organization.settings.form.spaceLogo.fields.image.dropdown.upload',
                              ),
                              type: 'button',
                              disabled: !enableToSetLogo,
                              icon: UploadIcon,
                              onClick: () => {
                                if (isFileUploadLoading) {
                                  return;
                                }

                                document.getElementById('logo-upload')?.click();
                              },
                            },
                            {
                              text: t(
                                'admin:pages.organization.settings.form.spaceLogo.fields.image.dropdown.remove',
                              ),
                              type: 'button',
                              icon: RemoveIcon,
                              color: 'red',
                              disabled: !logoForm.watch('fileUrl') || !enableToSetLogo,
                              onClick: () =>
                                logoForm.setValue('fileUrl', '', { shouldDirty: true }),
                            },
                          ]
                        : [
                            {
                              text: t(
                                'admin:pages.organization.settings.form.spaceLogo.fields.image.dropdown.edit',
                              ),
                              type: 'button',
                              disabled: !enableToSetLogo,
                              icon: EditIcon,
                              onClick: () => {
                                if (isFileUploadLoading) {
                                  return;
                                }

                                document.getElementById('logo-upload')?.click();
                              },
                            },
                            {
                              text: t(
                                'admin:pages.organization.settings.form.spaceLogo.fields.image.dropdown.remove',
                              ),
                              type: 'button',
                              icon: RemoveIcon,
                              color: 'red',
                              disabled: !logoForm.watch('fileUrl') || !enableToSetLogo,
                              onClick: () =>
                                logoForm.setValue('fileUrl', '', { shouldDirty: true }),
                            },
                          ]
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          {logoForm.formState.isDirty && (
            <div className='organization-settings__form__row flex-end'>
              <Button
                size='md'
                type='submit'
                disabled={isLoading || memberSessionData?.level !== 'OrganAdmin'}
              >
                {t('admin:pages.organization.settings.form.spaceLogo.submitButtonText')}
              </Button>
            </div>
          )}
        </form>
      </FormProvider>

      <FormProvider {...aliasForm}>
        <form
          className='organization-settings__form'
          onSubmit={aliasForm.handleSubmit(onSubmitAlias)}
        >
          <div className='organization-settings__form__row'>
            <div className='organization-settings__form__row__title'>
              <Text type='b3' fontWeight={400}>
                {t('admin:pages.organization.settings.form.spaceURL.title')}
              </Text>
            </div>

            <div className='organization-settings__form__row__field'>
              <Text type='b3' fontWeight={400}>
                {t('admin:pages.organization.settings.form.spaceURL.fields.url.prefix')}
              </Text>
              <Input
                width='100%'
                disabled={memberSessionData?.level !== 'OrganAdmin' || !enableToSetURL}
                {...aliasForm.register('alias', {
                  validate: (value) => {
                    if (/^[a-z0-9]{2,10}$/.test(value)) {
                      return true;
                    }

                    return t('admin:pages.organization.settings.errorMessages.space.url');
                  },
                })}
                placeholder={t(
                  'admin:pages.organization.settings.form.spaceURL.fields.url.placeholder',
                )}
                isValid={!aliasForm.formState.errors.alias}
              />

              {space?.alias === aliasForm.watch('alias') && (
                <OutlineButton
                  width={80}
                  type='button'
                  onClick={() => {
                    navigator.clipboard
                      .writeText(`https://vote.ohpick.me/${space?.alias}`)
                      .then(() => {
                        setToast({
                          isVisible: true,
                          type: 'success',
                          message: t(
                            'admin:pages.organization.settings.form.spaceURL.messages.success',
                          ),
                        });
                      })
                      .catch(() => {
                        setToast({
                          isVisible: true,
                          type: 'error',
                          message: t(
                            'admin:pages.organization.settings.form.spaceURL.messages.failure',
                          ),
                        });
                      });
                  }}
                >
                  {t('admin:pages.organization.settings.form.spaceURL.copyButtonText')}
                </OutlineButton>
              )}
            </div>
          </div>

          {aliasForm.formState.errors.alias && (
            <div className='organization-settings__form__row flex-end'>
              <Text type='lab1' fontWeight={500} color='error-500'>
                {aliasForm.formState.errors.alias?.message}
              </Text>
            </div>
          )}

          {space?.alias !== aliasForm.watch('alias') && aliasForm.formState.isDirty && (
            <div className='organization-settings__form__row flex-end'>
              <Button
                size='md'
                type='submit'
                disabled={isLoading || memberSessionData?.level !== 'OrganAdmin'}
              >
                {t('admin:pages.organization.settings.form.spaceURL.submitButtonText')}
              </Button>
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  );
}

export default Resource;
