import { Text } from '@pickme/design-system';
import { t } from 'i18next';

import MyPageName from 'components/features/my-page/Name';
import MyPageEmail from 'components/features/my-page/Email';
import MyPagePhone from 'components/features/my-page/Phone';
import MyPagePassword from 'components/features/my-page/Password';
import MyPageDeletion from 'components/features/my-page/Deletion';
import MarketingAgreement from 'components/features/my-page/MarketingAgreement';
import SocialIntegration from 'components/features/my-page/SocialIntegration';

import { useGetSessionAsUser } from 'query-hooks/auth';

import { container, fields } from './index.css';

function MyPage() {
  const { data: userSessionData } = useGetSessionAsUser({ suspense: false });
  const isMarketingAgree =
    userSessionData !== undefined && userSessionData.notification.marketingAgreements.length > 0;
  const isCredential =
    userSessionData !== undefined && userSessionData.primaryAuthMethod.isCredential();

  return (
    <div className={container}>
      <Text size={32} fontWeight={700}>
        {t('admin:pages.account.title')}
      </Text>

      <div className={fields}>
        <MyPageName name={userSessionData?.name || ''} />
        <MyPageEmail email={userSessionData?.email || ''} />
        <SocialIntegration />
        <MyPagePhone
          phoneNumber={userSessionData?.phoneNumber}
          verified={!!userSessionData?.verification.phoneNumber}
        />
        {isCredential && <MyPagePassword />}
        <MarketingAgreement isAgree={isMarketingAgree} />
      </div>

      <MyPageDeletion needPasswordConfirm={isCredential} />
    </div>
  );
}

export default MyPage;
