import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { Modal, ModalActions, ModalBody, ModalHeader } from '@pickme/ui';
import { Counter, Radio, Text } from '@pickme/design-system';
import { PLANS, Plan } from '@pickme/core';
import { chunk } from 'lodash-es';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

import { useGetPayment, usePurchaseMoreVoters } from 'query-hooks/payment';

import { useGetActiveSpace } from 'hooks/useGetActiveSpace';

import { toast } from 'states/toast';

import { CARD_NAME } from 'constants/payment';

import {
  container,
  callout,
  calloutRow,
  row,
  priceDetailContainer,
  priceContainer,
  purchaseMethodContainer,
  cardInfoContainer,
} from './index.css';

const MAX_VOTER_LIMIT = 3000;

type Props = {
  plan: Plan;
  isVisible: boolean;
  onClose: () => void;
};

function PurchaseMoreVotersModal({ plan, isVisible, onClose }: Props) {
  const setToast = useSetRecoilState(toast);

  const { data: paymentInfo, refetch } = useGetPayment();
  const { isLoading, mutate, reset } = usePurchaseMoreVoters();

  const activeSpace = useGetActiveSpace();
  const voterLimit = activeSpace?.subscription?.voterLimit ?? 0;
  const maxVoterLimit = MAX_VOTER_LIMIT - voterLimit;

  const methods = useForm<{ voterLimit: number }>({
    mode: 'onChange',
    defaultValues: {
      voterLimit: 0,
    },
  });

  useEffect(() => {
    if (isVisible) {
      refetch();
      reset();
      methods.reset({
        voterLimit: 0,
      });
    }
  }, [isVisible]);

  const onSubmit: SubmitHandler<{ voterLimit: number }> = async (data) => {
    if (activeSpace?._id) {
      mutate(
        {
          spaceId: activeSpace._id,
          voterLimit: data.voterLimit,
        },
        {
          onSuccess: () => onClose(),
        },
      );
    }
  };

  const onError: SubmitErrorHandler<{ voterLimit: number }> = (error) => {
    setToast({
      isVisible: true,
      type: 'error',
      message: error.voterLimit?.message || '',
    });
  };

  return (
    <Modal size='lg' isVisible={isVisible} onClose={onClose}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <ModalHeader
            title={t('admin:components.features.payment.modal.purchaseMoreVoters.title')}
            onClose={onClose}
          />

          <ModalBody className={container}>
            <div className={callout}>
              <Text size={18} fontWeight={500} color='blue-black'>
                {PLANS[plan].name.kor}{' '}
                {t('admin:components.features.payment.modal.purchaseMoreVoters.message')}
              </Text>

              <div className={calloutRow}>
                <Text size={18} fontWeight={500} color='primary-500'>
                  {PLANS[plan].features.price}
                  {t('admin:terms.unit.won')}
                </Text>

                <Controller
                  control={methods.control}
                  name='voterLimit'
                  rules={{
                    validate: {
                      min: (value) =>
                        value > 0 ||
                        t(
                          'admin:components.features.payment.modal.purchaseMoreVoters.validate.minVoterLimit',
                        ),
                      max: (value) =>
                        value <= maxVoterLimit ||
                        t(
                          'admin:components.features.payment.modal.purchaseMoreVoters.validate.minVoterLimit',
                          {
                            number: maxVoterLimit,
                          },
                        ),
                    },
                  }}
                  render={({ field }) => <Counter {...field} min={0} max={1000} />}
                />
              </div>

              {!!methods.formState.errors.voterLimit?.message && (
                <Text size={14} color='red-500' fontWeight={500}>
                  {methods.formState.errors.voterLimit?.message}
                </Text>
              )}
            </div>

            <div className={priceContainer}>
              <div className={priceDetailContainer}>
                <div className={row}>
                  <Text size={16} fontWeight={400} color='gray-800'>
                    {t('admin:components.features.payment.modal.purchaseMoreVoters.labels.0')}
                  </Text>
                  <Text size={16} fontWeight={400} color='gray-800'>
                    {(methods.watch('voterLimit') * PLANS[plan].features.price ?? 0).toLocaleString(
                      'ko-KR',
                    )}
                    {t('admin:terms.unit.won')}
                  </Text>
                </div>

                <div className={row}>
                  <Text size={16} fontWeight={400} color='gray-800'>
                    {t('admin:components.features.payment.modal.purchaseMoreVoters.labels.1')}
                  </Text>
                  <Text size={16} fontWeight={400} color='gray-800'>
                    {(
                      (methods.watch('voterLimit') * PLANS[plan].features.price ?? 0) / 10
                    ).toLocaleString('ko-KR')}
                    {t('admin:terms.unit.won')}
                  </Text>
                </div>
              </div>

              <div className={row}>
                <Text size={16} fontWeight={500} color='blue-black'>
                  {t('admin:components.features.payment.modal.purchaseMoreVoters.labels.2')}
                </Text>
                <Text size={18} fontWeight={600} color='blue-black'>
                  {(
                    ((methods.watch('voterLimit') * PLANS[plan].features.price ?? 0) * 11) /
                    10
                  ).toLocaleString('ko-KR')}
                  {t('admin:terms.unit.won')}
                </Text>
              </div>
            </div>

            <div className={purchaseMethodContainer}>
              <Text size={16} fontWeight={500} color='blue-black'>
                {t('admin:components.features.payment.modal.purchaseMoreVoters.labels.3')}
              </Text>

              <div className={row}>
                <Radio checked />

                <div className={cardInfoContainer}>
                  <Text size={18} fontWeight={500} color='blue-black'>
                    {CARD_NAME?.[paymentInfo?.card.acquirerCode || ''] || ''}{' '}
                  </Text>

                  <Text size={14} fontWeight={500}>
                    {chunk(paymentInfo?.card.number, 4)
                      .map((arr) => arr.join(''))
                      .join(' ')}
                  </Text>
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalActions
            buttons={[
              {
                text: isLoading ? (
                  <Spinner />
                ) : (
                  t('admin:components.features.payment.modal.purchaseMoreVoters.buttons.submit')
                ),
                variant: 'primary',
                type: 'submit',
                disabled: isLoading || !methods.formState.isValid,
              },
            ]}
          />
        </form>
      </FormProvider>
    </Modal>
  );
}

export default PurchaseMoreVotersModal;
