import moment from 'moment-timezone';
import { useState, useEffect } from 'react';
import { Text } from '@pickme/design-system';
import { useSpring, animated } from 'react-spring';
import { t } from 'i18next';

import NotificationSender from './Sender';
import NotificationIndicator from './Indicator';

import { container, date, example, title } from './index.css';

type TemplateType = '1' | '2' | '3' | '4' | '5';

function Template({ template }: { template: TemplateType }) {
  const [showContent, setShowContent] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateType>('1');

  useEffect(() => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    setShowContent(false);
    const newTimeoutId = setTimeout(() => {
      setSelectedTemplate(template);
      setShowContent(true);
    }, 1500);

    setTimeoutId(newTimeoutId);
    return () => {
      clearTimeout(newTimeoutId);
    };
  }, [template]);

  const indicatorAnimation = useSpring({
    to: { opacity: showContent ? 0 : 1 },
    from: { opacity: 1 },
    config: { duration: 100 },
  });

  const contentAnimation = useSpring({
    to: { opacity: showContent ? 1 : 0 },
    from: { opacity: 0 },
    config: { duration: 250 },
  });

  return (
    <div className={container}>
      <Text size={18} fontWeight={600} color='black' className={title}>
        미리 보기
      </Text>

      <Text color='white' className={date}>
        {moment().format('YYYY년 M월 D일 dd요일')}
      </Text>

      <NotificationSender sender='픽미 알림톡' />

      <animated.div style={indicatorAnimation}>
        <NotificationIndicator />
      </animated.div>

      <animated.div style={contentAnimation}>
        <Text color='black' className={example.header}>
          {t('admin:components.features.notification.template.header')}
        </Text>

        <div className={example.body}>
          <Text color='gray-700'>{TEMPLATE_TEXT[selectedTemplate]}</Text>

          {selectedTemplate !== '4' && (
            <div className={example.button}>
              <Text color='black'>
                {selectedTemplate === '3'
                  ? t('admin:components.features.notification.template.button.result')
                  : t('admin:components.features.notification.template.button.participate')}
              </Text>
            </div>
          )}
        </div>
      </animated.div>
    </div>
  );
}

export const TEMPLATE_TEXT = {
  '1': t('admin:components.features.notification.template.texts.1'),
  '2': t('admin:components.features.notification.template.texts.2'),
  '3': t('admin:components.features.notification.template.texts.3'),
  '4': t('admin:components.features.notification.template.texts.4'),
  '5': t('admin:components.features.notification.template.texts.5'),
};

export default Template;
