import { GradientDoughnutGraph, NumberCountingUpAnimation, Text } from '@pickme/ui';
import { t } from 'i18next';
import { PollGroup } from '@pickme/core';

import { setColors } from 'functions/poll-progress';

type Props = {
  group?: PollGroup;
  rate?: number;
  voteCount?: number;
  population?: number;
  showTitle?: boolean;
  noAnimation?: boolean;
};

function OverviewSection({ group, rate, voteCount, population, showTitle, noAnimation }: Props) {
  const { mainColor, lightColor } = setColors(group);
  return (
    <div className='poll-progress__section'>
      {showTitle && (
        <div className='poll-progress__section__title'>
          <Text type='b1' fontWeight={600}>
            {t('admin:components.features.poll.progress.overviewSection.title')}
          </Text>
        </div>
      )}

      <div className='poll-progress__overview'>
        <div className='poll-progress__overview__progress'>
          <div className='poll-progress__overview__progress__graph'>
            <GradientDoughnutGraph
              gradientColors={[lightColor, mainColor]}
              value={Number(((rate || 0) * 100).toFixed(2))}
              animation={!noAnimation}
            />
          </div>
          <div className='poll-progress__overview__progress__text'>
            <Text type='b3' color='gray-400' fontWeight={400}>
              {group === 'ongoing' || group === 'ready'
                ? t('admin:components.features.poll.progress.overviewSection.rate.realTime')
                : t('admin:components.features.poll.progress.overviewSection.rate.result')}
            </Text>
            <Text type='b2' fontWeight={500}>
              {noAnimation ? (
                <>{Number(((rate ?? 0) * 100).toFixed(2))}</>
              ) : (
                <NumberCountingUpAnimation
                  number={Number(((rate ?? 0) * 100).toFixed(2))}
                  decimalPlace={2}
                />
              )}
              %
            </Text>
          </div>
        </div>

        <div className='poll-progress__overview__total-count poll-progress__overview__total-count--border'>
          <Text type='b3' color='gray-400' fontWeight={400}>
            {t('admin:components.features.poll.progress.overviewSection.total.target')}
          </Text>
          <Text type='b2' fontWeight={500}>
            {population ?? 0}
            {t('admin:terms.unit.people')}
          </Text>
        </div>

        <div className='poll-progress__overview__voted-count'>
          <Text type='b3' color='gray-400' fontWeight={400}>
            {t('admin:components.features.poll.progress.overviewSection.total.voter')}
          </Text>
          <Text type='b2' fontWeight={500}>
            {noAnimation ? (
              <>
                {voteCount ?? 0}
                {t('admin:terms.unit.people')}
              </>
            ) : (
              <>
                <NumberCountingUpAnimation number={voteCount ?? 0} />
                {t('admin:terms.unit.people')}
              </>
            )}
          </Text>
        </div>
      </div>
    </div>
  );
}

export default OverviewSection;
