export default {
  useRemoveScheduledNotification: {
    onSuccess: {
      toast: '알림톡 예약이 성공적으로 취소되었습니다.',
    },
  },
  useSendNotification: {
    onSuccess: {
      toast: {
        send: '알림톡이 전송되었습니다.',
        reserve: '알림톡이 예약되었습니다.',
      },
    },
  },
};
