export default {
  sent: {
    title: '알림톡 전송 이력이 없어요.',
    description: '“알림톡 전송하기” 버튼을 클릭해 메시지를 보내보세요.',
    button: '알림톡 전송하기',
  },
  reserved: {
    title: '알림톡 예약 내역이 없어요.',
    description: '“알림톡 전송하기” 버튼을 클릭해 메시지를 보내보세요.',
    button: '알림톡 전송하기',
  },
  search: {
    title: '검색 결과가 없어요',
    description: `다른 키워드로 다시 검색해주세요.`,
  },
};
