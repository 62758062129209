import moment from 'moment-timezone';

type PollRateItem = { date: string; count: number; rate: number };

export const parseHourlyRates = (rateByHour: PollRateItem[], endDate: string) => {
  if (rateByHour.length === 0 || !endDate) {
    return [];
  }

  const hourlyRates: PollRateItem[] = [];

  rateByHour.forEach((item, index) => {
    if (index === 0) {
      hourlyRates.push(item);
      return;
    }

    const prevItem = rateByHour[index - 1];
    const isNeedMoreItemsBetween = !checkIfCurrentDateIsAfterOneHourFromPrevDate(
      prevItem.date,
      item.date,
    );

    if (isNeedMoreItemsBetween) {
      const addedItems = makeAddedRateItems(prevItem, item.date);
      hourlyRates.push(...addedItems);
    }

    hourlyRates.push(item);
  });

  const lastRateItem = hourlyRates[hourlyRates.length - 1];
  const targetDate = moment(endDate).isSameOrBefore(moment()) ? moment(endDate) : moment();
  const isNeedMoreItemsAfterLastItem = !checkIfLastDateIsSameWithTargetDate(
    lastRateItem.date,
    targetDate,
  );

  if (isNeedMoreItemsAfterLastItem) {
    const addedItems = makeAddedRateItems(lastRateItem, targetDate);
    hourlyRates.push(...addedItems);
  }

  return hourlyRates;
};

export const makeAddedRateItems = (prevItem: PollRateItem, currItemDate: moment.MomentInput) => {
  const diff = moment
    .duration(moment(currItemDate).tz('Asia/Seoul').diff(moment(prevItem.date).tz('Asia/Seoul')))
    .asHours();
  const diffHours = Math.floor(diff) - 1;

  const addedItems = Array.from({ length: diffHours }, (_, rateDateIndex) => ({
    date: moment(prevItem.date)
      .tz('Asia/Seoul')
      .add(1 + rateDateIndex, 'h')
      .format('YYYY-MM-DDTHH:mm'),
    count: prevItem.count,
    rate: prevItem.rate,
  }));

  return addedItems;
};

export const checkIfCurrentDateIsAfterOneHourFromPrevDate = (
  prev: moment.MomentInput,
  curr: moment.MomentInput,
) => moment(curr).format('YYYYMMDDHH') === moment(prev).add(1, 'h').format('YYYYMMDDHH');

export const checkIfLastDateIsSameWithTargetDate = (
  last: moment.MomentInput,
  target: moment.MomentInput,
) => moment(last).format('YYYYMMDDHH') === moment(target).format('YYYYMMDDHH');

export const makeLineChartLabels = (items: PollRateItem[]) => {
  if (items.length === 0) {
    return [];
  }

  if (items.length === 1) {
    const label: string = moment(items[0].date).tz('Asia/Seoul').format('HH시');
    return [label];
  }

  const dateOfFirstItem = moment(items[0].date).tz('Asia/Seoul');
  const dateOfLastItem = moment(items[items.length - 1].date).tz('Asia/Seoul');
  const isRateInADay = moment(dateOfFirstItem).isSame(dateOfLastItem, 'date');

  if (isRateInADay) {
    const labels = items.map(({ date }) => moment(date).tz('Asia/Seoul').format('HH시'));
    return labels;
  }

  const labels = items.map((rate, index) => {
    const currDate = moment(rate.date).tz('Asia/Seoul');

    if (index === 0) {
      return currDate.format('M/D HH시');
    }

    if (index % 2 !== 0) {
      return '';
    }

    if (items.length > 100 && index % 3 !== 0) {
      return '';
    }

    return currDate.format('M/D HH시');
  });

  return labels;
};
