import { Draft } from './draft';
import { PollKind } from './poll';

export type DraftTemplates = { [target in DraftTemplateCategory]?: DraftTemplate[] };

export type DraftTemplate = {
  title: string;
  description: string;
  thumbnail: string;
  draft?: Draft;
  kind: PollKind;
  category: DraftTemplateCategory;
};

export enum DraftTemplateCategory {
  School = 'school',
  Company = 'company',
  Corporation = 'corporation',
  Labor = 'labor',
  Association = 'association',
  Club = 'club',
  Apartment = 'apartment',
  Religion = 'religion',
  Contest = 'contest',
}
