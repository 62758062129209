export default {
  labels: {
    TransferTransaction: '수락 완료',
    ExtraTransaction: '지급 완료',
    'ChargeTransaction-system-complete': '지급 완료',
    'ChargeTransaction-system-canceled': '환수 완료',
    'ChargeTransaction-pending': '결제 대기',
    'ChargeTransaction-completed': '결제 완료',
    'ChargeTransaction-expired': '입금 기한 만료',
    'ChargeTransaction-canceled': '결제 취소 완료',
    'ChargeTransaction-canceling': '취소 처리중',
  },
};
