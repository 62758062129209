import { Fragment } from 'react/jsx-runtime';

import Spinner from 'components/common/Spinner';
import InfiniteScrollHandler from 'components/common/InfiniteScrollHandler';
import HomeDraftCard from 'components/features/home/cards/Draft';
import PollEmptyStateView from 'components/features/poll/PollList/EmptyStateView';

import { useGetInfiniteDrafts } from 'query-hooks/draft';

import { type HomePollSearchQuery } from 'pages/Home/useHomePollSearchQueryParams';

import { pollCards } from '../common.css';

type Props = {
  query: HomePollSearchQuery;
};

function HomeDraftCards({ query }: Props) {
  const { kind } = query;
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useGetInfiniteDrafts(kind);

  const isEmpty = !isFetchingNextPage && data !== undefined && data.pages[0].total === 0;

  return (
    <InfiniteScrollHandler
      onScrollFinished={() => {
        if (hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      }}
    >
      <div className={pollCards}>
        {isEmpty ? (
          <PollEmptyStateView type='default' kind={query.kind} />
        ) : (
          data?.pages.map((page, index) => (
            <Fragment key={index}>
              {page?.drafts.map((draft) => <HomeDraftCard key={draft._id} draft={draft} />)}
            </Fragment>
          ))
        )}

        {isFetchingNextPage && <Spinner />}
      </div>
    </InfiniteScrollHandler>
  );
}

export default HomeDraftCards;
