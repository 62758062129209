import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Select, IconInput } from '@pickme/design-system';

import { POLL_SEARCH_GROUP_SELECT_ITEMS, POLL_KIND_SELECT_ITEMS } from 'constants/poll';

import {
  PollSearchGroup,
  PollSearchKind,
  PollSearchForm as PollSearchFormValues,
} from 'types/poll';

import SearchIcon from 'resources/icons/dashboard/search.svg';

import { container, input, selectWidth, selects } from './index.css';

type Props = {
  values?: Partial<PollSearchFormValues>;
  onSubmit: (data: PollSearchFormValues) => void;
};

function PollSearchForm({ onSubmit, values }: Props) {
  const methods = useForm<PollSearchFormValues>({
    defaultValues: {
      kind: PollSearchKind.All,
      groups: PollSearchGroup.All,
      name: '',
    },
  });

  useEffect(() => {
    methods.reset(values);
  }, [values?.kind, values?.groups, values?.name]);

  const onFormSubmit = methods.handleSubmit(onSubmit);

  return (
    <FormProvider {...methods}>
      <form onSubmit={onFormSubmit} className={container}>
        <div className={selects.container}>
          <Controller
            control={methods.control}
            name='kind'
            render={({ field }) => (
              <Select
                placeholder='유형'
                triggerButtonStyle='outline'
                className={selects.select}
                menuWidth={selectWidth}
                items={POLL_KIND_SELECT_ITEMS}
                selected={field.value}
                onSelect={(kind) => {
                  field.onChange(kind);
                  onFormSubmit();
                }}
              />
            )}
          />

          <Controller
            control={methods.control}
            name='groups'
            render={({ field }) => (
              <Select
                placeholder='상태'
                triggerButtonStyle='outline'
                className={selects.select}
                menuWidth={selectWidth}
                items={POLL_SEARCH_GROUP_SELECT_ITEMS.filter(
                  (item) => item.value !== PollSearchGroup.Paused,
                )}
                selected={field.value}
                onSelect={(groups) => {
                  field.onChange(groups);
                  onFormSubmit();
                }}
              />
            )}
          />
        </div>

        <IconInput
          {...methods.register('name')}
          icon={SearchIcon}
          placeholder='검색어를 입력해 주세요'
          className={input}
          type='submit'
          onClick={onFormSubmit}
        />
      </form>
    </FormProvider>
  );
}

export default PollSearchForm;
