import { useEffect, useState } from 'react';
import { Button, Text, BasicTooltip } from '@pickme/ui';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

import Tabs from 'components/common/Tabs';
import ChannelConnectModal from 'components/features/notification/modal/ChannelConnect';
import ChannelRemoveModal from 'components/features/notification/modal/ChannelRemove';

import { useGetChannel } from 'query-hooks/channel';
import { useGetSessionAsMember } from 'query-hooks/auth';

import { useGetFeatures } from 'hooks/useGetFeatures';

import { KAKAO_TABS } from 'constants/integration';

import SuccessIcon from 'resources/icons/notification/success.png';
import FailIcon from 'resources/icons/notification/fail.png';

import './index.scss';

function Channel() {
  const navigate = useNavigate();
  const tabs = KAKAO_TABS.map(({ text, url }) => ({
    text,
    onClick: () => navigate(url),
  }));

  const [isChannelConnectModalVisible, setChannelConnectModalVisible] = useState(false);
  const [isChannelRemoveModalVisible, setChannelRemoveModalVisible] = useState(false);

  const { data: memberData } = useGetSessionAsMember();
  const { type } = useGetFeatures();
  const { data: channel, isFetched } = useGetChannel();

  useEffect(() => {
    if (isFetched && !channel?._id) {
      navigate(-1);
    }
  }, [isFetched, channel]);

  return (
    <div className={`notification ${type !== 'enterprise' ? 'padded-top' : ''}`}>
      <div className='notification__title'>
        <Text type='h3' fontWeight={600}>
          {t('admin:pages.integration.kakaoChannel.title')}
        </Text>
      </div>

      <div className='notification__tabs-container'>
        <Tabs tabs={tabs} selectedIndex={0} />
      </div>

      <div className='notification__section'>
        {channel && (
          <>
            <div className='notification__channel'>
              <div className='notification__channel__row'>
                <div className='notification__channel__row__title'>
                  <Text type='b3' fontWeight={400} color='gray-400'>
                    {t('admin:pages.integration.kakaoChannel.card.name')}
                  </Text>
                </div>

                <div className='notification__channel__row__content'>
                  <Text type='b3' fontWeight={400}>
                    {channel?.searchId}
                  </Text>

                  {channel.status === 'pending' && (
                    <BasicTooltip
                      message={t('admin:pages.integration.kakaoChannel.card.description.pending')}
                    >
                      <img src={FailIcon} alt='fail' />
                    </BasicTooltip>
                  )}

                  {channel.status === 'approved' && (
                    <BasicTooltip
                      message={t('admin:pages.integration.kakaoChannel.card.description.complete')}
                    >
                      <img src={SuccessIcon} alt='success' />
                    </BasicTooltip>
                  )}
                </div>
              </div>

              <div className='notification__channel__row'>
                <div className='notification__channel__row__title'>
                  <Text type='b3' fontWeight={400} color='gray-400'>
                    {t('admin:pages.integration.kakaoChannel.card.phoneNumber')}
                  </Text>
                </div>

                <div className='notification__channel__row__content'>
                  <Text type='b3' fontWeight={400}>
                    {channel?.phoneNumber}
                  </Text>
                </div>
              </div>

              <div className='notification__channel__row'>
                <div className='notification__channel__row__title'>
                  <Text type='b3' fontWeight={400} color='gray-400'>
                    {t('admin:pages.integration.kakaoChannel.card.category')}
                  </Text>
                </div>

                <div className='notification__channel__row__content'>
                  <Text type='b3' fontWeight={400}>
                    {channel?.categoryName.replaceAll(',', ' / ')}
                  </Text>
                </div>
              </div>
            </div>

            {memberData?.level === 'OrganAdmin' && (
              <div className='notification__channel__button'>
                <Button
                  size='md'
                  variant='error'
                  onClick={() => setChannelRemoveModalVisible(true)}
                >
                  {t('admin:pages.integration.kakaoChannel.card.buttonText')}
                </Button>
              </div>
            )}
          </>
        )}
      </div>

      <ChannelRemoveModal
        isVisible={isChannelRemoveModalVisible}
        status={channel?.status}
        onClose={() => setChannelRemoveModalVisible(false)}
      />

      <ChannelConnectModal
        isVisible={isChannelConnectModalVisible}
        onClose={() => setChannelConnectModalVisible(false)}
      />
    </div>
  );
}

export default Channel;
