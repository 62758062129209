import { Controller, useFormContext } from 'react-hook-form';
import { Text, PlainButton } from '@pickme/design-system';

import NumericInput from 'components/common/input/NumericInput';

import { parseFormattedNumber } from 'functions/utils';
import { validatePointAmount } from 'functions/validator/payment';

import { PointBillForm } from 'types/payment';

import ClearIcon from 'resources/icons/payment/clear.svg';

import { clearButton, placeholder, pointInput } from './index.css';

function PointInput() {
  const methods = useFormContext<PointBillForm>();
  const amount = methods.watch('amount');

  return (
    <>
      <Controller
        control={methods.control}
        name='amount'
        rules={{
          required: true,
          validate: (point) => {
            const validation = validatePointAmount(parseFormattedNumber(point));

            if (validation.message) {
              return validation.message;
            }

            return validation.isValid;
          },
        }}
        render={({ field }) => (
          <div className={pointInput.container}>
            {!amount && (
              <div className={placeholder}>
                <Text size={18} fontWeight={500} color='gray-400'>
                  충전할 포인트를 입력해 주세요
                </Text>
                <Text size={14} fontWeight={500} color='gray-400'>
                  (1,000P ~ 500,000P 이하)
                </Text>
              </div>
            )}

            <NumericInput
              className={pointInput.wrapper}
              inputClassName={pointInput.input}
              value={field.value}
              onChange={(point) => {
                const validation = validatePointAmount(parseFormattedNumber(point));

                if (validation.message) {
                  methods.setError('amount', { message: validation.message });
                }

                if (validation.isValid) {
                  methods.clearErrors('amount');
                }

                field.onChange(point);
              }}
            />

            {amount && (
              <PlainButton onClick={() => methods.resetField('amount')} className={clearButton}>
                <img src={ClearIcon} alt='clear amount' />
              </PlainButton>
            )}
          </div>
        )}
      />

      {methods.formState.errors?.amount && (
        <Text size={12} color='red-500'>
          {methods.formState.errors?.amount.message}
        </Text>
      )}
    </>
  );
}

export default PointInput;
