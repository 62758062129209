import clsx from 'clsx';
import { useState } from 'react';
import { Input, Text, PlainButton, Tooltip } from '@pickme/design-system';

import PhoneChangeForm from 'components/features/my-page/form/PhoneChangeForm';

import { field } from '../common.css';
import { labelContainer } from './index.css';

type Props = { phoneNumber?: string; verified: boolean };

function MyPagePhone({ phoneNumber, verified }: Props) {
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <div className={field.container}>
      <Text size={14} className={clsx(field.label, labelContainer)}>
        휴대폰 번호
        <Tooltip message='휴대폰 번호를 추가해 인증하면 알림톡과 문자로 알림을 받아볼 수 있어요' />
      </Text>

      {isEditMode ? (
        <PhoneChangeForm
          onClose={() => setIsEditMode(false)}
          phoneNumber={phoneNumber || ''}
          verified={verified}
        />
      ) : (
        <>
          <div>
            <Input
              value={phoneNumber}
              size='lg'
              width={322}
              placeholder='입력한 휴대폰 번호가 없습니다.'
              disabled
            />
            {phoneNumber && (
              <Text size={12} color={verified ? 'green-400' : 'red-500'}>
                {verified ? '인증된 휴대폰 번호입니다.' : '인증되지 않은 휴대폰 번호입니다.'}
              </Text>
            )}
          </div>

          <PlainButton
            className={field.button({ hoverEffect: true })}
            onClick={() => setIsEditMode(true)}
          >
            {verified ? '변경' : '인증'}
          </PlainButton>
        </>
      )}
    </div>
  );
}

export default MyPagePhone;
