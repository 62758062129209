import instantVoterCardSection from './instantVoterCardSection';
import questionCard from './questionCard';
import voterCard from './voterCard';
import detail from './detail';

export default {
  instantVoterCardSection,
  questionCard,
  voterCard,
  detail,
};
