import { useMutation } from 'react-query';

import {
  createSendOTPByEmail,
  createSendOTPBySMS,
  createVerifyEmail,
  createVerifySMS,
} from 'apis/otp';
import { useSetRecoilState } from 'recoil';
import { toast } from 'states/toast';
import { AxiosError } from 'axios';

export const useSendOTPByEmail = () => {
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async (email: string) => {
      await createSendOTPByEmail(email);
      return { email };
    },
    {
      onError: (error: AxiosError) => {
        setToast({
          isVisible: true,
          message: error.message,
          type: 'error',
        });
      },
    },
  );
};
export const useVerifyEmail = () =>
  useMutation(async ({ email, code }: { email: string; code: string }) => {
    const { data } = await createVerifyEmail(email, code);
    return data;
  });

export const useSendOTPBySMS = () => {
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async (phoneNumber: string) => {
      const parsedPhoneNumber = phoneNumber.replaceAll('-', '');
      await createSendOTPBySMS(parsedPhoneNumber);
      return { phoneNumber };
    },
    {
      onError: (error: AxiosError) => {
        setToast({
          isVisible: true,
          message: error.message,
          type: 'error',
        });
      },
    },
  );
};

export const useVerifySMS = () => {
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ phoneNumber, code }: { phoneNumber: string; code: string }) => {
      const parsedPhoneNumber = phoneNumber.replaceAll('-', '');
      const { data } = await createVerifySMS(parsedPhoneNumber, code);
      return data;
    },
    {
      onError: (error: AxiosError) => {
        setToast({
          isVisible: true,
          message: error.message,
          type: 'error',
        });
      },
    },
  );
};
