import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Text, Modal, ModalHeader, ModalBody, ModalActions, Dropdown, Input } from '@pickme/ui';
import { SpaceCategory } from '@pickme/core';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

import { useCreateOrganization } from 'query-hooks/organization';

import { createOrganizationModal } from 'states/modal';
import { toast } from 'states/toast';

import { SPACE_NAME_MAX_LENGTH } from 'constants/rules';

import DownIcon from 'resources/icons/application/down.png';

import './index.scss';

const CATEGORIES: { [key in SpaceCategory]: string } = {
  [SpaceCategory.MIDDLE_HIGH_SCHOOL]: t(
    'admin:components.features.modal.createSpace.labels.middleHighSchool',
  ),
  [SpaceCategory.UNIVERSITY]: t('admin:components.features.modal.createSpace.labels.university'),
  [SpaceCategory.COMPANY]: t('admin:components.features.modal.createSpace.labels.company'),
  [SpaceCategory.RELIGIOUS_GROUP]: t(
    'admin:components.features.modal.createSpace.labels.religiousGroup',
  ),
  [SpaceCategory.ASSOCIATION]: t('admin:components.features.modal.createSpace.labels.association'),
  [SpaceCategory.APARTMENT]: t('admin:components.features.modal.createSpace.labels.apartment'),
  [SpaceCategory.OTHER]: t('admin:components.features.modal.createSpace.labels.other'),
};

function CreateOrganizationModal() {
  const setToast = useSetRecoilState(toast);
  const [modal, setModal] = useRecoilState(createOrganizationModal);
  const form = useForm<{ name: string; category: SpaceCategory; mailing: boolean }>();

  const { isLoading, mutate } = useCreateOrganization();

  const onSubmit: SubmitHandler<{ name: string; category: SpaceCategory; mailing: boolean }> = (
    data,
  ) => {
    let message = '';
    if (!data.name) {
      message = t('admin:components.features.modal.createSpace.form.errorMessages.name');
    } else if (!data.category) {
      message = t('admin:components.features.modal.createSpace.form.errorMessages.category');
    }

    if (message) {
      setToast({
        isVisible: true,
        type: 'error',
        message,
      });
      return;
    }

    mutate(
      { name: data.name, category: data.category },
      {
        onSuccess: () => {
          setModal({ isVisible: false });
        },
      },
    );
  };

  useEffect(() => {
    if (!modal.isVisible) {
      form.reset();
    }
  }, [modal.isVisible]);

  return (
    <Modal
      className='organization-add-modal'
      size='md'
      isVisible={modal.isVisible}
      onClose={() => setModal({ isVisible: false })}
    >
      <FormProvider {...form}>
        <form className='auth-form' onSubmit={form.handleSubmit(onSubmit)}>
          <ModalHeader
            title={t('admin:components.features.modal.createSpace.title')}
            onClose={() => setModal({ isVisible: false })}
          />

          <ModalBody>
            <div className='organization-add-modal__description'>
              <Text type='b3' fontWeight={400} color='gray-400'>
                {t('admin:components.features.modal.createSpace.description')}
              </Text>
            </div>

            <Input
              width='100%'
              size='lg'
              placeholder={t(
                'admin:components.features.modal.createSpace.form.fields.name.placeholder',
                {
                  length: SPACE_NAME_MAX_LENGTH,
                },
              )}
              type='name'
              id='name'
              maxLength={SPACE_NAME_MAX_LENGTH}
              {...form.register('name', {
                maxLength: SPACE_NAME_MAX_LENGTH,
                required: true,
              })}
            />

            <Dropdown
              className='organization-add-modal__dropdown'
              button={
                <>
                  <Text
                    type='b3'
                    fontWeight={400}
                    color={form.watch('category') ? 'black' : 'gray-300'}
                  >
                    {CATEGORIES[form.watch('category')] ||
                      t('admin:components.features.modal.createSpace.form.fields.category.default')}
                  </Text>
                  <img src={DownIcon} alt='arrow' />
                </>
              }
              items={Object.entries(CATEGORIES).map(([key, value]) => ({
                text: value,
                onClick: () => form.setValue('category', key as SpaceCategory),
              }))}
            />
          </ModalBody>

          <ModalActions
            buttons={[
              {
                text: isLoading ? (
                  <Spinner />
                ) : (
                  t('admin:components.features.modal.createSpace.actions.submit')
                ),
                type: 'submit',
                variant: 'primary',
                disabled: isLoading,
              },
            ]}
          />
        </form>
      </FormProvider>
    </Modal>
  );
}

export default CreateOrganizationModal;
