import { ReactNode } from 'react';
import { Text } from '@pickme/design-system';

import { IntegrationService } from 'types/integration';

import { cardBody, container, iconStyle, titleStyle, descriptionStyle } from './index.css';

type Props = {
  service: IntegrationService;
  icon: string;
  title: string;
  description: string[];
  children?: ReactNode;
};

function IntegrationCard({ service, icon, title, description, children }: Props) {
  return (
    <div className={container}>
      <img className={iconStyle} src={icon} alt={service} />

      <div className={cardBody}>
        <Text className={titleStyle} size={18} fontWeight={600}>
          {title}
        </Text>

        <ul className={descriptionStyle}>
          {description.map((text) => (
            <li key={text} className={descriptionStyle}>
              <Text size={16} fontWeight={400} color='gray-600'>
                {text}
              </Text>
            </li>
          ))}
        </ul>
      </div>

      {children}
    </div>
  );
}

export default IntegrationCard;
