import { useEffect } from 'react';
import { Modal, ModalActions, ModalBody, ModalHeader, Spinner, Text } from '@pickme/ui';
import { t } from 'i18next';

import { useCancelHandSpaceOver } from 'query-hooks/space';

type Props = {
  isVisible: boolean;
  email: string;
  onClose: () => void;
};

function CancelHandSpaceOverModal({ isVisible, email, onClose }: Props) {
  const { isLoading, isSuccess, mutate } = useCancelHandSpaceOver();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return (
    <Modal size='lg' isVisible={isVisible} onClose={onClose}>
      <ModalHeader
        title={t('admin:components.features.space.modal.cancelHandSpaceOver.title')}
        onClose={onClose}
      />

      <ModalBody>
        <Text type='b3' color='gray-400'>
          {t('admin:components.features.space.modal.cancelHandSpaceOver.message', {
            email,
          })}
        </Text>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t('admin:components.features.space.modal.cancelHandSpaceOver.buttons.cancel'),
            variant: 'gray',
            disabled: isLoading,
            onClick: onClose,
          },
          {
            text: isLoading ? (
              <Spinner />
            ) : (
              t('admin:components.features.space.modal.cancelHandSpaceOver.buttons.submit')
            ),
            onClick: () => mutate(),
            variant: 'error',
            disabled: isLoading,
          },
        ]}
      />
    </Modal>
  );
}

export default CancelHandSpaceOverModal;
