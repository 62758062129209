import components from './admin/components';
import constants from './admin/constants';
import functions from './admin/functions';
import pages from './admin/pages';
import queryHooks from './admin/query-hooks';
import terms from './admin/terms';

export default {
  admin: {
    components,
    constants,
    functions,
    pages,
    'query-hooks': queryHooks,
    terms,
  },
};
