import { useSetRecoilState } from 'recoil';
import { Controller, SubmitErrorHandler, useFormContext } from 'react-hook-form';
import { Editor } from '@pickme/ui';
import { Button, Input } from '@pickme/design-system';
import { Plan } from '@pickme/core';
import { t } from 'i18next';

import Cover from 'components/features/application/field/Cover';
import Periods from 'components/features/application/form/Basic/Period';
import FieldTitle from 'components/features/application/label/FieldTitle';
import PublicField from 'components/features/application/form/Basic/Public';

import { useGetFeatures } from 'hooks/useGetFeatures';

import { imageUploader } from 'functions/application';

import { toast } from 'states/toast';

import { BasicFormBody } from 'types/application';

import { container, fieldStyle } from './index.css';

type Props = {
  isEdit?: boolean;
  kind: 'election' | 'survey';
  onSubmit: (data: BasicFormBody) => void;
};

function BasicForm({ isEdit, kind, onSubmit }: Props) {
  const setToast = useSetRecoilState(toast);
  const methods = useFormContext<BasicFormBody>();

  const onError: SubmitErrorHandler<BasicFormBody> = (error) => {
    if (error.periods?.root?.message) {
      setToast({
        isVisible: true,
        type: 'error',
        message: error.periods?.root?.message,
      });
    }
  };

  const { period } = useGetFeatures();
  const selectedSubscriptionType = methods.watch('subscriptionType') ?? Plan.Free;

  return (
    <form onSubmit={methods.handleSubmit(onSubmit, onError)} noValidate>
      <input hidden {...methods.register('voterType')} />
      <input hidden {...methods.register('type')} />
      <input hidden {...methods.register('subscriptionType')} />

      <div className={container}>
        <div className={fieldStyle}>
          <FieldTitle
            title={
              kind === 'election'
                ? t('admin:components.features.application.form.basic.fields.name.label.election')
                : t('admin:components.features.application.form.basic.fields.name.label.survey')
            }
            isRequired
          />

          <Input
            type='text'
            width='100%'
            placeholder={t(
              'admin:components.features.application.form.basic.fields.name.placeholder',
            )}
            maxLength={150}
            isValid={!methods.formState.errors.name}
            {...methods.register('name', { required: true, validate: (text) => text.length > 1 })}
          />
        </div>

        <div className={fieldStyle}>
          <FieldTitle title='커버 이미지' />
          <Controller
            control={methods.control}
            name='coverImageFileUrl'
            render={({ field }) => (
              <Cover
                imageUrl={field.value || ''}
                onChange={field.onChange}
                disabled={field.disabled}
              />
            )}
          />
        </div>

        {kind === 'survey' && (
          <div className={fieldStyle}>
            <FieldTitle
              title={t('admin:components.features.application.form.basic.fields.public.label')}
            />

            <PublicField isEdit={isEdit} />
          </div>
        )}

        <div className={fieldStyle}>
          <FieldTitle
            title={
              kind === 'election'
                ? t('admin:components.features.application.form.basic.fields.period.label.election')
                : t('admin:components.features.application.form.basic.fields.period.label.survey')
            }
            isRequired
          />

          <Periods
            isEdit={isEdit}
            max={selectedSubscriptionType !== Plan.Free && period?.end ? period?.end : ''}
          />
        </div>

        <div className={fieldStyle}>
          <FieldTitle
            title={
              kind === 'election'
                ? t(
                    'admin:components.features.application.form.basic.fields.description.label.election',
                  )
                : t(
                    'admin:components.features.application.form.basic.fields.description.label.survey',
                  )
            }
          />

          <Controller
            control={methods.control}
            name='description'
            render={({ field: { value, onChange } }) => (
              <Editor imageUploader={imageUploader} value={value} onChange={onChange} />
            )}
          />
        </div>

        <Button fullWidth size='md' variant='primary' type='submit'>
          {t('admin:components.features.application.form.basic.buttons.submit')}
        </Button>
      </div>
    </form>
  );
}

export default BasicForm;
