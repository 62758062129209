import { DraftTemplate, DraftTemplateCategory } from 'types/template';

import Thumbnail1 from 'resources/images/templates/election/religion1.png';
import Thumbnail2 from 'resources/images/templates/election/religion2.png';

const DRAFT_1 = {
  title: '교회 향존직 선출 투표',
  _id: '64993202a5f0ec00830bec0d',
  content: {
    name: '교회 향존직 선출 투표',
    periods: [
      {
        startDate: '2024-02-29T15:04',
        endDate: '2024-04-19T15:04',
      },
    ],
    description:
      '<p>교회 향존직 1차 선출 투표 안내드립니다.</p><p>후보자 명단을 확인한 후 장로 임직 대상자를 선택해 주시기 바랍니다.</p><p><br></p><p>성도 여러분들의 기도와 적극적인 협조 부탁드립니다.</p><p>감사합니다.</p>',
    type: 'Election',
    voterType: 'voterBook',
    subscriptionType: 'enterprise',
    subElections: [
      {
        kind: 'RankElection',
        title: '장로 선출 투표',
        shuffle: false,
        candidates: [
          {
            title: '기호 1번',
            description:
              '<h2>박만중 (만 63세)</h2><p><br></p><p>현) 예배준비 팀장</p><p>전) 새가족 팀장</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '박만중 안수집사',
                fileUrl: 'https://api.ohpick.me/api/file/6493ec6f409fe700479eeb0d',
              },
            ],
            tags: [],
            id: 'd8a3f7ad-dfde-4463-9363-2ac2cabbf4e3',
          },
          {
            title: '기호 2번',
            description:
              '<h2>한기주 (만 67세)</h2><p><br></p><p>현) 청년부 부팀장</p><p>전) 구역장</p><p>전) 찬양대팀장</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '한기주 안수집사',
                fileUrl: 'https://api.ohpick.me/api/file/6493ec5b645b6e0048c1b5ce',
              },
            ],
            tags: [],
            id: '0a0846aa-16e2-4576-9cb6-9bf90cdfd4a0',
          },
          {
            title: '기호 3번',
            description:
              '<h2>김형록 (만 69세)</h2><p><br></p><p>현) 시설관리팀장</p><p>전) 주방팀장</p><p>전) 관리부팀장</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '김형록 안수집사',
                fileUrl: 'https://api.ohpick.me/api/file/6493ec8158f5030047604fc4',
              },
            ],
            tags: [],
            id: '60e0a878-4864-4772-a515-f469fec6ab08',
          },
          {
            title: '기호 4번',
            description:
              '<h2>나덕윤 (만 64세)</h2><p><br></p><p>현) 유소년부팀장</p><p>전) 예배준비팀장</p><p>전) 찬양대팀장</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '나덕윤 안수집사',
                fileUrl: 'https://api.ohpick.me/api/file/6493ec90645b6e0048c1b5d5',
              },
            ],
            tags: [],
            id: 'ac31efbe-924b-433c-9342-03f33da28c83',
          },
          {
            title: '기호 5번',
            description:
              '<h2>차병진 (만 63세)</h2><p><br></p><p>현) 안수집사회 회장</p><p>전) 해외선교팀장</p><p>전) 구역장</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '차병진 안수집사',
                fileUrl: 'https://api.ohpick.me/api/file/6493ec9b409fe700479eeb14',
              },
            ],
            tags: [],
            id: 'bbb8ed68-13de-478c-acd2-40e3d6c695fb',
          },
        ],
        responseValidation: {
          allowAbstentionVote: true,
          rankLimit: 1,
        },
        id: 'bfaed2e4-7a98-481b-bcfb-5bc9f22cf127',
      },
      {
        kind: 'RankElection',
        title: '권사 선출 투표',
        shuffle: false,
        candidates: [
          {
            title: '기호 1번',
            description:
              '<h2>서민선 (만 46세)</h2><p><br></p><p>현) 권찰</p><p>현) 유소년부교사</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '서민선',
                fileUrl: 'https://api.ohpick.me/api/file/6494026637178500475ccd33',
              },
            ],
            tags: [],
            id: 'd92bb89f-fe47-41a9-8a2e-62d236cb0ecf',
          },
          {
            title: '기호 2번',
            description:
              '<h2>안명희 (만 62세)</h2><p><br></p><p>현) 구역장</p><p>현) 유소년부교사</p><p>전) 권찰</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '안명희',
                fileUrl: 'https://api.ohpick.me/api/file/6494027337178500475ccd3a',
              },
            ],
            tags: [],
            id: '8a477f99-a3b7-434b-b808-cfa2f9f8486d',
          },
          {
            title: '기호 3번',
            description: '<h2>오경희 (만 55세)</h2><p><br></p><p>현) 권찰</p><p>전) 카페팀장</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '오경희',
                fileUrl: 'https://api.ohpick.me/api/file/6494028737178500475ccd41',
              },
            ],
            tags: [],
            id: '3f403c62-a26e-4111-b909-29254ee7ae86',
          },
          {
            title: '기호 4번',
            description:
              '<h2>강경자 (만 64세)</h2><p><br></p><p>현) 구역장</p><p>전) 일대일양육자</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '강경자',
                fileUrl: 'https://api.ohpick.me/api/file/6494029737178500475ccd48',
              },
            ],
            tags: [],
            id: '49d093b0-11ef-43d0-a40d-7fb0fa76b4f8',
          },
          {
            title: '기호 5번',
            description:
              '<h2>김연주 (만 54세)</h2><p><br></p><p>현) 중보기도팀 부팀장</p><p>전) 카페부팀장</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '김연주',
                fileUrl: 'https://api.ohpick.me/api/file/649402b537178500475ccd4f',
              },
            ],
            tags: [],
            id: '8d92d440-db2f-4f20-8a12-2a59f78484d5',
          },
        ],
        responseValidation: {
          allowAbstentionVote: true,
          rankLimit: 3,
        },
        id: '953026b6-c9fb-49c9-ad04-fad7d48b2760',
      },
      {
        kind: 'ChoiceElection',
        title: '안수집사 선출 투표',
        shuffle: false,
        candidates: [
          {
            title: '기호 1번',
            description:
              '<h2>한동준 (만 45세)</h2><p><br></p><p>현) 청소년부교사</p><p>현) 국악동아리장</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '한동준',
                fileUrl: 'https://api.ohpick.me/api/file/649407d237178500475cd0d9',
              },
            ],
            tags: [],
            id: '09dad85d-d364-4d31-bc14-3fb6ab036fc9',
          },
          {
            title: '기호 2번',
            description: '<h2>최동현 (만 51세)</h2><p><br></p><p>현) 구역장</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '최동현',
                fileUrl: 'https://api.ohpick.me/api/file/649407d937178500475cd0e0',
              },
            ],
            tags: [],
            id: '1d3523de-db67-428f-b515-5515895fa277',
          },
          {
            title: '기호 3번',
            description:
              '<h2>한동준 (만 45세)</h2><p><br></p><p>현) 친교팀장</p><p>전) 찬양대원</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '권지한',
                fileUrl: 'https://api.ohpick.me/api/file/649407e137178500475cd0e7',
              },
            ],
            tags: [],
            id: '53dd4476-37d0-4d7e-86ec-b1182daf25d5',
          },
          {
            title: '기호 4번',
            description: '<h2>조완옥 (만 57세)</h2><p><br></p><p>전) 유소년부 팀장</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '조완옥',
                fileUrl: 'https://api.ohpick.me/api/file/649407e937178500475cd0ee',
              },
            ],
            tags: [],
            id: '99383f1e-e612-4d41-8eaf-0f28af45b711',
          },
          {
            title: '기호 5번',
            description: '<h2>정종만 (만 45세)</h2><p><br></p><p>전) 청년부 특강 강의</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '정종만',
                fileUrl: 'https://api.ohpick.me/api/file/649407f337178500475cd0f5',
              },
            ],
            tags: [],
            id: '75424c50-bcde-4a8f-aa1d-cfadeb2ae36d',
          },
        ],
        responseValidation: {
          allowAbstentionVote: true,
          minResponse: 1,
          maxResponse: 1,
        },
        id: 'f12ba50a-d321-437f-a9f4-a1f4fe2445d5',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'election',
  createdAt: '2023-06-26T06:36:50.165Z',
  __v: 0,
};

const DRAFT_2 = {
  title: '사찰 총무원장 선출 투표',
  _id: '64b89b3d95059000479a18bc',
  content: {
    name: '사찰 총무원장 선출 투표',
    periods: [
      {
        startDate: '2023-07-21T12:56',
        endDate: '2023-07-28T10:57',
      },
    ],
    description:
      '<p>본 투표는 사찰 총무원장 선출 투표입니다.</p><p>여러분의 소중한 한 표 부탁드립니다.</p><p>감사합니다.</p>',
    type: 'Election',
    voterType: 'voterBook',
    subscriptionType: 'enterprise',
    subElections: [
      {
        kind: 'ProsOrConsElection',
        title: '사찰 총무원장 선출 투표',
        shuffle: false,
        candidates: [
          {
            title: '단독후보. 도현스님',
            description:
              '<h2><span style="color: rgb(34, 34, 34);">"한국불교중흥의 새 역사를 열겠습니다."</span></h2><p><br></p><p><span style="color: rgb(34, 34, 34);">우리가 걸어온 과거와 앞으로 걸어갈 미래와 소통하겠습니다.</span></p><p><span style="color: rgb(34, 34, 34);">앞으로의 소통으로 한국불교중흥의 새로운 역사를 만들어 가겠습니다.</span></p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '도현스님',
                fileUrl: 'https://api.ohpick.me/api/file/64b898d795059000479a189f',
              },
            ],
            tags: [],
            id: '8b22a2e8-405d-4e4b-a18e-f87b6de725fa',
          },
        ],
        responseValidation: {
          allowAbstentionVote: true,
        },
        id: 'e0b4222f-a1b7-4f16-9d4a-b253b437800b',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'election',
  createdAt: '2023-07-20T02:26:05.469Z',
  __v: 0,
};

export default [
  {
    title: '교회 향존직 선출 투표',
    description: '교회 향존직 선출 투표를 위한 템플릿입니다.',
    thumbnail: Thumbnail1,
    draft: DRAFT_1,
    category: DraftTemplateCategory.Religion,
    kind: 'Election',
  },
  {
    title: '사찰 총무원장 선출 투표',
    description: '사찰 총무원장 선출 투표를 위한 템플릿입니다.',
    thumbnail: Thumbnail2,
    draft: DRAFT_2,
    category: DraftTemplateCategory.Religion,
    kind: 'Election',
  },
] as DraftTemplate[];
