import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {
  Dropdown,
  Input,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  Text,
  QuestionTooltip,
} from '@pickme/ui';
import { useSetRecoilState } from 'recoil';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';
import ChannelVerifyModal from 'components/features/notification/modal/ChannelVerify';

import {
  useGetChannelCategories,
  useGetRegisterToken,
  useRegisterChannel,
} from 'query-hooks/channel';

import { toast } from 'states/toast';

import { phoneNumberValidator } from 'functions/validator/voter';

import { ChannelRegistrationFormBody } from 'types/channel';

import DownIcon from 'resources/icons/application/down.png';

import './index.scss';

type Props = {
  isVisible: boolean;
  onClose: () => void;
};

function ChannelConnectModal({ isVisible, onClose }: Props) {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toast);
  const [isVerifyModalVisible, setVerifyModalVisible] = useState(false);

  const { data: categories } = useGetChannelCategories();
  const { mutate: mutateGetRegisterToken, isLoading: isGetTokenLoading } = useGetRegisterToken();
  const { mutate: mutateRegisterChannel, isLoading: isRegisterChannelLoading } =
    useRegisterChannel();
  const isLoading = isGetTokenLoading || isRegisterChannelLoading;

  const methods = useForm<ChannelRegistrationFormBody>();

  const onSubmitToGetToken: SubmitHandler<ChannelRegistrationFormBody> = (form) => {
    mutateGetRegisterToken(
      {
        searchId: form.searchId,
        phoneNumber: form.phoneNumber,
      },
      {
        onSuccess: () => {
          setVerifyModalVisible(true);
        },
      },
    );
  };

  const onSubmit = (token: string) => {
    mutateRegisterChannel(
      { form: { ...methods.getValues(), token } },
      {
        onSuccess: () => {
          setVerifyModalVisible(false);
          onClose();
          navigate('/settings/integration/kakao/channel');
        },
      },
    );
  };

  const onError: SubmitErrorHandler<ChannelRegistrationFormBody> = (error) => {
    const message =
      error.searchId?.root?.message ||
      error.searchId?.message ||
      error.phoneNumber?.message ||
      error.categoryName?.message;

    if (message) {
      setToast({
        isVisible: true,
        type: 'error',
        message,
      });
    }
  };

  return (
    <>
      <Modal
        className='channel-connect-modal'
        size='lg'
        isVisible={isVisible && !isVerifyModalVisible}
        onClose={onClose}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmitToGetToken, onError)}>
            <ModalHeader
              title={t('admin:components.features.notification.modal.channelConnect.title')}
              onClose={onClose}
            />

            <ModalBody>
              <div className='channel-connect-modal__form'>
                <div className='channel-connect-modal__form-group'>
                  <div className='channel-connect-modal__form-group__title'>
                    <Text type='b3' fontWeight={400}>
                      {t(
                        'admin:components.features.notification.modal.channelConnect.field.id.label',
                      )}
                    </Text>

                    <QuestionTooltip
                      message={t(
                        'admin:components.features.notification.modal.channelConnect.field.id.tooltip',
                      )}
                    />
                  </div>

                  <div className='channel-connect-modal__form-group__row'>
                    <Text type='b3' fontWeight={400}>
                      @
                    </Text>
                    <Input
                      id='userId'
                      placeholder={t(
                        'admin:components.features.notification.modal.channelConnect.field.id.placeholder',
                      )}
                      {...methods.register('searchId', {
                        validate: (value) => {
                          if (!value) {
                            return t(
                              'admin:components.features.notification.modal.channelConnect.field.id.validate',
                            );
                          }

                          return true;
                        },
                      })}
                    />
                  </div>
                </div>

                <div className='channel-connect-modal__form-group'>
                  <div className='channel-connect-modal__form-group__title'>
                    <Text type='b3' fontWeight={400}>
                      {t(
                        'admin:components.features.notification.modal.channelConnect.field.phone.label',
                      )}
                    </Text>

                    <QuestionTooltip
                      message={t(
                        'admin:components.features.notification.modal.channelConnect.field.phone.tooltip',
                      )}
                    />
                  </div>

                  <Input
                    id='phoneNumber'
                    placeholder={t(
                      'admin:components.features.notification.modal.channelConnect.field.phone.placeholder',
                    )}
                    type='tel'
                    {...methods.register('phoneNumber', {
                      validate: (value) => {
                        if (!value || !phoneNumberValidator(value)) {
                          return t(
                            'admin:components.features.notification.modal.channelConnect.field.phone.validate',
                          );
                        }

                        return true;
                      },
                    })}
                  />
                </div>

                <div className='channel-connect-modal__form-group'>
                  <div className='channel-connect-modal__form-group__title'>
                    <Text type='b3' fontWeight={400}>
                      {t(
                        'admin:components.features.notification.modal.channelConnect.field.category.label',
                      )}
                    </Text>
                  </div>

                  <Controller
                    name='categoryName'
                    control={methods.control}
                    rules={{
                      validate: (value) => {
                        if (!value) {
                          return t(
                            'admin:components.features.notification.modal.channelConnect.field.category.validate',
                          );
                        }

                        return true;
                      },
                    }}
                    render={() => (
                      <Dropdown
                        button={
                          <button type='button' className='channel-connect-modal__dropdown-button'>
                            <Text size='sm1'>
                              {methods.watch('categoryName')?.replaceAll(',', ' / ') ||
                                t(
                                  'admin:components.features.notification.modal.channelConnect.field.category.placeholder',
                                )}
                            </Text>

                            <img
                              className='channel-connect-modal__dropdown-icon'
                              src={DownIcon}
                              alt='dropdown'
                            />
                          </button>
                        }
                        direction='down'
                        height={300}
                        items={(categories || []).map((value) => ({
                          text: `${value.name.replaceAll(',', ' / ')}`,
                          type: 'button',
                          onClick: () => {
                            methods.setValue('categoryCode', value.code);
                            methods.setValue('categoryName', value.name);
                          },
                        }))}
                      />
                    )}
                  />

                  <input hidden {...methods.register('categoryCode')} />
                </div>
              </div>
            </ModalBody>

            <ModalActions
              buttons={[
                {
                  text: t(
                    'admin:components.features.notification.modal.channelConnect.buttons.cancel',
                  ),
                  type: 'button',
                  variant: 'gray',
                  onClick: () => onClose(),
                },
                {
                  text: isLoading ? (
                    <Spinner />
                  ) : (
                    t('admin:components.features.notification.modal.channelConnect.buttons.submit')
                  ),
                  type: 'submit',
                  variant: 'primary',
                  disabled: isLoading,
                },
              ]}
            />
          </form>
        </FormProvider>
      </Modal>

      <Modal
        size='md'
        isVisible={isVerifyModalVisible}
        onClose={() => setVerifyModalVisible(false)}
        className='channel-connect-verify-modal'
      >
        <ModalHeader
          title={t('admin:components.features.notification.modal.channelConnect.verify.title')}
          onClose={onClose}
        />
        <ModalBody>
          <div className='channel-connect-verify-modal__body'>
            <Text type='b3' fontWeight={400} color='gray-400'>
              {t('admin:components.features.notification.modal.channelConnect.verify.label')}
            </Text>
            <Input
              placeholder={t(
                'admin:components.features.notification.modal.channelConnect.verify.placeholder',
              )}
              onChange={(event) => {
                methods.setValue('token', event.target.value);
              }}
            />
          </div>
        </ModalBody>
        <ModalActions
          buttons={[
            {
              text: t(
                'admin:components.features.notification.modal.channelConnect.verify.buttons.cancel',
              ),
              type: 'button',
              variant: 'gray',
              onClick: () => setVerifyModalVisible(false),
            },
            {
              text: isLoading ? (
                <Spinner />
              ) : (
                t(
                  'admin:components.features.notification.modal.channelConnect.verify.buttons.submit',
                )
              ),
              type: 'submit',
              variant: 'primary',
              disabled: isLoading,
            },
          ]}
        />
      </Modal>

      <ChannelVerifyModal
        isVisible={isVerifyModalVisible}
        isLoading={isLoading}
        onClose={() => setVerifyModalVisible(false)}
        onSubmit={(code) => onSubmit(code)}
      />
    </>
  );
}

export default ChannelConnectModal;
