import { ReactNode } from 'react';
import { Text } from '@pickme/design-system';

import { pair } from './index.css';

type Props = {
  title: string;
  titleWidth?: string | number;
  gap?: string | number;
  children: ReactNode;
};

function MessagingDetailPair({ title, titleWidth, gap, children }: Props) {
  const width = titleWidth || 120;

  return (
    <div className={pair} style={{ gap }}>
      <div style={{ width }}>
        <Text size={16} color='gray-600'>
          {title}
        </Text>
      </div>

      {typeof children === 'string' ? (
        <Text size={16} color='black'>
          {children}
        </Text>
      ) : (
        children
      )}
    </div>
  );
}

export default MessagingDetailPair;
