import { Avatar } from '@pickme/ui';
import { slice } from 'lodash-es';

type Props = {
  className: string;
  imageUrls: string[];
};

function Avatars({ className, imageUrls }: Props) {
  return (
    <div className={className}>
      {slice(imageUrls, 0, 5).map((imageUrl) => (
        <Avatar key={imageUrl} size='md' image={imageUrl || ''} border />
      ))}

      {imageUrls.length > 5 && (
        <div className='application-form__poll-card__avatars__more'>+{imageUrls.length - 5}</div>
      )}
    </div>
  );
}

export default Avatars;
