import { Text } from '@pickme/design-system';
import { PlainButton } from '@pickme/ui';
import { useState } from 'react';
import { t } from 'i18next';
import { NavLink } from 'react-router-dom';

import Dropdown from 'components/features/layout/Dropdown';

import { useGetSessionAsMember, useSignOut } from 'query-hooks/auth';

import ProfileIcon from 'resources/icons/nav/nav_profile.svg';

import {
  button,
  outerContainer,
  container,
  item,
  nameCardAvatar,
  name,
  email,
  labelArea,
} from './index.css';

function UserMenu() {
  const [isDropdownOpened, setDropdownOpen] = useState(false);

  const { data: member } = useGetSessionAsMember();
  const firstCharacterOfName = member?.name?.trim().slice(0, 1) || '';

  const { mutate: signOut } = useSignOut();

  return (
    <div className={outerContainer}>
      <Dropdown
        position='bottom'
        isOpen={isDropdownOpened}
        onOpenDropdown={() => setDropdownOpen(true)}
        onCloseDropdown={() => setDropdownOpen(false)}
        trigger={
          <div className={button}>
            <img src={ProfileIcon} alt={member?.name} />

            <Text>{member?.name}</Text>
          </div>
        }
      >
        <div className={container}>
          <div className={item({ type: 'nameCard' })}>
            <Text
              className={nameCardAvatar({
                colorType: ((firstCharacterOfName?.charCodeAt(0) ?? 0) % 3) as 0 | 1 | 2,
              })}
              size={24}
              fontWeight={800}
            >
              {firstCharacterOfName}
            </Text>

            <div className={labelArea}>
              <Text className={name} size={16} fontWeight={600} color='black'>
                {member?.name}
              </Text>

              <Text className={email} size={12} fontWeight={400} color='gray-500'>
                {member?.email}
              </Text>
            </div>
          </div>

          <NavLink
            type='button'
            className={item({ type: 'button' })}
            to='/account'
            unstable_viewTransition
          >
            <Text size={16} fontWeight={400}>
              {t('admin:components.common.sidebar.buttons.account')}
            </Text>
          </NavLink>

          <NavLink
            type='button'
            className={item({ type: 'button' })}
            to='/feedback'
            onClick={() => setDropdownOpen(false)}
            unstable_viewTransition
          >
            <Text size={16} fontWeight={400}>
              {t('admin:components.common.navbar.buttons.feedback')}
            </Text>
          </NavLink>

          <PlainButton
            type='button'
            className={item({ type: 'button' })}
            onClick={() => {
              signOut({ redirectToIntroduction: true });
            }}
          >
            <Text size={16} fontWeight={400} color='red-500'>
              {t('admin:components.common.sidebar.buttons.logout')}
            </Text>
          </PlainButton>
        </div>
      </Dropdown>
    </div>
  );
}

export default UserMenu;
