import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { PlainButton, Text } from '@pickme/design-system';

import { useRemoveDraft } from 'query-hooks/draft';

import { Draft } from 'types/draft';

import RemoveIcon from 'resources/icons/poll-home/remove.svg';

import { badge, card, contents, date, rightSection } from '../../common.css';

type Props = {
  draft: Draft;
};

function HomeDraftCard({ draft }: Props) {
  const navigate = useNavigate();

  const { mutate } = useRemoveDraft();

  const { periods } = draft.content;
  const isPeriodsSet = periods && periods.length > 0;

  const navigateToEditPage = () => {
    const pollKind = draft.content.type?.toLowerCase();

    if (!pollKind) {
      return;
    }

    navigate(`/poll/${pollKind}/draft/${draft._id}`, { unstable_viewTransition: true });
  };

  return (
    <div
      className={card}
      role='button'
      tabIndex={0}
      onClick={navigateToEditPage}
      onKeyDown={(event) => {
        if (event.key !== 'Enter') {
          return;
        }

        navigateToEditPage();
      }}
    >
      <div className={contents.container}>
        <div className={contents.left}>
          <div className={badge.container}>
            <div className={badge.item}>{draft.content.isPublic ? '공개 투표' : '구성원 투표'}</div>
          </div>

          <Text size={16} fontWeight={600} color='gray-800'>
            {draft.title}
          </Text>

          <Text size={14} fontWeight={500} color='gray-500' className={date}>
            {isPeriodsSet ? (
              <>
                {dayjs(periods[0].startDate).format('YY.MM.DD (dd) HH:mm')} ~
                {dayjs(periods[periods.length - 1].endDate).format('YY.MM.DD (dd) HH:mm')}
              </>
            ) : (
              '기간을 설정해주세요'
            )}
          </Text>
        </div>

        <div className={contents.right}>
          {/* TODO: 게시하기 버튼이 추가되어야 합니다. */}
          {/* TODO: 복제 기능이 추가되면 드롭다운 주석을 해제해야 합니다. */}

          {/* <DraftMoreDropdown draft={draft} triggerClassName={rightSection.iconButton} /> */}
          <PlainButton
            className={rightSection.iconButton}
            onClick={(e) => {
              e.stopPropagation();

              const kind = draft.content.type;

              if (!kind) {
                return;
              }

              mutate({ id: draft._id, kind: kind === 'Election' ? 'election' : 'survey' });
            }}
          >
            <img src={RemoveIcon} alt='remove draft' />
          </PlainButton>
        </div>
      </div>
    </div>
  );
}

export default HomeDraftCard;
