import { isEqual } from 'lodash-es';
import { useMutation, useQuery, useQueryClient, useInfiniteQuery, useQueries } from 'react-query';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import {
  createApplyElectionRequest,
  createEditElectionRequest,
  createGetPollRateRequest,
  createGetPollRequest,
  createGetPollResult,
  createGetPollsRequest,
  createGetPollStatistics,
  createGetVotersResult,
  createGetPopulation,
  createGetPollsHistoryRequest,
  createGetPollCreationStatisticsRequest,
  createGetPollAnalysis,
  createApplyPollAnalysis,
  createGetPollsWithoutPagingRequest,
} from 'apis/poll';

import { toast } from 'states/toast';
import { pollViewer } from 'states/poll-viewer';

import channelTalk from 'functions/channel-talk';
import { createPollQuery } from 'functions/poll';
import { sendCreatePollEvent, sendEditPollEvent } from 'functions/analytics/poll';

import { ElectionFormBody, ElectionFormBodyForEdit, SurveyFormBody } from 'types/application';
import {
  PollAnalysisResult,
  PollKind,
  PollSearchQuery,
  PollQueryAdapter,
  PollSearchKind,
  PollSearchGroup,
  PollStatistics,
} from 'types/poll';

const casesNeedRedirect = ['POLL-007', 'POLL-008', 'POLL-010', 'POLL-012'];

export const useApplyPoll = () => {
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async (
      body:
        | Omit<ElectionFormBody, 'incorrectVoters' | 'subscription'>
        | Omit<SurveyFormBody, 'incorrectVoters' | 'subscription'>,
    ) => {
      const { data } = await createApplyElectionRequest(body);
      return data;
    },
    {
      onSuccess: ({ _id }, formData) => {
        sendCreatePollEvent({
          pollId: _id,
          voters: formData.voterBook.length,
          plan: formData.subscriptionType,
          periods: formData.periods,
          kind: formData.type,
          subPollCounts:
            (isElectionFormBody(formData) && formData.subElections.length) ||
            (isSurveyFormBody(formData) && formData.questions.length) ||
            0,
          voterAuthMethods: formData.voterAuthMethods,
          allowRealTimeResult: formData.allowRealTimeResult,
          isOpenPollRate: formData.isOpenPollRate,
          isOpenPollResult: formData.isOpenPollResult,
          openThreshold: formData.openThreshold,
        });

        channelTalk.track('create poll');

        queryClient.invalidateQueries(['polls']);
        queryClient.invalidateQueries(['polls-stats']);
      },
      onError: (error: AxiosError) => {
        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });
      },
    },
  );
};

export const useEditPoll = () => {
  const setToast = useSetRecoilState(toast);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, body }: { id: string; kind: PollKind; body: ElectionFormBodyForEdit }) => {
      const { data } = await createEditElectionRequest(id, body);
      return data;
    },
    {
      onSuccess: async (_, { id, body, kind }) => {
        sendEditPollEvent({
          pollId: id,
          kind,
          editFields: Object.keys(body) as (keyof ElectionFormBodyForEdit)[],
        });

        queryClient.invalidateQueries(['poll', id]);
        queryClient.invalidateQueries(['polls']);
        queryClient.invalidateQueries(['polls-stats']);
      },
      onError: (error: AxiosError) => {
        if (casesNeedRedirect.includes(error.code || '')) {
          navigate(-1);
        }

        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });
      },
    },
  );
};

export const useGetPolls = ({
  disabled,
  ...queryAdapter
}: { disabled?: boolean } & PollQueryAdapter) => {
  const pollQuery = createPollQuery(queryAdapter);

  return useQuery(
    ['polls', pollQuery],
    async () => {
      const { data } = await createGetPollsRequest(pollQuery);
      return {
        ...data,
        isSearched: !!pollQuery.pollName || !!pollQuery.period || !!pollQuery.groups,
      };
    },
    {
      enabled: !disabled,
    },
  );
};

export const useGetInfinitePolls = (query: PollSearchQuery) => {
  const pollQuery = createPollQuery(query);

  return useInfiniteQuery(
    ['polls', 'infinite', pollQuery],
    async ({ pageParam = 1 }) => {
      const { data } = await createGetPollsRequest({ ...pollQuery, page: pageParam });
      return data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.polls.length === 0) {
          return undefined;
        }

        return allPages.length + 1;
      },
    },
  );
};

export const useGetPollsById = (pollIds: string[]) =>
  useQueries(
    pollIds.map((pollId) => ({
      queryKey: ['poll', pollId],
      queryFn: async () => {
        const { data } = await createGetPollRequest(pollId);
        return data;
      },
    })),
  );

export const useGetOngoingPollCounts = () =>
  useQuery(
    ['polls', 'ongoing'],
    async () => {
      const { data } = await createGetPollsWithoutPagingRequest({
        pollRate: false,
        groups: 'ongoing',
      });

      return data.length;
    },
    {
      enabled: false,
    },
  );

export const useGetPoll = (id: string) => {
  const setToast = useSetRecoilState(toast);
  const [{ isVisible }, setPollViewer] = useRecoilState(pollViewer);
  const navigate = useNavigate();

  return useQuery(
    ['poll', id],
    async () => {
      const { data } = await createGetPollRequest(id);
      return data;
    },
    {
      enabled: !!id,
      onError: (error: AxiosError) => {
        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });

        if (isVisible) {
          setPollViewer({
            id: '',
            isVisible: false,
          });
        } else {
          navigate(-1);
        }
      },
      staleTime: 30 * 1000,
      cacheTime: 30 * 1000,
    },
  );
};

export const useGetPollRate = (id: string) => {
  const setToast = useSetRecoilState(toast);
  const [{ isVisible }, setPollViewer] = useRecoilState(pollViewer);
  const navigate = useNavigate();

  return useQuery(
    ['poll', id, 'rate'],
    async () => {
      const { data } = await createGetPollRateRequest(id);
      return data;
    },
    {
      enabled: !!id,
      onError: (error: AxiosError) => {
        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });

        if (isVisible) {
          setPollViewer({
            id: '',
            isVisible: false,
          });
        } else {
          navigate(-1);
        }
      },
      staleTime: 30 * 1000,
      cacheTime: 30 * 1000,
    },
  );
};

export const useGetPollStats = (kind: PollKind | PollSearchKind) =>
  useQuery<PollStatistics>(
    ['poll-stats', kind],
    async () => {
      const query: { kind?: PollKind | PollSearchKind } = { kind };
      if (kind === PollSearchKind.All) {
        delete query.kind;
      }

      const { data } = await createGetPollStatistics(query);

      return {
        [PollSearchGroup.All]: Object.entries(data).reduce((prev, [_, value]) => prev + value, 0),
        [PollSearchGroup.Ready]: data.ready,
        [PollSearchGroup.Ongoing]: data.ongoing,
        [PollSearchGroup.Paused]: data.pause,
        [PollSearchGroup.Ended]: data.post,
      };
    },
    {
      enabled: !!kind,
      onError: () => {},
    },
  );

export const useGetPollResult = (id: string) => {
  const setToast = useSetRecoilState(toast);
  const [{ isVisible }, setPollViewer] = useRecoilState(pollViewer);
  const navigate = useNavigate();

  return useQuery(
    ['poll', id, 'result'],
    async () => {
      const { data } = await createGetPollResult(id);
      return data;
    },
    {
      enabled: !!id,
      onError: (error: AxiosError) => {
        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });

        if (isVisible) {
          setPollViewer({
            id: '',
            isVisible: false,
          });
        } else {
          navigate(-1);
        }
      },
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    },
  );
};

export const useGetVoters = (pollId: string) => {
  const navigate = useNavigate();
  return useQuery(
    ['poll', pollId, 'voters'],
    async () => {
      const { data } = await createGetVotersResult(pollId);
      return data;
    },
    {
      enabled: !!pollId,
      onError: (error: AxiosError) => {
        if (casesNeedRedirect.includes(error.code || '')) {
          navigate(-1);
        }
      },
    },
  );
};

export const useGetPopulation = (pollId: string, enabled: boolean) => {
  const navigate = useNavigate();

  return useQuery(
    ['poll', pollId, 'population'],
    async () => {
      const { data } = await createGetPopulation(pollId);
      return data;
    },
    {
      enabled: !!pollId && enabled,
      onError: (error: AxiosError) => {
        if (casesNeedRedirect.includes(error.code || '')) {
          navigate(-1);
        }
      },
    },
  );
};

export const useGetPollsHistory = (page?: number) =>
  useQuery(
    ['polls-history', page],
    async () => {
      const { data } = await createGetPollsHistoryRequest(page ?? 1);

      return data;
    },
    {
      onError: () => {},
    },
  );

export const useGetPollCreationStatistics = () =>
  useQuery(
    ['poll-stats', 'creation'],
    async () => {
      const { data } = await createGetPollCreationStatisticsRequest();

      return data;
    },
    {
      onError: () => {},
    },
  );

export const useGetPollAnalysis = (id: string) => {
  const queryClient = useQueryClient();

  return useQuery(
    ['poll-analysis', id],
    async () => {
      const { data } = await createGetPollAnalysis(id);

      if (isEqual(data, [])) {
        return {
          pollOid: id,
          analysisType: 'summary',
          _id: '',
          status: 'unRequested',
        } as PollAnalysisResult;
      }

      const result: PollAnalysisResult = data[0];
      if (result.status === 'requested') {
        setTimeout(() => {
          queryClient.invalidateQueries(['poll-analysis', id]);
        }, 4000);
      }

      return result;
    },
    {
      enabled: !!id,
      onError: () => {},
    },
  );
};

export const useApplyPollAnalysis = () => {
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async (pollId: string) => {
      const { data } = await createApplyPollAnalysis(pollId);

      return data;
    },
    {
      onSuccess: (_, pollId) => {
        queryClient.invalidateQueries(['poll-analysis', pollId]);
      },
      onError: (error: AxiosError) => {
        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });
      },
    },
  );
};

const isElectionFormBody = (
  body: Partial<ElectionFormBody> | Partial<SurveyFormBody>,
): body is ElectionFormBody => body.type === 'Election';

const isSurveyFormBody = (
  body: Partial<ElectionFormBody> | Partial<SurveyFormBody>,
): body is SurveyFormBody => body.type === 'Survey';
