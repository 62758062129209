export enum IntegrationService {
  Kakao = 'kakao',
  Email = 'email',
  Slack = 'slack',
}

export enum IntegrationStatus {
  Disabled = 'disabled',
  Enabled = 'enabled',
  Pending = 'pending',
  Complete = 'complete',
  NotLaunchedYet = 'notLaunchedYet',
}
