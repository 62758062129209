import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Input, IconInput } from '@pickme/design-system';
import { t } from 'i18next';

import { NameAndPeriodSearchForm } from 'types/search';

import SearchIcon from 'resources/icons/dashboard/search.svg';

import { container, period, input } from './index.css';

type Props = {
  initialValues?: NameAndPeriodSearchForm;
  placeholder?: string;
  disableToUsePeriod?: boolean;
};

// TODO: 일반적인 이름인, keyword로 변경할 것.
// 추가적인 분기가 생긴다면, 다른 컴포넌트로 만들 것.
function NameAndPeriodSearchController({ initialValues, placeholder, disableToUsePeriod }: Props) {
  const searchForm = useFormContext<NameAndPeriodSearchForm>();

  useEffect(() => {
    if (initialValues) {
      searchForm.reset(initialValues);
    }
  }, [initialValues?.name, initialValues?.period?.start, initialValues?.period?.end]);

  return (
    <div className={container}>
      <IconInput
        size='md'
        className={input}
        icon={SearchIcon}
        {...searchForm.register('name')}
        type='submit'
        placeholder={
          placeholder ?? t('admin:components.features.search.nameAndPeriodSearch.name.placeholder')
        }
      />

      {!disableToUsePeriod && (
        <div className={period}>
          <Input size='md' className={input} type='date' {...searchForm.register('period.start')} />
          <span className='dashboard__search-period-between'>~</span>
          <Input size='md' className={input} type='date' {...searchForm.register('period.end')} />
        </div>
      )}

      <Button type='submit' size='md' variant='gray'>
        {t('admin:components.features.search.nameAndPeriodSearch.buttons.submit')}
      </Button>
    </div>
  );
}

export default NameAndPeriodSearchController;
