import { Outlet } from 'react-router-dom';

import './index.scss';

function AuthLayout() {
  return (
    <main className='auth-layout'>
      <meta
        name='viewport'
        content='initial-scale=1, minimum-scale=1.0, maximum-scale=3.0, width=device-width, height=device-height, user-scalable=no'
      />
      <Outlet />
    </main>
  );
}

export default AuthLayout;
