import { Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { t } from 'i18next';

type Props = {
  isVisible: boolean;
  isDisabled: boolean;
  name: string;
  onClose: () => void;
  onSubmit: () => void;
};

function RemoveVoterModal({ isVisible, isDisabled, name, onClose, onSubmit }: Props) {
  return (
    <Modal isVisible={isVisible} onClose={onClose} size='lg'>
      <ModalHeader
        title={t('admin:components.features.application.form.voter.modals.remove.title', {
          name,
        })}
        onClose={onClose}
      />

      <ModalBody>
        <Text type='b3' fontWeight={400} color='gray-400'>
          {t('admin:components.features.application.form.voter.modals.remove.description')}
        </Text>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t(
              'admin:components.features.application.form.voter.modals.remove.actions.cancel',
            ),
            onClick: onClose,
            variant: 'gray',
          },
          {
            text: t(
              'admin:components.features.application.form.voter.modals.remove.actions.submit',
            ),
            disabled: isDisabled,
            onClick: onSubmit,
            variant: 'error',
          },
        ]}
      />
    </Modal>
  );
}

export default RemoveVoterModal;
