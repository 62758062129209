import { DraftTemplate, DraftTemplateCategory } from 'types/template';

import Thumbnail1 from 'resources/images/templates/survey/association1.png';

const DRAFT_1 = {
  title: '협회 정기대의원총회 참석 여부 조사',
  _id: '649935c8a5f0ec00830bec88',
  content: {
    name: '협회 정기대의원총회 참석 여부 조사',
    periods: [
      {
        startDate: '2023-06-26T17:41',
        endDate: '2023-06-30T15:49',
      },
    ],
    description:
      '<p>안녕하세요.</p><p>본 설문은 10월 1일에 있을 협회 정기대의원총회 참석 여부 조사입니다.</p><p>여러분들의 많은 관심과 참여 부탁드립니다. 감사합니다.</p>',
    type: 'Survey',
    voterType: 'voterBook',
    hasOpenVotes: true,
    subscriptionType: 'enterprise',
    questions: [
      {
        kind: 'RadioQuestion',
        title: '정기대의원총회 참석 여부',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '참석합니다.',
            fileUrl: '',
          },
          {
            description: '불참합니다.',
            number: 2,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
        id: '8427c085-3524-4591-8be2-73d62bc1ff58',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-06-26T06:52:56.352Z',
  __v: 0,
};

export default [
  {
    title: '협회 정기대의원총회 참석 여부 조사',
    description: '협회 정기대의원총회 참석 여부 조사를 위한 템플릿입니다.',
    thumbnail: Thumbnail1,
    draft: DRAFT_1,
    category: DraftTemplateCategory.Association,
    kind: 'Survey',
  },
] as DraftTemplate[];
