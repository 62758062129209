import { Outlet } from 'react-router-dom';

import PollShareModal from 'components/features/modal/PollShareModal';
import PollShareModalV2 from 'components/features/poll-v2/modal/PollShareModal';

import { useChannelTalk } from 'hooks/useChannelTalk';
import { useNavigateToRedirectUrl, useSetRedirectUrl } from 'hooks/useRedirectUrl';

function GlobalWrapper() {
  useChannelTalk();
  useSetRedirectUrl();
  useNavigateToRedirectUrl();

  return (
    <div>
      <Outlet />
      {import.meta.env.VITE_USE_V2 ? <PollShareModalV2 /> : <PollShareModal />}
    </div>
  );
}

export default GlobalWrapper;
