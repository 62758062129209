export default {
  title: '투표 공유하기',
  description: '쉽고 빠르게 참여자들에게 투표를 안내하세요',
  items: {
    kakao: {
      title: ['방법 1', '카카오 알림톡 메세지 보내기'],
      button: '알림톡 보내기',
    },
    link: {
      title: ['방법 2', '참여자 링크를 복사하여 전달하기'],
      labels: ['스페이스 고유 링크', '투표 고유 링크'],
      button: '링크복사',
    },
    qr: {
      title: ['방법 3', 'QR코드 이미지 공유하기'],
      buttons: ['새 창에서 보기', '이미지로 저장하기'],
    },
  },
  messages: {
    success: '성공적으로 링크가 복사되었습니다.',
    failure: '링크 복사에 실패했습니다.',
  },
  actions: {
    later: '다음에하기',
    verify: '인증하기',
  },
};
