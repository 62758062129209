import { Table } from '@pickme/ui';
import { Text } from '@pickme/design-system';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { t } from 'i18next';

import Pagination from 'components/common/Pagination';
import PaymentRecordModal from 'components/features/payment/modal/PaymentRecord';

import { useGetPaymentRecords } from 'query-hooks/payment';

import { useQueryString } from 'hooks/useQueryString';

import { PaymentRecord } from 'types/payment';

import SearchIcon from 'resources/icons/table/search.png';

import { columns, container, table } from './index.css';

function PaymentRecordsTable() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [detailModal, setDetailModal] = useState({ isVisible: false, id: '' });

  const { page } = useQueryString({ page: 1 });
  const { isLoading, data } = useGetPaymentRecords(page);

  return (
    <>
      <div className={container}>
        <Text size={16} fontWeight={500}>
          {t('admin:pages.payment.history.title')}
        </Text>

        <Table
          className={table}
          headers={tableHeaders}
          showSkeleton={isLoading}
          rows={setTableRows(data?.payments)}
          id='payment-history'
          placeholder={{
            icon: SearchIcon,
            text: t('admin:pages.payment.history.search.result.blank'),
          }}
          onItemClick={(id) => {
            setDetailModal({
              isVisible: true,
              id,
            });
          }}
        />

        <Pagination
          page={page}
          endPage={data?.endPage || 1}
          setPage={(targetPage: number) => {
            navigate(`${pathname}?page=${targetPage}`);
          }}
        />
      </div>

      <PaymentRecordModal
        isVisible={detailModal.isVisible}
        id={detailModal.id}
        onClose={() => {
          setDetailModal({
            isVisible: false,
            id: '',
          });
        }}
      />
    </>
  );
}

const tableHeaders = [
  {
    title: t('admin:pages.payment.history.table.header.name'),
    name: 'name',
    className: columns.name,
  },
  {
    title: t('admin:pages.payment.history.table.header.date'),
    name: 'date',
    className: columns.date,
  },
  {
    title: t('admin:pages.payment.history.table.header.method'),
    name: 'date',
    className: columns.method,
  },
  {
    title: t('admin:pages.payment.history.table.header.amount'),
    name: 'amount',
    className: columns.amount,
  },
  {
    title: t('admin:pages.payment.history.table.header.detail'),
    name: 'detail',
    className: columns.detail,
  },
];

const setTableRows = (payments?: PaymentRecord[]) =>
  payments?.map((payment) => ({
    id: payment._id,
    items: [
      {
        data: `${payment.product?.planTypeName || ''} ${t('admin:terms.space.plan')}/${(
          (payment.product?.type === 'increaseLimit' && payment.product.number) ||
          (payment.product?.type === 'subscription' && payment.product.voterLimit)
        ).toLocaleString('ko-KR')}${t('admin:terms.unit.people')}`,
        name: 'name',
        className: columns.name,
      },
      {
        data: moment(payment.createdAt).tz('Asia/Seoul').format('YY/MM/DD HH:mm:ss'),
        name: 'date',
        className: columns.date,
      },
      {
        data: payment.methodName,
        name: 'amount',
        className: columns.method,
      },
      {
        data: `${payment.amount.toLocaleString('ko-KR')} ${t('admin:terms.unit.won')}`,
        name: 'amount',
        className: columns.amount,
      },
      {
        data: t('admin:pages.payment.history.table.body.labels.detail'),
        name: 'detail',
        className: columns.detail,
      },
    ],
  })) || [];

export default PaymentRecordsTable;
