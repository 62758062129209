import { IntegrationStatus } from 'types/integration';

export default {
  title: '연동',
  buttons: {
    [IntegrationStatus.Disabled]: {
      common: '연동하기',
      kakao: '채널 연동',
    },
    [IntegrationStatus.Enabled]: {
      common: '연동하기',
      kakao: '채널 연동',
    },
    [IntegrationStatus.Pending]: {
      common: '연동중',
      kakao: '채널 심사중',
    },
    [IntegrationStatus.Complete]: '연동 완료',
    [IntegrationStatus.NotLaunchedYet]: '출시 예정',
  },
};
