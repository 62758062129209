import { useState } from 'react';
import { Text, Table, PlainButton } from '@pickme/design-system';

import ArrowIcon from 'resources/icons/payment/arrow-down.svg';

import { wrapper } from '../index.css';
import { arrow, cell, header, table, title } from './index.css';

function CardLimitationNote() {
  const [isOpened, setIsOpened] = useState(false);

  const toggleTable = () => setIsOpened((prev) => !prev);

  return (
    <div className={wrapper}>
      <PlainButton className={title} onClick={toggleTable}>
        <Text size={14} fontWeight={400} color='gray-500'>
          카드사별 충전 한도
        </Text>

        <img src={ArrowIcon} alt='arrow' className={arrow({ rotate: isOpened })} />
      </PlainButton>

      <div className={table({ isOpened })}>
        <Table>
          <Table.Header>
            <Table.Tr>
              <Table.Th textAlign='start' className={header}>
                카드사
              </Table.Th>
              <Table.Th textAlign='start' className={header}>
                결제한도
              </Table.Th>
            </Table.Tr>
          </Table.Header>

          <Table.Body>
            {CARD_PAY_LIMITATIONS.map((card) => (
              <Table.Tr key={card.name}>
                <Table.Td textAlign='start' className={cell}>
                  {card.name}
                </Table.Td>
                <Table.Td textAlign='start' className={cell}>
                  {card.limit}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}

export default CardLimitationNote;

const CARD_PAY_LIMITATIONS = [
  { name: '비씨, 하나 (구외환)', limit: '없음' },
  { name: '국민', limit: '월 100만원 한도' },
  { name: '삼성', limit: '월 50만원 한도' },
  { name: '농협 NH', limit: '없음 (1회 10만원 / 1일 10회 / 1일 50만원 한도)' },
  { name: '롯데', limit: '없음 (1회 5만원 한도)' },
  { name: '신한', limit: '없음 (1회 5만원 한도)' },
  { name: '현대', limit: '없음(1회 10만원 / 1일 10회 / 1일 50만원 한도)' },
];
