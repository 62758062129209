// TODO: Sidebar, Navbar에서 사용할 dropdown 입니다. 추후 적절한 위치로 옮길 예정입니다.

import { ReactNode, useEffect, useRef } from 'react';
import { PlainButton } from '@pickme/ui';

import { container, dropdown } from './index.css';

type Props = {
  isOpen: boolean;
  onOpenDropdown: () => void;
  onCloseDropdown: () => void;
  trigger: ReactNode;
  children: ReactNode;
  position: 'right' | 'bottom';
};

function Dropdown({ isOpen, onOpenDropdown, onCloseDropdown, trigger, children, position }: Props) {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) {
      return () => {};
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        onCloseDropdown();
      }
    };

    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onCloseDropdown]);

  return (
    <div className={container} ref={dropdownRef}>
      <PlainButton
        type='button'
        onClick={() => {
          if (isOpen) {
            onCloseDropdown();
          } else {
            onOpenDropdown();
          }
        }}
      >
        {trigger}
      </PlainButton>

      <div className={dropdown({ position, isVisible: isOpen })}>{children}</div>
    </div>
  );
}

export default Dropdown;
