import categorizedProgressGraphSection from './categorizedProgressGraphSection';
import instantVoterOverviewSection from './instantVoterOverviewSection';
import instantVoterProgressGraphSection from './instantVoterProgressGraphSection';
import overviewSection from './overviewSection';
import progressGraphSection from './progressGraphSection';
import pollProgress from './pollProgress';

export default {
  categorizedProgressGraphSection,
  instantVoterOverviewSection,
  instantVoterProgressGraphSection,
  overviewSection,
  progressGraphSection,
  pollProgress,
};
