import { OutlineButton, Text } from '@pickme/ui';
import { t } from 'i18next';
import { PollGroup } from '@pickme/core';

import { useGetPopulation } from 'query-hooks/poll';

import { createDownloadVoterBook } from 'apis/file';

import DownPrimaryIcon from 'resources/icons/application/download-primary.png';

import './index.scss';

type Props = {
  pollName: string;
  pollId: string;
  pollGroup?: PollGroup;
  isDownloadButtonVisible: boolean;
};

const downloadVoterBook = async (pollId: string, pollName: string, type: 'all' | 'votedVoters') => {
  try {
    await createDownloadVoterBook(pollId, pollName, type);
  } catch {
    // TODO: Add error handle.
    // no-op
  }
};

function InstantVoterCardSection({ pollName, pollId, pollGroup, isDownloadButtonVisible }: Props) {
  const { data } = useGetPopulation(pollId, pollGroup === 'counted');

  return (
    <div className='poll-detail__instant-voter'>
      <div className='poll-detail__instant-voter__label'>
        {pollGroup !== 'counted' ? (
          <Text fontWeight={400} type='b3'>
            {t(
              'admin:components.features.poll.pollDetail.instantVoterCardSection.label.instantVoter',
            )}
          </Text>
        ) : (
          <>
            <Text fontWeight={400} type='b3'>
              {t('admin:components.features.poll.pollDetail.instantVoterCardSection.label.total')}
            </Text>
            <Text fontWeight={500} type='b3'>
              {data?.totalPopulation || 0} {t('admin:terms.unit.people')}
            </Text>
          </>
        )}
      </div>

      {isDownloadButtonVisible && (
        <OutlineButton
          variant='primary'
          size='sm'
          onClick={() => {
            downloadVoterBook(pollId, pollName, 'votedVoters');
          }}
        >
          <img
            className='poll-detail__instant-voter__download__button__icon'
            src={DownPrimaryIcon}
            alt='download'
          />
          {pollGroup === 'counted'
            ? t(
                'admin:components.features.poll.pollDetail.instantVoterCardSection.buttons.download.final',
              )
            : t(
                'admin:components.features.poll.pollDetail.instantVoterCardSection.buttons.download.realtime',
              )}
        </OutlineButton>
      )}
    </div>
  );
}

export default InstantVoterCardSection;
