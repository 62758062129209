export default {
  useRemoveSpace: {
    onSuccess: {
      toast: '성공적으로 스페이스가 삭제되었습니다',
    },
  },

  useLeaveSpace: {
    onSuccess: {
      toast: '더 이상 해당 스페이스에 접근할 수 없습니다',
    },
  },

  useHandSpaceOver: {
    onSuccess: {
      modal: {
        title: '소유자 변경 초대 메일 발송',
        message:
          '새 소유자에게 소유자 변경 초대 메일을 발송했어요. 새 소유자가 이전을 수락해야 최종적으로 소유자가 변경됩니다.',
      },
    },
  },

  useCancelHandSpaceOver: {
    onSuccess: {
      toast: '스페이스 소유자 변경 요청이 성공적으로 취소되었습니다',
    },
  },

  useTakeOverSpaceWithoutPayment: {
    onSuccess: {
      modal: {
        title: '스페이스 소유자 변경 완료',
        message: '축하합니다! 스페이스 소유자 변경이 완료되었어요.',
        buttonText: '확인',
      },
    },
  },

  useTakeOverSpaceWithPayment: {
    onSuccess: {
      modal: {
        title: '스페이스 소유자 변경 완료',
        message: '축하합니다! 스페이스 소유자 변경이 완료되었어요.',
        buttonText: '확인',
      },
    },
  },
};
