import { FormProvider, useForm } from 'react-hook-form';
import { Spinner } from '@pickme/ui';
import { Input, PlainButton } from '@pickme/design-system';

import { useReplaceUserPassword } from 'query-hooks/user';

import { passwordValidator } from 'functions/validator/auth';

import { field, fields } from '../../common.css';
import { divider } from './index.css';

type Props = {
  onClose: () => void;
};

function PasswordChangeForm({ onClose }: Props) {
  const passwordForm = useForm({
    mode: 'onChange',
    defaultValues: { password: '', newPassword: '', passwordChecker: '' },
  });
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = passwordForm;

  const onCloseModal = () => {
    onClose();
    reset();
  };

  const isLoading = false;

  const { mutate: replacePassword } = useReplaceUserPassword();

  const onSubmit = () => {
    replacePassword(
      { password: watch('password'), newPassword: watch('newPassword') },
      {
        onSuccess: () => {
          onCloseModal();
        },
      },
    );
  };

  const isSubmitDisabled =
    isLoading ||
    !watch('password') ||
    !watch('newPassword') ||
    !watch('passwordChecker') ||
    watch('newPassword') !== watch('passwordChecker');

  return (
    <FormProvider {...passwordForm}>
      <form onSubmit={handleSubmit(onSubmit)} className={fields}>
        <Input
          {...register('password', { required: true })}
          size='lg'
          width={322}
          type='password'
          placeholder='현재 비밀번호를 입력해 주세요'
        />

        <div className={divider} />

        <Input
          {...register('newPassword', {
            required: true,
            validate: (value: string) => {
              const isValid = passwordValidator(value);

              if (!isValid) {
                return '8 ~ 20자리의 영문 + 숫자 + 특수문자의 조합으로 입력 가능합니다.';
              }

              return true;
            },
          })}
          size='lg'
          width={322}
          type='password'
          placeholder='새 비밀번호를 입력해 주세요'
          isValid={!errors.newPassword}
          errorMessage={errors.newPassword?.message}
        />

        <div className={field.container}>
          <Input
            {...register('passwordChecker', {
              required: true,
              validate: (value) => {
                const isValid = value && value === watch('newPassword');

                if (!isValid) {
                  return '동일한 비밀번호를 입력해 주세요.';
                }

                return true;
              },
            })}
            size='lg'
            width={322}
            type='password'
            isValid={!errors.passwordChecker}
            placeholder='새 비밀번호를 한 번 더 확인해 주세요'
            errorMessage={errors.passwordChecker?.message}
          />

          <PlainButton
            type='submit'
            disabled={isSubmitDisabled}
            className={field.button({ submit: true })}
          >
            {isLoading ? <Spinner /> : '변경'}
          </PlainButton>

          <PlainButton onClick={onClose} className={field.button()}>
            취소
          </PlainButton>
        </div>
      </form>
    </FormProvider>
  );
}

export default PasswordChangeForm;
