import { Viewer } from '@pickme/ui';
import { Text, PlainButton } from '@pickme/design-system';

import BackIcon from 'resources/icons/common/back.svg';

import { container, divider, header, previous, viewer } from './index.css';

type Props = {
  title: string;
  template: string;
  onClose: () => void;
};

function TemplateViewer({ title, template, onClose }: Props) {
  return (
    <div className={container}>
      <div className={header}>
        <PlainButton onClick={onClose}>
          <img src={BackIcon} alt='back' className={previous} />
        </PlainButton>

        <Text size={16} color='gray-600'>
          {title}
        </Text>
      </div>

      <div className={divider} />

      <Viewer contents={template} contentsType='html' className={viewer} />
    </div>
  );
}

export default TemplateViewer;
