import { t } from 'i18next';
import { useState } from 'react';
import { Pagination, Text } from '@pickme/design-system';
import { Modal, ModalBody, ModalHeader } from '@pickme/ui';
import { useNavigate } from 'react-router-dom';

import DraftItem from 'components/features/application/modal/Draft/Item';

import { useGetDrafts, useRemoveDraft } from 'query-hooks/draft';

import { container, draftsContainer, messageForNoDrafts } from './index.css';

type Props = {
  isVisible: boolean;
  onClose: () => void;
};

function DraftModal({ isVisible, onClose }: Props) {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const { data } = useGetDrafts('all', page);
  const { mutate: removeMutate } = useRemoveDraft();

  return (
    <Modal isVisible={isVisible} onClose={onClose} size='xl'>
      <ModalHeader
        title={t('admin:components.features.application.modal.draft.title')}
        onClose={onClose}
      />

      <ModalBody className={container}>
        <div className={draftsContainer}>
          {!data?.drafts || data?.drafts.length === 0 ? (
            <Text className={messageForNoDrafts} size={14} fontWeight={400} color='gray-800'>
              {t('admin:components.features.application.modal.draft.description')}
            </Text>
          ) : (
            data?.drafts.map((draft) => (
              <DraftItem
                key={draft._id}
                draft={draft}
                onSelect={() => {
                  onClose();
                  navigate(`/poll/${draft.content.type?.toLowerCase()}/draft/${draft._id}`, {
                    unstable_viewTransition: true,
                    replace: true,
                  });
                }}
                onRemoveButtonClick={() => {
                  removeMutate({
                    id: draft._id,
                    kind: draft.content.type === 'Election' ? 'election' : 'survey',
                  });
                }}
              />
            ))
          )}
        </div>

        <Pagination currentPage={page} totalPage={data?.endPage || 1} onMove={setPage} />
      </ModalBody>
    </Modal>
  );
}

export default DraftModal;
