import moment from 'moment-timezone';
import { Text, PollGroupBadge, IconButton } from '@pickme/design-system';
import { Table } from '@pickme/ui';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import SubscriptionBadge from 'components/features/badge/SubscriptionBadge';

import { pollViewer } from 'states/poll-viewer';
import { pollActionModal, pollShareModal } from 'states/modal';

import { PollListItem } from 'types/poll';

import EditIcon from 'resources/icons/table/buttons/edit.svg';
import ShareIcon from 'resources/icons/table/buttons/near_me.svg';
import RemoveIcon from 'resources/icons/table/buttons/delete.svg';

import { cellStyles, buttons, table } from './index.css';

type Props = {
  kind: 'election' | 'survey' | 'all';
  polls: PollListItem[];
  showSkeleton: boolean;
};

function PollTableView({ kind, polls, showSkeleton }: Props) {
  const navigate = useNavigate();

  const setPollViewer = useSetRecoilState(pollViewer);
  const setPollActionModal = useSetRecoilState(pollActionModal);
  const setPollShareModal = useSetRecoilState(pollShareModal);

  const rows =
    polls.map((poll) => {
      const planColumn = [
        {
          data: <SubscriptionBadge type={poll.subscriptionType} />,
          name: 'plan',
          className: cellStyles.plan,
        },
      ];

      const row = [
        {
          data: (
            <div>
              <Text size={14} fontWeight={400} color='gray-800'>
                {poll.name}
              </Text>

              <Text size={14} fontWeight={400} color='gray-500'>
                {`${moment(poll.startDate).tz('Asia/Seoul').format('YY/MM/DD H:mm')} ~ ${moment(
                  poll.endDate.date,
                )
                  .tz('Asia/Seoul')
                  .format('YY/MM/DD H:mm')}`}
              </Text>
            </div>
          ),
          name: 'name',
          className: cellStyles.name,
        },
        {
          data: <PollGroupBadge group={poll.group} />,
          name: 'status',
          className: cellStyles.status,
        },
        {
          data: `${poll.questions?.length || poll.subElections?.length || 0}`,
          name: 'itemCount',
          className: cellStyles.itemCount,
        },
        {
          data: `${poll.pollRate.voteCount || 0}`,
          name: 'voteCount',
          className: cellStyles.voteCount,
        },

        {
          data: `${Number(((poll.pollRate.rate ?? 0) * 100).toFixed(2))}%`,
          name: 'rate',
          className: cellStyles.rate,
        },
        {
          data: (
            <div className={buttons}>
              <IconButton
                icon={ShareIcon}
                alt='share'
                disabled={false}
                onClick={(event) => {
                  event.stopPropagation();
                  setPollShareModal({ isVisible: true, pollName: poll.name, pollId: poll._id });
                }}
              />

              <IconButton
                icon={EditIcon}
                alt='edit'
                disabled={poll.disabledActions.edit}
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(
                    `/poll/${poll.kind === 'Election' ? 'election' : 'survey'}/edit/${poll._id}`,
                  );
                }}
              />

              <IconButton
                icon={RemoveIcon}
                alt='remove'
                disabled={poll.disabledActions.remove}
                onClick={(event) => {
                  event.stopPropagation();
                  setPollActionModal({
                    isVisible: true,
                    kind: poll.kind,
                    type: 'remove',
                    id: poll._id,
                  });
                }}
              />
            </div>
          ),
          name: 'more',
          className: cellStyles.buttons,
        },
      ];

      row.filter((item) => item.name === 'plan' && kind === 'all');

      return {
        id: poll._id,
        items: [...(kind !== 'all' ? planColumn : []), ...row],
      };
    }) || [];

  return (
    <Table
      id='poll-table'
      className={table}
      headers={TABLE_HEADER[kind]}
      rows={rows}
      showSkeleton={showSkeleton}
      onItemClick={(id) => {
        setPollViewer({
          id,
          isVisible: true,
        });
      }}
    />
  );
}

const TABLE_HEADER = {
  election: [
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.plan'),
      name: 'plan',
      className: cellStyles.plan,
    },
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.name.election'),
      name: 'name',
      className: cellStyles.name,
    },
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.status'),
      name: 'status',
      className: cellStyles.status,
    },
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.itemCount'),
      name: 'itemCount',
      className: cellStyles.itemCount,
    },
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.voteCount'),
      name: 'voteCount',
      className: cellStyles.voteCount,
    },
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.rate'),
      name: 'rate',
      className: cellStyles.rate,
    },
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.more'),
      name: 'more',
      className: cellStyles.buttons,
    },
  ],
  survey: [
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.plan'),
      name: 'plan',
      className: cellStyles.plan,
    },
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.name.survey'),
      name: 'name',
      className: cellStyles.name,
    },
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.status'),
      name: 'status',
      className: cellStyles.status,
    },
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.itemCount'),
      name: 'itemCount',
      className: cellStyles.itemCount,
    },
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.voteCount'),
      name: 'voteCount',
      className: cellStyles.voteCount,
    },
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.rate'),
      name: 'rate',
      className: cellStyles.rate,
    },
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.more'),
      name: 'more',
      className: cellStyles.buttons,
    },
  ],
  all: [
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.name.all'),
      name: 'name',
      className: cellStyles.name,
    },
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.status'),
      name: 'status',
      className: cellStyles.status,
    },
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.itemCount'),
      name: 'itemCount',
      className: cellStyles.itemCount,
    },
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.voteCount'),
      name: 'voteCount',
      className: cellStyles.voteCount,
    },
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.rate'),
      name: 'rate',
      className: cellStyles.rate,
    },
    {
      title: t('admin:components.features.dashboard.pollTable.table.header.more'),
      name: 'more',
      className: cellStyles.buttons,
    },
  ],
};

export default PollTableView;
