import createSpace from './createSpace';
import invite from './invite';
import newFeature from './newFeature';
import message from './message';
import phoneNumber from './phoneNumber';
import pollAction from './pollAction';
import pollShare from './pollShare';
import setLanguage from './setLanguage';
import userRemoval from './userRemoval';

export default {
  createSpace,
  invite,
  newFeature,
  message,
  phoneNumber,
  pollAction,
  pollShare,
  setLanguage,
  userRemoval,
};
