import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';
import {
  CredentialSignInBody,
  ProfileAsUser,
  ProfileAsMember,
  SignUpRequestBody,
  SocialIntegrateRequestBody,
  SocialSignInBody,
  SocialSignUpBody,
  SocialUserInfoQuery,
  SocialUserInfo,
  SocialProvider,
} from 'types/auth';

const APIS: Apis = {
  getSession: { method: 'GET', url: '/api/auth/profile' },
  credentialSignIn: { method: 'POST', url: '/api/auth/user/login' },
  credentialSignUp: { method: 'POST', url: '/api/user' },
  socialSignIn: { method: 'POST', url: '/api/auth/socialUser/login' },
  socialSignUp: { method: 'POST', url: '/api/user/social' },
  getSocialUserInfo: { method: 'GET', url: '/api/auth/social/userInfo' },
  socialIntegrate: { method: 'POST', url: '/api/user/social/link' },
  socialDisconnect: { method: 'PUT', url: '/api/user/social/unlink' },
  checkEmailDuplication: { method: 'GET', url: '/api/user/check/duplicate/:email' },
  signOut: { method: 'GET', url: '/api/auth/logout' },
  sendPasswordResetEmail: { method: 'POST', url: '/api/user/send-password-reset' },
  resetPasswordWithToken: { method: 'PUT', url: '/api/user/password-reset' },
  checkPassword: { method: 'PUT', url: '/api/user/password/validate' },
};

export const createGetSessionAsMemberRequest = () =>
  createHttpRequest<ProfileAsMember>({
    ...APIS.getSession,
    headers: { 'Cache-Control': 'no-cache' },
  });

export const createGetSessionAsUserRequest = () =>
  createHttpRequest<ProfileAsUser>({
    ...APIS.getSession,
    headers: { 'Cache-Control': 'no-cache' },
    withoutOrganizationOid: true,
  });

export const createCredentialSignInRequest = (form: CredentialSignInBody) =>
  createHttpRequest<ProfileAsMember>({
    ...APIS.credentialSignIn,
    data: form,
    withoutOrganizationOid: true,
  });

export const createCredentialSignUpRequest = (form: SignUpRequestBody) =>
  createHttpRequest<ProfileAsMember>({
    ...APIS.credentialSignUp,
    data: {
      token: form.token,
      password: form.password,
      name: form.name,
      phoneNumber: form.phoneNumber,
      marketingAgreements: form.mailing ? ['email', 'sms'] : [],
    },
    withoutOrganizationOid: true,
  });

export const createSocialSignInRequest = (data: SocialSignInBody) =>
  createHttpRequest({
    ...APIS.socialSignIn,
    data,
    withoutOrganizationOid: true,
  });

export const createSocialSignUpRequest = (data: SocialSignUpBody) =>
  createHttpRequest({
    ...APIS.socialSignUp,
    data,
    withoutOrganizationOid: true,
  });

export const createGetSocialUserInfoRequest = (query: SocialUserInfoQuery) =>
  createHttpRequest<SocialUserInfo>({
    ...APIS.getSocialUserInfo,
    query,
    withoutOrganizationOid: true,
  });

export const createSocialIntegrateRequest = (data: SocialIntegrateRequestBody) =>
  createHttpRequest({
    ...APIS.socialIntegrate,
    data,
    withoutOrganizationOid: true,
  });

export const createSocialDisconnectRequest = (provider: SocialProvider) =>
  createHttpRequest({
    ...APIS.socialDisconnect,
    data: {
      provider,
    },
    withoutOrganizationOid: true,
  });

export const createCheckEmailDuplicationRequest = (email: string) =>
  createHttpRequest<{ result: boolean }>({
    ...APIS.checkEmailDuplication,
    params: { email },
    withoutOrganizationOid: true,
  });

export const createSignOutRequest = () =>
  createHttpRequest<{ result: boolean }>({ ...APIS.signOut, withoutOrganizationOid: true });

export const createSendPasswordResetEmailRequest = (email: string) =>
  createHttpRequest<{ message: 'ok' }>({
    ...APIS.sendPasswordResetEmail,
    data: { email },
    withoutOrganizationOid: true,
  });

export const createResetPasswordWithTokenRequest = (token: string, password: string) =>
  createHttpRequest<{ message: 'ok' }>({
    ...APIS.resetPasswordWithToken,
    data: { token, password },
    withoutOrganizationOid: true,
  });

export const createPasswordCheckRequest = (password: string) =>
  createHttpRequest<{ result: boolean }>({
    ...APIS.checkPassword,
    data: { password },
    withoutOrganizationOid: true,
  });
