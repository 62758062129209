import { useEffect, useState } from 'react';
import { Input, Modal, ModalActions, ModalBody, ModalHeader, PlainButton, Text } from '@pickme/ui';
import { useFieldArray, useForm } from 'react-hook-form';
import { chunk } from 'lodash-es';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';
import Header from 'components/features/application/form/Voter/IncorrectVoters/Header';
import Pagination from 'components/common/Pagination';

import { IncorrectVoter } from 'types/application';

import DeleteIcon from 'resources/icons/table/delete.png';

import './index.scss';

type Props = {
  isVisible: boolean;
  incorrectVoters: IncorrectVoter[];
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (voters: IncorrectVoter[]) => void;
};

function IncorrectVotersModal({ isVisible, incorrectVoters, isLoading, onClose, onSubmit }: Props) {
  const [page, setPage] = useState(1);

  const methods = useForm<{ incorrectVoters: IncorrectVoter[] }>({
    defaultValues: {
      incorrectVoters: [],
    },
  });

  useEffect(() => {
    if (isVisible && incorrectVoters) {
      methods.setValue('incorrectVoters', incorrectVoters);
    }
  }, [isVisible, incorrectVoters]);

  const { fields, remove } = useFieldArray({
    control: methods.control,
    name: 'incorrectVoters',
  });

  const endPage = Math.floor(fields.length / 10) + 1;
  const fieldsInPage = chunk(fields, 10)?.[page - 1] || [];

  return (
    <Modal className='incorrect-voters-modal' isVisible={isVisible} onClose={onClose} size='lg'>
      <ModalHeader
        title={t('admin:components.features.application.form.voter.modals.incorrect.title')}
        onClose={onClose}
      />

      <ModalBody>
        <div className='incorrect-voters-modal__table'>
          <Header />

          {fieldsInPage.map((field, index) => {
            const voterNumber = (page - 1) * 10 + index;

            return (
              <div className='incorrect-voters-modal__voters__row' key={field.id}>
                <div className='incorrect-voters-modal__voters__row__item number'>
                  <Text type='b3' fontWeight={300}>
                    {voterNumber + 1}
                  </Text>
                </div>

                <div className='incorrect-voters-modal__voters__row__item name'>
                  <Input
                    width='100%'
                    {...methods.register(`incorrectVoters.${voterNumber}.name`)}
                    isValid={!field.wrongFields.includes('name')}
                  />
                </div>

                <div className='incorrect-voters-modal__voters__row__item userId'>
                  <Input
                    width='100%'
                    {...methods.register(`incorrectVoters.${voterNumber}.userId`)}
                    isValid={!field.wrongFields.includes('userId')}
                  />
                </div>

                <div className='incorrect-voters-modal__voters__row__item phone'>
                  <Input
                    width='100%'
                    {...methods.register(`incorrectVoters.${voterNumber}.phoneNumber`, {
                      setValueAs: (value) => value?.trim() || undefined,
                    })}
                    isValid={!field.wrongFields.includes('phoneNumber')}
                  />
                </div>

                <div className='incorrect-voters-modal__voters__row__item email'>
                  <Input
                    width='100%'
                    {...methods.register(`incorrectVoters.${voterNumber}.email`, {
                      setValueAs: (value) => value?.trim().toLowerCase() || undefined,
                    })}
                    isValid={!field.wrongFields.includes('email')}
                  />
                </div>

                <div className='incorrect-voters-modal__voters__row__item category'>
                  <Input
                    width='100%'
                    {...methods.register(`incorrectVoters.${voterNumber}.category`, {
                      setValueAs: (value) => value?.trim() || undefined,
                    })}
                    isValid={!field.wrongFields.includes('category')}
                  />
                </div>

                <div className='incorrect-voters-modal__voters__row__item reason'>
                  <Text type='b3' fontWeight={300}>
                    {field.message}
                  </Text>
                </div>

                <div className='incorrect-voters-modal__voters__row__item delete'>
                  <PlainButton onClick={() => remove(voterNumber)}>
                    <img src={DeleteIcon} alt='delete' />
                  </PlainButton>
                </div>
              </div>
            );
          })}
        </div>

        <Pagination page={page} endPage={endPage} setPage={setPage} />
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t(
              'admin:components.features.application.form.voter.modals.incorrect.buttons.cancel',
            ),
            type: 'button',
            variant: 'gray',
            onClick: onClose,
          },
          {
            text: isLoading ? (
              <Spinner />
            ) : (
              t('admin:components.features.application.form.voter.modals.incorrect.buttons.submit')
            ),
            type: 'button',
            disabled: isLoading,
            onClick: () => {
              onSubmit(methods.getValues('incorrectVoters'));
            },
          },
        ]}
      />
    </Modal>
  );
}

export default IncorrectVotersModal;
