export default {
  label: {
    instantVoter: '불특정 대상자',
    total: '총 참여자 수',
  },
  buttons: {
    download: {
      realtime: '실시간 참여자 명단',
      final: '최종 참여자 명단',
    },
  },
};
