import { Outlet } from 'react-router-dom';
import { Text } from '@pickme/design-system';

import Tabs from 'components/common/Tabs';

import { useTabWithPath } from 'hooks/useTabWithPath';

import { HISTORY_TABS } from 'constants/notification';

import { container } from 'styles/page.css';

function History() {
  const { setTab, selectedTabIndex } = useTabWithPath({
    urlPrefix: '/service/notification/history',
    tabItems: HISTORY_TABS,
  });

  const tabs = HISTORY_TABS.map(({ text, value }) => ({
    text,
    onClick: () => setTab(value),
  }));

  return (
    <div className={container({ gap: 32 })}>
      <Text tag='h1' size={32} fontWeight={600}>
        전송 내역
      </Text>

      <div className={container({ gap: 16 })}>
        <Tabs tabs={tabs} selectedIndex={selectedTabIndex} />

        <Outlet />
      </div>
    </div>
  );
}

export default History;
