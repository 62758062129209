import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Modal, ModalBody, ModalHeader } from '@pickme/ui';
import { OutlineButton, Button, Text } from '@pickme/design-system';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { t } from 'i18next';

import CommonNote from 'components/features/payment/modal/PointBill/CommonNote';
import CardLimitationNote from 'components/features/payment/modal/PointBill/CardLimitationNote';
import PointInput from 'components/features/payment/modal/PointBill/PointInput';

import { useGetOrderId } from 'query-hooks/payment';

import { messageModal } from 'states/modal';

import { formatNumber, parseFormattedNumber } from 'functions/utils';

import { EMPTY_STRING } from 'constants/common';
import { PAY_METHOD_SELECT_ITEMS } from 'constants/payment';

import { PointBillForm } from 'types/payment';

import { container } from 'styles/page.css';

import {
  incrementButton,
  buttons,
  modalBody,
  wrapper,
  amountWithVat,
  methodSelect,
  submit,
} from './index.css';

type Props = {
  isVisible: boolean;
  onClose: () => void;
};

const POINT_INCREMENT = [1000, 5000, 10000, 50000, 100000];

const TOSS_CLIENT_KEY = import.meta.env.VITE_TOSS_DEFAULT_CLIENT_KEY || '';

const applyVAT = (value: number) => Math.floor(value * 1.1);

function PointBillModal({ isVisible, onClose }: Props) {
  const methods = useForm<PointBillForm>({
    defaultValues: {
      amount: '',
      method: '계좌이체',
    },
  });

  const method = methods.watch('method');
  const isCardLimitationVisible = method === '카드';

  const amount = methods.watch('amount');
  const payAmount = applyVAT(parseFormattedNumber(amount));

  const { refetch } = useGetOrderId();
  const setMessageModal = useSetRecoilState(messageModal);

  const onIncrementButtonClick = (increment: number) => {
    const currentAmount = parseFormattedNumber(amount || '0');
    const newAmount = currentAmount + increment;
    const formattedAmount = formatNumber(newAmount);

    methods.setValue('amount', formattedAmount, { shouldValidate: true });
  };

  const onSubmit: SubmitHandler<PointBillForm> = async (data) => {
    const displayedPoint = data.amount;
    const chargeAmount = applyVAT(parseFormattedNumber(data.amount));
    const { data: orderIdData } = await refetch();
    const tossPayments = await loadTossPayments(TOSS_CLIENT_KEY);

    try {
      await tossPayments.requestPayment(data.method, {
        amount: chargeAmount,
        orderId: orderIdData?.orderId || '',
        orderName: `${t('admin:components.features.payment.modal.pointBill.point')} - ${displayedPoint}P`,
        successUrl: `${
          import.meta.env.VITE_INDEX_URL
        }/payment/callback/success?product=point&orderId=${orderIdData?.orderId}&method=${
          data.method
        }&amount=${payAmount}`,
        failUrl: `${import.meta.env.VITE_INDEX_URL}/payment/callback/failure?product=point`,
      });
    } catch (error: any) {
      setMessageModal({
        isVisible: true,
        title: t('admin:components.features.payment.modal.pointBill.error.title'),
        message: t('admin:components.features.payment.modal.pointBill.error.message', {
          text: error.message || t('admin:components.features.payment.modal.pointBill.point'),
        }),
      });
    }
  };

  useEffect(() => {
    if (isVisible) {
      methods.reset();
    }
  }, [isVisible]);

  return (
    <Modal isVisible={isVisible} onClose={onClose} size='xl'>
      <ModalHeader title='충전하기' onClose={onClose} />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={modalBody}>
          <ModalBody className={container({ gap: 32 })}>
            <div className={wrapper}>
              <Text fontWeight={600} color='black'>
                충전 포인트
              </Text>

              <PointInput />

              <div className={buttons}>
                {POINT_INCREMENT.map((add) => (
                  <OutlineButton
                    key={add}
                    variant='gray'
                    onClick={() => onIncrementButtonClick(add)}
                    className={incrementButton}
                  >
                    +{formatNumber(add)}P
                  </OutlineButton>
                ))}
              </div>
            </div>

            <div className={wrapper}>
              <Text fontWeight={600} color='black'>
                결제 예정 금액
              </Text>

              <div className={amountWithVat}>
                <Text size={26} color='primary-500' fontWeight={600}>
                  {amount !== EMPTY_STRING ? formatNumber(payAmount) : 0}원
                </Text>

                <Text size={14} color='gray-400'>
                  (VAT 포함)
                </Text>
              </div>
            </div>

            <div className={wrapper}>
              <Text fontWeight={600} color='black'>
                결제 방식
              </Text>

              <div className={methodSelect.container}>
                {PAY_METHOD_SELECT_ITEMS.map(({ label, value }) => (
                  <OutlineButton
                    key={value}
                    variant='gray'
                    className={methodSelect.item({ active: method === value })}
                    onClick={() => methods.setValue('method', value)}
                  >
                    {label}
                  </OutlineButton>
                ))}
              </div>
            </div>

            {isCardLimitationVisible && <CardLimitationNote />}

            <CommonNote />
          </ModalBody>

          <Button type='submit' fullWidth className={submit} disabled={!methods.formState.isValid}>
            결제하기
          </Button>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default PointBillModal;
