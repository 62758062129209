import { sendEventLog } from 'functions/analytics/common';

import { SignUpStep } from 'types/auth';

export const sendSignUpEvent = (status: SignUpStep | 'success' | 'fail') => {
  sendEventLog('sign_up_process', {
    status,
  });
};

export const sendSignInEvent = () => {
  sendEventLog('login', {});
};
