import { useMutation } from 'react-query';

import { createUploadFileRequest } from 'apis/file';

export const useUploadFile = () =>
  useMutation(
    async (file: File) => {
      const { data } = await createUploadFileRequest(file);

      return `${import.meta.env.VITE_API_HOST_URL}/api/file/${data.fileOid}`;
    },
    {
      onError: () => {},
    },
  );
