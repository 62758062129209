import { atom } from 'recoil';

import { PollKind } from 'types/poll';

const initialState: {
  isVisible: boolean;
  title: string;
  message: string;
  type?: 'success' | 'error';
} = {
  isVisible: false,
  title: '',
  message: '',
};

export const messageModal = atom({
  key: 'message-modal',
  default: initialState,
});

export const languageModal = atom({
  key: 'language-modal',
  default: { isVisible: false },
});

export const createOrganizationModal = atom({
  key: 'create-organization-modal',
  default: { isVisible: false },
});

const pollActionModalInitialState: {
  isVisible: boolean;
  allowRealTimeResult?: boolean;
  kind: PollKind;
  type: 'remove' | 'cancel' | 'count' | 'pause' | 'restart' | 'early-exit';
  id: string;
} = {
  isVisible: false,
  allowRealTimeResult: false,
  kind: 'Election',
  type: 'remove',
  id: '',
};

export const pollActionModal = atom({
  key: 'poll-action-modal',
  default: pollActionModalInitialState,
});

export const cancelPointPurchaseModal = atom<{
  isVisible: boolean;
  chargeId: string;
  paymentOid: string;
  orderId?: string;
}>({
  key: 'cancel-point-modal',
  default: { isVisible: false, chargeId: '', paymentOid: '' },
});

export const incomingDetailModal = atom<{
  isVisible: boolean;
  chargeId: string;
  orderId?: string;
  paymentOid: string;
}>({
  key: 'incoming-detail-modal',
  default: { isVisible: false, chargeId: '', paymentOid: '' },
});

export const faceMessageModal = atom({
  key: 'face-message-modal',
  default: { isVisible: false, title: '', message: '', buttonText: '' },
});

export const pollShareModal = atom({
  key: 'poll-share-modal',
  default: { isVisible: false, pollName: '', pollId: '' },
});

export const userRemovalModal = atom({
  key: 'user-removal-modal',
  default: { isVisible: false },
});

export const promotionPointModal = atom({
  key: 'promotion-point-modal',
  default: { isVisible: false },
});
