import moment from 'moment-timezone';
import { Text } from '@pickme/design-system';
import { t } from 'i18next';

import { Draft } from 'types/draft';

import RemoveIcon from 'resources/icons/draft/remove.svg';

import { badge, container, description, removeButton } from './index.css';

type Props = {
  draft: Draft;
  onSelect: () => void;
  onRemoveButtonClick: () => void;
};

function DraftItem({ draft, onSelect, onRemoveButtonClick }: Props) {
  return (
    <div
      role='button'
      className={container}
      onClick={() => onSelect()}
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === 'Space') {
          event.preventDefault();
          onSelect();
        }
      }}
    >
      <div className={badge}>
        <Text size={14} fontWeight={500} color='gray-600'>
          {draft.content.type === 'Election'
            ? t('admin:terms.poll.kind.election')
            : t('admin:terms.poll.kind.survey')}
        </Text>
      </div>

      <div className={description}>
        <Text size={16} fontWeight={400} color='gray-800'>
          {draft.content.name}
        </Text>

        <Text size={14} fontWeight={400} color='gray-500'>
          {moment(draft.createdAt).format('YYYY년 MM월 DD일 (ddd) HH:mm')}
        </Text>
      </div>

      <button
        type='button'
        className={removeButton}
        onClick={(event) => {
          event.stopPropagation();
          onRemoveButtonClick();
        }}
      >
        <img src={RemoveIcon} alt='remove' />
      </button>
    </div>
  );
}

export default DraftItem;
