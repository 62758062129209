import { useEffect } from 'react';
import { flatten } from 'lodash-es';
import { Select } from '@pickme/design-system';

import { useGetSessionAsMember } from 'query-hooks/auth';
import { useGetAllManagers } from 'query-hooks/manager';

import { Manager } from 'types/manager';

import { trigger } from './index.css';

type Props = {
  onSelectManager: (manager: Manager) => void;
  onSelectManualInputItem?: () => void;
  isManualMode?: boolean;
  addManualInputItem?: boolean;
  selectedManager?: Manager;
};

function ManagerSelector({
  selectedManager,
  onSelectManager,
  isManualMode,
  addManualInputItem,
  onSelectManualInputItem,
}: Props) {
  const { data: memberData } = useGetSessionAsMember();
  const { data: managerData, fetchNextPage, hasNextPage, isFetchingNextPage } = useGetAllManagers();

  const admins = flatten(managerData?.pages?.map((group) => group.admins)).filter(
    (admin) => admin.id !== memberData?.id,
  );
  const defaultItems =
    admins?.length === 0
      ? [{ label: '선택할 대상이 없습니다', value: 'empty', disabled: true }]
      : admins.map((admin) => ({ label: `${admin.name}/${admin.email || '-'}`, value: admin._id }));
  const manualItems = addManualInputItem
    ? [
        {
          label: '새 유저를 초대할게요',
          value: 'new',
          onClick: () => {
            if (onSelectManualInputItem) {
              onSelectManualInputItem();
            }
          },
        },
      ]
    : [];
  const items = [...defaultItems, ...manualItems];

  const placeholder = isManualMode ? '새 유저를 초대할게요' : '매니저를 선택해 주세요';

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage]);

  return (
    <Select
      className={trigger}
      items={items}
      selected={selectedManager?._id}
      onSelect={(id) => {
        const selectedAdmin = admins.find((item) => item._id === id);

        if (!selectedAdmin) {
          return;
        }

        onSelectManager(selectedAdmin);
      }}
      placeholder={placeholder}
      width='fullWidth'
    />
  );
}

export default ManagerSelector;
