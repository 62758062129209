import application from './application';
import auth from './auth';
import banner from './banner';
import dashboard from './dashboard';
import home from './home';
import manager from './manager';
import modal from './modal';
import notification from './notification';
import payment from './payment';
import poll from './poll';
import myPage from './myPage';
import promotion from './promotion';
import search from './search';
import space from './space';
import template from './template';

export default {
  application,
  auth,
  banner,
  dashboard,
  home,
  manager,
  modal,
  notification,
  payment,
  poll,
  myPage,
  promotion,
  search,
  space,
  template,
};
