import payment from './payment';
import poll from './poll';
import pollResult from './pollResult';
import validator from './validator';

export default {
  payment,
  poll,
  pollResult,
  validator,
};
