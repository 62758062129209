import clsx from 'clsx';
import { ReactNode } from 'react';
import { Text } from '@pickme/design-system';

import { container } from './index.css';

type Props = {
  title: string;
  children: ReactNode;
  className?: string;
};

function NotificationSendCard({ title, children, className }: Props) {
  return (
    <div className={clsx(container, className)}>
      <Text size={18} fontWeight={600}>
        {title}
      </Text>

      {children}
    </div>
  );
}

export default NotificationSendCard;
