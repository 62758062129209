import { atom } from 'recoil';
import { PollKind } from '@pickme/core';

import { EMPTY_STRING } from 'constants/common';

import { PeriodGroup } from 'types/application-v2';

export type PollActionType =
  | 'start'
  | 'remove'
  | 'pause'
  | 'restart'
  | 'early-exit'
  | 'reservation'
  | 'reservation-cancel';

const pollActionsModalInitialState: {
  isVisible: boolean;
  pollId: string;
  type?: PollActionType;
  kind?: PollKind;
  periodGroups?: PeriodGroup[];
} = {
  isVisible: false,
  pollId: EMPTY_STRING,
};

export const pollActionsModalState = atom({
  key: 'poll-actions-modal',
  default: pollActionsModalInitialState,
});
