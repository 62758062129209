import { t } from 'i18next';

export const routesAfterSpaceSet = [
  { path: '/', main: t('admin:constants.nav.main.dashboard.title') },
  {
    path: '/poll/election',
    main: t('admin:constants.nav.main.election.title'),
    sub: t('admin:constants.nav.main.election.sub.dashboard'),
  },
  {
    path: '/poll/election/:id',
    main: t('admin:constants.nav.main.election.title'),
    sub: t('admin:constants.nav.main.election.sub.dashboard'),
  },
  {
    path: '/poll/survey',
    main: t('admin:constants.nav.main.survey.title'),
    sub: t('admin:constants.nav.main.survey.sub.dashboard'),
  },
  {
    path: '/poll/survey/:id',
    main: t('admin:constants.nav.main.survey.title'),
    sub: t('admin:constants.nav.main.survey.sub.dashboard'),
  },
  {
    path: '/service/notification/send/alimTalk',
    main: t('admin:constants.nav.main.notification.title'),
    sub: '메시지 보내기',
  },
  {
    path: '/service/notification/send/email',
    main: t('admin:constants.nav.main.notification.title'),
    sub: '메시지 보내기',
  },
  {
    path: '/service/notification/send/lms',
    main: t('admin:constants.nav.main.notification.title'),
    sub: '메시지 보내기',
  },
  {
    path: '/service/notification/history/sent',
    main: t('admin:constants.nav.main.notification.title'),
    sub: '전송 내역',
  },
  {
    path: '/service/notification/history/reserved',
    main: t('admin:constants.nav.main.notification.title'),
    sub: '전송 내역',
  },
  {
    path: '/settings/manager',
    main: t('admin:constants.nav.main.settings.title'),
    sub: t('admin:constants.nav.main.settings.sub.manager'),
  },
  {
    path: '/settings/resource',
    main: t('admin:constants.nav.main.settings.title'),
    sub: t('admin:constants.nav.main.settings.sub.resources'),
  },
  {
    path: '/settings/organization',
    main: t('admin:constants.nav.main.settings.title'),
    sub: t('admin:constants.nav.main.settings.sub.space'),
  },
  {
    path: '/settings/integration',
    main: t('admin:constants.nav.main.settings.title'),
    sub: t('admin:constants.nav.main.settings.sub.integration'),
  },
  {
    path: '/settings/integration/kakao/channel',
    main: t('admin:constants.nav.main.settings.title'),
    sub: t('admin:constants.nav.main.settings.sub.integration'),
  },
  {
    path: '/settings/payment/subscription',
    main: t('admin:constants.nav.main.settings.title'),
    sub: t('admin:constants.nav.main.settings.sub.payment'),
  },
  {
    path: '/settings/poll/history',
    main: t('admin:constants.nav.main.settings.title'),
    sub: t('admin:constants.nav.main.settings.sub.poll'),
  },
  {
    path: '/settings/poll/discard-data',
    main: t('admin:constants.nav.main.settings.title'),
    sub: t('admin:constants.nav.main.settings.sub.poll'),
  },
  {
    path: '/service/notification/point/incoming',
    main: t('admin:constants.nav.main.notification.title'),
    sub: t('admin:constants.nav.main.notification.sub.point'),
  },
  {
    path: '/service/notification/point/usage',
    main: t('admin:constants.nav.main.notification.title'),
    sub: t('admin:constants.nav.main.notification.sub.point'),
  },
  {
    path: '/feedback',
    main: t('admin:constants.nav.system.feedback.title'),
  },
];

export const routesBeforeSpaceSet = [{ path: '/accept', includeQueryString: true }];
