export const sendEventLog = (
  eventName: string,
  params: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams | undefined,
) => {
  if (!window.gtag) {
    return;
  }

  window.gtag('event', eventName, params);
};
