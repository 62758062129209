export default {
  detail: {
    title: '매니저 정보',
    tables: {
      header: {
        name: '이름',
        phoneNumber: '전화번호',
        email: '이메일',
        approvedAt: '합류 승인 날짜',
        join: '합류 여부',
        status: '상태',
      },
      body: {
        status: {
          pending: '합류대기',
          joined: '합류완료',
          approved: '활성화',
          disApproved: '비활성화',
        },
      },
    },
    button: {
      remove: '내보내기',
      active: '활성화하기',
      inactive: '비활성화하기',
      reject: '거절하기',
      approve: '승인하기',
      close: '닫기',
    },
    messages: {
      disallowedFeature: '이 플랜에서 매니저를 활성화할 수 없습니다',
    },
  },
  confirm: {
    title: {
      reject: '{{name}}님을 정말 거절하시겠어요?',
      remove: '{{name}}님을 정말 내보내시겠어요?',
    },
    description: {
      reject: '매니저 합류 요청을 거절하면 해당 스페이스에 접근이 불가능합니다.',
      remove: '매니저를 내보내면 더 이상 해당 스페이스에 접근이 불가능합니다.',
    },
    actions: {
      cancel: '취소',
      reject: '거절하기',
      remove: '내보내기',
    },
  },
};
