import { matchRoutes, useLocation } from 'react-router-dom';

import { routesAfterSpaceSet } from 'constants/routes';
import { routesAfterSpaceSet as routesAfterSpaceSetV2 } from 'constants/routes-v2';

const routesSet = import.meta.env.VITE_USE_V2 ? routesAfterSpaceSetV2 : routesAfterSpaceSet;

export function useSidebar() {
  const location = useLocation();
  const data = matchRoutes(routesSet, location)?.[0].route;

  return data;
}
