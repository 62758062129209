import { ReactNode } from 'react';

import { container } from './index.css';

type Props = {
  children: ReactNode;
};

function SubscriptionCard({ children }: Props) {
  return <div className={container}>{children}</div>;
}

export default SubscriptionCard;
