import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Text } from '@pickme/design-system';

import ProgressBar from 'components/common/ProgressBar';
import EmailForm from 'components/features/auth/form/signup/Email';
import UserInfoForm from 'components/features/auth/form/signup/UserInfo';
import OTPVerificationForm from 'components/features/auth/form/signup/OTP';
import SignUpComplete from 'components/features/auth/form/signup/Complete';

import { useGetSessionAsUser, useCredentialSignUp } from 'query-hooks/auth';

import { sendSignUpEvent } from 'functions/analytics/auth';
import { conditionalTransitionCallback } from 'functions/dom';

import { SignUpRequestBody, SignUpStep } from 'types/auth';

import { container, stepStyle, title } from 'pages/auth/common.css';

function CredentialSignUp() {
  const signUpFormRef = useRef<HTMLFormElement>(null);
  const [step, setStep] = useState<SignUpStep>(SignUpStep.Email);

  const navigate = useNavigate();
  const { data: userSessionData } = useGetSessionAsUser({ suspense: true });
  const { mutate, isLoading, isSuccess } = useCredentialSignUp();

  const signUpForm = useForm<SignUpRequestBody>({
    mode: 'onTouched',
    defaultValues: {
      email: '',
      name: '',
      phoneNumber: '',
      password: '',
      token: '',
      mailing: false,
    },
  });

  const onSubmit = (data: SignUpRequestBody) => {
    if (isLoading) {
      return;
    }

    mutate(data);
  };

  const signUpSteps = Object.values(SignUpStep);
  const currentStepIndex = signUpSteps.findIndex((signUpStep) => signUpStep === step) + 1;

  useEffect(() => {
    if (userSessionData) {
      navigate('/pickme', { replace: true });
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setStep(SignUpStep.Complete);
    }
  }, [isSuccess]);

  return (
    <div className={container}>
      <ProgressBar total={signUpSteps.length} now={currentStepIndex} />

      <Text size={16} color='primary-500' className={stepStyle}>
        Step {currentStepIndex}.
      </Text>

      <Text size={30} fontWeight={500} color='black' className={title}>
        {SIGNUP_STEP_TITLES[step]}
      </Text>

      {step === SignUpStep.Email && (
        <EmailForm
          onEmailSend={(email) => {
            conditionalTransitionCallback(() => {
              sendSignUpEvent(SignUpStep.Email);
              setStep(SignUpStep.Otp);
              signUpForm.setValue('email', email);
            });
          }}
        />
      )}

      {step === SignUpStep.Otp && (
        <OTPVerificationForm
          email={signUpForm.watch('email')}
          onOTPVerify={(token) => {
            conditionalTransitionCallback(() => {
              sendSignUpEvent(SignUpStep.Otp);
              setStep(SignUpStep.Userinfo);
              signUpForm.setValue('token', token);
            });
          }}
        />
      )}

      {step === SignUpStep.Userinfo && (
        <UserInfoForm
          isLoading={isLoading}
          onSubmit={(data, event) => {
            conditionalTransitionCallback(() => {
              sendSignUpEvent(SignUpStep.Userinfo);
              event?.preventDefault();

              signUpForm.setValue('name', data.name.trim());
              signUpForm.setValue('phoneNumber', data.phoneNumber);
              signUpForm.setValue('password', data.password);
              signUpForm.setValue('mailing', data.mailing);

              signUpFormRef.current?.requestSubmit();
            });
          }}
        />
      )}

      {step === SignUpStep.Complete && (
        <SignUpComplete name={signUpForm.watch('name')} email={signUpForm.watch('email')} />
      )}

      <FormProvider {...signUpForm}>
        <form onSubmit={signUpForm.handleSubmit(onSubmit)} ref={signUpFormRef}>
          <input hidden {...signUpForm.register('email')} />
          <input hidden {...signUpForm.register('name')} />
          <input hidden {...signUpForm.register('phoneNumber')} />
          <input hidden type='password' {...signUpForm.register('password')} />
          <input hidden {...signUpForm.register('mailing')} />
          <input hidden {...signUpForm.register('token')} />
        </form>
      </FormProvider>
    </div>
  );
}

export default CredentialSignUp;

const SIGNUP_STEP_TITLES = {
  [SignUpStep.Email]: '로그인에 사용할 \n 이메일을 입력해 주세요',
  [SignUpStep.Otp]: '인증번호를 발송했어요 \n 받으신 인증번호를 입력해 주세요',
  [SignUpStep.Userinfo]: '기본 정보를 입력해 주세요',
  [SignUpStep.Complete]: '회원가입이 완료되었어요!',
};
