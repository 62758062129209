import { t } from 'i18next';
import { Spinner } from '@pickme/ui';
import { Text, Button, Input } from '@pickme/design-system';
import { FormProvider, useForm } from 'react-hook-form';

import { useSendResetPasswordEmail } from 'query-hooks/auth';

import { emailValidator } from 'functions/validator/auth';

import { SendResetMailBody } from 'types/auth';

import { submitButton, title } from 'pages/auth/common.css';
import { input } from './index.css';

function SendResetEmailForm() {
  const methods = useForm<SendResetMailBody>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
  } = methods;

  const { isLoading, mutate } = useSendResetPasswordEmail();

  const onSubmit = (formData: SendResetMailBody) => {
    mutate(formData.email);
  };

  const submitButtonText = isSubmitted ? '다시 보내기' : '재설정하기';

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text size={30} fontWeight={600} className={title}>
          {t('admin:components.features.auth.form.sendResetMail.title')}
        </Text>

        <Input
          className={input}
          width='100%'
          size='lg'
          placeholder='이메일을 입력해주세요'
          type='email'
          id='email'
          {...register('email', {
            validate: emailValidator,
            required: true,
            setValueAs: (value) => value?.trim().toLowerCase(),
          })}
          isValid={!errors.email}
          errorMessage={
            errors.email
              ? t('admin:components.features.auth.form.sendResetMail.errorMessages.wrongEmail')
              : ''
          }
        />

        <Button
          fullWidth
          size='xl'
          variant='primary'
          type='submit'
          disabled={!isValid || isLoading}
          className={submitButton}
        >
          {isLoading ? <Spinner /> : submitButtonText}
        </Button>
      </form>
    </FormProvider>
  );
}

export default SendResetEmailForm;
