import { NavLink, useNavigate } from 'react-router-dom';

import { useSidebar } from 'hooks/useSidebar';

import ArrowRightIcon from 'resources/icons/nav/menu/arrow_right.svg';
import ActiveArrowRightIcon from 'resources/icons/nav/menu/arrow_right_active.svg';

import { arrow, container, iconStyle, item, textStyle } from './index.css';

type Props = {
  title: string;
  icon?: {
    normal: string;
    hover: string;
  };
  link?: string;
  alternativeUrl?: string;
  onToggle?: (toggled: boolean) => void;
  expandable: boolean;
  expanded: boolean;
  linkType: 'internal' | 'external';
};

function MainMenu({
  title,
  icon,
  link,
  alternativeUrl,
  expandable,
  expanded,
  onToggle,
  linkType,
}: Props) {
  const navigate = useNavigate();
  const sidebar = useSidebar();

  const isActive = sidebar?.path === link;
  const isColoredIconVisible = isActive;
  const isColoredBackgroundVisible = isActive;

  return (
    <div className={container}>
      <NavLink
        unstable_viewTransition
        end={!expandable}
        to={link ?? ''}
        className={item({ active: isColoredBackgroundVisible })}
        onClick={(event) => {
          if (!onToggle) {
            return;
          }

          if (linkType === 'external') {
            event.preventDefault();
            window.open(link, '_blank');
            return;
          }

          if (expandable) {
            event.preventDefault();

            if (alternativeUrl) {
              navigate(alternativeUrl);
            }

            onToggle(!expanded);
            return;
          }

          onToggle(false);
        }}
      >
        <img
          className={iconStyle}
          src={isColoredIconVisible ? icon?.hover : icon?.normal}
          alt={title}
        />

        <div className={textStyle}>{title}</div>

        {expandable && (
          <img
            className={arrow({ rotate: expanded })}
            src={isColoredIconVisible ? ActiveArrowRightIcon : ArrowRightIcon}
            alt='icon'
          />
        )}
      </NavLink>
    </div>
  );
}

export default MainMenu;
