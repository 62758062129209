import { Button, OutlineButton, Text } from '@pickme/design-system';
import { Modal } from '@pickme/ui';

import SocialIconButton from 'components/features/auth/SocialIconButton';

import { useSocialDisconnect } from 'query-hooks/auth';

import { SOCIAL_PROVIDER_LABELS } from 'constants/auth';

import { SocialProvider } from 'types/auth';

import { modalButtons } from 'styles/page.css';
import { title } from './index.css';

type Props = {
  provider: SocialProvider;
  isVisible: boolean;
  onClose: () => void;
};

function SocialDisconnectModal({ provider, isVisible, onClose }: Props) {
  const socialDisconnect = useSocialDisconnect();

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <SocialIconButton provider={provider} disabled />
      
      <Text size={20} fontWeight={600} className={title}>
        {SOCIAL_PROVIDER_LABELS[provider]} 계정 연결을 해제하시겠어요?
      </Text>

      <div className={modalButtons.container}>
        <OutlineButton variant='gray' className={modalButtons.cancel} onClick={onClose}>
          취소
        </OutlineButton>
        <Button
          className={modalButtons.submit}
          onClick={() => {
            socialDisconnect.mutate(provider);
            onClose();
          }}
        >
          예
        </Button>
      </div>
    </Modal>
  );
}

export default SocialDisconnectModal;
