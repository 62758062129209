import { useRecoilState } from 'recoil';
import { Modal, ModalBody } from '@pickme/ui';
import { Button, OutlineButton, Text } from '@pickme/design-system';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

import {
  useCancelPoll,
  useOrderResult,
  usePausePoll,
  useRemovePoll,
  useRestartPoll,
  useEarlyExitPoll,
} from 'query-hooks/poll-actions';

import { pollActionModal } from 'states/modal';

import { PollKind } from 'types/poll';

import { modalButtons } from 'styles/page.css';
import { submitButton } from './index.css';

export type PollActionType = 'remove' | 'cancel' | 'count' | 'pause' | 'restart' | 'early-exit';

function PollActionModal() {
  const [{ isVisible, id, type, kind, allowRealTimeResult }, setModalVisible] =
    useRecoilState(pollActionModal);
  const { isLoading: isRemoveLoading, mutate: mutateRemovePoll } = useRemovePoll();
  const { isLoading: isCancelLoading, mutate: mutateCancelPoll } = useCancelPoll();
  const { isLoading: isOrderLoading, mutate: mutateOrderResult } = useOrderResult();
  const { isLoading: isRestartPollLoading, mutate: mutateRestartPoll } = useRestartPoll();
  const { isLoading: isPausePollLoading, mutate: mutatePausePoll } = usePausePoll();
  const { isLoading: isEarlyExitPollLoading, mutate: mutateEarlyExitPoll } = useEarlyExitPoll();

  const isLoading =
    isRemoveLoading ||
    isCancelLoading ||
    isOrderLoading ||
    isRestartPollLoading ||
    isPausePollLoading ||
    isEarlyExitPollLoading;

  const onClose = () => {
    setModalVisible({
      type,
      kind,
      isVisible: false,
      allowRealTimeResult: false,
      id: '',
    });
  };

  const onSubmit = () => {
    if (isLoading) {
      return;
    }

    if (type === 'cancel') {
      mutateCancelPoll(
        { kind, id },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    } else if (type === 'count') {
      mutateOrderResult(
        { kind, id },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    } else if (type === 'pause') {
      mutatePausePoll(
        { kind, id },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    } else if (type === 'restart') {
      mutateRestartPoll(
        { kind, id },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    } else if (type === 'remove') {
      mutateRemovePoll(
        { kind, id },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    } else if (type === 'early-exit') {
      mutateEarlyExitPoll(
        {
          kind,
          id,
        },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    }
  };

  const submitText = setSubmitText(type, allowRealTimeResult);
  const submitVariant = setSubmitVariant(type);

  return (
    <Modal isVisible={isVisible} onClose={onClose} size='md'>
      <Text size={20} fontWeight={600} color='black'>
        {setModalTitle(type)}
      </Text>

      <ModalBody>
        <Text size={16} fontWeight={400} color='gray-700'>
          {setModalMessage(type, kind)}
        </Text>
      </ModalBody>

      <div className={modalButtons.container}>
        <OutlineButton variant='gray' onClick={onClose} className={modalButtons.cancel}>
          닫기
        </OutlineButton>
        <Button
          variant={submitVariant}
          disabled={isLoading}
          onClick={onSubmit}
          className={submitVariant === 'primary' ? modalButtons.submit : submitButton}
        >
          {isLoading ? <Spinner /> : submitText}
        </Button>
      </div>
    </Modal>
  );
}

export default PollActionModal;

function setSubmitText(type: PollActionType, allowRealTimeResult?: boolean) {
  if (allowRealTimeResult) {
    return t('admin:components.features.modal.pollAction.buttons.earlyCount');
  }

  switch (type) {
    case 'cancel':
      return '무효처리';
    case 'count':
      return '개표하기';
    case 'pause':
      return '일시중지';
    case 'restart':
      return '재시작';
    case 'remove':
      return '삭제하기';
    case 'early-exit':
      return '종료하기';
    default:
      return '';
  }
}

function setSubmitVariant(type: PollActionType) {
  if (type === 'count') return 'primary';
  return 'danger';
}

function setModalTitle(type: PollActionType) {
  switch (type) {
    case 'cancel':
      return '정말 무효 처리하시겠어요?';
    case 'count':
      return '개표하시겠어요?';
    case 'pause':
      return '일시 중지 하시겠어요?';
    case 'restart':
      return '재시작 하시겠어요?';
    case 'remove':
      return '정말 삭제하시겠어요?';
    case 'early-exit':
      return '강제 종료하시겠어요?';
    default:
      return '';
  }
}

function setModalMessage(type: PollActionType, kind: PollKind) {
  switch (type) {
    case 'cancel':
      return '무효 처리하면 결과를 확인할 수 없어요. 그래도 무효처리하시겠어요?';
    case 'count':
      return '개표가 완료되면 투표에 더 이상 참여할 수 없고, 결과를 확인할 수 있어요. 정말 개표하시겠어요?';
    case 'pause':
      return '일시 중지 기간 동안 참여할 수 없어요. 일시 중지 후 다시 재시작할 수 있습니다.';
    case 'restart':
      return kind === 'Election'
        ? '투표를 재시작 하시면 참여자가 투표에 참여할 수 있어요.'
        : '설문을 재시작 하시면 참여자가 설문에 참여할 수 있어요.';
    case 'remove':
      return '삭제된 내용은 되돌릴 수 없어요. 정말 삭제하시겠어요?';
    case 'early-exit':
      return '강제 종료하면 다시 시작할 수 없어요. 그래도 종료하시겠어요?';
    default:
      return '';
  }
}
