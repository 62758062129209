import { Text } from '@pickme/design-system';

import DefaultChannel from 'resources/icons/nav/space_placeholder.svg';

import { channel, channelLogo as channelLogoStyle } from './index.css';

type Props = {
  channelName: string;
  channelLogo?: string;
};

function ChannelInfo({ channelName, channelLogo }: Props) {
  const channelLogoSrc = channelLogo || DefaultChannel;

  return (
    <div className={channel.container}>
      <div className={channel.info}>
        <div className={channelLogoStyle.container}>
          <img src={channelLogoSrc} alt='channel log' className={channelLogoStyle.image} />
        </div>

        <Text size={32} fontWeight={600} color='black'>
          {channelName}
        </Text>
      </div>

      {/* TODO: 채널 기능이 추가되면 채널 바로가기 버튼을 추가해야 합니다. */}
    </div>
  );
}

export default ChannelInfo;
