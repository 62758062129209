import { isUndefined, find } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { Button, Dropdown, Switch, Text } from '@pickme/ui';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { t } from 'i18next';

import FieldTitle from 'components/features/application/label/old_FieldTitle';
import PlainTextArea from 'components/common/input/PlainTextArea';
import FieldTitleWithButton from 'components/features/application/label/FieldTitleWithButton';

import ApplicationLayout from 'components/common/layout/Application';

import CandidateForm from 'components/features/application/form/SubElections/SubElection/Candidate';
import CandidateCard from 'components/features/application/card/Candidate';
import KindValueDropdownField from 'components/features/application/field/KindValueDropdownField';
import KindValueInputField from 'components/features/application/field/KindValueInputField';

import { usePollStarted } from 'hooks/poll/usePollData';

import { toast } from 'states/toast';

import ChoiceIcon from 'resources/icons/application/kind/choice.png';
import YesNoIcon from 'resources/icons/application/kind/yesno.png';
import RankIcon from 'resources/icons/application/kind/rank.png';
import PointIcon from 'resources/icons/application/kind/point.png';
import DownArrowIcon from 'resources/icons/application/kind/down.png';

import { SubElectionFormBody, ElectionKind, CandidateFormBody } from 'types/application';

import './index.scss';

type Props = {
  initialValue?: SubElectionFormBody;
  onElectionSubmit: (body: SubElectionFormBody) => void;
  onClose: () => void;
};

const KINDS: { type: ElectionKind; title: string; icon: string }[] = [
  {
    type: 'ChoiceElection',
    title: t(
      'admin:components.features.application.form.subElection.form.subElection.labels.kind.choice',
    ),
    icon: ChoiceIcon,
  },
  {
    type: 'ProsOrConsElection',
    title: t(
      'admin:components.features.application.form.subElection.form.subElection.labels.kind.prosOrCons',
    ),
    icon: YesNoIcon,
  },
  {
    type: 'RankElection',
    title: t(
      'admin:components.features.application.form.subElection.form.subElection.labels.kind.rank',
    ),
    icon: RankIcon,
  },
  {
    type: 'ScoreElection',
    title: t(
      'admin:components.features.application.form.subElection.form.subElection.labels.kind.score',
    ),
    icon: PointIcon,
  },
];

function SubElectionForm({ onClose, initialValue, onElectionSubmit }: Props) {
  const setToast = useSetRecoilState(toast);
  const [candidateModal, setCandidateModal] = useState<{
    isVisible: boolean;
    selectedIndex?: number;
    candidate?: CandidateFormBody;
  }>({ isVisible: false });

  const isPollStarted = usePollStarted();

  const methods = useFormContext<SubElectionFormBody>();

  const { append, fields, swap, remove, update } = useFieldArray<SubElectionFormBody, 'candidates'>(
    {
      control: methods.control,
      name: 'candidates',
      keyName: 'id',
      rules: {
        validate: (candidates) => {
          if (candidates.length === 0) {
            return t(
              'admin:components.features.application.form.subElection.errorMessages.candidates.required',
            );
          }

          return true;
        },
      },
    },
  );

  const onSubmit: SubmitHandler<SubElectionFormBody> = (data) => {
    onElectionSubmit(data);
    onClose();
  };

  const onError: SubmitErrorHandler<SubElectionFormBody> = (data) => {
    const message =
      data.candidates?.root?.message ||
      data.responseValidation?.minResponse?.message ||
      data.responseValidation?.maxResponse?.message ||
      data.responseValidation?.rankLimit?.message ||
      data.responseValidation?.maxScore?.message ||
      data.responseValidation?.minScore?.message;
    if (message) {
      setToast({
        isVisible: true,
        type: 'error',
        message,
      });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 8,
        distance: 8,
      },
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const selectedIndex = active?.data?.current?.sortable.index;
      const changedIndex = over?.data?.current?.sortable.index;

      swap(selectedIndex, changedIndex);
    }
  };

  const selectedKindValue = methods.watch('kind');
  const selectedKind = find(KINDS, { type: selectedKindValue });

  useEffect(() => {
    const allowAbstentionVote = methods.getValues('responseValidation.allowAbstentionVote');
    if (selectedKindValue === 'ChoiceElection') {
      methods.setValue('responseValidation', {
        allowAbstentionVote,
        minResponse: 1,
        maxResponse: 1,
      });
    } else if (selectedKindValue === 'ProsOrConsElection') {
      methods.setValue('responseValidation', {
        allowAbstentionVote,
      });
    } else if (selectedKindValue === 'RankElection') {
      methods.setValue('responseValidation', {
        allowAbstentionVote,
        rankLimit: 1,
      });
    } else if (selectedKindValue === 'ScoreElection') {
      methods.setValue('responseValidation', {
        allowAbstentionVote,
        minScore: 1,
        maxScore: 100,
      });
    }
  }, [selectedKindValue]);

  const maxResponse = methods.watch(`responseValidation.maxResponse`);
  useEffect(() => {
    if (selectedKindValue === 'ChoiceElection' && maxResponse) {
      methods.setValue('responseValidation.minResponse', maxResponse);
    }
  }, [selectedKindValue, maxResponse]);

  useEffect(() => {
    if (initialValue) {
      methods.reset(initialValue);
    }
  }, [initialValue]);

  const candidatesCount = fields.length;

  return (
    <div className='application-form--fullscreen'>
      <ApplicationLayout
        title={t('admin:components.features.application.form.subElection.form.subElection.title')}
        hideStepNavbar
        onClose={() => onClose()}
        onSelect={() => {}}
        items={[]}
      >
        <FormProvider {...methods}>
          <form
            className='application-form election-form'
            noValidate
            onSubmit={methods.handleSubmit(onSubmit, onError)}
          >
            <div className='election-form__kind-dropdown'>
              <Dropdown
                button={
                  <div className='election-form__kind-dropdown__button'>
                    <img src={selectedKind?.icon} alt='kind' />
                    <Text type='b3' fontWeight={600}>
                      {selectedKind?.title}
                    </Text>
                    <img src={DownArrowIcon} alt='arrow' />
                  </div>
                }
                items={KINDS.map(({ title, icon, type }) => ({
                  text: title,
                  type: 'button',
                  icon,
                  onClick: () => methods.setValue('kind', type),
                }))}
                direction='down'
                directionBase='left'
                disabled={isPollStarted}
              />
            </div>

            <div className='election-form__title'>
              <PlainTextArea
                placeholder={t(
                  'admin:components.features.application.form.subElection.form.subElection.fields.name.placeholder',
                )}
                isValid={!methods.formState.errors.title}
                maxLength={150}
                {...methods.register('title', {
                  required: true,
                  validate: (value) => value.length > 0,
                })}
              />
            </div>

            <FieldTitleWithButton
              title={t(
                'admin:components.features.application.form.subElection.form.subElection.fields.candidates.label',
              )}
              description={t(
                'admin:components.features.application.form.subElection.form.subElection.fields.candidates.description',
              )}
              button={{
                text: t(
                  'admin:components.features.application.form.subElection.buttons.addCandidate',
                ),
                disabled: isPollStarted,
                onClick: () =>
                  setCandidateModal({
                    isVisible: true,
                  }),
              }}
            />

            <div className='election-form__candidates'>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={fields.map((field) => field.id)}
                  disabled={isPollStarted}
                  strategy={verticalListSortingStrategy}
                >
                  {fields.map((field, index) => (
                    <CandidateCard
                      key={field.id}
                      id={field.id}
                      title={field.title}
                      members={field.members}
                      tags={field.tags}
                      disabled={isPollStarted}
                      onClick={() =>
                        setCandidateModal({
                          isVisible: true,
                          selectedIndex: index,
                          candidate: field,
                        })
                      }
                      onRemove={() => remove(index)}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            </div>

            <hr />

            {selectedKindValue === 'ChoiceElection' && (
              <>
                <input hidden {...methods.register(`responseValidation.minResponse`)} />
                <KindValueDropdownField
                  title={t(
                    'admin:components.features.application.form.subElection.form.subElection.fields.maxResponse.label',
                  )}
                  unit={t('admin:terms.unit.people')}
                  disabled={isPollStarted}
                  fields={[
                    {
                      title: '',
                      fieldName: 'responseValidation.maxResponse',
                      min: 1,
                      max: candidatesCount || 1,
                      validate: (value) => {
                        if (value <= 0) {
                          return t(
                            'admin:components.features.application.form.subElection.errorMessages.response.min',
                          );
                        }

                        const min = methods.getValues('responseValidation.minResponse');
                        if (!min) {
                          return true;
                        }

                        if (min <= value) {
                          return true;
                        }

                        return t(
                          'admin:components.features.application.form.subElection.errorMessages.response.max',
                        );
                      },
                    },
                  ]}
                />
              </>
            )}

            {selectedKindValue === 'RankElection' && (
              <KindValueDropdownField
                title={t(
                  'admin:components.features.application.form.subElection.form.subElection.fields.rank.label',
                )}
                unit={t('admin:terms.unit.rank')}
                disabled={isPollStarted}
                fields={[
                  {
                    title: t(
                      'admin:components.features.application.form.subElection.form.subElection.labels.min',
                    ),
                    fieldName: 'responseValidation.rankLimit',
                    min: 1,
                    max: candidatesCount || 1,
                    validate: (value) => {
                      if (value <= 0) {
                        t(
                          'admin:components.features.application.form.subElection.errorMessages.response.max',
                        );
                        return t(
                          'admin:components.features.application.form.subElection.errorMessages.rank.min',
                        );
                      }

                      if (value > candidatesCount) {
                        return t(
                          'admin:components.features.application.form.subElection.errorMessages.rank.max',
                        );
                      }

                      return true;
                    },
                  },
                ]}
              />
            )}

            {selectedKindValue === 'ScoreElection' && (
              <KindValueInputField
                title={t(
                  'admin:components.features.application.form.subElection.form.subElection.fields.score.label',
                )}
                unit={t('admin:terms.unit.score')}
                disabled={isPollStarted}
                fields={[
                  {
                    title: t(
                      'admin:components.features.application.form.subElection.form.subElection.labels.min',
                    ),
                    fieldName: 'responseValidation.minScore',
                    min: 1,
                    max: 100,
                    validate: (value) => {
                      if (value <= 0) {
                        return t(
                          'admin:components.features.application.form.subElection.errorMessages.minScore.min',
                        );
                      }

                      if (value > 100) {
                        return t(
                          'admin:components.features.application.form.subElection.errorMessages.minScore.max',
                        );
                      }

                      const max = methods.getValues('responseValidation.maxScore') || 1;
                      if (value >= max) {
                        return t(
                          'admin:components.features.application.form.subElection.errorMessages.minScore.compareWithMaxScore',
                        );
                      }

                      return true;
                    },
                  },
                  {
                    title: t(
                      'admin:components.features.application.form.subElection.form.subElection.labels.max',
                    ),
                    fieldName: 'responseValidation.maxScore',
                    min: 1,
                    max: 100,
                    validate: (value) => {
                      if (value <= 0) {
                        return t(
                          'admin:components.features.application.form.subElection.errorMessages.maxScore.min',
                        );
                      }

                      if (value > 100) {
                        return t(
                          'admin:components.features.application.form.subElection.errorMessages.maxScore.max',
                        );
                      }

                      const min = methods.getValues('responseValidation.minScore') || 1;
                      if (min >= value) {
                        return t(
                          'admin:components.features.application.form.subElection.errorMessages.maxScore.compareWithMinScore',
                        );
                      }

                      return true;
                    },
                  },
                ]}
              />
            )}

            <div className='application-form__row application-form__row--justify'>
              <FieldTitle
                title={t(
                  'admin:components.features.application.form.subElection.form.subElection.fields.allowAbstentionVote.label',
                )}
                tooltip={t(
                  'admin:components.features.application.form.subElection.form.subElection.fields.allowAbstentionVote.tooltip',
                )}
              />

              <Controller
                control={methods.control}
                name='responseValidation.allowAbstentionVote'
                render={({ field }) => (
                  <Switch
                    id={field.name}
                    ref={field.ref}
                    disabled={isPollStarted}
                    onChange={(event) => {
                      if (event.target.checked) {
                        field.onChange(true);
                      } else {
                        field.onChange(false);
                      }
                    }}
                    checked={field.value === true}
                  />
                )}
              />
            </div>

            <div className='application-form__row application-form__row--justify'>
              <FieldTitle
                title={t(
                  'admin:components.features.application.form.subElection.form.subElection.fields.shuffle.label',
                )}
                tooltip={t(
                  'admin:components.features.application.form.subElection.form.subElection.fields.shuffle.tooltip',
                )}
              />

              <Controller
                control={methods.control}
                name='shuffle'
                render={({ field }) => (
                  <Switch
                    id={field.name}
                    ref={field.ref}
                    disabled={isPollStarted}
                    onChange={(event) => {
                      if (event.target.checked) {
                        field.onChange(true);
                      } else {
                        field.onChange(false);
                      }
                    }}
                    checked={field.value === true}
                  />
                )}
              />
            </div>

            <Button width='100%' size='lg' variant='primary' type='submit'>
              {t(
                'admin:components.features.application.form.subElection.form.subElection.submitButtonText',
              )}
            </Button>
          </form>
        </FormProvider>

        <CandidateForm
          isVisible={candidateModal.isVisible}
          initialValue={candidateModal.candidate}
          onClose={() => {
            setCandidateModal({
              isVisible: false,
            });
          }}
          onSubmitCandidate={(candidate) => {
            if (isUndefined(candidateModal?.selectedIndex)) {
              append(candidate);
            } else {
              update(candidateModal.selectedIndex, candidate);
            }

            setCandidateModal({
              isVisible: false,
            });
          }}
        />
      </ApplicationLayout>
    </div>
  );
}

export default SubElectionForm;
