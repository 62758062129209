export default {
  title: '스페이스 관리',
  errorMessages: {
    upgrade: {
      premium: '프리미엄 플랜으로 업그레이드가 필요합니다.',
      standard: '스탠다드 플랜으로 업그레이드가 필요합니다.',
    },
    space: {
      name: '스페이스 이름을 작성해주세요',
      url: '영문, 숫자를 조합하여 2자 이상 10자 이하로 입력해주세요',
    },
  },
  form: {
    spaceName: {
      title: '스페이스 이름',
      fields: {
        name: {
          placeholder: `스페이스명은 {{length}}자 이하로 작성해주세요`,
        },
      },
      submitButtonText: '저장하기',
    },
    spaceLogo: {
      title: '스페이스 로고',
      tooltip:
        '사용자에게 보여지는 로고 이미지를 등록해주세요. 로고 이미지는 1MB 이하의 파일을 권장합니다.',
      fields: {
        image: {
          dropdown: {
            upload: '등록하기',
            remove: '삭제하기',
            edit: '수정하기',
          },
        },
      },
      submitButtonText: '저장하기',
    },
    spaceURL: {
      title: '투표 참여 URL',
      fields: {
        url: {
          prefix: 'https://vote.ohpick.me/',
          placeholder: '투표 참여 URL을 작성해주세요',
        },
      },
      messages: {
        success: '성공적으로 링크가 복사되었습니다.',
        failure: '링크 복사에 실패했습니다.',
      },
      copyButtonText: 'URL 복사',
      submitButtonText: '저장하기',
    },
  },
};
