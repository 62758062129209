import { useInfiniteQuery, useMutation } from 'react-query';

import {
  createGetFeaturedImagesRequest,
  createGetImageDownloadRequest,
  createGetSearchImagesRequest,
  createGetTopicImagesRequest,
} from 'apis/image';

import { LAST_PAGE_OF_UNSPLASH_IMAGES } from 'constants/image';

export const useGetUnsplashImages = ({ keyword, id }: { keyword: string; id: string | null }) =>
  useInfiniteQuery(
    ['unsplash-image', { keyword, id }],
    async ({ pageParam = 1 }) => {
      if (!keyword && !id) {
        const { data } = await createGetFeaturedImagesRequest({ page: pageParam });
        return { images: data, page: pageParam };
      }

      if (id) {
        const { data } = await createGetTopicImagesRequest({ id, page: pageParam });
        return { images: data, page: pageParam };
      }

      const { data } = await createGetSearchImagesRequest({ page: pageParam, keyword });
      return { images: data.results, page: pageParam };
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage.images || lastPage.images.length === 0) {
          return undefined;
        }

        if (lastPage.page >= LAST_PAGE_OF_UNSPLASH_IMAGES) {
          return undefined;
        }

        return lastPage.page + 1;
      },
    },
  );

export const useDownloadUnsplashImage = () =>
  useMutation(async ({ id }: { id: string }) => {
    const { data } = await createGetImageDownloadRequest({ id });

    return data;
  });
