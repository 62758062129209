import { useGetChannel } from 'query-hooks/channel';

import { useGetFeatures } from 'hooks/useGetFeatures';
import { IntegrationStatus } from 'types/integration';

export function useKakaoChannel() {
  const { data: channel } = useGetChannel();
  const { enableToUseNotificationChannel } = useGetFeatures();

  if (channel?.status === 'approved') {
    return IntegrationStatus.Complete;
  }

  if (channel?.status === 'pending') {
    return IntegrationStatus.Pending;
  }

  if (!enableToUseNotificationChannel) {
    return IntegrationStatus.Disabled;
  }

  return IntegrationStatus.Enabled;
}
