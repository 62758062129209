import { AxiosError } from 'axios';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useQueryClient, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

import {
  createReplaceUserNameRequest,
  createReplaceUserEmailRequest,
  createReplaceUserPhoneNumberRequest,
  createReplaceUserPasswordRequest,
  createToggleMarketingAgreement,
} from 'apis/user';
import { createVerifyEmail, createVerifySMS } from 'apis/otp';
import { createDeleteAccountRequest } from 'apis/user-v2';

import { toast } from 'states/toast';
import { messageModal, promotionPointModal } from 'states/modal';
import { spaceState } from 'states/space';

import { passwordValidator } from 'functions/validator/auth';

import { ValidationError } from 'utils/error';

import { UserRemoveRequestBody } from 'types/auth';

export const useReplaceUserName = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ name }: { name: string }) => {
      const { data } = await createReplaceUserNameRequest(name);

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('auth');
        setToast({
          isVisible: true,
          type: 'success',
          message: '이름이 성공적으로 변경되었습니다.',
        });
      },
    },
  );
};

export const useReplaceUserEmail = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ email, otp }: { email: string; otp: string; type: 'register' | 'replace' }) => {
      const {
        data: { token },
      } = await createVerifyEmail(email, otp);

      const { data } = await createReplaceUserEmailRequest(token || '');

      return data;
    },
    {
      onSuccess: (_, { type }) => {
        queryClient.invalidateQueries('auth');

        if (type === 'replace') {
          setToast({
            isVisible: true,
            type: 'success',
            message: '계정이 성공적으로 변경되었습니다.',
          });
        } else {
          setToast({
            isVisible: true,
            type: 'success',
            message: t('admin:query-hooks.user.useReplaceUserEmail.onSuccess.toast.register'),
          });
        }
      },
      onError: (error: AxiosError) => {
        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });
      },
    },
  );
};

export const useReplaceUserPhoneNumber = () => {
  const queryClient = useQueryClient();

  const activeSpaceId = useRecoilValue(spaceState.activeSpaceId);
  const setToast = useSetRecoilState(toast);
  const setSuccessModal = useSetRecoilState(promotionPointModal);

  return useMutation(
    async ({
      otp,
      phoneNumber,
      alreadyVerified,
    }: {
      phoneNumber: string;
      otp: string;
      alreadyVerified: boolean;
    }) => {
      const parsedPhoneNumber = phoneNumber.replaceAll('-', '');
      const {
        data: { token },
      } = await createVerifySMS(parsedPhoneNumber, otp);

      const body: {
        phoneNumberToken: string;
        organizationOid?: string;
      } = {
        phoneNumberToken: token,
      };

      if (!alreadyVerified) {
        body.organizationOid = activeSpaceId;
      }

      const { data } = await createReplaceUserPhoneNumberRequest(body);

      return data;
    },
    {
      onSuccess: (_, { alreadyVerified }) => {
        queryClient.invalidateQueries('auth');
        setToast({
          isVisible: true,
          type: 'success',
          message: alreadyVerified
            ? '휴대폰 번호가 성공적으로 변경되었습니다.'
            : '휴대폰 번호가 성공적으로 인증되었습니다.',
        });

        if (!alreadyVerified) {
          setSuccessModal({ isVisible: true });
        }
      },
      onError: (error: AxiosError) => {
        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });
      },
    },
  );
};

export const useReplaceUserPassword = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ password, newPassword }: { password: string; newPassword: string }) => {
      if (!passwordValidator(password)) {
        throw new ValidationError();
      }

      const { data } = await createReplaceUserPasswordRequest({ password, newPassword });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('auth');
        setToast({
          isVisible: true,
          type: 'success',
          message: '비밀번호가 성공적으로 변경되었습니다.',
        });
      },
      onError: () => {
        setToast({
          isVisible: true,
          type: 'error',
          message: t('admin:query-hooks.user.useReplaceUserPassword.onError.toast'),
        });
      },
    },
  );
};

export const useDeleteAccount = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const setMessageModal = useSetRecoilState(messageModal);
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async (body: UserRemoveRequestBody) => {
      const { password } = body;
      if (password && !passwordValidator(password)) {
        throw new ValidationError();
      }

      const { data } = await createDeleteAccountRequest(body);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.resetQueries();
        navigate('/');
        setMessageModal({
          isVisible: true,
          title: '계정 탈퇴가 완료되었습니다',
          message:
            '픽미 서비스를 아끼고 사랑해주셔서 고맙습니다. \n 보다 나은 서비스로 다시 찾아 뵙겠습니다.',
          type: 'success',
        });
      },
      onError: (error: AxiosError) => {
        if (error.code === 'AUTH-010') {
          setMessageModal({
            isVisible: true,
            title: t('admin:query-hooks.user.useRemoveUser.onError.modal.title'),
            message: error.message,
            type: 'error',
          });
          return;
        }

        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });
      },
    },
  );
};

export const useToggleMarketingAgreement = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async (isAgree: boolean) => {
      await createToggleMarketingAgreement(isAgree);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('auth');
        setToast({
          isVisible: true,
          type: 'success',
          message: '마케팅 메시지 수신 동의가 변경되었습니다.',
        });
      },
      onError: (error: AxiosError) => {
        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });
      },
    },
  );
};
