export default {
  title: '언어 설정',
  labels: {
    language: '언어',
  },
  actions: {
    cancel: '취소',
    submit: '저장하기',
  },
};
