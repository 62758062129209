import axios, { AxiosInstance } from 'axios';

import { makeQueryString } from 'functions/url';

import { InsufficientRequestHeaderError } from 'utils/error';

import { HostType, Request } from 'types/api';

axios.defaults.baseURL =
  import.meta.env.MODE !== 'dev.local' ? import.meta.env.VITE_API_HOST_URL : '';

axios.defaults.withCredentials = true;

const unsplashAxiosInstance: AxiosInstance = axios.create({
  baseURL:
    import.meta.env.MODE !== 'dev.local' ? import.meta.env.VITE_UNSPLASH_API_HOST : '/unsplash-api',
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Client-ID ${import.meta.env.VITE_UNSPLASH_API_CLIENT_ID}`,
  },
  withCredentials: false,
});

export const createHttpRequest = async <T>(request: Request) => {
  const headers = request.headers || {};

  if (
    !request.skipOrganizationOidCheck &&
    !request.withoutOrganizationOid &&
    !axios.defaults.headers.common['organization-oid']
  ) {
    return Promise.reject(new InsufficientRequestHeaderError('NEED_ORGANIZATION_OID'));
  }

  if (!request.skipOrganizationOidCheck && request.withoutOrganizationOid) {
    headers['organization-oid'] = null;
  }

  const url = combineUrlWithAdditionalInfo(request);

  delete request.params;
  delete request.query;

  const requestConfig = { ...request, url, headers };

  if (request.delay) {
    await sleep(request.delay);
  }

  if (request.hostType === HostType.Unsplash) {
    return unsplashAxiosInstance.request<T>(requestConfig);
  }

  return axios.request<T>(requestConfig);
};

export const combineUrlWithAdditionalInfo = (request: Request) => {
  const { params, query } = request;
  let { url } = request;
  let addition = '';

  if (params) {
    url = replaceUrlParams(url, params);
  }

  if (query) {
    addition += `?${makeQueryString(query)}`;
  }

  return `${url}${addition}`;
};

export const replaceUrlParams = (url: string, params: string) => {
  let replacedUrl = url;

  Object.entries(params).forEach(([key, value]) => {
    replacedUrl = replacedUrl.replace(`:${key}`, value);
  });

  return replacedUrl;
};

export const sleep = (milliseconds: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, milliseconds);
  });
