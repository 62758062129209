import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, PlainButton } from '@pickme/design-system';

import ScrollToTopButton from 'components/common/ScrollToTopButton';
import ChannelInfo from 'components/features/home/ChannelInfo';
import HomePollSearchKindTabs from 'components/features/home/PollSearchKindTabs';
import PollGroupSelect from 'components/features/home/PollGroupSelect';
import HomePollCards from 'components/features/home/HomePollCards';
import HomeDraftCards from 'components/features/home/HomeDraftCards';

import { useGetPollStats } from 'query-hooks/poll';
import { useGetDrafts } from 'query-hooks/draft';

import { useGetActiveSpace } from 'hooks/useGetActiveSpace';

import { PollSearchGroup, PollSearchKind } from 'types/poll';

import AddIcon from 'resources/icons/dashboard/add.svg';

import { useHomePollSearchQueryParams } from './useHomePollSearchQueryParams';

import { container, top, bottom, whiteBackground, wrapper, grayRoundedButton } from './index.css';

function Home() {
  const navigate = useNavigate();
  const [isDraftVisible, setIsDraftVisible] = useState(false);

  const [params, setParams] = useHomePollSearchQueryParams(DEFAULT_POLL_SEARCH_QUERY);

  const organization = useGetActiveSpace();

  const { data: pollStatistics } = useGetPollStats(params.kind);
  const { data: drafts } = useGetDrafts(params.kind, 1);

  return (
    <div className={container}>
      <div className={whiteBackground} />

      <div className={wrapper.parent}>
        <div className={wrapper.child}>
          <div className={top.container}>
            <ChannelInfo channelName={organization?.name || ''} channelLogo={organization?.logo} />

            <div className={top.tabs}>
              <HomePollSearchKindTabs selected={params.kind} />

              <Button
                icon={AddIcon}
                onClick={() => navigate('/poll/init', { unstable_viewTransition: true })}
                className={top.newButton}
              >
                새로 만들기
              </Button>
            </div>
          </div>

          <div className={bottom.container}>
            <div className={bottom.searchBar}>
              <PollGroupSelect
                selected={params.groups}
                onSelect={(groups) =>
                  setParams({ ...DEFAULT_POLL_SEARCH_QUERY, kind: params.kind, groups })
                }
                pollStatistics={pollStatistics}
                disableOpen={isDraftVisible}
                triggerClassName={grayRoundedButton({ active: !isDraftVisible })}
                onTriggerClick={() => setIsDraftVisible(false)}
              />

              <PlainButton
                className={grayRoundedButton({ active: isDraftVisible })}
                onClick={() => setIsDraftVisible(true)}
              >
                저장 {drafts?.total}
              </PlainButton>

              {/* TODO: 갤러리뷰 모드의 대시보드가 피그마에 추가되면 갤러리뷰 / 테이블뷰 선택 버튼을 추가해야 합니다. */}
            </div>

            {isDraftVisible ? <HomeDraftCards query={params} /> : <HomePollCards query={params} />}
          </div>
        </div>
      </div>

      <ScrollToTopButton />
    </div>
  );
}

export default Home;

const DEFAULT_POLL_SEARCH_QUERY = {
  kind: PollSearchKind.All,
  groups: PollSearchGroup.All,
  page: 1,
};
