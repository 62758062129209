import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

import { useRecoilState, useSetRecoilState } from 'recoil';
import { Text, Button } from '@pickme/ui';

import { useGetOrganization, useApplyOrganization } from 'query-hooks/organization';

import { spaceState } from 'states/space';
import { toast } from 'states/toast';

import JoinIcon from 'resources/icons/organization/join.png';

import './Join.scss';

function OrganizationJoin() {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toast);
  const setActiveSpaceId = useSetRecoilState(spaceState.activeSpaceId);
  const [invitationAlias, setInvitationAlias] = useRecoilState(spaceState.invitationAlias);

  const { data, isError } = useGetOrganization('alias', invitationAlias ?? '');
  const { mutate } = useApplyOrganization();

  useEffect(() => {
    // 기존 선택된 스페이스 초기화
    setActiveSpaceId('');

    // 잘못된 alias 입력 시
    if (isError) {
      setInvitationAlias('');
      setToast({
        isVisible: true,
        type: 'error',
        message: t('admin:pages.organization.join.errorMessages.notPermitted.message'),
      });

      navigate('/pickme');
    }
  }, [isError]);

  const onSubmit = () => {
    mutate({ id: data?._id || '' });
  };

  return (
    <div className='auth organization-selector'>
      <Text size='md1'>
        {t('admin:pages.organization.join.greeting', {
          space: data?.name || '',
          user: data?.adminInfo?.name || '',
        })}
      </Text>

      <img src={JoinIcon} alt='join' className='organization-join__image' />

      <div className='organization-selector__button'>
        <Button width='100%' size='xl' variant='primary' type='submit' onClick={() => onSubmit()}>
          {t('admin:pages.organization.join.submitButtonText')}
        </Button>
      </div>

      <div className='organization-selector__description'>
        <Text type='b3' fontWeight={400} color='gray-400'>
          {t('admin:pages.organization.join.description')}
        </Text>
      </div>
    </div>
  );
}

export default OrganizationJoin;
