export default {
  useRemoveManagerFromOrganization: {
    onSuccess: {
      toast: '매니저를 내보냈습니다.',
    },
  },
  useExamineManager: {
    onSuccess: {
      toast: {
        approve: '성공적으로 매니저가 승인되었습니다.',
        reject: '매니저의 가입을 거절했습니다.',
      },
    },
  },
  useSetManagerActivation: {
    onSuccess: {
      toast: {
        active: '매니저가 활성화 되었습니다.',
        inactive: '매니저가 비활성화 되었습니다',
      },
    },
  },
};
