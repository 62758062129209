import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { t } from 'i18next';
import { Space, SpaceCategory } from '@pickme/core';

import {
  createGetOrganizationRequest,
  createGetOrganizationsRequest,
  createApplyOrganizationRequest,
  createOrganizationRequest,
  createReplaceOrganizationNameRequest,
  createReplaceOrganizationLogoRequest,
  createReplaceOrganizationAliasRequest,
  createCheckAliasDuplicatedRequest,
} from 'apis/organization';

import { toast } from 'states/toast';
import { spaceState } from 'states/space';
import { faceMessageModal, messageModal } from 'states/modal';

import { setPlan } from 'functions/analytics/user';

export const useGetOrganization = (type: 'objectId' | 'alias', keyword: string) =>
  useQuery(
    ['organization', type, keyword],
    async () => {
      if (!keyword) {
        throw new Error();
      }

      const { data } = await createGetOrganizationRequest(type, keyword);
      const space = new Space(data);

      return space;
    },
    {
      onSuccess: ({ subscription }) => {
        if (subscription) {
          setPlan({
            plan: subscription.type,
            voterLimit: subscription.voterLimit,
          });
        }
      },
    },
  );

export const useGetOrganizations = () =>
  useQuery('organizations', async () => {
    const { data: spaceResponses } = await createGetOrganizationsRequest();
    const spaces = spaceResponses.map((spaceResponse) => new Space(spaceResponse));

    return spaces;
  });

export const useApplyOrganization = () => {
  const setFaceMessageModal = useSetRecoilState(faceMessageModal);
  const setToast = useSetRecoilState(toast);
  const setInvitationAlias = useSetRecoilState(spaceState.invitationAlias);

  return useMutation(
    async ({ id }: { id: string }) => {
      await createApplyOrganizationRequest(id);
    },
    {
      onSuccess: () => {
        setFaceMessageModal({
          isVisible: true,
          title: t('admin:query-hooks.organization.useApplyOrganization.onSuccess.modal.title'),
          message: t('admin:query-hooks.organization.useApplyOrganization.onSuccess.modal.message'),
          buttonText: t(
            'admin:query-hooks.organization.useApplyOrganization.onSuccess.modal.buttonText',
          ),
        });
      },
      onError: () => {
        setToast({
          isVisible: true,
          type: 'error',
          message: t('admin:query-hooks.organization.useApplyOrganization.onError.toast'),
        });
      },
      onSettled: () => {
        setInvitationAlias('');
      },
    },
  );
};

export const useCreateOrganization = () => {
  const setActiveSpaceId = useSetRecoilState(spaceState.activeSpaceId);
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ name, category }: { name: string; category: SpaceCategory }) => {
      const { data } = await createOrganizationRequest(name, category);

      return { id: data._id, name: data.name };
    },
    {
      onSuccess: ({ id, name }) => {
        setActiveSpaceId(id);
        setToast({
          isVisible: true,
          type: 'success',
          message: t('admin:query-hooks.organization.useCreateOrganization.onSuccess.toast', {
            organization: name,
          }),
        });
      },
    },
  );
};

export const useReplaceOrganizationName = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ name, spaceId }: { name: string; spaceId: string }) => {
      await createReplaceOrganizationNameRequest(name, spaceId);
      const { data } = await createGetOrganizationRequest('objectId', spaceId);

      return data._id;
    },
    {
      onSuccess: () => {
        setToast({
          isVisible: true,
          type: 'success',
          message: t('admin:query-hooks.organization.useReplaceOrganizationName.onSuccess.toast'),
        });

        queryClient.resetQueries('organization');
        queryClient.resetQueries('organizations');
      },
    },
  );
};
export const useReplaceOrganizationLogo = () => {
  const queryClient = useQueryClient();
  const setActiveSpaceId = useSetRecoilState(spaceState.activeSpaceId);
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ fileUrl, organizationId }: { fileUrl: string; organizationId: string }) => {
      await createReplaceOrganizationLogoRequest(fileUrl);
      const { data } = await createGetOrganizationRequest('objectId', organizationId);

      return data._id;
    },
    {
      onSuccess: (spaceId) => {
        setActiveSpaceId(spaceId);
        setToast({
          isVisible: true,
          type: 'success',
          message: t('admin:query-hooks.organization.useReplaceOrganizationLogo.onSuccess.toast'),
        });

        queryClient.resetQueries('organization');
        queryClient.resetQueries('organizations');
      },
    },
  );
};

export const useReplaceOrganizationAlias = () => {
  const queryClient = useQueryClient();
  const setActiveSpaceId = useSetRecoilState(spaceState.activeSpaceId);
  const setToast = useSetRecoilState(toast);
  const setMessageModal = useSetRecoilState(messageModal);

  return useMutation(
    async ({ alias, organizationId }: { alias: string; organizationId: string }) => {
      const { data } = await createCheckAliasDuplicatedRequest(alias);
      if (data.result) {
        setMessageModal({
          isVisible: true,
          title: t('admin:query-hooks.organization.useReplaceOrganizationAlias.modal.title'),
          message: t('admin:query-hooks.organization.useReplaceOrganizationAlias.modal.message'),
        });

        throw new Error();
      }

      await createReplaceOrganizationAliasRequest(alias);

      const { data: spaceResponse } = await createGetOrganizationRequest(
        'objectId',
        organizationId,
      );

      return spaceResponse._id;
    },
    {
      onSuccess: (spaceId) => {
        setActiveSpaceId(spaceId);

        setToast({
          isVisible: true,
          type: 'success',
          message: t('admin:query-hooks.organization.useReplaceOrganizationAlias.onSuccess.toast'),
        });

        queryClient.resetQueries('organization');
        queryClient.resetQueries('organizations');
      },
    },
  );
};
