import { FieldArrayWithId } from 'react-hook-form';
import { t } from 'i18next';

import { emailValidator } from 'functions/validator/auth';

import { VoterError } from 'utils/error';

import { VoterBody, VoterFormBody } from 'types/application';

export const phoneNumberValidator = (value: string) =>
  /^(010-\d{4}-\d{4}$)|(^010\d{4}\d{4}$)/.test(value);

export const validateRow = (row: VoterBody) => {
  if (!row.name) {
    throw new VoterError(t('admin:functions.validator.poll.voter.missedName'), ['name']);
  }

  if (!row.email && !row.phoneNumber) {
    throw new VoterError(t('admin:functions.validator.poll.voter.lackOfMethod'), [
      'phoneNumber',
      'email',
    ]);
  }

  if (row.email && !emailValidator(row.email)) {
    throw new VoterError(t('admin:functions.validator.poll.voter.emailFormat'), ['email']);
  }

  if (row.phoneNumber && !phoneNumberValidator(row.phoneNumber)) {
    throw new VoterError(t('admin:functions.validator.poll.voter.phoneNumberFormat'), [
      'phoneNumber',
    ]);
  }
};

export const validateRowInDuplication = (
  row: VoterBody,
  userIds: Set<string>,
  phoneNumbers: Set<string>,
  emails: Set<string>,
) => {
  if (row.userId) {
    if (userIds.has(row.userId)) {
      throw new VoterError(t('admin:functions.validator.poll.voter.userIdDuplicated'), ['userId']);
    }

    userIds.add(row.userId);
  }

  if (row.phoneNumber) {
    if (phoneNumbers.has(row.phoneNumber)) {
      throw new VoterError(t('admin:functions.validator.poll.voter.phoneNumberDuplicated'), [
        'phoneNumber',
      ]);
    }

    phoneNumbers.add(row.phoneNumber);
  }

  if (row.email) {
    if (emails.has(row.email)) {
      throw new VoterError(t('admin:functions.validator.poll.voter.emailDuplicated'), ['email']);
    }

    emails.add(row.email);
  }
};

export const computeCategories = (fields: FieldArrayWithId<VoterFormBody, 'voterBook', 'id'>[]) => {
  const categoriesFromFields = fields.slice().map((obj) => obj.category) as string[];
  let unset = 0;

  const categoryCounts: { [key: string]: number } = {};
  categoriesFromFields.forEach((value) => {
    if (!value) {
      unset += 1;
    } else if (categoryCounts[value]) {
      categoryCounts[value] += 1;
    } else {
      categoryCounts[value] = 1;
    }
  });

  const result = Object.entries(categoryCounts).map(([name, count]) => ({ name, count }));
  return { categories: result, unset, total: fields.length };
};
