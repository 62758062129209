import { CategoryBarChart, Text } from '@pickme/ui';
import { t } from 'i18next';

type Props = {
  id: string;
  rateByCategory?: {
    category: string;
    count: number;
    rate: number;
    totalCount: number;
  }[];
  noAnimation?: boolean;
};

function CategorizedProgressGraphSection({ id, rateByCategory, noAnimation }: Props) {
  return (
    <div className='poll-progress__section'>
      <div className='poll-progress__section__title'>
        <Text type='b1' fontWeight={600}>
          {t('admin:components.features.poll.progress.categorizedProgressGraphSection.title')}
        </Text>
      </div>

      <div className='poll-progress__graph-container'>
        <div className='poll-progress__bar-graphs'>
          <CategoryBarChart
            id={id}
            unit={t('admin:terms.unit.vote')}
            items={
              rateByCategory?.map(({ category, totalCount, count }) => ({
                category:
                  category ||
                  t(
                    'admin:components.features.poll.progress.categorizedProgressGraphSection.uncategorized',
                  ),
                totalLabel: `(${t(
                  'admin:components.features.poll.progress.categorizedProgressGraphSection.total',
                )} ${totalCount}${t('admin:terms.unit.people')})`,
                totalCount,
                count,
              })) || []
            }
            animation={{
              countingUp: !noAnimation,
              fadeIn: !noAnimation,
              fillBar: !noAnimation,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CategorizedProgressGraphSection;
