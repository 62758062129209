export default {
  title: {
    election: '선거 만들기',
    survey: '설문 만들기',
  },
  table: {
    header: {
      name: {
        election: '선거 제목',
        survey: '설문 제목',
      },
      period: {
        election: '선거 기간',
        survey: '설문 기간',
      },
      manage: '관리',
    },
    body: {
      period: {
        empty: '선택안함',
      },
      manage: {
        remove: '삭제하기',
      },
    },
  },
  search: {
    result: {
      blank: {
        election: '저장된 선거가 없어요',
        survey: '저장된 설문이 없어요',
      },
    },
  },
  callout: {
    election: [
      '대표자 선출을 위한 새로운 선거를 만들어보세요!',
      '템플릿을 활용하면 쉽고 빠르게 선거를 만들 수 있어요.',
    ],
    survey: [
      '조직의 의견 수렴을 위한 설문을 만들어보세요!',
      '템플릿을 활용하면 쉽고 빠르게 설문을 만들 수 있어요.',
    ],
  },
  label: {
    draft: {
      election: '저장된 선거 목록',
      survey: '저장된 설문 목록',
    },
  },
  button: {
    create: {
      election: '새로운 선거 만들기',
      survey: '새로운 설문 만들기',
    },
  },
};
