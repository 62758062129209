import auth from './auth';
import channel from './channel';
import draft from './draft';
import manager from './manager';
import notification from './notification';
import organization from './organization';
import payment from './payment';
import pollActions from './poll-actions';
import promotion from './promotion';
import space from './space';
import user from './user';

export default {
  auth,
  channel,
  draft,
  manager,
  notification,
  organization,
  payment,
  'poll-actions': pollActions,
  promotion,
  space,
  user,
};
