import { Plan } from '@pickme/core';

import PollTableView from 'components/features/poll/PollList/PollTableView';
import PollCardView from 'components/features/poll/PollList/PollCardView';

import { useGetSessionAsMember } from 'query-hooks/auth';

import { useGetFeatures } from 'hooks/useGetFeatures';

import { Poll, PollViewMode } from 'types/poll';
import PollEmptyStateView from './EmptyStateView';

type Props = {
  viewMode: PollViewMode;
  kind: 'election' | 'survey' | 'all';
  polls: Poll[];
  keyword?: string;
  isLoading: boolean;
};

function PollList({ viewMode, kind, polls, keyword, isLoading }: Props) {
  const { data: memberSessionData } = useGetSessionAsMember();
  const { isPaymentFailed } = useGetFeatures();

  const pollListItems =
    polls?.map((poll) => {
      const isUserManageable =
        memberSessionData?.level === 'OrganAdmin' ||
        (memberSessionData?.level === 'SubAdmin' && memberSessionData?.id === poll?.authorId);

      const isEditDisabled =
        !isUserManageable ||
        poll.group === 'counted' ||
        poll.group === 'canceled' ||
        (poll.subscriptionType !== Plan.Free && isPaymentFailed);
      const isRemoveDisabled = !isUserManageable || poll.group === 'ongoing';

      return {
        ...poll,
        disabled: false,
        disabledActions: {
          edit: isEditDisabled,
          share: false,
          remove: isRemoveDisabled,
        },
      };
    }) || [];

  if (!isLoading && pollListItems.length === 0) {
    return <PollEmptyStateView type={keyword ? 'search' : 'default'} />;
  }

  return viewMode === PollViewMode.Table ? (
    <PollTableView kind={kind} polls={pollListItems} showSkeleton={isLoading} />
  ) : (
    <PollCardView polls={pollListItems} showSkeleton={isLoading} />
  );
}

export default PollList;
