import { Avatar, SecondaryBadge, Text } from '@pickme/ui';

import './index.scss';

type Props = {
  title: string;
  tags?: string[];
  members: { fileUrl?: string; name: string }[];
};

function CandidateCardContents({ title, members, tags }: Props) {
  const memberNames = members
    .filter(({ name: memberName }) => !!memberName)
    .map(({ name: memberName }) => memberName)
    .join('/');

  return (
    <div className='candidate-card-contents'>
      <Avatar image={members?.[0]?.fileUrl || ''} size='xl' />

      <div className='candidate-card-contents__body'>
        <Text className='candidate-card-contents__title' type='b1' fontWeight={500}>
          {title || ''}
        </Text>

        <Text className='candidate-card-contents__members' type='b3' fontWeight={400}>
          {memberNames || ''}
        </Text>

        <div className='candidate-card-contents__tags'>
          {tags?.map((tag, index) => (
            <SecondaryBadge key={`${tag}-${index}`}>{tag}</SecondaryBadge>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CandidateCardContents;
