import { useEffect, useRef } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import {
  Dropdown,
  Input,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  OutlineButton,
  SecondaryBadge,
} from '@pickme/ui';
import Slider from 'react-slick';
import { t } from 'i18next';

import FieldTitle from 'components/features/application/label/old_FieldTitle';
import MarkUpEditor from 'components/features/application/field/MarkUpEditor';
import HashInput from 'components/features/application/form/SubElections/SubElection/Candidate/HashInput';

import PlainTextArea from 'components/common/input/PlainTextArea';

import { useUploadFile } from 'query-hooks/file';

import { CandidateFormBody } from 'types/application';

import CandidatePlaceholderImage from 'resources/icons/application/candidate.png';
import MoreIcon from 'resources/icons/application/more.png';
import UploadIcon from 'resources/icons/application/upload.png';
import EditIcon from 'resources/icons/application/edit.png';
import RefreshIcon from 'resources/icons/application/refresh.png';
import RemoveIcon from 'resources/icons/application/remove.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './index.scss';

type Props = {
  isVisible: boolean;
  initialValue?: CandidateFormBody;
  onClose: () => void;
  onSubmitCandidate: (body: CandidateFormBody) => void;
};

const defaultValues = {
  name: '',
  description: '',
  members: [
    {
      fileUrl: undefined,
      description: '',
    },
  ],
  tags: [],
  introductionVideoUrl: '',
};

function CandidateForm({ isVisible, initialValue, onClose, onSubmitCandidate }: Props) {
  const sliderRef = useRef<Slider>(null);

  const methods = useForm<CandidateFormBody>({
    mode: 'onBlur',
    defaultValues,
  });
  const { control, handleSubmit, register, reset, setValue, getValues, watch, formState } = methods;

  const { fields, append, remove, update } = useFieldArray<CandidateFormBody>({
    control,
    name: 'members',
    keyName: 'id',
  });

  const { mutate: fileUploadMutate, isLoading: isFileUploadLoading } = useUploadFile();

  const onSubmit = (candidate: CandidateFormBody) => {
    const filteredMembers = candidate.members.slice().filter((member, index) => {
      if (index !== 0 && !member.fileUrl && !member.name) {
        return false;
      }
      return true;
    });

    onSubmitCandidate({ ...candidate, members: filteredMembers });
  };

  useEffect(() => {
    if (isVisible) {
      if (initialValue) {
        reset(initialValue);
      } else {
        reset({
          title: '',
          description: '',
          introductionVideoUrl: '',
          members: [
            {
              name: '',
              fileUrl: '',
            },
          ],
          tags: [],
        });
      }

      document.getElementById('candidate-form')?.scrollTo(0, 0);
    }
  }, [isVisible, initialValue]);

  return (
    <Modal
      className='candidate'
      isVisible={isVisible}
      onClose={onClose}
      size='lg'
      disableToCloseOnBackdrop
    >
      <FormProvider {...methods}>
        <form id='candidate-form' className='candidate-form' onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            title={t('admin:components.features.application.form.subElection.form.candidate.title')}
            onClose={onClose}
          />

          <ModalBody>
            <div className='candidate-form__title'>
              <PlainTextArea
                placeholder={t(
                  'admin:components.features.application.form.subElection.form.candidate.fields.title.placeholder',
                )}
                isValid={!formState.errors.title}
                maxLength={150}
                {...register('title', { required: true, validate: (text) => text.length > 0 })}
              />
            </div>

            <div className='application-form__row application-form__row--justify'>
              <FieldTitle title='' tooltip='' />
              <OutlineButton
                type='button'
                onClick={() => {
                  append({
                    fileUrl: '',
                    name: '',
                  });
                  sliderRef.current?.slickNext();
                }}
              >
                {t(
                  'admin:components.features.application.form.subElection.form.candidate.buttons.add',
                )}
              </OutlineButton>
            </div>

            <Slider
              className='candidate-form__candidates'
              slidesPerRow={3}
              infinite={false}
              ref={sliderRef}
            >
              {fields.map((field, index) => {
                const fileUrl = watch(`members.${index}.fileUrl`);
                return (
                  <div className='candidate-form__candidate' key={field.id}>
                    <div className='candidate-form__image-field'>
                      <input hidden {...register(`members.${index}.fileUrl`)} />
                      <input
                        hidden
                        id={`members-${index}-file-input`}
                        type='file'
                        accept='image/jpeg, image/jpg, image/png'
                        onChange={(event) => {
                          if (
                            isFileUploadLoading ||
                            !event.target.files ||
                            !event.target.files?.[0]
                          ) {
                            return;
                          }

                          fileUploadMutate(event.target.files[0], {
                            onSuccess: (result) => {
                              update(index, {
                                name: getValues(`members.${index}.name`),
                                fileUrl: result,
                              });
                            },
                          });
                        }}
                      />

                      {fileUrl ? (
                        <>
                          <img
                            className='candidate-form__image-field__image'
                            src={fileUrl}
                            alt='candidate'
                          />
                          {index === 0 && (
                            <SecondaryBadge variant='yellow'>
                              {t(
                                'admin:components.features.application.form.subElection.form.candidate.labels.primaryMember',
                              )}
                            </SecondaryBadge>
                          )}
                        </>
                      ) : (
                        // 실제 이미지가 들어갔을 경우 vs placeholder 일 경우 사이즈가 다르기 때문에
                        // 스타일을 맞추기 위해 container 추가.
                        <div className='candidate-form__image-field__placeholder-container'>
                          <img
                            className='candidate-form__image-field__placeholder'
                            src={CandidatePlaceholderImage}
                            alt='candidate'
                          />
                        </div>
                      )}
                      <div className='candidate-form__image-field__dropdown'>
                        <Dropdown
                          directionBase={index % 3 === 2 ? 'bottom' : 'left'}
                          direction='down'
                          button={
                            <img
                              className='candidate-form__image-field__icon'
                              src={MoreIcon}
                              alt='more'
                            />
                          }
                          items={
                            !fileUrl
                              ? [
                                  {
                                    text: t(
                                      'admin:components.features.application.form.subElection.form.candidate.buttons.upload',
                                    ),
                                    type: 'button',
                                    icon: UploadIcon,
                                    onClick: () => {
                                      if (isFileUploadLoading) {
                                        return;
                                      }

                                      document
                                        .getElementById(`members-${index}-file-input`)
                                        ?.click();
                                    },
                                  },
                                  {
                                    text: t(
                                      'admin:components.features.application.form.subElection.form.candidate.buttons.remove',
                                    ),
                                    type: 'button',
                                    icon: RemoveIcon,
                                    color: 'red',
                                    disabled: watch('members').length === 1,
                                    onClick: () => remove(index),
                                  },
                                ]
                              : [
                                  {
                                    text: t(
                                      'admin:components.features.application.form.subElection.form.candidate.buttons.edit',
                                    ),
                                    type: 'button',
                                    icon: EditIcon,
                                    onClick: () => {
                                      if (isFileUploadLoading) {
                                        return;
                                      }

                                      document
                                        .getElementById(`members-${index}-file-input`)
                                        ?.click();
                                    },
                                  },
                                  {
                                    text: t(
                                      'admin:components.features.application.form.subElection.form.candidate.buttons.refresh',
                                    ),
                                    type: 'button',
                                    icon: RefreshIcon,
                                    onClick: () => setValue(`members.${index}.fileUrl`, ''),
                                  },
                                  {
                                    text: t(
                                      'admin:components.features.application.form.subElection.form.candidate.buttons.remove',
                                    ),
                                    type: 'button',
                                    icon: RemoveIcon,
                                    color: 'red',
                                    disabled: watch('members').length === 1,
                                    onClick: () => remove(index),
                                  },
                                ]
                          }
                        />
                      </div>
                    </div>
                    <Input
                      width={140}
                      size='sm'
                      placeholder={t(
                        'admin:components.features.application.form.subElection.form.candidate.fields.member.name.placeholder',
                      )}
                      {...register(`members.${index}.name`)}
                    />
                  </div>
                );
              })}
            </Slider>

            <div className='candidate-form__row'>
              <FieldTitle
                title={t(
                  'admin:components.features.application.form.subElection.form.candidate.fields.member.tag.label',
                )}
                tooltip={t(
                  'admin:components.features.application.form.subElection.form.candidate.fields.member.tag.tooltip',
                )}
              />
              <Controller
                control={control}
                name='tags'
                render={({ field }) => (
                  <HashInput
                    isVisible={isVisible}
                    initialTags={field.value || []}
                    onChange={(tags) => setValue('tags', tags)}
                  />
                )}
              />
            </div>

            <div className='candidate-form__row'>
              <FieldTitle
                title={t(
                  'admin:components.features.application.form.subElection.form.candidate.fields.member.description.label',
                )}
                tooltip={t(
                  'admin:components.features.application.form.subElection.form.candidate.fields.member.description.tooltip',
                )}
              />

              <Controller
                control={control}
                name='description'
                rules={{
                  validate: (text) => !text || text?.replace(/<[^>]*>?/g, '')?.length <= 5000,
                }}
                render={({ field }) => <MarkUpEditor {...field} maxLength={5000} />}
              />
            </div>

            <div className='candidate-form__row'>
              <FieldTitle
                title={t(
                  'admin:components.features.application.form.subElection.form.candidate.fields.member.video.label',
                )}
              />

              <div className='candidate-form__video-link'>
                <Input placeholder='https://' size='md' {...register('introductionVideoUrl')} />
                <OutlineButton
                  disabled={!watch('introductionVideoUrl')}
                  size='md'
                  type='button'
                  onClick={() => {
                    let url = watch('introductionVideoUrl') || '';
                    if (!url.startsWith('http')) {
                      url = `https://${url}`;
                      methods.setValue('introductionVideoUrl', url);
                    }

                    window.open(url, '_blank');
                  }}
                >
                  {t(
                    'admin:components.features.application.form.subElection.form.candidate.fields.member.video.button',
                  )}
                </OutlineButton>
              </div>
            </div>
          </ModalBody>

          <ModalActions
            buttons={[
              {
                text: t(
                  'admin:components.features.application.form.subElection.modal.candidate.actions.cancel',
                ),
                onClick: onClose,
                variant: 'gray',
              },
              {
                text: t(
                  'admin:components.features.application.form.subElection.modal.candidate.actions.submit',
                ),
                type: 'submit',
              },
            ]}
          />
        </form>
      </FormProvider>
    </Modal>
  );
}

export default CandidateForm;
