export default {
  title: '비밀번호 재설정하기',
  fields: {
    email: {
      placeholder: '이메일',
    },
  },
  errorMessages: {
    wrongEmail: '올바른 이메일을 입력해주세요',
  },
  buttons: {
    submit: {
      default: '재설정하기',
      retry: '다시 보내기',
    },
  },
};
