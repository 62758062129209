import { DraftTemplate, DraftTemplateCategory } from 'types/template';

import Thumbnail1 from 'resources/images/templates/election/club1.png';

const DRAFT_1 = {
  title: '동호회장 선출 투표',
  _id: '64954a0a47debe00488edb11',
  content: {
    name: '동호회장 선출 투표',
    periods: [
      {
        startDate: '2024-02-01T15:44',
        endDate: '2024-02-29T15:44',
      },
    ],
    description:
      '<p>본 투표는 일 년 동안 동호회를 이끌어갈 동호회장을 선출하는 투표입니다.</p><p>동호회원님들의 소중한 한 표 부탁드립니다.</p><p>감사합니다.</p>',
    type: 'Election',
    voterType: 'voterBook',
    subscriptionType: 'enterprise',
    subElections: [
      {
        kind: 'ChoiceElection',
        title: '동호회장 선출 투표',
        shuffle: false,
        candidates: [
          {
            title: '후보 1. 김성준',
            description:
              '<p>이름 : 김성준</p><p>나이 : 33세</p><p>가입시기 : 2021년 3월</p><p><br></p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '김성준',
                fileUrl: 'https://api.ohpick.me/api/file/649542d347debe00488edaa7',
              },
            ],
            tags: [],
            id: 'a2d12486-2530-42ff-a821-312a6f76d98b',
          },
          {
            title: '후보 2. 한아름',
            description: '<p>이름 : 한아름</p><p>나이 : 27세</p><p>가입시기 : 2022년 6월</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '한아름',
                fileUrl: 'https://api.ohpick.me/api/file/649545d047debe00488edabe',
              },
            ],
            tags: [],
          },
          {
            title: '후보 3. 박지영',
            description: '<p>이름 : 박지영</p><p>나이 : 29세</p><p>가입시기 : 2022년 3월</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '박지영',
                fileUrl: 'https://api.ohpick.me/api/file/649546cc47debe00488edac7',
              },
            ],
            tags: [],
            id: '6cc0f4f9-ab2c-4107-b6c7-30f36946aaf8',
          },
          {
            title: '후보 4. 정지훈',
            description: '<p>이름 : 정지훈</p><p>나이 : 29세</p><p>가입시기 : 2020년 7월</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '정지훈',
                fileUrl: 'https://api.ohpick.me/api/file/649547aa47debe00488edad0',
              },
            ],
            tags: [],
            id: 'f559652d-569d-4870-a392-8a654e21886a',
          },
        ],
        responseValidation: {
          allowAbstentionVote: true,
          minResponse: 1,
          maxResponse: 1,
        },
        id: 'a7e57a42-c9d9-4382-9cc9-692d44d5d661',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'election',
  createdAt: '2023-06-23T07:30:18.543Z',
  __v: 0,
};

export default [
  {
    title: '동호회장 선출 투표',
    description: '동호회장 선출 투표를 위한 템플릿입니다.',
    thumbnail: Thumbnail1,
    draft: DRAFT_1,
    category: DraftTemplateCategory.Club,
    kind: 'Election',
  },
] as DraftTemplate[];
