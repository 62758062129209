import { MemberLevel, Plan, PollGroup } from '@pickme/core';

import { Period } from 'types/common';
import { ElectionKind, SurveyKind, VoterAuthMethod } from 'types/application';

export enum PollViewMode {
  Table = 'table',
  Card = 'card',
}

export type PollListItem = Poll & {
  disabledActions: {
    edit: boolean;
    share: boolean;
    remove: boolean;
  };
};

export type PollStat = { title: string; count: number; alias: PollGroup };
export type PollKind = 'Election' | 'Survey';

export type Poll = {
  author: {
    name: string;
    email: string;
    level: MemberLevel;
  } | null;
  population: {
    count: number;
    lastUpdate: string;
  };
  cachedVoteCount: {
    count: number;
    lastUpdate: string;
  };
  subscriptionType: Plan;
  _id: string;
  description: string;
  status: 'approved' | 'pending' | 'canceled';
  openThreshold: number;
  hasResult: boolean;
  hasOpenVotes?: boolean;
  isOpenPollRate: boolean;
  isPause: boolean;
  isOpenPollResult: boolean;
  demo: boolean;
  kind: PollKind;
  name: string;
  periods: { startDate: string; endDate: string }[];
  subElections?: SubElection[];
  questions?: Question[];
  pollRate: {
    population: number;
    rate: number;
    voteCount: number;
  };
  organizationId: string;
  authorId: string;
  startDate: string;
  endDate: { updatedAt: string; history: { date: string; updatedAt: string }[]; date: string };
  stage: 'pre' | 'in' | 'post';
  isInBreakTime: boolean;
  group: PollGroup;
  voterbookExpiry: string | null;
  isVoterBookDeleted: boolean;
  hasInstantVoter?: boolean;
  allowRealTimeResult?: boolean;
  voterAuthMethods?: VoterAuthMethod[];
  coverImageFileUrl?: string;
  voterBook?: Voter[];
  isPublic?: boolean;
};

export type SubElection = {
  _id: string;
  kind: ElectionKind;
  title: string;
  shuffle: boolean;
  responseValidation: {
    allowAbstentionVote: boolean;
    minResponse?: number;
    maxResponse?: number;
    rankLimit?: number;
    minScore?: number;
    maxScore?: number;
  };
  candidates: Candidate[];
};

export type Candidate = {
  tags: string[];
  _id: string;
  title: string;
  members: { fileUrl: string; name: string }[];
  description: string;
  introductionVideoUrl: string;
};

export type PollStatisticsResponse = {
  ready: number;
  ongoing: number;
  pause: number;
  post: number;
};

export type PollStatistics = Record<PollSearchGroup, number>;

export enum PollSearchKind {
  All = 'all',
  Election = 'Election',
  Survey = 'Survey',
}

export enum PollSearchGroup {
  All = 'all',
  Ready = PollGroup.Ready,
  Ongoing = PollGroup.Ongoing,
  Ended = PollGroup.Ended,
  Paused = PollGroup.Paused,
}

export type PollSearchForm = {
  kind: PollSearchKind;
  groups: PollSearchGroup;
  name: string;
};

export type PollSearchQuery = {
  kind?: PollSearchKind;
  groups?: PollSearchGroup;
  name?: string;
  page?: number;
  period?: Period;
  excludeInstantPoll?: boolean;
  authorId?: string;
};

// NOTE: 기존에 useGetPolls에서 사용하던 쿼리 타입을 그대로 옮겨온 것입니다.
// Poll 검색을 위한 타입(PollSearchKind, PollSearchGroup)과 Poll 자체 타입(PollKind, PollGroup)을 분리합니다.
// 점진적으로 PollSearchKind, PollSearchGroup만 사용하도록 변경할 예정입니다.
export type PollQueryAdapter = {
  kind?: PollSearchKind | PollKind | 'all';
  name?: string;
  page?: number;
  period?: Period;
  groups?: PollSearchGroup | PollGroup[];
  excludeInstantPoll?: boolean;
  authorId?: string;
};

export type PollQuery = {
  kind?: PollKind | string;
  pollName?: string;
  page: number | string;
  groups?: string;
  period?: string;
  excludeInstantPoll?: string;
  pollRate: boolean;
  authorId?: string;
};

export type PollResult = {
  title: string;
  group: PollGroup;
  author?: {
    name: string;
    email: string;
  };
  rate: {
    population: number;
    voteCount: number;
    rate: number;
    rateByHour: {
      date: string;
      count: number;
    }[];
  };
  subElectionResults?: (
    | ChoiceElectionResult
    | ProsOrConsElectionResult
    | RankElectionResult
    | ScoreElectionResult
  )[];
  questionResults?: (
    | RadioQuestionResult
    | CheckboxQuestionResult
    | TextQuestionResult
    | ScaleQuestionResult
    | RankQuestionResult
    | ScoreQuestionResult
  )[];
};

export type ChoiceElectionResult = {
  _id: string;
  kind: 'ChoiceElection';
  title: string;
  responseValidation: {
    allowAbstentionVote: true;
    minResponse: number;
    maxResponse: number;
  };
  totalVoteCount: number;
  abstentionCount: {
    value: number;
    rate: number;
  };
  resultsPerCandidate: {
    title: string;
    members: {
      _id: string;
      fileUrl: string;
      name: string;
    }[];
    voteCount: {
      value: number;
      rate: number;
    };
  }[];
};

export type ProsOrConsElectionResult = {
  _id: string;
  kind: 'ProsOrConsElection';
  title: string;
  responseValidation: {
    allowAbstentionVote: boolean;
  };
  totalVoteCount: number;
  resultsPerCandidate: {
    title: string;
    members: {
      _id: string;
      fileUrl: string;
      name: string;
    }[];
    pros: {
      value: number;
      rate: number;
    };
    cons: {
      value: number;
      rate: number;
    };
    abstention: {
      value: number;
      rate: number;
    };
  }[];
};

export type RankElectionResult = {
  _id: string;
  kind: 'RankElection';
  title: string;
  responseValidation: {
    allowAbstentionVote: boolean;
    rankLimit: number;
  };
  totalVoteCount: number;
  abstentionCount: {
    value: number;
    rate: number;
  };
  resultsPerCandidate: {
    title: string;
    members: [
      {
        _id: string;
        fileUrl: string;
        name: string;
      },
    ];
    voteCountsPerRank: {
      _id: string;
      rank: string;
      voteCount: number;
    }[];
    weightedScore: number;
  }[];
};

export type ScoreElectionResult = {
  _id: string;
  kind: 'ScoreElection';
  title: string;
  responseValidation: {
    allowAbstentionVote: boolean;
    minScore: number;
    maxScore: number;
  };
  totalVoteCount: number;
  abstentionCount: {
    value: number;
    rate: number;
  };
  resultsPerCandidate: {
    title: string;
    members: {
      _id: string;
      fileUrl: string;
      name: string;
    }[];
    totalScore: number;
    voteCount: number;
    averageScore: number;
  }[];
};

export type PollRate = {
  population: number;
  voteCount: number;
  rate: number;
  rateByHour: {
    date: string;
    count: number;
    rate: number;
  }[];
  rateByCategory: {
    category: string;
    count: number;
    rate: number;
    totalCount: number;
  }[];
};

export type Voter = {
  _id: string;
  hasVoted: boolean;
  userId?: string;
  name: string;
  phoneNumber?: string;
  email?: string;
  pollId: string;
  organizationId: string;
  __v: 0;
};

export type Population = {
  totalPopulation: number;
  categoryCount: number;
  populationPerCategory: {
    category: string;
    population: number;
  }[];
};

export type Question = {
  _id: string;
  kind: SurveyKind;
  title: string;
  description: string;
  videoUrl?: {
    youtube?: string;
  };
  fileUrl?: string;
  isRequired: boolean;
  shuffle?: boolean;
  selections?: {
    _id: string;
    number: number;
    fileUrl?: string;
    description: string;
    videoUrl?: {
      youtube?: string;
    };
  }[];
  subQuestions?: {
    _id: string;
    description?: string;
    fileUrl?: string;
    videoUrl?: {
      youtube?: string;
    };
  }[];
  min?: {
    description: string;
    value: number;
  };
  max?: {
    description: string;
    value: number;
  };
  responseValidation?: {
    threshold?: number;
    validation?: 'gt' | 'lt';
    kind?: 'AnswerLengthValidation';
    rankLimit?: number;
    minScore?: number;
    maxScore?: number;
  };
};

export type RadioQuestionResult = {
  fileUrl?: string;
  isRequired: boolean;
  kind: 'RadioQuestionResult';
  results: {
    count: number;
    description?: string;
    fileUrl?: string;
    number: number;
    rate: number;
  }[];
  title: string;
  totalAnsweredCount: number;
  _id: string;
};

export type CheckboxQuestionResult = {
  fileUrl?: string;
  isRequired: boolean;
  kind: 'CheckboxQuestionResult';
  results: {
    count: number;
    description?: string;
    fileUrl?: string;
    number: number;
    rate: number;
  }[];
  title: string;
  totalAnsweredCount: number;
  _id: string;
};

export type TextQuestionResult = {
  fileUrl?: string;
  isRequired: boolean;
  kind: 'TextQuestionResult';
  results: string[];
  title: string;
  totalAnsweredCount: number;
  _id: string;
};

export type ScaleQuestionResult = {
  fileUrl?: string;
  isRequired: boolean;
  kind: 'ScaleQuestionResult';
  max?: string;
  min?: string;
  results: { count: number; number: string; rate: number }[];
  title: string;
  totalAnsweredCount: number;
  _id: string;
};

export type RankQuestionResult = {
  fileUrl?: string;
  isRequired: boolean;
  kind: 'RankQuestionResult';
  responseValidation: { rankLimit: number };
  results: {
    answerCountsPerRank: {
      count: number;
      rank: string;
      _id: string;
    }[];
    description: string;
    fileUrl?: string;
    weightedScore: number;
  }[];
  title: string;
  totalAnsweredCount: number;
  _id: string;
};

export type ScoreQuestionResult = {
  fileUrl?: string;
  isRequired: boolean;
  kind: 'ScoreQuestionResult';
  responseValidation: { minScore: number; maxScore: number };
  results: {
    answeredCount: number;
    averageScore: number;
    description?: string;
    fileUrl?: string;
    totalScore: number;
  }[];
  _id: string;
  title: string;
  totalAnsweredCount: number;
};

export type PollInHistoryRequest = Poll & {
  author?: {
    level: MemberLevel;
    name: string;
  };
};

export type PollAnalysisResult = {
  _id: string;
  pollOid: string;
  status: PollResultAnalysisTypes | UnRequested;
  analysisType: 'summary';
  result?: string;
};

export type PollResultAnalysisTypes = 'requested' | 'completed';
export type UnRequested = 'unRequested';

// TODO: 설문조사 프로모션이 끝나면 관리자에서는 필요가 없는 타입이므로 삭제 예정
export type SurveyAnswer = {
  questionId: string;
  answer?: string | { contents: string; responseValidationId: string; kind: 'ContentsLength' };
  answers?: string[];
  subAnswers?: { subQuestionOid: string; value: string }[];
  subAnswerOidsForRank?: string[];
};
