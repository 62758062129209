import additional from './form/additional';
import basic from './form/basic';
import subElection from './form/subElection';
import question from './form/question';
import voter from './form/voter';

import cover from './fields/cover';
import image from './fields/image';

import complete from './modal/complete';
import draft from './modal/draft';
import draftRemoveConfirm from './modal/draftRemoveConfirm';
import draftWriteConfirm from './modal/draftWriteConfirm';
import leave from './modal/leave';
import periodError from './modal/periodError';

export default {
  form: {
    additional,
    basic,
    subElection,
    question,
    voter,
  },
  fields: {
    image,
    cover,
  },
  modal: {
    complete,
    draft,
    draftRemoveConfirm,
    draftWriteConfirm,
    leave,
    periodError,
  },
};
