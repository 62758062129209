export default {
  title: '대시보드',
  greeting: '안녕하세요 {{username}}님!',
  cards: {
    guide: {
      helpCenter: '헬프센터',
      description: '픽미가 처음이신가요? 헬프센터에서 궁금한 점을 찾아보세요!',
      buttonText: '확인하기',
    },
    plan: {
      title: {
        free: '무료 플랜',
        basic: '베이직 플랜',
        standard: '스탠다드 플랜',
        premium: '프리미엄 플랜',
        enterprise: '엔터프라이즈 플랜',
        campus: '캠퍼스 플랜',
      },
      description: {
        free: '현재 무료 플랜을 이용 중이에요. 30인 이하 인원은 무료로 투표를 만들 수 있어요.',
        basic: '현재 베이직 플랜을 이용 중이에요. 월 최대 1개까지 투표를 만들 수 있어요.',
        standard: '현재 스탠다드 플랜을 이용 중이에요. 월 최대 5개까지 투표를 만들 수 있어요.',
        premium: '현재 프리미엄 플랜을 이용 중이에요. 월 최대 20개까지 투표를 만들 수 있어요.',
        enterprise:
          '현재 엔터프라이즈 플랜을 이용 중이에요. 무제한 매니저 초대 기능으로 다른 담당자와 함께할 수 있어요.',
        campus:
          '현재 캠퍼스 플랜을 이용 중이에요. 무제한 매니저 초대 기능으로 학생회 임원과 함께할 수 있어요.',
      },
      buttonText: '플랜보기',
    },
    point: {
      description: '포인트를 충전하고 참여자들에게 투표 알림 메시지를 보내보세요.',
      buttonText: '충전하기',
    },
  },
  callout: {
    discard: [
      '일시정지/종료 시점으로부터 14일 동안 개표하지 않으면 자동으로 무효처리됩니다.',
      '개인정보 자동 파기 기능을 제공하고 있어요.',
      '개인정보 파기',
    ],
  },
};
