import { Plan } from '@pickme/core';

export type Period = {
  startDate: string;
  endDate: string;
};

export type BasicFormBody = {
  type: 'Election' | 'Survey';
  name: string;
  voterType: 'voterBook';
  periods: Period[];
  description: string;
  subscriptionType: Plan;
  coverImageFileUrl?: string;
  isPublic: boolean;
};

export type SubElectionsFormBody = {
  subElections: SubElectionFormBody[];
};

export type QuestionsFormBody = {
  questions: QuestionFormBody[];
};

export type ElectionKind =
  | 'ChoiceElection'
  | 'ScoreElection'
  | 'ProsOrConsElection'
  | 'RankElection';

export type SubElectionCommonPart = {
  kind: ElectionKind;
  title: string;
  shuffle: boolean;
  candidates: CandidateFormBody[];
};

export type SubElectionFormBody = SubElectionCommonPart & {
  responseValidation: {
    allowAbstentionVote: boolean;
    minScore?: number;
    maxScore?: number;
    minResponse?: number;
    maxResponse?: number;
    rankLimit?: number;
  };
};

export type CandidateFormBody = {
  title: string;
  tags?: string[];
  members: {
    fileUrl?: string;
    name: string;
  }[];
  description?: string;
  introductionVideoUrl?: string;
};

export type VoterFormBody = {
  hasInstantVoter?: boolean;
  voterBook: VoterBody[];
  incorrectVoters: IncorrectVoter[];
};

export type VoterBody = {
  name: string;
  userId?: string;
  phoneNumber?: string;
  email?: string;
  category?: string;
  // TODO: 임시적으로 사용하는 field 입니다.
  // 로직 변경 할 것.
  hasVoted?: boolean;
};

export type IncorrectVoter = {
  name: string;
  userId?: string;
  phoneNumber?: string;
  email?: string;
  category?: string;
  message: string;
  wrongFields: string[];
};

export enum VoterAuthMethod {
  Email = 'email',
  Phone = 'phoneNumber',
}

export type AdditionalFormBody = {
  voterAuthMethods: VoterAuthMethod[];
  allowRealTimeResult?: boolean;
  openThreshold?: number;
  isOpenPollRate?: boolean;
  isOpenPollResult?: boolean;
  hasOpenVotes?: boolean;
};

export type ElectionFormBody = BasicFormBody &
  SubElectionsFormBody &
  VoterFormBody &
  AdditionalFormBody;

export type ElectionFormBodyForEdit = Partial<ElectionFormBody>;

export type PollFormStep = 0 | 1 | 2 | 3;

export type SurveyFormBody = BasicFormBody & QuestionsFormBody & VoterFormBody & AdditionalFormBody;

export type SurveyFormBodyForEdit = Partial<SurveyFormBody>;

export type QuestionFormBody = {
  kind: SurveyKind;
  title: string;
  description: string;
  fileUrl?: string;
  videoUrl?: {
    youtube?: string;
  };
  isRequired: boolean;
  shuffle?: boolean;
  selections?: {
    number: number;
    fileUrl?: string;
    description?: string;
    videoUrl?: {
      youtube?: string;
    };
  }[];
  subQuestions?: {
    fileUrl?: string;
    description?: string;
    videoUrl?: {
      youtube?: string;
    };
  }[];
  min?: {
    description: string;
    value: number;
  };
  max?: {
    description: string;
    value: number;
  };
  responseValidation?: {
    threshold?: number;
    validation?: 'gt' | 'lt' | 'lte' | 'eq';
    kind?: 'AnswerLengthValidation';
    rankLimit?: number;
    minScore?: number;
    maxScore?: number;
  };
};

export type SurveyKind =
  | 'RadioQuestion'
  | 'CheckboxQuestion'
  | 'TextQuestion'
  | 'RankQuestion'
  | 'ScoreQuestion'
  | 'ScaleQuestion';
