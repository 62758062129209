import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Text, Input, Modal, ModalActions, ModalBody, ModalHeader } from '@pickme/ui';
import { t } from 'i18next';

import FieldTitle from 'components/features/application/label/old_FieldTitle';

import { VoterBody } from 'types/application';
import { phoneNumberValidator } from 'functions/validator/voter';
import { emailValidator } from 'functions/validator/auth';

type Props = {
  isVisible: boolean;
  initialValue?: VoterBody;
  isDisabled: boolean;
  wrongFields?: string[];
  onClose: () => void;
  onSubmitVoter: (voter: VoterBody) => void;
};

const defaultValues = {
  name: '',
  userId: '',
  category: '',
  email: '',
  phoneNumber: '',
};

function AddSingleVoterModal({
  isVisible,
  isDisabled,
  initialValue,
  wrongFields,
  onClose,
  onSubmitVoter,
}: Props) {
  const [type, setType] = useState<'add' | 'edit'>('add');
  const methods = useForm<VoterBody>({
    mode: 'onBlur',
    defaultValues,
  });
  const { register, handleSubmit, formState, reset, watch, setError } = methods;

  const onSubmit = (body: VoterBody) => {
    const parsedBody: VoterBody = { ...body };
    if (body.phoneNumber) {
      parsedBody.phoneNumber = body.phoneNumber.replaceAll('-', '');
    }

    onSubmitVoter(parsedBody);
  };

  useEffect(() => {
    if (isVisible) {
      if (initialValue) {
        setType('edit');
        reset({
          name: initialValue.name || defaultValues.name,
          category: initialValue.category || defaultValues.category,
          email: initialValue.email || defaultValues.email,
          phoneNumber: initialValue.phoneNumber || defaultValues.phoneNumber,
          userId: initialValue.userId || defaultValues.userId,
        });
      } else {
        setType('add');
        reset(defaultValues);
      }
    }
  }, [isVisible, initialValue]);

  useEffect(() => {
    if (wrongFields) {
      wrongFields.forEach((field) => {
        setError(field as keyof VoterBody, { type: 'onBlur', message: '' }, { shouldFocus: true });
      });
    }
  }, [`${wrongFields}`]);

  return (
    <Modal isVisible={isVisible} onClose={onClose} size='lg' disableToCloseOnBackdrop>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            title={
              type === 'edit'
                ? t(
                    'admin:components.features.application.form.voter.modals.addSingleVoter.title.edit',
                  )
                : t(
                    'admin:components.features.application.form.voter.modals.addSingleVoter.title.register',
                  )
            }
            onClose={onClose}
          />

          <ModalBody>
            <div className='voter-form__description'>
              <Text type='b3' fontWeight={400} color='gray-400'>
                {t(
                  'admin:components.features.application.form.voter.modals.addSingleVoter.description',
                )}
              </Text>
            </div>

            <div className='voter-form__field-group'>
              <FieldTitle
                title={t(
                  'admin:components.features.application.form.voter.modals.addSingleVoter.fields.name.label',
                )}
                isRequired
                fontWeight={600}
              />
              <Input
                width='100%'
                size='md'
                placeholder={t(
                  'admin:components.features.application.form.voter.modals.addSingleVoter.fields.name.placeholder',
                )}
                isValid={!formState.errors.name}
                {...register('name', { required: true })}
              />
            </div>

            <div className='voter-form__field-group'>
              <FieldTitle
                title={t(
                  'admin:components.features.application.form.voter.modals.addSingleVoter.fields.userId.label',
                )}
                tooltip={t(
                  'admin:components.features.application.form.voter.modals.addSingleVoter.fields.userId.tooltip',
                )}
                fontWeight={600}
              />
              <Input
                width='100%'
                size='md'
                placeholder={t(
                  'admin:components.features.application.form.voter.modals.addSingleVoter.fields.userId.placeholder',
                )}
                isValid={!formState.errors.userId}
                {...register('userId')}
              />
            </div>

            <div className='voter-form__field-group'>
              <FieldTitle
                title={t(
                  'admin:components.features.application.form.voter.modals.addSingleVoter.fields.phoneNumber.label',
                )}
                fontWeight={600}
              />
              <Input
                width='100%'
                size='md'
                placeholder={t(
                  'admin:components.features.application.form.voter.modals.addSingleVoter.fields.phoneNumber.placeholder',
                )}
                isValid={!formState.errors.phoneNumber}
                {...register('phoneNumber', {
                  setValueAs: (value) => value?.trim() || undefined,
                  validate: (value) =>
                    (!!value || !!watch('email')) &&
                    (!value || (value && phoneNumberValidator(value))),
                })}
              />
            </div>

            <div className='voter-form__field-group'>
              <FieldTitle
                title={t(
                  'admin:components.features.application.form.voter.modals.addSingleVoter.fields.email.label',
                )}
                fontWeight={600}
              />
              <Input
                width='100%'
                size='md'
                placeholder={t(
                  'admin:components.features.application.form.voter.modals.addSingleVoter.fields.email.placeholder',
                )}
                isValid={!formState.errors.email}
                {...register('email', {
                  setValueAs: (value) => value?.trim().toLowerCase() || undefined,
                  validate: (value) =>
                    (!!value || !!watch('phoneNumber')) &&
                    (!value || (value && emailValidator(value))),
                })}
              />
            </div>

            <div className='voter-form__field-group'>
              <FieldTitle
                title={t(
                  'admin:components.features.application.form.voter.modals.addSingleVoter.fields.category.label',
                )}
                tooltip={t(
                  'admin:components.features.application.form.voter.modals.addSingleVoter.fields.category.tooltip',
                )}
                fontWeight={600}
              />
              <Input
                width='100%'
                size='md'
                {...register('category', {
                  setValueAs: (value) => value?.trim() || undefined,
                })}
              />
            </div>
          </ModalBody>

          <ModalActions
            buttons={[
              {
                text: t(
                  'admin:components.features.application.form.voter.modals.addSingleVoter.actions.cancel',
                ),
                onClick: onClose,
                variant: 'gray',
              },
              {
                text: initialValue
                  ? t(
                      'admin:components.features.application.form.voter.modals.addSingleVoter.actions.submit.edit',
                    )
                  : t(
                      'admin:components.features.application.form.voter.modals.addSingleVoter.actions.submit.register',
                    ),
                disabled: !formState.isValid || isDisabled,
                type: 'submit',
              },
            ]}
          />
        </form>
      </FormProvider>
    </Modal>
  );
}

export default AddSingleVoterModal;
