import { DraftTemplate, DraftTemplateCategory } from 'types/template';

import Thumbnail1 from 'resources/images/templates/survey/contest1.png';
import Thumbnail2 from 'resources/images/templates/survey/contest2.png';
import Thumbnail3 from 'resources/images/templates/survey/contest3.png';

const DRAFT_1 = {
  title: '공모전 주제 선정 설문조사',
  _id: '65d6de3d668a8b01586b9230',
  content: {
    subscriptionType: 'free',
    name: '공모전 주제 선정 설문조사',
    description:
      '<p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">안녕하세요!</span></p><p class="editor-paragraph"><br></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">이번 공모전의 주제에 대한 의견을 수집하는 설문입니다.</span></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">많은 분이 즐겁게 참여할 수 있는 공모전이 될 수 있도록 주제 선정에 대해 의견을 나눠주세요!</span></p><p class="editor-paragraph"><br></p>',
    hasOpenVotes: true,
    questions: [
      {
        responseValidation: {
          validation: 'eq',
          threshold: 2,
        },
        videoUrl: {
          youtube: '',
        },
        isRequired: true,
        kind: 'CheckboxQuestion',
        _id: '65d6de3d668a8b01586b9231',
        title: '가장 흥미로운 공모전 종류를 2가지 선택해주세요',
        description: '',
        selections: [
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65d6de3d668a8b01586b9232',
            number: 1,
            description: '아이디어 공모전',
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65d6de3d668a8b01586b9233',
            description: '디자인 공모전',
            number: 2,
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65d6de3d668a8b01586b9234',
            description: '창업 아이디어 공모전',
            number: 3,
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65d6de3d668a8b01586b9235',
            description: '영상 콘텐츠 공모전',
            number: 4,
            fileUrl: '',
          },
        ],
        fileUrl: '',
        shuffle: true,
      },
      {
        videoUrl: {
          youtube: '',
        },
        isRequired: false,
        kind: 'TextQuestion',
        _id: '65d6de3d668a8b01586b9236',
        title: '희망하는 공모전 주제를 제안해주세요',
        description: '',
        responseValidation: {
          _id: '65d6de3d668a8b01586b9237',
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
      },
      {
        videoUrl: {
          youtube: '',
        },
        isRequired: false,
        kind: 'TextQuestion',
        _id: '65d6de3d668a8b01586b9238',
        title: '공모전 주제를 제안해주신 이유를 설명해주세요',
        description: '',
        responseValidation: {
          _id: '65d6de3d668a8b01586b9239',
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    hasInstantVoter: false,
    allowRealTimeResult: false,
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
    voterAuthMethods: ['email', 'phoneNumber'],
  },
  adminId: '645c43457371bd01778adb18',
  type: 'survey',
  createdAt: '2024-02-26T08:13:25.130Z',
  __v: 0,
};

const DRAFT_2 = {
  title: '창업 아이디어 공모전 투표',
  _id: '65dd62e0c3d997008bfa7649',
  content: {
    name: '창업 아이디어 공모전 투표',
    periods: [
      {
        startDate: '2024-02-27T06:15:00.000Z',
        endDate: '2024-02-29T04:15:00.000Z',
      },
    ],
    description:
      '<p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">안녕하세요!</span></p><p class="editor-paragraph"><br></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">창업 아이디어 공모전의 최종 선정을 위해 투표를 실시합니다.</span></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">아래에는 제출된 창업 아이디어 목록이 있습니다.</span></p><p class="editor-paragraph"><br></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">최고의 창업 아이디어에 투표해 주세요!</span></p><p class="editor-paragraph"><br></p>',
    type: 'Survey',
    voterType: 'voterBook',
    hasOpenVotes: false,
    subscriptionType: 'free',
    questions: [
      {
        responseValidation: {
          minScore: 1,
          maxScore: 10,
        },
        videoUrl: {
          youtube: '',
        },
        isRequired: true,
        kind: 'ScoreQuestion',
        _id: '65dd62e0c3d997008bfa764a',
        title: '추진 계획의 구체성',
        description: '',
        subQuestions: [
          {
            _id: '65dd62e0c3d997008bfa764b',
            description: '무인 매장 및 결제 플랫폼 구축',
            fileUrl: '',
          },
          {
            _id: '65dd62e0c3d997008bfa764c',
            description: '지능형 건강 관리 및 모니터링 앱 서비스',
            fileUrl: '',
          },
          {
            _id: '65dd62e0c3d997008bfa764d',
            description: '환경 친화적인 배달 로봇 서비스 개발',
            fileUrl: '',
          },
          {
            _id: '65dd62e0c3d997008bfa764e',
            description: '디지털 라이브러리를 위한 AI 기반 도서 추천 플랫폼',
            fileUrl: '',
          },
        ],
        fileUrl: '',
      },
      {
        responseValidation: {
          minScore: 1,
          maxScore: 10,
        },
        videoUrl: {
          youtube: '',
        },
        isRequired: true,
        kind: 'ScoreQuestion',
        _id: '65dd62e0c3d997008bfa764f',
        title: '창업 아이디어의 차별성 및 독창성',
        description: '',
        subQuestions: [
          {
            _id: '65dd62e0c3d997008bfa7650',
            description: '무인 매장 및 결제 플랫폼 구축',
            fileUrl: '',
          },
          {
            _id: '65dd62e0c3d997008bfa7651',
            description: '지능형 건강 관리 및 모니터링 앱 서비스',
            fileUrl: '',
          },
          {
            _id: '65dd62e0c3d997008bfa7652',
            description: '환경 친화적인 배달 로봇 서비스 개발',
            fileUrl: '',
          },
          {
            _id: '65dd62e0c3d997008bfa7653',
            description: '디지털 라이브러리를 위한 AI 기반 도서 추천 플랫폼',
            fileUrl: '',
          },
        ],
        fileUrl: '',
      },
      {
        responseValidation: {
          minScore: 1,
          maxScore: 10,
        },
        videoUrl: {
          youtube: '',
        },
        isRequired: true,
        kind: 'ScoreQuestion',
        _id: '65dd62e0c3d997008bfa7654',
        title: '기술의 구현(실현)가능성 및 우수성',
        description: '',
        subQuestions: [
          {
            _id: '65dd62e0c3d997008bfa7655',
            description: '무인 매장 및 결제 플랫폼 구축',
            fileUrl: '',
          },
          {
            _id: '65dd62e0c3d997008bfa7656',
            description: '지능형 건강 관리 및 모니터링 앱 서비스',
            fileUrl: '',
          },
          {
            _id: '65dd62e0c3d997008bfa7657',
            description: '환경 친화적인 배달 로봇 서비스 개발',
            fileUrl: '',
          },
          {
            _id: '65dd62e0c3d997008bfa7658',
            description: '디지털 라이브러리를 위한 AI 기반 도서 추천 플랫폼',
            fileUrl: '',
          },
        ],
        fileUrl: '',
      },
      {
        responseValidation: {
          minScore: 1,
          maxScore: 10,
        },
        videoUrl: {
          youtube: '',
        },
        isRequired: true,
        kind: 'ScoreQuestion',
        _id: '65dd62e0c3d997008bfa7659',
        title: '매출(수출) 발생 가능성',
        description: '',
        subQuestions: [
          {
            _id: '65dd62e0c3d997008bfa765a',
            description: '무인 매장 및 결제 플랫폼 구축',
            fileUrl: '',
          },
          {
            _id: '65dd62e0c3d997008bfa765b',
            description: '지능형 건강 관리 및 모니터링 앱 서비스',
            fileUrl: '',
          },
          {
            _id: '65dd62e0c3d997008bfa765c',
            description: '환경 친화적인 배달 로봇 서비스 개발',
            fileUrl: '',
          },
          {
            _id: '65dd62e0c3d997008bfa765d',
            description: '디지털 라이브러리를 위한 AI 기반 도서 추천 플랫폼',
            fileUrl: '',
          },
        ],
        fileUrl: '',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    hasInstantVoter: false,
    allowRealTimeResult: false,
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
    voterAuthMethods: ['email', 'phoneNumber'],
  },
  adminId: '645c43457371bd01778adb18',
  type: 'survey',
  createdAt: '2024-02-26T08:13:25.130Z',
  __v: 0,
};

const DRAFT_3 = {
  _id: '65dc5267c3d997008bfa2c3a',
  title: '공모전 만족도 조사',
  content: {
    name: '공모전 만족도 조사',
    periods: [
      {
        startDate: '2024-02-26T10:06:00.000Z',
        endDate: '2024-02-27T08:07:00.000Z',
      },
    ],
    description:
      '<p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">안녕하세요!</span></p><p class="editor-paragraph"><br></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">이번 공모전에 대한 설문조사를 진행하고 있습니다.</span></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">다음 공모전이 개선 될 수 있도록 이번 공모전에 대한 의견을 나눠주세요!</span></p><p class="editor-paragraph"><br></p>',
    type: 'Survey',
    voterType: 'voterBook',
    hasOpenVotes: false,
    subscriptionType: 'free',
    questions: [
      {
        videoUrl: {
          youtube: '',
        },
        isRequired: true,
        kind: 'RadioQuestion',
        _id: '65dc5267c3d997008bfa2c3b',
        title: '이번 공모전에 참여하게 된 이유는 무엇인가요?',
        description: '',
        selections: [
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dc5267c3d997008bfa2c3c',
            number: 1,
            description: '참가 상금',
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dc5267c3d997008bfa2c3d',
            description: '관심 있는 주제',
            number: 2,
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dc5267c3d997008bfa2c3e',
            description: '경험과 포트폴리오 구축',
            number: 3,
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dc5267c3d997008bfa2c3f',
            description: '기타',
            number: 4,
            fileUrl: '',
          },
        ],
        fileUrl: '',
        shuffle: true,
      },
      {
        videoUrl: {
          youtube: '',
        },
        isRequired: false,
        kind: 'TextQuestion',
        _id: '65dc5267c3d997008bfa2c40',
        title: "위의 질문에 '기타'로 응답한 경우 참여 이유를 작성해주세요",
        description: '',
        responseValidation: {
          _id: '65dc5267c3d997008bfa2c41',
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
      },
      {
        videoUrl: {
          youtube: '',
        },
        isRequired: false,
        kind: 'TextQuestion',
        _id: '65dc5267c3d997008bfa2c44',
        title: '공모전 진행 과정에서 가장 만족스러웠던 부분은 무엇인가요?',
        description: '',
        responseValidation: {
          _id: '65dc5267c3d997008bfa2c45',
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
      },
      {
        videoUrl: {
          youtube: '',
        },
        isRequired: false,
        kind: 'TextQuestion',
        _id: '65dc5267c3d997008bfa2c42',
        title: '공모전 진행 과정에서 개선이 필요하다고 생각되는 부분은 무엇인가요?',
        description: '',
        responseValidation: {
          _id: '65dc5267c3d997008bfa2c43',
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    hasInstantVoter: false,
    allowRealTimeResult: false,
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
    voterAuthMethods: ['email', 'phoneNumber'],
  },
  adminId: '645c43457371bd01778adb18',
  type: 'survey',
  createdAt: '2024-02-26T08:13:25.130Z',
  __v: 0,
};

export default [
  {
    title: DRAFT_1.title,
    description: `${DRAFT_1.title}를 위한 템플릿입니다.`,
    thumbnail: Thumbnail1,
    draft: DRAFT_1,
    category: DraftTemplateCategory.Contest,
    kind: 'Survey',
  },
  {
    title: DRAFT_2.title,
    description: `${DRAFT_2.title}를 위한 템플릿입니다.`,
    thumbnail: Thumbnail2,
    draft: DRAFT_2,
    category: DraftTemplateCategory.Contest,
    kind: 'Survey',
  },
  {
    title: DRAFT_3.title,
    description: `${DRAFT_3.title}를 위한 템플릿입니다.`,
    thumbnail: Thumbnail3,
    draft: DRAFT_3,
    category: DraftTemplateCategory.Contest,
    kind: 'Survey',
  },
] as DraftTemplate[];
