import { Modal, ModalBody, ModalHeader, Terms } from '@pickme/ui';
import { t } from 'i18next';

import type { TermKind } from '@pickme/ui';

import './index.scss';

type Props = { isVisible: boolean; kind: TermKind; onClose: () => void };

function TermModal({ isVisible, kind, onClose }: Props) {
  const termTitle =
    (kind === 'service' && t('admin:components.features.auth.modal.term.title.service')) ||
    (kind === 'privacy' && t('admin:components.features.auth.modal.term.title.privacy')) ||
    (kind === 'marketing' && t('admin:components.features.auth.modal.term.title.marketing')) ||
    '';

  return (
    <Modal isVisible={isVisible} onClose={onClose} className='term-modal'>
      <ModalHeader title={termTitle} onClose={onClose} />

      <ModalBody>
        <div className='term-modal__terms'>
          <Terms kind={kind} />
        </div>
      </ModalBody>
    </Modal>
  );
}

export default TermModal;
