import { isUndefined } from 'lodash-es';
import { useState } from 'react';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { Button } from '@pickme/ui';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { t } from 'i18next';

import FieldTitleWithButton from 'components/features/application/label/FieldTitleWithButton';
import QuestionForm from 'components/features/application/form/Questions/Question';
import QuestionCard from 'components/features/application/card/Question';
import SurveyKindSelect from 'components/features/application/modal/SurveyKindSelect';

import { usePollStarted } from 'hooks/poll/usePollData';

import { toast } from 'states/toast';

import { QuestionFormBody, QuestionsFormBody, SurveyKind } from 'types/application';

type Props = {
  onSubmit: (data: QuestionsFormBody) => void;
};

const defaultValues = {
  kind: 'RadioQuestion' as SurveyKind,
  title: '',
  description: '',
  isRequired: false,
};

function QuestionsForm({ onSubmit }: Props) {
  const [isSurveyKindSelectorVisible, setSurveyKindSelectorVisible] = useState(false);

  const isPollStarted = usePollStarted();
  const setToast = useSetRecoilState(toast);
  const methods = useFormContext<QuestionsFormBody>();
  const [questionFormModal, setQuestionFormModal] = useState<{
    isVisible: boolean;
    selectedIndex?: number;
    question?: QuestionFormBody;
  }>({ isVisible: false });

  const { fields, append, swap, update, remove } = useFieldArray({
    control: methods.control,
    name: 'questions',
    rules: {
      validate: (elections) => elections.length > 0,
    },
  });

  const questionForm = useForm<QuestionFormBody>({
    mode: 'onBlur',
    defaultValues,
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 8,
        distance: 8,
      },
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const selectedIndex = active?.data?.current?.sortable.index;
      const changedIndex = over?.data?.current?.sortable.index;

      swap(selectedIndex, changedIndex);
    }
  };

  const onError = () => {
    setToast({
      isVisible: true,
      type: 'error',
      message: t('admin:components.features.application.form.question.errorMessages.required'),
    });
  };

  const onSelectSurveyKind = (kind: SurveyKind) => {
    setQuestionFormModal({ isVisible: true });
    questionForm.reset({
      kind: 'RadioQuestion' as SurveyKind,
      title: '',
      description: '',
      isRequired: false,
    });
    questionForm.setValue('kind', kind);

    setSurveyKindSelectorVisible(false);
  };

  return (
    <div>
      <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
        <div className='application-form'>
          <FieldTitleWithButton
            title={t('admin:components.features.application.form.question.title')}
            description={t('admin:components.features.application.form.question.description')}
            button={{
              text: t('admin:components.features.application.form.question.buttons.add'),
              disabled: isPollStarted,
              onClick: () => setSurveyKindSelectorVisible(true),
            }}
          />

          <div className='application-form__poll-cards'>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={fields.map((field) => field.id)}
                strategy={verticalListSortingStrategy}
                disabled={isPollStarted}
              >
                {fields.map((field, index) => (
                  <QuestionCard
                    id={field.id}
                    key={field.id}
                    question={field}
                    disabled={isPollStarted}
                    onRemove={() => remove(index)}
                    onClick={() => {
                      setQuestionFormModal({
                        isVisible: true,
                        selectedIndex: index,
                        question: field,
                      });
                    }}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </div>

          <Button width='100%' size='lg' variant='primary' type='submit'>
            {t('admin:components.features.application.form.question.buttons.submit')}
          </Button>
        </div>
      </form>

      {questionFormModal.isVisible && (
        <FormProvider {...questionForm}>
          <QuestionForm
            disabled={isPollStarted}
            initialValue={questionFormModal.question}
            onQuestionSubmit={(question) => {
              if (isUndefined(questionFormModal?.selectedIndex)) {
                append(question);
              } else {
                update(questionFormModal.selectedIndex, question);
              }

              setQuestionFormModal({ isVisible: false });
            }}
            onClose={() => setQuestionFormModal({ isVisible: false })}
          />
        </FormProvider>
      )}

      <SurveyKindSelect
        isVisible={isSurveyKindSelectorVisible}
        onClose={() => setSurveyKindSelectorVisible(false)}
        onSubmit={(kind) => onSelectSurveyKind(kind)}
      />
    </div>
  );
}

export default QuestionsForm;
