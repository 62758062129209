import { Text } from '@pickme/ui';

function IncomingDetailRow({ title, content }: { title: string; content: string }) {
  return (
    <div className='incoming-detail-modal__row' key={title}>
      <div className='incoming-detail-modal__row__title'>
        <Text type='b3' fontWeight={400}>
          {title}
        </Text>
      </div>

      <div className='incoming-detail-modal__row__content'>
        <Text type='b3' fontWeight={400}>
          {content}
        </Text>
      </div>
    </div>
  );
}

export default IncomingDetailRow;
