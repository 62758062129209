import { DraftTemplate, DraftTemplateCategory } from 'types/template';

import Thumbnail1 from 'resources/images/templates/survey/apartment1.png';
import Thumbnail2 from 'resources/images/templates/survey/apartment2.png';

const DRAFT_1 = {
  title: '아파트 안건 찬반 투표',
  _id: '64993c2fa5f0ec00830becdd',
  content: {
    name: '아파트 안건 찬반 투표',
    periods: [
      {
        startDate: '2023-06-26T17:59',
        endDate: '2023-06-30T16:12',
      },
    ],
    description:
      '<p>이번 달 정기 입주자 대표회의 안건과 함께 찬반 투표 안내드립니다.</p><p>입주민 여러분들의 소중한 한 표 부탁드립니다.</p><p>감사합니다.</p>',
    type: 'Survey',
    voterType: 'voterBook',
    hasOpenVotes: false,
    subscriptionType: 'enterprise',
    questions: [
      {
        kind: 'RadioQuestion',
        title: '안건 1. 아파트내 무인택배함 설치 ',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '찬성',
            fileUrl: '',
          },
          {
            description: '반대',
            number: 2,
            fileUrl: '',
          },
          {
            description: '기권',
            number: 3,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'RadioQuestion',
        title: '안건 2. 지하주차장 천장 누수 공사업체 변경',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '찬성',
            fileUrl: '',
          },
          {
            description: '반대',
            number: 2,
            fileUrl: '',
          },
          {
            description: '기권',
            number: 3,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'RadioQuestion',
        title: '안건 3. GX룸 및 프로그램 다양화',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '찬성',
            fileUrl: '',
          },
          {
            description: '반대',
            number: 2,
            fileUrl: '',
          },
          {
            description: '기권',
            number: 3,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'TextQuestion',
        title: '건의 사항 및 의견이 있으면 자유롭게 적어주세요.',
        description: '',
        isRequired: false,
        selections: [],
        subQuestions: [],
        responseValidation: {
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-06-26T07:20:15.302Z',
  __v: 0,
};

const DRAFT_2 = {
  title: '아파트 관리 규약 개정 찬반 투표',
  _id: '64acc07b8d7a890048b05e51',
  content: {
    name: '아파트 관리 규약 개정 찬반 투표',
    periods: [
      {
        startDate: '2023-07-11T12:40',
        endDate: '2023-07-22T11:06',
      },
    ],
    description:
      '<p>안녕하세요. 입주민 여러분.</p><p>아파트 관리 규약 개정 찬반 투표 안내드립니다.</p><p><br></p><p>공동주택관리법 준칙안 개정 사항에 따라 입주민 여러분의 동의를 구하고자 합니다.</p><p>많은 관심과 참여 부탁드립니다.</p>',
    type: 'Survey',
    voterType: 'voterBook',
    hasOpenVotes: false,
    subscriptionType: 'enterprise',
    questions: [
      {
        kind: 'RadioQuestion',
        title: '도로명 주소 적용 (제2조 개정)',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '찬성',
            fileUrl: '',
          },
          {
            description: '반대',
            number: 2,
            fileUrl: '',
          },
          {
            description: '기원',
            number: 3,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
        id: 'ce4e2791-5f9e-4a92-a57f-fbd2368f59b6',
      },
      {
        kind: 'RadioQuestion',
        title: '입주자등의 자격 구체화 (제9조 제1항 개정)',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '찬성',
            fileUrl: '',
          },
          {
            description: '반대',
            number: 2,
            fileUrl: '',
          },
          {
            description: '기권',
            number: 3,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
        id: '2f160229-d912-489e-905b-685fbb309269',
      },
      {
        kind: 'RadioQuestion',
        title: '동별 대표자 임기제한 규정 주택법 개정 추진 사항 반영 (제18조 개정)',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '찬성',
            fileUrl: '',
          },
          {
            description: '반대',
            number: 2,
            fileUrl: '',
          },
          {
            description: '기권',
            number: 3,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
        id: '73c3f755-fa40-42c6-bf53-bd03bfdf2c12',
      },
      {
        kind: 'RadioQuestion',
        title: '입주자대표회의 회장 및 감사의 간선제 규정 (제19조의2 신설)',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '찬성',
            fileUrl: '',
          },
          {
            description: '반대',
            number: 2,
            fileUrl: '',
          },
          {
            description: '기권',
            number: 3,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-07-11T02:37:47.678Z',
  __v: 0,
};

export default [
  {
    title: '아파트 안건 찬반 투표',
    description: '아파트 안건 찬반 투표를 위한 템플릿입니다.',
    thumbnail: Thumbnail1,
    draft: DRAFT_1,
    category: DraftTemplateCategory.Apartment,
    kind: 'Survey',
  },
  {
    title: '아파트 관리 규약 개정 찬반 투표',
    description: '아파트 관리 규약 개정 찬반 투표를 위한 템플릿입니다.',
    thumbnail: Thumbnail2,
    draft: DRAFT_2,
    category: DraftTemplateCategory.Apartment,
    kind: 'Survey',
  },
] as DraftTemplate[];
