export default {
  title: '소유자 변경',
  message: '누구에게 해당 스페이스를 이전하시겠어요?',
  manual: {
    placeholder: '이메일을 입력해주세요',
    errorMessage: '올바른 이메일을 입력해주세요',
  },
  buttons: {
    cancel: '취소',
    next: '다음',
  },
};
