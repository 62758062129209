import pointSummary from './cards/pointSummary';

import cancel from './modal/cancel';
import discardSubscription from './modal/discardSubscription';
import incomingDetail from './modal/incomingDetail';
import paymentRecord from './modal/paymentRecord';
import pointBill from './modal/pointBill';
import replaceEmail from './modal/replaceEmail';
import purchaseMoreVoters from './modal/purchaseMoreVoters';
import transferPoint from './modal/transferPoint';
import transferPointConfirm from './modal/transferPointConfirm';

export default {
  modal: {
    cancel,
    discardSubscription,
    incomingDetail,
    paymentRecord,
    pointBill,
    replaceEmail,
    purchaseMoreVoters,
    transferPoint,
    transferPointConfirm,
  },
  cards: {
    pointSummary,
  },
};
