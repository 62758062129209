import dayjs from 'dayjs';
import { useFormContext, useWatch } from 'react-hook-form';
import { Text } from '@pickme/design-system';
import { Viewer } from '@pickme/ui';

import { MESSAGING_DEFAULT_SENDER } from 'constants/notification';

import { LmsForm } from 'types/message';

import LmsArrow from 'resources/icons/notification/lms-arrow.svg';

import { date, message, profile } from './index.css';

function LmsPreview() {
  const methods = useFormContext<LmsForm>();
  const text = useWatch({ control: methods.control, name: 'template' });

  return (
    <div>
      <Text size={14} className={profile}>
        {MESSAGING_DEFAULT_SENDER.lms}

        <img src={LmsArrow} alt='arrow' />
      </Text>

      <Text size={14} color='gray-500' className={date}>
        {dayjs().format('YYYY년 M월 D일 A h:mm')}
      </Text>

      <Viewer className={message} contents={text} contentsType='html' />
    </div>
  );
}

export default LmsPreview;
