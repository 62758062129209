import {
  Question,
  Survey,
  QuestionKind,
  ChoiceQuestion,
  ImageChoiceQuestion,
  VideoChoiceQuestion,
  DropdownQuestion,
  ScoreQuestion,
  RankQuestion,
  ScaleQuestion,
  StarRatingQuestion,
  StarRatingUnit,
  TextQuestion,
  PrivacyPolicyQuestion,
} from '@pickme/core';
import { v4 as uuid } from 'uuid';

import { PollApplicationAdapter } from 'models/application-v2/adapters/PollApplicationAdapter';
import { VoterBook } from 'models/poll-v2/Voterbook';

import {
  SurveyApplicationForms,
  SurveyForm,
  Question as QuestionFields,
  SurveyTypeCase,
  Answer,
} from 'types/application-v2';

const QUESTION_TYPE_MAPPER: { [key in QuestionKind]: SurveyTypeCase } = {
  [QuestionKind.객관식]: SurveyTypeCase.객관식,
  [QuestionKind.이미지]: SurveyTypeCase.이미지선택,
  [QuestionKind.동영상]: SurveyTypeCase.동영상선택,
  [QuestionKind.주관식]: SurveyTypeCase.주관식,
  [QuestionKind.이메일]: SurveyTypeCase.이메일주소,
  [QuestionKind.휴대폰번호]: SurveyTypeCase.휴대폰번호,
  [QuestionKind.날짜]: SurveyTypeCase.날짜,
  [QuestionKind.시간]: SurveyTypeCase.시간,
  [QuestionKind.링크]: SurveyTypeCase.링크,
  [QuestionKind.개인정보수집]: SurveyTypeCase.개인정보수집,
  [QuestionKind.선호도]: SurveyTypeCase.선호도평가,
  [QuestionKind.선형배율]: SurveyTypeCase.선형배율,
  [QuestionKind.드롭다운]: SurveyTypeCase.드롭다운,
  [QuestionKind.점수]: SurveyTypeCase.점수평가,
  [QuestionKind.별점]: SurveyTypeCase.별점,
};
export class SurveyApplicationAdapter extends PollApplicationAdapter {
  private _survey: Survey;

  constructor(survey: Survey, voterBook: VoterBook | null) {
    super(survey, voterBook);
    this._survey = survey;
  }

  static setMultipleSelectionValues(question: Question): {
    allowMultipleSelectionEnabled: boolean;
    allowMultipleSelectionMinValue: number;
    allowMultipleSelectionMaxValue: number;
  } {
    if (
      question instanceof ChoiceQuestion ||
      question instanceof ImageChoiceQuestion ||
      question instanceof VideoChoiceQuestion
    ) {
      return {
        allowMultipleSelectionEnabled: true,
        allowMultipleSelectionMinValue: question.isSingleChoice ? 1 : question.min,
        allowMultipleSelectionMaxValue: question.isSingleChoice ? 1 : question.max,
      };
    }

    return {
      allowMultipleSelectionEnabled: false,
      allowMultipleSelectionMinValue: 1,
      allowMultipleSelectionMaxValue: 1,
    };
  }

  static setScoreValues(question: Question): {
    minScore: number;
    maxScore: number;
  } {
    if (!(question instanceof ScoreQuestion)) {
      return {
        minScore: 0,
        maxScore: 100,
      };
    }

    return {
      minScore: question.min,
      maxScore: question.max,
    };
  }

  static setRankValues(question: Question): {
    rank: number;
    rankWeights: number[];
  } {
    if (!(question instanceof RankQuestion)) {
      return {
        rank: 1,
        rankWeights: [100],
      };
    }

    return {
      rank: question.rankLimit,
      rankWeights: question.rankWeights,
    };
  }

  static setScaleValues(question: Question): {
    scaleMinValue: number;
    scaleMinLabel: string;
    scaleMaxValue: number;
    scaleMaxLabel: string;
  } {
    if (!(question instanceof ScaleQuestion)) {
      return {
        scaleMinValue: 1,
        scaleMinLabel: '',
        scaleMaxValue: 10,
        scaleMaxLabel: '',
      };
    }

    return {
      scaleMinValue: question.min.value,
      scaleMinLabel: question.min.description || '',
      scaleMaxValue: question.max.value,
      scaleMaxLabel: question.max.description || '',
    };
  }

  static setStarValues(question: Question): {
    starTotal?: number;
    starUnit?: number;
  } {
    if (!(question instanceof StarRatingQuestion)) {
      return {
        starTotal: 5,
        starUnit: 1,
      };
    }

    return {
      starTotal: question.star,
      starUnit: question.unit.type === StarRatingUnit.One ? 1 : 0.5,
    };
  }

  static setIsLong(question: Question): {
    isLong?: boolean;
  } {
    if (!(question instanceof TextQuestion)) {
      return {
        isLong: false,
      };
    }

    return {
      isLong: question.maxLength >= 2000,
    };
  }

  static setPrivacyAgreements(question: Question): {
    privacyAgreementsItems?: string;
    privacyAgreementsPurpose?: string;
    privacyAgreementsPeriod?: string;
  } {
    if (!(question instanceof PrivacyPolicyQuestion)) {
      return {
        privacyAgreementsItems: '',
        privacyAgreementsPurpose: '',
        privacyAgreementsPeriod: '',
      };
    }

    return {
      privacyAgreementsItems: question.purposeOfCollection,
      privacyAgreementsPurpose: question.purposeOfCollection,
      privacyAgreementsPeriod: question.retentionPeriod,
    };
  }

  static setShuffle(question: Question): {
    shuffle?: boolean;
  } {
    if (
      question instanceof ChoiceQuestion ||
      question instanceof DropdownQuestion ||
      question instanceof ImageChoiceQuestion ||
      question instanceof RankQuestion ||
      question instanceof ScoreQuestion ||
      question instanceof VideoChoiceQuestion
    ) {
      return {
        shuffle: question.shuffle,
      };
    }

    return {
      shuffle: false,
    };
  }

  static setAnswers(question: Question): {
    answers: Answer[];
  } {
    if (question instanceof ChoiceQuestion) {
      return {
        answers: question.selections.map((selection) => ({
          id: uuid(),
          imageUrl: selection.fileUrl,
          value: selection.description,
        })),
      };
    }

    if (question instanceof DropdownQuestion) {
      return {
        answers: question.selections.map((selection) => ({
          id: uuid(),
          value: selection.description,
        })),
      };
    }
    if (question instanceof ImageChoiceQuestion) {
      return {
        answers: question.selections.map((selection) => ({
          id: uuid(),
          imageUrl: selection.fileUrl,
          value: selection.description,
        })),
      };
    }

    if (question instanceof VideoChoiceQuestion) {
      return {
        answers: question.selections.map((selection) => ({
          id: uuid(),
          value: selection.description,
          videoUrl: selection?.videoUrl?.youtube || '',
        })),
      };
    }

    if (question instanceof RankQuestion || question instanceof ScoreQuestion) {
      return {
        answers: question.subQuestions.map((subQuestion) => ({
          id: uuid(),
          imageUrl: subQuestion.fileUrl,
          value: subQuestion.description,
        })),
      };
    }

    return {
      answers: [],
    };
  }

  static setQuestions(questions: Question[]): QuestionFields[] {
    return questions.map((question) => {
      const multipleSelectionValues = SurveyApplicationAdapter.setMultipleSelectionValues(question);
      const scoreValues = SurveyApplicationAdapter.setScoreValues(question);
      const rankValues = SurveyApplicationAdapter.setRankValues(question);
      const scaleValues = SurveyApplicationAdapter.setScaleValues(question);
      const starValues = SurveyApplicationAdapter.setStarValues(question);
      const isLong = SurveyApplicationAdapter.setIsLong(question);
      const privacyAgreements = SurveyApplicationAdapter.setPrivacyAgreements(question);
      const shuffle = SurveyApplicationAdapter.setShuffle(question);
      const answers = SurveyApplicationAdapter.setAnswers(question);

      return {
        id: uuid(),
        title: question.title,
        description: question.description || '',
        isRequired: question.isRequired || false,
        type: QUESTION_TYPE_MAPPER[question.kind],
        ...multipleSelectionValues,
        ...scoreValues,
        ...rankValues,
        ...scaleValues,
        ...starValues,
        ...isLong,
        ...privacyAgreements,
        ...shuffle,
        ...answers,
      };
    });
  }

  getSurveyForm(): SurveyForm {
    return {
      pages: this._survey.pages.map((page) => ({
        id: uuid(),
        title: page.title || '',
        description: page.description,
        questions: SurveyApplicationAdapter.setQuestions(page.questions),
      })),
    };
  }

  getForms(): SurveyApplicationForms {
    return {
      introForm: this.getIntroForm(),
      customThemeForm: this.getCustomThemeForm(),
      endingForm: this.getEndingForm(),
      settingsForm: this.getSettingsForm(),
      voterBookForm: this.getVoterBookForm(),
      surveyForm: this.getSurveyForm(),
    };
  }
}
