import { Link, NavLink } from 'react-router-dom';
import { Text, breakpoints } from '@pickme/design-system';

import SignInForm from 'components/features/auth/form/SignInForm';
import SocialSignInButtons from 'components/features/auth/SocialSignIn';

import PCLogo from 'resources/icons/common/logo-pc.svg';
import MobileLogo from 'resources/icons/common/logo-mobile.svg';

import { container, divider, link, logo } from './index.css';

function SignIn() {
  return (
    <div className={container}>
      <Link to={import.meta.env.VITE_INTRO_URL}>
        <picture className={logo}>
          <source srcSet={MobileLogo} media={`(max-width: ${breakpoints.mobile}px)`} />
          <img src={PCLogo} alt='Pickme logo' />
        </picture>
      </Link>

      <SignInForm />

      <div className={link.container}>
        <Text color='primary-500'>
          <NavLink to='/auth/signup' unstable_viewTransition>
            회원가입
          </NavLink>
        </Text>

        <div className={link.divider} />

        <Text color='gray-400'>
          <NavLink to='/auth/reset' unstable_viewTransition>
            비밀번호 찾기
          </NavLink>
        </Text>
      </div>

      <div className={divider} />

      <SocialSignInButtons />
    </div>
  );
}

export default SignIn;
