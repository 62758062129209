/* eslint-disable prefer-destructuring */
import { SpaceResponse } from '@pickme/core';

import { CredentialAuthMethod } from 'models/auth/credential-auth-method';
import { SocialAuthMethod } from 'models/auth/social-auth-method';

import { AuthMethodResponse, AuthMethodType, ProfileAsUser, SocialProvider } from 'types/auth';

type AuthMethod = CredentialAuthMethod | SocialAuthMethod;

export class UserProfile {
  private _type: 'admin';

  private __id: string;

  private _email: string;

  private _authMethods: AuthMethod[];

  private _primaryAuthMethod: AuthMethod;

  private _name: string;

  private _channelTalkMemberHash: string;

  private _phoneNumber?: string;

  private _organizations: SpaceResponse[];

  private _verification: {
    phoneNumber: boolean;
  };

  private _notification: {
    marketingAgreements: ('email' | 'sms')[];
  };

  private _participatedPromotionOids?: string[];

  constructor(profile: ProfileAsUser) {
    this._type = profile.type;
    this.__id = profile._id;
    this._email = profile.authMethods[0].email;
    this._authMethods = profile.authMethods.map((authMethod) =>
      UserProfile.createAuthMethod(authMethod),
    );
    this._primaryAuthMethod = UserProfile.findPrimaryAuthMethod(this._authMethods);
    this._name = profile.name;
    this._channelTalkMemberHash = profile.channelTalkMemberHash;
    this._phoneNumber = profile.phoneNumber;
    this._organizations = profile.organizations;
    this._verification = profile.verification;
    this._notification = profile.notification;
    this._participatedPromotionOids = profile.participatedPromotionOids;
  }

  get type() {
    return this._type;
  }

  get _id() {
    return this.__id;
  }

  get email() {
    return this._email;
  }

  get authMethods() {
    return this._authMethods;
  }

  get primaryAuthMethod() {
    return this._primaryAuthMethod;
  }

  get name() {
    return this._name;
  }

  get channelTalkMemberHash() {
    return this._channelTalkMemberHash;
  }

  get phoneNumber() {
    return this._phoneNumber;
  }

  get organizations() {
    return this._organizations;
  }

  get verification() {
    return this._verification;
  }

  get notification() {
    return this._notification;
  }

  get participatedPromotionOids() {
    return this._participatedPromotionOids;
  }

  private static createAuthMethod(authMethodResponse: AuthMethodResponse) {
    if (authMethodResponse.type === AuthMethodType.Credentials) {
      return new CredentialAuthMethod(authMethodResponse);
    }
    return new SocialAuthMethod(authMethodResponse);
  }

  private static findPrimaryAuthMethod(authMethods: AuthMethod[]) {
    return authMethods.find((authMethod) => authMethod.isPrimary)!;
  }

  hasSocialProviderInAuthMethods(provider: SocialProvider) {
    return this._authMethods.some(
      (authMethod) => authMethod instanceof SocialAuthMethod && authMethod.provider === provider,
    );
  }
}
