import { useEffect } from 'react';
import { Text } from '@pickme/ui';
import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';

import { useQueryString } from 'hooks/useQueryString';

import './QRCode.scss';

function QRCodeViewer() {
  const navigate = useNavigate();
  const { alias, name, pollId } = useQueryString({ alias: '', name: '', pollId: '' });

  useEffect(() => {
    if (!alias || !name || !pollId) {
      navigate(-1);
    }
  }, [alias, name, pollId]);

  return (
    <div className='qrcode-viewer'>
      <Text type='h3' fontWeight={600}>
        {name}
      </Text>

      <QRCode id='qrcode' size={400} value={`https://vote.ohpick.me/poll/${pollId}`} level='H' />
    </div>
  );
}

export default QRCodeViewer;
