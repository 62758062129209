import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { Plan } from '@pickme/core';
import { t } from 'i18next';

import {
  createGetDraftRequest,
  createGetDraftsRequest,
  createRemoveDraftRequest,
  createWriteDraftRequest,
} from 'apis/draft';

import { useGetSessionAsUser } from 'query-hooks/auth';

import { toast } from 'states/toast';

import { sendDraftEvent } from 'functions/analytics/poll';

import { ElectionFormBody, SurveyFormBody } from 'types/application';
import { PollKind, PollSearchKind } from 'types/poll';
import { Draft } from 'types/draft';
import { DraftTemplateCategory } from 'types/template';

import ELECTION_TEMPLATE from 'resources/templates/election';
import SURVEY_TEMPLATE from 'resources/templates/survey';

export const useWriteDraft = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({
      form,
      kind,
    }: {
      form: ElectionFormBody | SurveyFormBody;
      kind: 'election' | 'survey';
    }) => {
      const { data } = await createWriteDraftRequest(kind, {
        ...form,
        name:
          form.name ||
          `${moment()
            .tz('Asia/Seoul')
            .format(t('admin:query-hooks.draft.useWriteDraft.format'))}${t(
            'admin:query-hooks.draft.useWriteDraft.text',
            {
              kind:
                kind === 'election'
                  ? t('admin:terms.poll.kind.election')
                  : t('admin:terms.poll.kind.survey'),
            },
          )}`,
      });

      return data;
    },
    {
      onSuccess: ({ draftId }, { kind }) => {
        sendDraftEvent({
          kind: kind === 'election' ? 'Election' : 'Survey',
        });

        queryClient.invalidateQueries('drafts');
        queryClient.invalidateQueries('draft');

        navigate(`/poll/${kind}/draft/${draftId}`, { replace: true });
        setToast({
          isVisible: true,
          type: 'success',
          message: t('admin:query-hooks.draft.useWriteDraft.onSuccess.toast'),
        });
      },
    },
  );
};

function getTemplate(id: string, name: string, email: string, phoneNumber: string) {
  const templateInfo = id.split('-');
  const kind = templateInfo?.[1];
  const category = templateInfo?.[2] as DraftTemplateCategory;
  const index = templateInfo?.[3] ? Number(templateInfo?.[3]) : 0;
  const TEMPLATE = kind === 'election' ? ELECTION_TEMPLATE : SURVEY_TEMPLATE;
  const draft = TEMPLATE?.[category]?.[index]?.draft;

  return {
    ...draft,
    content: {
      ...draft?.content,
      subscriptionType: Plan.Free,
      periods: [
        {
          startDate: moment().tz('Asia/Seoul').add(2, 'hours').format('YYYY-MM-DDTHH:mm'),
          endDate: moment()
            .tz('Asia/Seoul')
            .add(2, 'hours')
            .add(1, 'days')
            .format('YYYY-MM-DDTHH:mm'),
        },
      ],
      voterBook: [
        {
          name,
          userId: name,
          email,
          phoneNumber: phoneNumber || '',
        },
      ],
    },
  } as Draft;
}

// draft => /draft api 에서 받아오는 데이터 혹은 로컬에 저장된 읽기 전용 template
export const useGetDraft = (type: 'election' | 'survey', id: string) => {
  const { data: userSessionData } = useGetSessionAsUser({ suspense: false });
  return useQuery(
    ['draft', type, id],
    async () => {
      if (/template-(election|survey)-[a-z]+-\d/.test(id)) {
        const template = getTemplate(
          id,
          userSessionData?.name || '',
          userSessionData?.email || '',
          userSessionData?.phoneNumber || '',
        );

        return template;
      }

      const { data } = await createGetDraftRequest(type, id);

      return data;
    },
    {
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    },
  );
};

export const useGetDrafts = (kind: PollKind | 'all', page: number) =>
  useQuery(['drafts', kind, page], async () => {
    const { data } = await createGetDraftsRequest(
      kind.toLowerCase() as 'election' | 'survey' | 'all',
      page,
    );

    return data;
  });

export const useGetInfiniteDrafts = (kind: PollSearchKind) =>
  useInfiniteQuery(
    ['drafts', 'infinite', kind],
    async ({ pageParam = 1 }) => {
      const { data } = await createGetDraftsRequest(
        kind.toLowerCase() as 'election' | 'survey' | 'all',
        pageParam,
      );

      return data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.drafts.length === 0) {
          return undefined;
        }

        return allPages.length + 1;
      },
    },
  );

export const useRemoveDraft = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ id, kind }: { id: string; kind: 'election' | 'survey' }) => {
      const { data } = await createRemoveDraftRequest(kind, id);

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['drafts']);
        queryClient.invalidateQueries(['draft']);

        setToast({
          isVisible: true,
          type: 'success',
          message: t('admin:query-hooks.draft.useRemoveDraft.onSuccess.toast'),
        });
      },
    },
  );
};
