import { useState } from 'react';
import { t } from 'i18next';
import { useSetRecoilState } from 'recoil';
import { useDropzone } from 'react-dropzone';
import { Button, Text } from '@pickme/design-system';

import CoverModal from 'components/features/application/modal/Cover';

import { useUploadFile } from 'query-hooks/file';

import { toast } from 'states/toast';

import RemoveIcon from 'resources/icons/application/close.png';
import ImageIcon from 'resources/icons/application/image.svg';

import { card, container, coverImage, icon, outerContainer, removeButton } from './index.css';

type Props = {
  imageUrl: string;
  disabled?: boolean;
  onChange: (imageUrl: string) => void;
};

function ImageField({ imageUrl, disabled, onChange }: Props) {
  const [isCoverModalVisible, setCoverModalVisible] = useState(false);
  const { mutate: fileUploadMutate, isLoading: isFileUploadLoading } = useUploadFile();
  const setToast = useSetRecoilState(toast);

  const onDrop = (files: File[]) => {
    fileUploadMutate(files[0], {
      onSuccess: onChange,
    });
  };

  const onDropRejected = () => {
    setToast({
      isVisible: true,
      type: 'error',
      message: t('admin:components.features.application.fields.cover.errorMessages.common'),
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop,
    onDropRejected,
    maxFiles: 1,
    multiple: false,
    minSize: 50,
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png'],
    },
  });

  return (
    <div className={outerContainer}>
      <div
        className={`${container({ isActive: isDragActive || isFileUploadLoading })}`}
        {...getRootProps({
          onClick: () => {
            setCoverModalVisible(true);
          },
        })}
      >
        <input
          {...getInputProps({
            type: 'file',
            hidden: true,
            disabled: isFileUploadLoading || disabled,
            accept: 'image/jpeg, image/jpg, image/png',
          })}
        />

        {imageUrl ? (
          <img
            className={`${coverImage({ isActive: isDragActive || isFileUploadLoading })}`}
            src={imageUrl}
            alt='cover'
          />
        ) : (
          <div className={card}>
            <img className={icon} src={ImageIcon} alt='upload' />

            <Text size={14} fontWeight={400} color='gray-700'>
              {t('admin:components.features.application.fields.cover.label')}
            </Text>

            <Button variant='gray' size='sm'>
              {t('admin:components.features.application.fields.cover.button')}
            </Button>
          </div>
        )}

        {imageUrl && (
          <button
            className={removeButton}
            type='button'
            onClick={(event) => {
              event.stopPropagation();
              onChange('');
            }}
          >
            <img src={RemoveIcon} alt='remove' />
          </button>
        )}
      </div>

      <Text size={12} fontWeight={400} color='gray-400'>
        {t('admin:components.features.application.fields.cover.description')}
      </Text>

      <CoverModal
        isVisible={isCoverModalVisible}
        onClose={() => setCoverModalVisible(false)}
        onSubmit={onChange}
      />
    </div>
  );
}

export default ImageField;
