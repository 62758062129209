export default {
  total: '총 대상자 수',
  categorized: '분류 수',
  nonCategorized: '미분류 대상자 수',

  voterBook: {
    category: '분류',
    voterPerCategory: '분류별 대상자 수',
  },

  download: {
    list: '명부',
    buttons: {
      voter: {
        realtime: '실시간 참여자 명단',
        final: '최종 참여자 명단',
      },
      target: '대상자 명단',
    },
  },
};
