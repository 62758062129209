import { isNaN } from 'lodash-es';
import { ChangeEvent, ComponentProps, Ref, forwardRef } from 'react';
import { Input } from '@pickme/design-system';

import { formatNumber } from 'functions/utils';

import { EMPTY_STRING } from 'constants/common';

type Props = {
  value?: string;
  onChange?: (value: string) => void;
} & Omit<ComponentProps<typeof Input>, 'value' | 'onChange'>;

function NumericInput({ value, onChange, ...props }: Props, ref: Ref<HTMLInputElement>) {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) {
      return;
    }

    const rawValue = e.target.value.replace(/[^0-9]/g, EMPTY_STRING);

    if (rawValue === EMPTY_STRING || isNaN(Number(rawValue))) {
      onChange(EMPTY_STRING);
      return;
    }

    const numericValue = parseInt(rawValue, 10);
    const formattedValue = formatNumber(numericValue);

    onChange(formattedValue);
  };

  return <Input {...props} value={value} onChange={handleInputChange} ref={ref} />;
}

export default forwardRef(NumericInput);
