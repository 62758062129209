import { useState } from 'react';
import { PlainButton, Text } from '@pickme/design-system';

import DeleteAccountModal from 'components/features/my-page/modal/DeleteAccount';

import { useSignOut } from 'query-hooks/auth';

import { container, deletion } from './index.css';

type Props = {
  needPasswordConfirm: boolean;
};

function MyPageDeletion({ needPasswordConfirm }: Props) {
  const [isModalVisible, setModalVisible] = useState(false);

  const onOpenModal = () => setModalVisible(true);
  const onCloseModal = () => setModalVisible(false);

  const { mutate: signOut } = useSignOut();

  return (
    <div className={container}>
      <PlainButton onClick={onOpenModal}>
        <Text size={14} fontWeight={400} color='gray-400' className={deletion}>
          계정 탈퇴
        </Text>
      </PlainButton>

      {/* TODO: GNB가 수정되면 계정 관리에서 로그아웃을 삭제해야 합니다. */}
      <PlainButton onClick={() => signOut({ redirectToIntroduction: true })}>
        <Text size={14} fontWeight={400} color='red-500'>
          로그아웃
        </Text>
      </PlainButton>

      <DeleteAccountModal
        isVisible={isModalVisible}
        onClose={onCloseModal}
        needPasswordConfirm={needPasswordConfirm}
      />
    </div>
  );
}

export default MyPageDeletion;
