export default {
  card: {
    unavailable: '준비 중인 템플릿',
    button: '템플릿 사용하기',
  },
  modal: {
    title: {
      election: '선거 템플릿',
      survey: '설문 템플릿',
    },
    button: {
      all: '전체',
      more: '더보기',
    },
  },
  summary: {
    title: '템플릿',
    button: '더보기',
  },
  labels: {
    school: '학교',
    company: '회사',
    corporation: '기업',
    labor: '노동조합',
    association: '학회/협회',
    apartment: '아파트/공동주택',
    club: '동아리/동호회',
    religion: '종교단체',
  },
};
