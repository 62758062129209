import { Callout, PrimaryBadge, SecondaryBadge, Table, Text } from '@pickme/ui';
import { PollGroupBadge } from '@pickme/design-system';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { t } from 'i18next';
import { PollGroup } from '@pickme/core';

import Pagination from 'components/common/Pagination';
import Tabs from 'components/common/Tabs';

import { useGetPolls } from 'query-hooks/poll';

import { usePollQueryString } from 'hooks/usePollQueryString';

import { parseUrlWithPage } from 'functions/url';

import { SETTINGS_POLL_TABS } from 'constants/poll';

import SearchIcon from 'resources/icons/table/search.png';

import { Poll } from 'types/poll';

import './DiscardPrivacy.scss';

function DiscardPrivacy() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { page } = usePollQueryString();

  const { data, isLoading } = useGetPolls({
    page,
    groups: [PollGroup.Canceled, PollGroup.Counted],
    kind: 'all',
  });
  const { polls = [], endPage = 1 } = data || {};

  const onMovePage = (selectedPage: number) => {
    const parsedPage = parseUrlWithPage(`${pathname}${search}`, selectedPage);
    navigate(parsedPage);
  };

  const tabs = SETTINGS_POLL_TABS.map(({ text, url }) => ({
    text,
    onClick: () => navigate(url),
  }));

  return (
    <div className='poll-dashboard'>
      <Text type='h3' className='page-title'>
        {t('admin:pages.poll.discard.title')}
      </Text>

      <div className='notification__tabs-container'>
        <Tabs tabs={tabs} selectedIndex={1} />
      </div>

      <Callout
        id='poll-discard-personal-data'
        sentences={[
          <Text type='b3'>
            <b>{t('admin:pages.poll.discard.callout.0.0')}</b>{' '}
            {t('admin:pages.poll.discard.callout.0.1')}{' '}
            <b>{t('admin:pages.poll.discard.callout.0.2')}</b>
          </Text>,
          <Text type='b3'>{t('admin:pages.poll.discard.callout.1')}</Text>,
        ]}
        variant='warning'
      />

      <Table
        className='poll-discard-personal-data-table'
        headers={tableHeaders}
        id='poll-discard-personal-data-table'
        showSkeleton={isLoading}
        rows={setTableRows(polls)}
        placeholder={{
          icon: SearchIcon,
          text: t('admin:pages.poll.discard.search.result.blank'),
        }}
      />

      <Pagination page={page} endPage={endPage || 1} setPage={onMovePage} />
    </div>
  );
}

const tableHeaders = [
  { title: t('admin:pages.poll.discard.table.header.kind'), name: 'kind' },
  { title: t('admin:pages.poll.discard.table.header.name'), name: 'name' },
  { title: t('admin:pages.poll.discard.table.header.status'), name: 'status' },
  { title: t('admin:pages.poll.discard.table.header.count'), name: 'count' },
  {
    title: t('admin:pages.poll.discard.table.header.discard-status'),
    name: 'discard-status',
  },
  {
    title: t('admin:pages.poll.discard.table.header.discard-date'),
    name: 'discard-date',
  },
];

const setTableRows = (polls: Poll[]) =>
  polls.map((poll) => ({
    id: poll._id,
    items: [
      {
        data: (
          <SecondaryBadge>
            {poll.kind === 'Election'
              ? t('admin:terms.poll.kind.election')
              : t('admin:terms.poll.kind.survey')}
          </SecondaryBadge>
        ),
        name: 'kind',
      },
      {
        data: <Text type='b3'>{poll.name}</Text>,
        name: 'name',
      },
      {
        data: <PollGroupBadge group={poll.group} />,
        name: 'status',
      },
      {
        data: (
          <div>
            <Text type='b3' color='gray-600'>
              {poll.population.count}
            </Text>
          </div>
        ),
        name: 'count',
      },
      {
        data: (
          <PrimaryBadge variant={!poll.isVoterBookDeleted ? 'warning' : 'primary'}>
            {!poll.isVoterBookDeleted
              ? t('admin:pages.poll.discard.labels.status.pending')
              : t('admin:pages.poll.discard.labels.status.complete')}
          </PrimaryBadge>
        ),
        name: 'discard-status',
      },
      {
        data: poll.voterbookExpiry ? (
          <div>
            <Text type='b3'>
              {moment(poll.voterbookExpiry).tz('Asia/Seoul').format('YY/MM/DD')}
            </Text>
            <Text type='b3' color='gray-400'>
              {moment(poll.voterbookExpiry).tz('Asia/Seoul').format('HH:mm')}
            </Text>
          </div>
        ) : (
          <div />
        ),
        name: 'discard-date',
      },
    ],
  }));

export default DiscardPrivacy;
