export default {
  title: '결제하기',
  message: '플랜 인원 추가',
  validate: {
    minVoterLimit: '최소 인원은 0보다 커야 합니다.',
    maxVoterLimit: '최대 인원은 {{number}}보다 작아야 합니다.',
  },
  labels: ['공급 가액', '세액', '최종 결제 금액', '결제 수단'],
  buttons: {
    submit: '결제하기',
  },
};
