import dayjs from 'dayjs';
import { PrimaryBadge } from '@pickme/ui';
import { Table, Pagination, PlainButton, Text } from '@pickme/design-system';
import { ChargeTransaction, ExtraTransaction, Point, TransferTransaction } from '@pickme/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';
import IncomingDetailModal from 'components/features/payment/modal/IncomingDetail';
import CancelModal from 'components/features/payment/modal/Cancel';

import { useGetIncomings } from 'query-hooks/payment';

import { useQueryString } from 'hooks/useQueryString';

import { incomingDetailModal } from 'states/modal';

import {
  parseChargeTransactionToBadge,
  parseTransferTransactionToBadge,
  parseExtraTransactionToBadge,
} from 'functions/payment';
import { parseUrlWithPage } from 'functions/url';

import { alignCenter, tableRow } from '../index.css';

function PointIncoming() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const setIncomingDetailModal = useSetRecoilState(incomingDetailModal);

  const { page } = useQueryString({ page: 1 });

  const { data: incomingData, isLoading } = useGetIncomings(page);

  const onMovePage = (targetPage: number) => {
    const parsedPage = parseUrlWithPage(`${pathname}${search}`, targetPage);
    navigate(parsedPage);
  };

  return (
    <>
      <Table>
        <colgroup>
          <col />
          <col width={144} />
          <col width={144} />
          <col width={144} />
          <col width={144} />
          <col width={96} />
        </colgroup>

        <Table.Header>
          <Table.Tr>
            <Table.Th id='incoming-date' textAlign='start'>
              일시
            </Table.Th>
            <Table.Th id='incoming-payment' textAlign='start'>
              금액
            </Table.Th>
            <Table.Th id='incoming-point' textAlign='start'>
              포인트
            </Table.Th>
            <Table.Th id='incoming-kind' textAlign='start'>
              분류
            </Table.Th>
            <Table.Th id='incoming-status' textAlign='start'>
              상태
            </Table.Th>
            <Table.Th id='incoming-detail'>내역</Table.Th>
          </Table.Tr>
        </Table.Header>

        <Table.Body>
          {isLoading && (
            <Table.Tr>
              <Table.Td colSpan={4}>
                <Spinner />
              </Table.Td>
            </Table.Tr>
          )}

          {!isLoading &&
            (incomingData ? (
              incomingData.transactions.map((incoming) => (
                <Table.Tr key={incoming._id} className={tableRow}>
                  <Table.Td textAlign='start'>
                    {dayjs(incoming.createdAt).format('YY/MM/DD (dd) HH:mm')}
                  </Table.Td>

                  <Table.Td textAlign='start'>
                    {incoming instanceof ChargeTransaction && incoming.amount.value !== 0
                      ? incoming.amount.print()
                      : '-'}
                  </Table.Td>

                  <Table.Td textAlign='start'>
                    {new Point(
                      (incoming?.pointVariation.value || 0) + (incoming?.bonusVariation.value || 0),
                    ).print()}
                  </Table.Td>

                  <Table.Td textAlign='start'>
                    {(incoming instanceof ChargeTransaction &&
                      t('admin:pages.payment.point.labels.incoming.charge')) ||
                      (incoming instanceof ExtraTransaction &&
                        t('admin:pages.payment.point.labels.incoming.extra')) ||
                      (incoming instanceof TransferTransaction &&
                        t('admin:pages.payment.point.labels.incoming.transfer'))}
                  </Table.Td>

                  <Table.Td textAlign='start'>
                    {(incoming instanceof ChargeTransaction && (
                      <ChargeTransactionBadge chargeTransaction={incoming} />
                    )) ||
                      (incoming instanceof TransferTransaction && <TransferTransactionBadge />) ||
                      (incoming instanceof ExtraTransaction && <ExtraTransactionBadge />)}
                  </Table.Td>

                  <Table.Td className={alignCenter}>
                    <PlainButton
                      onClick={() =>
                        setIncomingDetailModal({
                          isVisible: true,
                          chargeId: incoming._id,
                          paymentOid:
                            incomingData?.transactions.find(
                              (charge) => charge?._id === incoming._id,
                            )?.paymentOid || '',
                        })
                      }
                    >
                      <Text size={14} color='primary-500'>
                        상세 내역
                      </Text>
                    </PlainButton>
                  </Table.Td>
                </Table.Tr>
              ))
            ) : (
              <Table.Tr>
                <Table.Td colSpan={6}>포인트 충전 내역이 없어요</Table.Td>
              </Table.Tr>
            ))}
        </Table.Body>
      </Table>

      {!isLoading && (
        <Pagination currentPage={page} totalPage={incomingData?.endPage || 1} onMove={onMovePage} />
      )}

      <IncomingDetailModal />
      <CancelModal />
    </>
  );
}

function ChargeTransactionBadge({ chargeTransaction }: { chargeTransaction: ChargeTransaction }) {
  const { text, variant } = parseChargeTransactionToBadge(chargeTransaction);
  return <PrimaryBadge variant={variant}>{text}</PrimaryBadge>;
}

function TransferTransactionBadge() {
  const { text, variant } = parseTransferTransactionToBadge();
  return <PrimaryBadge variant={variant}>{text}</PrimaryBadge>;
}

function ExtraTransactionBadge() {
  const { text, variant } = parseExtraTransactionToBadge();
  return <PrimaryBadge variant={variant}>{text}</PrimaryBadge>;
}

export default PointIncoming;
