import { ReactNode, useState } from 'react';
import { PlainButton } from '@pickme/ui';

import SubPollCardContents from 'components/features/poll-card-contents/SubPoll';

import UpIcon from 'resources/icons/poll-viewer/up.png';
import DownIcon from 'resources/icons/poll-viewer/down.png';

import './index.scss';

type Badge = { variant?: string; text: string };
type Props = {
  kindBadge: Badge;
  title: string;
  badges: Badge[];
  avatars?: string[];
  children: ReactNode;
};

function SubPollCard({ kindBadge, title, badges, avatars, children }: Props) {
  const [toggle, setToggle] = useState(false);

  return (
    <div className='poll-detail-sub-poll-card'>
      <SubPollCardContents kindBadge={kindBadge} title={title} badges={badges} avatars={avatars} />

      <PlainButton className='poll-detail-sub-poll-card__toggle' onClick={() => setToggle(!toggle)}>
        <img
          className='poll-detail-sub-poll-card__toggle-icon'
          src={toggle ? UpIcon : DownIcon}
          alt='more'
        />
      </PlainButton>

      {toggle && children}
    </div>
  );
}

export default SubPollCard;
