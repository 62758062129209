import { useState, useEffect } from 'react';
import { Spinner, Text } from '@pickme/ui';
import { useSetRecoilState } from 'recoil';
import { t } from 'i18next';
import { PollGroup } from '@pickme/core';

import Tabs from 'components/common/Tabs';
import PollDetail from 'components/features/poll/PollDetail';
import PollProgress from 'components/features/poll/Progress';
import PollResult from 'components/features/poll/Result';

import { useGetPoll } from 'query-hooks/poll';

import { pollViewer as pollViewerState } from 'states/poll-viewer';

import { GROUPS_ABLE_TO_ENTER_POLL_RESULT } from 'constants/poll';

import './index.scss';

type PollViewerMode = 'detail' | 'progress' | 'result';

type Props = {
  id: string;
  isModal?: boolean;
};

function PollViewer({ id, isModal }: Props) {
  const setPollViewer = useSetRecoilState(pollViewerState);
  const [mode, setMode] = useState<PollViewerMode>('detail');

  const { data: poll, isLoading } = useGetPoll(id);

  const progressTabLabel =
    (poll?.kind === 'Election' &&
      t('admin:components.features.poll.pollViewer.progress.election')) ||
    (poll?.kind === 'Survey' && t('admin:components.features.poll.pollViewer.progress.survey')) ||
    t('admin:components.features.poll.pollViewer.progress.default');
  const resultTabLabel =
    (poll?.kind === 'Election' && t('admin:components.features.poll.pollViewer.result.election')) ||
    (poll?.kind === 'Survey' && t('admin:components.features.poll.pollViewer.result.survey')) ||
    t('admin:components.features.poll.pollViewer.result.default');

  useEffect(() => {
    if (poll?.group === PollGroup.Ready || poll?.group === PollGroup.Canceled) {
      setMode('detail');
    } else if (
      poll?.group === PollGroup.Ended ||
      poll?.group === PollGroup.Ongoing ||
      poll?.group === PollGroup.Paused
    ) {
      setMode('progress');
    } else if (poll?.group === PollGroup.Counted) {
      setMode('result');
    }

    return () => setMode('detail');
  }, [id, poll?.group]);

  const closePollViewer = () => {
    setPollViewer({
      isVisible: false,
      id: '',
    });
  };

  if (isLoading) {
    return (
      <div className='poll-viewer poll-viewer--loading'>
        <Spinner />
      </div>
    );
  }

  return (
    <div className='poll-viewer'>
      <div className='poll-viewer__title'>
        <Text type='h3' fontWeight={600}>
          {poll?.name || ''}
        </Text>
      </div>

      <div className='poll-viewer__tabs'>
        <Tabs
          tabs={[
            {
              text: t('admin:components.features.poll.pollViewer.detail'),
              onClick: () => setMode('detail'),
            },
            {
              text: progressTabLabel,
              onClick: () => setMode('progress'),
            },
            {
              text: resultTabLabel,
              disabled:
                poll?.group !== 'counted' &&
                !(
                  poll?.allowRealTimeResult &&
                  GROUPS_ABLE_TO_ENTER_POLL_RESULT.includes(poll?.group)
                ),
              onClick: () => setMode('result'),
            },
          ]}
          selectedIndex={
            (mode === 'detail' && 0) || (mode === 'progress' && 1) || (mode === 'result' && 2) || 0
          }
        />
      </div>

      {mode === 'detail' && (
        <PollDetail
          poll={poll}
          onClose={() => {
            if (isModal) {
              closePollViewer();
            }
          }}
        />
      )}
      {mode === 'progress' && (
        <PollProgress
          id={id}
          onClose={() => {
            if (isModal) {
              closePollViewer();
            }
          }}
        />
      )}
      {mode === 'result' && (
        <PollResult
          id={id}
          onClose={() => {
            if (isModal) {
              closePollViewer();
            }
          }}
        />
      )}
    </div>
  );
}

export default PollViewer;
