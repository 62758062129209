import dayjs from 'dayjs';
import { useState } from 'react';
import { Pagination, Table, Text } from '@pickme/design-system';
import { ExternalPaymentMethodType, TOSS_EASY_PAY_CODE_TO_NAME } from '@pickme/core';

import Spinner from 'components/common/Spinner';
import PreviousSubscriptionPaymentHistoryModal from 'components/features/payment-v2/modal/PreviousHistory';

import { useGetPreviousSubscriptionHistory } from 'query-hooks/payment-v2';

import { PreviousSubscriptionHistoryResponse } from 'types/payment-v2';

import { useSubscriptionHistorySearchParams } from '../useSubscriptionHistorySearchParams';

import { detailButton, title } from '../common.css';

function PreviousSubscriptionHistoryTable() {
  const [params, setParams] = useSubscriptionHistorySearchParams({ page: 1 });

  const { data, isLoading } = useGetPreviousSubscriptionHistory({
    page: params.page,
  });

  const [detailModal, setDetailModal] = useState<{
    isVisible: boolean;
    history: PreviousSubscriptionHistoryResponse | null;
  }>({
    isVisible: false,
    history: null,
  });

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Tr>
            <Table.Th className={title}>결제 내역</Table.Th>
            <Table.Th>결제 일시</Table.Th>
            <Table.Th>결제 방법</Table.Th>
            <Table.Th>결제 금액</Table.Th>
            <Table.Th>내역</Table.Th>
          </Table.Tr>
        </Table.Header>

        <Table.Body>
          {isLoading ? (
            <Table.Tr>
              <Table.Td colSpan={5}>
                <Spinner />
              </Table.Td>
            </Table.Tr>
          ) : (
            data?.contents?.map((item) => (
              <Table.Tr key={item.id}>
                <Table.Td className={title}>{item.summary}</Table.Td>
                <Table.Td>{dayjs(item.createdAt).format('YY.MM.DD (ddd) HH:mm')}</Table.Td>
                <Table.Td>
                  {item.payment.method.type === ExternalPaymentMethodType.EasyPay
                    ? TOSS_EASY_PAY_CODE_TO_NAME[item.payment.method.providerCode]
                    : EXTERNAL_PAYMENT_METHOD_TYPE_LABEL[item.payment.method.type]}
                </Table.Td>
                <Table.Td>{item.totalPrice.toLocaleString()}원</Table.Td>
                <Table.Td>
                  <button
                    type='button'
                    className={detailButton}
                    onClick={() => setDetailModal({ isVisible: true, history: item })}
                  >
                    <Text color='primary-500'>상세 내역</Text>
                  </button>
                </Table.Td>
              </Table.Tr>
            ))
          )}
        </Table.Body>
      </Table>

      {isLoading || data?.endPage === 1 ? null : (
        <Pagination
          currentPage={params.page}
          totalPage={data?.endPage || 1}
          onMove={(selectedPage) => setParams({ ...params, page: selectedPage })}
        />
      )}

      <PreviousSubscriptionPaymentHistoryModal
        isVisible={detailModal.isVisible}
        onClose={() => setDetailModal({ isVisible: false, history: null })}
        history={detailModal.history}
      />
    </>
  );
}

export default PreviousSubscriptionHistoryTable;

const EXTERNAL_PAYMENT_METHOD_TYPE_LABEL = {
  [ExternalPaymentMethodType.Card]: '신용카드',
  [ExternalPaymentMethodType.VirtualAccount]: '무통장입금(가상계좌)',
  [ExternalPaymentMethodType.Transfer]: '계좌이체',
  [ExternalPaymentMethodType.Etc]: '계좌이체',
  [ExternalPaymentMethodType.System]: '시스템',
  [ExternalPaymentMethodType.Point]: '포인트',
  [ExternalPaymentMethodType.NoCharge]: '알 수 없음',
};
