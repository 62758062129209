import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';

const APIS: Apis = {
  orderResult: { method: 'POST', url: '/api/poll/:id/result' },
  cancelPoll: { method: 'POST', url: '/api/poll/:id/cancel' },
  pausePoll: { method: 'POST', url: '/api/poll/:id/pause' },
  restartPoll: { method: 'POST', url: '/api/poll/:id/pause' },
  removePoll: { method: 'DELETE', url: '/api/poll/:id' },
  earlyExit: { method: 'PUT', url: '/api/poll/:pollId/terminate' },
};

export const createOrderResultRequest = (id: string) =>
  createHttpRequest<{ orderId: string }>({
    ...APIS.orderResult,
    params: { id },
    data: { pollId: id },
  });

export const createCancelPollRequest = (id: string) =>
  createHttpRequest({ ...APIS.cancelPoll, params: { id } });

export const createPausePollRequest = (id: string) =>
  createHttpRequest({ ...APIS.pausePoll, params: { id } });

export const createRestartPollRequest = (id: string) =>
  createHttpRequest({ ...APIS.restartPoll, params: { id } });

export const createRemovePollRequest = (id: string) =>
  createHttpRequest({ ...APIS.removePoll, params: { id } });

export const createPollEarlyExitRequest = (pollId: string) =>
  createHttpRequest({
    ...APIS.earlyExit,
    params: { pollId },
  });
