import { ComponentProps, Ref, forwardRef } from 'react';
import { Select } from '@pickme/design-system';

import { MESSAGING_METHOD_SELECT_ITEMS } from 'constants/notification';
import { select } from '../common.css';

type Props = Omit<ComponentProps<typeof Select>, 'items'>;

function MessagingMethodSelect(props: Props, ref: Ref<HTMLInputElement>) {
  return (
    <Select
      {...props}
      placeholder='전송 방법 선택'
      items={MESSAGING_METHOD_SELECT_ITEMS}
      ref={ref}
      className={select}
    />
  );
}

export default forwardRef(MessagingMethodSelect);
