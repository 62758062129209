export default {
  fields: {
    name: {
      label: {
        election: '선거 제목',
        survey: '설문 제목',
      },
      placeholder: '제목을 작성해주세요',
    },
    public: {
      label: '공개 여부',
      items: {
        open: {
          title: '공개',
          description: '누구든지 자유롭게 참여할 수 있는 공개 설문을 만들어요.',
        },
        close: {
          title: '비공개',
          description: '초대 받거나 링크를 공유 받은 구성원들만 참여할 수 있어요.',
        },
      },
    },
    period: {
      label: {
        election: '선거 기간',
        survey: '설문 기간',
      },
    },
    description: {
      label: {
        election: '선거 설명',
        survey: '설문 설명',
      },
    },
  },
  buttons: {
    submit: '저장하고 다음',
  },
  errorMessages: {
    subscription: '결제 오류로 인해 구독 결제가 처리되지 못했어요',
  },
  modal: {
    changePlan: {
      title: {
        election: {
          free: '선거 생성 플랜을 무료 플랜으로 변경하시겠어요?',
          basic: '선거 생성 플랜을 베이직으로 변경하시겠어요?',
          standard: '선거 생성 플랜을 스탠다드로 변경하시겠어요?',
          premium: '선거 생성 플랜을 프리미엄으로 변경하시겠어요?',
          enterprise: '선거 생성 플랜을 엔터프라이즈로 변경하시겠어요?',
          campus: '선거 생성 플랜을 캠퍼스로 변경하시겠어요?',
        },
        survey: {
          free: '설문 생성 플랜을 무료 플랜으로 변경하시겠어요?',
          basic: '설문 생성 플랜을 베이직으로 변경하시겠어요?',
          standard: '설문 생성 플랜을 스탠다드로 변경하시겠어요?',
          premium: '설문 생성 플랜을 프리미엄으로 변경하시겠어요?',
          enterprise: '설문 생성 플랜을 엔터프라이즈로 변경하시겠어요?',
          campus: '설문 생성 플랜을 캠퍼스로 변경하시겠어요?',
        },
      },
      description: {
        election:
          '플랜을 변경하면 대상자 인원도 변경해야해요. 정말 선거 생성 플랜을 변경하시겠어요?',
        survey: '플랜을 변경하면 대상자 인원도 변경해야해요. 정말 설문 생성 플랜을 변경하시겠어요?',
      },
      actions: {
        cancel: '취소',
        submit: '변경하기',
      },
    },
  },
};
