import { Amount } from '@pickme/core';
import { Text } from '@pickme/ui';
import { t } from 'i18next';

function PriceViewer({ amount }: { amount: Amount }) {
  return (
    <div className='incoming-detail-modal__section'>
      <div className='incoming-detail-modal__row incoming-detail-modal__row--justify'>
        <div className='incoming-detail-modal__row__title'>
          <Text type='b3' fontWeight={400}>
            {t('admin:components.features.payment.modal.incomingDetail.priceViewer.tax')}
          </Text>
        </div>

        <div className='incoming-detail-modal__row__content'>
          <Text type='b3' fontWeight={400}>
            {new Amount(((amount.value ?? 0) * 10) / 11).print()}
          </Text>
        </div>
      </div>

      <div className='incoming-detail-modal__row incoming-detail-modal__row--justify'>
        <div className='incoming-detail-modal__row__title'>
          <Text type='b3' fontWeight={400}>
            {t('admin:components.features.payment.modal.incomingDetail.priceViewer.vat')}
          </Text>
        </div>

        <div className='incoming-detail-modal__row__content'>
          <Text type='b3' fontWeight={400}>
            {new Amount((amount.value ?? 0) / 11).print()}
          </Text>
        </div>
      </div>

      <div className='incoming-detail-modal__row incoming-detail-modal__row--justify'>
        <div className='incoming-detail-modal__row__title'>
          <Text type='b2' fontWeight={500}>
            {t('admin:components.features.payment.modal.incomingDetail.priceViewer.amount')}
          </Text>
        </div>

        <div className='incoming-detail-modal__row__content'>
          <Text type='b2' fontWeight={500}>
            {amount.print()}
          </Text>
        </div>
      </div>
    </div>
  );
}

export default PriceViewer;
