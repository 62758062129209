import { PollKind } from '@pickme/core';

import CandidateElectionIcon from 'resources/icons/poll-v2/candidate-election.svg';
import IssueElectionIcon from 'resources/icons/poll-v2/issue-election.svg';
import SurveyIcon from 'resources/icons/poll-v2/survey.svg';

type Props = {
  kind: PollKind;
};

const ICON = {
  [PollKind.CandidateElection]: CandidateElectionIcon,
  [PollKind.IssueElection]: IssueElectionIcon,
  [PollKind.Survey]: SurveyIcon,
};

function PollKindIcon({ kind }: Props) {
  return <img src={ICON[kind]} alt='poll kind' />;
}

export default PollKindIcon;
