export default {
  form: {
    fields: {
      phoneNumber: {
        placeholder: '전화번호 입력',
      },
    },
  },
  buttons: {
    cancel: '변경취소',
    edit: '변경하기',
  },
  messages: {
    verified: '인증된 전화번호입니다.',
  },
};
