import { useState } from 'react';
import { Text, Input, PlainButton } from '@pickme/design-system';
import { t } from 'i18next';

import NameChangeForm from 'components/features/my-page/form/NameChangeForm';

import { field } from '../common.css';

type Props = { name: string };

function MyPageName({ name }: Props) {
  const [isEditMode, setEditMode] = useState(false);

  const onOpenEditMode = () => setEditMode(true);
  const onCloseEditMode = () => setEditMode(false);

  return (
    <div className={field.container}>
      <Text size={14} className={field.label}>
        {t('admin:components.features.myPage.name.title')}
      </Text>

      {isEditMode ? (
        <NameChangeForm onClose={onCloseEditMode} />
      ) : (
        <>
          <Input value={name} size='lg' width={322} disabled />

          <PlainButton
            className={field.button({ hoverEffect: true })}
            onClick={isEditMode ? onCloseEditMode : onOpenEditMode}
          >
            변경
          </PlainButton>
        </>
      )}
    </div>
  );
}

export default MyPageName;
