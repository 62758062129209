import { useFormContext, useWatch } from 'react-hook-form';
import { Text, Tooltip } from '@pickme/design-system';
import { Point } from '@pickme/core';

import { useGetAccount } from 'query-hooks/payment';

import { Poll } from 'types/poll';
import { AlimTalkForm, RecipientType } from 'types/message';

import { subPoint as subPointStyle } from './index.css';

type Props = {
  polls: Poll[];
  pointPerVoter: number;
};

function EstimatedPointUsage({ polls, pointPerVoter }: Props) {
  const methods = useFormContext<AlimTalkForm>();
  const recipientType = useWatch({ control: methods.control, name: 'recipientType' });

  const { data: account } = useGetAccount();

  if (polls.length === 0 || !recipientType) {
    return null;
  }

  const isAllRecipient = recipientType === RecipientType.All;
  const targetVoterCount = polls
    .map((poll) => getTargetVoterCountFromPoll(poll, isAllRecipient))
    .reduce((prev, current) => prev + current, 0);
  const subPoint = targetVoterCount * pointPerVoter;

  return (
    <div>
      <Text size={14} color='gray-700'>
        보유포인트 <b>{account?.balance?.print()}</b>
      </Text>

      <div className={subPointStyle.container}>
        <Text size={16} fontWeight={600} color='gray-800' className={subPointStyle.title}>
          예상 차감 포인트
          <Tooltip message='대상자 명단에 휴대폰 번호가 등록된 인원만 집계해서 예상 차감 포인트를 계산해요' />
        </Text>

        <Text size={24} fontWeight={600} color='primary-500'>
          {new Point(subPoint).print()}
        </Text>
        <Text size={14} color='gray-600' className={subPointStyle.description}>
          {isAllRecipient ? '전체' : '투표 미참여자'} {targetVoterCount}명 x {pointPerVoter}P
        </Text>
      </div>
    </div>
  );
}

export default EstimatedPointUsage;

const getTargetVoterCountFromPoll = (poll: Poll, isAllRecipient: boolean) => {
  const voters = poll.voterBook;

  if (!voters) {
    return 0;
  }

  const targetVoters = voters?.filter((voter) => {
    if (isAllRecipient) {
      return true;
    }
    return !voter.hasVoted;
  });

  const phoneNumbers = targetVoters.map((voter) => voter.phoneNumber).filter(Boolean) as string[];
  const uniquePhonNumbers = new Set<string>(phoneNumbers);

  return uniquePhonNumbers.size;
};
