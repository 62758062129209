import axios from 'axios';
import { useEffect } from 'react';

export function useAuthHeader(organizationId: string) {
  useEffect(() => {
    if (organizationId) {
      axios.defaults.headers.common['organization-oid'] = organizationId;
    }

    return () => {
      delete axios.defaults.headers.common['organization-oid'];
    };
  }, [organizationId]);
}
