import { useEffect } from 'react';
import { Spinner } from '@pickme/ui';
import { Input, PlainButton } from '@pickme/design-system';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { t } from 'i18next';

import { useReplaceUserName } from 'query-hooks/user';

import { nameValidator } from 'functions/validator/auth';

import { field } from '../../common.css';

type Props = { onClose: () => void };

function NameChangeForm({ onClose }: Props) {
  const nameForm = useForm<{ name: string }>({
    mode: 'onTouched',
    defaultValues: { name: '' },
  });

  const { mutate: replaceUserName, isLoading } = useReplaceUserName();
  const onSubmit: SubmitHandler<{ name: string }> = (data) => {
    replaceUserName(
      { name: data.name },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  useEffect(() => {
    nameForm.reset();
  }, [nameForm]);

  return (
    <FormProvider {...nameForm}>
      <form
        onSubmit={nameForm.handleSubmit(onSubmit)}
        className={field.container}
        autoComplete='off'
      >
        <Input
          {...nameForm.register('name', {
            validate: (value: string) => {
              if (!value) {
                return t('admin:components.features.myPage.name.form.errorMessages.blank');
              }

              if (!nameValidator(value)) {
                return t('admin:components.features.myPage.name.form.errorMessages.nameFormat');
              }

              return true;
            },
            required: true,
          })}
          size='lg'
          placeholder='변경할 이름을 입력해 주세요'
          width={322}
          isValid={!nameForm.watch('name') || nameForm.formState.isValid}
          errorMessage={nameForm.formState.errors.name?.message}
          autoComplete='name'
        />

        <PlainButton
          type='submit'
          disabled={!nameForm.formState.isValid || isLoading}
          className={field.button({ submit: true })}
        >
          {isLoading ? <Spinner /> : '변경'}
        </PlainButton>

        <PlainButton className={field.button()} onClick={onClose}>
          취소
        </PlainButton>
      </form>
    </FormProvider>
  );
}

export default NameChangeForm;
