import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { Plan } from '@pickme/core';

import { toast } from 'states/toast';

export const useSendFeedback = () => {
  const setToast = useSetRecoilState(toast);
  const navigate = useNavigate();

  const url =
    '/AAAAioTw6js/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=FFNvrBn2WMXp9mMlDZBVCPhyoYbBuFxoKhOJIBtmq3c';

  return useMutation(
    async (form: { email: string; spaceName: string; plan: Plan; text: string }) => {
      const headers = new Headers({
        'Content-Type': 'application/json; charset=UTF-8',
      });
      const apiUrl =
        import.meta.env.MODE === 'dev.local'
          ? `/google-chat${url}`
          : `https://chat.googleapis.com/v1/spaces${url}`;
      const message = `${form.email} / ${form.spaceName} / ${form.plan}\n${form.text}`;

      const requestOptions: RequestInit = {
        method: 'POST',
        headers,
        body: JSON.stringify({ text: message }),
      };

      await fetch(apiUrl, requestOptions);
    },
    {
      onSettled: () => {
        setToast({
          isVisible: true,
          type: 'success',
          message: '감사합니다. 제출하신 피드백을 적극적으로 검토하겠습니다.',
        });

        navigate('/');
      },
    },
  );
};
