import { values } from 'lodash-es';

export const emailValidator = (value: string) => {
  if (value.length < 7 || value.length > 50) {
    return false;
  }

  // Just trust http://emailregex.com/
  const regex =
    // eslint-disable-next-line max-len, no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex.test(value)) {
    return false;
  }

  return true;
};

// temp
export const idValidator = (value: string) => /^[A-Za-z0-9]{3,30}$/.test(value);

export const nameValidator = (value: string) =>
  /^(?! *$)[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|\s|A-Za-z0-9]{1,100}$/.test(value);

export const organizationNameValidator = (value: string) =>
  /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]{1,100}$/.test(value);

export const PASSWORD_REGEX = {
  alphabet: /^(?=.*[A-Za-z]).*$/,
  number: /^(?=.*\d).*$/,
  specialCharacter: /^(?=.*[@$!%*#?&]).*$/,
  minDigit: /^[A-Za-z\d@$!%*#?&]{8,}$/,
  maxDigit: /^[A-Za-z\d@$!%*#?&]{1,20}$/,
};
export const passwordValidator = (value: string) =>
  values(PASSWORD_REGEX).every((pwRegex) => pwRegex.test(value));

export const otpValidator = (value: string) => value.length === 6;
