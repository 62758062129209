export default {
  title: '스페이스 소유자 변경',
  greeting: '{{space}}의 {{user}}님이 초대했어요',
  submitButtonText: '합류하기',
  description: '초대 받은 스페이스가 아니면 소유자에게 문의해주세요',
  errorMessages: {
    notPermitted: {
      message: '잘못된 합류 링크입니다.',
    },
  },
};
