import manualStartCard from './cards/manualStart';
import manualStartModal from './modals/manualStart';

export default {
  cards: {
    manualStart: manualStartCard,
  },
  modals: {
    manualStart: manualStartModal,
  },
};
