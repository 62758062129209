import { useState } from 'react';
import { Spinner } from '@pickme/ui';
import { Input, PlainButton } from '@pickme/design-system';
import moment from 'moment-timezone';
import Countdown from 'react-countdown';
import { useFormContext } from 'react-hook-form';
import { t } from 'i18next';

import { otpValidator } from 'functions/validator/auth';

import { EmailChangeFormBody } from 'types/auth';

import { field } from '../../common.css';
import { container, otp } from './index.css';

type Props = {
  resetOTP: () => void;
  onSendOTP: () => void;
  onClose: () => void;
  kind: 'email' | 'phone';
  isLoading: boolean;
};

function OTPVerifyForm({ resetOTP, onSendOTP, onClose, kind, isLoading }: Props) {
  const [OTPCountdown, setOTPCountdown] = useState(Date.now());

  const {
    watch,
    resetField,
    register,
    formState: { errors },
  } = useFormContext<EmailChangeFormBody>();
  const isEmail = kind === 'email';

  const EXPIRED_TIME_FOR_EMAIL = 5;
  const EXPIRED_TIME_FOR_SMS = 3;

  return (
    <div className={container}>
      <div className={otp.container}>
        <Input
          {...register('otp', {
            validate: (value: string) => !value || otpValidator(value),
          })}
          size='lg'
          pattern='\d*'
          placeholder={t('admin:components.features.myPage.otpVerify.form.fields.otp.placeholder')}
          width={322}
          isValid={!errors.otp}
          maxLength={6}
        />

        <PlainButton
          onClick={() => {
            onSendOTP();
            resetField('otp');
            setOTPCountdown(Date.now());
          }}
          className={otp.retry}
        >
          인증번호 재발송
        </PlainButton>

        <div className={otp.countdown}>
          <Countdown
            date={
              OTPCountdown + 1000 * 60 * (isEmail ? EXPIRED_TIME_FOR_EMAIL : EXPIRED_TIME_FOR_SMS)
            }
            intervalDelay={1000}
            precision={3}
            zeroPadTime={2}
            renderer={({ total }) => <div>{moment(total, 'x').format('mm:ss')}</div>}
            onComplete={() => {
              resetField('otp');
              resetOTP();
            }}
          />
        </div>
      </div>

      <PlainButton
        className={field.button({ submit: true })}
        type='submit'
        disabled={isLoading || !watch('otp') || !otpValidator(watch('otp'))}
      >
        {isLoading ? <Spinner /> : '인증'}
      </PlainButton>

      <PlainButton className={field.button()} onClick={onClose}>
        취소
      </PlainButton>
    </div>
  );
}

export default OTPVerifyForm;
