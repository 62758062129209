import { Text } from '@pickme/ui';
import { NavLink, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useEffect } from 'react';

import PollViewer from 'components/features/poll/PollViewer';
import PollViewerButtonGroups from 'components/features/poll/PollViewerButtonGroups';

import { useGetPoll } from 'query-hooks/poll';

import './PollViewer.scss';

function PollViewerPage() {
  const { id } = useParams<{ id: string }>();
  const { data: poll } = useGetPoll(id || '');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='poll-viewer-page'>
      <div className='poll-viewer-page__header'>
        <div className='poll-viewer-page__header__title'>
          <NavLink
            to={`/poll/${poll?.kind === 'Election' ? 'election' : 'survey'}`}
            unstable_viewTransition
          >
            <Text type='b3' fontWeight={400} color='gray-400'>
              {poll?.kind === 'Election'
                ? t('admin:terms.poll.kind.election')
                : t('admin:terms.poll.kind.survey')}{' '}
              {t('admin:pages.poll.viewer.title')} /{' '}
            </Text>
          </NavLink>

          <Text type='b3' fontWeight={500}>
            {poll?.name}
          </Text>
        </div>

        <PollViewerButtonGroups kind={poll?.kind} id={id || ''} />
      </div>

      <div className='poll-viewer-page__body'>
        <PollViewer id={id || ''} />
      </div>
    </div>
  );
}

export default PollViewerPage;
