import { Text } from '@pickme/design-system';
import { Space } from '@pickme/core';
import moment from 'moment-timezone';

import PlaceholderIcon from 'resources/icons/nav/space_placeholder.svg';

import { container, image, textContainer } from './index.css';

type Props = {
  space: Space;
};

function SelectedSpaceView({ space }: Props) {
  return (
    <div className={container}>
      <img
        className={image}
        src={space.logo || PlaceholderIcon}
        width='46'
        height='46'
        loading='lazy'
        alt={space.name}
      />

      <div className={textContainer}>
        <Text size={16} fontWeight={500} color='gray-800'>
          {space.name}
        </Text>

        <Text size={12} fontWeight={400} color='gray-500'>
          생성일 {moment(space.createdAt).tz('Asia/Seoul').format('YYYY.MM.DD')}
        </Text>
      </div>
    </div>
  );
}

export default SelectedSpaceView;
