import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetSessionAsMember, useGetSessionAsUser } from 'query-hooks/auth';

import channelTalk from 'functions/channel-talk';

const PLUGIN_KEY = 'ec1792b4-4a60-4f5f-93fd-9f131a3c2561';

export function useChannelTalk() {
  const { data: userSessionData, isSuccess: isUserInfoSuccess } = useGetSessionAsUser({
    suspense: false,
  });
  const { data: memberSessionData, isSuccess: isMemberSessionSuccess } = useGetSessionAsMember();
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (import.meta.env.MODE !== 'production') {
      return () => {};
    }

    if (memberSessionData?._id && userSessionData?._id) {
      channelTalk.boot({
        pluginKey: PLUGIN_KEY,
        memberId: userSessionData._id,
        memberHash: userSessionData.channelTalkMemberHash,
        profile: {
          name: userSessionData.name,
          email: userSessionData.email,
          mobileNumber: userSessionData.phoneNumber || '',
          userLevel: memberSessionData.level,
          organizationName: memberSessionData.organizationName,
          organizationId: memberSessionData.organizationId,
        },
        unsubscribeEmail: !userSessionData?.notification?.marketingAgreements?.[0],
      });
    } else {
      channelTalk.boot({
        pluginKey: PLUGIN_KEY,
      });
    }

    return () => {
      channelTalk.shutdown();
    };
  }, [isUserInfoSuccess, isMemberSessionSuccess]);

  useEffect(() => {
    if (import.meta.env.MODE !== 'production') {
      return;
    }

    if (userSessionData && memberSessionData) {
      channelTalk.updateUser({
        profile: {
          name: userSessionData.name,
          email: userSessionData.email,
          mobileNumber: userSessionData.phoneNumber || '',
          level: memberSessionData.level,
          organizationName: memberSessionData.organizationName,
          organizationId: memberSessionData.organizationId,
        },
        unsubscribeEmail: !userSessionData?.notification?.marketingAgreements?.[0],
      });
    }
  }, [
    userSessionData?.name,
    userSessionData?.email,
    userSessionData?.phoneNumber,
    memberSessionData?.level,
    memberSessionData?.organizationName,
    memberSessionData?.organizationId,
  ]);

  useEffect(() => {
    if (import.meta.env.MODE !== 'production') {
      return;
    }

    if (pathname || search) {
      channelTalk.setPage(`${pathname}${search || ''}`);
    }
  }, [pathname, search]);
}
