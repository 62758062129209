export default {
  title: '최종 투표율',
  rate: {
    realTime: '실시간 투표율',
    result: '최종 투표율',
    format: 'YYYY-MM-DD HH:mm 기준',
  },
  total: {
    target: '총 대상자 수',
    voter: '총 참여자 수',
  },
};
