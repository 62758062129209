import clsx from 'clsx';

import { background, progress } from './index.css';

type Props = {
  total: number;
  now: number;
  className?: string;
};

function ProgressBar({ total, now, className }: Props) {
  const percentage = total > 0 ? Math.floor((now / total) * 100) : 0;
  const correctedPercentage = percentage <= 100 ? percentage : 100;

  return (
    <div className={clsx(background, className)}>
      <div className={progress} style={{ width: `${correctedPercentage}%` }} />
    </div>
  );
}

export default ProgressBar;
