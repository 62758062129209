import { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { countBy } from 'lodash-es';
import { Modal, ModalBody, ModalHeader } from '@pickme/ui';
import { Text, OutlineButton, Button } from '@pickme/design-system';
import { t } from 'i18next';

import NumericInput from 'components/common/input/NumericInput';
import ManagerSelector from 'components/features/manager/ManagerSelector';

import { useGetAccount } from 'query-hooks/payment';

import { formatNumber, parseFormattedNumber } from 'functions/utils';

import { EMPTY_STRING } from 'constants/common';

import { Manager } from 'types/manager';

import ArrowIcon from 'resources/icons/payment/next.svg';

import { container } from 'styles/page.css';
import { card, buttons, wrapper, pointInput } from './common.css';
import { ableToTransfer, li } from './index.css';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  onSubmitButtonClick: (receiver: Manager, point: number) => void;
};

type PointTransferForm = {
  formattedPoint: string;
  receiver?: Manager;
};

function TransferPointModal({ isVisible, onClose, onSubmitButtonClick }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  const methods = useForm<PointTransferForm>({
    mode: 'onTouched',
    defaultValues: {
      formattedPoint: EMPTY_STRING,
    },
  });
  const { formState } = methods;
  const formattedPoint = methods.watch('formattedPoint');
  const receiver = methods.watch('receiver');

  const { data: accountData } = useGetAccount();

  useEffect(() => {
    if (inputRef.current) {
      const count = countBy(formattedPoint);
      const comma = count[','] || 0;
      const length = formattedPoint.length - comma * 0.8;
      inputRef.current.style.width = `${Math.max(length, 1)}ch`;
    }
  }, [formattedPoint]);

  useEffect(() => {
    if (isVisible) {
      return;
    }

    setTimeout(() => {
      methods.reset();
      methods.setValue('receiver', undefined);
    }, 300);
  }, [isVisible]);

  const transferablePoint = accountData?.balance.value || 0;
  const isPointTextActive = formattedPoint !== EMPTY_STRING;

  const onFormSubmit = (data: PointTransferForm) => {
    if (!data.receiver || !data.formattedPoint) {
      return;
    }

    const parsedPoint = parseFormattedNumber(data.formattedPoint);
    onSubmitButtonClick(data.receiver, parsedPoint);
  };

  return (
    <Modal size='xl' isVisible={isVisible} onClose={onClose} className='transfer-point-modal'>
      <ModalHeader
        title={t('admin:components.features.payment.modal.transferPoint.title')}
        onClose={onClose}
        hideCloseButton
      />

      <form onSubmit={methods.handleSubmit(onFormSubmit)}>
        <ModalBody className={container({ gap: 32 })}>
          <div className={card.container}>
            <div className={card.left}>
              <Text>선물할 포인트를 입력해 주세요</Text>

              <div>
                <div className={pointInput.container}>
                  <Controller
                    control={methods.control}
                    name='formattedPoint'
                    rules={{
                      required: true,
                      validate: (value) => {
                        const point = parseFormattedNumber(value);
                        const isValid = point <= transferablePoint;
                        return isValid;
                      },
                    }}
                    render={({ field }) => (
                      <NumericInput
                        value={field.value}
                        onChange={(value) => {
                          const point = parseFormattedNumber(value);
                          const isValid = point <= transferablePoint;

                          if (!isValid) {
                            methods.setError('formattedPoint', {
                              message: '보유 포인트보다 적게 입력해주세요.',
                            });
                          } else {
                            methods.clearErrors('formattedPoint');
                          }

                          field.onChange(value);
                        }}
                        inputClassName={pointInput.input({ active: isPointTextActive })}
                        placeholder='0'
                        ref={inputRef}
                      />
                    )}
                  />
                  <Text className={pointInput.suffix({ active: isPointTextActive })}>P</Text>
                </div>

                {formState.errors.formattedPoint && (
                  <Text size={12} color='red-500'>
                    {formState.errors.formattedPoint.message}
                  </Text>
                )}
              </div>

              <Text className={ableToTransfer} color='gray-600'>
                선물 가능한 포인트: {formatNumber(transferablePoint)}P
              </Text>
            </div>

            <div className={card.right}>
              <Text>누구에게 포인트를 선물하시겠어요?</Text>

              {receiver ? (
                <Text size={26} fontWeight={500} color='black'>
                  {receiver.name}
                </Text>
              ) : (
                <Text size={26} fontWeight={500} color='gray-200'>
                  매니저
                </Text>
              )}

              <Controller
                control={methods.control}
                name='receiver'
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <ManagerSelector selectedManager={field.value} onSelectManager={field.onChange} />
                )}
              />
            </div>
          </div>

          <div className={wrapper}>
            <Text size={14} color='black' fontWeight={500}>
              픽미 포인트 선물하기 안내
            </Text>

            <ul className='transfer-point-modal__callout__contents'>
              {DESCRIPTION.contents.map((text, index) => (
                <li key={`leave-space-callout-${index}`} className={li}>
                  {text}
                </li>
              ))}
            </ul>
          </div>
        </ModalBody>

        <div className={buttons.rightSection}>
          <OutlineButton
            size='lg'
            variant='gray'
            width={108}
            onClick={onClose}
            className={buttons.button}
          >
            닫기
          </OutlineButton>

          <Button
            size='lg'
            width={108}
            disabled={!formState.isValid}
            type='submit'
            className={buttons.button}
          >
            다음
            <img src={ArrowIcon} alt='next' />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default TransferPointModal;

const DESCRIPTION = {
  title: t('admin:components.features.payment.modal.transferPoint.description.title'),
  contents: [
    t('admin:components.features.payment.modal.transferPoint.description.contents.0'),
    t('admin:components.features.payment.modal.transferPoint.description.contents.1'),
    t('admin:components.features.payment.modal.transferPoint.description.contents.2'),
  ],
};
