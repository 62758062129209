export default {
  title: '질문 만들기',
  description: '여러개의 질문을 추가할 수 있어요',
  buttons: {
    add: '+ 질문 추가하기',
    submit: '저장하고 다음',
    addAnswer: '+ 추가',
    addSubQuestions: '+ 추가',
    media: '+ 미디어 등록하기',
    image: '이미지',
    video: '영상',
  },
  errorMessages: {
    required: '질문을 1개 이상 등록해야 다음 단계로 갈 수 있어요',
    answerRequired: '답변을 1개 이상 등록해야 다음 단계로 갈 수 있어요',
    selections: {
      scale: {
        min: '최대 단계보다 같거나 큰 값을 선택할 수 없습니다.',
        max: '최소 단계보다 같거나 작은 값을 선택할 수 없습니다.',
      },
    },
    answers: {
      required: '답변 내용을 입력해주세요',
    },
    subQuestions: {
      required: '답변 내용을 입력해주세요',
    },
    threshold: {
      min: '응답수는 0보다 큰 수를 입력해야합니다.',
      max: '최대 응답수는 최소 응답수보다 같거나, 큰 수를 입력해야합니다.',
    },
    rankLimit: {
      min: '선호도는 0보다 큰 수를 입력해야합니다.',
      max: '답변 수 보다 큰 선호도 순위를 선택할 수 없습니다.',
    },
    minScore: {
      min: '최소 점수는 0보다 큰 수를 입력해야합니다.',
      max: '최소 점수는 100보다 작은 수를 입력해야합니다.',
      compareWithMaxScore: '최소 점수는 최대 점수보다 작은 수를 입력해야합니다.',
    },
    maxScore: {
      min: '최대 점수는 0보다 큰 수를 입력해야합니다.',
      max: '최대 점수는 100보다 작은 수를 입력해야합니다.',
      compareWithMinScore: '최대 점수는 최소 점수보다 큰 수를 입력해야합니다.',
    },
  },
  forms: {
    question: {
      fields: {
        kind: {
          dropdown: {
            radio: '객관식 질문 (단일 선택)',
            checkbox: '객관식 질문 (복수 선택)',
            text: '주관식 질문',
            rank: '선호도 평가 질문',
            score: '점수 평가 질문',
            scale: '선형 배율 질문',
          },
        },
        selections: {
          text: {
            label: '답변 내용',
            placeholder: '응답자가 서술식 답변을 쓸 수 있어요',
          },
          scale: {
            label: '단계 개수',
            placeholder: '라벨을 입력해주세요',
          },
        },
        subQuestions: {
          label: '답변 내용',
          placeholder: '답변 내용을 작성해주세요',
        },
        answers: {
          label: '답변 내용',
          placeholder: '답변 내용을 작성해주세요',
        },
        title: {
          placeholder: '질문 제목을 작성해주세요.',
        },
        validation: {
          label: '응답 개수 설정',
        },
        threshold: {
          label: '응답 개수',
        },
        rankLimit: {
          label: '선호도',
        },
        score: {
          label: '점수',
          min: {
            label: '최소',
          },
          max: {
            label: '최대',
          },
        },
        required: {
          label: '필수 입력 항목',
          tooltip: '설문의 필수 응답 유무를 선택해주세요',
        },
        shuffle: {
          label: '답변 랜덤 노출',
          tooltip: '답변 랜덤 노출 유무를 선택해주세요',
        },
      },
      submitButtonText: '저장하기',
    },
  },
  modals: {
    kindSelect: {
      title: '새로운 질문을 만드시나요?',
      items: {
        radio: {
          title: '객관식 질문 (단일 선택)',
          message: '응답자가 한개의 답변만 선택할 수 있는 질문 유형이에요',
        },
        checkbox: {
          title: '객관식 질문 (복수 선택)',
          message: '응답자가 여러개의 답변을 선택할 수 있는 질문 유형이에요',
        },
        text: {
          title: '주관식 질문',
          message: '응답자가 서술식 답변을 쓸 수 있는 질문 유형이에요',
        },
        rank: {
          title: '선호도 평가 질문',
          message: '순위를 제시하면 응답자가 선호 순서대로 답변을 선택할 수 있는 질문 유형이에요',
        },
        score: {
          title: '점수 평가 질문',
          message: '응답자가 점수를 줄 수 있는 질문 유형이에요 결과는 점수의 평균값으로 제공돼요',
        },
        scale: {
          title: '선형 배율 질문',
          message:
            '양극단의 라벨을 제시하면 응답자가 단계별 정도에 따라 선호도를 표현할 수 있는 질문 유형이에요',
        },
      },
    },
    video: {
      title: '영상 추가하기',
      fields: {
        link: '유튜브 영상 링크',
      },
      actions: {
        submit: '저장',
      },
    },
  },
};
