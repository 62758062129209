import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { PlainButton, scrollbar, Text } from '@pickme/design-system';

import Dropdown from 'components/features/layout/Dropdown';
import SelectedSpaceView from 'components/common/layout/Main/Sidebar/space/Selector/Selected';
import SpaceItem from 'components/common/layout/Main/Sidebar/space/Selector/Item';
import Trigger from 'components/common/layout/Main/Sidebar/space/Selector/Trigger';

import { useGetOrganizations } from 'query-hooks/organization';

import { useGetActiveSpace } from 'hooks/useGetActiveSpace';

import { spaceState } from 'states/space';
import { createOrganizationModal } from 'states/modal';

import AddIcon from 'resources/icons/nav/add.svg';

import { button, container, divider, icon, iconContainer, innerContainer } from './index.css';

function SpaceSelector() {
  const navigate = useNavigate();
  const [isDropdownOpened, setDropdownOpen] = useState(false);

  const setActiveSpaceId = useSetRecoilState(spaceState.activeSpaceId);
  const setCreateOrganizationModal = useSetRecoilState(createOrganizationModal);

  const activeSpace = useGetActiveSpace();
  const { data: spaces } = useGetOrganizations();

  const onSelect = (spaceId: string) => {
    setActiveSpaceId(spaceId);
    navigate('/', { replace: true, unstable_viewTransition: true });
  };

  const onCloseDropdown = () => {
    setDropdownOpen(false);
  };

  const isSelectedSpaceViewVisible = !!activeSpace;

  const selectableSpaces = spaces?.filter(({ _id }) => _id !== activeSpace?._id) || [];
  const isSpaceItemsVisible = selectableSpaces.length > 0;

  return (
    <Dropdown
      isOpen={isDropdownOpened}
      onOpenDropdown={() => setDropdownOpen(true)}
      onCloseDropdown={onCloseDropdown}
      position='right'
      trigger={<Trigger logo={activeSpace?.logo} name={activeSpace?.name || ''} />}
    >
      <div className={container}>
        {isSelectedSpaceViewVisible && <SelectedSpaceView space={activeSpace} />}

        {isSelectedSpaceViewVisible && isSpaceItemsVisible && <hr className={divider} />}

        {isSpaceItemsVisible && (
          <div className={`${innerContainer} ${scrollbar}`}>
            {selectableSpaces.map((space) => (
              <SpaceItem
                key={`${space._id}-${space.name}`}
                space={space}
                onSelect={(spaceId) => {
                  onSelect(spaceId);
                  onCloseDropdown();
                }}
              />
            ))}
          </div>
        )}

        <PlainButton
          className={button}
          onClick={() => {
            setDropdownOpen(false);
            setCreateOrganizationModal({ isVisible: true });
          }}
        >
          <div className={iconContainer}>
            <img
              className={icon}
              src={AddIcon}
              alt={t('admin:components.common.sidebar.buttons.createSpace')}
            />
          </div>

          <Text size={16} fontWeight={400}>
            {t('admin:components.common.sidebar.buttons.createSpace')}
          </Text>
        </PlainButton>
      </div>
    </Dropdown>
  );
}

export default SpaceSelector;
