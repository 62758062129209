import { AuthMethodType, SocialAuthMethodResponse, SocialProvider } from 'types/auth';

import { CommonAuthMethod } from './common-auth-method';

export class SocialAuthMethod extends CommonAuthMethod {
  _type: AuthMethodType.Social;

  private _provider: SocialProvider;

  private _socialKey: string;

  private _accessToken: string;

  private _refreshToken?: string;

  constructor(response: SocialAuthMethodResponse) {
    super(response);
    this._type = AuthMethodType.Social;
    this._provider = response.provider;
    this._socialKey = response.socialKey;
    this._accessToken = response.accessToken;
    this._refreshToken = response.refreshToken;
  }

  get type() {
    return this._type;
  }

  get provider() {
    return this._provider;
  }

  get socialKey() {
    return this._socialKey;
  }

  get accessToken() {
    return this._accessToken;
  }

  get refreshToken() {
    return this._refreshToken;
  }
}
