import { useState } from 'react';
import { Text, Input, PlainButton } from '@pickme/design-system';

import EmailChangeForm from 'components/features/my-page/form/EmailChangeForm';

import { field } from '../common.css';

type Props = { email: string };

function MyPageEmail({ email }: Props) {
  const [isEditMode, setEditMode] = useState(false);

  const onOpenEditMode = () => setEditMode(true);
  const onCloseEditMode = () => setEditMode(false);

  return (
    <div>
      <div className={field.container}>
        <Text size={14} className={field.label}>
          계정
        </Text>

        {isEditMode ? (
          <EmailChangeForm onClose={onCloseEditMode} />
        ) : (
          <>
            <Input value={email} size='lg' width={322} disabled />

            <PlainButton
              className={field.button({ hoverEffect: true })}
              onClick={isEditMode ? onCloseEditMode : onOpenEditMode}
            >
              변경
            </PlainButton>
          </>
        )}
      </div>
    </div>
  );
}

export default MyPageEmail;
