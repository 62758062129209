import { htmlToText } from 'html-to-text';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Modal, ModalHeader } from '@pickme/ui';
import { PlainButton, Text, IconButton } from '@pickme/design-system';

import { useGetPoll } from 'query-hooks/poll';

import { useClipboardCopy } from 'hooks/useClipboardCopy';
import { useWindowSize } from 'hooks/useWindowSize';

import { pollShareModal } from 'states/modal';

import { EMPTY_STRING } from 'constants/common';

import KakaoIcon from 'resources/images/sns/kakao.svg';
import FacebookIcon from 'resources/images/sns/facebook.svg';
import LineIcon from 'resources/images/sns/line.svg';
import CopyIcon from 'resources/icons/dashboard/copy.svg';

import { copiedText, linkCopy, sns } from './index.css';

function PollShareModal() {
  const copy = useClipboardCopy();
  const [{ isVisible, pollId }, setModalVisible] = useRecoilState(pollShareModal);
  const { data: poll } = useGetPoll(pollId);

  const pollLink = `${import.meta.env.VITE_USER_URL}/poll/${pollId}`;
  const encodedPollLink = encodeURIComponent(pollLink);

  const imageUrl = poll?.coverImageFileUrl
    ? poll.coverImageFileUrl
    : `https://admin.ohpick.me/preview.png`;
  const description = poll ? htmlToText(poll.description) : EMPTY_STRING;

  const { isMobileSize } = useWindowSize();
  const nativeShareOption = {
    title: poll?.name,
    url: pollLink,
  };
  const canNativeShare =
    window.navigator.canShare &&
    window.navigator.canShare(nativeShareOption) &&
    window.navigator.share;

  const onClose = () => {
    setModalVisible({
      isVisible: false,
      pollName: '',
      pollId: '',
    });
  };

  useEffect(() => {
    const { Kakao } = window;

    if (!Kakao?.isInitialized()) {
      Kakao?.init(import.meta.env.VITE_KAKAO_JAVASCRIPT_SDK_KEY);
    }

    return () => Kakao.cleanup();
  }, []);

  useEffect(() => {
    if (isVisible && isMobileSize && canNativeShare) {
      window.navigator.share(nativeShareOption);
      onClose();
    }
  }, [isVisible, isMobileSize, canNativeShare]);

  if (isMobileSize && canNativeShare) {
    return null;
  }

  return (
    <Modal className='poll-share-modal' isVisible={isVisible} onClose={onClose} size='md'>
      <ModalHeader title='공유하기' onClose={onClose} />

      <div className={sns.container}>
        <PlainButton
          className={sns.item}
          onClick={() => {
            window.Kakao.Share.sendDefault({
              objectType: 'feed',
              content: {
                title: poll?.name,
                description,
                imageUrl,
                link: {
                  mobileWebUrl: pollLink,
                  webUrl: pollLink,
                },
              },
              buttons: [
                {
                  title: '참여하기',
                  link: {
                    mobileWebUrl: pollLink,
                    webUrl: pollLink,
                  },
                },
              ],
            });
          }}
        >
          <div className={sns.imageContainer}>
            <img src={KakaoIcon} alt='share kakao' />
          </div>
          카카오톡
        </PlainButton>

        <PlainButton
          className={sns.item}
          onClick={() =>
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedPollLink}`)
          }
        >
          <div className={sns.imageContainer}>
            <img src={FacebookIcon} alt='share facebook' />
          </div>
          페이스북
        </PlainButton>

        <PlainButton
          className={sns.item}
          onClick={() =>
            window.open(`https://social-plugins.line.me/lineit/share?url=${encodedPollLink}`)
          }
        >
          <div className={sns.imageContainer}>
            <img src={LineIcon} alt='kakao' />
          </div>
          라인
        </PlainButton>
      </div>

      <div className={linkCopy}>
        <Text size={14} fontWeight={400} color='gray-800' className={copiedText}>
          {pollLink}
        </Text>

        <IconButton
          onClick={() =>
            copy({ text: pollLink, successMessage: '링크가 성공적으로 복사되었습니다.' })
          }
          icon={CopyIcon}
          alt='copy poll link'
        />
      </div>
    </Modal>
  );
}

export default PollShareModal;
