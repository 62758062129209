import { Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { t } from 'i18next';

type Props = {
  isVisible: boolean;
  data: { success: number; failure: number };
  onClose: () => void;
};

function ResultModal({ isVisible, data, onClose }: Props) {
  return (
    <Modal isVisible={isVisible} onClose={onClose} size='lg'>
      <ModalHeader
        title={t('admin:components.features.application.form.voter.modals.addVotersResult.title')}
        onClose={onClose}
      />

      <ModalBody>
        <div className='voter-form__voters__title'>
          <Text type='b3' fontWeight={600}>
            {t(
              'admin:components.features.application.form.voter.modals.addVotersResult.labels.total',
            )}
          </Text>
          <Text type='b3' fontWeight={600}>
            {data.success + data.failure}
          </Text>
        </div>
        <div className='voter-form__voters__title'>
          <Text type='b3' fontWeight={600}>
            {t(
              'admin:components.features.application.form.voter.modals.addVotersResult.labels.success',
            )}
          </Text>
          <Text type='b3' fontWeight={600}>
            {data.success}
          </Text>
        </div>
        <div className='voter-form__voters__title'>
          <Text type='b3' fontWeight={600}>
            {t(
              'admin:components.features.application.form.voter.modals.addVotersResult.labels.failure',
            )}
          </Text>
          <Text type='b3' color='error-500' fontWeight={400}>
            {data.failure}
          </Text>
        </div>

        <div className='voter-form__voters__description'>
          <Text type='lab1' fontWeight={400} color='gray-400'>
            {t(
              'admin:components.features.application.form.voter.modals.addVotersResult.description',
            )}
          </Text>
        </div>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t(
              'admin:components.features.application.form.voter.modals.addVotersResult.actions.close',
            ),
            onClick: onClose,
          },
        ]}
      />
    </Modal>
  );
}

export default ResultModal;
