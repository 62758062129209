export default {
  title: '매니저 관리',
  table: {
    header: {
      name: '이름',
      phone: '전화번호',
      email: '이메일',
      level: '역할',
      date: '합류 승인 날짜',
      join: '합류여부',
      status: '상태',
    },
  },
  callout: ['현재 {{number}}명의 매니저가 있어요.', ' 매니저를 초대해 보세요!'],
  button: {
    invite: '매니저 초대하기',
    remove: '스페이스 삭제',
    leave: '스페이스 나가기',
    cancel: '소유자 변경 요청 취소',
    transfer: '소유자 변경',
  },
  labels: {
    join: {
      pending: '합류대기',
      approved: '합류완료',
    },
    status: {
      pending: '활성화',
      approved: '비활성화',
    },
  },
  search: {
    placeholder: '이름 또는 이메일 주소를 입력해주세요.',
    result: {
      blank: '등록된 매니저가 없어요\n매니저를 초대해보세요!',
    },
  },
};
