import { useEffect, useState } from 'react';
import { Text, IconInput, PlainButton, OutlineButton } from '@pickme/design-system';
import clsx from 'clsx';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

import { useDownloadUnsplashImage, useGetUnsplashImages } from 'query-hooks/image';

import { UnsplashImageResponse } from 'types/image';

import SearchIcon from 'resources/icons/dashboard/search.svg';

import {
  card,
  container,
  emptyResult,
  imageLabel,
  imageStyle,
  imagesContainer,
  skeleton,
  textUnderline,
  topicsContainer,
} from './index.css';

type Props = {
  onSelect: (imageUrl: string) => void;
};

function Unsplash({ onSelect }: Props) {
  const [keyword, setKeyword] = useState<string>('');
  const [submittedKeyword, setSubmittedKeyword] = useState<string>('');

  const [selectedImage, setSelectedImage] = useState<{ id: string; url: string } | null>(null);
  const [selectedTopicId, setSelectedTopicId] = useState<string | null>(null);

  const { data, hasNextPage, isFetching, fetchNextPage, remove } = useGetUnsplashImages({
    keyword: submittedKeyword,
    id: selectedTopicId,
  });
  const { mutate } = useDownloadUnsplashImage();

  const imagePages = data?.pages;
  const imageArray = data?.pages.flatMap((page) => page.images);
  // NOTE: api 버그인 것 같지만, 중복된 이미지가 존재합니다.
  const images = removeDuplicates(imageArray || []);

  const isEmptyResultTextVisible =
    keyword && !isFetching && (!imagePages?.[0].images || imagePages[0].images.length === 0);

  useEffect(() => {
    if (selectedImage?.url) {
      onSelect(selectedImage.url);
      mutate({ id: selectedImage.id });
    }
  }, [selectedImage?.url]);

  const { ref, inView } = useInView();

  useEffect(() => {
    if (isFetching || !hasNextPage) {
      return;
    }

    if (inView) {
      fetchNextPage();
    }
  }, [inView, isFetching]);

  useEffect(() => {
    if (selectedTopicId) {
      setKeyword('');
      setSubmittedKeyword('');
    }
  }, [selectedTopicId]);

  const onSearch = () => {
    remove();
    setSubmittedKeyword(keyword);
    setSelectedTopicId(null);
  };

  return (
    <div className={container}>
      <IconInput
        size='md'
        type='button'
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === 'enter') {
            event.preventDefault();
            onSearch();
          }
        }}
        onClick={onSearch}
        icon={SearchIcon}
        value={keyword}
        onChange={(event) => setKeyword(event.target.value)}
        placeholder={t('admin:components.features.search.nameAndPeriodSearch.name.placeholder')}
      />

      <div className={topicsContainer}>
        {TOPICS.map((topic) => {
          const isSelected = selectedTopicId === topic.id;

          return (
            <OutlineButton
              size='sm'
              variant={isSelected ? 'primary' : 'gray'}
              onClick={() => setSelectedTopicId(topic.id)}
            >
              {topic.title}
            </OutlineButton>
          );
        })}
      </div>

      <div className={imagesContainer}>
        {images?.map((image) => {
          const isSelected = image.id === selectedImage?.id;

          return (
            <PlainButton
              key={image.id}
              className={card}
              onClick={() =>
                setSelectedImage({
                  id: image.id,
                  url: image.urls.regular,
                })
              }
            >
              <img
                draggable={false}
                className={imageStyle({ isActive: isSelected })}
                src={image.urls.small}
                alt={image.id}
              />

              <Link
                className={imageLabel}
                to={`${image.user.links.html}?utm_source=Pick%20me&utm_medium=referral`}
                target='_blank'
              >
                <Text
                  className={clsx(imageLabel, textUnderline)}
                  size={12}
                  fontWeight={400}
                  color='gray-600'
                >
                  {image.user.username}
                </Text>
              </Link>
            </PlainButton>
          );
        })}

        {isEmptyResultTextVisible && (
          <div className={emptyResult}>
            <Text size={14} fontWeight={500}>
              검색 결과가 없습니다.
            </Text>
          </div>
        )}

        {isFetching &&
          new Array(12).fill('').map(() => (
            <div className={card}>
              <div className={skeleton.image} />

              <div className={skeleton.label} />
            </div>
          ))}

        <div ref={ref} style={{ height: '50px' }} />
      </div>
    </div>
  );
}

export default Unsplash;

const TOPICS = [
  { id: null, title: '전체' },
  { id: 'WdChqlsJN9c', title: '블랙 앤 화이트' },
  { id: 'bo8jQKTaE0Y', title: '배경화면' },
  { id: '6sMVjTLSkeQ', title: '자연' },
  { id: 'CDwuwXJAbEw', title: '3D 렌더링' },
  { id: 'Fzo3zuOHN6w', title: '여행' },
  { id: 'M8jVbLbTRws', title: '건축' },
  { id: 'iUIsnVtjB0Y', title: '질감' },
  { id: 'xHxYTMHLgOc', title: '거리 사진' },
  { id: 'hmenvQhUmxM', title: '필름' },
  { id: 'E--_pnIirG4', title: '기록 보관' },
  { id: 'qPYsDzvJOYc', title: '실험적인' },
  { id: 'Jpg6Kidl-Hk', title: '동물' },
  { id: 'S4MKLAsBB74', title: '패션' },
  { id: 'towJZFskpGg', title: '사람들' },
  { id: 'aeu6rL-j6ew', title: '업무' },
  { id: 'xjPR4hlkBGA', title: '음식' },
  { id: '_hb-dl4Q-4U', title: '건강' },
  { id: 'Bn-DjrcBrwo', title: '스포츠' },
];

function removeDuplicates(imageArray: UnsplashImageResponse[]): UnsplashImageResponse[] {
  const idSet = new Set<string>();

  const uniqueImages = imageArray.filter((image) => {
    const isDuplicate = idSet.has(image.id);
    idSet.add(image.id);

    return !isDuplicate;
  });

  return uniqueImages;
}
