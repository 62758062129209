export default {
  number: '결제 번호',
  date: '거래 일시',
  category: {
    label: '분류',
    content: '선물 포인트',
  },
  sender: '선물 보낸 사람',
  point: '선물 포인트',
};
