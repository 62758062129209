export default {
  title: '매니저 초대하기',
  description: [
    '링크를 복사하여 매니저로 초대해주세요.',
    '가입한 매니저를 소유자가 승인해야 최종 등록됩니다.',
  ],
  button: '링크 복사',
  messages: {
    success: '성공적으로 링크가 복사되었습니다',
    failure: '링크 복사에 실패했습니다.',
  },
};
