import { PLANS, Plan } from '@pickme/core';

import { badge } from './index.css';

type Props = {
  type: Plan;
  className?: string;
};

function SubscriptionBadge({ type, className }: Props) {
  return (
    <div className={`${badge({ color: COLORS[type] })} ${className || ''}`}>
      {PLANS[type].name.eng}
    </div>
  );
}

export default SubscriptionBadge;

const COLORS = {
  [Plan.Free]: 'lime' as const,
  [Plan.Basic]: 'yellow' as const,
  [Plan.Standard]: 'green' as const,
  [Plan.Premium]: 'teal' as const,
  [Plan.Enterprise]: 'purple' as const,
  [Plan.Campus]: 'red' as const,
};
