import { Controller, useFormContext } from 'react-hook-form';
import { GhostButton, Switch, Text } from '@pickme/design-system';
import { t } from 'i18next';
import { useSetRecoilState } from 'recoil';

import { toast } from 'states/toast';
import { createDownloadVoterBookTemplate } from 'apis/file';

import { VoterFormBody } from 'types/application';

import { container, header, headerContainer } from './index.css';

type Props = {
  readOnly: boolean;
  disabled?: boolean;
};

function InstantVoter({ readOnly, disabled }: Props) {
  const { control } = useFormContext<VoterFormBody>();
  const setToast = useSetRecoilState(toast);

  return (
    <div className={container}>
      <div className={headerContainer}>
        <div className={header}>
          <Text size={16} fontWeight={600} color='gray-800'>
            {t('admin:components.features.application.form.voter.instantVoter.title')}
          </Text>

          <Text size={16} fontWeight={400} color='gray-700'>
            {t('admin:components.features.application.form.voter.instantVoter.description')}
          </Text>
        </div>

        <GhostButton
          color='green'
          onClick={() => {
            createDownloadVoterBookTemplate().then(() => {
              setToast({
                isVisible: true,
                type: 'success',
                message: t(
                  'admin:components.features.application.form.voter.modals.addVoters.messages.success',
                ),
              });
            });
          }}
        >
          엑셀 서식 다운로드
        </GhostButton>
      </div>

      <Controller
        control={control}
        name='hasInstantVoter'
        disabled={disabled}
        render={({ field }) => (
          <Switch
            id={field.name}
            ref={field.ref}
            onChange={(event) => {
              if (readOnly) {
                event.preventDefault();
                return;
              }

              if (event.target.checked) {
                field.onChange(false);
              } else {
                field.onChange(true);
              }
            }}
            readOnly={readOnly}
            disabled={field.disabled}
            checked={!field.value}
          />
        )}
      />
    </div>
  );
}

export default InstantVoter;
