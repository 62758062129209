import SubPollCard from 'components/features/application/card/SubPoll';

import { makeElectionBadges } from 'functions/poll';

import { ELECTION_KIND_BADGES } from 'constants/poll';

import { SubElectionFormBody } from 'types/application';

type Props = {
  id: string;
  poll: SubElectionFormBody;
  disabled?: boolean;
  onClick: () => void;
  onRemove: () => void;
};

function SubElectionCard({ id, poll, disabled, onClick, onRemove }: Props) {
  const badges: { variant: string; text: string }[] = makeElectionBadges(poll);

  const memberImages: string[] =
    poll.candidates?.slice().map(({ members }) => members?.[0]?.fileUrl || '') || [];

  return (
    <SubPollCard
      id={id}
      disabled={disabled}
      onClick={onClick}
      onRemove={onRemove}
      kindBadge={{
        variant: ELECTION_KIND_BADGES[poll?.kind].variant,
        text: ELECTION_KIND_BADGES[poll?.kind].text,
      }}
      title={poll?.title}
      badges={badges}
      avatars={memberImages}
    />
  );
}

export default SubElectionCard;
