import signin from './form/signin';
import signup from './form/signup';
import otp from './form/otp';
import resetPassword from './form/resetPassword';
import sendRegisterMail from './form/sendRegisterMail';
import sendResetMail from './form/sendResetMail';
import term from './modal/term';

export default {
  form: {
    signin,
    signup,
    otp,
    resetPassword,
    sendRegisterMail,
    sendResetMail,
  },
  modal: {
    term,
  },
};
