import { Text } from '@pickme/design-system';
import { ReactNode } from 'react';

import { row, rowHeader, topAlignedRow } from './index.css';

type Props = {
  title: string;
  children: ReactNode;
  align: 'center' | 'top';
};

function SendFieldRow({ title, children, align }: Props) {
  return (
    <div className={`${row} ${align === 'top' ? topAlignedRow : ''}`}>
      <Text className={rowHeader} size={16} fontWeight={500}>
        {title}
      </Text>

      {children}
    </div>
  );
}

export default SendFieldRow;
