export default {
  useWriteDraft: {
    format: 'MM월 DD일 HH:mm',
    text: '에 작성한 {{kind}}입니다.',
    onSuccess: {
      toast: '저장되었습니다.',
    },
  },
  useRemoveDraft: {
    onSuccess: {
      toast: '임시 저장된 선거를 삭제했습니다.',
    },
  },
};
