export default {
  progress: {
    election: '선거현황',
    survey: '설문현황',
    default: '현황',
  },
  result: {
    election: '선거결과',
    survey: '설문결과',
    default: '결과',
  },
  detail: '상세보기',
};
