export default {
  badges: {
    ChoiceElection: '최소{{min}}명·최대{{max}}명',
    RankElection: '{{rank}}순위',
    RankQuestion: '{{rank}}순위',
    ScoreElection: '최소{{min}}점·최대{{max}}점',
    ScoreQuestion: '최소{{min}}점·최대{{max}}점',
    ScaleQuestion: '단위 {{min}} ~ {{max}}',
    CheckboxQuestion: '응답 {{threshold}}개',
    shuffled: '후보 랜덤 노출',
    ordered: '후보 순차 정렬',
    allowAbstentionVote: '기권표 포함',
    disallowAbstentionVote: '기권표 불포함',
    required: '필수 응답 항목',
  },
};
