export default {
  default: {
    title: '생성된 {{kind}}가 없어요',
    description: `“새로 만들기” 버튼을 클릭해 새로운 {{kind}}를 만들어보세요.\n누구든지 활용할 수 있는 다양한 템플릿을 제공하고 있어요.`,
    button: '새로 만들기',
  },
  search: {
    title: '검색 결과가 없어요',
    description: `다른 키워드로 다시 검색해주세요.`,
  },
};
