import { Text, Button, Callout } from '@pickme/ui';
import { useSetRecoilState } from 'recoil';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

import OverviewSection from 'components/features/poll/Progress/OverviewSection';
import InstantVoterOverviewSection from 'components/features/poll/Progress/InstantVoterOverviewSection';
import ProgressGraphSection from 'components/features/poll/Progress/ProgressGraphSection';
import InstantVoterProgressGraphSection from 'components/features/poll/Progress/InstantVoterProgressGraphSection';
import CategorizedProgressGraphSection from 'components/features/poll/Progress/CategorizedProgressGraphSection';

import { useGetPoll, useGetPollRate } from 'query-hooks/poll';
import { useGetSessionAsMember } from 'query-hooks/auth';

import { useGetActiveSpace } from 'hooks/useGetActiveSpace';

import { checkOrderable } from 'functions/poll-progress';

import { pollActionModal } from 'states/modal';

import './index.scss';

const GROUPS_ABLE_TO_CANCEL = ['ongoing', 'ended'];
const GROUPS_ABLE_TO_STOP = ['ongoing'];
const GROUPS_ABLE_TO_COUNT = ['ended'];
const GROUPS_ABLE_TO_EARLY_EXIT = ['ongoing'];

function PollProgress({
  id,
  showTitle,
  noAnimation,
  onClose,
}: {
  id: string;
  showTitle?: boolean;
  noAnimation?: boolean;
  onClose?: () => void;
}) {
  const setPollActionModal = useSetRecoilState(pollActionModal);

  const { data: memberSessionData } = useGetSessionAsMember();
  const { data } = useGetPollRate(id);
  const { data: pollData } = useGetPoll(id);
  const space = useGetActiveSpace();

  const isExceptionalCase =
    space?._id === '656400f5836ce700480cb784' || space?._id === '6410029c777549024c2617b0';

  const isUserManageable =
    memberSessionData?.level === 'OrganAdmin' ||
    (memberSessionData?.level === 'SubAdmin' && memberSessionData?.id === pollData?.authorId) ||
    isExceptionalCase;

  const isOrderable =
    data?.rate !== undefined &&
    pollData?.openThreshold !== undefined &&
    checkOrderable(data?.rate, pollData?.openThreshold);

  return (
    <div className='poll-progress'>
      <Callout
        className='pickme-callout__expire'
        id='poll-expire'
        variant='primary'
        bulletStyle='bullet'
        sentences={[
          <Text className='pickme-callout--inline' type='b3' fontWeight={400} color='gray-800'>
            {t('admin:pages.dashboard.callout.discard.0')}
          </Text>,
          <Text className='pickme-callout--inline' type='b3' fontWeight={400} color='gray-800'>
            {t('admin:pages.dashboard.callout.discard.1')}{' '}
            <Link
              to='/settings/poll/discard-data'
              unstable_viewTransition
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
            >
              <b>{t('admin:pages.dashboard.callout.discard.2')}</b>
            </Link>
          </Text>,
        ]}
      />

      {pollData?.hasInstantVoter ? (
        <InstantVoterOverviewSection voteCount={data?.voteCount} />
      ) : (
        <OverviewSection
          showTitle={showTitle}
          noAnimation={noAnimation}
          group={pollData?.group}
          rate={data?.rate}
          voteCount={data?.voteCount}
          population={data?.population}
        />
      )}

      {pollData?.hasInstantVoter ? (
        <InstantVoterProgressGraphSection
          group={pollData?.group}
          rateByHour={data?.rateByHour}
          endDate={pollData?.endDate.date}
          noAnimation={noAnimation}
        />
      ) : (
        <ProgressGraphSection
          group={pollData?.group}
          rateByHour={data?.rateByHour}
          endDate={pollData?.endDate.date}
          openThreshold={pollData?.openThreshold}
          noAnimation={noAnimation}
        />
      )}

      {!pollData?.hasInstantVoter && (
        <CategorizedProgressGraphSection
          id={id}
          rateByCategory={data?.rateByCategory}
          noAnimation={noAnimation}
        />
      )}

      {isUserManageable && pollData?.group !== 'canceled' && (
        <div className='poll-progress__buttons'>
          <Button
            width={108}
            size='md'
            variant='black'
            onClick={() =>
              setPollActionModal({
                isVisible: true,
                kind: pollData?.kind || 'Election',
                type: 'cancel',
                id,
              })
            }
            disabled={!GROUPS_ABLE_TO_CANCEL.includes(pollData?.group ?? '')}
          >
            {t('admin:components.features.poll.progress.pollProgress.buttons.invalid')}
          </Button>

          <Button
            width={108}
            size='md'
            variant={pollData?.isPause ? 'primary' : 'error'}
            onClick={() => {
              setPollActionModal({
                isVisible: true,
                kind: pollData?.kind || 'Election',
                type: pollData?.isPause ? 'restart' : 'pause',
                id,
              });
            }}
            disabled={!GROUPS_ABLE_TO_STOP.includes(pollData?.group ?? '')}
          >
            {pollData?.isPause
              ? t('admin:components.features.poll.progress.pollProgress.buttons.restart')
              : t('admin:components.features.poll.progress.pollProgress.buttons.pause')}
          </Button>

          <Button
            width={108}
            size='md'
            variant='primary'
            onClick={() => {
              setPollActionModal({
                isVisible: true,
                allowRealTimeResult: pollData?.allowRealTimeResult,
                kind: pollData?.kind || 'Election',
                type: 'early-exit',
                id,
              });
            }}
            disabled={!GROUPS_ABLE_TO_EARLY_EXIT.includes(pollData?.group ?? '')}
          >
            {t('admin:components.features.poll.progress.pollProgress.buttons.earlyExit')}
          </Button>

          <Button
            width={108}
            size='md'
            variant='success'
            onClick={() => {
              setPollActionModal({
                isVisible: true,
                allowRealTimeResult: pollData?.allowRealTimeResult,
                kind: pollData?.kind || 'Election',
                type: 'count',
                id,
              });
            }}
            disabled={!GROUPS_ABLE_TO_COUNT.includes(pollData?.group ?? '') || !isOrderable}
          >
            {pollData?.allowRealTimeResult
              ? t('admin:components.features.poll.progress.pollProgress.buttons.end')
              : t('admin:components.features.poll.progress.pollProgress.buttons.count')}
          </Button>
        </div>
      )}
    </div>
  );
}

export default PollProgress;
