import { useFormContext, useWatch } from 'react-hook-form';

import { PASSWORD_REGEX } from 'functions/validator/auth';

import { UserInfoForm } from 'types/auth';

import ValidIcon from 'resources/icons/auth/valid.svg';
import InvalidIcon from 'resources/icons/auth/invalid.svg';

import { container, validationStatus } from './index.css';

function PasswordValidationStatuses() {
  const methods = useFormContext<UserInfoForm>();
  const password = useWatch({ control: methods.control, name: 'password' });

  return (
    <div className={container}>
      {PASSWORD_VALIDATIONS.map(({ label, reg }) => {
        const isValid = reg.test(password);

        return (
          <div className={validationStatus({ isValid })}>
            <img src={isValid ? ValidIcon : InvalidIcon} alt='Validation Status' />

            {label}
          </div>
        );
      })}
    </div>
  );
}

export default PasswordValidationStatuses;

const PASSWORD_VALIDATIONS = [
  {
    label: '영문',
    reg: PASSWORD_REGEX.alphabet,
  },
  {
    label: '숫자',
    reg: PASSWORD_REGEX.number,
  },
  {
    label: '특수문자',
    reg: PASSWORD_REGEX.specialCharacter,
  },
  {
    label: '8자리 이상',
    reg: PASSWORD_REGEX.minDigit,
  },
  {
    label: '20자리 이하',
    reg: PASSWORD_REGEX.maxDigit,
  },
];
