import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';
import { HandSpaceOver } from 'types/organization';

const APIS: Apis = {
  removeSpace: { method: 'DELETE', url: '/api/organization' },
  leaveSpace: { method: 'POST', url: '/api/organization/exit' },
  handSpaceOver: { method: 'POST', url: '/api/organization/transfer/request' },
  cancelHandSpaceOver: { method: 'POST', url: '/api/organization/transfer/request/cancel' },
  getTakingOverSpaceStatus: { method: 'GET', url: '/api/organization/transfer-request/:id' },
  takeOverSpaceWithoutPayment: { method: 'POST', url: '/api/organization/:id/transfer/accept' },
  takeOverSpaceWithPayment: {
    method: 'POST',
    url: '/api/organization/:id/transfer/accept-with-payment',
  },
};

export const createRemoveSpaceRequest = () =>
  createHttpRequest<{ message: 'ok' }>({ ...APIS.removeSpace });

export const createLeaveSpaceRequest = () =>
  createHttpRequest<{ message: 'ok' }>({ ...APIS.leaveSpace });

export const createHandSpaceOverRequest = (email: string) =>
  createHttpRequest<{ message: 'ok' }>({ ...APIS.handSpaceOver, data: { email } });

export const createCancelHandSpaceOverRequest = () =>
  createHttpRequest<{ message: 'ok' }>({ ...APIS.cancelHandSpaceOver });

export const createTakeOverSpaceWithoutPaymentRequest = ({
  organizationOid,
}: {
  organizationOid: string;
}) =>
  createHttpRequest<{ message: 'ok' }>({
    ...APIS.takeOverSpaceWithoutPayment,
    params: { id: organizationOid },
    withoutOrganizationOid: true,
  });

export const createTakeOverSpaceWithPaymentRequest = (data: {
  organizationOid: string;
  authKey: string;
  customerKey: string;
}) =>
  createHttpRequest<{ message: 'ok' }>({
    ...APIS.takeOverSpaceWithPayment,
    params: { id: data.organizationOid },
    data: {
      authKey: data.authKey,
      customerKey: data.customerKey,
    },
    withoutOrganizationOid: true,
  });

export const createGetHandSpaceOverRequest = ({ organizationOid }: { organizationOid: string }) =>
  createHttpRequest<HandSpaceOver>({
    ...APIS.getTakingOverSpaceStatus,
    params: { id: organizationOid },
    withoutOrganizationOid: true,
  });
