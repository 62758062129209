import { Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { Plan } from '@pickme/core';
import { t } from 'i18next';

type Props = {
  isVisible: boolean;
  plan: Plan;
  kind: 'election' | 'survey';
  onClose: () => void;
  onSubmit: () => void;
};

function ChangeSubscriptionModal({ isVisible, kind, plan, onClose, onSubmit }: Props) {
  return (
    <Modal isVisible={isVisible} onClose={onClose} size='md'>
      <ModalHeader title={getModalTitle(kind, plan)} onClose={onClose} />

      <ModalBody>
        <Text type='b3' fontWeight={400} color='gray-400'>
          {kind === 'election'
            ? t(
                'admin:components.features.application.form.basic.modal.changePlan.description.election',
              )
            : t(
                'admin:components.features.application.form.basic.modal.changePlan.description.survey',
              )}
        </Text>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t(
              'admin:components.features.application.form.basic.modal.changePlan.actions.cancel',
            ),
            type: 'button',
            variant: 'gray',
            onClick: onClose,
          },
          {
            text: t(
              'admin:components.features.application.form.basic.modal.changePlan.actions.submit',
            ),
            type: 'button',
            variant: 'primary',
            onClick: onSubmit,
          },
        ]}
      />
    </Modal>
  );
}

export default ChangeSubscriptionModal;

const getModalTitle = (kind: 'election' | 'survey', plan: Plan) => {
  if (kind === 'election') {
    switch (plan) {
      case Plan.Free:
        return t(
          'admin:components.features.application.form.basic.modal.changePlan.title.election.free',
        );
      case Plan.Basic:
        return t(
          'admin:components.features.application.form.basic.modal.changePlan.title.election.basic',
        );
      case Plan.Standard:
        return t(
          'admin:components.features.application.form.basic.modal.changePlan.title.election.standard',
        );
      case Plan.Premium:
        return t(
          'admin:components.features.application.form.basic.modal.changePlan.title.election.premium',
        );
      case Plan.Enterprise:
        return t(
          'admin:components.features.application.form.basic.modal.changePlan.title.election.enterprise',
        );
      case Plan.Campus:
        return t(
          'admin:components.features.application.form.basic.modal.changePlan.title.election.campus',
        );
      default:
        return '';
    }
  }

  switch (plan) {
    case Plan.Free:
      return t(
        'admin:components.features.application.form.basic.modal.changePlan.title.survey.free',
      );
    case Plan.Basic:
      return t(
        'admin:components.features.application.form.basic.modal.changePlan.title.survey.basic',
      );
    case Plan.Standard:
      return t(
        'admin:components.features.application.form.basic.modal.changePlan.title.survey.standard',
      );
    case Plan.Premium:
      return t(
        'admin:components.features.application.form.basic.modal.changePlan.title.survey.premium',
      );
    case Plan.Enterprise:
      return t(
        'admin:components.features.application.form.basic.modal.changePlan.title.survey.enterprise',
      );
    case Plan.Campus:
      return t(
        'admin:components.features.application.form.basic.modal.changePlan.title.survey.campus',
      );
    default:
      return '';
  }
};
