import { Outlet } from 'react-router-dom';

import Navbar from 'components/common/layout/Payment/Navbar';

import './index.scss';

type Props = {
  title?: string;
};

function Payment({ title }: Props) {
  return (
    <>
      <meta name='viewport' content='width=1920, height=1080, user-scalable=no' />
      <main className='payment-layout'>
        <Navbar title={title} />

        <div className='payment-layout__body'>
          <main className='payment-layout__main'>
            <Outlet />
          </main>
        </div>
      </main>
    </>
  );
}

export default Payment;
