import { PollViewMode } from 'types/poll';

import TableViewIcon from 'resources/icons/dashboard/table_view.svg';
import GridViewIcon from 'resources/icons/dashboard/grid_view.svg';

import { button, container, image } from './index.css';

type Props = {
  mode: PollViewMode;
  setMode: (mode: PollViewMode) => void;
};

function PollsViewButtons({ mode, setMode }: Props) {
  return (
    <div className={container}>
      <button
        type='button'
        className={button({
          isActive: mode === PollViewMode.Table,
        })}
        onClick={() => setMode(PollViewMode.Table)}
      >
        <img className={image} src={TableViewIcon} alt='table' />
      </button>

      <button
        type='button'
        className={button({
          isActive: mode === PollViewMode.Card,
        })}
        onClick={() => setMode(PollViewMode.Card)}
      >
        <img className={image} src={GridViewIcon} alt='card' />
      </button>
    </div>
  );
}

export default PollsViewButtons;
