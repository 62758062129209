import { Switch, Text } from '@pickme/design-system';

import { useToggleMarketingAgreement } from 'query-hooks/user';

import { field } from '../common.css';
import { container } from './index.css';

type Props = {
  isAgree: boolean;
};

function MarketingAgreement({ isAgree }: Props) {
  const { mutate, isLoading } = useToggleMarketingAgreement();

  return (
    <div className={container}>
      <Text size={14} className={field.label}>
        마케팅 메시지 수신 동의
      </Text>

      <Switch
        checked={isAgree}
        disabled={isLoading}
        onChange={() => {
          mutate(!isAgree);
        }}
      />
    </div>
  );
}

export default MarketingAgreement;
