import { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useGetSessionAsUser, useGetSocialUserInfo } from 'query-hooks/auth';

import { SocialProvider } from 'types/auth';

function SocialCallback() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [searchParams] = useSearchParams(search);

  const provider = searchParams.get('provider');
  const accessToken = searchParams.get('accessToken');
  const refreshToken = searchParams.get('refreshToken');

  const { data: userSessionData } = useGetSessionAsUser({ suspense: true });

  if (!provider || !accessToken) {
    return <Navigate to='/' replace />;
  }

  const { data: userInfo } = useGetSocialUserInfo({
    provider: provider as SocialProvider,
    accessToken,
    refreshToken: refreshToken || undefined,
  });

  useEffect(() => {
    if (!userInfo) {
      return;
    }

    if (userSessionData) {
      navigate('integrate');
      return;
    }

    if (userInfo.isNew) {
      navigate(`signup`);
      return;
    }

    navigate('signin');
  }, [userInfo]);

  return <Outlet context={userInfo} />;
}

export default SocialCallback;
