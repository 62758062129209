import { MemberLevel, Plan, SpaceResponse } from '@pickme/core';

export type OAuthState = {
  authRedirectUrl: string;
  successRedirectUrl: string;
  type: 'sign' | 'withdraw' | 'connect';
};

export enum SignUpStep {
  Email = 'email',
  Otp = 'otp',
  Userinfo = 'userinfo',
  Complete = 'complete',
}

export type UserInfoForm = {
  mailing: boolean;
  phoneNumber: string;
  password: string;
  name: string;
};

export type SignUpFormBody = {
  phoneNumber: string;
  email: string;
  password: string;
  name: string;
  mailing: boolean;
};

export type SignUpRequestBody = {
  email: string;
  token: string;
  password: string;
  phoneNumber: string;
  name: string;
  mailing: boolean;
};

export type CredentialSignInBody = {
  email: string;
  password: string;
};

export enum SocialProvider {
  Google = 'google',
  Kakao = 'kakao',
  Naver = 'naver',
}

export type SocialUserBase = {
  provider: SocialProvider;
  socialKey: string;
  accessToken: string;
  refreshToken?: string;
};

export type SocialUserInfo = {
  name: string;
  email: string;
  isNew: boolean;
} & SocialUserBase;

export type SocialSignUpForm = {
  mailing: boolean;
};

export type SocialSignUpBody = {
  email: string;
  name: string;
  marketingAgreements: Marketing[];
  phoneNumber?: string;
} & SocialUserBase;

export type SocialSignInBody = Omit<SocialUserBase, 'provider'>;

export type SocialUserInfoQuery = Omit<SocialUserBase, 'socialKey'>;

export type SocialIntegrateRequestBody = Omit<SocialUserInfo, 'name' | 'isNew'>;

export type SendResetMailBody = {
  email: string;
};

export type SetOrganizationBody = {
  name: string;
};

export type ResetPasswordBody = {
  token: string;
  password: string;
  passwordChecker: string;
};

export type UserRemoveRequestBody = {
  password?: string;
};

export type ProfileAsMember = {
  type: 'admin';
  adminIds: string[];
  _id: string;
  id: string;
  email: string;
  phoneNumber?: string;
  name: string;
  expires: number;
  organizationAlias: string;
  organizationId: string;
  organizationName: string;
  level: MemberLevel;
  subscription: {
    organizationOid: string;
    type: Plan;
    voterLimit: number;
  };
};

export enum AuthMethodType {
  Credentials = 'credentials',
  Social = 'social',
}

export type AuthMethodResponse = CredentialAuthMethodResponse | SocialAuthMethodResponse;

export type CommonAuthMethodResponse = {
  email: string;
  isRoot: boolean;
};

export type CredentialAuthMethodResponse = {
  type: AuthMethodType.Credentials;
} & CommonAuthMethodResponse;

export type SocialAuthMethodResponse = {
  type: AuthMethodType.Social;
  provider: SocialProvider;
  socialKey: string;
  accessToken: string;
  refreshToken?: string;
} & CommonAuthMethodResponse;

export type ProfileAsUser = {
  type: 'admin';
  _id: string;
  name: string;
  authMethods: AuthMethodResponse[];
  phoneNumber?: string;
  notification: {
    marketingAgreements: ('email' | 'sms')[];
  };
  channelTalkMemberHash: string;
  participatedPromotionOids?: string[];
  isSuspended: boolean;
  verification: {
    phoneNumber: boolean;
  };
  organizations: SpaceResponse[];
};

export type Marketing = 'email' | 'sms';

export type EmailChangeFormBody = {
  email: string;
  password: string;
  newEmail: string;
  otp: string;
};
