import { useEffect } from 'react';
import { Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { MemberLevel } from '@pickme/core';
import { t } from 'i18next';

import { useGetAccount } from 'query-hooks/payment';
import { useGetOngoingPollCounts } from 'query-hooks/poll';

import { useGetActiveSpace } from 'hooks/useGetActiveSpace';

import './index.scss';

const DESCRIPTION = {
  owner: {
    title: t('admin:components.features.space.modal.leaveSpace.description.owner.title'),
    contents: [
      t('admin:components.features.space.modal.leaveSpace.description.owner.contents.0'),
      t('admin:components.features.space.modal.leaveSpace.description.owner.contents.1'),
      t('admin:components.features.space.modal.leaveSpace.description.owner.contents.2'),
    ],
  },
  manager: {
    title: t('admin:components.features.space.modal.leaveSpace.description.manager.title'),
    contents: [
      t('admin:components.features.space.modal.leaveSpace.description.manager.contents.0'),
      t('admin:components.features.space.modal.leaveSpace.description.manager.contents.1'),
      t('admin:components.features.space.modal.leaveSpace.description.manager.contents.2'),
    ],
  },
};

type Props = {
  isVisible: boolean;
  onClose: () => void;
  onSubmitButtonClick: () => void;
  memberLevel: MemberLevel;
};

function LeaveSpaceModal({ isVisible, onClose, onSubmitButtonClick, memberLevel }: Props) {
  const title =
    memberLevel === 'OrganAdmin'
      ? t('admin:components.features.space.modal.leaveSpace.title.delete')
      : t('admin:components.features.space.modal.leaveSpace.title.leave');
  const submitButtonText =
    memberLevel === 'OrganAdmin'
      ? t('admin:components.features.space.modal.leaveSpace.buttons.submit.delete')
      : t('admin:components.features.space.modal.leaveSpace.buttons.submit.leave');
  const description = memberLevel === 'OrganAdmin' ? DESCRIPTION.owner : DESCRIPTION.manager;

  const organizationData = useGetActiveSpace();
  const { data: accountData } = useGetAccount();
  const { data: counts, refetch } = useGetOngoingPollCounts();

  useEffect(() => {
    if (isVisible) {
      refetch();
    }
  }, [isVisible]);

  return (
    <Modal size='lg' isVisible={isVisible} onClose={onClose} className='leave-space-modal'>
      <ModalHeader title={title} onClose={onClose} />

      <ModalBody>
        <Text type='b3' color='gray-400'>
          {t('admin:components.features.space.modal.leaveSpace.message')}
        </Text>

        <div className='leave-space-modal__info'>
          {[
            {
              title: t('admin:components.features.space.modal.leaveSpace.info.organizationName'),
              content: organizationData?.name,
            },
            {
              title: t('admin:components.features.space.modal.leaveSpace.info.poll'),
              content: `${counts ?? 0}${t('admin:terms.unit.stuff')}`,
            },
            {
              title: t('admin:components.features.space.modal.leaveSpace.info.point'),
              content: accountData?.balance.print(),
            },
          ].map((item) => (
            <div key={item.title} className='leave-space-modal__info__row'>
              <div className='leave-space-modal__info__row__title'>
                <Text type='b2' fontWeight={600} color='gray-600'>
                  {item.title}
                </Text>
              </div>
              <div className='leave-space-modal__info__row__content'>
                <Text type='b2' fontWeight={400} color='gray-600'>
                  {item.content}
                </Text>
              </div>
            </div>
          ))}
        </div>

        <div className='leave-space-modal__callout'>
          <Text type='b2' color='error-500' fontWeight={600}>
            {description.title}
          </Text>

          <div className='leave-space-modal__callout__contents'>
            {description.contents.map((text, index) => (
              <li key={`leave-space-callout-${index}`}>
                <Text type='b2' color='gray-600' fontWeight={400}>
                  {text}
                </Text>
              </li>
            ))}
          </div>
        </div>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t('admin:components.features.space.modal.leaveSpace.buttons.cancel'),
            variant: 'gray',
            onClick: onClose,
          },
          {
            text: submitButtonText,
            variant: 'error',
            onClick: onSubmitButtonClick,
          },
        ]}
      />
    </Modal>
  );
}

export default LeaveSpaceModal;
