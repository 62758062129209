import { useSetRecoilState } from 'recoil';

import { toast } from 'states/toast';

type CopyFunctionParameter = {
  text: string;
  successMessage?: string;
  failMessage?: string;
  onCopied?: () => void;
};

const DEFAULT_SUCCESS_MESSAGE = '복사되었습니다.';

export const useClipboardCopy = () => {
  const setToast = useSetRecoilState(toast);

  const { clipboard } = window.navigator;

  const copy = async ({ text, successMessage, failMessage, onCopied }: CopyFunctionParameter) => {
    try {
      await clipboard.writeText(text);

      onCopied?.();

      setToast({
        isVisible: true,
        message: successMessage || DEFAULT_SUCCESS_MESSAGE,
        type: 'success',
      });
    } catch (error) {
      setToast({
        isVisible: true,
        message: failMessage || '복사에 실패했습니다. 다시 시도해주세요.',
        type: 'error',
      });
    }
  };

  return copy;
};
