import { useState } from 'react';
import { t } from 'i18next';
import { useSetRecoilState } from 'recoil';
import { PLANS, Plan } from '@pickme/core';
import { Dropdown, Text } from '@pickme/ui';

import SubscriptionBadge from 'components/features/badge/SubscriptionBadge';
import ChangeSubscriptionModal from 'components/features/application/modal/ChangeSubscription';

import { toast } from 'states/toast';

import { useGetFeatures } from 'hooks/useGetFeatures';
import { usePollStarted } from 'hooks/poll/usePollData';

import DownArrowIcon from 'resources/icons/application/kind/down.png';
import { container } from './index.css';

type Props = {
  isEdit?: boolean;
  kind: 'election' | 'survey';
  disabled?: boolean;
  selectedPlan: Plan;
  onSelect: (plan: Plan) => void;
};

function PlanField({ isEdit, disabled, kind, selectedPlan, onSelect }: Props) {
  const setToast = useSetRecoilState(toast);
  const { isPaymentFailed, type: spacePlan } = useGetFeatures();
  const availableTypes = new Set([Plan.Free, spacePlan]);

  const isPollStarted = usePollStarted();

  const [changeSubscriptionModal, setChangeSubscriptionModal] = useState<{
    type: Plan;
    isVisible: boolean;
  }>({
    type: Plan.Free,
    isVisible: false,
  });

  return (
    <>
      <Dropdown
        disabled={isPollStarted || disabled}
        button={
          <div className={container}>
            <SubscriptionBadge type={selectedPlan} />
            <Text type='b3' fontWeight={600}>
              {PLANS[selectedPlan].name.kor}
            </Text>
            <img src={DownArrowIcon} alt='arrow' />
          </div>
        }
        // @ts-ignore
        items={Array.from(availableTypes).map((type) => ({
          text: (
            <>
              <SubscriptionBadge type={type} />
              <Text type='b3' fontWeight={600}>
                {PLANS[type].name.kor}
              </Text>
            </>
          ),
          type: 'button',
          onClick: () => {
            if (type !== Plan.Free && isPaymentFailed) {
              setToast({
                isVisible: true,
                type: 'error',
                message: t(
                  'admin:components.features.application.form.basic.errorMessages.subscription',
                ),
              });
              return;
            }

            if (isEdit && selectedPlan !== type) {
              setChangeSubscriptionModal({
                isVisible: true,
                type,
              });
            } else {
              onSelect(type);
            }
          },
        }))}
        direction='down'
        directionBase='left'
      />

      <ChangeSubscriptionModal
        isVisible={changeSubscriptionModal.isVisible}
        kind={kind}
        plan={changeSubscriptionModal.type}
        onClose={() =>
          setChangeSubscriptionModal({
            ...changeSubscriptionModal,
            isVisible: false,
          })
        }
        onSubmit={() => {
          onSelect(changeSubscriptionModal.type);
          setChangeSubscriptionModal({
            ...changeSubscriptionModal,
            isVisible: false,
          });
        }}
      />
    </>
  );
}

export default PlanField;
