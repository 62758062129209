import * as yup from 'yup';
import { isNaN, isNumber } from 'lodash-es';

import { Candidate, CandidateElectionItem, ElectionTypeCase, Person } from 'types/application-v2';
import {
  CANDIDATE_TEAM_NAME_MAX_LENGTH,
  ELECTION_DESCRIPTION_MAX_LENGTH,
  ELECTION_TITLE_MAX_LENGTH,
  RANK_WEIGHT_MAX_VALUE,
  RANK_WEIGHT_MIN_VALUE,
  SCORE_MAX_VALUE,
  SCORE_MIN_VALUE,
} from 'constants/application-v2';

export const getCandidateElectionFormSchema = (): yup.ObjectSchema<{
  candidateElections: CandidateElectionItem[];
}> =>
  yup.object().shape({
    candidateElections: yup
      .array()
      .of(candidateElectionItemSchema)
      .required('선거를 1개 이상 등록해주세요')
      .min(1, '선거를 1개 이상 등록해주세요'),
  });

const personSchema: yup.ObjectSchema<Person> = yup.object({
  id: yup.string().optional(),
  name: yup.string().required('후보자 이름을 입력해주세요'),
  department: yup.object({
    enabled: yup.boolean(),
    value: yup.string(),
  }),
  position: yup.object({
    enabled: yup.boolean(),
    value: yup.string(),
  }),
  biography: yup.object({
    enabled: yup.boolean(),
    value: yup.array().of(
      yup.object({
        date: yup.string(),
        description: yup.string(),
      }),
    ),
  }),
  imageUrl: yup.object({
    enabled: yup.boolean(),
    value: yup.string().url(),
  }),
});

const candidateSchema: yup.ObjectSchema<Candidate> = yup.object({
  id: yup.string().optional(),
  title: yup
    .string()
    .required('후보명을 입력해 주세요')
    .max(
      CANDIDATE_TEAM_NAME_MAX_LENGTH,
      `후보명은 ${CANDIDATE_TEAM_NAME_MAX_LENGTH}자 이하로 입력해주세요.`,
    ),
  imageUrls: yup
    .object({
      main: yup.string().url().optional(),
      others: yup.array().of(yup.string().url().optional()).optional(),
    })
    .optional(),
  members: yup
    .array()
    .of(personSchema)
    .required('후보자 정보를 입력해주세요')
    .min(1, '후보자 정보를 입력해주세요'),
  pledges: yup.array().of(yup.string().optional()).optional(),
  introduction: yup.string().optional(),
  social: yup.object({
    facebook: yup.string().url().optional(),
    instagram: yup.string().url().optional(),
    youtube: yup.string().url().optional(),
    blog: yup.string().url().optional(),
    website: yup.string().url().optional(),
  }),
});

const candidateElectionItemSchema: yup.ObjectSchema<CandidateElectionItem> = yup.object({
  key: yup.string().required(),
  type: yup.mixed<ElectionTypeCase>().oneOf(Object.values(ElectionTypeCase)).required(),
  title: yup
    .string()
    .max(
      ELECTION_TITLE_MAX_LENGTH,
      `선거 제목은 ${ELECTION_TITLE_MAX_LENGTH}자 이하로 입력해주세요.`,
    )
    .required('선거 제목을 입력해주세요.'),
  description: yup
    .string()
    .optional()
    .max(
      ELECTION_DESCRIPTION_MAX_LENGTH,
      `선거 설명은 ${ELECTION_DESCRIPTION_MAX_LENGTH}자 이하로 입력해주세요.`,
    ),
  imageUrl: yup.string().url().strip(),
  candidates: yup.array().of(candidateSchema).required().min(1, '최소 1명의 후보를 등록해 주세요.'),
  allowMultipleSelectionEnabled: yup.boolean().optional(),
  allowMultipleSelectionMinValue: yup
    .number()
    .when(['type', 'allowMultipleSelectionEnabled'], ([type, enabled], schema) => {
      if (!enabled || type !== ElectionTypeCase.선택투표) {
        return schema.strip();
      }

      return schema.required('최소 선택 수를 입력해 주세요').min(1, '최소 선택 수를 입력해 주세요');
    })
    .test(
      'min-less-than-max',
      '최소 선택 수는 최대 선택 수보다 작아야 합니다.',
      (value, context) => {
        const { type, allowMultipleSelectionMaxValue, allowMultipleSelectionEnabled } =
          context.parent;
        if (!allowMultipleSelectionEnabled || type !== ElectionTypeCase.선택투표) {
          return true;
        }

        if (!value) {
          return false;
        }

        return value <= allowMultipleSelectionMaxValue;
      },
    ),
  allowMultipleSelectionMaxValue: yup
    .number()
    .when(['type', 'allowMultipleSelectionEnabled'], ([type, enabled], schema) => {
      if (!enabled || type !== ElectionTypeCase.선택투표) {
        return schema.strip();
      }

      return schema.required('최대 선택 수를 입력해 주세요').min(1, '최대 선택 수를 입력해 주세요');
    })
    .test(
      'max-greater-than-min',
      '최대 선택 수는 최소 선택 수보다 커야 합니다.',
      (value, context) => {
        const { type, allowMultipleSelectionEnabled, candidates } = context.parent;
        if (!allowMultipleSelectionEnabled || type !== ElectionTypeCase.선택투표) {
          return true;
        }

        if (!value) {
          return false;
        }

        if (!candidates?.length) {
          return true;
        }

        return value <= candidates.length;
      },
    ),
  minScore: yup.number().when(['type'], ([type], schema) => {
    if (type !== ElectionTypeCase.점수투표) {
      return schema.strip();
    }

    return schema
      .required('최소 점수를 입력해 주세요')
      .min(SCORE_MIN_VALUE, `최소 ${SCORE_MIN_VALUE}점 이상 입력해 주세요.`)
      .max(SCORE_MAX_VALUE, `최대 ${SCORE_MAX_VALUE}점 이하로 입력해 주세요.`)
      .test('min-less-than-max', '최소 점수는 최대 점수보다 작아야 합니다.', (value, context) => {
        const { maxScore } = context.parent;
        if (!maxScore) {
          return true;
        }

        return value < maxScore;
      });
  }),
  maxScore: yup.number().when(['type', 'minScore'], ([type], schema) => {
    if (type !== ElectionTypeCase.점수투표) {
      return schema.strip();
    }

    return schema
      .required('최소 점수를 입력해 주세요')
      .min(SCORE_MIN_VALUE, `최소 ${SCORE_MIN_VALUE}점 이상 입력해 주세요.`)
      .max(SCORE_MAX_VALUE, `최대 ${SCORE_MAX_VALUE}점 이하로 입력해 주세요.`);
  }),
  rank: yup.number().when('type', (type, schema) => {
    if (type[0] === ElectionTypeCase.순위투표) {
      return schema
        .required('순위를 입력해 주세요')
        .min(1, '순위를 입력해 주세요')
        .test(
          'rank-less-than-candidate-count',
          '순위는 후보자 수보다 작아야 합니다.',
          (value, context) => {
            const { candidates } = context.parent;

            return value <= candidates.length;
          },
        );
    }

    return schema.strip();
  }),
  rankWeights: yup
    .array()
    .of(yup.number().optional())
    .when('type', (type, schema) => {
      if (type[0] !== ElectionTypeCase.순위투표) {
        return schema.strip();
      }

      return schema
        .required('순위 점수를 입력해 주세요')
        .test(
          'rank-weight-count-equal-rank',
          '순위 점수의 개수와 순위의 개수가 같아야 합니다.',
          (rankWeights, context) => {
            const { rank } = context.parent;

            let path = '';
            let message = '';
            rankWeights.forEach((rankWeight, index) => {
              if (!rankWeight || typeof rankWeight !== 'number' || isNaN(rankWeight)) {
                if (!path && !message) {
                  path = `${context.path}.${index}`;
                  message = '순위 점수를 입력해주세요.';
                  return;
                }
              }

              if (typeof rankWeight !== 'number') {
                if (!path && !message) {
                  path = `${context.path}.${index}`;
                  message = '순위 점수를 입력해주세요.';
                  return;
                }
                return;
              }

              if (rankWeight && rankWeight < RANK_WEIGHT_MIN_VALUE) {
                if (!path && !message) {
                  path = `${context.path}.${index}`;
                  message = `${RANK_WEIGHT_MIN_VALUE}에서 ${RANK_WEIGHT_MAX_VALUE} 사이의 값을 입력해 주세요.`;
                }
              }

              if (rankWeight && rankWeight > RANK_WEIGHT_MAX_VALUE) {
                if (!path && !message) {
                  path = `${context.path}.${index}`;
                  message = `${RANK_WEIGHT_MIN_VALUE}에서 ${RANK_WEIGHT_MAX_VALUE} 사이의 값을 입력해 주세요.`;
                }
              }
            });

            if (path && message) {
              return context.createError({
                path,
                message,
              });
            }

            if (rankWeights.length !== rank) {
              return context.createError({
                path: 'rankWeights',
                message: '순위 점수의 개수와 순위의 개수가 같아야 합니다.',
              });
            }

            for (let i = 1; i < rankWeights.length; i += 1) {
              const prevValue = rankWeights[i - 1];
              const currentValue = rankWeights[i];
              if (isNumber(prevValue) && isNumber(currentValue)) {
                if (currentValue > prevValue) {
                  return context.createError({
                    path: `${context.path}.${i}`,
                    message: '순위 점수는 이전 값보다 크지 않아야 합니다.',
                  });
                }
              }
            }

            return true;
          },
        );
    }),
  shuffle: yup.boolean().optional(),
  includeAbstention: yup.boolean().optional(),
});
