import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';

import { faceMessageModal } from 'states/modal';

import FaceIcon from 'resources/icons/application/face.png';

import './index.scss';

function FaceMessageModal() {
  const { isVisible, title, message, buttonText } = useRecoilValue(faceMessageModal);
  const setMessageModal = useSetRecoilState(faceMessageModal);

  const onClose = () => {
    setMessageModal({ isVisible: false, title, message, buttonText });

    setTimeout(() => {
      setMessageModal({ isVisible: false, title: '', message: '', buttonText: '' });
    }, 300);
  };

  return (
    <Modal className='face-message-modal' isVisible={isVisible} onClose={onClose}>
      <img className='face-message-modal__image' src={FaceIcon} alt='face' />

      <ModalHeader size='h4' title={title} onClose={onClose} centered hideCloseButton />

      <ModalBody centered>
        <Text size='sm1' fontWeight={300} color='gray-400'>
          {message}
        </Text>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: buttonText,
            onClick: onClose,
          },
        ]}
      />
    </Modal>
  );
}

export default FaceMessageModal;
