import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Spinner } from '@pickme/ui';
import { Input, Button } from '@pickme/design-system';

import SignUpTerms from 'components/features/auth/form/signup/UserInfo/Terms';
import PasswordValidationStatuses from 'components/features/auth/form/signup/UserInfo/PasswordValidationStatuses';

import { nameValidator, passwordValidator } from 'functions/validator/auth';
import { phoneNumberValidator } from 'functions/validator/voter';

import { UserInfoForm } from 'types/auth';

import { submitButton } from 'pages/auth/common.css';
import { container, fields, passwordField } from './index.css';

type Props = {
  isLoading: boolean;
  onSubmit: SubmitHandler<{
    mailing: boolean;
    phoneNumber: string;
    password: string;
    name: string;
  }>;
};

function SignUpUserInfoForm({ isLoading, onSubmit }: Props) {
  const methods = useForm<UserInfoForm>({
    mode: 'onTouched',
    defaultValues: {
      phoneNumber: '',
      password: '',
      name: '',
      mailing: true,
    },
  });
  const { errors, isValid } = methods.formState;

  const [agreeTerms, setAgreeTerms] = useState(true);
  const [agreePrivacy, setAgreePrivacy] = useState(true);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className={container}>
          <div className={fields}>
            <Input
              width='100%'
              size='lg'
              placeholder='이름'
              type='text'
              id='name'
              {...methods.register('name', {
                validate: (value) => nameValidator(value),
                required: true,
              })}
              isValid={!errors.name || !methods.watch('name')}
            />

            <Input
              width='100%'
              size='lg'
              placeholder='휴대폰 번호'
              type='tel'
              id='phone'
              {...methods.register('phoneNumber', {
                validate: (value) =>
                  phoneNumberValidator(value) || '올바른 휴대폰 번호를 입력해 주세요',
                required: true,
              })}
              isValid={!errors.phoneNumber || !methods.watch('phoneNumber')}
              errorMessage={errors.phoneNumber?.message}
            />

            <div className={passwordField}>
              <Input
                width='100%'
                size='lg'
                placeholder='비밀번호 영문, 숫자, 특수문자 8~20자리'
                type='password'
                id='password'
                autoComplete='off'
                {...methods.register('password', {
                  validate: (value) => passwordValidator(value),
                  required: true,
                })}
                isValid={!errors.password || !methods.watch('password')}
                errorMessage={errors.password?.message}
              />

              <PasswordValidationStatuses />
            </div>
          </div>

          <SignUpTerms
            agreeTerms={agreeTerms}
            setAgreeTerms={setAgreeTerms}
            agreePrivacy={agreePrivacy}
            setAgreePrivacy={setAgreePrivacy}
          />
        </div>

        <Button
          fullWidth
          size='xl'
          variant='primary'
          type='submit'
          disabled={!isValid || !agreePrivacy || !agreeTerms || isLoading}
          className={submitButton}
        >
          {isLoading ? <Spinner /> : '가입하기'}
        </Button>
      </form>
    </FormProvider>
  );
}

export default SignUpUserInfoForm;
