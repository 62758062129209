import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useGetPoll } from 'query-hooks/poll';

import './PollPreviewer.scss';

function PollPreviewer() {
  const { id = '' } = useParams<{ id: string }>();

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { data: poll, isError } = useGetPoll(id);

  useEffect(() => {
    if (isError) {
      window.open('', '_self')?.close();
    }
  }, [isError]);

  useEffect(() => {
    const onSendPollData = ({ data, origin }: MessageEvent) => {
      const disallowed = origin !== import.meta.env.VITE_USER_URL || !poll?._id || data !== 'set';
      if (disallowed) {
        return;
      }

      if (iframeRef.current && iframeRef.current.contentWindow) {
        iframeRef.current.contentWindow.postMessage(poll, import.meta.env.VITE_USER_URL);
      }
    };

    window.addEventListener('message', onSendPollData);

    return () => window.removeEventListener('message', onSendPollData);
  });

  return (
    <iframe
      className='poll-previewer'
      id={`poll-previewer-${id}`}
      title={`preview ${id}`}
      src={`${import.meta.env.VITE_USER_URL}/embed/poll`}
      ref={iframeRef}
    />
  );
}

export default PollPreviewer;
