export default {
  title: '결제',
  cards: {
    subscription: {
      title: '구독 정보',
      button: {
        subscribe: '결제하기',
        cancelToDiscard: '구독 중단 취소',
        cancelToReplace: '신청 취소',
        replacePlan: '변경하기',
        replaceVoterLimit: '변경하기',
        discardSubscription: '중단하기',
        replaceCard: '결제 카드 변경',
        replaceEmail: '이메일 변경',
        purchaseMoreVoters: '인원 추가 결제하기',
      },
      description: {
        replacePlan: {
          current: ['{{plan}} 플랜', '을 사용중입니다.'],
          forFreePlan: '플랜별 상세 안내를 확인하고 구독을 시작하세요',
          forPaidPlan: '플랜을 변경하고 싶으신가요? ',
        },
        replaceVoterLimit: '투표인 수를 변경하고 싶으신가요? ',
        scheduledToDiscard: {
          discardAt: ['{{date}}에 ', '구독이 중단될 예정', '입니다.'],
          cancel: '구독 중단 취소를 누르면 원래대로 다음 정기 결제 예정일에 구독이 갱신됩니다.',
        },
        scheduledToReplacePlan: {
          preview: ['{{plan}} 플랜', '에서 ', '{{plan}}', '으로 변경될 예정입니다'],
          replaceAt: '{{date}} 부터 변경된 플랜으로 적용됩니다.',
        },
        discardSubscription: '구독을 중단하고 싶으신가요?',
      },
    },
    payment: {
      title: '결제 정보',
    },
  },
  labels: {
    voterLimit: '투표인 수',
    period: '이용 기간',
    card: '결제 카드',
  },
  callout: {
    failure: [
      '결제 정보 오류로 인해 구독 결제가 처리되지 못했어요. 픽미에서 결제를 다시 시도하겠지만 7일 후 유료 플랜 기능 이용이 중단되지 않도록 결제 정보를 업데이트해주세요.',
    ],
  },
  errorMessages: {
    card: {
      title: '카드 변경에 실패했습니다.',
      message: '실패 사유: {{message}}',
    },
  },
};
