/* eslint-disable react/jsx-no-useless-fragment */
import { PropsWithChildren, useCallback, useEffect, useRef } from 'react';

import { trigger } from './index.css';

type Props = PropsWithChildren<{
  onScrollFinished: () => void;
}>;

function InfiniteScrollHandler({ children, onScrollFinished }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const onIntersection: IntersectionObserverCallback = useCallback(
    (entries) => {
      const [target] = entries;
      if (target.isIntersecting) {
        onScrollFinished();
      }
    },
    [onScrollFinished],
  );

  useEffect(() => {
    let observer: IntersectionObserver;

    if (ref.current && children) {
      const option = { threshold: 0 };

      observer = new IntersectionObserver(onIntersection, option);
      observer.observe(ref.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [onIntersection, children]);

  return (
    <>
      {children}
      <div className={trigger} ref={ref} />
    </>
  );
}

export default InfiniteScrollHandler;
