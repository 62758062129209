export default {
  form: {
    fields: {
      phoneNumber: {
        placeholder: '전화번호 입력',
      },
    },
  },
  buttons: {
    sendOTP: '전송완료',
    verify: '인증하기',
  },
  messages: {
    notVerified: '인증되지 않은 전화번호입니다.',
  },
};
