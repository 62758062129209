import { useEffect, useState } from 'react';
import { Tabs } from '@pickme/design-system';
import { Modal, ModalActions, ModalBody, ModalHeader } from '@pickme/ui';

import FileUploadField from 'components/features/application/modal/Cover/FileUploadField';
import UnsplashField from 'components/features/application/modal/Cover/UnsplashField';

import { container } from './index.css';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (imageUrl: string) => void;
};

enum TabId {
  File = 'file',
  Unsplash = 'unsplash',
}

const TABS = [
  { id: TabId.File, label: '업로드' },
  { id: TabId.Unsplash, label: 'Unsplash' },
];

function CoverModal({ isVisible, onClose, onSubmit }: Props) {
  const [tabIndex, setTabIndex] = useState(0);

  const [imageUrlMap, setImageUrlMap] = useState<{ [key in TabId]: string }>({
    [TabId.File]: '',
    [TabId.Unsplash]: '',
  });

  useEffect(() => {
    if (!isVisible) {
      setTabIndex(0);
      setImageUrlMap({
        [TabId.File]: '',
        [TabId.Unsplash]: '',
      });
    }
  }, [isVisible]);

  return (
    <Modal isVisible={isVisible} onClose={onClose} size='lg'>
      <ModalHeader size='b1' title='이미지 등록하기' onClose={onClose} />

      <ModalBody className={container}>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={setTabIndex}
          items={TABS.map(({ label }, index) => ({ index, label }))}
        />

        {TABS[tabIndex].id === TabId.File && (
          <FileUploadField
            imageUrl={imageUrlMap.file}
            setImageUrl={(imageUrl) => {
              setImageUrlMap({
                ...imageUrlMap,
                file: imageUrl,
              });
            }}
          />
        )}

        {TABS[tabIndex].id === TabId.Unsplash && (
          <UnsplashField
            onSelect={(imageUrl) => {
              setImageUrlMap({
                ...imageUrlMap,
                unsplash: imageUrl,
              });
            }}
          />
        )}
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: '저장',
            type: 'button',
            disabled: !imageUrlMap[TABS[tabIndex].id],
            variant: 'primary',
            onClick: () => {
              onSubmit(imageUrlMap[TABS[tabIndex].id]);
              onClose();
            },
          },
        ]}
      />
    </Modal>
  );
}

export default CoverModal;
