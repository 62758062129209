import { useState } from 'react';
import { Text, PlainButton, Input } from '@pickme/design-system';
import { t } from 'i18next';

import PasswordChangeForm from 'components/features/my-page/form/PasswordChangeForm';

import { field } from '../common.css';

function MyPagePassword() {
  const [isEditMode, setIsEditMode] = useState(false);

  const onOpenEditMode = () => setIsEditMode(true);
  const onCloseEditMode = () => setIsEditMode(false);

  return (
    <div className={field.container}>
      <Text size={14} className={field.label}>
        {t('admin:components.features.myPage.password.title')}
      </Text>

      {isEditMode ? (
        <PasswordChangeForm onClose={onCloseEditMode} />
      ) : (
        <>
          <Input type='password' value='password' disabled size='lg' width={322} />

          <PlainButton onClick={onOpenEditMode} className={field.button({ hoverEffect: true })}>
            변경
          </PlainButton>
        </>
      )}
    </div>
  );
}

export default MyPagePassword;
