import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'persist-states',
});

const activeSpaceId = atom<string>({
  key: 'active-space-id',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

const invitationAlias = atom<string>({
  key: 'invitation-alias',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const spaceState = {
  activeSpaceId,
  invitationAlias,
};
