import { forwardRef, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Text, Input, Button } from '@pickme/design-system';
import { t } from 'i18next';

import { useResetPasswordWithToken } from 'query-hooks/auth';

import { useQueryString } from 'hooks/useQueryString';

import { passwordValidator } from 'functions/validator/auth';

import { ResetPasswordBody } from 'types/auth';

import { submitButton, title } from 'pages/auth/common.css';
import { fields } from './index.css';

function ResetPassword() {
  const { token } = useQueryString({ token: '' });

  const { isLoading, mutate } = useResetPasswordWithToken();

  const methods = useForm<ResetPasswordBody>({
    mode: 'onBlur',
    defaultValues: {
      password: '',
      passwordChecker: '',
    },
  });

  const {
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors },
  } = methods;

  useEffect(() => {
    if (token) {
      setValue('token', token);
    }
  }, [token]);

  const onSubmit: SubmitHandler<ResetPasswordBody> = (data) => {
    if (isLoading) {
      return;
    }

    mutate({ token: data.token, password: data.password });
  };

  const passwordValue = watch('password');

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text size={30} fontWeight={600} className={title}>
          {t('admin:components.features.auth.form.resetPassword.title')}
        </Text>

        <input {...register('token')} hidden />

        <div className={fields}>
          <Input
            width='100%'
            size='lg'
            type='password'
            id='password'
            placeholder='새 비밀번호를 입력해 주세요'
            {...register('password', {
              validate: (value) => {
                if (!value) {
                  return false;
                }

                if (!passwordValidator(value)) {
                  return t(
                    'admin:components.features.auth.form.resetPassword.errorMessages.password',
                  );
                }

                return true;
              },
              required: true,
            })}
            isValid={!errors.password}
            errorMessage={errors.password?.message}
          />

          <Input
            width='100%'
            size='lg'
            type='password'
            id='passwordChecker'
            placeholder={t(
              'admin:components.features.auth.form.resetPassword.fields.newPassword.placeholder',
            )}
            {...register('passwordChecker', {
              validate: (value) => {
                if (!value) {
                  return false;
                }

                if (value !== passwordValue) {
                  return t(
                    'admin:components.features.auth.form.resetPassword.errorMessages.passwordChecker',
                  );
                }

                return true;
              },
              required: true,
            })}
            isValid={!errors.passwordChecker}
            errorMessage={errors.passwordChecker?.message}
          />
        </div>

        <Button
          fullWidth
          size='xl'
          variant='primary'
          type='submit'
          disabled={!isValid || isLoading}
          className={submitButton}
        >
          {t('admin:components.features.auth.form.resetPassword.buttons.submit')}
        </Button>
      </form>
    </FormProvider>
  );
}

export default forwardRef(ResetPassword);
