export default {
  poll: {
    period: {
      disorder: '시작 시간을 현재 시간 이후로 설정해주세요',
      outOfContract: '기간을 월 계약 기간 이내로 설정해주세요',
      duplicated: '선거 기간이 서로 중복되지 않도록 설정해주세요',
    },
    voter: {
      missedName: '이름 누락',
      lackOfMethod: '인증수단 부재',
      emailFormat: '이메일 형식 오류',
      phoneNumberFormat: '전화번호 형식 오류',
      userIdDuplicated: '고유번호 중복',
      phoneNumberDuplicated: '전화번호 중복',
      emailDuplicated: '이메일 중복',
    },
  },
};
