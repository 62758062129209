import { useEffect } from 'react';
import moment from 'moment-timezone';
import { UseFormReturn } from 'react-hook-form';
import { Plan } from '@pickme/core';

import { useGetDraft } from 'query-hooks/draft';

import {
  AdditionalFormBody,
  BasicFormBody,
  QuestionsFormBody,
  SubElectionsFormBody,
  VoterAuthMethod,
  VoterFormBody,
} from 'types/application';

export function useInitializeElectionFormWithDraft(
  draftId: string,
  forms: {
    basicForm: UseFormReturn<BasicFormBody>;
    subElectionsForm: UseFormReturn<SubElectionsFormBody>;
    votersForm: UseFormReturn<VoterFormBody>;
    additionalForm: UseFormReturn<AdditionalFormBody>;
  },
) {
  const { data: draft, isFetched } = useGetDraft('election', draftId);
  const { basicForm, subElectionsForm, votersForm, additionalForm } = forms;

  useEffect(() => {
    if (isFetched && draft) {
      basicForm.setValue('subscriptionType', draft?.content.subscriptionType ?? Plan.Free, {
        shouldValidate: true,
        shouldDirty: true,
      });

      basicForm.setValue('name', draft?.content.name ?? '', {
        shouldValidate: true,
        shouldDirty: true,
      });

      basicForm.setValue('description', draft?.content.description ?? '', {
        shouldValidate: true,
        shouldDirty: true,
      });

      basicForm.setValue(
        'periods',
        draft?.content.periods?.map(({ startDate, endDate }) => ({
          startDate: startDate ? moment(startDate).tz('Asia/Seoul').format('YYYY-MM-DDTHH:mm') : '',
          endDate: endDate ? moment(endDate).tz('Asia/Seoul').format('YYYY-MM-DDTHH:mm') : '',
        })) || [
          {
            startDate: '',
            endDate: '',
          },
        ],
        {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        },
      );

      // NOTE: useFieldArray를 사용 시, trigger를 사용하지 않으면 초기 상태의 validation이 동작하지 않습니다.
      // 따라서 한번 더, trigger를 호출해주어야 합니다.
      basicForm.trigger('periods');

      basicForm.setValue('coverImageFileUrl', draft?.content.coverImageFileUrl ?? '', {
        shouldValidate: true,
        shouldDirty: true,
      });

      basicForm.setValue('isPublic', draft?.content.isPublic ?? false, {
        shouldValidate: true,
        shouldDirty: true,
      });

      additionalForm.setValue('isOpenPollRate', draft.content?.isOpenPollRate ?? false, {
        shouldValidate: true,
        shouldDirty: true,
      });

      additionalForm.setValue('isOpenPollResult', draft.content?.isOpenPollResult ?? false, {
        shouldValidate: true,
        shouldDirty: true,
      });

      additionalForm.setValue('openThreshold', draft.content?.openThreshold ?? 0, {
        shouldValidate: true,
        shouldDirty: true,
      });

      additionalForm.setValue(
        'voterAuthMethods',
        draft.content?.voterAuthMethods ?? [VoterAuthMethod.Email, VoterAuthMethod.Phone],
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );

      subElectionsForm.setValue('subElections', draft.content?.subElections || [], {
        shouldValidate: true,
        shouldDirty: true,
      });

      votersForm.setValue('voterBook', draft.content.voterBook || [], {
        shouldValidate: true,
        shouldDirty: true,
      });

      votersForm.setValue('incorrectVoters', draft.content.incorrectVoters || [], {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [isFetched, draft]);
}

export function useInitializeSurveyFormWithDraft(
  draftId: string,
  forms: {
    basicForm: UseFormReturn<BasicFormBody>;
    questionsForm: UseFormReturn<QuestionsFormBody>;
    votersForm: UseFormReturn<VoterFormBody>;
    additionalForm: UseFormReturn<AdditionalFormBody>;
  },
) {
  const { data: draft, isFetched } = useGetDraft('survey', draftId);
  const { basicForm, questionsForm, votersForm, additionalForm } = forms;

  useEffect(() => {
    if (isFetched && draft) {
      basicForm.setValue('subscriptionType', draft?.content.subscriptionType ?? Plan.Free, {
        shouldValidate: true,
        shouldDirty: true,
      });

      basicForm.setValue('name', draft?.content.name ?? '', {
        shouldValidate: true,
        shouldDirty: true,
      });

      basicForm.setValue('description', draft?.content.description ?? '', {
        shouldValidate: true,
        shouldDirty: true,
      });

      basicForm.setValue(
        'periods',
        draft?.content.periods?.map(({ startDate, endDate }) => ({
          startDate: startDate ? moment(startDate).tz('Asia/Seoul').format('YYYY-MM-DDTHH:mm') : '',
          endDate: endDate ? moment(endDate).tz('Asia/Seoul').format('YYYY-MM-DDTHH:mm') : '',
        })) || [
          {
            startDate: '',
            endDate: '',
          },
        ],
        {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        },
      );

      // NOTE: useFieldArray를 사용 시, trigger를 사용하지 않으면 초기 상태의 validation이 동작하지 않습니다.
      // 따라서 한번 더, trigger를 호출해주어야 합니다.
      basicForm.trigger('periods');

      basicForm.setValue('coverImageFileUrl', draft?.content.coverImageFileUrl ?? '', {
        shouldValidate: true,
        shouldDirty: true,
      });

      basicForm.setValue('isPublic', draft?.content.isPublic ?? false, {
        shouldValidate: true,
        shouldDirty: true,
      });

      additionalForm.setValue('isOpenPollRate', draft.content?.isOpenPollRate ?? false, {
        shouldValidate: true,
        shouldDirty: true,
      });

      additionalForm.setValue('isOpenPollResult', draft.content?.isOpenPollResult ?? false, {
        shouldValidate: true,
        shouldDirty: true,
      });

      additionalForm.setValue('allowRealTimeResult', draft.content?.allowRealTimeResult ?? false, {
        shouldValidate: true,
        shouldDirty: true,
      });

      additionalForm.setValue('hasOpenVotes', draft.content?.hasOpenVotes ?? false, {
        shouldValidate: true,
        shouldDirty: true,
      });

      additionalForm.setValue('openThreshold', draft.content?.openThreshold ?? 0, {
        shouldValidate: true,
        shouldDirty: true,
      });

      additionalForm.setValue(
        'voterAuthMethods',
        draft.content?.voterAuthMethods ?? [VoterAuthMethod.Email, VoterAuthMethod.Phone],
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );

      questionsForm.setValue('questions', draft.content?.questions || [], {
        shouldValidate: true,
        shouldDirty: true,
      });

      votersForm.setValue('hasInstantVoter', draft.content.hasInstantVoter || false, {
        shouldValidate: true,
        shouldDirty: true,
      });

      votersForm.setValue('voterBook', draft.content.voterBook || [], {
        shouldValidate: true,
        shouldDirty: true,
      });

      votersForm.setValue('incorrectVoters', draft.content.incorrectVoters || [], {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [isFetched, draft, draftId]);
}
