import { Plan } from '@pickme/core';

import { DraftTemplate, DraftTemplateCategory } from 'types/template';

import Thumbnail1 from 'resources/images/templates/survey/corporation1.png';
import Thumbnail2 from 'resources/images/templates/survey/corporation2.png';
import Thumbnail3 from 'resources/images/templates/survey/corporation3.png';
import Thumbnail4 from 'resources/images/templates/survey/corporation4.png';
import Thumbnail5 from 'resources/images/templates/survey/corporation5.png';
import Thumbnail6 from 'resources/images/templates/survey/corporation6.png';
import Thumbnail7 from 'resources/images/templates/survey/corporation7.png';

const DRAFT_1 = {
  title: '퀘스 재택근무 도입 설문 조사',
  _id: '645b674e7371bd01778ac612',
  content: {
    name: '퀘스 재택근무 도입 설문 조사',
    periods: [
      {
        startDate: '2023-10-10T20:12',
        endDate: '2023-10-31T20:12',
      },
    ],
    description:
      '<p>안녕하세요.</p><p><br></p><p>본 설문은 퀘스 구성원들을 대상으로 진행하는 재택근무 도입 설문 조사입니다.</p><p>여러분들의 의견을 자유롭게 들려주세요.</p><p><br></p><p>감사합니다.</p>',
    type: 'Survey',
    voterType: 'voterBook',
    subscriptionType: Plan.Free,
    questions: [
      {
        kind: 'RadioQuestion',
        title: '재택근무를 주간 몇 회 시행하는 걸 선호하시나요?',
        description: '',
        isRequired: false,
        selections: [
          {
            number: 1,
            description: '주 1회',
            fileUrl: '',
          },
          {
            description: '주 2회',
            number: 2,
            fileUrl: '',
          },
          {
            description: '주 3회',
            number: 3,
            fileUrl: '',
          },
          {
            description: '주 4회',
            number: 4,
            fileUrl: '',
          },
          {
            description: '주 5회',
            number: 5,
            fileUrl: '',
          },
          {
            description: '선호하지 않습니다.',
            number: 6,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
        id: '0c95639b-3476-4434-8c00-392e9d9cac23',
      },
      {
        kind: 'CheckboxQuestion',
        title: '재택근무 시 중요시 여기는 업무 환경은 무엇인가요?',
        description: '',
        isRequired: false,
        selections: [
          {
            number: 1,
            description: '소통 및 협업 툴',
            fileUrl: '',
          },
          {
            description: '모니터, 사무용 의자 등 사무 장비',
            number: 2,
            fileUrl: '',
          },
          {
            description: '업무 공간',
            number: 3,
            fileUrl: '',
          },
          {
            description: '기타',
            number: 4,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        responseValidation: {
          validation: 'lte',
          threshold: 4,
        },
        fileUrl: '',
      },
      {
        kind: 'ScaleQuestion',
        title: '지난 1차 재택근무 시험 운행시 만족도를 선택해주세요.',
        description: '',
        isRequired: false,
        selections: [],
        subQuestions: [],
        min: {
          value: 1,
          description: '불만족',
        },
        max: {
          value: 10,
          description: '만족',
        },
        fileUrl: '',
        id: 'ca80a2c7-2bd4-429a-9743-6d40fa3f5790',
      },
      {
        kind: 'RankQuestion',
        title: '재택근무 진행 시 사용될 협업툴을 선호 순서대로 선택해 주세요.',
        description: '',
        isRequired: false,
        selections: [],
        subQuestions: [
          {
            description: '잔디',
            fileUrl: '',
          },
          {
            description: '채널톡',
            fileUrl: '',
          },
          {
            description: '게더타운',
            fileUrl: '',
          },
          {
            description: '플로우',
            fileUrl: '',
          },
          {
            description: '스윗',
            fileUrl: '',
          },
          {
            description: '기타',
            fileUrl: '',
          },
        ],
        responseValidation: {
          rankLimit: 6,
        },
        fileUrl: '',
      },
      {
        kind: 'TextQuestion',
        title: '재택근무 도입에 있어 의견을 자유롭게 적어주세요.',
        description: '',
        isRequired: false,
        selections: [],
        subQuestions: [],
        responseValidation: {
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
        id: 'bc065886-f76d-4a32-ae82-b2272460c035',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-05-10T09:43:42.793Z',
  __v: 0,
};

const DRAFT_2 = {
  title: '픽미 구내식당 만족도 조사',
  _id: '645b64656a757501877231c2',
  content: {
    name: '픽미 구내식당 만족도 조사',
    periods: [
      {
        startDate: '2023-09-10T20:12',
        endDate: '2023-09-18T20:12',
      },
    ],
    description:
      '<p>안녕하세요.</p><p><br></p><p>본 설문은 픽미 구성원들을 대상으로 진행하는 구내 식당 만족도 조사입니다.</p><p>여러분들의 의견을 자유롭게 들려주세요!</p><p><br></p><p>감사합니다.</p>',
    type: 'Survey',
    voterType: 'voterBook',
    subscriptionType: Plan.Free,
    questions: [
      {
        kind: 'RadioQuestion',
        title: '일주일에 몇 번 구내식당을 이용하십니까?',
        description: '',
        isRequired: false,
        selections: [
          {
            number: 1,
            description: '1회 ~ 5회',
            fileUrl: '',
          },
          {
            description: '6회 ~ 10회',
            number: 2,
            fileUrl: '',
          },
          {
            description: '11회 ~ 15회',
            number: 3,
            fileUrl: '',
          },
          {
            description: '0번',
            number: 4,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
        id: '979af743-c5a8-47be-aae5-f3c54b6680f7',
      },
      {
        kind: 'ScaleQuestion',
        title: '식당의 위생상태에 대해선 어떻게 생각하십니까?',
        description: '',
        isRequired: false,
        selections: [],
        subQuestions: [],
        min: {
          value: 1,
          description: '불만족',
        },
        max: {
          value: 10,
          description: '만족',
        },
        fileUrl: '',
        id: '30751c15-2d8b-4cb1-acfd-43f763f641b3',
      },
      {
        kind: 'RankQuestion',
        title: '선호하는 순서대로 식재료를 선택해주세요.',
        description: '',
        isRequired: false,
        selections: [],
        subQuestions: [
          {
            description: '육류',
            fileUrl: '',
          },
          {
            description: '생선류',
            fileUrl: '',
          },
          {
            description: '계육',
            fileUrl: '',
          },
          {
            description: '야채',
            fileUrl: '',
          },
          {
            description: '가공품',
            fileUrl: '',
          },
        ],
        responseValidation: {
          rankLimit: 5,
        },
        fileUrl: '',
        id: '11cf93b2-9b72-44e7-a373-bb1f02364e76',
      },
      {
        kind: 'RankQuestion',
        title: '선호하는 순서대로 메뉴를 선택해주세요.',
        description: '',
        isRequired: false,
        selections: [],
        subQuestions: [
          {
            description: '탕류',
            fileUrl: '',
          },
          {
            description: '볶음류',
            fileUrl: '',
          },
          {
            description: '일품류',
            fileUrl: '',
          },
          {
            description: '찌개류',
            fileUrl: '',
          },
          {
            description: '면류',
            fileUrl: '',
          },
        ],
        responseValidation: {
          rankLimit: 5,
        },
        fileUrl: '',
        id: 'e1c574fe-d030-4dd7-bf30-afd6ee61def7',
      },
      {
        kind: 'ScoreQuestion',
        title: '청결 상태를 평가해주세요.',
        description: '',
        isRequired: false,
        selections: [],
        subQuestions: [
          {
            description: '조리원 위생 상태',
            fileUrl: '',
          },
          {
            description: '식당 홈 청결도',
            fileUrl: '',
          },
          {
            description: '식기구 청결도',
            fileUrl: '',
          },
        ],
        responseValidation: {
          minScore: 1,
          maxScore: 10,
        },
        fileUrl: '',
      },
      {
        kind: 'TextQuestion',
        title: '의견 및 개선사항을 자유롭게 적어주세요.',
        description: '',
        isRequired: false,
        selections: [],
        subQuestions: [],
        responseValidation: {
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
        id: 'd71a7f45-5902-440f-9122-2ffa9fff99a1',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-05-10T09:31:17.207Z',
  __v: 0,
};

const DRAFT_3 = {
  title: 'CI 및 마스코트 선정 투표',
  _id: '649bdfd4c57b0500488d0d27',
  content: {
    name: 'CI 및 마스코트 선정 투표',
    periods: [
      {
        startDate: '2023-06-28T18:08',
        endDate: '2023-07-28T16:09',
      },
    ],
    description:
      '<p>임직원 여러분 안녕하세요.</p><p>회사를 대표할 CI와 마스코트를 선정하는 투표입니다.</p><p>여러분들의 소중한 한 표 부탁드립니다.</p><p>감사합니다.</p>',
    type: 'Survey',
    voterType: 'voterBook',
    hasOpenVotes: false,
    subscriptionType: 'enterprise',
    questions: [
      {
        kind: 'RankQuestion',
        title: '선호하는 순서대로 CI를 선택해주세요.',
        description: '',
        isRequired: true,
        selections: [],
        subQuestions: [
          {
            description: 'A안',
            fileUrl: '649bdcd5c57b0500488d0430',
          },
          {
            description: 'B안',
            fileUrl: '649bdcdac57b0500488d0437',
          },
          {
            description: 'C안',
            fileUrl: '649bdcddc57b0500488d043e',
          },
        ],
        responseValidation: {
          rankLimit: 3,
        },
        fileUrl: '',
      },
      {
        kind: 'RadioQuestion',
        title: '회사를 대표할 마스코트를 선택해주세요.',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: 'A안',
            fileUrl: '649bdd24c57b0500488d0445',
          },
          {
            description: 'B안',
            number: 2,
            fileUrl: '649bdd2dc57b0500488d044c',
          },
        ],
        subQuestions: [],
        fileUrl: '',
        id: '1b94bfca-476e-4bf2-a498-1ad8f404958c',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-06-28T07:23:00.108Z',
  __v: 0,
};

const DRAFT_4 = {
  title: '직장 내 성희롱 설문 조사',
  _id: '64b74ed24e2743004875c432',
  content: {
    name: '직장 내 성희롱 설문 조사',
    periods: [
      {
        startDate: '2023-07-24T13:57',
        endDate: '2023-07-31T13:57',
      },
    ],
    description:
      '<p>본 설문은 직장 내 성희롱 설문 조사입니다.</p><p>여러분들의 솔직한 답변 부탁드립니다.</p><p>감사합니다.</p>',
    type: 'Survey',
    voterType: 'voterBook',
    hasOpenVotes: false,
    subscriptionType: 'enterprise',
    questions: [
      {
        kind: 'RadioQuestion',
        title: '귀하의 고용형태를 선택해주세요.',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '정규직 사원',
            fileUrl: '',
          },
          {
            description: '비정규직 사원',
            number: 2,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'CheckboxQuestion',
        title: '귀하의 연령을 선택해주세요.',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '20세 이하',
            fileUrl: '',
          },
          {
            description: '21~25세 이하',
            number: 2,
            fileUrl: '',
          },
          {
            description: '26~30세 이하',
            number: 3,
            fileUrl: '',
          },
          {
            description: '31세 이상',
            number: 4,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        responseValidation: {
          validation: 'lte',
          threshold: 4,
        },
        fileUrl: '',
        id: '05775134-515f-4d18-ac15-00fc0905f505',
      },
      {
        kind: 'RadioQuestion',
        title: '귀하의 결혼 상태를 선택해주세요.',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '기혼',
            fileUrl: '',
          },
          {
            description: '미혼',
            number: 2,
            fileUrl: '',
          },
          {
            description: '이혼',
            number: 3,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'RadioQuestion',
        title: '귀하의 근속년수를 선택해주세요. (*비정규직 근속년수 포함)',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '1년 이하',
            fileUrl: '',
          },
          {
            description: '2년 이하',
            number: 2,
            fileUrl: '',
          },
          {
            description: '3년 이하',
            number: 3,
            fileUrl: '',
          },
          {
            description: '4년 이하',
            number: 4,
            fileUrl: '',
          },
          {
            description: '5년 이상',
            number: 5,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'RadioQuestion',
        title:
          '최근 1년간 직장 내 괴롭힘을 직접적(피해, 가해) 또는 간접적(목격하거나 상담을 해준)으로 경험한 적이 있으신가요?',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '있다',
            fileUrl: '',
          },
          {
            description: '없다',
            number: 2,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
        id: 'e7569184-acaa-47a8-b17a-098938d52d45',
      },
      {
        kind: 'CheckboxQuestion',
        title: '직장내 성희롱 예방장치 유무에 대하여 알고있나요?',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '회사내 성희롱 고충처리 위원회가 있다.',
            fileUrl: '',
          },
          {
            description: '노조내 성희롱 고충처리 위원회가 있다.',
            number: 2,
            fileUrl: '',
          },
          {
            description: '특별한 예방장치가 없다.',
            number: 3,
            fileUrl: '',
          },
          {
            description: '모르겠다.',
            number: 4,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        responseValidation: {
          validation: 'lte',
          threshold: 4,
        },
        fileUrl: '',
      },
      {
        kind: 'CheckboxQuestion',
        title: '다음 보기중 최근 1년간 경험한 적이 있으면 선택해주세요.',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '나의 외모에 대한 성적 비유를 들은적이 있다.',
            fileUrl: '',
          },
          {
            description: '음담패설을 들은적이 있다.',
            number: 2,
            fileUrl: '',
          },
          {
            description: '상대방이 자신의 성생활에 대해 이야기하는 것을 들은적이 있다.',
            number: 3,
            fileUrl: '',
          },
          {
            description: '불쾌한 시선으로 나의 신체 특정 부위를 쳐다보는 경우가 있다.',
            number: 4,
            fileUrl: '',
          },
          {
            description: '나의 손, 가슴, 엉덩이, 성기등 특정부위를 만진적이 있다.',
            number: 5,
            fileUrl: '',
          },
          {
            description:
              '회식, 야유회 등에서 옆에 앉거나 술을 따르라는 혹은 블루스를 추자는 강요를 받았다.',
            number: 6,
            fileUrl: '',
          },
          {
            description: '성관계를 강요 받았다.',
            number: 7,
            fileUrl: '',
          },
          {
            description: '성희롱을 하면서 은근히 업무에대한 압력 및 제의를 받은적이 있다.',
            number: 8,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        responseValidation: {
          validation: 'lte',
          threshold: 8,
        },
        fileUrl: '',
        id: '800e2b26-3edd-4502-b76b-d0769c944af3',
      },
      {
        kind: 'RadioQuestion',
        title: '성희롱이 발생한 원인은 무엇이라 생각하시나요?',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '내가 행동을 잘못해서',
            fileUrl: '',
          },
          {
            description: '나를 동료보다는 성적대상으로 보아서',
            number: 2,
            fileUrl: '',
          },
          {
            description: '내가 직위가 낮기 때문에',
            number: 3,
            fileUrl: '',
          },
          {
            description: '성희롱에 대한 처벌이 약해서',
            number: 4,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
        id: '2bca57c6-ddc7-45c0-b149-abdd1ff0b32a',
      },
      {
        kind: 'RadioQuestion',
        title: '성희롱에 대한 대처 방법중 가장 바람직한 방법을 선택해주세요.',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '참고 아무 대응도 하지 않는다.',
            fileUrl: '',
          },
          {
            description: '그냥 웃거나 농담으로 넘긴다.',
            number: 2,
            fileUrl: '',
          },
          {
            description: '싫다는 의사를 표현하고 행동을 중지할 것을 요구한다.',
            number: 3,
            fileUrl: '',
          },
          {
            description: '직장 동료나 타 상사에게 보고하고 도움을 요청한다.',
            number: 4,
            fileUrl: '',
          },
          {
            description: '노동조합등 직장내 성폭력 상담소에 도움을 요청한다.',
            number: 5,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    hasInstantVoter: false,
    allowRealTimeResult: false,
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-07-19T02:47:46.113Z',
  __v: 0,
};

const DRAFT_5 = {
  title: '명절선물세트 선호도 조사',
  _id: '64a283e256fc87004753e989',
  content: {
    name: '명절선물세트 선호도 조사',
    periods: [
      {
        startDate: '2023-07-03T18:53',
        endDate: '2023-07-04T17:03',
      },
    ],
    description:
      '<p>임직원 여러분 안녕하세요.</p><p>다가오는 명절, 감사인사를 전할 선물세트 선호도 조사 안내드립니다.</p><p>여러분들의 소중한 한 표 부탁드립니다.</p><p>감사합니다.</p>',
    type: 'Survey',
    voterType: 'voterBook',
    hasOpenVotes: false,
    subscriptionType: 'enterprise',
    questions: [
      {
        kind: 'ScaleQuestion',
        title: '지난 명절 선물에 만족하셨나요?',
        description: '',
        isRequired: false,
        selections: [],
        subQuestions: [],
        min: {
          value: 1,
          description: '불만족',
        },
        max: {
          value: 10,
          description: '만족',
        },
        fileUrl: '',
      },
      {
        kind: 'RadioQuestion',
        title: '이번 명절 자사로부터 받고 싶은 선물은 무엇인가요?',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '과일',
            fileUrl: '',
          },
          {
            description: '육류∙축산물 (한우.갈비)',
            number: 2,
            fileUrl: '',
          },
          {
            description: '가공식품 (햄, 참치)',
            number: 3,
            fileUrl: '',
          },
          {
            description: '상품권',
            number: 4,
            fileUrl: '',
          },
          {
            description: '건강기능식품 (홍삼 등)',
            number: 5,
            fileUrl: '',
          },
          {
            description: '생활용품 (샴푸, 치약)',
            number: 6,
            fileUrl: '',
          },
          {
            description: '의류∙패션∙잡화 (양말 등)',
            number: 7,
            fileUrl: '',
          },
          {
            description: '생선∙수산∙건어물 (굴비 등)',
            number: 8,
            fileUrl: '',
          },
          {
            description: '농산물 (버섯 등)',
            number: 9,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-07-03T08:16:34.186Z',
  __v: 0,
};

const DRAFT_6 = {
  _id: '65dd71c46af7bf00d12eea08',
  title: '사내 업무 환경 만족도 설문조사',
  content: {
    name: '사내 업무 환경 만족도 설문조사',
    description:
      '<p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">​OO 기업의 사내 업무 환경에 대한 만족도 조사입니다.</span></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">업무 환경 \b개선을 위해 임직원 여러분들의 많은 참여 바랍니다.</span></p>',
    questions: [
      {
        videoUrl: {
          youtube: '',
        },
        isRequired: false,
        kind: 'RadioQuestion',
        _id: '65dd71c46af7bf00d12eea09',
        title: '현재 업무 문화에 대해 만족한다.',
        description: '',
        selections: [
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd71c46af7bf00d12eea0a',
            description: '매우 만족',
            fileUrl: '',
            number: 1,
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd71c46af7bf00d12eea0b',
            description: '만족',
            number: 2,
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd71c46af7bf00d12eea0c',
            description: '보통',
            number: 3,
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd71c46af7bf00d12eea0d',
            description: '불만족',
            number: 4,
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd71c46af7bf00d12eea0e',
            description: '매우 불만족',
            number: 5,
            fileUrl: '',
          },
        ],
        fileUrl: '',
      },
      {
        videoUrl: {
          youtube: '',
        },
        isRequired: false,
        kind: 'RadioQuestion',
        _id: '65dd72656af7bf00d12eea9f',
        title: '안전하게 일할 수 있는 공간을 제공한다.',
        description: '',
        selections: [
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd72656af7bf00d12eeaa0',
            number: 1,
            description: '매우 그렇다',
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd72656af7bf00d12eeaa1',
            description: '그렇다',
            number: 2,
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd72656af7bf00d12eeaa2',
            description: '보통이다',
            number: 3,
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd72656af7bf00d12eeaa3',
            description: '아니다',
            number: 4,
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd72656af7bf00d12eeaa4',
            description: '매우 아니다',
            number: 5,
            fileUrl: '',
          },
        ],
        fileUrl: '',
      },
      {
        videoUrl: {
          youtube: '',
        },
        isRequired: false,
        kind: 'RadioQuestion',
        _id: '65dd72656af7bf00d12eeaa5',
        title: '현재 기업의 재정 상태는 안정적이다.',
        description: '',
        selections: [
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd72656af7bf00d12eeaa6',
            number: 1,
            description: '매우 그렇다',
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd72656af7bf00d12eeaa7',
            description: '그렇다',
            number: 2,
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd72656af7bf00d12eeaa8',
            description: '보통이다',
            number: 3,
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd72656af7bf00d12eeaa9',
            description: '아니다',
            number: 4,
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd72656af7bf00d12eeaaa',
            description: '매우 아니다',
            number: 5,
            fileUrl: '',
          },
        ],
        fileUrl: '',
      },
      {
        videoUrl: {
          youtube: '',
        },
        isRequired: false,
        kind: 'RadioQuestion',
        _id: '65dd72656af7bf00d12eeaab',
        title: '나의 업무가 어떤 비즈니스 임팩트를 발생하는지 알고 있다.',
        description: '',
        selections: [
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd72656af7bf00d12eeaac',
            number: 1,
            description: '매우 그렇다',
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd72656af7bf00d12eeaad',
            description: '그렇다',
            number: 2,
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd72656af7bf00d12eeaae',
            description: '보통이다',
            number: 3,
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd72656af7bf00d12eeaaf',
            description: '아니다',
            number: 4,
            fileUrl: '',
          },
          {
            videoUrl: {
              youtube: '',
            },
            _id: '65dd72656af7bf00d12eeab0',
            description: '매우 아니다',
            number: 5,
            fileUrl: '',
          },
        ],
        fileUrl: '',
      },
      {
        videoUrl: {
          youtube: '',
        },
        isRequired: false,
        kind: 'TextQuestion',
        _id: '65dd72656af7bf00d12eeab1',
        title: '기타 업무 환경 개선을 위한 의견을 작성해주세요.',
        description: '',
        responseValidation: {
          _id: '65dd72656af7bf00d12eeab2',
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
      },
    ],
    periods: [
      {
        startDate: '2024-02-27T07:19:00.000Z',
        endDate: '2025-12-31T05:20:00.000Z',
      },
    ],
    type: 'Survey',
    voterType: 'voterBook',
    hasOpenVotes: false,
    subscriptionType: 'enterprise',
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-06-26T06:41:40.686Z',
  __v: 0,
};

const DRAFT_7 = {
  _id: '65dd73cd6af7bf00d12eec25',
  title: '복리후생 만족도 설문조사',
  content: {
    periods: [
      {
        startDate: '2024-02-27T07:30:00.000Z',
        endDate: '2025-12-31T05:30:00.000Z',
      },
    ],
    name: '복리후생 만족도 설문조사',
    description:
      '<p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">​복리후생 제도 만족도 설문조사입니다.</span></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">\b개선을 위해 많은 참여 바랍니다.</span></p>',

    questions: [
      {
        videoUrl: {
          youtube: '',
        },
        isRequired: true,
        kind: 'TextQuestion',
        _id: '65dd73cd6af7bf00d12eec26',
        title: '귀하는 당사의 복리후생 제도에 대해 어떻게 생각하시나요?',
        description: '',
        responseValidation: {
          _id: '65dd73cd6af7bf00d12eec27',
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
      },
      {
        min: {
          value: 1,
          description: '그렇지 않다',
        },
        max: {
          value: 5,
          description: '그렇다',
        },
        videoUrl: {
          youtube: '',
        },
        isRequired: true,
        kind: 'ScaleQuestion',
        _id: '65dd74da6af7bf00d12eec6a',
        title:
          '귀하는 당사에저 제공하는 건강관리 서비스(건강검진, 예방접종 등)에 대해 만족하십니까?',
        description: '',
        fileUrl: '',
      },
      {
        min: {
          value: 1,
          description: '그렇지 않다.',
        },
        max: {
          value: 5,
          description: '그렇다.',
        },
        videoUrl: {
          youtube: '',
        },
        isRequired: true,
        kind: 'ScaleQuestion',
        _id: '65dd74da6af7bf00d12eec6b',
        title: '귀하는 당사의 주 5일 근무 및 유연근무제에 만족하십니까?',
        description: '',
        fileUrl: '',
      },
      {
        responseValidation: {
          rankLimit: 4,
        },
        videoUrl: {
          youtube: '',
        },
        isRequired: true,
        kind: 'RankQuestion',
        _id: '65dd74da6af7bf00d12eec6c',
        title: '당사의 복지 제도 중 만족도가 높은 순서대로 선택해주세요.',
        description: '',
        subQuestions: [
          {
            _id: '65dd74da6af7bf00d12eec6d',
            description: '생일 반차',
            fileUrl: '',
          },
          {
            _id: '65dd74da6af7bf00d12eec6e',
            description: '사내 카페테리아',
            fileUrl: '',
          },
          {
            _id: '65dd74da6af7bf00d12eec6f',
            description: '명절 선물',
            fileUrl: '',
          },
          {
            _id: '65dd74da6af7bf00d12eec70',
            description: '자격증 등 교육 지원',
            fileUrl: '',
          },
        ],
        fileUrl: '',
        shuffle: true,
      },
      {
        videoUrl: {
          youtube: '',
        },
        isRequired: false,
        kind: 'TextQuestion',
        _id: '65dd74da6af7bf00d12eec71',
        title: '귀하는 당사의 복리후생 제도 향상을 위해 어떤 것이 필요하다고 생각하십니까? ',
        description: '',
        responseValidation: {
          _id: '65dd74da6af7bf00d12eec72',
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
      },
    ],
    type: 'Survey',
    voterType: 'voterBook',
    hasOpenVotes: false,
    subscriptionType: 'enterprise',
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-06-26T06:41:40.686Z',
  __v: 0,
};

export default [
  {
    title: '사내 프로젝트 의견 조사',
    description: '사내 프로젝트 의견 조사를 위한 템플릿입니다.',
    thumbnail: Thumbnail1,
    draft: DRAFT_1,
    category: DraftTemplateCategory.Corporation,
    kind: 'Survey',
  },
  {
    title: '사내 구내식당 만족도 조사',
    description: '사내 구내식당 만족도 조사를 위한 템플릿입니다.',
    thumbnail: Thumbnail2,
    draft: DRAFT_2,
    category: DraftTemplateCategory.Corporation,
    kind: 'Survey',
  },
  {
    title: '회사 로고 및 캐릭터 선정 투표',
    description: '회사 로고 및 캐릭터 선정 투표를 위한 템플릿입니다.',
    thumbnail: Thumbnail3,
    draft: DRAFT_3,
    category: DraftTemplateCategory.Corporation,
    kind: 'Survey',
  },
  {
    title: '직장 내 성희롱 설문 조사',
    description: '직장 내 성희롱 설문 조사를 위한 템플릿입니다.',
    thumbnail: Thumbnail4,
    draft: DRAFT_4,
    category: DraftTemplateCategory.Corporation,
    kind: 'Survey',
  },
  {
    title: '명절 선물세트 선호도 조사',
    description: '명절 선물세트 선호도 조사를 위한 템플릿입니다.',
    thumbnail: Thumbnail5,
    draft: DRAFT_5,
    category: DraftTemplateCategory.Corporation,
    kind: 'Survey',
  },
  {
    title: '사내 업무 환경 만족도 설문조사',
    description: `${DRAFT_6.title}를 위한 템플릿입니다.`,
    thumbnail: Thumbnail6,
    draft: DRAFT_6,
    category: DraftTemplateCategory.Corporation,
    kind: 'Survey',
  },
  {
    title: '복리후생 만족도 설문조사',
    description: `${DRAFT_7.title}를 위한 템플릿입니다.`,
    thumbnail: Thumbnail7,
    draft: DRAFT_7,
    category: DraftTemplateCategory.Corporation,
    kind: 'Survey',
  },
] as DraftTemplate[];
