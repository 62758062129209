import { useLocation, useMatch, useNavigate } from 'react-router-dom';

import { TabItemWithPath } from 'types/common';

export const useTabWithPath = <T extends string>({
  urlPrefix,
  tabItems,
  withQueryParams = false,
}: {
  urlPrefix: string;
  tabItems: TabItemWithPath<T>[];
  withQueryParams?: boolean;
}) => {
  const navigate = useNavigate();
  const match = useMatch(`${urlPrefix}/:lastPathname`);
  const { search } = useLocation();

  const matchedTab =
    tabItems.find((tabItem) => tabItem.path === match?.params.lastPathname) || tabItems[0];

  const selectedTabIndex = tabItems.findIndex((tabItem) => tabItem.path === matchedTab.value);

  const setTabItem = (newTab: T) => {
    const path = tabItems.find((tabItem) => tabItem.value === newTab)?.path || tabItems[0].value;
    const formattedPath = withQueryParams ? `${path}${search}` : path;
    navigate(formattedPath);
  };

  return { tab: matchedTab.value, setTab: setTabItem, selectedTabIndex };
};
