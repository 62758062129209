export default {
  useOrderResult: {
    onSuccess: {
      toast: {
        election: '선거가 개표되었습니다.',
        survey: '설문 결과를 확인할 수 있습니다.',
      },
    },
  },

  useCancelPoll: {
    onSuccess: {
      toast: {
        election: '선거가 무효 처리되었습니다.',
        survey: '설문이 무효 처리되었습니다.',
      },
    },
  },

  usePausePoll: {
    onSuccess: {
      toast: {
        election: '선거가 일시 정지되었습니다.',
        survey: '설문이 일시 정지되었습니다.',
      },
    },
  },

  useRestartPoll: {
    onSuccess: {
      toast: {
        election: '선거가 재시작되었습니다.',
        survey: '설문이 재시작되었습니다.',
      },
    },
  },

  useRemovePoll: {
    onSuccess: {
      toast: '성공적으로 삭제되었습니다.',
    },
  },

  useEarlyExitPoll: {
    onSuccess: {
      toast: {
        election: '선거가 즉시 종료되었습니다.',
        survey: '설문이 즉시 종료되었습니다.',
      },
    },
  },
};
