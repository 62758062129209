import { t } from 'i18next';
import { POLL_LABELS, PollGroup } from '@pickme/core';
import { colors } from '@pickme/design-system';

import { PollSearchGroup, PollSearchKind } from 'types/poll';

export const POLL_GROUPS: { text: string; value: PollGroup }[] = Object.entries(POLL_LABELS).map(
  ([value, label]) => ({ text: label, value: value as PollGroup }),
);

export const ELECTION_KIND_BADGES: { [key: string]: { variant: string; text: string } } = {
  ChoiceElection: { variant: 'light-gray', text: t('admin:constants.poll.election.kind.choice') },
  ProsOrConsElection: {
    variant: 'light-gray',
    text: t('admin:constants.poll.election.kind.prosOrCons'),
  },
  RankElection: { variant: 'light-gray', text: t('admin:constants.poll.election.kind.rank') },
  ScoreElection: { variant: 'light-gray', text: t('admin:constants.poll.election.kind.score') },
};

export const QUESTION_KIND_BADGES: { [key: string]: { variant: string; text: string } } = {
  RadioQuestion: {
    variant: 'light-gray',
    text: t('admin:constants.poll.survey.question.kind.radio'),
  },
  CheckboxQuestion: {
    variant: 'light-gray',
    text: t('admin:constants.poll.survey.question.kind.checkbox'),
  },
  TextQuestion: {
    variant: 'light-gray',
    text: t('admin:constants.poll.survey.question.kind.text'),
  },
  RankQuestion: {
    variant: 'light-gray',
    text: t('admin:constants.poll.survey.question.kind.rank'),
  },
  ScoreQuestion: {
    variant: 'light-gray',
    text: t('admin:constants.poll.survey.question.kind.score'),
  },
  ScaleQuestion: {
    variant: 'light-gray',
    text: t('admin:constants.poll.survey.question.kind.scale'),
  },
};

export const SETTINGS_POLL_TABS = [
  {
    text: t('admin:constants.poll.tabs.0'),
    url: '/settings/poll/history',
  },
  {
    text: t('admin:constants.poll.tabs.1'),
    url: '/settings/poll/discard-data',
  },
];

export const GROUPS_ABLE_TO_ENTER_POLL_RESULT = [
  PollGroup.Ongoing,
  PollGroup.Ended,
  PollGroup.Counted,
];
export const GROUPS_ABLE_TO_CANCEL = [PollGroup.Ongoing, PollGroup.Ended];
export const GROUPS_ABLE_TO_STOP = [PollGroup.Ongoing, PollGroup.Paused];
export const GROUPS_ABLE_TO_COUNT = [PollGroup.Ended];
export const GROUPS_ABLE_TO_EARLY_EXIT = [PollGroup.Ongoing];

export const POLL_KIND_SELECT_ITEMS: { label: string; value: PollSearchKind }[] = [
  { label: '전체', value: PollSearchKind.All },
  { label: '투표', value: PollSearchKind.Election },
  { label: '설문', value: PollSearchKind.Survey },
];

export const POLL_SEARCH_GROUP: Record<PollSearchGroup, { label: string; color?: string }> = {
  [PollSearchGroup.All]: {
    label: '전체',
  },
  [PollSearchGroup.Ready]: {
    label: POLL_LABELS[PollSearchGroup.Ready],
    color: colors['yellow-400'],
  },
  [PollSearchGroup.Ongoing]: {
    label: POLL_LABELS[PollSearchGroup.Ongoing],
    color: colors['primary-500'],
  },
  [PollSearchGroup.Paused]: {
    label: POLL_LABELS[PollSearchGroup.Paused],
    color: colors['blue-gray-300'],
  },
  [PollSearchGroup.Ended]: {
    label: POLL_LABELS[PollSearchGroup.Ended],
    color: colors['blue-gray-700'],
  },
};

export const POLL_SEARCH_GROUP_SELECT_ITEMS: { label: string; value: PollSearchGroup }[] =
  Object.entries(POLL_SEARCH_GROUP).map(([key, value]) => ({
    label: value.label,
    value: key as PollSearchGroup,
  }));
