import moment from 'moment-timezone';

moment.locale('ko', {
  weekdaysShort: ['일', '월', '화', '수', '목', '금', '토'],
  weekdaysMin: ['일', '월', '화', '수', '목', '금', '토'],
  relativeTime: {
    future: '%s 후',
    past: '%s 전',
    s: '1초',
    m: '1분',
    mm: '%d분',
    h: '1시간',
    hh: '%d시간',
    d: '1일',
    dd: '%d일',
    M: '한 달',
    MM: '%d달',
    y: '1년',
    yy: '%d년',
  },
});
