import { useLocation, useNavigate } from 'react-router-dom';

import Tabs from 'components/common/Tabs';

import { POLL_KIND_SELECT_ITEMS } from 'constants/poll';

import { PollSearchKind } from 'types/poll';

type Props = {
  selected: PollSearchKind;
};

function HomePollSearchKindTabs({ selected }: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const tabs = POLL_KIND_SELECT_ITEMS.map((item) => ({
    text: item.label,
    onClick: () => navigate(`${pathname}?kind=${item.value}`),
  }));
  const selectedIndex = POLL_KIND_SELECT_ITEMS.findIndex((item) => item.value === selected) || 0;

  return <Tabs tabs={tabs} selectedIndex={selectedIndex} unsetBottomBorder fullWidth={false} />;
}

export default HomePollSearchKindTabs;
