import { isEqual } from 'lodash-es';
import { Input, PlainButton, Text } from '@pickme/ui';
import { useEffect, useState } from 'react';
import { t } from 'i18next';

import CloseIcon from 'resources/icons/application/close.png';

import './index.scss';

type Props = {
  isVisible: boolean;
  initialTags: string[];
  onChange: (tags: string[]) => void;
};

function HashTagInput({ isVisible, initialTags, onChange }: Props) {
  const [value, setValue] = useState('');
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    if (initialTags && !isEqual(initialTags, tags)) {
      setTags(initialTags);
    }
  }, [initialTags?.toString()]);

  useEffect(() => {
    if (!isVisible) {
      setValue('');
    }
  }, [isVisible]);

  useEffect(() => {
    if (tags) {
      onChange(tags);
    }
  }, [tags.length]);

  return (
    <div className='candidate-form__hashtag-input'>
      {tags.length > 0 && (
        <div className='candidate-form__hashtag-input__tags'>
          {tags.map((tag, index) => (
            <div key={`${tag}-${index}`} className='candidate-form__hashtag-input__tag'>
              <Text type='b3' color='gray-400'>
                {tag}
              </Text>
              <PlainButton
                onClick={() => {
                  const copiedTags = tags.slice();
                  copiedTags.splice(index, 1);
                  setTags(copiedTags);
                }}
              >
                <img src={CloseIcon} alt='close' />
              </PlainButton>
            </div>
          ))}
        </div>
      )}
      <Input
        placeholder={t(
          'admin:components.features.application.form.subElection.form.candidate.fields.member.tag.placeholder',
        )}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }}
        width='100%'
        size='md'
        disabled={tags.length > 10}
        value={value}
        maxLength={20}
        onKeyUp={(event) => {
          if (event.key === ',' || event.key === 'Enter' || event.key === ' ') {
            if (value.trim() === '') {
              return;
            }

            if (/#{0,1}[A-Za-z0-9ㄱ-ㅎ|ㅏ-ㅣ|가-힣]*/.test(value)) {
              const parsedValue = value.replaceAll(',', '').replaceAll('#', '').trim();
              if (parsedValue === '') {
                return;
              }

              setTags((arr) => [...arr, `#${parsedValue}`]);
            }

            setValue('');
          }
        }}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      />
    </div>
  );
}

export default HashTagInput;
