import { debounce } from 'lodash-es';
import { useEffect, useState } from 'react';
import { PlainButton } from '@pickme/design-system';

import ScrollTopIcon from 'resources/icons/poll-home/scroll-top.svg';

import { floatingButton } from './index.css';

function ScrollToTopButton() {
  const [isScrollTopButtonVisible, setIsScrollTopButtonVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const updateScrollTopButtonVisibility = () => {
      setIsScrollTopButtonVisible(window.scrollY > 200);
    };
    const debouncedCallback = debounce(updateScrollTopButtonVisibility, 100);

    updateScrollTopButtonVisibility();
    window.addEventListener('scroll', debouncedCallback);

    return () => {
      window.removeEventListener('scroll', debouncedCallback);
      debouncedCallback.cancel();
    };
  }, []);

  return (
    isScrollTopButtonVisible && (
      <PlainButton className={floatingButton} onClick={scrollToTop}>
        <img src={ScrollTopIcon} alt='scroll top' />
      </PlainButton>
    )
  );
}

export default ScrollToTopButton;
