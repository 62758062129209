import { CommonAuthMethod } from 'models/auth/common-auth-method';

import { AuthMethodType, CredentialAuthMethodResponse } from 'types/auth';

export class CredentialAuthMethod extends CommonAuthMethod {
  _type: AuthMethodType.Credentials;

  constructor(response: CredentialAuthMethodResponse) {
    super(response);
    this._type = AuthMethodType.Credentials;
  }

  get type() {
    return this._type;
  }
}
