import { Text, Button } from '@pickme/design-system';
import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { t } from 'i18next';
import { PollGroup } from '@pickme/core';

import PollStats from 'components/features/poll/cards/Stats';
import PollList from 'components/features/poll/PollList';
import NameAndPeriodSearchController from 'components/features/search/NameAndPeriodSearch';
import Pagination from 'components/common/Pagination';
import PollsViewButtons from 'components/features/poll/buttons/ViewButtons';

import { useGetPolls, useGetPollStats } from 'query-hooks/poll';

import { usePollQueryString } from 'hooks/usePollQueryString';

import { makeQueryString, parseUrlWithPage } from 'functions/url';

import { PollKind, PollViewMode } from 'types/poll';
import { NameAndPeriodSearchForm } from 'types/search';

import AddIcon from 'resources/icons/dashboard/add.svg';
import ElectionImage from 'resources/images/banner/election.png';
import SurveyImage from 'resources/images/banner/survey.png';

import { container } from 'styles/page.css';
import { image, pollSection, pollSectionRow } from './index.css';

type Props = {
  kind: PollKind;
};

function PollDashboard({ kind }: Props) {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const [viewMode, setViewMode] = useState<PollViewMode>(PollViewMode.Table);

  const { page, start, end, groups, name } = usePollQueryString();

  const { data: pollStats } = useGetPollStats(kind);
  const { data, isLoading } = useGetPolls({
    kind,
    name,
    page,
    period: { start, end },
    groups,
  });
  const { polls = [], endPage = 1 } = data || {};

  const onMovePage = (selectedPage: number) => {
    const parsedPage = parseUrlWithPage(`${pathname}${search}`, selectedPage);
    navigate(parsedPage);
  };

  const searchFormRef = useRef<HTMLFormElement>(null);
  const methods = useForm<NameAndPeriodSearchForm & { groups: PollGroup }>();

  const onSearch = (searchForm: NameAndPeriodSearchForm & { groups?: PollGroup }) => {
    const query: { page: number; name?: string; start?: string; end?: string; groups?: PollGroup } =
      {
        page: 1,
      };

    if (searchForm.name) {
      query.name = searchForm.name;
    }

    if (searchForm.period?.start && searchForm.period?.end) {
      query.start = searchForm.period.start;
      query.end = searchForm.period.end;
    }

    if (groups) {
      query.groups = groups?.[0];
    }

    const queryString = makeQueryString(query);
    navigate(`${pathname}?${queryString}`);
  };

  return (
    <div className={container({ gap: 32 })}>
      <Text size={32} fontWeight={600} tag='h1'>
        {kind === 'Election'
          ? t('admin:terms.poll.kind.election')
          : t('admin:terms.poll.kind.survey')}
      </Text>

      <div className={pollSection}>
        <img
          className={image}
          src={kind === 'Election' ? ElectionImage : SurveyImage}
          alt={kind}
          onContextMenu={(event) => event.preventDefault()}
          onMouseDown={(event) => event.preventDefault()}
          onKeyDown={(event) => event.preventDefault()}
          role='presentation'
        />

        <PollStats stats={pollStats} group={groups?.[0]} />

        <FormProvider {...methods}>
          <form ref={searchFormRef} onSubmit={methods.handleSubmit(onSearch)}>
            <NameAndPeriodSearchController
              initialValues={{
                name,
                period: { start, end },
              }}
            />
          </form>
        </FormProvider>

        <div className={pollSectionRow}>
          <Button
            size='lg'
            onClick={() => {
              navigate(`/poll/init?type=${kind}`, {
                unstable_viewTransition: true,
              });
            }}
          >
            <img src={AddIcon} alt='add' />
            새로 만들기
          </Button>

          <PollsViewButtons mode={viewMode} setMode={setViewMode} />
        </div>

        <PollList
          viewMode={viewMode}
          polls={polls}
          keyword={name}
          kind={kind === 'Election' ? 'election' : 'survey'}
          isLoading={isLoading}
        />

        {!isLoading && polls.length > 0 && (
          <Pagination page={page} endPage={endPage} setPage={onMovePage} />
        )}
      </div>
    </div>
  );
}

export default PollDashboard;
