import { Text } from '@pickme/ui';
import { t } from 'i18next';

type Props = {
  voteCount?: number;
};

function InstantVoterOverviewSection({ voteCount }: Props) {
  return (
    <div className='poll-progress__section'>
      <div className='poll-progress__overview'>
        <div className='poll-progress__overview__progress'>
          <div className='poll-progress__overview__progress__text'>
            <Text type='b3' color='gray-400' fontWeight={400}>
              {t('admin:components.features.poll.progress.instantVoterOverviewSection.title')}
            </Text>
          </div>

          <div className='poll-progress__overview__total-count poll-progress__overview__total-count--border-left'>
            <Text type='b2' fontWeight={500}>
              {voteCount ?? 0}
              {t('admin:terms.unit.people')}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstantVoterOverviewSection;
