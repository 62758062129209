import { useLocation } from 'react-router-dom';
import { isNaN } from 'lodash-es';

type Query<T> = { [key in keyof T]?: string | number };

export const useQueryString = <T extends Query<T>>(initialValues: T): T => {
  const { search } = useLocation();
  const result: Query<T> = { ...initialValues };

  new URLSearchParams(search).forEach((value, key) => {
    if (key in result) {
      if (typeof initialValues[key as keyof T] === 'number') {
        const numericValue = parseInt(value, 10);
        result[key as keyof T] = isNaN(numericValue) ? initialValues[key as keyof T] : numericValue;
      } else {
        result[key as keyof T] = value;
      }
    }
  });

  return result as T;
};
