import dayjs from 'dayjs';
import { useState } from 'react';
import { PlainButton, Button } from '@pickme/design-system';

import MessagingDetailPair from 'components/features/notification-v2/modal/messaging-detail/common/Pair';
import TemplateViewer from 'components/features/notification-v2/modal/messaging-detail/common/TemplateViewer';

import { RECIPIENT_TYPE_LABELS } from 'constants/notification-v2';

import { LmsMessagingDetail, MessagingType, PollMessaging } from 'types/message';

import { pairContainer, reservationCancelButton, viewerOpenButton } from '../../common.css';

type Props = {
  spaceName: string;
  type: MessagingType;
  messaging: PollMessaging;
  onReservationCancel?: () => void;
};

function LmsDetail({ spaceName, type, messaging, onReservationCancel }: Props) {
  const [isTemplateViewerVisible, setIsTemplateViewerVisible] = useState(false);

  const detail = messaging.detail as LmsMessagingDetail;
  const totalSendTryCount = detail.sendResult.failed + detail.sendResult.lms;

  return isTemplateViewerVisible ? (
    <TemplateViewer
      title='문자 내용'
      template={detail.styledTemplate}
      onClose={() => setIsTemplateViewerVisible(false)}
    />
  ) : (
    <>
      <div>
        <div className={pairContainer}>
          <MessagingDetailPair title='스페이스 이름'>{spaceName}</MessagingDetailPair>
          <MessagingDetailPair title='전송 대상'>
            {RECIPIENT_TYPE_LABELS[messaging.recipientType]}
          </MessagingDetailPair>
          <MessagingDetailPair title='발신자 번호'>1533-1912</MessagingDetailPair>
        </div>

        {type === MessagingType.Send && (
          <div className={pairContainer}>
            <MessagingDetailPair title='전송 일시'>
              {dayjs(messaging.sendRequestDate).format('YY/MM/DD (dd) HH:mm')}
            </MessagingDetailPair>
            <MessagingDetailPair title='전송 시도 건수'>{totalSendTryCount}</MessagingDetailPair>
            <MessagingDetailPair title='전송 실패 건수'>
              {detail.sendResult.failed}
            </MessagingDetailPair>
            <MessagingDetailPair title='사용 포인트'>{messaging.usageAmount}P</MessagingDetailPair>
          </div>
        )}

        {type === MessagingType.Reserve && (
          <div className={pairContainer}>
            <MessagingDetailPair title='전송 예정 일시'>
              {dayjs(messaging.sendRequestDate).format('YY/MM/DD (dd) HH:mm')}
            </MessagingDetailPair>
            <MessagingDetailPair title='사용 예정 포인트'>
              {messaging.usageAmount}P
            </MessagingDetailPair>
          </div>
        )}
      </div>

      <PlainButton className={viewerOpenButton} onClick={() => setIsTemplateViewerVisible(true)}>
        문자 내용 보기
      </PlainButton>

      {type === MessagingType.Reserve && (
        <Button variant='danger' onClick={onReservationCancel} className={reservationCancelButton}>
          예약취소
        </Button>
      )}
    </>
  );
}

export default LmsDetail;
