import { Modal, ModalBody, ModalHeader, PlainButton, Text } from '@pickme/ui';
import { t } from 'i18next';

import { ElectionKind } from 'types/application';

import ChoiceIcon from 'resources/icons/application/kind/choice.png';
import ProsOrConsIcon from 'resources/icons/application/kind/yesno.png';
import RankIcon from 'resources/icons/application/kind/rank.png';
import ScoreIcon from 'resources/icons/application/kind/point.png';

import './index.scss';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (kind: ElectionKind) => void;
};

const KINDS = [
  {
    kind: 'ChoiceElection' as ElectionKind,
    icon: ChoiceIcon,
    title: t(
      'admin:components.features.application.form.subElection.modal.kindSelect.items.choice.title',
    ),
    message: t(
      'admin:components.features.application.form.subElection.modal.kindSelect.items.choice.message',
    ),
  },
  {
    kind: 'ProsOrConsElection' as ElectionKind,
    icon: ProsOrConsIcon,
    title: t(
      'admin:components.features.application.form.subElection.modal.kindSelect.items.prosOrCons.title',
    ),
    message: t(
      'admin:components.features.application.form.subElection.modal.kindSelect.items.prosOrCons.message',
    ),
  },
  {
    kind: 'RankElection' as ElectionKind,
    icon: RankIcon,
    title: t(
      'admin:components.features.application.form.subElection.modal.kindSelect.items.rank.title',
    ),
    message: t(
      'admin:components.features.application.form.subElection.modal.kindSelect.items.rank.message',
    ),
  },
  {
    kind: 'ScoreElection' as ElectionKind,
    icon: ScoreIcon,
    title: t(
      'admin:components.features.application.form.subElection.modal.kindSelect.items.score.title',
    ),
    message: t(
      'admin:components.features.application.form.subElection.modal.kindSelect.items.score.message',
    ),
  },
];

function ElectionKindSelect({ isVisible, onClose, onSubmit }: Props) {
  return (
    <Modal className='kind-selector-modal' isVisible={isVisible} onClose={onClose} size='lg'>
      <ModalHeader
        title={t('admin:components.features.application.form.subElection.modal.kindSelect.title')}
        onClose={onClose}
      />

      <ModalBody>
        <div className='kind-selector'>
          {KINDS.map(({ kind, icon, title, message }) => (
            <PlainButton
              onClick={() => {
                onSubmit(kind);
              }}
              key={kind}
              className='kind-selector__row'
            >
              <div className='kind-selector__row__header'>
                <img src={icon} className='kind-selector__row__header__icon' alt='kind' />
              </div>

              <div className='kind-selector__row__body'>
                <div className='kind-selector__row__body__title'>
                  <Text type='b2' fontWeight={500}>
                    {title}
                  </Text>
                </div>

                <div className='kind-selector__row__body__description'>
                  <Text type='b3' fontWeight={400} color='gray-400'>
                    {message}
                  </Text>
                </div>
              </div>
            </PlainButton>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ElectionKindSelect;
