import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { colors } from '@pickme/design-system';

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartDataLabels);

type Item = { dataX: string; dataY: number | null; label?: { main: string; sub: string } };

type Props = {
  items: Item[];
  xLabels: string[];
  y?: { min: number; max: number };
  barColors: string[];
};

function VerticalBarChart({ items, xLabels, y, barColors }: Props) {
  const dataValues = items.map(({ dataY }) => dataY);

  const maxValue = Math.max(...(dataValues.filter(Boolean) as number[]));
  const stepSize = 10 ** (`${maxValue}`.length - 1);
  const isMobileWidth = window.innerWidth < 769;

  const responsiveGraphMoreWidth =
    items.length - 5 > 0 && isMobileWidth ? (items.length - 5) * 44 : 0;
  const width = 300 + responsiveGraphMoreWidth;

  const data: ChartData<'bar'> = {
    labels: xLabels,
    datasets: [
      {
        data: dataValues,
        backgroundColor: barColors,
        hoverBackgroundColor: barColors,
        borderRadius: 4,
        borderWidth: 0,
        maxBarThickness: 32,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: { enabled: false },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (_, context) => {
          if (!context) {
            return null;
          }

          const { dataIndex } = context;

          const main = items[dataIndex]?.label?.main || '';
          const sub = items[dataIndex]?.label?.sub || '';

          return [main, sub];
        },
        font: { size: 12 },
        textAlign: 'center',
        color: colors['gray-800'],
      },
    },
    scales: {
      y: {
        grid: { display: true },
        border: { display: false, dash: [2, 4] },
        min: y?.min ?? 0,
        max:
          y?.max ??
          Math.floor((maxValue + stepSize * (isMobileWidth ? 2 : 1)) / stepSize) * stepSize,
      },
      x: {
        grid: { display: false },
        border: { display: false },
      },
    },
  };

  return (
    <div style={{ minWidth: width }}>
      <Bar data={data} options={options} />
    </div>
  );
}

export default VerticalBarChart;
