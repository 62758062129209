import {
  CandidateChoiceSubElection,
  CandidateElection,
  CandidateRankSubElection,
  CandidateScoreSubElection,
  CandidateSubElection,
  ElectionCategory,
  Candidate,
} from '@pickme/core';
import { v4 as uuid } from 'uuid';

import { PollApplicationAdapter } from 'models/application-v2/adapters/PollApplicationAdapter';
import { VoterBook } from 'models/poll-v2/Voterbook';

import {
  CandidateElectionApplicationForms,
  CandidateElectionForm,
  ElectionTypeCase,
  Candidate as CandidateFields,
} from 'types/application-v2';

const ELECTION_TYPE_MAPPER: { [key in ElectionCategory]: ElectionTypeCase } = {
  [ElectionCategory.선택투표]: ElectionTypeCase.선택투표,
  [ElectionCategory.순위투표]: ElectionTypeCase.순위투표,
  [ElectionCategory.점수투표]: ElectionTypeCase.점수투표,
  [ElectionCategory.찬반투표]: ElectionTypeCase.찬반투표,
};

export class CandidateElectionApplicationAdapter extends PollApplicationAdapter {
  private _candidateElection: CandidateElection;

  constructor(candidateElection: CandidateElection, voterBook: VoterBook | null) {
    super(candidateElection, voterBook);
    this._candidateElection = candidateElection;
  }

  static setMultipleSelectionValues(election: CandidateSubElection): {
    allowMultipleSelectionEnabled: boolean;
    allowMultipleSelectionMinValue: number;
    allowMultipleSelectionMaxValue: number;
  } {
    if (!(election instanceof CandidateChoiceSubElection) || election.isSingleChoice) {
      return {
        allowMultipleSelectionEnabled: false,
        allowMultipleSelectionMinValue: 1,
        allowMultipleSelectionMaxValue: 1,
      };
    }

    return {
      allowMultipleSelectionEnabled: true,
      allowMultipleSelectionMinValue: election.min,
      allowMultipleSelectionMaxValue: election.max,
    };
  }

  static setScoreValues(election: CandidateSubElection): {
    minScore: number;
    maxScore: number;
  } {
    if (!(election instanceof CandidateScoreSubElection)) {
      return {
        minScore: 0,
        maxScore: 100,
      };
    }

    return {
      minScore: election.min,
      maxScore: election.max,
    };
  }

  static setRankValues(election: CandidateSubElection): {
    rank: number;
    rankWeights: number[];
  } {
    if (!(election instanceof CandidateRankSubElection)) {
      return {
        rank: 1,
        rankWeights: [100],
      };
    }

    return {
      rank: election.electionCount,
      rankWeights: election.rankWeights,
    };
  }

  static setCandidates(candidates: Candidate[]): CandidateFields[] {
    return candidates.map((candidate) => ({
      id: uuid(),
      description: candidate.description,
      title: candidate.name,
      social: candidate.social,
      imageUrls: {
        main: candidate.imageUrl,
        others: candidate.memberImageUrls,
      },
      members: candidate.members.map((member) => ({
        id: uuid(),
        name: member.name,
        department: {
          enabled: !!member.department,
          value: member.department,
        },
        position: {
          enabled: !!member.position,
          value: member.position,
        },
        biography: {
          enabled: member.bio && member.bio.length > 0,
          value: member.bio?.map((bio) => ({
            date: bio.year || '',
            description: bio.title || '',
          })),
        },
        imageUrl: {
          enabled: !!member.imageUrl,
          value: member.imageUrl,
        },
      })),
    }));
  }

  getCandidateElectionForm(): CandidateElectionForm {
    return {
      candidateElections: this._candidateElection.subElections.map((election) => {
        const multipleSelectionValues =
          CandidateElectionApplicationAdapter.setMultipleSelectionValues(election);
        const scoreValues = CandidateElectionApplicationAdapter.setScoreValues(election);
        const rankValues = CandidateElectionApplicationAdapter.setRankValues(election);
        const candidates = CandidateElectionApplicationAdapter.setCandidates(election.candidates);

        return {
          key: uuid(),
          type: ELECTION_TYPE_MAPPER[election.category],
          title: election.title,
          description: election.description?.text || '',
          candidates,
          shuffle: election.shuffle,
          includeAbstention: election.includeAbstention,
          ...multipleSelectionValues,
          ...scoreValues,
          ...rankValues,
        };
      }),
    };
  }

  getForms(): CandidateElectionApplicationForms {
    return {
      introForm: this.getIntroForm(),
      customThemeForm: this.getCustomThemeForm(),
      endingForm: this.getEndingForm(),
      settingsForm: this.getSettingsForm(),
      voterBookForm: this.getVoterBookForm(),
      candidateElectionForm: this.getCandidateElectionForm(),
    };
  }
}
