import { useMemo } from 'react';
import moment from 'moment-timezone';
import { Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { t } from 'i18next';

import { Period } from 'types/application';

type Props = {
  type: 'election' | 'survey';
  isVisible: boolean;
  periods: Period[];
  onClose: () => void;
  onSubmit: (periods: Period[]) => void;
};

function PeriodErrorModal({ type, isVisible, periods, onClose, onSubmit }: Props) {
  const adjustedPeriods = useMemo(() => adjustPeriods(periods), [isVisible]);

  return (
    <Modal isVisible={isVisible} onClose={onClose} size='md'>
      <ModalHeader
        size='b1'
        hideCloseButton
        title={t(`admin:components.features.application.modal.periodError.title.${type}`)}
        onClose={onClose}
      />

      <ModalBody>
        <Text type='b3' fontWeight={400} color='gray-400'>
          {t(`admin:components.features.application.modal.periodError.description.${type}`)}
        </Text>

        <br />
        <Text type='b3' fontWeight={400} color='gray-600'>
          {adjustedPeriods.map(({ startDate, endDate }) => (
            <div key={startDate}>
              {moment(startDate).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm')} ~{' '}
              {moment(endDate).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm')}
            </div>
          ))}
        </Text>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t('admin:components.features.application.modal.periodError.actions.cancel'),
            type: 'button',
            variant: 'gray',
            onClick: onClose,
          },
          {
            text: t('admin:components.features.application.modal.periodError.actions.submit'),
            type: 'button',
            variant: 'primary',
            onClick: () => {
              onSubmit(adjustPeriods(adjustedPeriods));
              onClose();
            },
          },
        ]}
      />
    </Modal>
  );
}

export default PeriodErrorModal;

const findEarliestStartTime = (periods: Period[]) => {
  let earliestStartTime: moment.Moment | null = null;

  periods.forEach((period) => {
    const startDate = moment(period.startDate);
    if (!earliestStartTime || startDate.isBefore(earliestStartTime)) {
      earliestStartTime = startDate;
    }
  });

  return earliestStartTime;
};

const adjustPeriods = (periods: Period[]) => {
  const earliestStartTime = findEarliestStartTime(periods);
  if (!earliestStartTime) {
    return periods;
  }

  const currentTime = moment();
  const timeDifference = currentTime.diff(earliestStartTime, 'minutes');

  return periods.map((period) => {
    const startDate = moment.tz(period.startDate, 'Asia/Seoul').add(timeDifference, 'minutes');
    const endDate = moment.tz(period.endDate, 'Asia/Seoul').add(timeDifference, 'minutes');

    return {
      startDate: startDate.format('YYYY-MM-DDTHH:mm'),
      endDate: endDate.format('YYYY-MM-DDTHH:mm'),
    };
  });
};
