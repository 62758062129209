import { useState, useEffect } from 'react';
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  OutlineButton,
  PlainButton,
  Text,
} from '@pickme/ui';
import { useDropzone } from 'react-dropzone';
import { useSetRecoilState } from 'recoil';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

import { createDownloadVoterBookTemplate } from 'apis/file';

import { toast } from 'states/toast';

import DownloadIcon from 'resources/icons/application/download.png';
import UploadIcon from 'resources/icons/application/upload.png';
import SheetIcon from 'resources/icons/application/excel.png';
import RemoveIcon from 'resources/icons/application/remove.png';

import './index.scss';

type Props = {
  isVisible: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmitVoters: (file: File) => void;
};

function AddVotersModal({ isVisible, isLoading, onClose, onSubmitVoters }: Props) {
  const setToast = useSetRecoilState(toast);

  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | undefined>();

  const onSubmit = () => {
    if (!file) {
      return;
    }

    onSubmitVoters(file);
  };

  const onDrop = (files: File[]) => {
    // TODO: Move loading into right place
    setLoading(true);
    setFile(files[0]);
    // TODO: Move loading into right place
    setLoading(false);
  };

  const onDropRejected = () => {
    // TODO: Add toast message
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    maxFiles: 1,
    multiple: false,
    minSize: 50,
    // TODO: Add maxSize,
    // maxSize: ,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
  });

  useEffect(() => {
    if (isVisible) {
      setFile(undefined);
    }
  }, [isVisible]);

  return (
    <Modal isVisible={isVisible} onClose={onClose} size='lg' disableToCloseOnBackdrop>
      <ModalHeader
        title={t('admin:components.features.application.form.voter.modals.addVoters.title')}
        onClose={onClose}
      />

      <ModalBody>
        <div className='voter-form__voters__title'>
          <Text type='b3' color='primary-500' fontWeight={400}>
            01
          </Text>
          <Text type='b3' fontWeight={400}>
            {t(
              'admin:components.features.application.form.voter.modals.addVoters.items.template.title',
            )}
          </Text>
        </div>

        <div className='voter-form__voters__description'>
          <Text type='b3' fontWeight={400} color='gray-400'>
            {t(
              'admin:components.features.application.form.voter.modals.addVoters.items.template.description',
            )}
          </Text>
        </div>

        <div className='voter-form__voters__button'>
          <OutlineButton
            variant='success'
            width='100%'
            size='md'
            onClick={() => {
              createDownloadVoterBookTemplate().then(() => {
                setToast({
                  isVisible: true,
                  type: 'success',
                  message: t(
                    'admin:components.features.application.form.voter.modals.addVoters.messages.success',
                  ),
                });
              });
            }}
          >
            <img className='voter-form__voters__template-icon' src={DownloadIcon} alt='download' />
            {t(
              'admin:components.features.application.form.voter.modals.addVoters.items.template.button',
            )}
          </OutlineButton>
        </div>

        <div className='voter-form__voters__title'>
          <Text type='b3' color='primary-500' fontWeight={400}>
            02
          </Text>
          <Text type='b3' fontWeight={400}>
            {t(
              'admin:components.features.application.form.voter.modals.addVoters.items.upload.title',
            )}
          </Text>
        </div>

        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {file ? (
            <div className='voter-form__voters__upload-file'>
              <div className='voter-form__voters__upload-file__title'>
                <img src={SheetIcon} alt='sheet' />
                <Text type='b3' fontWeight={300}>
                  {file.name}
                </Text>
              </div>
              <PlainButton
                className='voter-form__voters__upload-file__remove'
                onClick={(event) => {
                  event.stopPropagation();
                  setFile(undefined);
                }}
              >
                <img src={RemoveIcon} alt='remove' />
              </PlainButton>
            </div>
          ) : (
            <div className={`voter-form__voters__upload-area ${isDragActive ? 'active' : ''}`}>
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <img src={UploadIcon} alt='upload' />
                  <Text type='b3' color='gray-400' fontWeight={300}>
                    {t(
                      'admin:components.features.application.form.voter.modals.addVoters.items.upload.description',
                    )}
                  </Text>
                </>
              )}
            </div>
          )}
        </div>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t(
              'admin:components.features.application.form.voter.modals.addVoters.actions.close',
            ),
            onClick: onClose,
            variant: 'gray',
          },
          {
            text: isLoading ? (
              <Spinner />
            ) : (
              t('admin:components.features.application.form.voter.modals.addVoters.actions.submit')
            ),
            disabled: !file || isLoading,
            onClick: onSubmit,
            type: 'submit',
          },
        ]}
      />
    </Modal>
  );
}

export default AddVotersModal;
