import dayjs from 'dayjs';
import { useFormContext, useWatch } from 'react-hook-form';
import { Text, PlainButton } from '@pickme/design-system';

import { getDateTimeString } from 'functions/date-time';

import { DateTime } from 'types/message';

import CloseIcon from 'resources/icons/common/close.svg';

import { container } from './index.css';

function ReservationDate() {
  const methods = useFormContext<{ dateTime?: DateTime }>();
  const dateTime = useWatch({ control: methods.control, name: 'dateTime' });

  const isReservationDateVisible = !!dateTime?.date && !!dateTime.time;

  return (
    isReservationDateVisible && (
      <div className={container}>
        <Text size={14} color='gray-800'>
          예약
        </Text>

        <Text size={14} color='gray-600'>
          {dayjs(getDateTimeString(dateTime)).format('YY/MM/DD(dd) HH:mm')}
        </Text>

        <PlainButton onClick={() => methods.setValue('dateTime', { date: '', time: '' })}>
          <img src={CloseIcon} alt='close' />
        </PlainButton>
      </div>
    )
  );
}

export default ReservationDate;
