import { Text } from '@pickme/design-system';

import PlaceholderIcon from 'resources/icons/nav/space_placeholder.svg';

import { image, title, trigger } from './index.css';

function Trigger({ name, logo }: { name: string; logo?: string }) {
  return (
    <div className={trigger}>
      <img
        className={image}
        src={logo || PlaceholderIcon}
        width='46'
        height='46'
        loading='lazy'
        alt={name}
      />

      <Text className={title} size={16} fontWeight={600} color='gray-800'>
        {name}
      </Text>
    </div>
  );
}

export default Trigger;
