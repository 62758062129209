import { useEffect, useState } from 'react';
import { Input, Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { t } from 'i18next';

import './index.scss';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  onSubmitButtonClick: () => void;
};

function RemoveSpaceModal({ isVisible, onClose, onSubmitButtonClick }: Props) {
  const [text, setText] = useState('');

  useEffect(() => {
    if (!isVisible) {
      setTimeout(() => {
        setText('');
      }, 500);
    }
  }, [isVisible]);

  return (
    <Modal size='lg' isVisible={isVisible} onClose={onClose} className='remove-space-modal'>
      <ModalHeader title={t('admin:components.features.space.modal.removeSpace.title')} onClose={onClose} />

      <ModalBody>
        <Text className='remove-space-modal__description' type='b3' color='gray-400'>
        {t('admin:components.features.space.modal.removeSpace.message.prefix')}
          <Text type='b3' color='error-500' fontWeight={500}>
            DELETE
          </Text>
          {t('admin:components.features.space.modal.removeSpace.message.suffix')}
        </Text>

        <Input
          placeholder='DELETE'
          width='100%'
          value={text}
          onChange={(event) => setText(event.target.value)}
        />
      </ModalBody>

      <ModalActions
        buttons={[
          { text: t('admin:components.features.space.modal.removeSpace.buttons.cancel'), variant: 'gray', onClick: onClose },
          {
            text: t('admin:components.features.space.modal.removeSpace.buttons.submit'),
            variant: 'error',
            onClick: onSubmitButtonClick,
            disabled: text !== 'DELETE',
          },
        ]}
      />
    </Modal>
  );
}

export default RemoveSpaceModal;
