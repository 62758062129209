import { useEffect } from 'react';
import { Button, Text } from '@pickme/ui';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';

import PollDetail from 'components/features/poll/PollDetail';
import PollProgress from 'components/features/poll/Progress';
import PollResult from 'components/features/poll/Result';

import { useGetPoll } from 'query-hooks/poll';

import './Report.scss';

function PollViewerPage() {
  const { id } = useParams<{ id: string }>();
  const { data: poll, isSuccess } = useGetPoll(id || '');

  useEffect(() => {
    if (isSuccess) {
      if (poll.group !== 'counted') {
        window.close();
      }
    }
  }, [isSuccess]);

  return (
    <div className='poll-report'>
      <div className='poll-viewer'>
        <div className='poll-viewer__title'>
          <Text type='h3' fontWeight={600}>
            {poll?.name || ''}
          </Text>

          <Button
            className='poll-viewer__title__download'
            onClick={() => {
              window.print();
            }}
          >
            {t('admin:pages.poll.report.button')}
          </Button>
        </div>

        <PollDetail poll={poll} />
        <PollProgress id={id || ''} showTitle noAnimation />
        <PollResult id={id || ''} showTitle noAnimation />
      </div>

      <div className='poll-report__footnotes'>
        {t('admin:pages.poll.report.footer.0')}(<a href='https://www.ohpick.me'>www.ohpick.me</a>)
        {t('admin:pages.poll.report.footer.1')}
      </div>
    </div>
  );
}

export default PollViewerPage;
