import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, PlainButton } from '@pickme/design-system';

import ScrollToTopButton from 'components/common/ScrollToTopButton';
import ChannelInfo from 'components/features/home-v2/ChannelInfo';
import HomePollSearchKindTabsV2 from 'components/features/home-v2/PollSearchKindTabs';
import PollGroupSelect from 'components/features/home-v2/PollGroupSelect';
import HomePollCardsV2 from 'components/features/home-v2/HomePollCards';
import HomePendingPollCards from 'components/features/home-v2/HomePendingPollCards';
import PollActionsModal from 'components/features/home-v2/modal/PollActions';

import { useGetPendingPollsCount, useGetPollStats } from 'query-hooks/poll-v2';

import { useActiveChannel } from 'hooks/useActiveChannel';

import { EMPTY_STRING } from 'constants/common';

import { PollSearchGroupV2, PollKindForSearchV2 } from 'types/poll-v2';

import AddIcon from 'resources/icons/dashboard/add.svg';

import { useHomePollSearchQueryParamsV2 } from './useHomePollSearchQueryParamsV2';

import { container, top, bottom, whiteBackground, wrapper, grayRoundedButton } from './index.css';

function HomeV2() {
  const navigate = useNavigate();
  const [isPendingVisible, setIsPendingVisible] = useState(false);

  const [params, setParams] = useHomePollSearchQueryParamsV2(DEFAULT_POLL_SEARCH_QUERY);
  const { data: pendingCount } = useGetPendingPollsCount(params.kind);

  const { activeChannel: channel } = useActiveChannel();

  const { data: pollStatistics } = useGetPollStats(params.kind);

  return (
    <div className={container}>
      <div className={whiteBackground} />

      <div className={wrapper.parent}>
        <div className={wrapper.child}>
          <div className={top.container}>
            <ChannelInfo
              channelId={channel?._id || EMPTY_STRING}
              channelName={channel?.name || EMPTY_STRING}
              channelLogo={channel?.logo || EMPTY_STRING}
            />

            <div className={top.tabs}>
              <HomePollSearchKindTabsV2 selected={params.kind} />

              <Button
                icon={AddIcon}
                onClick={() => navigate('/poll/init', { unstable_viewTransition: true })}
                className={top.newButton}
              >
                새로 만들기
              </Button>
            </div>
          </div>

          <div className={bottom.container}>
            <div className={bottom.searchBar}>
              <PollGroupSelect
                selected={params.groups}
                onSelect={(groups) =>
                  setParams({ ...DEFAULT_POLL_SEARCH_QUERY, kind: params.kind, groups })
                }
                pollStatistics={pollStatistics}
                disableOpen={isPendingVisible}
                triggerClassName={grayRoundedButton({ active: !isPendingVisible })}
                onTriggerClick={() => setIsPendingVisible(false)}
              />

              <PlainButton
                className={grayRoundedButton({ active: isPendingVisible })}
                onClick={() => setIsPendingVisible(true)}
              >
                저장 {pendingCount}
              </PlainButton>

              {/* TODO: 갤러리뷰 모드의 대시보드가 피그마에 추가되면 갤러리뷰 / 테이블뷰 선택 버튼을 추가해야 합니다. */}
            </div>

            {isPendingVisible ? (
              <HomePendingPollCards query={params} />
            ) : (
              <HomePollCardsV2 query={params} />
            )}
          </div>
        </div>
      </div>

      <ScrollToTopButton />

      <PollActionsModal />
    </div>
  );
}

export default HomeV2;

const DEFAULT_POLL_SEARCH_QUERY = {
  kind: PollKindForSearchV2.All,
  groups: PollSearchGroupV2.All,
  page: 1,
};
