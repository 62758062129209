import { findKey } from 'lodash-es';
import { useEffect } from 'react';
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  Dropdown,
  Input,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  OutlineButton,
  Text,
} from '@pickme/ui';
import { ChargeTransaction } from '@pickme/core';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';
import FieldTitle from 'components/features/application/label/old_FieldTitle';

import { useCancelCharge, useGetIncoming } from 'query-hooks/payment';

import { cancelPointPurchaseModal } from 'states/modal';
import { toast } from 'states/toast';

import { BANKS } from 'constants/payment';

import { ChargeCancelForm } from 'types/payment';

import DownIcon from 'resources/icons/application/down.png';

import './index.scss';

function CancelModal() {
  const setToast = useSetRecoilState(toast);
  const [{ isVisible, chargeId, paymentOid }, setCancelModal] =
    useRecoilState(cancelPointPurchaseModal);

  const { data, isLoading: isGetChargeLoading } = useGetIncoming(chargeId);

  const onClose = () => {
    setCancelModal({ isVisible: false, chargeId: '', paymentOid: '' });
  };

  const { mutate, isLoading: isCancelLoading } = useCancelCharge();

  const isLoading = isGetChargeLoading || isCancelLoading;
  const methods = useForm<ChargeCancelForm>({
    defaultValues: {
      chargeId: '',
      accountNumber: '',
      bank: '',
      holderName: '',
      reason: '',
      paymentOid: '',
    },
  });

  useEffect(() => {
    if (chargeId && paymentOid) {
      methods.setValue('chargeId', chargeId);
      methods.setValue('paymentOid', paymentOid);
    } else {
      methods.reset();
    }
  }, [chargeId, paymentOid]);

  const onSubmit: SubmitHandler<ChargeCancelForm> = (form) => {
    mutate(form, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  const onError: SubmitErrorHandler<ChargeCancelForm> = (form) => {
    const message =
      form.reason?.message ||
      form.accountNumber?.message ||
      form.bank?.message ||
      form.holderName?.message ||
      '';
    if (message) {
      setToast({
        isVisible: true,
        type: 'error',
        message,
      });
    }
  };

  return (
    <Modal className='charge-cancel-modal' size='lg' isVisible={isVisible} onClose={onClose}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <input hidden {...methods.register('chargeId')} />
          <input hidden {...methods.register('paymentOid')} />

          <ModalHeader
            title={t('admin:components.features.payment.modal.cancel.title')}
            onClose={onClose}
          />

          <ModalBody>
            <Text type='b3' fontWeight={400} color='gray-400'>
              {t('admin:components.features.payment.modal.cancel.message')}
            </Text>

            <div className='charge-cancel-modal__fields'>
              {data instanceof ChargeTransaction && data.details.method === 'virtualAccount' && (
                <>
                  <div className='charge-cancel-modal__row charge-cancel-modal__row--justify'>
                    <div className='charge-cancel-modal__row__title'>
                      <FieldTitle
                        title={t('admin:components.features.payment.modal.cancel.field.bank.label')}
                        isRequired
                      />
                    </div>

                    <Controller
                      name='bank'
                      control={methods.control}
                      rules={{
                        required: true,
                        validate: (value) => {
                          if (!value) {
                            return t(
                              'admin:components.features.payment.modal.cancel.field.bank.validate',
                            );
                          }

                          return true;
                        },
                      }}
                      render={() => (
                        <Dropdown
                          height={200}
                          button={
                            <OutlineButton
                              width={150}
                              height={36}
                              variant='gray'
                              type='button'
                              className='set-language-modal__dropdown-button'
                            >
                              <Text type='b3'>
                                {findKey(BANKS, (value) => value === methods.watch('bank')) ||
                                  t(
                                    'admin:components.features.payment.modal.cancel.field.bank.label',
                                  )}
                              </Text>
                              <img src={DownIcon} alt='dropdown' />
                            </OutlineButton>
                          }
                          items={Object.entries(BANKS).map(([key, value]) => ({
                            text: key,
                            onClick: () => {
                              methods.setValue('bank', value);
                            },
                          }))}
                        />
                      )}
                    />
                  </div>

                  <div className='charge-cancel-modal__row charge-cancel-modal__row--column'>
                    <div className='charge-cancel-modal__row__title'>
                      <FieldTitle
                        title={t(
                          'admin:components.features.payment.modal.cancel.field.depositor.label',
                        )}
                        isRequired
                      />
                    </div>

                    <Input
                      {...methods.register('holderName', {
                        required: true,
                        validate: (value) => {
                          if (!value) {
                            return t(
                              'admin:components.features.payment.modal.cancel.field.depositor.validate',
                            );
                          }

                          return true;
                        },
                      })}
                      placeholder={t(
                        'admin:components.features.payment.modal.cancel.field.depositor.placeholder',
                      )}
                    />
                  </div>

                  <div className='charge-cancel-modal__row charge-cancel-modal__row--column'>
                    <div className='charge-cancel-modal__row__title'>
                      <FieldTitle
                        title={t(
                          'admin:components.features.payment.modal.cancel.field.account.label',
                        )}
                        isRequired
                      />
                    </div>

                    <Input
                      {...methods.register('accountNumber', {
                        required: true,
                        validate: (value) => {
                          if (!value) {
                            return t(
                              'admin:components.features.payment.modal.cancel.field.account.validate',
                            );
                          }

                          return true;
                        },
                      })}
                      placeholder={t(
                        'admin:components.features.payment.modal.cancel.field.account.placeholder',
                      )}
                    />
                  </div>
                </>
              )}

              <div className='charge-cancel-modal__row charge-cancel-modal__row--column'>
                <div className='charge-cancel-modal__row__title'>
                  <FieldTitle
                    title={t('admin:components.features.payment.modal.cancel.field.reason.label')}
                  />
                </div>

                <textarea
                  className='md pickme-input'
                  rows={4}
                  cols={50}
                  {...methods.register('reason', {
                    validate: (value) => {
                      if (!value) {
                        return t(
                          'admin:components.features.payment.modal.cancel.field.reason.validate',
                        );
                      }

                      return true;
                    },
                  })}
                />
              </div>
            </div>
          </ModalBody>

          <ModalActions
            buttons={[
              {
                text: t('admin:components.features.payment.modal.cancel.buttons.cancel'),
                variant: 'gray',
                onClick: () => onClose(),
              },
              {
                text: isLoading ? (
                  <Spinner />
                ) : (
                  t('admin:components.features.payment.modal.cancel.buttons.submit')
                ),
                variant: 'primary',
                disabled: isLoading,
                type: 'submit',
              },
            ]}
          />
        </form>
      </FormProvider>
    </Modal>
  );
}

export default CancelModal;
