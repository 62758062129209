import { Link } from 'react-router-dom';
import { Text } from '@pickme/design-system';
import { t } from 'i18next';

import { sendSelectPollTemplate } from 'functions/analytics/poll';

import { DraftTemplate } from 'types/template';

import { cardBody, container, kindBadge, thumbnailStyle } from './index.css';

type Props = {
  template: DraftTemplate;
  index: number;
};

function PollInitializationCard({ template, index }: Props) {
  return (
    <Link
      className={container}
      to={`/poll/${template.kind === 'Election' ? 'election' : 'survey'}/draft/template-${
        template.kind === 'Election' ? 'election' : 'survey'
      }-${template.category}-${index}`}
      unstable_viewTransition
      onClick={() => {
        sendSelectPollTemplate(template.title);
      }}
    >
      <img
        className={thumbnailStyle}
        src={template.thumbnail}
        alt={template.title}
        draggable={false}
      />

      <div className={cardBody}>
        <div className={kindBadge}>
          <Text size={14} fontWeight={500} color='gray-600'>
            {template.kind === 'Election'
              ? t('admin:terms.poll.kind.election')
              : t('admin:terms.poll.kind.survey')}
          </Text>
        </div>

        <Text size={20} fontWeight={600}>
          {template.title}
        </Text>

        <Text size={16} fontWeight={400} color='gray-600'>
          {template.description}
        </Text>
      </div>
    </Link>
  );
}

export default PollInitializationCard;
