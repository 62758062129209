export default {
  notice: '일시정지/종료 시점으로부터 14일 동안 개표하지 않으면 자동으로 무효처리됩니다.',
  buttons: {
    invalid: '무효처리',
    restart: '재시작',
    pause: '일시정지',
    end: '종료하기',
    count: '개표하기',
    earlyExit: '즉시종료',
  },
};
