export default {
  number: '결제 번호',
  date: '거래 일시',
  category: {
    label: '카테고리',
    content: '적립 포인트',
  },
  description: '설명',
  point: '적립 포인트',
};
