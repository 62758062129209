import {
  METHODS_AVAILABLE_FOR_PURCHASE,
  AMOUNTS_AVAILABLE_FOR_PURCHASE,
  POINT_AMOUNT_LIMITATION,
} from 'constants/payment';
import { formatNumber } from 'functions/utils';

import { PointOrderForm } from 'types/payment';

export const validatePointPaymentOptions = (
  orderForm: Partial<PointOrderForm>,
  method?: string,
) => {
  if (!orderForm.orderId || !orderForm.paymentKey) {
    return false;
  }

  if (!METHODS_AVAILABLE_FOR_PURCHASE.includes(method || '')) {
    return false;
  }

  if (!AMOUNTS_AVAILABLE_FOR_PURCHASE.includes(orderForm.amount || 0)) {
    return false;
  }

  return true;
};

export const validatePointAmount = (point: number) => {
  const isLessThanMin = point < POINT_AMOUNT_LIMITATION.MIN;
  const isMoreThanMax = point > POINT_AMOUNT_LIMITATION.MAX;
  const isValid = !isLessThanMin && !isMoreThanMax;
  const result: { isValid: boolean; message?: string } = {
    isValid,
  };

  if (isLessThanMin) {
    result.message = `${formatNumber(POINT_AMOUNT_LIMITATION.MIN)}P 이상의 값을 입력해주세요.`;
  }

  if (isMoreThanMax) {
    result.message = `${formatNumber(POINT_AMOUNT_LIMITATION.MAX)}P 이하의 값을 입력해주세요.`;
  }

  return result;
};
