import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { Dropdown, BasicTooltip } from '@pickme/ui';
import { Plan } from '@pickme/core';
import { t } from 'i18next';

import { createDownloadExcelReport } from 'apis/file';

import { useGetPoll } from 'query-hooks/poll';
import { useGetSessionAsMember } from 'query-hooks/auth';

import { useGetFeatures } from 'hooks/useGetFeatures';
import { useGetActiveSpace } from 'hooks/useGetActiveSpace';

import { toast } from 'states/toast';
import { pollActionModal, pollShareModal } from 'states/modal';

import { PollKind } from 'types/poll';

import './index.scss';

import LinkIcon from 'resources/icons/poll-viewer/link.png';
import MoreIcon from 'resources/icons/poll-viewer/more.png';
import PreviewIcon from 'resources/icons/poll-viewer/preview.png';
import EditIcon from 'resources/icons/application/edit.png';
import RemoveIcon from 'resources/icons/application/remove.png';
import ShareIcon from 'resources/icons/poll-viewer/share.png';
import DownloadIcon from 'resources/icons/poll-viewer/download.png';
import PdfIcon from 'resources/icons/poll-viewer/pdf.png';
import ExcelIcon from 'resources/icons/poll-viewer/excel.png';

type Props = {
  id: string;
  kind?: PollKind;
  onClose?: () => void;
};

function PollViewerButtonGroups({ id, kind, onClose }: Props) {
  const navigate = useNavigate();

  const { data: memberSessionData } = useGetSessionAsMember();
  const { data: poll } = useGetPoll(id);
  const { isPaymentFailed } = useGetFeatures();

  const setPollShareModal = useSetRecoilState(pollShareModal);
  const setToast = useSetRecoilState(toast);
  const setPollActionModal = useSetRecoilState(pollActionModal);
  const space = useGetActiveSpace();

  const isExceptionalCase =
    space?._id === '656400f5836ce700480cb784' || space?._id === '6410029c777549024c2617b0';

  const isUserManageable =
    memberSessionData?.level === 'OrganAdmin' ||
    (memberSessionData?.level === 'SubAdmin' && memberSessionData?.id === poll?.authorId) ||
    isExceptionalCase;

  const allowRealTimeResult = poll?.allowRealTimeResult && poll.group !== 'ready';

  return (
    <div className='poll-viewer-layout__header__buttons'>
      <Dropdown
        direction='down'
        directionBase='bottom'
        button={
          <button
            className='poll-viewer-layout__header__button'
            type='button'
            disabled={poll?.group !== 'counted' && !allowRealTimeResult}
          >
            <img src={DownloadIcon} alt='LinkIcon' />
          </button>
        }
        items={[
          {
            text: t('admin:components.features.poll.pollViewerButtonGroups.download.pdf'),
            icon: PdfIcon,
            disabled: poll?.group !== 'counted',
            onClick: () => window.open(`/poll/report/${poll?._id}`),
          },
          {
            text: t('admin:components.features.poll.pollViewerButtonGroups.download.excel'),
            type: 'button',
            icon: ExcelIcon,
            disabled: poll?.group !== 'counted' && !allowRealTimeResult,
            onClick: () => {
              if (!poll) {
                return;
              }

              createDownloadExcelReport(poll?._id, poll?.kind, poll?.name);
            },
          },
        ]}
      />

      <BasicTooltip
        message={t('admin:components.features.poll.pollViewerButtonGroups.link.label')}
        direction='down'
        align='right'
      >
        <button
          className='poll-viewer-layout__header__button'
          type='button'
          onClick={() => {
            navigator.clipboard
              .writeText(`${import.meta.env.VITE_USER_URL}/poll/${id}`)
              .then(() => {
                setToast({
                  isVisible: true,
                  type: 'success',
                  message: t(
                    'admin:components.features.poll.pollViewerButtonGroups.link.copy.success',
                  ),
                });
              })
              .catch(() => {
                setToast({
                  isVisible: true,
                  type: 'error',
                  message: t(
                    'admin:components.features.poll.pollViewerButtonGroups.link.copy.fail',
                  ),
                });
              });
          }}
        >
          <img src={LinkIcon} alt='LinkIcon' />
        </button>
      </BasicTooltip>

      <BasicTooltip
        message={t('admin:components.features.poll.pollViewerButtonGroups.preview.label')}
        direction='down'
        align='right'
      >
        <button
          className='poll-viewer-layout__header__button'
          type='button'
          onClick={() => {
            window.open(`/poll/${id}/preview`, `previewer-${id}`, `width=${390}, height=${650}`);
          }}
        >
          <img src={PreviewIcon} alt='PreviewIcon' />
        </button>
      </BasicTooltip>

      <Dropdown
        direction='down'
        directionBase='bottom'
        button={
          <button className='poll-viewer-layout__header__button' type='button'>
            <img src={MoreIcon} alt='MoreIcon' />
          </button>
        }
        items={[
          {
            text: t('admin:components.features.poll.pollViewerButtonGroups.more.share'),
            icon: ShareIcon,
            onClick: () =>
              setPollShareModal({
                isVisible: true,
                pollName: poll?.name || '',
                pollId: poll?._id || '',
              }),
          },
          {
            text: t('admin:components.features.poll.pollViewerButtonGroups.more.edit'),
            type: 'button',
            icon: EditIcon,
            disabled:
              !isUserManageable ||
              poll?.group === 'counted' ||
              poll?.group === 'canceled' ||
              (poll?.subscriptionType !== Plan.Free && isPaymentFailed),
            onClick: () => {
              if (onClose) {
                onClose();
              }

              navigate(`/poll/${kind === 'Election' ? 'election' : 'survey'}/edit/${id}`);
            },
          },
          {
            text: t('admin:components.features.poll.pollViewerButtonGroups.more.remove'),
            type: 'button',
            icon: RemoveIcon,
            color: 'red',
            disabled: !isUserManageable || poll?.group === 'ongoing',
            onClick: () => {
              setPollActionModal({
                isVisible: true,
                kind: poll?.kind || 'Election',
                type: 'remove',
                id,
              });
            },
          },
        ]}
      />
    </div>
  );
}

export default PollViewerButtonGroups;
