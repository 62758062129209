import moment from 'moment-timezone';
import { saveAs } from 'file-saver';

import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';
import { PollKind } from 'types/poll';

export const FILE_APIS: Apis = {
  uploadFile: { method: 'POST', url: '/api/file' },
  downloadVoterBook: { method: 'GET', url: '/api/poll/:pollId/voterbook' },
  downloadVoterBookTemplate: { method: 'GET', url: '/api/poll/voters-form' },
  downloadFile: { method: 'GET', url: '/api/file/:id' },
  downloadElectionReport: { method: 'GET', url: '/api/poll/:id/election-result/download' },
  downloadSurveyReport: { method: 'GET', url: '/api/poll/:id/survey-result/download' },
};

export const createUploadFileRequest = (file: File) => {
  const form = new FormData();
  form.append('file', file, encodeURIComponent(file.name));

  return createHttpRequest<{ fileOid: string }>({
    headers: {
      // TODO: Check if it need to work properly in some browsers.
      'Content-Type': 'multipart/form-data; charset: UTF-8;',
    },
    ...FILE_APIS.uploadFile,
    data: form,
  });
};

export const createDownloadVoterBook = (
  pollId: string,
  pollName: string,
  targetType: 'all' | 'votedVoters',
) =>
  createHttpRequest<Blob>({
    ...FILE_APIS.downloadVoterBook,
    responseType: 'blob',
    params: { pollId },
    query: {
      type: 'xlsx',
      targetType,
    },
  }).then((response) =>
    saveAs(
      response.data,
      `${pollName}_${targetType === 'all' ? '대상자' : '참여자'}_${moment()
        .tz('Asia/Seoul')
        .format('YYYY_MM_DD_HH_mm')}.xlsx`,
    ),
  );

export const createDownloadVoterBookTemplate = () =>
  createHttpRequest<Blob>({
    ...FILE_APIS.downloadVoterBookTemplate,
    responseType: 'blob',
  }).then((response) => saveAs(response.data, `대상자_명부_템플릿.xlsx`));

export const createDownloadAttachedFile = (fileId: string, fileName: string) =>
  createHttpRequest<Blob>({
    ...FILE_APIS.downloadFile,
    responseType: 'blob',
    params: { id: fileId },
    withoutOrganizationOid: true,
  }).then((response) => saveAs(response.data, fileName));

export const createDownloadExcelReport = (pollId: string, kind: PollKind, pollName: string) => {
  const request =
    kind === 'Election' ? FILE_APIS.downloadElectionReport : FILE_APIS.downloadSurveyReport;

  return createHttpRequest<Blob>({
    ...request,
    responseType: 'blob',
    params: { id: pollId },
  }).then((response) => saveAs(response.data, `${pollName}_결과.xlsx`));
};
