import CandidateCardContents from 'components/features/poll-card-contents/Candidate';

import { Candidate } from 'types/poll';

import './index.scss';

type Props = {
  candidate: Candidate;
};

function CandidateCard({ candidate }: Props) {
  return (
    <div className='poll-detail__election__candidate'>
      <CandidateCardContents
        title={candidate.title}
        tags={candidate.tags}
        members={candidate.members}
      />
    </div>
  );
}

export default CandidateCard;
