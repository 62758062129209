import accept from './accept';
import invite from './invite';
import join from './join';
import manager from './manager';
import selector from './selector';
import settings from './settings';

export default {
  accept,
  invite,
  join,
  manager,
  selector,
  settings,
};
