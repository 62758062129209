import moment from 'moment-timezone';
import { ModalBody, Text } from '@pickme/ui';
import { ExtraTransaction } from '@pickme/core';
import { t } from 'i18next';

import IncomingDetailRow from 'components/features/payment/modal/IncomingDetail/IncomingDetailRow';

import { parseExtraTransactionToBadge } from 'functions/payment';

const setIncomingRows = (incoming: ExtraTransaction) => {
  const items = [
    {
      title: t('admin:components.features.payment.modal.incomingDetail.extraTransaction.number'),
      content: incoming.paymentOid || '',
    },
    {
      title: t('admin:components.features.payment.modal.incomingDetail.extraTransaction.date'),
      content: incoming.createdAt
        ? moment(incoming.createdAt).tz('Asia/Seoul').format('YY/MM/DD HH:mm')
        : '',
    },
    {
      title: t(
        'admin:components.features.payment.modal.incomingDetail.extraTransaction.category.label',
      ),
      content: t(
        'admin:components.features.payment.modal.incomingDetail.extraTransaction.category.content',
      ),
    },
    {
      title: t(
        'admin:components.features.payment.modal.incomingDetail.extraTransaction.description',
      ),
      content: incoming.description,
    },
    {
      title: t('admin:components.features.payment.modal.incomingDetail.extraTransaction.point'),
      content: incoming.amount.print(),
    },
  ];

  return items;
};

function IncomingDetailModal({ extraTransaction }: { extraTransaction: ExtraTransaction }) {
  const badge = parseExtraTransactionToBadge();

  return (
    <ModalBody>
      <Text type='b2' fontWeight={500} color='primary-700'>
        {extraTransaction ? badge.text : ''}
      </Text>

      <hr />

      <div className='incoming-detail-modal__section'>
        {setIncomingRows(extraTransaction).map((item) => (
          <IncomingDetailRow title={item.title} content={item.content} key={item.title} />
        ))}
      </div>
    </ModalBody>
  );
}

export default IncomingDetailModal;
