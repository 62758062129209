import moment from 'moment-timezone';
import { read, utils, writeFile } from 'xlsx';

import { VoterBody } from 'types/application';

export async function parseSheetAsVoters(file: File) {
  const rawRows = await readSpreadSheet(file);
  const parsedRows = parseRows(rawRows);

  return parsedRows;
}

async function readSpreadSheet(file: File) {
  const arrayBuffer = await file.arrayBuffer();
  const wb = read(arrayBuffer);
  const rawRows = utils.sheet_to_json<{
    이름: string;
    고유번호: string;
    전화번호: string;
    이메일: string;
    분류: string;
  }>(wb.Sheets[wb.SheetNames[0]]);

  return rawRows;
}

function parseRows(
  rows: {
    이름: string;
    고유번호: string;
    전화번호: string;
    이메일: string;
    분류: string;
  }[],
) {
  const result = rows.slice().map((row) => {
    const name = row['이름']?.toString();
    const userId = row['고유번호']?.toString();
    const phoneNumber = row['전화번호']?.toString();
    const email = row['이메일']?.toString();
    const category = row['분류']?.toString();

    return {
      name: `${name?.trim() || ''}`,
      userId: `${userId?.trim() || ''}`,
      phoneNumber: phoneNumber?.trim()?.replaceAll('-', '') || undefined,
      email: email?.trim().toLocaleLowerCase() || undefined,
      category: category?.trim() || undefined,
    };
  });

  return result;
}

const GUIDE_MESSAGE = `<안내>
    - 이름은 필수 입력사항입니다.
    - 전화번호, 이메일 항목 중 1개는 필수 입력사항입니다.
    - 분류 항목에 선거인의 소속 하위 분류를 입력해주세요. 개표 완료 후 분류별 투표 결과를 확인할 수 있습니다.
    - 정해진 항목 외의 컬럼 추가시 선거 신청 중 오류가 발생할 수 있습니다. 정해진 양식에 맞춰 작성해주시길 바랍니다.
  `;

export function downloadCurrentVoterBook(voterBook: VoterBody[], pollName: string) {
  const voterBookPaperForm = [['이름', '고유번호', '전화번호', '이메일', '분류', GUIDE_MESSAGE]];

  voterBook.forEach((voter) => {
    voterBookPaperForm.push([
      voter.name,
      voter?.userId || '',
      voter.phoneNumber || '',
      voter.email || '',
      voter.category || '',
    ]);
  });

  const sheetName = 'Sheet1';
  const workbook = utils.book_new();
  const sheet = utils.aoa_to_sheet(voterBookPaperForm, { cellStyles: true });
  sheet['!merges'] = [{ s: { c: 5, r: 0 }, e: { c: 9, r: 11 } }];
  sheet['!cols'] = [
    { width: 14 },
    { width: 17 },
    { width: 17 },
    { width: 28 },
    { width: 14 },
    { width: 10 },
    { width: 10 },
    { width: 10 },
  ];

  utils.book_append_sheet(workbook, sheet, sheetName);
  writeFile(
    workbook,
    `${pollName}_${moment().tz('Asia/Seoul').format('YYMMDD_HHmm')}_대상자_명단.xlsx`,
  );
}
