import { Checkbox, Dropdown, PlainButton, Text } from '@pickme/ui';
import { t } from 'i18next';

import { VoterBody } from 'types/application';

import MoreIcon from 'resources/icons/application/more.png';
import EditIcon from 'resources/icons/application/edit.png';
import RemoveIcon from 'resources/icons/application/remove.png';
import VotedIcon from 'resources/icons/application/voted.jpg';

import './index.scss';

type Props = {
  selected: boolean;
  onSelect: (checked: boolean) => void;
  voter: VoterBody;
  onEditButtonClick: () => void;
  onRemoveButtonClick: () => void;
};

function VoterFormRow({
  selected,
  onSelect,
  voter,
  onEditButtonClick,
  onRemoveButtonClick,
}: Props) {
  return (
    <div
      tabIndex={-1}
      role='button'
      className={`voter-form__voters__row ${voter.hasVoted ? '' : 'clickable'}`}
      onKeyDown={() => {
        if (!voter.hasVoted) {
          onEditButtonClick();
        }
      }}
      onClick={() => {
        if (!voter.hasVoted) {
          onEditButtonClick();
        }
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className='voter-form__voters__row__number voter-form__voters__number'
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <Checkbox
          checked={selected}
          disabled={voter.hasVoted}
          onChange={(event) => {
            if (event.target.checked) {
              onSelect(true);
            } else {
              onSelect(false);
            }
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        />
      </div>

      <div className='voter-form__voters__row__name voter-form__voters__name'>
        <Text type='b3' fontWeight={300}>
          {voter.name}
        </Text>
      </div>

      <div className='voter-form__voters__row__id voter-form__voters__id'>
        <Text type='b3' fontWeight={300}>
          {voter?.userId || '-'}
        </Text>
      </div>

      <div className='voter-form__voters__row__phone voter-form__voters__phone'>
        <Text type='b3' fontWeight={300}>
          {voter.phoneNumber || '-'}
        </Text>
      </div>

      <div className='voter-form__voters__row__email voter-form__voters__email'>
        <Text type='b3' fontWeight={300}>
          {voter.email || '-'}
        </Text>
      </div>

      <div className='voter-form__voters__row__category voter-form__voters__category'>
        <Text type='b3' fontWeight={300}>
          {voter.category || '-'}
        </Text>
      </div>

      <div className='voter-form__voters__row__open voter-form__voters__open'>
        {voter.hasVoted ? (
          <img src={VotedIcon} alt='voted' />
        ) : (
          <Dropdown
            direction='down'
            button={
              <PlainButton>
                <img src={MoreIcon} alt='more' />
              </PlainButton>
            }
            items={[
              {
                type: 'button',
                icon: EditIcon,
                text: t('admin:components.features.application.form.voter.table.body.buttons.edit'),
                onClick: () => onEditButtonClick(),
              },
              {
                type: 'button',
                icon: RemoveIcon,
                text: t(
                  'admin:components.features.application.form.voter.table.body.buttons.remove',
                ),
                color: 'red',
                onClick: () => onRemoveButtonClick(),
              },
            ]}
          />
        )}
      </div>
    </div>
  );
}

export default VoterFormRow;
