import { CSSProperties, useState } from 'react';
import { Callout, Dropdown, SecondaryBadge, Table, Text } from '@pickme/ui';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { t } from 'i18next';

import Pagination from 'components/common/Pagination';
import Tabs from 'components/common/Tabs';
import SubscriptionBadge from 'components/features/badge/SubscriptionBadge';

import { useGetPollsHistory, useGetPollCreationStatistics } from 'query-hooks/poll';
import { useGetSubscriptionHistory } from 'query-hooks/payment';

import { useGetFeatures } from 'hooks/useGetFeatures';
import { usePollQueryString } from 'hooks/usePollQueryString';

import { parseUrlWithPage } from 'functions/url';

import { SETTINGS_POLL_TABS } from 'constants/poll';

import { PollInHistoryRequest } from 'types/poll';
import { SubscriptionHistory } from 'types/payment';

import SearchIcon from 'resources/icons/table/search.png';
import DownArrowIcon from 'resources/icons/application/kind/down.png';

import './History.scss';

function PollHistory() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { page } = usePollQueryString();

  const [selectedSubscriptionIndex, selectSubscriptionIndex] = useState(0);

  const { type } = useGetFeatures();
  const { data: subscriptionHistory } = useGetSubscriptionHistory();
  const { data: stats } = useGetPollCreationStatistics();
  const { data, isLoading } = useGetPollsHistory(page);
  const { polls = [], endPage = 1 } = data || {};

  const onMovePage = (selectedPage: number) => {
    const parsedPage = parseUrlWithPage(`${pathname}${search}`, selectedPage);
    navigate(parsedPage);
  };

  const tabs = SETTINGS_POLL_TABS.map(({ text, url }) => ({
    text,
    onClick: () => navigate(url),
  }));

  return (
    <div className='poll-dashboard'>
      <Text type='h3' className='page-title'>
        {t('admin:pages.poll.history.title')}
      </Text>

      <div className='notification__tabs-container'>
        <Tabs tabs={tabs} selectedIndex={0} />
      </div>

      <Callout
        id='poll-history'
        sentences={[
          t('admin:pages.poll.history.callout.0'),
          t('admin:pages.poll.history.callout.1'),
        ]}
        variant='primary'
      />

      <div className='poll-history__stats__container'>
        <CreationStatCard
          total={stats?.total || 0}
          election={stats?.election || 0}
          survey={stats?.survey || 0}
        />

        {type !== 'enterprise' &&
          !!subscriptionHistory &&
          subscriptionHistory?.[selectedSubscriptionIndex] && (
            <SubscriptionCard
              history={subscriptionHistory?.[selectedSubscriptionIndex]}
              onSelect={(index) => selectSubscriptionIndex(index)}
              dropdownItems={
                subscriptionHistory.slice().map(({ period }, index) => ({
                  text: `${moment(period.start).tz('Asia/Seoul').format('YYYY.MM.DD')}~${moment(
                    period.end,
                  )
                    .tz('Asia/Seoul')
                    .format('YYYY.MM.DD')}`,
                  index,
                })) || []
              }
            />
          )}
      </div>

      <Table
        className='poll-history-table'
        headers={tableHeaders}
        id='poll-history'
        showSkeleton={isLoading}
        rows={setTableRows(polls)}
        placeholder={{
          icon: SearchIcon,
          text: t('admin:pages.poll.history.search.result.blank'),
        }}
      />

      <Pagination page={page} endPage={endPage || 1} setPage={onMovePage} />
    </div>
  );
}

const tableHeaders = [
  { title: t('admin:pages.poll.history.table.header.plan'), name: 'plan' },
  { title: t('admin:pages.poll.history.table.header.kind'), name: 'kind' },
  { title: t('admin:pages.poll.history.table.header.name'), name: 'name' },
  { title: t('admin:pages.poll.history.table.header.period'), name: 'period' },
  { title: t('admin:pages.poll.history.table.header.count'), name: 'count' },
  { title: t('admin:pages.poll.history.table.header.author'), name: 'author' },
];

const setTableRows = (polls: PollInHistoryRequest[]) =>
  polls?.map((poll) => ({
    id: poll._id,
    items: [
      {
        data: <SubscriptionBadge type={poll.subscriptionType} />,
        name: 'plan',
      },
      {
        data: <SecondaryBadge>{poll.kind === 'Election' ? '선거' : '설문'}</SecondaryBadge>,
        name: 'kind',
      },
      {
        data: <Text type='b3'>{poll.name}</Text>,
        name: 'name',
      },
      {
        data: (
          <div>
            <Text type='b3'>
              {moment(poll.startDate).tz('Asia/Seoul').format('YY/MM/DD')} ~{' '}
              {moment(poll.endDate.date).tz('Asia/Seoul').format('YY/MM/DD')}
            </Text>
            <Text type='b3' color='gray-400'>
              {moment(poll.startDate).tz('Asia/Seoul').format('HH:mm')} ~{' '}
              {moment(poll.endDate.date).tz('Asia/Seoul').format('HH:mm')}
            </Text>
          </div>
        ),
        name: 'period',
      },
      {
        data: (
          <div>
            <Text type='b3' color='gray-600'>
              {poll.population.count}
            </Text>
          </div>
        ),
        name: 'count',
      },
      {
        data: (
          <div>
            {poll && poll.author ? (
              <>
                <Text type='b3' color='gray-600'>
                  {poll.author?.name}
                </Text>
                <Text type='b4' color='gray-300'>
                  {poll.author?.level === 'OrganAdmin' && t('admin:terms.space.owner')}
                  {poll.author?.level === 'SubAdmin' && t('admin:terms.space.manager')}
                </Text>
              </>
            ) : (
              <Text type='b4' color='gray-300'>
                탈퇴한 유저입니다
              </Text>
            )}
          </div>
        ),
        name: 'author',
      },
    ],
  })) || [];

export default PollHistory;

function CreationStatCard({
  total,
  election,
  survey,
}: {
  total: number;
  election: number;
  survey: number;
}) {
  return (
    <div className='poll-history__stats__card poll-history__stats__creation'>
      <div className='poll-history__stats__creation__item'>
        <Text type='b3' color='gray-400'>
          {t('admin:pages.poll.history.card.stat.title')}
        </Text>

        <Text type='b2' fontWeight={500}>
          {total}
        </Text>
      </div>
      <div className='poll-history__stats__creation__item'>
        <Text type='b3' color='gray-400'>
          {t('admin:terms.poll.kind.election')}
        </Text>

        <Text type='b2' fontWeight={500}>
          {election}
        </Text>
      </div>
      <div className='poll-history__stats__creation__item'>
        <Text type='b3' color='gray-400'>
          {t('admin:terms.poll.kind.survey')}
        </Text>

        <Text type='b2' fontWeight={500}>
          {survey}
        </Text>
      </div>
    </div>
  );
}

function SubscriptionCard({
  history,
  dropdownItems,
  onSelect,
}: {
  history: SubscriptionHistory;
  dropdownItems: { text: string; index: number }[];
  onSelect: (index: number) => void;
}) {
  const isPreviousHistory = moment(history.period.end).isBefore(moment());
  const percent = (history.createdPollCount / history.allowedPollCreationLimit) * 100;

  const style = {
    '--color': isPreviousHistory ? 'black' : 'var(--vivid-green-700)',
    '--percent-width': `${percent}%`,
    height: 10,
  } as CSSProperties;

  return (
    <div className='poll-history__stats__card poll-history__stats__subscription'>
      <div className='poll-history__stats__subscription__item'>
        <div className='poll-history__stats__subscription__item__labels'>
          <SubscriptionBadge type={history.subscriptionType} />

          <div className='poll-history__stats__subscription__item__period'>
            <Text type='b3' fontWeight={400} color='gray-400'>
              {isPreviousHistory
                ? t('admin:pages.poll.history.card.subscription.title.before')
                : t('admin:pages.poll.history.card.subscription.title.now')}{' '}
            </Text>

            <Text type='b3' fontWeight={400}>
              {history.createdPollCount}
            </Text>
            {history.subscriptionType !== 'enterprise' && (
              <Text type='b3' fontWeight={400} color='gray-400'>
                /{history.allowedPollCreationLimit}
              </Text>
            )}
          </div>
        </div>

        <div>
          <Dropdown
            button={
              <div className='poll-history__stats__subscription__item__dropdown__button'>
                <Text type='b3' fontWeight={400}>
                  {moment(history.period.start).tz('Asia/Seoul').format('YYYY.MM.DD')}~
                  {moment(history.period.end).tz('Asia/Seoul').format('YYYY.MM.DD')}
                </Text>
                <img src={DownArrowIcon} alt='arrow' />
              </div>
            }
            items={
              dropdownItems?.map(({ text, index }) => ({
                text,
                type: 'button',
                onClick: () => onSelect(index),
              })) || []
            }
            direction='down'
            directionBase='left'
          />
        </div>
      </div>

      <div className='pickme-bar-graph pickme-bar__graph--bar' style={style}>
        <div className='pickme-bar-graph pickme-bar__graph--percent' style={style} />
      </div>
    </div>
  );
}
