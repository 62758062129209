import clsx from 'clsx';
import { PlainButton } from '@pickme/design-system';

import GoogleIcon from 'resources/icons/auth/social-google.svg';
import KakaoIcon from 'resources/icons/auth/social-kakao.svg';
import NaverIcon from 'resources/icons/auth/social-naver.svg';

import { SocialProvider } from 'types/auth';

import { socialIcons } from './index.css';

type Props = {
  provider: SocialProvider;
  onClick?: () => void;
  disabled?: boolean;
  inactive?: boolean;
  className?: string;
};

const ICON: Record<SocialProvider, string> = {
  [SocialProvider.Google]: GoogleIcon,
  [SocialProvider.Kakao]: KakaoIcon,
  [SocialProvider.Naver]: NaverIcon,
};

function SocialIconButton({ provider, onClick, disabled, inactive = false, className }: Props) {
  return (
    <PlainButton
      className={clsx(socialIcons[provider]({ inactive }), className)}
      onClick={onClick}
      disabled={disabled}
    >
      <img src={ICON[provider]} alt={provider} />
    </PlainButton>
  );
}

export default SocialIconButton;
