export default {
  title: '알림톡',
  integration: {
    title: '채널 연동',
    tooltip:
      '채널 연동은 필수가 아니에요. 비즈니스 채널 연동을 하지 않아도 픽미 공식 계정으로 알림톡 전송이 가능합니다.',
    buttonText: '채널 연동하기',
  },
  callout: {
    sent: [
      '알림톡 전송 이력 상세 내용을 확인할 수 있어요.',
      '전송 일시, 전송 시도 건수, 전송 성공 건수, 전송 실패 건수를 확인할 수 있어요.',
      '전송 요청',
      ': 접수가 완료된 메시지를 전송 요청한 상태입니다. 메시지 타입에 따라서 해당 상태가 최대 72시간 동안 지속될 수 있어요.',
      '전송 완료',
      ': 전송 요청한 메시지의 결과를 모두 전달 받은 상태입니다. 전송 실패건은 포인트 환급이 이루어집니다.',
    ],
    reserved: [
      '전송 예약 설정한 알림톡 예약 목록을 확인할 수 있어요.',
      '투표를 삭제하면 알림톡 예약이 자동으로 취소돼요.',
    ],
  },
  search: {
    placeholder: '투표 제목을 입력해주세요.',
    result: {
      blank: {
        sent: '알림톡 전송 내역이 없어요',
        reserved: '예약된 알림톡 내역이 없어요',
      },
    },
  },
  table: {
    header: {
      sent: {
        name: '투표 제목',
        date: '전송 일시',
        point: '포인트',
        status: '상태',
        detail: '내역',
      },
      reserved: {
        name: '투표 제목',
        date: '전송 예정 일시',
        point: '예상 포인트',
        detail: '내역',
      },
    },
    body: {
      labels: {
        sending: '전송 요청',
        complete: '전송 완료',
        detail: '상세 내역',
      },
    },
  },
};
