import { Outlet } from 'react-router-dom';
import { Text } from '@pickme/design-system';

import Tabs from 'components/common/Tabs';

import { useTabWithPath } from 'hooks/useTabWithPath';
import { useQueryParams } from 'hooks/useQueryParams';

import { MESSAGE_SEND_TABS } from 'constants/notification';

import { EMPTY_STRING } from 'constants/common';

import { container } from 'styles/page.css';
import { newLabel } from './index.css';

export type SendOutletContext = { pollId: string };

function Send() {
  const [{ pollId }] = useQueryParams({ pollId: EMPTY_STRING });

  const { setTab, selectedTabIndex } = useTabWithPath({
    urlPrefix: '/service/notification/send',
    tabItems: MESSAGE_SEND_TABS,
    withQueryParams: true,
  });
  const requiredNewLabel = ['lms', 'email'];

  const tabs = MESSAGE_SEND_TABS.map(({ text, value }) => ({
    text,
    className: requiredNewLabel.includes(value) ? newLabel : EMPTY_STRING,
    onClick: () => setTab(value),
  }));

  return (
    <div className={container({ gap: 32 })}>
      <Text tag='h1' size={32} fontWeight={600}>
        메시지 보내기
      </Text>

      <div className={container({ gap: 16 })}>
        <Tabs tabs={tabs} selectedIndex={selectedTabIndex} />

        <Outlet context={{ pollId }} />
      </div>
    </div>
  );
}

export default Send;
