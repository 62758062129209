export default {
  sender: '온라인 투표 서비스 픽미',
  header: '알림톡 도착',
  button: {
    result: '결과보기',
    participate: '참여하기',
  },
  texts: {
    '1': `[OOO 온라인 투표]\n\n안녕하세요. OOO님! OOOOO가 시작되었습니다. 많은 관심과 적극적인 참여 부탁드립니다.\n\n투표 기간 :2023-04-03(월) 09:00 ~ 2023-04-05(수) 18:00`,
    '2': `[OOO 온라인 투표]\n\n안녕하세요. OOO님! OOOOO가 시작되었습니다. 많은 관심과 적극적인 참여 부탁드립니다.\n* 본 메세지는 투표에 참여하지 않은 대상자에게만 발송되는 메세지입니다.\n\n투표 기간 :2023-04-03(월) 09:00 ~ 2023-04-05(수) 18:00`,
    '3': `[OOO 온라인 투표]\n\n안녕하세요. OOO님! OOOOO가 종료되었습니다. 결과를 확인해주세요.`,
    '4': `[OOO 온라인 투표]\n\n안녕하세요. OOO님! OOOOO가 일시정지 되었습니다. 재시작되기 전까지 참여가 일시적으로 제한됩니다.`,
    '5': `[OOO 온라인 투표]\n\n안녕하세요. OOO님! OOOOO가 재시작되었습니다.`,
  },
};
