import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Input, Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

import { useReplacePaymentEmail } from 'query-hooks/payment';

import { emailValidator } from 'functions/validator/auth';

import './index.scss';

type Props = {
  isVisible: boolean;
  defaultValue?: string;
  onClose: () => void;
};

function PointBillModal({ isVisible, defaultValue, onClose }: Props) {
  const methods = useForm<{ email: string }>({
    defaultValues: {
      email: '',
    },
  });

  const { isLoading, mutate } = useReplacePaymentEmail();

  useEffect(() => {
    if (defaultValue) {
      methods.setValue('email', defaultValue);
    }
  }, [defaultValue]);

  const onSubmit: SubmitHandler<{ email: string }> = async (data) => {
    mutate(data.email, {
      onSuccess: () => onClose(),
    });
  };

  return (
    <Modal size='md' isVisible={isVisible} onClose={onClose}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <ModalHeader
            title={t('admin:components.features.payment.modal.replaceEmail.title')}
            onClose={onClose}
          />

          <ModalBody>
            <div className='replace-email-modal__description'>
              <Text type='b3' fontWeight={400} color='gray-400'>
                {t('admin:components.features.payment.modal.replaceEmail.message')}
              </Text>
            </div>

            <Input
              width='100%'
              type='email'
              {...methods.register('email', {
                setValueAs: (value) => value?.trim().toLowerCase(),
                validate: (value) => emailValidator(value),
              })}
              isValid={!methods.formState.errors.email}
              errorMessage={
                methods.formState.errors.email
                  ? t('admin:components.features.payment.modal.replaceEmail.error.message')
                  : ''
              }
            />
          </ModalBody>

          <ModalActions
            buttons={[
              {
                text: isLoading ? (
                  <Spinner />
                ) : (
                  t('admin:components.features.payment.modal.replaceEmail.buttons.submit')
                ),
                variant: 'primary',
                type: 'submit',
                disabled: isLoading,
              },
            ]}
          />
        </form>
      </FormProvider>
    </Modal>
  );
}

export default PointBillModal;
