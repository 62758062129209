import { Poll, PollProgress } from '@pickme/core';
import { intersection } from 'lodash-es';

import { useGetPoll } from 'query-hooks/poll-v2';

import { PeriodActionType } from 'types/application-v2';
import { PollAction } from 'types/poll-v2';

const PERIOD_TYPE_TO_ACTION_MAP: { [key in PeriodActionType]: PollAction[] } = {
  [PeriodActionType.Instantly]: [
    PollAction.시작하기,
    PollAction.저장,
    PollAction.수정하기,
    PollAction.일시중지,
    PollAction.재시작하기,
    PollAction.종료하기,
    PollAction.개표하기,
  ],
  [PeriodActionType.Custom]: [
    PollAction.저장,
    PollAction.수정하기,
    PollAction.예약하기,
    PollAction.예약취소,
    PollAction.강제종료,
    PollAction.개표하기,
  ],
};

const PROGRESS_TO_ACTION_MAP: { [key in PollProgress]: PollAction[] } = {
  [PollProgress.Application]: [PollAction.저장, PollAction.시작하기, PollAction.예약하기],
  [PollProgress.Pending]: [PollAction.수정하기, PollAction.시작하기, PollAction.예약하기],
  [PollProgress.Before]: [PollAction.수정하기, PollAction.예약취소],
  [PollProgress.Ongoing]: [
    PollAction.수정하기,
    PollAction.일시중지,
    PollAction.종료하기,
    PollAction.강제종료,
  ],
  [PollProgress.Paused]: [
    PollAction.수정하기,
    PollAction.재시작하기,
    PollAction.종료하기,
    PollAction.강제종료,
  ],
  [PollProgress.After]: [PollAction.수정하기, PollAction.개표하기],
  [PollProgress.Completed]: [PollAction.수정하기],
};

const usePollProperties = ({
  poll,
  application,
}: {
  poll?: Poll;
  application?: {
    periodType: PeriodActionType;
  };
  result?: any;
}) => {
  const periodType: PeriodActionType = application?.periodType || PeriodActionType.Instantly;
  const pollProgress: PollProgress = poll?.getProgress() || PollProgress.Application;

  return {
    periodType,
    pollProgress,
  };
};

export const useAblePollActions = ({
  pollId,
  application,
  result,
}: {
  pollId?: string;
  application?: {
    periodType: PeriodActionType;
  };
  result?: any;
}) => {
  const { data: poll } = useGetPoll(pollId || '');
  const pollProps = usePollProperties({ poll, application, result });

  const ableActions = intersection(
    PROGRESS_TO_ACTION_MAP[pollProps.pollProgress],
    PERIOD_TYPE_TO_ACTION_MAP[pollProps.periodType],
  );

  return ableActions;
};
