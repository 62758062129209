import { CSSProperties } from 'react';
import { NavLink } from 'react-router-dom';

import { useSidebar } from 'hooks/useSidebar';

import { ChannelNavMenuItem } from 'types/menu';

import { container, subItem, subItems, subItemsInnerContainer } from './index.css';

type Props = {
  isVisible: boolean;
  subMenuList: ChannelNavMenuItem[];
};

function SubMenu({ isVisible, subMenuList }: Props) {
  const sidebar = useSidebar();
  const subItemCount = { '--sub-item-count': subMenuList.length } as CSSProperties;

  return (
    <ul className={subItems({ isVisible })} style={subItemCount}>
      {/* 
      NOTE: 아래에 div 를 하나 더 생성된 이유:
        padding을 주면, 애니메이션 상, 어색한 부분이 생기기 때문에(padding, 따로 높이 따로 transition 됩니다.)
        innerContainer의 margin으로 처리했습니다.
    */}
      <div className={subItemsInnerContainer}>
        {subMenuList.map((subMenu) => (
          <div className={container}>
            <NavLink
              unstable_viewTransition
              to={subMenu?.link ?? ''}
              key={subMenu.title}
              className={() => subItem({ active: sidebar?.sub === subMenu.title })}
            >
              {subMenu.title}
            </NavLink>
          </div>
        ))}
      </div>
    </ul>
  );
}

export default SubMenu;
