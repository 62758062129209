import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { t } from 'i18next';

import {
  createGetManagerRequest,
  createGetManagersRequest,
  createRemoveManagerFromOrganizationRequest,
  createExamineManagerRequest,
  createSetActivationRequest,
  createActiveManagersCountRequest,
} from 'apis/manager';

import { toast } from 'states/toast';

import { ManagerQuery } from 'types/manager';

export const useGetManager = (id: string) =>
  useQuery(
    ['manager', id],
    async () => {
      const { data } = await createGetManagerRequest(id);

      return data;
    },
    {
      enabled: !!id,
    },
  );

export const useGetManagers = (query: ManagerQuery) =>
  useQuery(
    ['managers', query],
    async () => {
      const { data } = await createGetManagersRequest(query);

      return data;
    },
    {
      staleTime: 30 * 1000,
      cacheTime: 30 * 1000,
    },
  );

export const useGetAllManagers = () =>
  useInfiniteQuery(
    ['managers', 'all'],
    async ({ pageParam = 1 }) => {
      const { data } = await createGetManagersRequest({ page: pageParam, status: 'approved' });

      return { ...data, pageParam };
    },
    {
      getNextPageParam: (lastPage, allPages) =>
        allPages.length < lastPage.endPage && lastPage.pageParam + 1,
      staleTime: 30 * 1000,
      cacheTime: 30 * 1000,
    },
  );

export const useGetManagerCounts = () =>
  useQuery(
    ['managers', 'count'],
    async () => {
      const count = await createActiveManagersCountRequest();

      return count;
    },
    {
      staleTime: 30 * 1000,
      cacheTime: 30 * 1000,
    },
  );

export const useRemoveManagerFromOrganization = () => {
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id }: { id: string }) => {
      const { data } = await createRemoveManagerFromOrganizationRequest(id);

      return data;
    },
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries('managers');
        queryClient.invalidateQueries(['manager', id]);

        setToast({
          isVisible: true,
          type: 'notification',
          message: t('admin:query-hooks.manager.useRemoveManagerFromOrganization.onSuccess.toast'),
        });
      },
    },
  );
};

export const useExamineManager = () => {
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, approval }: { id: string; approval: boolean }) => {
      const { data } = await createExamineManagerRequest(id, approval);

      return data;
    },
    {
      onSuccess: (_, { id, approval }) => {
        queryClient.invalidateQueries('managers');
        queryClient.invalidateQueries(['manager', id]);

        const toastType = approval ? 'success' : 'notification';
        const toastMessage = approval
          ? t('admin:query-hooks.manager.useExamineManager.onSuccess.toast.approve')
          : t('admin:query-hooks.manager.useExamineManager.onSuccess.toast.reject');

        setToast({
          isVisible: true,
          type: toastType,
          message: toastMessage,
        });
      },
    },
  );
};

export const useSetManagerActivation = () => {
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, activation }: { id: string; activation: boolean }) => {
      const { data } = await createSetActivationRequest(id, activation);

      return data;
    },
    {
      onSuccess: (_, { id, activation }) => {
        queryClient.invalidateQueries('managers');
        queryClient.invalidateQueries(['manager', id]);

        const toastType = activation ? 'success' : 'notification';
        const toastMessage = activation
          ? t('admin:query-hooks.manager.useSetManagerActivation.onSuccess.toast.active')
          : t('admin:query-hooks.manager.useSetManagerActivation.onSuccess.toast.inactive');

        setToast({
          isVisible: true,
          type: toastType,
          message: toastMessage,
        });
      },
    },
  );
};
