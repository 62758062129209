import ResetPasswordForm from 'components/features/auth/form/ResetPassword';

import { container } from '../common.css';

function ResetPassword() {
  return (
    <div className={container}>
      <ResetPasswordForm />
    </div>
  );
}

export default ResetPassword;
