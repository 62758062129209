import { OutlineButton, Text } from '@pickme/ui';

import './index.scss';

type Props = {
  title: string;
  description: string;
  button?: { text: string; disabled?: boolean; onClick: () => void };
};

function FieldTitleWithButton({ title, description, button }: Props) {
  return (
    <div className='application-field-title-button'>
      <div className='application-field-title-button__body'>
        <Text type='b1' fontWeight={600}>
          {title}
        </Text>
        <Text type='b2' color='gray-400' fontWeight={300}>
          {description}
        </Text>
      </div>

      {button && (
        <OutlineButton
          disabled={button.disabled}
          type='button'
          size='md'
          onClick={() => button.onClick()}
        >
          {button.text}
        </OutlineButton>
      )}
    </div>
  );
}

export default FieldTitleWithButton;
