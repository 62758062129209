import { useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { Button, Dropdown, Input, OutlineButton, Switch, Text } from '@pickme/ui';
import { find, isUndefined } from 'lodash-es';
import { useSetRecoilState } from 'recoil';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { t } from 'i18next';
import ReactHtmlParser from 'html-react-parser';
import { YouTubeURLParser } from '@pickme/core';

import PlainTextArea from 'components/common/input/PlainTextArea';
import ImageField from 'components/features/application/field/ImageField';
import ImageInputButton from 'components/features/application/field/ImageInput';
import ApplicationLayout from 'components/common/layout/Application';
import FieldTitle from 'components/features/application/label/old_FieldTitle';
import Selection from 'components/features/application/form/Questions/Question/Selection';
import SubQuestion from 'components/features/application/form/Questions/Question/SubQuestion';
import KindValueDropdownField from 'components/features/application/field/KindValueDropdownField';
import KindValueInputField from 'components/features/application/field/KindValueInputField';
import VideoLinkModal from 'components/features/application/modal/VideoLink';

import { toast } from 'states/toast';

import SelectIcon from 'resources/icons/application/kind/select.png';
import ChoiceIcon from 'resources/icons/application/kind/choice.png';
import TextIcon from 'resources/icons/application/kind/text.png';
import RankIcon from 'resources/icons/application/kind/rank.png';
import ScoreIcon from 'resources/icons/application/kind/point.png';
import LineIcon from 'resources/icons/application/kind/line.png';
import DownArrowIcon from 'resources/icons/application/kind/down.png';

import ImageIcon from 'resources/icons/application/image.png';
import VideoIcon from 'resources/icons/application/video.svg';

import { QuestionFormBody, SurveyKind } from 'types/application';

import './index.scss';

type Props = {
  initialValue?: QuestionFormBody;
  disabled?: boolean;
  onQuestionSubmit: (body: QuestionFormBody) => void;
  onClose: () => void;
};

function QuestionForm({ onClose, disabled, initialValue, onQuestionSubmit }: Props) {
  const [videoLinkModal, setVideoLinkModal] = useState<{
    isVisible: boolean;
    fieldName:
      | 'videoUrl.youtube'
      | `selections.${number}.videoUrl.youtube`
      | `subQuestions.${number}.videoUrl.youtube`
      | null;
    link: string | null;
  }>({
    isVisible: false,
    fieldName: null,
    link: null,
  });

  const methods = useFormContext<QuestionFormBody>();
  const setToast = useSetRecoilState(toast);

  const selectedKindValue = methods.watch('kind');
  const selectedKind = find(KINDS, { type: selectedKindValue });

  const selectionFields = useFieldArray<QuestionFormBody, 'selections'>({
    control: methods.control,
    name: 'selections',
    rules: {
      validate: (selections) => {
        if (!selectedKind?.selections) {
          return true;
        }

        if (selections.length === 0) {
          return t(
            'admin:components.features.application.form.question.errorMessages.answerRequired',
          );
        }

        return true;
      },
    },
  });
  const watchSelections = methods.watch('selections');
  const selections =
    watchSelections?.map((field, index) => ({
      ...field,
      ...selectionFields.fields?.[index],
    })) || [];

  const subQuestionFields = useFieldArray<QuestionFormBody, 'subQuestions'>({
    control: methods.control,
    name: 'subQuestions',
    rules: {
      validate: (subQuestions) => {
        if (!selectedKind?.subQuestions) {
          return true;
        }

        if (subQuestions.length === 0) {
          return t(
            'admin:components.features.application.form.question.errorMessages.answerRequired',
          );
        }

        return true;
      },
    },
  });
  const watchSubQuestions = methods.watch('subQuestions');
  const subQuestions = watchSubQuestions?.map((field, index) => ({
    ...field,
    ...subQuestionFields.fields?.[index],
  }));

  const selectionsCount = selectionFields.fields.length ?? 0;
  const subQuestionsCount = subQuestionFields.fields.length ?? 0;

  useEffect(() => {
    if (initialValue?.kind === selectedKindValue) {
      return;
    }

    const enteredSelections = methods.getValues('selections') ?? [];
    const enteredSubQuestions = methods.getValues('subQuestions') ?? [];
    const kind = methods.getValues('kind');
    methods.reset({
      ...defaultValues,
      kind,
      selections:
        enteredSelections?.length > 0 ? enteredSelections : [{ number: 1, description: '' }],
      subQuestions: enteredSubQuestions?.length > 0 ? enteredSubQuestions : [{ description: '' }],
    });

    if (!selectedKind?.selections) {
      methods.setValue('selections', []);
    }

    if (!selectedKind?.subQuestions) {
      methods.setValue('subQuestions', []);
    }

    if (selectedKindValue === 'CheckboxQuestion') {
      methods.setValue('responseValidation.validation', 'lte');
    } else if (selectedKindValue === 'TextQuestion') {
      methods.setValue('responseValidation', {
        kind: 'AnswerLengthValidation',
        threshold: 5000,
        validation: 'lt',
      });
    } else if (selectedKindValue === 'RankQuestion') {
      methods.setValue('responseValidation.rankLimit', 1);
    } else if (selectedKindValue === 'ScoreQuestion') {
      methods.setValue('responseValidation.minScore', 1);
      methods.setValue('responseValidation.maxScore', 100);
    } else if (selectedKindValue === 'ScaleQuestion') {
      methods.setValue('min.value', 1);
      methods.setValue('max.value', 10);
    }
  }, [selectedKindValue, initialValue]);

  const validation = methods.watch('responseValidation.validation');
  useEffect(() => {
    if (selectedKindValue === 'CheckboxQuestion' && validation === 'lte') {
      methods.setValue('responseValidation.threshold', selectionsCount);
    }
  }, [selectionsCount, selectedKindValue, validation]);

  useEffect(() => {
    if (initialValue) {
      methods.reset({
        ...initialValue,
        title: initialValue.title || '',
        description: initialValue.description || '',
        videoUrl: { youtube: initialValue?.videoUrl?.youtube || '' },
        fileUrl: initialValue.fileUrl || '',
      });
    }
  }, [initialValue]);

  const handleSelectionsDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!isUndefined(destination?.index) && destination?.index !== source.index) {
      selectionFields.swap(source.index, destination?.index as number);
    }
  };

  const handleSubQuestionsDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!isUndefined(destination?.index) && destination?.index !== source.index) {
      subQuestionFields.swap(source.index, destination?.index as number);
    }
  };

  const onSubmit: SubmitHandler<QuestionFormBody> = (data) => {
    onQuestionSubmit(data);
    onClose();
  };

  const onError: SubmitErrorHandler<QuestionFormBody> = (data) => {
    const message =
      data.title?.message ||
      data.description?.message ||
      data.selections?.message ||
      data.min?.value?.message ||
      data.max?.value?.message ||
      data.responseValidation?.minScore?.message ||
      data.responseValidation?.maxScore?.message ||
      data.responseValidation?.rankLimit?.message ||
      '';
    if (message) {
      setToast({ isVisible: true, type: 'error', message });
    }
  };

  const fileUrl = methods.watch('fileUrl');
  const videoUrl = methods.watch('videoUrl.youtube');

  useEffect(() => {
    if (fileUrl) {
      methods.setValue('videoUrl.youtube', undefined);
    }
  }, [fileUrl]);

  useEffect(() => {
    if (videoUrl) {
      methods.setValue('fileUrl', undefined);
    }
  }, [videoUrl]);

  return (
    <div className='application-form--fullscreen'>
      <ApplicationLayout
        title={t('admin:components.features.application.form.question.title')}
        hideStepNavbar
        onClose={() => onClose()}
        onSelect={() => {}}
        items={[]}
      >
        <FormProvider {...methods}>
          <form
            className='application-form question-form'
            onSubmit={methods.handleSubmit(onSubmit, onError)}
          >
            <div className='question-form__kind-dropdown'>
              <Dropdown
                button={
                  <div className='question-form__kind-dropdown__button'>
                    <img src={selectedKind?.icon} alt='kind' />
                    <Text type='b3' fontWeight={600}>
                      {selectedKind?.title}
                    </Text>
                    <img src={DownArrowIcon} alt='arrow' />
                  </div>
                }
                items={KINDS.map(({ title, icon, type }) => ({
                  text: title,
                  type: 'button',
                  icon,
                  onClick: () => methods.setValue('kind', type),
                }))}
                direction='down'
                directionBase='left'
              />
            </div>

            <div className='question-form__title'>
              <PlainTextArea
                placeholder={t(
                  'admin:components.features.application.form.question.forms.question.fields.title.placeholder',
                )}
                isValid={!methods.formState.errors.title}
                maxLength={150}
                {...methods.register('title', {
                  required: true,
                  validate: (value) => value.length > 0,
                })}
              />
            </div>

            <input hidden {...methods.register('fileUrl')} />
            <input hidden {...methods.register('videoUrl.youtube')} />

            {videoUrl
              ? ReactHtmlParser(
                  new YouTubeURLParser(videoUrl).getIframe({
                    allowFullScreen: true,
                    width: 455,
                    height: 300,
                  }) || '',
                )
              : ''}

            {fileUrl && (
              <div className='question-form__image-field'>
                <ImageField
                  fileUrl={fileUrl || ''}
                  onEditButtonClick={() =>
                    document.getElementById('question-form__title-image')?.click()
                  }
                  onRemoveButtonClick={() => methods.setValue('fileUrl', '')}
                />
              </div>
            )}

            <div className='question-form__media-dropdown'>
              <Dropdown
                button={
                  <OutlineButton type='button' width={100} size='md'>
                    {t('admin:components.features.application.form.question.buttons.media')}
                  </OutlineButton>
                }
                items={[
                  {
                    text: t('admin:components.features.application.form.question.buttons.image'),
                    type: 'button',
                    icon: ImageIcon,
                    onClick: () => {
                      document.getElementById('question-form__title-image')?.click();
                    },
                  },
                  {
                    text: t('admin:components.features.application.form.question.buttons.video'),
                    type: 'button',
                    icon: VideoIcon,
                    onClick: () =>
                      setVideoLinkModal({
                        isVisible: true,
                        fieldName: 'videoUrl.youtube',
                        link: methods.getValues('videoUrl.youtube') || null,
                      }),
                  },
                ]}
                direction='down'
                directionBase='left'
                disabled={disabled}
              />
            </div>

            <ImageInputButton
              id='question-form__title-image'
              hideButton
              onSubmit={(id) => {
                methods.setValue('fileUrl', id);
              }}
            />

            <div className='question-form__selections'>
              {selectedKindValue === 'TextQuestion' && (
                <div className='question-form__selections'>
                  <div className='question-form__selections__title'>
                    <FieldTitle
                      title={t(
                        'admin:components.features.application.form.question.forms.question.fields.selections.text.label',
                      )}
                      fontWeight={600}
                    />
                  </div>
                  <Input
                    placeholder={t(
                      'admin:components.features.application.form.question.forms.question.fields.selections.text.placeholder',
                    )}
                    disabled
                  />
                </div>
              )}

              {selectedKindValue === 'ScaleQuestion' && (
                <div className='question-form__selections'>
                  <KindValueDropdownField
                    title={t(
                      'admin:components.features.application.form.question.forms.question.fields.selections.scale.label',
                    )}
                    unit=''
                    disabled={disabled}
                    fields={[
                      {
                        title: '',
                        fieldName: 'min.value',
                        min: 0,
                        max: 1,
                        validate: (value) => {
                          const maxValue = methods.getValues('max.value');
                          if (!maxValue) {
                            return true;
                          }

                          if (maxValue <= value) {
                            return t(
                              'admin:components.features.application.form.question.errorMessages.selections.scale.min',
                            );
                          }

                          return true;
                        },
                      },
                      {
                        title: '',
                        fieldName: 'max.value',
                        min: 2,
                        max: 10,
                        validate: (value) => {
                          const minValue = methods.getValues('min.value');
                          if (!minValue) {
                            return true;
                          }

                          if (minValue >= value) {
                            return t(
                              'admin:components.features.application.form.question.errorMessages.selections.scale.max',
                            );
                          }

                          return true;
                        },
                      },
                    ]}
                  />

                  <div className='question-form__scale__min'>
                    <Text type='b3' fontWeight={400}>
                      {methods.watch('min.value')}
                    </Text>

                    <Input
                      width='100%'
                      placeholder={t(
                        'admin:components.features.application.form.question.forms.question.fields.selections.scale.placeholder',
                      )}
                      {...methods.register('min.description')}
                    />
                  </div>

                  <div className='question-form__scale__max'>
                    <Text type='b3' fontWeight={400}>
                      {methods.watch('max.value')}
                    </Text>

                    <Input
                      width='100%'
                      placeholder={t(
                        'admin:components.features.application.form.question.forms.question.fields.selections.scale.placeholder',
                      )}
                      {...methods.register('max.description')}
                    />
                  </div>
                </div>
              )}

              {selectedKind?.selections && (
                <div className='question-form__selections'>
                  <div className='question-form__selections__title'>
                    <FieldTitle
                      title={t(
                        'admin:components.features.application.form.question.forms.question.fields.answers.label',
                      )}
                      fontWeight={600}
                    />
                  </div>

                  <DragDropContext
                    onDragEnd={(result) => {
                      handleSelectionsDragEnd(result);
                    }}
                  >
                    <Droppable droppableId='selections'>
                      {(droppable) => (
                        <div ref={droppable.innerRef} {...droppable.droppableProps}>
                          {selections.map((field, index) => (
                            <Draggable
                              draggableId={`draggable-selections-${field.id}`}
                              index={index}
                              key={field.id}
                              isDragDisabled={disabled}
                            >
                              {(draggable, snapshot) => (
                                <div ref={draggable.innerRef} {...draggable.draggableProps}>
                                  <Selection
                                    index={index}
                                    id={`draggable-selections-${field.id}`}
                                    isDragging={snapshot.isDragging}
                                    isRemoveButtonVisible={
                                      !disabled && selectionFields.fields.length > 1
                                    }
                                    onRemoveButtonClick={() => {
                                      selectionFields.remove(index);
                                    }}
                                    onVideoButtonClick={(link) => {
                                      setVideoLinkModal({
                                        isVisible: true,
                                        fieldName: `selections.${index}.videoUrl.youtube`,
                                        link,
                                      });
                                    }}
                                    dragHandleProps={draggable.dragHandleProps}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {droppable.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <OutlineButton
                    className='question-form__selections__add'
                    type='button'
                    disabled={disabled}
                    onClick={() =>
                      selectionFields.append({
                        description: '',
                        number: selectionFields.fields.length + 1,
                        fileUrl: '',
                      })
                    }
                  >
                    {t('admin:components.features.application.form.question.buttons.addAnswer')}
                  </OutlineButton>
                </div>
              )}

              {selectedKind?.subQuestions && (
                <div className='question-form__selections'>
                  <div className='question-form__selections__title'>
                    <FieldTitle
                      title={t(
                        'admin:components.features.application.form.question.forms.question.fields.subQuestions.label',
                      )}
                      fontWeight={600}
                    />
                  </div>

                  <DragDropContext onDragEnd={(result) => handleSubQuestionsDragEnd(result)}>
                    <Droppable droppableId='subQuestions'>
                      {(droppable) => (
                        <div ref={droppable.innerRef} {...droppable.droppableProps}>
                          {subQuestions?.map((field, index) => (
                            <Draggable
                              draggableId={`draggable-subQuestions-${field.id}`}
                              index={index}
                              key={`draggable-subQuestions-${field.id}`}
                              isDragDisabled={disabled}
                            >
                              {(draggable, snapshot) => (
                                <div ref={draggable.innerRef} {...draggable.draggableProps}>
                                  <SubQuestion
                                    index={index}
                                    id={`draggable-subQuestions-${field.id}`}
                                    isDragging={snapshot.isDragging}
                                    isRemoveButtonVisible={
                                      !disabled && subQuestionFields.fields.length > 1
                                    }
                                    onRemoveButtonClick={() => subQuestionFields.remove(index)}
                                    dragHandleProps={draggable.dragHandleProps}
                                    onVideoButtonClick={(link) => {
                                      setVideoLinkModal({
                                        isVisible: true,
                                        fieldName: `subQuestions.${index}.videoUrl.youtube`,
                                        link,
                                      });
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}

                          {droppable.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <OutlineButton
                    className='question-form__selections__add'
                    type='button'
                    disabled={disabled}
                    onClick={() =>
                      subQuestionFields.append({
                        description: '',
                        fileUrl: '',
                      })
                    }
                  >
                    {t(
                      'admin:components.features.application.form.question.buttons.addSubQuestions',
                    )}
                  </OutlineButton>
                </div>
              )}

              {selectedKindValue === 'CheckboxQuestion' && (
                <>
                  <div className='application-form__row application-form__row--justify'>
                    <FieldTitle
                      title={t(
                        'admin:components.features.application.form.question.forms.question.fields.validation.label',
                      )}
                    />

                    <Controller
                      control={methods.control}
                      name='responseValidation.validation'
                      disabled={disabled}
                      render={({ field }) => (
                        <Switch
                          id={field.name}
                          ref={field.ref}
                          disabled={disabled}
                          onChange={(event) => {
                            if (event.target.checked) {
                              field.onChange('eq');
                            } else {
                              field.onChange('lte');
                            }
                          }}
                          checked={field.value === 'eq'}
                        />
                      )}
                    />
                  </div>

                  {validation === 'eq' && (
                    <KindValueDropdownField
                      title={t(
                        'admin:components.features.application.form.question.forms.question.fields.threshold.label',
                      )}
                      unit={t('admin:terms.unit.stuff')}
                      disabled={disabled}
                      fields={[
                        {
                          title: '',
                          fieldName: 'responseValidation.threshold',
                          min: 1,
                          max: selectionsCount || 1,
                          validate: (value) => {
                            if (value < 1) {
                              return t(
                                'admin:components.features.application.form.question.errorMessages.threshold.min',
                              );
                            }

                            if (value <= selectionsCount) {
                              return true;
                            }

                            return t(
                              'admin:components.features.application.form.question.errorMessages.threshold.max',
                            );
                          },
                        },
                      ]}
                    />
                  )}
                </>
              )}

              {selectedKindValue === 'RankQuestion' && (
                <KindValueDropdownField
                  title={t(
                    'admin:components.features.application.form.question.forms.question.fields.rankLimit.label',
                  )}
                  unit={t('admin:terms.unit.rank')}
                  disabled={disabled}
                  fields={[
                    {
                      title: t(
                        'admin:components.features.application.form.question.forms.question.fields.rankLimit.label',
                      ),
                      fieldName: 'responseValidation.rankLimit',
                      min: 1,
                      max: subQuestionsCount || 1,
                      validate: (value) => {
                        if (value <= 0) {
                          return t(
                            'admin:components.features.application.form.question.errorMessages.rankLimit.min',
                          );
                        }

                        if (value > subQuestionsCount) {
                          return t(
                            'admin:components.features.application.form.question.errorMessages.rankLimit.max',
                          );
                        }

                        return true;
                      },
                    },
                  ]}
                />
              )}

              {selectedKindValue === 'ScoreQuestion' && (
                <KindValueInputField
                  title={t(
                    'admin:components.features.application.form.question.forms.question.fields.score.label',
                  )}
                  unit={t('admin:terms.unit.score')}
                  disabled={disabled}
                  fields={[
                    {
                      title: t(
                        'admin:components.features.application.form.question.forms.question.fields.score.min.label',
                      ),
                      fieldName: 'responseValidation.minScore',
                      min: 1,
                      max: 100,
                      validate: (value) => {
                        if (value <= 0) {
                          return t(
                            'admin:components.features.application.form.question.errorMessages.minScore.min',
                          );
                        }

                        if (value > 100) {
                          return t(
                            'admin:components.features.application.form.question.errorMessages.minScore.max',
                          );
                        }

                        const max = methods.getValues('responseValidation.maxScore') || 1;
                        if (value >= max) {
                          return t(
                            'admin:components.features.application.form.question.errorMessages.minScore.compareWithMaxScore',
                          );
                        }

                        return true;
                      },
                    },
                    {
                      title: t(
                        'admin:components.features.application.form.question.forms.question.fields.score.max.label',
                      ),
                      fieldName: 'responseValidation.maxScore',
                      min: 1,
                      max: 100,
                      validate: (value) => {
                        if (value <= 0) {
                          return t(
                            'admin:components.features.application.form.question.errorMessages.maxScore.min',
                          );
                        }

                        if (value > 100) {
                          return t(
                            'admin:components.features.application.form.question.errorMessages.maxScore.max',
                          );
                        }

                        const min = methods.getValues('responseValidation.minScore') || 1;
                        if (min >= value) {
                          return t(
                            'admin:components.features.application.form.question.errorMessages.maxScore.compareWithMinScore',
                          );
                        }

                        return true;
                      },
                    },
                  ]}
                />
              )}
            </div>

            <hr />

            <div className='application-form__row application-form__row--justify'>
              <FieldTitle
                title={t(
                  'admin:components.features.application.form.question.forms.question.fields.required.label',
                )}
                tooltip={t(
                  'admin:components.features.application.form.question.forms.question.fields.required.tooltip',
                )}
              />

              <Controller
                control={methods.control}
                name='isRequired'
                render={({ field }) => (
                  <Switch
                    id={field.name}
                    ref={field.ref}
                    disabled={disabled}
                    onChange={(event) => {
                      if (event.target.checked) {
                        field.onChange(true);
                      } else {
                        field.onChange(false);
                      }
                    }}
                    checked={field.value === true}
                  />
                )}
              />
            </div>
            {selectedKind?.shuffle && (
              <div className='application-form__row application-form__row--justify'>
                <FieldTitle
                  title={t(
                    'admin:components.features.application.form.question.forms.question.fields.shuffle.label',
                  )}
                  tooltip={t(
                    'admin:components.features.application.form.question.forms.question.fields.shuffle.tooltip',
                  )}
                />

                <Controller
                  control={methods.control}
                  name='shuffle'
                  render={({ field }) => (
                    <Switch
                      id={field.name}
                      ref={field.ref}
                      disabled={disabled}
                      onChange={(event) => {
                        if (event.target.checked) {
                          field.onChange(true);
                        } else {
                          field.onChange(false);
                        }
                      }}
                      checked={field.value === true}
                    />
                  )}
                />
              </div>
            )}

            <Button width='100%' size='lg' variant='primary' type='submit'>
              {t(
                'admin:components.features.application.form.question.forms.question.submitButtonText',
              )}
            </Button>
          </form>
        </FormProvider>
      </ApplicationLayout>

      <VideoLinkModal
        onClose={() => {
          setVideoLinkModal({
            isVisible: false,
            fieldName: null,
            link: null,
          });
        }}
        isVisible={videoLinkModal.isVisible}
        videoLink={videoLinkModal.link}
        onSubmit={(url) => {
          if (videoLinkModal.fieldName) {
            methods.setValue(videoLinkModal.fieldName, url);
          }

          setVideoLinkModal({
            isVisible: false,
            fieldName: null,
            link: null,
          });
        }}
      />
    </div>
  );
}

export default QuestionForm;

const KINDS: {
  type: SurveyKind;
  title: string;
  icon: string;
  shuffle: boolean;
  selections: boolean;
  subQuestions?: boolean;
}[] = [
  {
    type: 'RadioQuestion',
    icon: SelectIcon,
    title: t(
      'admin:components.features.application.form.question.forms.question.fields.kind.dropdown.radio',
    ),
    shuffle: true,
    selections: true,
  },
  {
    type: 'CheckboxQuestion',
    icon: ChoiceIcon,
    title: t(
      'admin:components.features.application.form.question.forms.question.fields.kind.dropdown.checkbox',
    ),
    shuffle: true,
    selections: true,
  },
  {
    type: 'TextQuestion',
    icon: TextIcon,
    title: t(
      'admin:components.features.application.form.question.forms.question.fields.kind.dropdown.text',
    ),
    shuffle: false,
    selections: false,
  },
  {
    type: 'RankQuestion',
    icon: RankIcon,
    title: t(
      'admin:components.features.application.form.question.forms.question.fields.kind.dropdown.rank',
    ),
    shuffle: true,
    selections: false,
    subQuestions: true,
  },
  {
    type: 'ScoreQuestion',
    icon: ScoreIcon,
    title: t(
      'admin:components.features.application.form.question.forms.question.fields.kind.dropdown.score',
    ),
    shuffle: true,
    selections: false,
    subQuestions: true,
  },
  {
    type: 'ScaleQuestion',
    icon: LineIcon,
    title: t(
      'admin:components.features.application.form.question.forms.question.fields.kind.dropdown.scale',
    ),
    shuffle: false,
    selections: false,
  },
];

const defaultValues = {
  kind: 'RadioQuestion' as SurveyKind,
  title: '',
  description: '',
  isRequired: false,
};
