import { DraftTemplate, DraftTemplateCategory } from 'types/template';

import Thumbnail1 from 'resources/images/templates/election/labor1.png';

const DRAFT_1 = {
  title: '픽미 노동조합 임원 선출 선거',
  _id: '6459b43c6a7575018771b4be',
  content: {
    name: '픽미 노동조합 임원 선출 선거',
    periods: [
      {
        startDate: '2023-05-25T16:22',
        endDate: '2023-06-30T16:22',
      },
    ],
    description:
      '<p>안녕하세요.</p><p><br></p><p>2023 픽미 노동조합 임원 선출 선거입니다.</p><p>노조 여러분들의 소중한 한표 부탁드립니다.</p><p><br></p><p>감사합니다.</p>',
    type: 'Election',
    voterType: 'voterBook',
    subscriptionType: 'enterprise',
    subElections: [
      {
        kind: 'ChoiceElection',
        title: '노동조합 서울본부 임원 선출 선거',
        shuffle: false,
        candidates: [
          {
            title: '후보1. 박상필',
            description:
              '<p>픽미 노동조합 서울본부 임원 선출 선거에 출마한 박상필입니다.</p><p>여러분들의 소중한 한표 부탁드립니다.</p><p><br></p><p><strong>공약</strong></p><ol><li>고용 안정의 새 질서를 만들겠습니다.</li><li>단체 협약 지원으로 노동 조건을 개선하겠습니다.</li><li>성과연봉제 및 퇴출제를 저지하겠습니다.</li></ol>',
            introductionVideoUrl: '',
            members: [
              {
                name: '박상필',
                fileUrl: 'https://api.ohpick.me/api/file/64478cb37bc5dd032df5758a',
              },
            ],
            tags: ['#고용안전', '#협약지원', '#성과연봉제', '#퇴출제'],
            id: '67de044e-c3e3-47a5-a351-4548817ecff3',
          },
          {
            title: '후보2. 최길연',
            description:
              '<p>픽미 노동조합 서울본부 임원 선출 선거에 출마한 최길연입니다.</p><p>여러분들의 소중한 한표 부탁드립니다.</p><p><br></p><p><strong>공약</strong></p><ol><li>근로 조건 및 복지 제도를 개선하겠습니다.</li><li>여직원 보건 휴가 의무 사용 및 여성 근무복 제도를 개선하겠습니다.</li><li>조합원 보호를 위해 악성민원 전담팀을 구성하겠습니다.</li></ol>',
            introductionVideoUrl: '',
            members: [
              {
                name: '최길연',
                fileUrl: 'https://api.ohpick.me/api/file/64478cce7bc5dd032df57591',
              },
            ],
            tags: ['#근로조건개선', '#여성근무복제도개선', '#조합원보호'],
            id: 'bb41cbaa-cea1-4c45-b6c1-e92eedbb06c7',
          },
        ],
        responseValidation: {
          allowAbstentionVote: true,
          minResponse: 1,
          maxResponse: 1,
        },
        id: '21263d05-5d8f-4fbd-afd5-6337cbe14d19',
      },
      {
        kind: 'ProsOrConsElection',
        title: '노동조합 대구본부 임원 선출 선거',
        shuffle: false,
        candidates: [
          {
            title: '후보1. 최연화',
            description:
              '<p>2023 픽미 노동조합 대구본부 임원 선출 선거에 출마한 최연화입니다.</p><p>여러분들의 소중한 한표 부탁드립니다.</p><p><br></p><p><strong>공약</strong></p><ol><li>명절근무 급여 가급을 증대하겠습니다.</li><li>줄서기 없는 노동조합을 구현하겠습니다.</li><li>4급 승진 정원을 확대하겠습니다.</li></ol>',
            introductionVideoUrl: '',
            members: [
              {
                name: '최연화',
                fileUrl: 'https://api.ohpick.me/api/file/64478dfa7bc5dd032df575cc',
              },
            ],
            tags: ['#명절급여증대', '#청렴한노동조합', '#승진정원확대'],
            id: '01c292e8-6946-4305-9751-131f7fba1496',
          },
        ],
        responseValidation: {
          allowAbstentionVote: false,
        },
        id: 'aa064008-7af3-47f1-bae0-6804466ce030',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'election',
  createdAt: '2023-05-09T02:47:24.638Z',
  __v: 0,
};

export default [
  {
    title: '노동조합 임원 선출 투표',
    description: '노동조합 임원 선출 투표를 위한 템플릿입니다.',
    thumbnail: Thumbnail1,
    draft: DRAFT_1,
    category: DraftTemplateCategory.Labor,
    kind: 'Election',
  },
] as DraftTemplate[];
