import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getIntroFormSchema } from 'functions/validator/application-v2/intro';
import { getEndingFormSchema } from 'functions/validator/application-v2/ending';
import { getSettingsFormSchema } from 'functions/validator/application-v2/settings';
import { getVoterBookFormSchema } from 'functions/validator/application-v2/voterbook';
import { getIssueElectionFormSchema } from 'functions/validator/application-v2/issue-election';

import { POLL_THEMES } from 'constants/theme-v2';
import {
  ENDING_FORM_DEFAULT_VALUES,
  INTRO_FORM_DEFAULT_VALUES,
  SETTINGS_FORM_DEFAULT_VALUES,
  VOTER_BOOK_FORM_DEFAULT_VALUES,
} from 'constants/application-v2';

import {
  IssueElectionForm as IssueElectionFormType,
  EndingForm as EndingFormType,
  IntroForm as IntroFormType,
  SettingsForm as SettingsFormType,
  CustomThemeForm,
  ThemeNames,
  VoterBookForm as VoterBookFormType,
  ThemeType,
  VoterType,
} from 'types/application-v2';
import { PollKindV2 } from 'types/poll-v2';

const introSchema = getIntroFormSchema(PollKindV2.IssueElection);
const endingSchema = getEndingFormSchema();
const settingsSchema = getSettingsFormSchema();
const voterBookSchema = getVoterBookFormSchema();
const electionFormSchema = getIssueElectionFormSchema();

function useIssueElectionForms() {
  const introForm = useForm<IntroFormType>({
    defaultValues: INTRO_FORM_DEFAULT_VALUES,
    resolver: yupResolver(introSchema),
    mode: 'onTouched',
  });

  const issueElectionForm = useForm<IssueElectionFormType>({
    defaultValues: {
      issueElections: [],
    },
    resolver: yupResolver(electionFormSchema),
    mode: 'onTouched',
  });

  const customThemeForm = useForm<CustomThemeForm>({
    defaultValues: {
      type: ThemeType.Custom,
      colors: {
        primary: POLL_THEMES[ThemeNames.White].colors.primary,
        secondary: POLL_THEMES[ThemeNames.White].colors.secondary,
      },
    },
  });

  const endingForm = useForm<EndingFormType>({
    defaultValues: ENDING_FORM_DEFAULT_VALUES,
    resolver: yupResolver(endingSchema),
    mode: 'onTouched',
  });

  const settingsForm = useForm<SettingsFormType>({
    defaultValues: SETTINGS_FORM_DEFAULT_VALUES,
    resolver: yupResolver(settingsSchema),
    mode: 'onTouched',
  });

  const voterBookForm = useForm<VoterBookFormType>({
    defaultValues: VOTER_BOOK_FORM_DEFAULT_VALUES,
    resolver: yupResolver(voterBookSchema),
    mode: 'onTouched',
  });

  const voterErrors = voterBookForm.formState.errors.voters;
  const validVoterCounts = voterErrors?.filter?.((error) => !!error)?.length || 0;
  const isValidVoterExisting = validVoterCounts > 0;

  const isValid =
    introForm.formState.isValid &&
    issueElectionForm.formState.isValid &&
    endingForm.formState.isValid &&
    settingsForm.formState.isValid &&
    (settingsForm.watch('voterType') !== VoterType.VoterBook || isValidVoterExisting);

  const getValues = () => ({
    customThemeForm: customThemeForm.getValues(),
    issueElectionForm: issueElectionForm.getValues(),
    endingForm: endingForm.getValues(),
    introForm: introForm.getValues(),
    settingsForm: settingsForm.getValues(),
    voterBookForm: {
      ...voterBookForm.getValues(),
      voters: voterBookForm
        .getValues()
        .voters.filter((_, index) => !voterErrors || !voterErrors[index]),
    },
  });

  return {
    introForm,
    issueElectionForm,
    customThemeForm,
    endingForm,
    settingsForm,
    voterBookForm,
    isValid,
    getValues,
  };
}

export default useIssueElectionForms;
