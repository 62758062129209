export default {
  title: '이메일로 로그인하기',
  fields: {
    email: {
      placeholder: '이메일 또는 아이디',
    },
    password: {
      placeholder: '비밀번호',
    },
  },
  errorMessages: {
    wrongEmail: '올바른 이메일 또는 아이디를 입력해주세요',
    wrongPassword: '비밀번호를 확인해주세요',
  },
  buttons: {
    findPassword: '비밀번호 찾기',
    signup: '회원가입',
    submit: '로그인',
  },
  labels: {
    signup: '픽미 계정이 없으신가요?',
  },
};
