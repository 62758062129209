import { SpaceResponse, SpaceCategory } from '@pickme/core';

import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';

const APIS: Apis = {
  getOrganization: { method: 'GET', url: '/api/organization/:keyword' },
  applyOrganization: { method: 'POST', url: '/api/organization/:id/apply' },
  createOrganization: { method: 'POST', url: '/api/organization' },
  getMyOrganizations: { method: 'GET', url: '/api/user/my/organizations' },
  replaceName: { method: 'PUT', url: '/api/organization/name' },
  replaceLogo: { method: 'PUT', url: '/api/organization/logo' },
  replaceAlias: { method: 'PUT', url: '/api/organization/alias' },
  checkAliasDuplicated: { method: 'GET', url: '/api/organization/check/duplicate/:alias' },
};

type SpaceResponseWithoutSubscriptionAndAdminInfo = Omit<
  SpaceResponse,
  'subscription' | 'adminInfo'
>;

export const createGetOrganizationRequest = (type: 'objectId' | 'alias', keyword: string) =>
  createHttpRequest<SpaceResponse>({
    ...APIS.getOrganization,
    params: { keyword },
    query: { type },
    skipOrganizationOidCheck: true,
  });

export const createGetOrganizationsRequest = () =>
  createHttpRequest<SpaceResponseWithoutSubscriptionAndAdminInfo[]>({
    ...APIS.getMyOrganizations,
    withoutOrganizationOid: true,
  });

export const createApplyOrganizationRequest = (id: string) =>
  createHttpRequest<SpaceResponseWithoutSubscriptionAndAdminInfo>({
    ...APIS.applyOrganization,
    params: { id },
    withoutOrganizationOid: true,
  });

export const createOrganizationRequest = (name: string, category: SpaceCategory) =>
  createHttpRequest<SpaceResponseWithoutSubscriptionAndAdminInfo>({
    ...APIS.createOrganization,
    data: { name, category },
    withoutOrganizationOid: true,
  });

export const createReplaceOrganizationNameRequest = (name: string, spaceId: string) =>
  createHttpRequest<{ message: 'ok' }>({
    ...APIS.replaceName,
    data: { name },
    headers: {
      'organization-oid': spaceId,
    },
  });

export const createReplaceOrganizationLogoRequest = (fileUrl: string) =>
  createHttpRequest<{ message: 'ok' }>({ ...APIS.replaceLogo, data: { fileUrl } });

export const createReplaceOrganizationAliasRequest = (alias: string) =>
  createHttpRequest<{ message: 'ok' }>({ ...APIS.replaceAlias, data: { alias } });

export const createCheckAliasDuplicatedRequest = (alias: string) =>
  // true => 중복
  // false => 사용가능
  createHttpRequest<{ result: boolean }>({ ...APIS.checkAliasDuplicated, params: { alias } });
