import { useRecoilState } from 'recoil';
import { Modal, ModalBody, ModalHeader } from '@pickme/ui';
import { Button, Text } from '@pickme/design-system';

import SuccessIcon from 'resources/icons/notification/success.png';
import FailIcon from 'resources/icons/notification/fail.png';

import { messageModal } from 'states/modal';

import { modalButtons } from 'styles/page.css';
import { icon } from './index.css';

const MODAL_ICON_TYPE = {
  success: SuccessIcon,
  error: FailIcon,
};

function MessageModal() {
  const [{ isVisible, title, message, type }, setMessageModal] = useRecoilState(messageModal);

  const onClose = () => {
    setMessageModal({ isVisible: false, title, message });

    setTimeout(() => {
      setMessageModal({ isVisible: false, title: '', message: '' });
    }, 500);
  };

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      {type && <img src={MODAL_ICON_TYPE[type]} alt='modal icon type' className={icon} />}

      <ModalHeader title={title} onClose={onClose} size='h4' hideCloseButton />

      <ModalBody>
        <Text size={16} fontWeight={400} color='gray-700'>
          {message}
        </Text>
      </ModalBody>

      <div className={modalButtons.container}>
        <Button className={modalButtons.submit} onClick={onClose}>
          확인
        </Button>
      </div>
    </Modal>
  );
}

export default MessageModal;
