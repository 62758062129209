import { container, item } from './index.css';

function Indicator() {
  return (
    <div className={container}>
      <div className={item} />
      <div className={item} />
      <div className={item} />
    </div>
  );
}

export default Indicator;
