import { useState } from 'react';
import { OutlineButton, PlainButton, Text } from '@pickme/ui';
import { t } from 'i18next';
import { PollGroup } from '@pickme/core';

import { useGetPopulation } from 'query-hooks/poll';

import { createDownloadVoterBook } from 'apis/file';

import UpIcon from 'resources/icons/poll-viewer/up.png';
import DownIcon from 'resources/icons/poll-viewer/down.png';
import SheetIcon from 'resources/icons/application/excel.png';
import DownloadIcon from 'resources/icons/application/download.png';
import DownPrimaryIcon from 'resources/icons/application/download-primary.png';

import './index.scss';

type Props = {
  pollName: string;
  pollId: string;
  pollGroup?: PollGroup;
  isDownloadButtonVisible: boolean;
};

const downloadVoterBook = async (pollId: string, pollName: string, type: 'all' | 'votedVoters') => {
  try {
    await createDownloadVoterBook(pollId, pollName, type);
  } catch {
    // TODO: Add error handle.
    // no-op
  }
};

function VoterSection({ pollName, pollId, pollGroup, isDownloadButtonVisible }: Props) {
  const [toggle, setToggle] = useState(false);

  const { data: populationData } = useGetPopulation(pollId, true);

  const categorizedPopulations: { category: string; population: number }[] = [];
  let nonCategoryVoterCount = 0;

  populationData?.populationPerCategory.forEach(({ category, population }) => {
    if (category) {
      categorizedPopulations.push({ category, population });
    } else {
      nonCategoryVoterCount += population;
    }
  });

  const isVoterBookCategoryVisible = isDownloadButtonVisible && categorizedPopulations.length > 0;

  return (
    <div className='poll-detail__voter'>
      <div className='poll-detail__voter__caption'>
        <div className='poll-detail__voter__caption__group'>
          <Text fontWeight={400} type='b3' color='gray-400'>
            {t('admin:components.features.poll.pollDetail.voterCard.total')}
          </Text>
          <Text fontWeight={500} type='b3'>
            {populationData?.totalPopulation}
          </Text>
        </div>
        <div className='poll-detail__voter__caption__group'>
          <Text fontWeight={400} type='b3' color='gray-400'>
            {t('admin:components.features.poll.pollDetail.voterCard.categorized')}
          </Text>
          <Text fontWeight={500} type='b3'>
            {categorizedPopulations.length}
          </Text>
        </div>
        <div className='poll-detail__voter__caption__group'>
          <Text fontWeight={400} type='b3' color='gray-400'>
            {t('admin:components.features.poll.pollDetail.voterCard.nonCategorized')}
          </Text>
          <Text fontWeight={500} type='b3'>
            {nonCategoryVoterCount}
          </Text>
        </div>
      </div>

      {isVoterBookCategoryVisible && (
        <div className='poll-detail__voter__table'>
          <div className='poll-detail__voter__table__header'>
            <div className='poll-detail__voter__table__header__item'>
              <Text type='b3' fontWeight={400} color='gray-400'>
                {t('admin:components.features.poll.pollDetail.voterCard.voterBook.category')}
              </Text>
            </div>
            <div className='poll-detail__voter__table__header__item'>
              <Text type='b3' fontWeight={400} color='gray-400'>
                {t(
                  'admin:components.features.poll.pollDetail.voterCard.voterBook.voterPerCategory',
                )}
              </Text>

              <PlainButton
                className='poll-detail__voter__table__header__toggle'
                onClick={() => setToggle(!toggle)}
              >
                <img
                  className='poll-detail__voter__table__header__toggle__icon'
                  src={toggle ? UpIcon : DownIcon}
                  alt='more'
                />
              </PlainButton>
            </div>
          </div>

          {toggle &&
            categorizedPopulations.map(({ category, population }) => (
              <div className='poll-detail__voter__table__row' key={`${category}-${population}`}>
                <div className='poll-detail__voter__table__row__item'>
                  <Text type='b3' fontWeight={300}>
                    {category || ''}
                  </Text>
                </div>
                <div className='poll-detail__voter__table__row__item'>
                  <Text type='b3' fontWeight={300}>
                    {population}
                    {t('admin:terms.unit.people')}
                  </Text>
                </div>
              </div>
            ))}
        </div>
      )}

      {isDownloadButtonVisible && (
        <div className='poll-detail__voter__download'>
          <div className='poll-detail__voter__download__file'>
            <img className='poll-detail__voter__download__icon' src={SheetIcon} alt='sheet' />

            <Text type='b3' fontWeight={400}>
              {pollName} {t('admin:components.features.poll.pollDetail.voterCard.download.list')}
            </Text>
          </div>

          <div className='poll-detail__voter__download__buttons'>
            <OutlineButton
              variant='primary'
              size='sm'
              onClick={() => downloadVoterBook(pollId, pollName, 'votedVoters')}
            >
              <img
                className='poll-detail__voter__download__button__icon'
                src={DownPrimaryIcon}
                alt='download'
              />
              {pollGroup === 'counted'
                ? t(
                    'admin:components.features.poll.pollDetail.voterCard.download.buttons.voter.final',
                  )
                : t(
                    'admin:components.features.poll.pollDetail.voterCard.download.buttons.voter.realtime',
                  )}
            </OutlineButton>

            <OutlineButton
              variant='success'
              size='sm'
              onClick={() => {
                downloadVoterBook(pollId, pollName, 'all');
              }}
            >
              <img
                className='poll-detail__voter__download__button__icon'
                src={DownloadIcon}
                alt='download'
              />
              {t('admin:components.features.poll.pollDetail.voterCard.download.buttons.target')}
            </OutlineButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default VoterSection;
