export default {
  title: '대상자 설정',
  callout: {
    free: ['무료 플랜', '으로 투표를 만드는 경우 투표 참여 인원이 ', '30명', '으로 제한됩니다'],
    freeCapacity: [
      '이용 중인 유료 플랜으로 투표를 만드는 경우 ',
      '31명',
      '으로 인원으로 제한됩니다',
    ],
    chargedCapacity: [
      '이용 중인 유료 플랜으로 투표를 만드는 경우 ',
      '31명 이상 {{max}}명 이하',
      '의 인원으로 제한됩니다',
    ],
    public: ['설문조사 공개 여부를 ', '공개로 설정한 경우', ' 대상자를 등록할 수 없습니다.'],
  },
  instantVoter: {
    title: '대상자 직접 등록',
    description: '엑셀 파일을 업로드하여 설문에 참여할 대상자를 직접 설정합니다.',
    button: '엑셀 서식 다운로드',
  },
  search: {
    keyword: {
      placeholder: '검색어를 입력해주세요',
    },
    button: '검색',
    result: {
      blank: ['검색 결과가 없어요', '다른 검색어를 입력해주세요'],
      empty: ['등록된 대상자가 없습니다', '투표 대상자를 등록해주세요'],
    },
  },
  labels: {
    voters: {
      total: '전체 {{number}}',
      success: '성공 {{number}}',
      failure: '실패 {{number}}',
      category: '분류 {{number}}',
    },
  },
  table: {
    header: {
      name: '이름',
      id: '고유번호',
      phone: '전화번호',
      email: '이메일',
      category: '분류',
      share: '관리',
    },
    body: {
      buttons: {
        edit: '수정하기',
        remove: '삭제하기',
      },
    },
  },
  errorMessages: {
    required: '최소 1명 이상 등록해야 다음 단계로 갈 수 있어요',
    plan: {
      capacity: {
        free: '무료 플랜은 최대 30명까지 등록할 수 있어요',
        charged: {
          min: '최소 31명 이상의 대상자를 등록해주세요',
          max: `최대 {{number}}명 까지 등록할 수 있어요`,
        },
      },
    },
    incorrectVoter: '실패 값이 0일 때 다음 단계로 갈 수 있어요',
    emailOrPhoneNumber: '본인인증을 위한 전화번호 또는 이메일주소를 작성해주세요',
    template: '업로드하려는 엑셀 파일이 최신 양식과 다릅니다. 양식을 다운로드 받아서 이용해주세요',
    addVoters: '{{number}}건이 실패했습니다.',
  },
  buttons: {
    download: '목록 다운로드',
    add: '대상자 추가하기',
    addSingleVoter: '한명씩 추가하기',
    addVoters: '여러명 일괄 등록하기',
    submit: '저장하고 다음',
  },
  modals: {
    category: {
      title: '대상자 분류',
      labels: {
        categorizedVoters: '분류된 대상자 수',
        uncategorizedVoters: '미분류 대상자 수',
        blank: '분류 대상자가 없습니다.',
      },
      description: {
        uncategorizedVoters: '미분류 대상자는 분류별 투표율에 반영되지 않습니다',
      },
      table: {
        header: {
          category: '분류',
          count: '분류별 대상자 수',
        },
      },
      actions: {
        close: '확인',
      },
    },
    incorrect: {
      title: '등록 실패 항목',
      table: {
        header: {
          name: '이름',
          userId: '고유번호',
          phone: '전화번호',
          email: '이메일',
          category: '분류',
          reason: '실패사유',
        },
      },
      buttons: {
        cancel: '취소',
        submit: '저장하기',
      },
    },
    addSingleVoter: {
      title: {
        edit: '수정하기',
        register: '한명씩 등록하기',
      },
      description:
        '투표인 작성시 이름, 본인 인증 수단 (전화번호 또는 이메일)은 필수 입력사항입니다.',
      fields: {
        name: {
          label: '이름',
          placeholder: '이름을 작성해주세요',
        },
        userId: {
          label: '고유번호',
          tooltip: '대상자를 식별할 수 있는 숫자 조합이에요',
          placeholder: '고유번호를 작성해주세요',
        },
        phoneNumber: {
          label: '전화번호',
          placeholder: '전화번호를 작성해주세요',
        },
        email: {
          label: '이메일',
          placeholder: '이메일을 작성해주세요',
        },
        category: {
          label: '분류',
          tooltip: '투표인의 소속 하위 분류를 입력하여 투표인을 나눌 수 있어요. (학과, 부서 등)',
        },
      },
      messages: {
        success: '성공적으로 업데이트 되었습니다.',
      },
      actions: {
        cancel: '취소',
        submit: {
          edit: '저장하기',
          register: '등록하기',
        },
      },
    },
    addVoters: {
      title: '여러명 일괄 등록하기',
      labels: {
        total: '전체',
        success: '성공',
        failure: '실패',
      },
      items: {
        template: {
          title: '양식 다운로드',
          description:
            '양식 다운로드 후 반드시 해당 파일에 투표인 정보 작성 후 업로드해주세요. 투표인 명부 작성시 이름, 본인 인증 수단 (전화번호 또는 이메일)은 필수 입력사항입니다.',
          button: '엑셀 템플릿 다운로드',
        },
        upload: {
          title: '파일 업로드',
          description: '엑셀 파일을 업로드해주세요',
        },
      },
      messages: {
        success: '템플릿 다운로드를 완료했어요',
      },
      actions: {
        close: '취소',
        submit: '등록하기',
      },
    },
    addVotersResult: {
      title: '여러명 일괄 등록 결과',
      labels: {
        total: '전체',
        success: '성공',
        failure: '실패',
      },
      description: '실패 건수는 실패 사유 확인 후 수정하여 저장해주세요.',
      actions: {
        close: '확인',
      },
      messages: {
        success: '성공적으로 업데이트 되었습니다.',
      },
    },
    remove: {
      title: '"{{name}}" 대상자를 삭제하시겠어요?',
      description: '삭제하시면 대상자의 데이터를 다시 복구할 수 없어요',
      actions: {
        cancel: '취소',
        submit: '삭제하기',
      },
    },
  },
};
