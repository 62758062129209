import moment from 'moment-timezone';
import { PollGroup } from '@pickme/core';

import { useQueryString } from 'hooks/useQueryString';

import { POLL_GROUPS } from 'constants/poll';

export const usePollQueryString = () => {
  const {
    name,
    page,
    start: startInQueryString,
    end: endInQueryString,
    groups: splitGroups,
  } = useQueryString({
    name: '',
    page: 1,
    start: '',
    end: '',
    groups: '',
  });

  const start =
    startInQueryString && moment(startInQueryString).isValid() ? startInQueryString : '';
  const end = endInQueryString && moment(endInQueryString).isValid() ? endInQueryString : '';
  const groups = (splitGroups
    ?.split(',')
    ?.slice()
    ?.filter((value) => !!POLL_GROUPS.find((group) => value === group.value)) || []) as PollGroup[];

  return { name, page, start, end, groups };
};
