import { Dropdown } from '@pickme/design-system';

import { POLL_SEARCH_GROUP, POLL_SEARCH_GROUP_SELECT_ITEMS } from 'constants/poll';

import { PollSearchGroup, PollStatistics } from 'types/poll';

import { circle, selectedItemStyle } from './index.css';

type Props = {
  selected: PollSearchGroup;
  onSelect: (value: PollSearchGroup) => void;
  pollStatistics?: PollStatistics;
  disableOpen?: boolean;
  triggerClassName?: string;
  onTriggerClick?: () => void;
};

function PollGroupSelect({
  selected,
  onSelect,
  pollStatistics,
  disableOpen,
  triggerClassName,
  onTriggerClick,
}: Props) {
  const selectedItem = POLL_SEARCH_GROUP_SELECT_ITEMS.find((item) => item.value === selected);

  return (
    <Dropdown>
      <Dropdown.Trigger
        arrowColor='blue-gray-700'
        className={triggerClassName}
        disableOpen={disableOpen}
        onClick={onTriggerClick}
      >
        <b>
          {selectedItem?.label} {selectedItem && pollStatistics?.[selectedItem.value]}
        </b>
      </Dropdown.Trigger>

      <Dropdown.Menu>
        {POLL_SEARCH_GROUP_SELECT_ITEMS.map((item) => {
          const circleColor = POLL_SEARCH_GROUP[item.value].color;

          return (
            <Dropdown.Item
              key={item.value}
              onClick={() => onSelect(item.value)}
              className={selectedItemStyle({ selected: item.value === selected })}
            >
              {circleColor && <div className={circle} style={{ backgroundColor: circleColor }} />}
              {item.label} {pollStatistics?.[item.value]}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default PollGroupSelect;
