import { Dropdown, PlainButton } from '@pickme/ui';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { t } from 'i18next';

import SubPollCardContents from 'components/features/poll-card-contents/SubPoll';
import MoreIcon from 'resources/icons/application/more.png';
import RemoveIcon from 'resources/icons/application/remove.png';
import DraggableIcon from 'resources/icons/application/draggable.png';

import './index.scss';

type Props = {
  id: string;
  disabled?: boolean;
  onClick: () => void;
  onRemove: () => void;
  kindBadge: { variant?: string; text: string };
  title: string;
  badges: { variant?: string; text: string }[];
  avatars?: string[];
};

function SubPollCard({
  id,
  disabled,
  onClick,
  onRemove,
  kindBadge,
  title,
  badges,
  avatars,
}: Props) {
  const sortable = useSortable({ id });
  const { attributes, listeners, setNodeRef, transition, transform } = sortable;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <PlainButton
      onClick={() => onClick()}
      className='application-form-sub-poll-card'
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <PlainButton
        type='button'
        className='application-form-sub-poll-card__draggable-button'
        {...listeners}
      >
        <img
          className='application-form-sub-poll-card__draggable-icon'
          src={DraggableIcon}
          alt='more'
        />
      </PlainButton>

      <SubPollCardContents kindBadge={kindBadge} title={title} badges={badges} avatars={avatars} />

      {!disabled && <SubPollCardDropdown disabled={disabled} onRemove={onRemove} />}
    </PlainButton>
  );
}

export default SubPollCard;

function SubPollCardDropdown({ disabled, onRemove }: { disabled?: boolean; onRemove: () => void }) {
  return (
    <div className='application-form-sub-poll-card__dropdown'>
      <Dropdown
        directionBase='bottom'
        direction='down'
        button={
          <img
            className='application-form-sub-poll-card__dropdown-icon'
            src={MoreIcon}
            alt='more'
          />
        }
        items={[
          {
            text: t('admin:components.features.application.form.subElection.buttons.remove'),
            type: 'button',
            icon: RemoveIcon,
            color: 'red',
            disabled,
            onClick: () => onRemove(),
          },
        ]}
      />
    </div>
  );
}
