import { useRecoilValue } from 'recoil';

import SpaceSelector from 'components/common/layout/Main/Sidebar/space/Selector';
import Menu from 'components/common/layout/Main/Sidebar/Menu';

import { sidebarState } from 'states/layout';

import { MAIN_NAV_MENU, SYSTEM_NAV_MENU } from 'constants/nav';

import { body, container } from './index.css';

function Sidebar() {
  const { isVisible } = useRecoilValue(sidebarState);

  return (
    <div
      className={`${container({
        isVisible,
      })}`}
    >
      <SpaceSelector />

      <nav className={body}>
        <Menu items={MAIN_NAV_MENU} />
        <Menu items={SYSTEM_NAV_MENU} />
      </nav>
    </div>
  );
}

export default Sidebar;
