import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox } from '@pickme/ui';
import { PlainButton, Text } from '@pickme/design-system';

import type { TermKind } from '@pickme/ui';

import TermModal from 'components/features/auth/modal/TermModal';

import ArrowIcon from 'resources/icons/auth/arrow.svg';

import { agreeAll, agreeRequiredText, checkbox, container, terms } from './index.css';

type Props = {
  agreeTerms: boolean;
  setAgreeTerms: (value: boolean) => void;
  agreePrivacy: boolean;
  setAgreePrivacy: (value: boolean) => void;
};

function SignUpTerms({ agreeTerms, setAgreeTerms, agreePrivacy, setAgreePrivacy }: Props) {
  const methods = useFormContext();

  const [termModal, setTermModal] = useState<{ isVisible: boolean; kind: TermKind }>({
    isVisible: false,
    kind: 'service',
  });

  const [toggle, setToggle] = useState(true);

  return (
    <>
      <div className={container}>
        <div className={agreeAll.container}>
          <Checkbox
            variant='primary'
            checked={agreePrivacy && agreeTerms && methods.watch('mailing')}
            onChange={(event) => {
              if (event.target.checked) {
                setAgreeTerms(true);
                setAgreePrivacy(true);
                methods.setValue('mailing', true);
              } else {
                setAgreeTerms(false);
                setAgreePrivacy(false);
                methods.setValue('mailing', false);
              }
            }}
          >
            <b>모두 동의합니다.</b>
          </Checkbox>

          <PlainButton onClick={() => setToggle(!toggle)}>
            <img className={agreeAll.arrow({ isOpened: toggle })} src={ArrowIcon} alt='arrow' />
          </PlainButton>
        </div>

        <div className={terms({ isOpened: toggle })}>
          <Checkbox
            className={checkbox}
            variant='primary'
            checked={agreeTerms}
            onChange={(event) => {
              if (event.target.checked) {
                setAgreeTerms(true);
              } else {
                setAgreeTerms(false);
              }
            }}
          >
            <PlainButton onClick={() => setTermModal({ isVisible: true, kind: 'service' })}>
              <b>이용약관</b>
            </PlainButton>
            에 동의합니다
            <Text className={agreeRequiredText} size={12}>
              (필수)
            </Text>
          </Checkbox>

          <Checkbox
            className={checkbox}
            variant='primary'
            checked={agreePrivacy}
            onChange={(event) => {
              if (event.target.checked) {
                setAgreePrivacy(true);
              } else {
                setAgreePrivacy(false);
              }
            }}
          >
            <PlainButton onClick={() => setTermModal({ isVisible: true, kind: 'privacy' })}>
              <b>개인정보 처리 방침</b>
            </PlainButton>
            에 동의합니다
            <Text className={agreeRequiredText} size={12}>
              (필수)
            </Text>
          </Checkbox>

          <Checkbox className={checkbox} variant='primary' {...methods.register('mailing')}>
            <PlainButton onClick={() => setTermModal({ isVisible: true, kind: 'marketing' })}>
              <b>마케팅 메시지 수신</b>
            </PlainButton>
            에 동의합니다
            <Text className={agreeRequiredText} size={12}>
              (선택)
            </Text>
          </Checkbox>
        </div>
      </div>

      <TermModal
        isVisible={termModal.isVisible}
        kind={termModal.kind}
        onClose={() => {
          setTermModal({ isVisible: false, kind: termModal.kind });
        }}
      />
    </>
  );
}

export default SignUpTerms;
