import moment from 'moment-timezone';
import { LineGraph, Text } from '@pickme/ui';
import { t } from 'i18next';
import { PollGroup } from '@pickme/core';

import { setColors } from 'functions/poll-progress';
import { parseHourlyRates, makeLineChartLabels } from 'functions/poll-rate';

type Props = {
  group?: PollGroup;
  rateByHour?: { date: string; count: number; rate: number }[];
  endDate?: string;
  openThreshold?: number;
  noAnimation?: boolean;
};

function ProgressGraphSection({ group, rateByHour, endDate, openThreshold, noAnimation }: Props) {
  const { mainColor, lightColor } = setColors(group);
  const hourlyRates = parseHourlyRates(rateByHour || [], endDate || '');
  const labels = makeLineChartLabels(hourlyRates);

  return (
    <div className='poll-progress__section'>
      <div className='poll-progress__section__title'>
        <Text type='b1' fontWeight={600}>
          {t('admin:components.features.poll.progress.progressGraphSection.title')}
        </Text>
      </div>

      <div className='poll-progress__graph-container'>
        <div className='poll-progress__time-series-graph'>
          <LineGraph
            XLabels={labels}
            horizontalItem={{
              label: t('admin:components.features.poll.progress.progressGraphSection.label'),
              value: openThreshold ?? 0,
            }}
            items={
              hourlyRates?.map(({ date, count, rate }) => ({
                dataX: moment(date)
                  .tz('Asia/Seoul')
                  .format(t('admin:components.features.poll.progress.progressGraphSection.format')),
                dataY: Number(((rate ?? 0) * 100).toFixed(2)),
                tooltipItems: [
                  {
                    label: t('admin:components.features.poll.progress.progressGraphSection.rate'),
                    value: `${((rate ?? 0) * 100).toFixed(2)}%`,
                  },
                  {
                    label: t('admin:components.features.poll.progress.progressGraphSection.voter'),
                    value: `${count}${t('admin:terms.unit.people')}`,
                  },
                ],
              })) || []
            }
            lightColor={lightColor}
            mainColor={mainColor}
            animation={!noAnimation}
          />
        </div>
      </div>
    </div>
  );
}

export default ProgressGraphSection;
