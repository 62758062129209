import { Text } from '@pickme/ui';
import { t } from 'i18next';

import NameIcon from 'resources/icons/auth/name.png';
import IdIcon from 'resources/icons/application/id.png';
import PhoneIcon from 'resources/icons/application/phone.png';
import EmailIcon from 'resources/icons/auth/email.png';
import CategoryIcon from 'resources/icons/application/category.png';
import WarningIcon from 'resources/icons/table/warning.png';

const tableHeaders = [
  { title: '', name: 'number' },
  {
    title: t('admin:components.features.application.form.voter.modals.incorrect.table.header.name'),
    icon: NameIcon,
    name: 'name',
  },
  {
    title: t(
      'admin:components.features.application.form.voter.modals.incorrect.table.header.userId',
    ),
    icon: IdIcon,
    name: 'userId',
  },
  {
    title: t(
      'admin:components.features.application.form.voter.modals.incorrect.table.header.phone',
    ),
    icon: PhoneIcon,
    name: 'phone',
  },
  {
    title: t(
      'admin:components.features.application.form.voter.modals.incorrect.table.header.email',
    ),
    icon: EmailIcon,
    name: 'email',
  },
  {
    title: t(
      'admin:components.features.application.form.voter.modals.incorrect.table.header.category',
    ),
    icon: CategoryIcon,
    name: 'category',
  },
  {
    title: t(
      'admin:components.features.application.form.voter.modals.incorrect.table.header.reason',
    ),
    icon: WarningIcon,
    name: 'reason',
  },
  {
    title: '',
    name: 'delete',
  },
];

function Header() {
  return (
    <div className='incorrect-voters-modal__voters__header'>
      {tableHeaders.map((header) => (
        <div className={`incorrect-voters-modal__voters__header__item ${header.name}`}>
          {header.icon && (
            <img
              src={header.icon}
              alt='name'
              className='incorrect-voters-modal__voters__header__icon'
            />
          )}

          {header.title && (
            <Text type='b3' fontWeight={400} color='gray-400'>
              {header.title}
            </Text>
          )}
        </div>
      ))}
    </div>
  );
}

export default Header;
