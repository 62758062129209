import { ManagerRoleTitleV2 } from '@pickme/core';

export enum CollaboratorRole {
  Privileged = 'privileged',
  Editor = 'editor',
  Viewer = 'viewer',
}

export type SelectableCollaboratorRole = Exclude<CollaboratorRole, CollaboratorRole.Privileged>;

export enum LevelType {
  OrganAdmin = 'OrganAdmin',
  SubAdmin = 'SubAdmin',
}

export type Collaborator = {
  id: string;
  deleted: boolean;
  level: LevelType;
  name: string;
  email: string | null;
  role: ManagerRoleTitleV2;
  pollRole: CollaboratorRole;
  isAuthor: boolean;
};
