import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';
import { Manager, ManagerQuery } from 'types/manager';

const APIS: Apis = {
  examineManager: { method: 'PUT', url: '/api/admin/examine/:id' },
  getManager: { method: 'GET', url: '/api/admin/:id' },
  getManagers: { method: 'GET', url: '/api/admin' },
  removeManagerFromOrganization: { method: 'POST', url: '/api/admin/expel/:id' },
  setActivation: { method: 'PUT', url: '/api/admin/:id/activation' },
  getManagersByStatus: { method: 'GET', url: '/api/admin?status=approved' },
};

export const createGetManagerRequest = (id: string) =>
  createHttpRequest<Manager>({
    ...APIS.getManager,
    params: { id },
  });

export const createGetManagersRequest = (query: ManagerQuery) =>
  createHttpRequest<{
    admins: Manager[];
    totalCount: number;
    endPage: number;
  }>({
    ...APIS.getManagers,
    query,
  });

export const createExamineManagerRequest = (id: string, approval: boolean) =>
  createHttpRequest<{
    message: 'ok';
  }>({
    ...APIS.examineManager,
    params: { id },
    data: { approval },
  });

export const createRemoveManagerFromOrganizationRequest = (id: string) =>
  createHttpRequest<{
    message: 'ok';
  }>({
    ...APIS.removeManagerFromOrganization,
    params: { id },
  });

export const createSetActivationRequest = (id: string, activation: boolean) =>
  createHttpRequest<{
    message: 'ok';
  }>({
    ...APIS.setActivation,
    params: { id },
    data: { activate: activation },
  });

export const createActiveManagersCountRequest = async () => {
  const { data } = await createHttpRequest<{
    totalCount: number;
  }>({
    ...APIS.getManagersByStatus,
  });

  return data.totalCount || 0;
};
