import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Navbar from 'components/common/layout/Main/Navbar';
import Sidebar from 'components/common/layout/Main/Sidebar';

import { sidebarState } from 'states/layout';

import { container, main } from './index.css';

function MainLayoutV2Legacy() {
  const { isVisible } = useRecoilValue(sidebarState);

  return (
    <>
      <Navbar />

      <div className={container}>
        <Sidebar />

        <main className={main({ leftPadded: isVisible })}>
          <Outlet />
        </main>
      </div>
    </>
  );
}

export default MainLayoutV2Legacy;
