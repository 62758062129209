export default {
  useReplaceUserName: {
    onSuccess: {
      toast: '성공적으로 업데이트되었습니다',
    },
  },

  useReplaceUserEmail: {
    onSuccess: {
      toast: {
        replace: '성공적으로 이메일 주소가 변경되었습니다',
        register: '이메일이 등록되었습니다.',
      },
    },
  },

  useReplaceUserPhoneNumber: {
    onSuccess: {
      toast: {
        replace: '성공적으로 전화번호가 변경되었습니다',
        verify: '성공적으로 전화번호가 인증되었습니다',
      },
    },
  },

  useReplaceUserPassword: {
    onSuccess: {
      toast: '성공적으로 비밀번호가 변경되었습니다',
    },
    onError: {
      toast: '비밀번호를 확인해주세요',
    },
  },

  useRemoveUser: {
    onError: {
      modal: {
        title: '탈퇴할 수 없어요.',
      },
    },
  },
};
