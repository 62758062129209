export default {
  title: '투표 만들기',
  description: '여러개의 투표를 추가할 수 있어요',
  buttons: {
    add: '+ 투표 추가하기',
    addCandidate: '+ 후보 추가',
    submit: '저장하고 다음',
    remove: '삭제하기',
  },
  errorMessages: {
    required: '투표를 1개 이상 등록해야 다음 단계로 갈 수 있어요',
    candidates: {
      required: '후보를 1명 이상 등록해야 다음 단계로 갈 수 있어요',
    },
    response: {
      min: '최소 응답수는 0보다 큰 수를 입력해야합니다.',
      max: '최대 응답수는 최소 응답수보다 같거나, 큰 수를 입력해야합니다.',
    },
    rank: {
      min: '최소 순위는 0보다 큰 수를 입력해야합니다.',
      max: '후보 수 보다 많은 최소 순위를 선택할 수 없습니다.',
    },
    minScore: {
      min: '최소 점수는 0보다 큰 수를 입력해야합니다.',
      max: '최소 점수는 100보다 작은 수를 입력해야합니다.',
      compareWithMaxScore: '최소 점수는 최대 점수보다 작은 수를 입력해야합니다.',
    },
    maxScore: {
      min: '최대 점수는 0보다 큰 수를 입력해야합니다.',
      max: '최대 점수는 100보다 작은 수를 입력해야합니다.',
      compareWithMinScore: '최대 점수는 최소 점수보다 큰 수를 입력해야합니다.',
    },
  },
  form: {
    subElection: {
      title: '투표 만들기',
      fields: {
        name: {
          placeholder: '투표 제목을 작성해주세요.',
        },
        candidates: {
          label: '후보 등록',
          description: '사용자에게 보여지는 후보의 정보를 작성해주세요',
        },
        rank: {
          label: '순위',
        },
        maxResponse: {
          label: '선출 인원 수',
        },
        score: {
          label: '점수',
        },
        allowAbstentionVote: {
          label: '기권표 포함',
          tooltip: '투표의 기권표 포함 유무를 선택해주세요',
        },
        shuffle: {
          label: '후보 랜덤 노출',
          tooltip: '후보 랜덤 노출 유무를 선택해주세요',
        },
      },
      labels: {
        min: '최소',
        max: '최대',
        kind: {
          choice: '선택투표',
          prosOrCons: '찬반투표',
          rank: '순위투표',
          score: '점수투표',
        },
      },
      submitButtonText: '저장하기',
    },
    candidate: {
      title: '후보 추가',
      fields: {
        title: {
          placeholder: '이름을 작성해주세요',
        },
        member: {
          name: {
            placeholder: '이름 작성',
          },
          tag: {
            label: '후보 태그',
            placeholder: '후보 태그',
            tooltip: '후보를 설명하는 태그를 작성해주세요',
          },
          description: {
            label: '후보 추가 정보',
            tooltip: '후보의 공약이나 출마의 변 등을 작성해주세요',
          },
          video: {
            label: '소개 영상',
            button: '링크 확인',
          },
        },
      },
      buttons: {
        add: '+ 추가하기',
        upload: '등록하기',
        remove: '삭제하기',
        edit: '수정하기',
        refresh: '초기화하기',
      },
      labels: {
        primaryMember: '대표',
      },
    },
  },
  modal: {
    kindSelect: {
      title: '새로운 투표를 만드시나요?',
      items: {
        choice: {
          title: '선택 투표',
          message: '관리자가 설정한 후보 수만큼 선택할 수 있는 투표 유형이에요',
        },
        prosOrCons: {
          title: '찬반 투표',
          message: '각 후보자를 찬성 또는 반대할 수 있는 투표 유형이에요',
        },
        rank: {
          title: '순위 투표',
          message: '후보자에게 순위를 부여할 수 있는 투표 유형이에요',
        },
        score: {
          title: '점수 투표',
          message: '후보자에게 점수를 부여할 수 있는 유형이에요',
        },
      },
    },
    candidate: {
      actions: {
        cancel: '취소',
        submit: '등록하기',
      },
    },
  },
  cards: {
    candidate: {
      buttons: {
        remove: '삭제하기',
      },
    },
  },
};
