import point from './point';
import paymentHistory from './history';
import subscription from './subscription';
import callback from './callback';

export default {
  point,
  history: paymentHistory,
  subscription,
  callback,
};
