import { useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  OutlineButton,
  PrimaryBadge,
  Text,
} from '@pickme/ui';
import moment from 'moment-timezone';
import { useSetRecoilState } from 'recoil';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';
import ConfirmModal from 'components/features/manager/ConfirmModal';

import {
  useExamineManager,
  useGetManager,
  useRemoveManagerFromOrganization,
  useSetManagerActivation,
} from 'query-hooks/manager';

import { useGetFeatures } from 'hooks/useGetFeatures';

import { toast } from 'states/toast';

import './index.scss';

type Props = {
  isVisible: boolean;
  managerId?: string;
  onClose: () => void;
};

function ManagerModal({ isVisible, managerId, onClose }: Props) {
  const setToast = useSetRecoilState(toast);
  const [confirmModal, setConfirmModal] = useState<{
    isVisible: boolean;
    type: 'removeFormGroup' | 'reject';
  }>({
    isVisible: false,
    type: 'reject',
  });

  const { managerLimit } = useGetFeatures();
  const { data: manager } = useGetManager(managerId || '');
  const { mutate: mutateExamineManager, isLoading: isExamineLoading } = useExamineManager();
  const {
    mutate: mutateRemoveManagerFromOrganization,
    isLoading: isRemoveManagerFromOrganizationLoading,
  } = useRemoveManagerFromOrganization();
  const { mutate: mutateSetManagerActivation, isLoading: isSetManagerActivationLoading } =
    useSetManagerActivation();

  const textRows = [
    {
      title: t('admin:components.features.manager.modal.detail.tables.header.name'),
      content: manager?.name,
    },
    {
      title: t('admin:components.features.manager.modal.detail.tables.header.phoneNumber'),
      content: manager?.phoneNumber,
    },
    {
      title: t('admin:components.features.manager.modal.detail.tables.header.email'),
      content: manager?.email,
    },
    {
      title: t('admin:components.features.manager.modal.detail.tables.header.approvedAt'),
      content: manager?.approvedAt
        ? moment(manager?.approvedAt)
            .tz('Asia/Seoul')
            .format('YY/MM/DD HH:mm')
        : '',
    },
  ];

  const examineMutateWithCallback = (approval: boolean) => {
    if (managerId) {
      mutateExamineManager(
        { id: managerId, approval },
        {
          onSuccess: (_, variables) => {
            if (!variables.approval) {
              onClose();
              setConfirmModal({
                isVisible: false,
                type: 'reject',
              });
            }
          },
        },
      );
    }
  };

  const mutateRemoveManagerFromOrganizationWithCallback = () => {
    if (managerId) {
      mutateRemoveManagerFromOrganization(
        { id: managerId },
        {
          onSuccess: () => {
            onClose();
            setConfirmModal({
              isVisible: false,
              type: 'reject',
            });
          },
        },
      );
    }
  };

  return (
    <>
      <Modal className='manager-modal' size='md' isVisible={isVisible} onClose={onClose}>
        <ModalHeader
          title={t('admin:components.features.manager.modal.detail.title')}
          onClose={onClose}
        />

        <ModalBody>
          <div className='manager-modal__fields'>
            {textRows.map((row) => (
              <div className='manager-modal__field' key={row.title}>
                <div className='manager-modal__field__title'>
                  <Text type='b3' fontWeight={400}>
                    {row.title}
                  </Text>
                </div>
                <div className='manager-modal__field__content'>
                  <Text type='b3' fontWeight={400}>
                    {row?.content || ''}
                  </Text>
                </div>
              </div>
            ))}

            <div className='manager-modal__field'>
              <div className='manager-modal__field__title'>
                <Text type='b3' fontWeight={400}>
                  {t('admin:components.features.manager.modal.detail.tables.header.join')}
                </Text>
              </div>

              {manager?.status && (
                <PrimaryBadge variant={manager?.status === 'pending' ? 'warning' : 'primary'}>
                  {manager?.status === 'pending'
                    ? t('admin:components.features.manager.modal.detail.tables.body.status.pending')
                    : t('admin:components.features.manager.modal.detail.tables.body.status.joined')}
                </PrimaryBadge>
              )}
            </div>

            <div className='manager-modal__field'>
              <div className='manager-modal__field__title'>
                <Text type='b3' fontWeight={400}>
                  {t('admin:components.features.manager.modal.detail.tables.header.status')}
                </Text>
              </div>

              {manager?.status && (
                <PrimaryBadge variant={manager?.status === 'approved' ? 'success' : 'gray'}>
                  {manager?.status === 'approved'
                    ? t(
                        'admin:components.features.manager.modal.detail.tables.body.status.approved',
                      )
                    : t(
                        'admin:components.features.manager.modal.detail.tables.body.status.disApproved',
                      )}
                </PrimaryBadge>
              )}
            </div>
          </div>
        </ModalBody>

        <div className='manager-modal__actions'>
          {manager?.status === 'inactive' && (
            <>
              <Button
                size='md'
                variant='error'
                disabled={isRemoveManagerFromOrganizationLoading}
                onClick={() =>
                  setConfirmModal({
                    isVisible: true,
                    type: 'removeFormGroup',
                  })
                }
              >
                {isRemoveManagerFromOrganizationLoading ? (
                  <Spinner />
                ) : (
                  t('admin:components.features.manager.modal.detail.button.remove')
                )}
              </Button>
              <OutlineButton
                size='md'
                disabled={isSetManagerActivationLoading}
                onClick={() => {
                  if (managerLimit === 0) {
                    setToast({
                      isVisible: true,
                      type: 'warning',
                      message: t(
                        'admin:components.features.manager.modal.detail.messages.disallowedFeature',
                      ),
                    });
                    return;
                  }
                  mutateSetManagerActivation({ id: managerId || '', activation: true });
                }}
              >
                {isSetManagerActivationLoading ? (
                  <Spinner />
                ) : (
                  t('admin:components.features.manager.modal.detail.button.active')
                )}
              </OutlineButton>
            </>
          )}
          {manager?.status === 'approved' && (
            <OutlineButton
              size='md'
              variant='error'
              disabled={isSetManagerActivationLoading}
              onClick={() => {
                if (managerLimit === 0) {
                  setToast({
                    isVisible: true,
                    type: 'warning',
                    message: t(
                      'admin:components.features.manager.modal.detail.messages.disallowedFeature',
                    ),
                  });
                  return;
                }
                mutateSetManagerActivation({ id: managerId || '', activation: false });
              }}
            >
              {isSetManagerActivationLoading ? (
                <Spinner />
              ) : (
                t('admin:components.features.manager.modal.detail.button.inactive')
              )}
            </OutlineButton>
          )}

          {manager?.status === 'pending' && (
            <>
              <Button
                disabled={isExamineLoading}
                onClick={() =>
                  setConfirmModal({
                    isVisible: true,
                    type: 'reject',
                  })
                }
                size='md'
                variant='error'
              >
                {isExamineLoading ? (
                  <Spinner />
                ) : (
                  t('admin:components.features.manager.modal.detail.button.reject')
                )}
              </Button>
              <Button
                disabled={isExamineLoading}
                onClick={() => examineMutateWithCallback(true)}
                size='md'
              >
                {isExamineLoading ? (
                  <Spinner />
                ) : (
                  t('admin:components.features.manager.modal.detail.button.approve')
                )}
              </Button>
            </>
          )}

          <Button
            className='manager-modal__close-button'
            size='md'
            variant='gray'
            type='button'
            onClick={() => onClose()}
          >
            {t('admin:components.features.manager.modal.detail.button.close')}
          </Button>
        </div>
      </Modal>

      <ConfirmModal
        isVisible={confirmModal.isVisible}
        type={confirmModal.type}
        name={manager?.name || ''}
        isLoading={isExamineLoading || isRemoveManagerFromOrganizationLoading}
        onSubmit={() => {
          if (confirmModal.type === 'reject') {
            examineMutateWithCallback(false);
          } else {
            mutateRemoveManagerFromOrganizationWithCallback();
          }
        }}
        onClose={() => setConfirmModal({ isVisible: false, type: confirmModal.type })}
      />
    </>
  );
}

export default ManagerModal;
