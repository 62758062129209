import { OutlineButton, Text } from '@pickme/ui';
import { t } from 'i18next';

import { useApplyPollAnalysis, useGetPollAnalysis } from 'query-hooks/poll';

import LogoIcon from 'resources/icons/nav/pickme_round_logo.png';
import BotIcon from 'resources/icons/common/bot.png';

import './index.scss';

type Props = {
  pollId: string;
};

function BotCard({ pollId }: Props) {
  const { isLoading: isAPILoading, data } = useGetPollAnalysis(pollId);
  const { isLoading: isApplyLoading, mutate } = useApplyPollAnalysis();
  const isLoading = isAPILoading || data?.status === 'requested' || isApplyLoading;

  return (
    <div className='poll-result__card'>
      <div className='poll-result__card__header'>
        <div className='poll-result__card__header__title'>
          <img className='poll-result__card__header__title__logo' src={LogoIcon} alt='logo' />
          <Text type='b2' fontWeight={500}>
            {t('admin:components.features.poll.result.botCard.title')}
          </Text>
        </div>

        {data?.status !== 'completed' && (
          <OutlineButton
            className='poll-result__card__header__button'
            disabled={isLoading}
            onClick={() => {
              if (!isLoading) {
                mutate(pollId);
              }
            }}
          >
            <img src={BotIcon} alt='bot' />
            <Text type='b3' fontWeight={600} color='primary-500'>
              {isLoading ? t('admin:components.features.poll.result.botCard.analyzing') : 'GPT 3.5'}
            </Text>
          </OutlineButton>
        )}
      </div>

      {data?.status === 'unRequested' ? (
        <div />
      ) : (
        <div className='poll-result__card__bot'>
          <img className='poll-result__card__bot__icon' src={BotIcon} alt='bot' />
          {isLoading ? (
            <div className='poll-result__card__bot__dots' />
          ) : (
            <Text fontWeight={400} type='b3'>
              {data?.result}
            </Text>
          )}
        </div>
      )}
    </div>
  );
}

export default BotCard;
