import { useLocation, useParams } from 'react-router-dom';

import { useGetPoll } from 'query-hooks/poll';

export function usePollStarted() {
  const { pathname } = useLocation();

  const isEditPage = pathname.includes('/edit/');
  if (!isEditPage) {
    return false;
  }

  const pollId = useParams<{ id: string }>().id ?? '';
  if (!pollId) {
    return false;
  }

  const { data: poll } = useGetPoll(pollId);
  return poll?.group !== 'ready';
}

export function usePollPeriods() {
  const { pathname } = useLocation();

  const isEditPage = pathname.includes('/edit/');
  const pollId = useParams<{ id: string }>().id ?? '';
  if (!isEditPage || !pollId) {
    return [];
  }

  if (!pollId) {
    return [];
  }

  const { data: poll } = useGetPoll(pollId);
  return poll?.periods ?? [];
}
