export default {
  title: '스페이스 소유자 변경',
  submitButtonText: {
    default: '소유자 변경 승인하기',
    payment: '결제 수단 등록하기',
  },
  items: {
    space: {
      title: '스페이스',
    },
    plan: {
      title: '구독 플랜',
    },
    contractType: {
      title: '계약 주기',
    },
    contractPeriod: {
      title: '구독 기간',
    },
    nextPaymentDate: {
      title: '다음 정기 결제일',
    },
  },
  messages: {
    payment:
      '원활한 플랜 유지를 위해 결제 수단을 등록해주세요. 다음 정기 결제일까지 어떠한 결제도 진행되지 않습니다.',
  },
  errorMessages: {
    failed: {
      title: '결제 수단 등록에 실패했습니다.',
      message: '실패 사유: {{errorMessage}}',
    },
  },
};
