import { t } from 'i18next';
import { Text } from '@pickme/design-system';

import { wrapper } from '../index.css';
import { li } from './index.css';

function CommonNote() {
  return (
    <div className={wrapper}>
      <Text size={14} fontWeight={600} color='black'>
        {t('admin:components.features.payment.modal.pointBill.common.title')}
      </Text>

      <ul>
        <li className={li}>
          {t('admin:components.features.payment.modal.pointBill.common.texts.0')}
        </li>
        <li className={li}>
          {t('admin:components.features.payment.modal.pointBill.common.texts.2')}
        </li>
        <li className={li}>
          {t('admin:components.features.payment.modal.pointBill.common.texts.3')}
        </li>
        <li className={li}>
          {t('admin:components.features.payment.modal.pointBill.common.texts.4')}
        </li>
        <li className={li}>
          {t('admin:components.features.payment.modal.pointBill.common.texts.5')}
        </li>
      </ul>
    </div>
  );
}

export default CommonNote;
