import { htmlToText } from 'html-to-text';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { FormProvider, SubmitErrorHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Callout, OutlineButton, Text } from '@pickme/design-system';
import { Poll } from '@pickme/core';
import { useOutletContext } from 'react-router-dom';
import * as yup from 'yup';

import NotificationSendCard from 'components/features/notification-v2/cards/Send';
import TargetFields from 'components/features/notification-v2/fields/send/Target';
import PollSelectModal from 'components/features/notification-v2/modal/PollSelect';
import EmailEditorField from 'components/features/notification-v2/fields/EmailEditor';
import SendReservationModal from 'components/features/notification-v2/modal/Reservation';
import ReservationDate from 'components/features/notification-v2/ReservationDate';

import { useSendEmail } from 'query-hooks/message';
import { useGetPollsWithIds } from 'query-hooks/poll-v2';

import { useGetFeatureEnabled } from 'hooks/useGetFeatures-v2';

import { toast } from 'states/toast';

import { FeatureTitleV2, MessagingAction } from 'types/features-v2';
import { RecipientType, EmailForm, MessagingType } from 'types/message';
import { type SendOutletContext } from 'pages/notification/Send';

import { calloutTitle, sendButtons } from '../common.css';
import { calloutTitleText, container } from './index.css';

function Email() {
  const setToast = useSetRecoilState(toast);
  const enableToUseNotification = useGetFeatureEnabled({
    feature: FeatureTitleV2.메시지,
    actionName: MessagingAction.메시지보내기,
  });

  const { pollId } = useOutletContext<SendOutletContext>();

  const methods = useForm<EmailForm>({
    mode: 'onTouched',
    defaultValues: {
      pollIds: pollId ? [pollId] : [],
      recipientType: RecipientType.All,
      dateTime: {
        date: '',
        time: '',
      },
    },
    resolver: yupResolver(schema),
  });
  const pollIds = useWatch({ control: methods.control, name: 'pollIds' });
  const results = useGetPollsWithIds(pollIds);
  const polls = results.map((result) => result.data).filter(Boolean) as Poll[];

  const convertedText = htmlToText(methods.watch('template'));
  const isSubmitDisabled = convertedText.trim().length === 0;

  const [isPollSelectorModalVisible, setPollSelectorModalVisible] = useState(false);
  const [isSendReservationModalVisible, setIsSendReservationModalVisible] = useState(false);

  const { mutate, isLoading } = useSendEmail();

  const onSubmit = (form: EmailForm) => {
    const type =
      form.dateTime?.date && form.dateTime.time ? MessagingType.Reserve : MessagingType.Send;
    mutate({ form, type });
  };

  const onError: SubmitErrorHandler<EmailForm> = (data) => {
    setToast({
      isVisible: true,
      type: 'error',
      message: data.pollIds?.message || data.subject?.message || '',
    });
  };

  return (
    <>
      <Callout>
        <Callout.Title className={calloutTitle}>
          <div className={calloutTitleText.main}>
            <Text size={16} fontWeight={500}>
              메일 1건 :
            </Text>
            <Text size={16} fontWeight={600} color='purple-500'>
              무료
            </Text>
          </div>

          <Text color='gray-600' className={calloutTitleText.sub}>
            (메일 무료 전송 이벤트 기간)
          </Text>
        </Callout.Title>

        <Callout.Sentence>
          대상자 명단에 이메일 주소가 등록되어 있어야 메시지를 전송할 수 있어요.
        </Callout.Sentence>
      </Callout>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit, onError)} className={container}>
          <NotificationSendCard title='전송 대상 선택'>
            <TargetFields
              polls={polls}
              isRecipientTypeDisabled={!enableToUseNotification}
              onClickAddPolls={() => setPollSelectorModalVisible(true)}
              pointPerVoter={0}
              hideEstimatedSubPoint
            />
          </NotificationSendCard>

          <NotificationSendCard title='이메일 작성'>
            <EmailEditorField />
          </NotificationSendCard>

          <div className={sendButtons.container}>
            <OutlineButton
              size='lg'
              variant='gray'
              onClick={() => setIsSendReservationModalVisible(true)}
              className={sendButtons.reserve}
            >
              예약
            </OutlineButton>

            <Button
              type='submit'
              size='lg'
              className={sendButtons.submit}
              disabled={isSubmitDisabled || isLoading}
            >
              전송하기
            </Button>
          </div>

          <ReservationDate />
        </form>
      </FormProvider>

      <PollSelectModal
        isVisible={isPollSelectorModalVisible}
        submittedPollIds={pollIds}
        onClose={() => setPollSelectorModalVisible(false)}
        onSubmit={(selectedPollIds) => {
          methods.setValue('pollIds', selectedPollIds);
          setPollSelectorModalVisible(false);
        }}
      />

      <SendReservationModal
        isVisible={isSendReservationModalVisible}
        onClose={() => setIsSendReservationModalVisible(false)}
        value={methods.watch('dateTime')}
        onSubmit={(value) => {
          methods.setValue('dateTime', value);
        }}
      />
    </>
  );
}

export default Email;

const schema = yup.object().shape({
  pollIds: yup.array().required().min(1, '투표를 선택해주세요'),
  recipientType: yup.mixed<RecipientType>().oneOf(Object.values(RecipientType)).required(),
  subject: yup.string().required('제목을 입력해주세요'),
});
