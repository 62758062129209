import { Text, QuestionTooltip } from '@pickme/ui';

import './index.scss';

type Props = {
  title: string;
  isRequired?: boolean;
  fontWeight?: 400 | 600;
  tooltip?: string;
};

function FieldTitle({ title, isRequired, fontWeight = 400, tooltip }: Props) {
  return (
    <div className='application-field-title'>
      <Text type='b3' fontWeight={fontWeight}>
        {title}
      </Text>

      {isRequired && (
        <Text
          type='b3'
          color='error-500'
          fontWeight={400}
          className='application-field-title--required'
        >
          *
        </Text>
      )}

      {tooltip && <QuestionTooltip message={tooltip} />}
    </div>
  );
}

export default FieldTitle;
