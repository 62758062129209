import { t } from 'i18next';

import {
  ChoiceElectionResult,
  ProsOrConsElectionResult,
  ScoreElectionResult,
  RankElectionResult,
  RadioQuestionResult,
  CheckboxQuestionResult,
  RankQuestionResult,
  TextQuestionResult,
  ScaleQuestionResult,
  ScoreQuestionResult,
} from 'types/poll';

export function parseChoiceElectionResult(result: ChoiceElectionResult, noAnimation?: boolean) {
  return {
    id: result._id,
    title: result.title,
    totalCount: result.totalVoteCount,
    unit: t('admin:terms.unit.vote'),
    tooltipLabels: {
      rate: t('admin:terms.poll.rate.election'),
      count: t('admin:terms.poll.count.election'),
    },
    items: result.resultsPerCandidate
      .sort((pre, next) => next.voteCount.value - pre.voteCount.value)
      ?.map((resultPerCandidate) => {
        const image = resultPerCandidate.members?.[0]?.fileUrl;

        return {
          title: resultPerCandidate.title,
          image: image || '',
          count: resultPerCandidate.voteCount.value,
          rate: resultPerCandidate.voteCount.rate,
        };
      }),
    abstention: result.responseValidation.allowAbstentionVote
      ? {
          title: t('admin:terms.poll.abstention.election'),
          rate: result.abstentionCount.rate,
          count: result.abstentionCount.value,
        }
      : undefined,
    animation: {
      fadeIn: !noAnimation,
      fillBar: !noAnimation,
      countingUp: !noAnimation,
    },
  };
}

export function parseProsOrConsElectionResult(
  result: ProsOrConsElectionResult,
  noAnimation?: boolean,
) {
  return {
    id: result._id,
    title: result.title,
    unit: t('admin:terms.unit.vote'),
    allowAbstentionVote: result.responseValidation.allowAbstentionVote,
    index: {
      pros: t('admin:terms.poll.pros'),
      cons: t('admin:terms.poll.cons'),
      abstention: t('admin:terms.poll.abstention.election'),
    },
    tooltipLabels: {
      rate: t('admin:terms.poll.rate.election'),
      count: t('admin:terms.poll.count.election'),
    },
    items: result.resultsPerCandidate?.map((resultPerCandidate) => {
      const image = resultPerCandidate.members?.[0]?.fileUrl;

      return {
        title: resultPerCandidate.title,
        image: image || '',
        pros: { count: resultPerCandidate.pros.value, rate: resultPerCandidate.pros.rate },
        cons: { count: resultPerCandidate.cons.value, rate: resultPerCandidate.cons.rate },
        abstention: {
          count: resultPerCandidate.abstention.value,
          rate: resultPerCandidate.abstention.rate,
        },
      };
    }),
    animation: {
      fadeIn: !noAnimation,
      fillBar: !noAnimation,
      countingUp: !noAnimation,
    },
  };
}

export function parseScoreElectionResult(result: ScoreElectionResult, noAnimation?: boolean) {
  return {
    id: result._id,
    title: result.title,
    unit: t('admin:terms.unit.vote'),
    items: result.resultsPerCandidate
      .sort((pre, next) => next.averageScore - pre.averageScore)
      ?.map((resultPerCandidate) => {
        const image = resultPerCandidate.members?.[0]?.fileUrl || '';

        return {
          title: resultPerCandidate.title,
          image,
          totalScore: resultPerCandidate.totalScore,
          voteCount: resultPerCandidate.voteCount,
          averageScore: resultPerCandidate.averageScore,
        };
      }),
    labels: {
      score: {
        title: t('admin:terms.poll.totalScore'),
        unit: t('admin:terms.unit.score'),
      },
      voter: {
        title: t('admin:terms.poll.allVoters.election'),
        unit: t('admin:terms.unit.people'),
      },
      average: {
        title: t('admin:terms.poll.average'),
        unit: t('admin:terms.unit.score'),
      },
      abstention: {
        title: t('admin:terms.poll.abstention.election'),
        unit: t('admin:terms.unit.vote'),
      },
    },
    allowAbstentionVote: result.responseValidation.allowAbstentionVote,
    abstentionCount: result.abstentionCount.value,
    animation: {
      fadeIn: !noAnimation,
      fillBar: !noAnimation,
      countingUp: !noAnimation,
    },
  };
}

export function parseRankElectionResult(result: RankElectionResult, noAnimation?: boolean) {
  return {
    id: result._id,
    title: result.title,
    items: result.resultsPerCandidate
      .sort((pre, next) => next.weightedScore - pre.weightedScore)
      ?.map((resultPerCandidate) => {
        const image = resultPerCandidate.members?.[0]?.fileUrl || '';

        return {
          title: resultPerCandidate.title,
          image,
          voteCountsPerRank: resultPerCandidate.voteCountsPerRank.map((voteCountsPerRank) => ({
            ...voteCountsPerRank,
            unit: t('admin:terms.unit.rank'),
          })),
          weightedScore: resultPerCandidate.weightedScore,
        };
      }),
    labels: {
      vote: {
        unit: t('admin:terms.unit.vote'),
      },
      score: {
        title: t('admin:functions.pollResult.tooltip.rank.title'),
        unit: t('admin:terms.unit.score'),
        tooltip: t('admin:functions.pollResult.tooltip.rank.message'),
      },
      abstention: {
        title: t('admin:terms.poll.abstention.election'),
        unit: t('admin:terms.unit.vote'),
      },
    },
    allowAbstentionVote: result.responseValidation.allowAbstentionVote,
    abstentionCount: result.abstentionCount.value,
    animation: {
      fadeIn: !noAnimation,
      fillBar: !noAnimation,
      countingUp: !noAnimation,
    },
  };
}

export function parseChoiceQuestionResult(
  result: RadioQuestionResult | CheckboxQuestionResult,
  index: number,
  noAnswerCount: number,
  noAnimation?: boolean,
) {
  return {
    index,
    id: result._id,
    title: result.title,
    answerCountText:
      result.kind === 'RadioQuestionResult'
        ? `${t('admin:terms.poll.allVoters.survey')} ${result.totalAnsweredCount.toLocaleString(
            'ko-KR',
          )}${t('admin:terms.unit.voter')}${
            noAnswerCount
              ? ` (${t('admin:terms.poll.abstention.survey')} ${noAnswerCount}${t(
                  'admin:terms.unit.voter',
                )})`
              : ''
          }`
        : `${t('admin:terms.poll.allVoters.survey')} ${result.totalAnsweredCount.toLocaleString(
            'ko-KR',
          )}${t('admin:terms.unit.voter')}(${t('admin:terms.poll.includingMultipleAnswers')})`,
    isRequired: result.isRequired,
    totalCount: result.totalAnsweredCount,
    unit: t('admin:terms.unit.vote'),
    tooltipLabels: {
      rate: t('admin:terms.poll.rate.election'),
      count: t('admin:terms.poll.count.election'),
    },
    items: result.results
      .sort((pre, next) => next.count - pre.count)
      .map((item) => ({
        ...item,
        description: item.description || '',
        number: `${item.number}`,
      })),
    animation: {
      fadeIn: !noAnimation,
      fillBar: !noAnimation,
      countingUp: !noAnimation,
    },
  };
}

export function parseTextQuestionResult(
  result: TextQuestionResult,
  index: number,
  noAnswerCount: number,
  noAnimation?: boolean,
) {
  return {
    index,
    id: result._id,
    title: result.title,
    answerCountText: `${t(
      'admin:terms.poll.allVoters.survey',
    )} ${result.totalAnsweredCount.toLocaleString('ko-KR')}${t('admin:terms.unit.voter')}${
      noAnswerCount
        ? ` (${t('admin:terms.poll.abstention.survey')} ${noAnswerCount}${t(
            'admin:terms.unit.voter',
          )})`
        : ''
    }`,
    isRequired: result.isRequired,
    labels: {
      empty: t('admin:functions.pollResult.labels.blank'),
    },
    items: result.results,
    animation: {
      fadeIn: !noAnimation,
      fillBar: !noAnimation,
      countingUp: !noAnimation,
    },
  };
}

export function parseScaleQuestionResult(
  result: ScaleQuestionResult,
  index: number,
  noAnswerCount: number,
  noAnimation?: boolean,
) {
  return {
    index,
    title: result.title,
    answerCountText: `${t(
      'admin:terms.poll.allVoters.survey',
    )} ${result.totalAnsweredCount.toLocaleString('ko-KR')}${t('admin:terms.unit.voter')}${
      noAnswerCount
        ? ` (${t('admin:terms.poll.abstention.survey')} ${noAnswerCount}${t(
            'admin:terms.unit.voter',
          )})`
        : ''
    }`,
    isRequired: result.isRequired,
    labels: {
      min: result.min || '',
      max: result.max || '',
    },
    items: result.results,
    animation: !noAnimation,
  };
}

export function parseScoreQuestionResult(
  result: ScoreQuestionResult,
  index: number,
  noAnswerCount: number,
  noAnimation?: boolean,
) {
  return {
    index,
    id: result._id,
    title: result.title,
    isRequired: result.isRequired,
    answerCountText: `${t(
      'admin:terms.poll.allVoters.survey',
    )} ${result.totalAnsweredCount.toLocaleString('ko-KR')}${t('admin:terms.unit.voter')}${
      noAnswerCount
        ? ` (${t('admin:terms.poll.abstention.survey')} ${noAnswerCount}${t(
            'admin:terms.unit.voter',
          )})`
        : ''
    }`,
    items: result.results
      .sort((pre, next) => next.averageScore - pre.averageScore)
      ?.map((item) => {
        const image = item?.fileUrl || '';

        return {
          description: item.description || '',
          image,
          totalScore: item.totalScore,
          answeredCount: item.answeredCount,
          averageScore: item.averageScore,
        };
      }),
    labels: {
      score: {
        title: t('admin:terms.poll.totalScore'),
        unit: t('admin:terms.unit.score'),
      },
      voter: {
        // NOTE: 기존 텍스트가 총원으로 되어 있어 .election으로 넣었지만,
        // .survey(총 응답자 수)로 수정의 여지가 있습니다.
        title: t('admin:terms.poll.allVoters.election'),
        unit: t('admin:terms.unit.voter'),
      },
      average: {
        title: t('admin:terms.poll.average'),
        unit: t('admin:terms.unit.score'),
      },
    },
    animation: {
      fadeIn: !noAnimation,
      fillBar: !noAnimation,
      countingUp: !noAnimation,
    },
  };
}

export function parseRankQuestionResult(
  result: RankQuestionResult,
  index: number,
  noAnswerCount: number,
  noAnimation?: boolean,
) {
  return {
    id: result._id,
    index,
    title: result.title,
    isRequired: result.isRequired,
    answerCountText: `${t(
      'admin:terms.poll.allVoters.survey',
    )} ${result.totalAnsweredCount.toLocaleString('ko-KR')}${t('admin:terms.unit.voter')}${
      noAnswerCount
        ? ` (${t('admin:terms.poll.abstention.survey')} ${noAnswerCount}${t(
            'admin:terms.unit.voter',
          )})`
        : ''
    }`,
    items: result.results
      .sort((pre, next) => next.weightedScore - pre.weightedScore)
      ?.map((item) => {
        const image = item.fileUrl || '';

        return {
          description: item.description,
          image,
          answerCountsPerRank: item.answerCountsPerRank.map((answerCountsPerRank) => ({
            ...answerCountsPerRank,
            unit: t('admin:terms.unit.rank'),
          })),
          weightedScore: item.weightedScore,
        };
      }),
    labels: {
      vote: {
        unit: t('admin:terms.unit.vote'),
      },
      score: {
        title: t('admin:functions.pollResult.tooltip.rank.title'),
        unit: t('admin:terms.unit.score'),
        tooltip: t('admin:functions.pollResult.tooltip.rank.message'),
      },
    },
    animation: {
      fadeIn: !noAnimation,
      fillBar: !noAnimation,
      countingUp: !noAnimation,
    },
  };
}
