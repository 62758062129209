import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { AxiosError } from 'axios';
import { t } from 'i18next';

import {
  createGetHandSpaceOverRequest,
  createLeaveSpaceRequest,
  createRemoveSpaceRequest,
  createHandSpaceOverRequest,
  createTakeOverSpaceWithPaymentRequest,
  createTakeOverSpaceWithoutPaymentRequest,
  createCancelHandSpaceOverRequest,
} from 'apis/space';

import { toast } from 'states/toast';
import { spaceState } from 'states/space';
import { faceMessageModal, messageModal } from 'states/modal';

import { ValidationError } from 'utils/error';

export const useRemoveSpace = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);
  const setActiveSpaceId = useSetRecoilState(spaceState.activeSpaceId);

  return useMutation(
    async () => {
      const { data } = await createRemoveSpaceRequest();
      return data;
    },
    {
      onSuccess: () => {
        setToast({
          isVisible: true,
          type: 'success',
          message: t('admin:query-hooks.space.useRemoveSpace.onSuccess.toast'),
        });
        setActiveSpaceId('');
        queryClient.removeQueries();
        navigate('/pickme');
      },
      onError: (error: AxiosError) => {
        setToast({
          type: 'error',
          isVisible: true,
          message: error.message,
        });
      },
    },
  );
};

export const useLeaveSpace = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);
  const setActiveSpaceId = useSetRecoilState(spaceState.activeSpaceId);

  return useMutation(
    async () => {
      const { data } = await createLeaveSpaceRequest();
      return data;
    },
    {
      onSuccess: () => {
        setToast({
          isVisible: true,
          type: 'notification',
          message: t('admin:query-hooks.space.useLeaveSpace.onSuccess.toast'),
        });
        setActiveSpaceId('');
        queryClient.removeQueries();
        navigate('/pickme');
      },
      onError: (error: AxiosError) => {
        setToast({
          type: 'error',
          isVisible: true,
          message: error.message,
        });
      },
    },
  );
};

export const useHandSpaceOver = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);
  const setMessageModal = useSetRecoilState(messageModal);

  return useMutation(
    async ({ email }: { email: string }) => {
      const { data } = await createHandSpaceOverRequest(email);
      return data;
    },
    {
      onSuccess: () => {
        setMessageModal({
          isVisible: true,
          title: t('admin:query-hooks.space.useHandSpaceOver.onSuccess.modal.title'),
          message: t('admin:query-hooks.space.useHandSpaceOver.onSuccess.modal.message'),
        });
        queryClient.invalidateQueries('organization');
        queryClient.invalidateQueries('organizations');
      },
      onError: (error: AxiosError) => {
        setToast({
          type: 'error',
          isVisible: true,
          message: error.message,
        });
      },
    },
  );
};

export const useCancelHandSpaceOver = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async () => {
      const { data } = await createCancelHandSpaceOverRequest();
      return data;
    },
    {
      onSuccess: () => {
        setToast({
          isVisible: true,
          type: 'success',
          message: t('admin:query-hooks.space.useCancelHandSpaceOver.onSuccess.toast'),
        });
        queryClient.invalidateQueries('organization');
        queryClient.invalidateQueries('organizations');
      },
      onError: (error: AxiosError) => {
        setToast({
          type: 'error',
          isVisible: true,
          message: error.message,
        });
      },
    },
  );
};

export const useGetTakingOverSpaceStatus = (id: string) => {
  const setToast = useSetRecoilState(toast);
  const navigate = useNavigate();

  return useQuery(
    ['taking-over-space', id],
    async () => {
      if (!id) {
        throw new ValidationError();
      }

      const { data } = await createGetHandSpaceOverRequest({ organizationOid: id });
      return data;
    },
    {
      onError: (error: AxiosError) => {
        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });

        navigate('/');
      },
      staleTime: 30 * 1000,
      cacheTime: 30 * 1000,
    },
  );
};

export const useTakeOverSpaceWithoutPayment = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toast);
  const setFaceMessageModal = useSetRecoilState(faceMessageModal);
  const setActiveSpaceId = useSetRecoilState(spaceState.activeSpaceId);

  return useMutation(
    async ({ organizationOid }: { organizationOid: string }) => {
      const { data } = await createTakeOverSpaceWithoutPaymentRequest({ organizationOid });
      return data;
    },
    {
      onSuccess: () => {
        navigate('/');

        setFaceMessageModal({
          isVisible: true,
          title: t('admin:query-hooks.space.useTakeOverSpaceWithoutPayment.onSuccess.modal.title'),
          message: t(
            'admin:query-hooks.space.useTakeOverSpaceWithoutPayment.onSuccess.modal.message',
          ),
          buttonText: t(
            'admin:query-hooks.space.useTakeOverSpaceWithoutPayment.onSuccess.modal.buttonText',
          ),
        });
        setActiveSpaceId('');

        queryClient.resetQueries();
      },
      onError: (error: AxiosError) => {
        setToast({
          type: 'error',
          isVisible: true,
          message: error.message,
        });
      },
    },
  );
};

export const useTakeOverSpaceWithPayment = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toast);
  const setFaceMessageModal = useSetRecoilState(faceMessageModal);
  const setActiveSpaceId = useSetRecoilState(spaceState.activeSpaceId);

  return useMutation(
    async (body: { organizationOid: string; authKey: string; customerKey: string }) => {
      const { data } = await createTakeOverSpaceWithPaymentRequest(body);
      return data;
    },
    {
      onSuccess: () => {
        navigate('/');

        setFaceMessageModal({
          isVisible: true,
          title: t('admin:query-hooks.space.useTakeOverSpaceWithPayment.onSuccess.modal.title'),
          message: t('admin:query-hooks.space.useTakeOverSpaceWithPayment.onSuccess.modal.message'),
          buttonText: t(
            'admin:query-hooks.space.useTakeOverSpaceWithPayment.onSuccess.modal.buttonText',
          ),
        });
        setActiveSpaceId('');

        queryClient.resetQueries();
      },
      onError: (error: AxiosError) => {
        setToast({
          type: 'error',
          isVisible: true,
          message: error.message,
        });
      },
    },
  );
};
