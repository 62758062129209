export default {
  title: {
    participate: {
      election: '선거 참여 설정',
      survey: '설문 참여 설정',
    },
    participants: '참여자 권한 설정',
    admin: '관리자 권한 설정',
  },
  fields: {
    voterAuthMethods: {
      title: '본인 인증 수단',
      items: {
        email: '이메일',
        phoneNumber: '전화번호',
        all: '전화번호 + 이메일',
      },
    },
    hasOpenVotes: {
      title: '응답 신원 매칭',
      description:
        '설문 응답 내용과 참여자의 신원 정보를 매칭하여 결과를 볼 수 있습니다. 응답자 신원 매칭을 하지 않는 경우 응답 내용만 확인할 수 있습니다.',
    },
    realtime: {
      title: '실시간 결과 보기',
      description:
        '설문이 시작되면 실시간으로 응답 내용을 확인할 수 있습니다. 실시간 결과 보기를 하지 않는 경우 설문 조사 종료 후 최종 결과를 확인할 수 있습니다.',
    },
    openThreshold: {
      label: '개표 가능한 투표율',
      tooltip:
        '개표 가능한 투표율을 설정하지 않으면 투표율에 관계없이 투표가 종료되면 개표할 수 있어요',
      placeholder: '개표 가능한 투표율을 입력해주세요',
    },
    openPollRate: {
      title: '실시간 투표율 공개',
      description: '참여자에게 실시간 투표율을 공개합니다.',
      label: {
        default: '실시간 투표율 공개',
        instantVoter: '실시간 참여자 수 공개',
      },
      tooltip: {
        default: '참여자에게 실시간 투표율 공개 유무를 선택해주세요',
        instantVoter: '참여자에게 실시간 참여자 수 공개 유무를 선택해주세요',
      },
    },
    openPollResult: {
      title: '결과 공개',
      description: {
        survey: '참여자에게 설문조사 결과를 공개합니다.',
        election: '참여자에게 선거 결과를 공개합니다.',
      },
    },
  },
  buttons: {
    submit: {
      election: {
        apply: '선거 만들기 완료',
        edit: '선거 수정하기 완료',
      },
      survey: {
        apply: '설문 만들기 완료',
        edit: '설문 수정하기 완료',
      },
    },
  },
};
