export default {
  title: {
    notice: '공지사항',
    tip: '활용팁',
  },
  search: {
    placeholder: '검색어를 입력해주세요',
    result: {
      blank: '검색 결과가 없어요\n다른 검색어를 입력해주세요!',
    },
  },
  table: {
    header: {
      title: '제목',
      date: '등록일',
    },
  },
  post: {
    file: '첨부파일',
    back: '목록으로 돌아가기',
  },
};
