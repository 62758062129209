import { CollaboratorRole, SelectableCollaboratorRole } from 'types/poll-settings-v2';

export const COLLABORATOR_ROLE_LABELS: Record<SelectableCollaboratorRole, string> = {
  [CollaboratorRole.Editor]: '에디터',
  [CollaboratorRole.Viewer]: '뷰어',
} as const;

export const COLLABORATOR_ROLE_SELECT_ITEMS = Object.entries(COLLABORATOR_ROLE_LABELS).map(
  ([value, label]) => ({
    label,
    value: value as SelectableCollaboratorRole,
  }),
);
