export default {
  title: '정말 스페이스를 삭제하시겠어요?',
  message: {
    prefix: '삭제를 진행하고 싶으면 영대문자로',
    suffix: '를 입력한 후 삭제하기 버튼을 눌러주세요.',
  },
  buttons: {
    cancel: '취소',
    submit: '삭제하기',
  },
};
