export default {
  table: {
    header: {
      plan: '플랜',
      name: {
        election: '선거 제목',
        survey: '설문 제목',
        all: '투표 제목',
      },
      status: '상태',
      itemCount: '문항',
      voteCount: '참여자 수',
      rate: '투표율',
      more: '관리',
    },
  },
  search: {
    result: {
      blank: '검색 결과가 없어요\n다른 검색어를 입력해주세요',
      placeholder: {
        all: '예정된 투표가 없어요',
        election: '예정된 선거가 없어요',
        survey: '예정된 설문이 없어요',
      },
    },
  },
  buttons: {
    share: '공유하기',
    edit: '수정하기',
    remove: '삭제하기',
  },
};
