import { Text, Modal, ModalHeader, ModalBody, ModalActions } from '@pickme/ui';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

function ConfirmModal({
  isVisible,
  isLoading,
  name,
  type,
  onClose,
  onSubmit,
}: {
  isVisible: boolean;
  isLoading: boolean;
  name: string;
  type: 'removeFormGroup' | 'reject';
  onClose: () => void;
  onSubmit: () => void;
}) {
  const submitButtonText =
    type === 'reject'
      ? t('admin:components.features.manager.modal.confirm.actions.reject')
      : t('admin:components.features.manager.modal.confirm.actions.remove');

  return (
    <Modal className='manager-confirm-modal' size='md' isVisible={isVisible} onClose={onClose}>
      <ModalHeader
        title={
          type === 'reject'
            ? t('admin:components.features.manager.modal.confirm.title.reject', {
                name,
              })
            : t('admin:components.features.manager.modal.confirm.title.remove', {
                name,
              })
        }
        onClose={onClose}
      />

      <ModalBody>
        <Text type='b3' fontWeight={400} color='gray-400'>
          {type === 'reject'
            ? t('admin:components.features.manager.modal.confirm.description.reject')
            : t('admin:components.features.manager.modal.confirm.description.remove')}
        </Text>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t('admin:components.features.manager.modal.confirm.actions.cancel'),
            type: 'button',
            variant: 'gray',
            onClick: () => onClose(),
          },
          {
            text: isLoading ? <Spinner /> : submitButtonText,
            type: 'button',
            variant: 'error',
            disabled: isLoading,
            onClick: () => onSubmit(),
          },
        ]}
      />
    </Modal>
  );
}

export default ConfirmModal;
