import { useEffect } from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useGetSessionAsUser } from 'query-hooks/auth';

import { redirectUrl as redirectUrlState } from 'states/redirect';
import { spaceState } from 'states/space';

import { routesAfterSpaceSet, routesBeforeSpaceSet } from 'constants/routes';

export function useSetRedirectUrl() {
  const setRedirectUrl = useSetRecoilState(redirectUrlState);
  const location = useLocation();
  const matchedRouteInAfterSpaceSet = matchRoutes(routesAfterSpaceSet, location)?.[0];
  const matchedRouteInBeforeSpaceSet = matchRoutes(routesBeforeSpaceSet, location)?.[0];
  const needToReserve = !!(matchedRouteInAfterSpaceSet || matchedRouteInBeforeSpaceSet);

  const { data: userSessionData } = useGetSessionAsUser({ suspense: false });
  const isSigned = !!userSessionData?._id;

  useEffect(() => {
    let url = location.pathname;
    if (url === '/' || !needToReserve || isSigned) {
      return;
    }

    if (matchedRouteInBeforeSpaceSet?.route.includeQueryString) {
      url += location.search;
    }

    setRedirectUrl({
      url,
      timestamp: Date.now(),
      afterSpaceSet: !matchedRouteInBeforeSpaceSet,
    });
  }, [matchedRouteInAfterSpaceSet?.route.path, matchedRouteInBeforeSpaceSet?.route.path, isSigned]);
}

export function useNavigateToRedirectUrl() {
  const navigate = useNavigate();

  const activeSpaceId = useRecoilValue(spaceState.activeSpaceId);
  const isOrganizationSelected = !!activeSpaceId;

  const { data: userSessionData } = useGetSessionAsUser({ suspense: false });
  const isSigned = !!userSessionData?._id;
  const isEmailRegistered = !!userSessionData?.email;

  const [redirectUrl, setRedirectUrl] = useRecoilState(redirectUrlState);

  const isAbleToRedirect =
    (!redirectUrl.afterSpaceSet || isOrganizationSelected) && isSigned && isEmailRegistered;

  useEffect(() => {
    if (redirectUrl.url && isAbleToRedirect) {
      // 현재 시간 - 20분 보다, 타임스탬프 가 더 나중이어야 함.
      if (moment(redirectUrl.timestamp, 'x').isAfter(moment().subtract(20, 'minutes'))) {
        navigate(redirectUrl.url);
      }

      setRedirectUrl({ url: '', timestamp: 0, afterSpaceSet: true });
    }
  }, [redirectUrl.url, isAbleToRedirect]);
}
