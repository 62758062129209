import chargeTransaction from './incomingDetail/chargeTransaction';
import extraTransaction from './incomingDetail/extraTransaction';
import priceViewer from './incomingDetail/priceViewer';
import transferTransaction from './incomingDetail/transferTransaction';

export default {
  chargeTransaction,
  extraTransaction,
  priceViewer,
  transferTransaction,

  title: '상세 내역',
  buttons: {
    print: '영수증 출력',
    cancel: '결제 취소',
  },
};
