import { t } from 'i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Spinner } from '@pickme/ui';
import { Button, Input } from '@pickme/design-system';

import { useCredentialSignIn } from 'query-hooks/auth';

import { emailValidator, idValidator, passwordValidator } from 'functions/validator/auth';

import { CredentialSignInBody } from 'types/auth';

import { submitButton } from 'pages/auth/common.css';
import { fields } from './index.css';

function SignInForm() {
  const methods = useForm<CredentialSignInBody>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
    shouldUseNativeValidation: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const { isLoading, mutate } = useCredentialSignIn();

  const onSubmit = (formData: CredentialSignInBody) => {
    mutate(formData);
  };

  const isPasswordEntered = !!methods.watch('password');
  const isPasswordErrorMessageVisible =
    isPasswordEntered && methods.getFieldState('password').isDirty && !!errors.password;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className={fields}>
          <Input
            {...register('email', {
              validate: (value) => {
                const isValidEmail = emailValidator(value);
                const isValidId = idValidator(value);

                return isValidEmail || isValidId;
              },
              setValueAs: (value) => value?.trim().toLowerCase(),
              required: true,
            })}
            size='lg'
            placeholder='아이디 또는 이메일을 입력해 주세요'
            isValid={!errors.email}
            errorMessage={
              errors.email
                ? t('admin:components.features.auth.form.signin.errorMessages.wrongEmail')
                : ''
            }
          />

          <Input
            {...register('password', {
              validate: passwordValidator,
              required: true,
            })}
            size='lg'
            type='password'
            placeholder='비밀번호를 입력해 주세요'
            isValid={!isPasswordErrorMessageVisible}
            errorMessage={
              isPasswordErrorMessageVisible
                ? t('admin:components.features.auth.form.signin.errorMessages.wrongPassword')
                : ''
            }
          />
        </div>

        <Button
          fullWidth
          size='xl'
          variant='primary'
          type='submit'
          disabled={!isValid || isLoading}
          data-testid='submit'
          className={submitButton}
        >
          {isLoading ? <Spinner /> : '로그인하기'}
        </Button>
      </form>
    </FormProvider>
  );
}

export default SignInForm;
