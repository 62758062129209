import { loadTossPayments } from '@tosspayments/payment-sdk';
import { ChargeTransaction } from '@pickme/core';

import {
  CHARGE_TRANSACTION_BADGES,
  EXTRA_TRANSACTION_BADGES,
  TRANSFER_TRANSACTION_BADGES,
} from 'constants/payment';

export const parseChargeTransactionToBadge = (chargeTransaction: ChargeTransaction) => {
  if (!chargeTransaction.status) {
    return {
      variant: 'gray',
      text: '',
    };
  }

  if (chargeTransaction.details.method === 'system' && chargeTransaction.status === 'completed') {
    return CHARGE_TRANSACTION_BADGES['system-completed'];
  }

  if (chargeTransaction.details.method === 'system' && chargeTransaction.status === 'canceled') {
    return CHARGE_TRANSACTION_BADGES['system-canceled'];
  }

  return CHARGE_TRANSACTION_BADGES[chargeTransaction.status];
};

export const parseTransferTransactionToBadge = () => TRANSFER_TRANSACTION_BADGES.common;

export const parseExtraTransactionToBadge = () => EXTRA_TRANSACTION_BADGES.common;

const CLIENT_KEY = import.meta.env.VITE_TOSS_BILLING_CLIENT_KEY || '';

export const registerBillingForTakingOverSpace = async (
  data: {
    customerKey: string;
    organizationOid: string;
  },
  failureCallback: (errorMessage: string) => void,
) => {
  try {
    const tossPayments = await loadTossPayments(CLIENT_KEY);
    tossPayments.requestBillingAuth('카드', {
      customerKey: data.customerKey,
      successUrl: `${
        import.meta.env.VITE_INDEX_URL
      }/payment/callback/success?product=hand-space-over&customerKey=${
        data.customerKey
      }&organizationOid=${data.organizationOid}`,
      failUrl: `${import.meta.env.VITE_INDEX_URL}/payment/callback/failure?product=hand-space-over`,
    });
  } catch (error: any) {
    failureCallback(error.message);
  }
};
