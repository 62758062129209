import ReactDOM from 'react-dom/client';

import '@pickme/ui/dist/style.css';
import '@pickme/design-system/dist/style.css';

import './setupAxios';
import './setupMoment';
import './setupI18n';
import './setupDayjs';

import { initSentry } from './functions/sentry';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

initSentry();
