export default {
  useGetOrderId: {
    onError: {
      toast: '결제에 실패했습니다. 관리자에게 문의해주세요.',
    },
  },

  useCreatePurchasePointWithVirtualAccount: {
    onError: {
      modal: {
        title: '결제에 실패했습니다.',
        message: '실패 사유: {{text}}',
      },
    },
  },

  useCreatePurchasePoint: {
    onError: {
      modal: {
        title: '결제에 실패했습니다.',
        message: '실패 사유: {{text}}',
      },
    },
  },

  useTransferPoint: {
    onSuccess: {
      toast: '포인트 선물이 성공적으로 완료되었습니다',
    },
  },

  useReplaceCard: {
    onSuccess: {
      toast: '결제 카드 변경이 완료되었습니다.',
    },
    onError: {
      modal: {
        title: '카드 변경에 실패했습니다.',
        message: '실패 사유: {{text}}',
      },
    },
  },

  useReplacePlan: {
    onSuccess: {
      toast: {
        pause: '구독 중단이 예약되었습니다.',
        replace: '구독 변경이 예약되었습니다.',
      },
    },
  },

  useCancelPlanReplace: {
    onSuccess: {
      toast: {
        pause: '구독 중단 예약이 취소되었습니다.',
        replace: '구독 변경이 취소되었습니다.',
      },
    },
  },

  useReplacePaymentEmail: {
    onSuccess: {
      toast: '성공적으로 결제 이메일이 업데이트되었습니다.',
    },
  },

  usePurchaseMoreVoters: {
    onSuccess: {
      toast: '추가 결제에 성공했습니다.',
    },
  },

  useCancelReplaceVoterLimit: {
    onSuccess: {
      toast: '과금 투표인 수 변경 예약이 취소되었습니다.',
    },
  },
};
