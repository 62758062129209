import { Dropdown, PlainButton } from '@pickme/ui';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { t } from 'i18next';

import CandidateCardContents from 'components/features/poll-card-contents/Candidate';

import { CandidateFormBody } from 'types/application';

import MoreIcon from 'resources/icons/application/more.png';
import DraggableIcon from 'resources/icons/application/draggable.png';
import RemoveIcon from 'resources/icons/application/remove.png';

import './index.scss';

type Props = CandidateFormBody & {
  id: string;
  onClick: () => void;
  onRemove: () => void;
  disabled: boolean;
};

function CandidateCard({ id, title, members, tags, onClick, onRemove, disabled }: Props) {
  const sortable = useSortable({ id });
  const { attributes, listeners, setNodeRef, transition, transform } = sortable;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <PlainButton
      onClick={() => onClick()}
      className='election-form-candidate'
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <PlainButton
        type='button'
        {...listeners}
        className='election-form-candidate__draggable-button'
      >
        <img className='election-form-candidate__draggable-icon' src={DraggableIcon} alt='more' />
      </PlainButton>

      <CandidateCardContents title={title} tags={tags} members={members} />

      <div className='election-form-candidate__dropdown'>
        <Dropdown
          button={
            <img className='election-form-candidate__dropdown-icon' src={MoreIcon} alt='more' />
          }
          items={[
            {
              text: t(
                'admin:components.features.application.form.subElection.cards.candidate.buttons.remove',
              ),
              type: 'button',
              icon: RemoveIcon,
              color: 'red',
              disabled,
              onClick: () => onRemove(),
            },
          ]}
        />
      </div>
    </PlainButton>
  );
}

export default CandidateCard;
