import axios from 'axios';
import { UseFormReturn } from 'react-hook-form';
import { isEmpty } from 'lodash-es';

import {
  BasicFormBody,
  SubElectionsFormBody,
  VoterFormBody,
  AdditionalFormBody,
  ElectionFormBody,
  QuestionsFormBody,
  SurveyFormBody,
} from 'types/application';

export function getDirtyFieldsFromElectionForm(forms: {
  basicForm: UseFormReturn<BasicFormBody>;
  subElectionsForm: UseFormReturn<SubElectionsFormBody>;
  votersForm: UseFormReturn<VoterFormBody>;
  additionalForm: UseFormReturn<AdditionalFormBody>;
}) {
  const { basicForm, subElectionsForm, votersForm, additionalForm } = forms;

  let body: Partial<ElectionFormBody> = {};
  if (
    basicForm.formState.isDirty ||
    !isEmpty(basicForm.formState.dirtyFields) ||
    !isEmpty(basicForm.formState.touchedFields)
    // NOTE: useFieldArray의 변경 사항이 dirtyFields로 잡히지 않아 touchFields를 추가합니다.
  ) {
    body = {
      ...body,
      ...basicForm.getValues(),
      description: (body.description = (basicForm.getValues('description') || '').replace(
        /(<p><br><\/p>){1,}$/,
        '',
      )),
    };
  }

  if (subElectionsForm.formState.isDirty || !isEmpty(subElectionsForm.formState.dirtyFields)) {
    body.subElections = subElectionsForm.getValues('subElections');
  }

  if (votersForm.formState.isDirty || !isEmpty(votersForm.formState.dirtyFields)) {
    body.voterBook = votersForm.getValues('voterBook');
  }

  if (additionalForm.formState.isDirty || !isEmpty(additionalForm.formState.dirtyFields)) {
    body = {
      ...body,
      ...additionalForm.getValues(),
    };
  }

  return body;
}

export function getDirtyFieldsFromSurveyForm(forms: {
  basicForm: UseFormReturn<BasicFormBody>;
  questionsForm: UseFormReturn<QuestionsFormBody>;
  votersForm: UseFormReturn<VoterFormBody>;
  additionalForm: UseFormReturn<AdditionalFormBody>;
}) {
  const { basicForm, questionsForm, votersForm, additionalForm } = forms;

  let body: Partial<SurveyFormBody> = {};
  if (
    basicForm.formState.isDirty ||
    !isEmpty(basicForm.formState.dirtyFields) ||
    !isEmpty(basicForm.formState.touchedFields)
    // NOTE: useFieldArray의 변경 사항이 dirtyFields로 잡히지 않아 touchFields를 추가합니다.
  ) {
    body = {
      ...body,
      ...basicForm.getValues(),
      description: (body.description = (basicForm.getValues('description') || '').replace(
        /(<p><br><\/p>){1,}$/,
        '',
      )),
    };
  }

  if (questionsForm.formState.isDirty || !isEmpty(questionsForm.formState.dirtyFields)) {
    body.questions = questionsForm.getValues('questions');
  }

  if (votersForm.formState.isDirty || !isEmpty(votersForm.formState.dirtyFields)) {
    const hasInstantVoter = votersForm.getValues('hasInstantVoter');
    body.hasInstantVoter = hasInstantVoter;
    body.voterBook = hasInstantVoter ? [] : votersForm.getValues('voterBook');
  }

  if (additionalForm.formState.isDirty || !isEmpty(additionalForm.formState.dirtyFields)) {
    body = {
      ...body,
      ...additionalForm.getValues(),
    };
  }

  return body;
}

export const imageUploader = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const form = new FormData();
    form.append('file', file, encodeURIComponent(file.name));

    axios({
      method: 'POST',
      url: `${
        import.meta.env.MODE === 'dev.local' ? '' : import.meta.env.VITE_API_HOST_URL
      }/api/file`,
      data: form,
    })
      .then((response) => {
        resolve(`${import.meta.env.VITE_API_HOST_URL}/api/file/${response.data.fileOid}`);
      })
      .catch((error) => reject(error));
  });
