import { PrimaryBadge } from '@pickme/ui';
import { MemberLevel } from '@pickme/core';
import { t } from 'i18next';

type Props = {
  level: MemberLevel;
};

const BADGE_VALUES: {
  [level in MemberLevel]: {
    color: string;
    text: string;
  };
} = {
  OrganAdmin: {
    color: 'vivid-purple',
    text: t('admin:terms.space.owner'),
  },
  SubAdmin: {
    color: 'vivid-blue',
    text: t('admin:terms.space.manager'),
  },
};

function MemberLevelBadge({ level }: Props) {
  return (
    <PrimaryBadge variant={BADGE_VALUES[level].color}>{BADGE_VALUES[level].text}</PrimaryBadge>
  );
}

export default MemberLevelBadge;
