export default {
  title: '투표 관리',
  table: {
    header: {
      kind: '분류',
      name: '투표 제목',
      status: '상태',
      count: '총 대상자 수',
      'discard-status': '파기 상태',
      'discard-date': '파기 예정 날짜',
    },
  },
  search: {
    result: {
      blank: '표시할 내용이 없습니다.',
    },
  },
  callout: [
    ['개표/무효', '시점으로부터', '7일 이후에 참여자의 개인정보가 자동 파기됩니다.'],
    '개인정보가 파기되면 참여자 명단과 대상자 명단을 더 이상 다운로드할 수 없어요.',
  ],
  labels: {
    status: {
      complete: '파기 완료',
      pending: '파기 예정',
    },
  },
};
