export default {
  title: '이메일',
  buttons: {
    edit: '변경하기',
    cancel: '변경취소',
    verifyOTP: '인증하기',
    pending: '전송완료',
  },
  messages: {
    isVerifiedEmail: '인증된 이메일입니다.',
    sendOTP: '인증번호를 발송했어요 받으신 인증번호를 입력해주세요',
    isVerifiedPassword: '비밀번호가 확인되었습니다.',
    isEmailDuplicated: '이미 등록된 이메일입니다',
    emailFormat: '올바른 이메일 주소를 입력해주세요',
    password: '비밀번호를 입력해주세요',
    wrongPassword: '잘못된 비밀번호입니다.',
  },
  form: {
    fields: {
      password: {
        label: '비밀번호 확인',
        placeholder: '본인 확인을 위해 비밀번호를 입력해주세요',
      },
      email: {
        label: '새로운 이메일',
        placeholder: '새 이메일 주소를 입력해주세요',
      },
    },
  },
};
