import { useEffect } from 'react';
import { Modal, ModalActions, ModalBody, ModalHeader, Spinner, Text } from '@pickme/ui';
import { t } from 'i18next';

import { useHandSpaceOver } from 'query-hooks/space';

import { useGetActiveSpace } from 'hooks/useGetActiveSpace';

import { Manager } from 'types/manager';

import './index.scss';

const DESCRIPTION = {
  leave: {
    title: t('admin:components.features.space.modal.handSpaceOverConfirm.description.leave.title'),
    contents: [
      t('admin:components.features.space.modal.handSpaceOverConfirm.description.leave.contents.0'),
      t('admin:components.features.space.modal.handSpaceOverConfirm.description.leave.contents.1'),
      t('admin:components.features.space.modal.handSpaceOverConfirm.description.leave.contents.2'),
      t('admin:components.features.space.modal.handSpaceOverConfirm.description.leave.contents.3'),
    ],
  },
  relegation: {
    title: t(
      'admin:components.features.space.modal.handSpaceOverConfirm.description.relegation.title',
    ),
    contents: [
      t(
        'admin:components.features.space.modal.handSpaceOverConfirm.description.relegation.contents.0',
      ),
      t(
        'admin:components.features.space.modal.handSpaceOverConfirm.description.relegation.contents.1',
      ),
      t(
        'admin:components.features.space.modal.handSpaceOverConfirm.description.relegation.contents.2',
      ),
      t(
        'admin:components.features.space.modal.handSpaceOverConfirm.description.relegation.contents.3',
      ),
    ],
  },
};

type Props = {
  isVisible: boolean;
  onClose: () => void;
  manager: Manager | string;
  type: 'leave' | 'relegation';
};

function HandSpaceOverConfirmModal({ isVisible, onClose, manager, type }: Props) {
  const isManual = typeof manager === 'string';
  const space = useGetActiveSpace();
  const spaceName = space?.name;

  const { isLoading, isSuccess, mutate } = useHandSpaceOver();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return (
    <Modal size='lg' isVisible={isVisible} onClose={onClose} className='hand-space-over-confirm'>
      <ModalHeader
        title={t('admin:components.features.space.modal.handSpaceOverConfirm.title')}
        onClose={onClose}
      />

      <ModalBody>
        <Text type='b3' color='gray-400'>
          {t('admin:components.features.space.modal.handSpaceOverConfirm.message')}
        </Text>

        <div className='hand-space-over-confirm__info'>
          {[
            {
              title: t(
                'admin:components.features.space.modal.handSpaceOverConfirm.info.organizationName',
              ),
              content: spaceName,
            },
            {
              title: t('admin:components.features.space.modal.handSpaceOverConfirm.info.newOwner'),
              content: isManual ? manager : `${manager.email || manager.id}(${manager.name})`,
            },
          ].map((item) => (
            <div key={item.title} className='hand-space-over-confirm__info__row'>
              <div className='hand-space-over-confirm__info__row__title'>
                <Text type='b2' fontWeight={600} color='gray-600'>
                  {item.title}
                </Text>
              </div>
              <div className='hand-space-over-confirm__info__row__content'>
                <Text type='b2' fontWeight={400} color='gray-600'>
                  {item.content}
                </Text>
              </div>
            </div>
          ))}
        </div>

        <div className='hand-space-over-confirm__callout'>
          <Text type='b2' color='error-500' fontWeight={600}>
            {DESCRIPTION[type].title}
          </Text>

          <div className='hand-space-over-confirm__callout__contents'>
            {DESCRIPTION[type].contents.map((text, index) => (
              <li key={`leave-space-callout-${index}`}>
                <Text type='b2' color='gray-600' fontWeight={400}>
                  {text}
                </Text>
              </li>
            ))}
          </div>
        </div>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t('admin:components.features.space.modal.handSpaceOverConfirm.buttons.cancel'),
            variant: 'gray',
            disabled: isLoading,
            onClick: onClose,
          },
          {
            text: isLoading ? (
              <Spinner />
            ) : (
              t('admin:components.features.space.modal.handSpaceOverConfirm.buttons.submit')
            ),
            disabled: isLoading,
            onClick: () => mutate({ email: isManual ? manager : manager.email }),
          },
        ]}
      />
    </Modal>
  );
}

export default HandSpaceOverConfirmModal;
