import { useUploadFile } from 'query-hooks/file';

import ImageIcon from 'resources/icons/application/image.png';

import './index.scss';

type Props = {
  id: string;
  onSubmit: (fileUrl: string) => void;
  hideButton?: boolean;
};

function ImageInputButton({ id, hideButton, onSubmit }: Props) {
  const { mutate: fileUploadMutate, isLoading: isFileUploadLoading } = useUploadFile();

  return (
    <>
      {!hideButton && (
        <button
          className='poll-form__image-picker'
          type='button'
          onClick={() => {
            if (isFileUploadLoading) {
              return;
            }

            document.getElementById(id)?.click();
          }}
        >
          <img src={ImageIcon} alt='picker' />
        </button>
      )}

      <input
        hidden
        id={id}
        type='file'
        accept='image/jpeg, image/jpg, image/png'
        onChange={(event) => {
          if (isFileUploadLoading || !event.target.files || !event.target.files?.[0]) {
            return;
          }

          fileUploadMutate(event.target.files[0], {
            onSuccess: onSubmit,
          });
        }}
      />
    </>
  );
}

export default ImageInputButton;
