import { useQueryString } from 'hooks/useQueryString';

export const usePaymentCallbackQueryString = () => {
  const {
    product,
    voterLimit,
    planType,
    contractType,
    authKey,
    amount,
    method,
    orderId,
    paymentKey,
    customerKey,
    organizationOid,
  } = useQueryString({
    product: '',
    voterLimit: 1,
    planType: '',
    contractType: '',
    authKey: '',
    amount: 0,
    method: '',
    orderId: '',
    paymentKey: '',
    customerKey: '',
    organizationOid: '',
  });

  return {
    product,
    subscription: { voterLimit, planType, contractType, authKey },
    point: { method, amount, orderId, paymentKey },
    handSpaceOver: {
      organizationOid,
      customerKey,
    },
  };
};
