import { t } from 'i18next';
import { PollGroup } from '@pickme/core';

import { POLL_SEARCH_GROUP } from 'constants/poll';

import { ElectionKind, QuestionFormBody, SubElectionFormBody, SurveyKind } from 'types/application';
import {
  PollQuery,
  PollSearchGroup,
  PollSearchKind,
  PollQueryAdapter,
  Question,
  SubElection,
} from 'types/poll';

export const createPollQuery = ({
  kind = PollSearchKind.All,
  name,
  page = 1,
  period,
  groups = PollSearchGroup.All,
  excludeInstantPoll,
  authorId,
}: PollQueryAdapter) => {
  const pollQuery: PollQuery = {
    pollRate: true,
    page,
  };

  if (kind !== 'all' || kind !== PollSearchKind.All) {
    pollQuery.kind = kind;
  }

  if (name) {
    pollQuery.pollName = name;
  }

  if (period?.start && period?.end) {
    pollQuery.period = JSON.stringify(period);
  }

  if (groups && groups !== PollSearchGroup.All) {
    let groupsQuery = [];

    if (Array.isArray(groups)) {
      groupsQuery = groups;
    }

    if (groups === PollSearchGroup.Ended) {
      groupsQuery = [PollGroup.Ended, PollGroup.Counted, PollGroup.Canceled];
    } else {
      groupsQuery = [groups];
    }

    pollQuery.groups = groupsQuery.join(',');
  }

  if (excludeInstantPoll) {
    pollQuery.excludeInstantPoll = 'true';
  }

  if (authorId) {
    pollQuery.authorId = authorId;
  }

  return pollQuery;
};

export const getGroupColorFromPollGroup = (group: PollGroup | PollSearchGroup) => {
  switch (group) {
    case PollGroup.Ready:
    case PollSearchGroup.Ready:
      return POLL_SEARCH_GROUP[PollSearchGroup.Ready].color;
    case PollGroup.Ongoing:
    case PollSearchGroup.Ongoing:
      return POLL_SEARCH_GROUP[PollSearchGroup.Ongoing].color;
    case PollGroup.Paused:
      return POLL_SEARCH_GROUP[PollSearchGroup.Paused].color;
    default:
      return POLL_SEARCH_GROUP[PollSearchGroup.Ended].color;
  }
};

export const makeElectionBadges = (
  election: SubElection | SubElectionFormBody,
): { variant: string; text: string }[] => {
  const badges: { variant: string; text: string }[] = [];

  const isNeededResponseValidationBadge = checkResponseValidationBadgeNecessary(election.kind);
  if (isNeededResponseValidationBadge) {
    const responseValidationBadge = makeResponseValidationBadge(election);
    if (responseValidationBadge) {
      badges.push(responseValidationBadge);
    }
  }

  const abstentionBadge = makeAbstentionBadge(election);
  const shuffleBadge = makeShuffleBadge(election);
  badges.push(abstentionBadge, shuffleBadge);

  return badges;
};

export const makeQuestionBadges = (
  question: Question | QuestionFormBody,
): { variant: string; text: string }[] => {
  const badges: { variant: string; text: string }[] = [];

  if (question.isRequired) {
    const requiredBadge = makeRequiredBadge();
    badges.push(requiredBadge);
  }

  const isNeededResponseValidationBadge = checkResponseValidationBadgeNecessary(question.kind);
  if (isNeededResponseValidationBadge) {
    const responseValidationBadge = makeResponseValidationBadge(question);
    if (responseValidationBadge) {
      badges.push(responseValidationBadge);
    }
  }

  if (question.kind !== 'TextQuestion' && question.kind !== 'ScaleQuestion') {
    const shuffleBadge = makeShuffleBadge(question);
    badges.push(shuffleBadge);
  }

  return badges;
};

const checkResponseValidationBadgeNecessary = (subPollKind: ElectionKind | SurveyKind) => {
  if (subPollKind === 'CheckboxQuestion') {
    return true;
  }
  switch (subPollKind) {
    case 'ChoiceElection':
    case 'RankElection':
    case 'RankQuestion':
    case 'ScoreElection':
    case 'ScoreQuestion':
    case 'ScaleQuestion':
      return true;
    default:
      return false;
  }
};

const makeResponseValidationBadge = (
  subPoll: SubElection | Question | SubElectionFormBody | QuestionFormBody,
) => {
  switch (subPoll.kind) {
    case 'ChoiceElection':
      return {
        variant: 'blue',
        text: t('admin:functions.poll.badges.ChoiceElection', {
          min: subPoll?.responseValidation?.minResponse,
          max: subPoll?.responseValidation?.maxResponse,
        }),
      };
    case 'RankElection':
      return {
        variant: 'yellow',
        text: t('admin:functions.poll.badges.RankElection', {
          rank: subPoll?.responseValidation?.rankLimit,
        }),
      };
    case 'RankQuestion':
      return {
        variant: 'yellow',
        text: t('admin:functions.poll.badges.RankQuestion', {
          rank: subPoll?.responseValidation?.rankLimit,
        }),
      };
    case 'ScoreElection':
      return {
        variant: 'maroon',
        text: t('admin:functions.poll.badges.ScoreElection', {
          min: subPoll?.responseValidation?.minScore,
          max: subPoll?.responseValidation?.maxScore,
        }),
      };
    case 'CheckboxQuestion':
      if (subPoll?.responseValidation?.validation === 'eq') {
        return {
          variant: 'blue',
          text: t('admin:functions.poll.badges.CheckboxQuestion', {
            threshold: subPoll?.responseValidation?.threshold,
          }),
        };
      }
      return null;
    case 'ScoreQuestion':
      return {
        variant: 'maroon',
        text: t('admin:functions.poll.badges.ScoreQuestion', {
          min: subPoll?.responseValidation?.minScore,
          max: subPoll?.responseValidation?.maxScore,
        }),
      };
    case 'ScaleQuestion':
      return {
        variant: 'green',
        text: t('admin:functions.poll.badges.ScaleQuestion', {
          min: subPoll?.min?.value,
          max: subPoll?.max?.value,
        }),
      };

    default:
      return null;
  }
};

const makeShuffleBadge = (
  subPoll: SubElection | Question | SubElectionFormBody | QuestionFormBody,
) => {
  const shuffleBadge = {
    variant: subPoll?.shuffle ? 'brown' : 'carrot',
    text: subPoll?.shuffle
      ? t('admin:functions.poll.badges.shuffled')
      : t('admin:functions.poll.badges.ordered'),
  };

  return shuffleBadge;
};

const makeAbstentionBadge = (election: SubElection | SubElectionFormBody) => {
  const abstentionBadge = {
    variant: election?.responseValidation.allowAbstentionVote ? 'green' : 'rose',
    text: election?.responseValidation.allowAbstentionVote
      ? t('admin:functions.poll.badges.allowAbstentionVote')
      : t('admin:functions.poll.badges.disallowAbstentionVote'),
  };

  return abstentionBadge;
};

const makeRequiredBadge = () => {
  const requiredBadge = { variant: 'error', text: t('admin:functions.poll.badges.required') };
  return requiredBadge;
};
