export default {
  title: '정말 구독을 중단하시겠어요?',
  message: {
    immediate: '즉시 구독이 중단됩니다. 이후에는 무료 플랜으로 이용할 수 있습니다.',
    period: {
      format: 'YYYY년 M월 D일',
      text: '{{date}}까지는 모든 기능을 정상적으로 사용할 수 있습니다. 이후에는 무료 플랜으로 이용할 수 있습니다.',
    },
  },
  buttons: {
    cancel: '취소',
    submit: '중단하기',
  },
};
