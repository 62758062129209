export default {
  title: {
    delete: '스페이스 삭제',
    leave: '스페이스 나가기',
  },
  message: '스페이스에서 나가기 전에 아래 내용을 확인해주세요.',
  info: {
    organizationName: '스페이스 이름',
    poll: '진행 중인 투표',
    point: '알림톡 잔여 포인트',
  },
  description: {
    owner: {
      title: '스페이스 삭제 안내',
      contents: [
        '스페이스를 삭제하면 모든 데이터가 사라지고 복구할 수 없습니다.',
        '스페이스를 삭제하면 진행 중인 모든 투표는 정지되고 삭제됩니다.',
        '스페이스를 삭제하면 알림톡 포인트는 소멸됩니다. 잔여 알림톡 포인트 환불 관련하여 고객센터에 문의해주세요.',
      ],
    },
    manager: {
      title: '스페이스 나가기 안내',
      contents: [
        '스페이스에서 나가면 다시 초대를 받기 전까지 이 스페이스에 접근할 수 없습니다.',
        '스페이스에서 나가면 모든 투표 관리 관한은 스페이스 소유자에게 이전되며, 권한은 복구할 수 없습니다.',
        '스페이스에서 나가면 알림톡 포인트는 소멸됩니다. 잔여 알림톡 포인트 환불 관련하여 고객센터에 문의해주세요.',
      ],
    },
  },
  buttons: {
    cancel: '취소',
    submit: {
      delete: '삭제하기',
      leave: '나가기',
    },
  },
};
