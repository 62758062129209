import { Text } from '@pickme/design-system';

import { ELECTION_BADGES, SURVEY_BADGES } from 'constants/poll-v2';

import { ElectionTypeCase } from 'types/application-v2';
import { SurveyTypeCase } from 'types/application-v2/default';

import { container, iconStyle } from './index.css';

type Props = {
  size?: 'md' | 'sm';
  type: ElectionTypeCase | SurveyTypeCase;
  number?: number;
};

function PollBadge({ size = 'sm', type, number }: Props) {
  const badge = [...ELECTION_BADGES, ...SURVEY_BADGES].find(
    ({ type: badgeType }) => badgeType === type,
  );

  if (!badge) {
    return null;
  }

  return (
    <div
      className={container({
        size,
      })}
      style={{ backgroundColor: badge.backgroundColor }}
    >
      <img className={iconStyle({ size })} src={badge.icon} alt={badge.type} />

      {!badge?.ignoreCount && number ? (
        <Text
          size={14}
          fontWeight={400}
          style={{
            color: badge.textColor,
          }}
        >
          {number}
        </Text>
      ) : null}
    </div>
  );
}

export default PollBadge;
