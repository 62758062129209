import { DraftTemplates } from 'types/template';

import SCHOOL_TEMPLATES from './school';
import COMPANY_TEMPLATES from './company';
import LABOR_TEMPLATES from './labor';
import ASSOCIATION_TEMPLATES from './association';
import CLUB_TEMPLATES from './club';
import APARTMENT_TEMPLATES from './apartment';
import RELIGION_TEMPLATES from './religion';

const ELECTION_TEMPLATES: DraftTemplates = {
  school: SCHOOL_TEMPLATES,
  company: COMPANY_TEMPLATES,
  labor: LABOR_TEMPLATES,
  association: ASSOCIATION_TEMPLATES,
  club: CLUB_TEMPLATES,
  apartment: APARTMENT_TEMPLATES,
  religion: RELIGION_TEMPLATES,
};

export default ELECTION_TEMPLATES;
