import { atom } from 'recoil';

const initialState: {
  isVisible: boolean;
  id: string;
} = {
  isVisible: false,
  id: '',
};

export const pollViewer = atom({
  key: 'poll-viewer',
  default: initialState,
});
