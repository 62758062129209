import {
  Text,
  ChoiceElectionResultCard,
  ProsOrConsElectionResultCard,
  RankElectionResultCard,
  ScoreElectionResultCard,
  ChoiceQuestionResultCard,
  TextQuestionResultCard,
  RankQuestionResultCard,
  ScaleQuestionResultCard,
  ScoreQuestionResultCard,
  Callout,
} from '@pickme/ui';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

import BotCard from 'components/features/poll/Result/BotCard';

import { useGetPoll, useGetPollResult } from 'query-hooks/poll';

import {
  parseChoiceElectionResult,
  parseProsOrConsElectionResult,
  parseScoreElectionResult,
  parseRankElectionResult,
  parseChoiceQuestionResult,
  parseTextQuestionResult,
  parseScaleQuestionResult,
  parseRankQuestionResult,
  parseScoreQuestionResult,
} from 'functions/poll-result';

import {
  ChoiceElectionResult,
  ProsOrConsElectionResult,
  RankElectionResult,
  ScoreElectionResult,
  RadioQuestionResult,
  CheckboxQuestionResult,
  RankQuestionResult,
  ScaleQuestionResult,
  ScoreQuestionResult,
  TextQuestionResult,
} from 'types/poll';

import './index.scss';

type Props = {
  id: string;
  showTitle?: boolean;
  noAnimation?: boolean;
  onClose?: () => void;
};

function PollResult({ id, showTitle, onClose, noAnimation }: Props) {
  const { data: pollData } = useGetPoll(id);
  const { data } = useGetPollResult(id);

  return (
    <div className='poll-result'>
      {showTitle && (
        <div className='poll-result__title'>
          <Text type='b1' fontWeight={600}>
            {t('admin:components.features.poll.result.pollResult.title')}
          </Text>
        </div>
      )}

      {pollData?.kind === 'Survey' && (
        <Callout
          id='open-votes'
          variant='primary'
          bulletStyle={pollData.hasOpenVotes ? 'bullet' : 'none'}
          sentences={
            pollData.hasOpenVotes
              ? [
                  t('admin:components.features.poll.result.pollResult.download.excel'),
                  <Text type='b3' fontWeight={400}>
                    {t('admin:components.features.poll.result.pollResult.download.disabled')}{' '}
                    <Link
                      to='/settings/poll/discard-data'
                      onClick={() => {
                        if (onClose) {
                          onClose();
                        }
                      }}
                    >
                      <b>{t('admin:components.features.poll.result.pollResult.download.remove')}</b>
                    </Link>
                  </Text>,
                ]
              : [t('admin:components.features.poll.result.pollResult.download.excel')]
          }
        />
      )}

      {data?.subElectionResults && (
        <ElectionResult subElectionResults={data?.subElectionResults} noAnimation={noAnimation} />
      )}
      {data?.questionResults && (
        <SurveyResult
          questionResults={data?.questionResults || []}
          totalVoteCount={data?.rate.voteCount}
          noAnimation={noAnimation}
        />
      )}

      {data?.group === 'counted' && <BotCard pollId={id} />}
    </div>
  );
}

function ElectionResult({
  subElectionResults,
  noAnimation,
}: {
  subElectionResults: (
    | ChoiceElectionResult
    | ProsOrConsElectionResult
    | RankElectionResult
    | ScoreElectionResult
  )[];
  noAnimation?: boolean;
}) {
  return (
    <>
      {subElectionResults.map((subElectionResult) => {
        if (subElectionResult.kind === 'ChoiceElection') {
          return (
            <ChoiceElectionResultCard
              key={subElectionResult._id}
              {...parseChoiceElectionResult(subElectionResult, noAnimation)}
            />
          );
        }

        if (subElectionResult.kind === 'ProsOrConsElection') {
          return (
            <ProsOrConsElectionResultCard
              key={subElectionResult._id}
              {...parseProsOrConsElectionResult(subElectionResult, noAnimation)}
            />
          );
        }

        if (subElectionResult.kind === 'ScoreElection') {
          return (
            <ScoreElectionResultCard
              key={subElectionResult._id}
              {...parseScoreElectionResult(subElectionResult, noAnimation)}
            />
          );
        }

        if (subElectionResult.kind === 'RankElection') {
          return (
            <RankElectionResultCard
              key={subElectionResult._id}
              {...parseRankElectionResult(subElectionResult, noAnimation)}
            />
          );
        }

        return <div />;
      })}
    </>
  );
}

function SurveyResult({
  questionResults,
  totalVoteCount,
  noAnimation,
}: {
  questionResults: (
    | RadioQuestionResult
    | CheckboxQuestionResult
    | TextQuestionResult
    | ScaleQuestionResult
    | RankQuestionResult
    | ScoreQuestionResult
  )[];
  totalVoteCount: number;
  noAnimation?: boolean;
}) {
  return (
    <>
      {questionResults.map((questionResult, index) => {
        const noAnswerCount = totalVoteCount - questionResult.totalAnsweredCount;

        if (
          questionResult.kind === 'RadioQuestionResult' ||
          questionResult.kind === 'CheckboxQuestionResult'
        ) {
          return (
            <ChoiceQuestionResultCard
              key={questionResult._id}
              {...parseChoiceQuestionResult(questionResult, index, noAnswerCount, noAnimation)}
            />
          );
        }
        if (questionResult.kind === 'TextQuestionResult') {
          return (
            <TextQuestionResultCard
              key={questionResult._id}
              {...parseTextQuestionResult(questionResult, index, noAnswerCount, noAnimation)}
            />
          );
        }
        if (questionResult.kind === 'ScaleQuestionResult') {
          return (
            <ScaleQuestionResultCard
              key={questionResult._id}
              {...parseScaleQuestionResult(questionResult, index, noAnswerCount, noAnimation)}
            />
          );
        }
        if (questionResult.kind === 'RankQuestionResult') {
          return (
            <RankQuestionResultCard
              key={questionResult._id}
              {...parseRankQuestionResult(questionResult, index, noAnswerCount, noAnimation)}
            />
          );
        }
        if (questionResult.kind === 'ScoreQuestionResult') {
          return (
            <ScoreQuestionResultCard
              key={questionResult._id}
              {...parseScoreQuestionResult(questionResult, index, noAnswerCount, noAnimation)}
            />
          );
        }

        return <div />;
      })}
    </>
  );
}

export default PollResult;
