import { useEffect, useState } from 'react';
import { Navigate, useOutletContext } from 'react-router-dom';

import Spinner from 'components/common/Spinner';

import { useSocialIntegrate } from 'query-hooks/auth';

import { SocialUserInfo } from 'types/auth';

function SocialIntegrateCallback() {
  const userInfo = useOutletContext<SocialUserInfo>();
  const socialIntegrate = useSocialIntegrate();

  const [redirectUrl, setRedirectUrl] = useState<string>();

  useEffect(() => {
    socialIntegrate.mutate({
      provider: userInfo.provider,
      email: userInfo.email,
      socialKey: userInfo.socialKey,
      accessToken: userInfo.accessToken,
      refreshToken: userInfo.refreshToken,
    });
  }, []);

  useEffect(() => {
    if (socialIntegrate.isSuccess || socialIntegrate.isError) {
      setRedirectUrl('/account');
    }
  }, [socialIntegrate.isSuccess, socialIntegrate.isError]);

  return redirectUrl ? <Navigate to={redirectUrl} replace /> : <Spinner />;
}

export default SocialIntegrateCallback;
