import { atom } from 'recoil';

const initialState: {
  tag: 'input' | null;
  isFocused: boolean;
} = {
  tag: null,
  isFocused: false,
};

export const focus = atom({
  key: 'focus-element',
  default: initialState,
});
