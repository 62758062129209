import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Table, Callout, PlainButton, PrimaryBadge } from '@pickme/ui';
import { Text, Button, Pagination } from '@pickme/design-system';
import { Plan } from '@pickme/core';
import moment from 'moment-timezone';
import { useSetRecoilState } from 'recoil';
import { find } from 'lodash-es';
import { t } from 'i18next';

import NameAndPeriodSearch from 'components/features/search/NameAndPeriodSearch';
import InviteModal from 'components/features/modal/InviteModal';
import ManagerModal from 'components/features/manager/ManagerModal';
import DisabledFeatureBanner from 'components/features/banner/DisabledFeature';
import LeaveSpaceModal from 'components/features/space/modal/LeaveSpace';
import RemoveSpaceModal from 'components/features/space/modal/RemoveSpace';
import MoveToHandSpaceOverBeforeRemoveSpaceModal from 'components/features/space/modal/MoveToHandSpaceOverBeforeRemoveSpace';
import CancelHandSpaceOverModal from 'components/features/space/modal/CancelHandSpaceOver';
import HandSpaceOverModal from 'components/features/space/modal/HandSpaceOver';
import HandSpaceOverConfirmModal from 'components/features/space/modal/HandSpaceOverConfirm';
import MemberLevelBadge from 'components/features/badge/MemberLevel';

import { useGetManagerCounts, useGetManagers } from 'query-hooks/manager';
import { useGetSessionAsMember } from 'query-hooks/auth';
import { useLeaveSpace, useRemoveSpace } from 'query-hooks/space';

import { useQueryString } from 'hooks/useQueryString';
import { useGetFeatures } from 'hooks/useGetFeatures';
import { useGetActiveSpace } from 'hooks/useGetActiveSpace';

import { toast } from 'states/toast';

import { makeQueryString, parseUrlWithPage } from 'functions/url';

import { NameAndPeriodSearchForm } from 'types/search';
import { Manager } from 'types/manager';

import InviteIcon from 'resources/icons/table/invite.png';
import AddIcon from 'resources/icons/dashboard/add.svg';

import { container, headerText, pageBodyContainer, row, table } from './index.css';
import './index.scss';

function PollDashboard() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const setToast = useSetRecoilState(toast);

  const { managerLimit, isPaymentFailed, type } = useGetFeatures();
  const { data: memberData } = useGetSessionAsMember();

  const { mutate: leaveSpace, isLoading: isLeaveSpaceLoading } = useLeaveSpace();
  const { mutate: removeSpace, isLoading: isRemoveSpaceLoading } = useRemoveSpace();

  const searchForm = useForm<NameAndPeriodSearchForm>();
  const { page, keyword } = useQueryString({ page: 1, keyword: '' });

  const space = useGetActiveSpace();

  const { data: managersData, isLoading } = useGetManagers({ page, keyword });
  const { data: managersCountData } = useGetManagerCounts();
  const managersCount = managersCountData || 0;

  const [managerModal, setManagerModal] = useState<{ isVisible: boolean; managerId?: string }>({
    isVisible: false,
  });
  const [isInviteModalVisible, setInviteModalVisible] = useState(false);
  const [isLeaveModalVisible, setLeaveModalVisible] = useState(false);
  const [isRemoveModalVisible, setRemoveModalVisible] = useState(false);
  const [
    isMoveToHandSpaceOverBeforeRemoveSpaceModalVisible,
    setMoveToHandSpaceOverBeforeRemoveSpaceModalVisible,
  ] = useState(false);
  const [isCancelHandSpaceOverModalVisible, setCancelHandSpaceOverModalVisible] = useState(false);
  const [isHandSpaceOverModalVisible, setHandSpaceOverModalVisible] = useState(false);
  const [HandSpaceOverConfirm, setHandSpaceOverConfirm] = useState<{
    isVisible: boolean;
    manager?: Manager | string;
  }>({
    isVisible: false,
  });

  const onSearch = (data: NameAndPeriodSearchForm) => {
    const query: { page: number; keyword?: string } = {
      page: 1,
    };

    if (data.name) {
      query.keyword = data.name;
    }

    const queryString = makeQueryString(query);
    navigate(`${pathname}?${queryString}`);
  };

  const onMovePage = (selectedPage: number) => {
    const parsedPage = parseUrlWithPage(`${pathname}${search}`, selectedPage);
    navigate(parsedPage);
  };

  const onClickTableRow = (id: string) => {
    if (find(managersData?.admins, { id })?.level === 'OrganAdmin') {
      return;
    }

    setManagerModal({
      isVisible: true,
      managerId: id,
    });
  };

  const tableHeader = [
    {
      title: t('admin:pages.organization.manager.table.header.name'),
      name: 'name',
    },
    {
      title: t('admin:pages.organization.manager.table.header.phone'),
      name: 'phone',
    },
    {
      title: t('admin:pages.organization.manager.table.header.email'),
      name: 'email',
    },
    {
      title: t('admin:pages.organization.manager.table.header.level'),
      name: 'level',
    },
    {
      title: t('admin:pages.organization.manager.table.header.date'),
      name: 'date',
    },
    {
      title: t('admin:pages.organization.manager.table.header.join'),
      name: 'join',
    },
    {
      title: t('admin:pages.organization.manager.table.header.status'),
      name: 'status',
    },
  ];

  return (
    <>
      {managerLimit === 0 && <DisabledFeatureBanner type='subscribe' />}
      <div className={`${container} ${managerLimit === 0 ? 'padded-top' : ''}`}>
        <Text size={32} fontWeight={600} tag='h1'>
          {t('admin:pages.organization.manager.title')}
        </Text>

        <div className={pageBodyContainer}>
          <Callout
            id='manager'
            sentences={[
              `${t('admin:pages.organization.manager.callout.0', {
                number: managersCount ? managersCount - 1 : 0,
              })}${
                memberData?.level === 'OrganAdmin'
                  ? t('admin:pages.organization.manager.callout.1')
                  : ''
              }`,
            ]}
            variant='primary'
          />

          <FormProvider {...searchForm}>
            <form onSubmit={searchForm.handleSubmit(onSearch)}>
              <NameAndPeriodSearch
                initialValues={{ name: keyword }}
                placeholder={t('admin:pages.organization.manager.search.placeholder')}
                disableToUsePeriod
              />
            </form>
          </FormProvider>

          <div className={row}>
            <div className={headerText}>
              <Text size={16} fontWeight={500}>
                {t('admin:terms.space.manager')}
              </Text>

              <Text size={16} fontWeight={500}>
                {managersCount ? managersCount - 1 : 0}
                {t('admin:terms.unit.people')}
              </Text>
            </div>

            {memberData?.level === 'OrganAdmin' && (
              <Button
                size='lg'
                disabled={managerLimit <= (managersCount || 0)}
                icon={AddIcon}
                onClick={() => {
                  if (isPaymentFailed) {
                    setToast({
                      isVisible: true,
                      type: 'error',
                      message: t('admin:pages.organization.manager.errorMessages.payment.message'),
                    });
                    return;
                  }

                  setInviteModalVisible(true);
                }}
              >
                {t('admin:pages.organization.manager.button.invite')}
              </Button>
            )}
          </div>

          <Table
            id='manager'
            className={`manager__table ${table}`}
            headers={tableHeader}
            showSkeleton={isLoading}
            rows={setTableRows(
              managersData?.admins || [],
              () => setCancelHandSpaceOverModalVisible(true),
              () => setHandSpaceOverModalVisible(true),
              memberData?.level === 'OrganAdmin',
              !!space?.transferRequest,
            )}
            onItemClick={
              memberData?.level === 'OrganAdmin' || managerLimit === 0 ? onClickTableRow : undefined
            }
            placeholder={{
              icon: InviteIcon,
              text: t('admin:pages.organization.manager.search.result.blank'),
            }}
          />

          <Pagination
            currentPage={page}
            totalPage={managersData?.endPage || 1}
            onMove={onMovePage}
          />

          <Button
            width={130}
            size='md'
            variant='danger'
            onClick={() => {
              if (type !== Plan.Free && memberData?.level === 'OrganAdmin') {
                setMoveToHandSpaceOverBeforeRemoveSpaceModalVisible(true);
                return;
              }

              setLeaveModalVisible(true);
            }}
          >
            {memberData?.level === 'OrganAdmin'
              ? t('admin:pages.organization.manager.button.remove')
              : t('admin:pages.organization.manager.button.leave')}
          </Button>
        </div>

        {memberData?.level && (
          <LeaveSpaceModal
            isVisible={isLeaveModalVisible}
            onClose={() => setLeaveModalVisible(false)}
            onSubmitButtonClick={() => {
              setLeaveModalVisible(false);

              if (memberData.level === 'SubAdmin') {
                if (isLeaveSpaceLoading) {
                  return;
                }

                leaveSpace();
              } else {
                setRemoveModalVisible(true);
              }
            }}
            memberLevel={memberData.level}
          />
        )}

        <RemoveSpaceModal
          isVisible={isRemoveModalVisible}
          onClose={() => setRemoveModalVisible(false)}
          onSubmitButtonClick={() => {
            if (isRemoveSpaceLoading) {
              return;
            }

            removeSpace();
          }}
        />

        <MoveToHandSpaceOverBeforeRemoveSpaceModal
          isVisible={isMoveToHandSpaceOverBeforeRemoveSpaceModalVisible}
          onClose={() => setMoveToHandSpaceOverBeforeRemoveSpaceModalVisible(false)}
          onSubmitButtonClick={() => {
            setMoveToHandSpaceOverBeforeRemoveSpaceModalVisible(false);
            setHandSpaceOverModalVisible(true);
          }}
        />

        <CancelHandSpaceOverModal
          isVisible={isCancelHandSpaceOverModalVisible}
          email={space?.transferRequest?.email || ''}
          onClose={() => setCancelHandSpaceOverModalVisible(false)}
        />

        <HandSpaceOverModal
          isVisible={isHandSpaceOverModalVisible}
          onClose={() => setHandSpaceOverModalVisible(false)}
          onSubmitButtonClick={(manager) => {
            setHandSpaceOverModalVisible(false);
            setHandSpaceOverConfirm({
              isVisible: true,
              manager,
            });
          }}
        />

        {HandSpaceOverConfirm.manager && (
          <HandSpaceOverConfirmModal
            isVisible={HandSpaceOverConfirm.isVisible}
            manager={HandSpaceOverConfirm.manager}
            type={managerLimit > managersCount ? 'relegation' : 'leave'}
            onClose={() => {
              setHandSpaceOverConfirm({
                isVisible: false,
              });
            }}
          />
        )}

        <InviteModal
          isVisible={isInviteModalVisible}
          link={`${import.meta.env.VITE_INDEX_URL}/invite/${space?.alias}`}
          onClose={() => setInviteModalVisible(false)}
        />
        <ManagerModal
          isVisible={managerModal.isVisible}
          managerId={managerModal.managerId}
          onClose={() => setManagerModal({ isVisible: false })}
        />
      </div>
    </>
  );
}

const setTableRows = (
  managers: Manager[],
  onCancelHandSpaceOverButtonClick: () => void,
  onHandSpaceOverButtonClick: () => void,
  isOrganAdmin: boolean,
  transferRequested?: boolean,
) =>
  managers?.map((manager) => {
    const items = [
      {
        data: manager.name,
        name: 'name',
      },
      {
        data: manager.phoneNumber,
        name: 'phone',
      },
      {
        data: manager.email,
        name: 'email',
      },
      {
        data: <MemberLevelBadge level={manager.level} />,
        name: 'level',
      },
      {
        data: moment(manager.approvedAt).tz('Asia/Seoul').format('YY/MM/DD HH:mm'),
        name: 'date',
      },
    ];

    if (manager.level === 'OrganAdmin') {
      items.push({
        data: isOrganAdmin ? (
          <PlainButton
            onClick={() => {
              if (transferRequested) {
                onCancelHandSpaceOverButtonClick();
              } else {
                onHandSpaceOverButtonClick();
              }
            }}
          >
            <Text size={12} color={transferRequested ? 'red-500' : 'primary-500'} fontWeight={400}>
              {transferRequested
                ? t('admin:pages.organization.manager.button.cancel')
                : t('admin:pages.organization.manager.button.transfer')}
            </Text>
          </PlainButton>
        ) : (
          <div />
        ),
        name: 'join',
      });
      items.push({
        data: <div />,
        name: 'status',
      });
    } else {
      items.push({
        data: (
          <PrimaryBadge variant={manager.status === 'pending' ? 'warning' : 'primary'}>
            {manager.status === 'pending'
              ? t('admin:pages.organization.manager.labels.join.pending')
              : t('admin:pages.organization.manager.labels.join.approved')}
          </PrimaryBadge>
        ),
        name: 'join',
      });
      items.push({
        data: (
          <PrimaryBadge variant={manager.status === 'approved' ? 'success' : 'gray'}>
            {manager.status === 'approved'
              ? t('admin:pages.organization.manager.labels.status.pending')
              : t('admin:pages.organization.manager.labels.status.approved')}
          </PrimaryBadge>
        ),
        name: 'status',
      });
    }

    return {
      id: manager.id,
      items,
    };
  }) || [];

export default PollDashboard;
