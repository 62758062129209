import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useBlocker } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import moment from 'moment-timezone';
import { Plan } from '@pickme/core';
import { t } from 'i18next';

import ApplicationLayout from 'components/common/layout/Application';
import CompleteModal from 'components/features/application/modal/Complete';
import DraftWriteConfirmModal from 'components/features/application/modal/DraftWriteConfirm';
import LeaveModal from 'components/features/application/modal/Leave';
import ElectionForm from 'components/features/application/form/Election';
import PlanField from 'components/features/application/field/PlanField';
import PeriodErrorModal from 'components/features/application/modal/PeriodError';
import DraftButton from 'components/features/application/button/Draft';
import DraftModal from 'components/features/application/modal/Draft';

import { useApplyPoll } from 'query-hooks/poll';
import { useWriteDraft } from 'query-hooks/draft';

import { useElectionForm } from 'hooks/poll/usePollForm';
import { usePollFormStepFromHashRouter } from 'hooks/poll/usePollFormStepFromHashRouter';
import { useGetFeatures } from 'hooks/useGetFeatures';
import { useElectionFormChanged } from 'hooks/poll/useFormChanged';
import { useQueryString } from 'hooks/useQueryString';
import { usePollPeriodErrorModal } from 'hooks/usePollPeriodErrorModal';

import { toast } from 'states/toast';

import { Period, PollFormStep } from 'types/application';

function ElectionApplication() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const setToast = useSetRecoilState(toast);

  const [isCompleteModalVisible, setCompleteModalVisible] = useState(false);
  const [isDraftWriteConfirmModalVisible, setDraftWriteConfirmModalVisible] = useState(false);
  const [leaveModal, setLeaveModal] = useState<{
    isVisible: boolean;
    nextUrl: string;
  }>({
    isVisible: false,
    nextUrl: '',
  });
  const [isDraftModalVisible, setDraftModalVisible] = useState(false);

  const { basicForm, subElectionsForm, votersForm, additionalForm } = useElectionForm();

  useEffect(() => {
    if (basicForm) {
      basicForm.setValue('periods', [
        {
          startDate: moment().tz('Asia/Seoul').add(2, 'hours').format('YYYY-MM-DDTHH:mm'),
          endDate: '',
        },
      ]);
    }
  }, [basicForm]);

  const isBasicFormActive = basicForm.formState.isDirty && basicForm.formState.isValid;
  const isSubElectionsFormActive =
    subElectionsForm.formState.isDirty && subElectionsForm.formState.isValid;

  const incorrectVoterCounts = votersForm.watch('incorrectVoters').length;
  const isVotersFormActive =
    votersForm.formState.isDirty && votersForm.formState.isValid && incorrectVoterCounts === 0;
  const isAdditionalFormActive =
    isBasicFormActive && isSubElectionsFormActive && isVotersFormActive;

  const step = usePollFormStepFromHashRouter({
    isSecondStepAccessible: isBasicFormActive,
    isThirdStepAccessible: isSubElectionsFormActive,
    isForthStepAccessible: isBasicFormActive && isSubElectionsFormActive && isVotersFormActive,
  });

  const { type, isPaymentFailed } = useGetFeatures();
  const { type: typeInQueryString } = useQueryString({ type: '' as Plan | '' });

  const availableTypes = new Set([Plan.Free, type]);
  if (!typeInQueryString || !availableTypes.has(typeInQueryString)) {
    navigate('/', { replace: true, unstable_viewTransition: true });
  }

  useEffect(() => {
    if (typeInQueryString) {
      basicForm.setValue('subscriptionType', typeInQueryString as Plan);
    }
  }, [typeInQueryString]);

  const {
    isLoading: isApplyPollLoading,
    mutate,
    isSuccess: isApplyPollSuccess,
    data,
    error,
    variables,
  } = useApplyPoll();
  const {
    isLoading: isWriteDraftLoading,
    isSuccess: isWriteDraftSuccess,
    mutate: writeDraftMutate,
  } = useWriteDraft();
  const isLoading = isApplyPollLoading || isWriteDraftLoading;
  const isSuccess = isApplyPollSuccess || isWriteDraftSuccess;

  const onSubmit = () => {
    if (
      !(
        basicForm.formState.touchedFields &&
        subElectionsForm.formState.touchedFields &&
        votersForm.formState.touchedFields &&
        additionalForm.formState.touchedFields
      )
    ) {
      return;
    }

    const body = {
      ...basicForm.getValues(),
      ...subElectionsForm.getValues(),
      voterBook: votersForm.getValues().voterBook,
      ...additionalForm.getValues(),
    };

    mutate(body);
  };

  const onSubmitWithAdjustedPeriods = (periods: Period[]) => {
    if (
      !(
        basicForm.formState.touchedFields &&
        subElectionsForm.formState.touchedFields &&
        votersForm.formState.touchedFields &&
        additionalForm.formState.touchedFields
      )
    ) {
      return;
    }

    const body = {
      ...basicForm.getValues(),
      ...subElectionsForm.getValues(),
      voterBook: votersForm.getValues().voterBook,
      ...additionalForm.getValues(),
      periods,
    };

    mutate(body);
  };

  const [isPeriodErrorModalVisible, setPeriodErrorModalVisible] = usePollPeriodErrorModal(error);

  const onSubmitDraft = () => {
    const body = {
      ...basicForm.getValues(),
      ...subElectionsForm.getValues(),
      ...votersForm.getValues(),
      ...additionalForm.getValues(),
    };

    writeDraftMutate(
      { kind: 'election', form: body },
      {
        onSuccess: () => {
          setDraftWriteConfirmModalVisible(false);
        },
      },
    );
  };

  useEffect(() => {
    if (isApplyPollSuccess) {
      setCompleteModalVisible(true);
    }
  }, [isApplyPollSuccess]);

  const hasFormChanged = useElectionFormChanged({
    basicForm,
    subElectionsForm,
    votersForm,
    additionalForm,
  });

  useBlocker(({ currentLocation, nextLocation }) => {
    // NOTE: 첫 페이지로 이 페이지가 열린 경우, useBlocker가 사라지지 않는 버그가 있어서 예외처리합니다.
    if (!currentLocation.pathname.includes('/poll/election/application')) {
      return false;
    }

    if (!nextLocation.pathname.includes('/poll/election/application')) {
      if (!leaveModal.isVisible && hasFormChanged && !isSuccess && !isLoading) {
        setLeaveModal({
          isVisible: true,
          nextUrl: nextLocation.pathname,
        });
        return true;
      }
    }

    return false;
  });

  return (
    <ApplicationLayout<PollFormStep>
      items={[
        {
          value: 0,
          title: t('admin:pages.poll.election.stages.0'),
          active: isBasicFormActive,
          selected: step === 0,
        },
        {
          value: 1,
          title: t('admin:pages.poll.election.stages.1'),
          active: isSubElectionsFormActive,
          selected: step === 1,
          disabled: !isBasicFormActive,
        },
        {
          value: 2,
          title: t('admin:pages.poll.election.stages.2'),
          active: isVotersFormActive,
          selected: step === 2,
          disabled: !(isBasicFormActive && isSubElectionsFormActive),
        },
        {
          value: 3,
          title: t('admin:pages.poll.election.stages.3'),
          active: isAdditionalFormActive,
          selected: step === 3,
          disabled: !isAdditionalFormActive,
        },
      ]}
      title={t('admin:pages.poll.election.title.application')}
      onClose={() =>
        navigate('/poll/init?type=Election', { replace: true, unstable_viewTransition: true })
      }
      onSelect={(selectedStep) => {
        if (
          (step === 0 && !basicForm.formState.isValid) ||
          (step === 1 && !subElectionsForm.formState.isValid) ||
          (step === 2 && !votersForm.formState.isValid) ||
          (step === 3 && !additionalForm.formState.isValid)
        ) {
          return;
        }

        navigate(`${pathname}${search}#${selectedStep + 1}`, {
          unstable_viewTransition: true,
          replace: true,
        });
      }}
      rightComponent={
        <>
          <PlanField
            kind='election'
            selectedPlan={basicForm.watch('subscriptionType')}
            onSelect={(plan) => basicForm.setValue('subscriptionType', plan)}
          />

          <DraftButton
            onSaveButtonClick={() => {
              if (basicForm.getValues('subscriptionType') !== Plan.Free && isPaymentFailed) {
                setToast({
                  isVisible: true,
                  type: 'error',
                  message: t('admin:pages.poll.election.errorMessages.payment'),
                });
                return;
              }

              setDraftWriteConfirmModalVisible(true);
            }}
            onDraftModalButtonClick={() => setDraftModalVisible(true)}
          />
        </>
      }
    >
      <ElectionForm
        step={step}
        isLoading={isLoading}
        basicForm={basicForm}
        subElectionsForm={subElectionsForm}
        votersForm={votersForm}
        additionalForm={additionalForm}
        onSubmit={onSubmit}
      />

      <CompleteModal
        type='application'
        kind='election'
        isVisible={isCompleteModalVisible}
        onClose={() => {
          setCompleteModalVisible(false);
          navigate(`/poll/election/${data?._id}`, {
            unstable_viewTransition: true,
          });
        }}
      />

      <DraftWriteConfirmModal
        isVisible={isDraftWriteConfirmModalVisible}
        onClose={() => setDraftWriteConfirmModalVisible(false)}
        onSubmit={() => onSubmitDraft()}
      />

      <LeaveModal
        isVisible={leaveModal.isVisible}
        onClose={() =>
          setLeaveModal({
            ...leaveModal,
            isVisible: false,
          })
        }
        onLeave={() => {
          navigate(leaveModal.nextUrl || '/', {
            replace: true,
            unstable_viewTransition: true,
          });
          setLeaveModal({
            ...leaveModal,
            nextUrl: '',
          });
        }}
      />

      <PeriodErrorModal
        type='election'
        isVisible={isPeriodErrorModalVisible}
        periods={variables?.periods || []}
        onClose={() => setPeriodErrorModalVisible(false)}
        onSubmit={onSubmitWithAdjustedPeriods}
      />

      <DraftModal isVisible={isDraftModalVisible} onClose={() => setDraftModalVisible(false)} />
    </ApplicationLayout>
  );
}

export default ElectionApplication;
