import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const initialState = {
  url: '',
  timestamp: 0,
  afterSpaceSet: true,
};

const { persistAtom } = recoilPersist({
  key: 'persist-redirect-url',
});

export const redirectUrl = atom<{
  url: string;
  timestamp: number;
  afterSpaceSet: boolean;
}>({
  key: 'redirect-url',
  default: initialState,
  effects_UNSTABLE: [persistAtom],
});
