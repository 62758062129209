import { useSetRecoilState } from 'recoil';
import { Button, Input, Modal, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { t } from 'i18next';

import { toast } from 'states/toast';

import './index.scss';

type Props = {
  isVisible: boolean;
  link: string;
  onClose: () => void;
};

function InviteModal({ isVisible, link, onClose }: Props) {
  const setToast = useSetRecoilState(toast);

  const onLinkButtonClick = () => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setToast({
          isVisible: true,
          type: 'success',
          message: t('admin:components.features.modal.invite.messages.success'),
        });
      })
      .catch(() => {
        setToast({
          isVisible: true,
          type: 'error',
          message: t('admin:components.features.modal.invite.messages.failure'),
        });
      });
  };
  return (
    <Modal size='md' isVisible={isVisible} onClose={onClose} className='invite-modal'>
      <ModalHeader title={t('admin:components.features.modal.invite.title')} onClose={onClose} />

      <ModalBody>
        <div className='invite-modal__row'>
          <Input disabled value={link} />

          <Button onClick={() => onLinkButtonClick()}>
            {t('admin:components.features.modal.invite.button')}
          </Button>
        </div>

        <div className='invite-modal__description'>
          <Text type='b3' fontWeight={400} color='gray-400'>
            {t('admin:components.features.modal.invite.description.0')}
            <br />
            {t('admin:components.features.modal.invite.description.1')}
          </Text>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default InviteModal;
