import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Spinner } from '@pickme/ui';
import { Input, Button } from '@pickme/design-system';
import { t } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { InferType, object, string } from 'yup';

import { useGetEmailDuplication } from 'query-hooks/auth';
import { useSendOTPByEmail } from 'query-hooks/otp';

import { emailValidator } from 'functions/validator/auth';

import { submitButton } from 'pages/auth/common.css';
import { fields } from './index.css';

type Props = {
  onEmailSend: (email: string) => void;
};

function SignUpEmailForm({ onEmailSend }: Props) {
  const schema = object().shape({
    email: string()
      .required(
        t('admin:components.features.auth.form.signup.forms.email.errorMessages.wrongEmail'),
      )
      .test(
        'format',
        t('admin:components.features.auth.form.signup.forms.email.errorMessages.wrongEmail'),
        (value) => emailValidator(value),
      )
      .test(
        'check-duplication',
        t('admin:components.features.auth.form.signup.forms.email.errorMessages.alreadySigned'),
        async (value) => {
          if (!value) {
            return false;
          }

          const { data } = await refetch();
          if (data?.email === value && data.isDuplicated) {
            return false;
          }

          return true;
        },
      ),
  });

  type EmailForm = InferType<typeof schema>;
  const methods = useForm<EmailForm>({
    mode: 'onTouched',
    defaultValues: { email: '' },
    resolver: yupResolver(schema),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = methods;

  const enteredEmail = watch('email');
  const { refetch } = useGetEmailDuplication(enteredEmail);
  const { mutate, isLoading, isSuccess, reset } = useSendOTPByEmail();

  const onSubmit: SubmitHandler<{ email: string }> = (data) => {
    if (isLoading) {
      return;
    }

    mutate(data.email);
  };

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      onEmailSend(enteredEmail);
    }
  }, [isSuccess]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={fields}>
          <Input
            width='100%'
            size='lg'
            placeholder='이메일을 입력해주세요'
            type='email'
            id='email'
            {...register('email', {
              setValueAs: (value) => value?.trim().toLowerCase(),
            })}
            isValid={!methods.formState.errors.email}
            errorMessage={errors.email?.message}
          />
        </div>

        <Button
          fullWidth
          size='xl'
          variant='primary'
          type='submit'
          disabled={!isValid || isLoading}
          className={submitButton}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            t('admin:components.features.auth.form.signup.forms.email.buttons.submit')
          )}
        </Button>
      </form>
    </FormProvider>
  );
}

export default SignUpEmailForm;
