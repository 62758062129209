export default {
  title: '결제 내역',
  search: {
    result: {
      blank: '표시할 내용이 없습니다.',
    },
  },
  table: {
    header: {
      name: '상품 이름',
      date: '결제 일시',
      method: '결제 방법',
      amount: '결제 금액',
      detail: '내역',
      reserved: {
        name: '투표 제목',
        date: '전송 예정 일시',
        point: '예상 포인트',
        detail: '내역',
      },
    },
    body: {
      labels: {
        detail: '상세 내역',
      },
    },
  },
};
