import clsx from 'clsx';
import { MouseEventHandler } from 'react';
import { colors } from '@pickme/design-system';

import CloseIcon from './CloseIcon';

import { button } from './index.css';

type Props = {
  className?: string;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

function ImageCloseButton({ className, disabled, onClick }: Props) {
  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <button className={clsx(button, className)} type='button' onClick={onClick} disabled={disabled}>
      <CloseIcon color={colors['gray-500']} />
    </button>
  );
}

export default ImageCloseButton;
