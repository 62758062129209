export default {
  title: '비밀번호',
  buttons: {
    edit: '재설정하기',
  },
  modal: {
    setup: {
      title: '비밀번호 변경',
      form: {
        fields: {
          password: {
            label: '현재 비밀번호',
            placeholder: '현재 비밀번호를 입력해주세요',
          },
          newPassword: {
            label: '새 비밀번호',
            placeholder: '새 비밀번호를 입력해주세요',
          },
          newPasswordChecker: {
            label: '새 비밀번호 확인',
            placeholder: '새 비밀번호를 재입력해주세요',
          },
        },
        errorMessages: {
          newPassword: '8~20자리의 영문 + 숫자 + 특수문자의 조합으로 입력 가능합니다',
          newPasswordChecker: '동일한 비밀번호를 한번 더 입력해주세요',
        },
      },
      actions: {
        cancel: '취소',
        submit: '저장하기',
      },
    },
  },
};
