export default {
  title: '이름',
  buttons: {
    edit: '변경하기',
    cancel: '변경취소',
  },
  form: {
    fields: {
      name: {
        label: '새로운 이름',
        placeholder: '새로운 이름을 입력해주세요',
      },
    },
    buttons: {
      submit: '저장하기',
    },
    errorMessages: {
      blank: '이름을 입력해주세요',
      nameFormat: '이름은 한글로 입력해주세요',
    },
  },
};
