import moment from 'moment-timezone';
import { Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { Plan } from '@pickme/core';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

import { useReplacePlan } from 'query-hooks/payment';

import { useGetFeatures } from 'hooks/useGetFeatures';

type Props = {
  isVisible: boolean;
  dueDate: string;
  onClose: () => void;
};

function DiscardSubscriptionModal({ isVisible, dueDate, onClose }: Props) {
  const { mutate, isLoading } = useReplacePlan();
  const { isPaymentFailed } = useGetFeatures();

  const onSubmit = () => {
    mutate(
      { plan: Plan.Free },
      {
        onSuccess: () => onClose(),
      },
    );
  };

  return (
    <Modal size='md' isVisible={isVisible} onClose={onClose}>
      <ModalHeader
        title={t('admin:components.features.payment.modal.discardSubscription.title')}
        onClose={onClose}
      />
      <ModalBody>
        <Text type='b3' fontWeight={400} color='gray-400'>
          {isPaymentFailed
            ? t('admin:components.features.payment.modal.discardSubscription.message.immediate')
            : t('admin:components.features.payment.modal.discardSubscription.message.period.text', {
                date: moment(dueDate)
                  .tz('Asia/Seoul')
                  .format(
                    t(
                      'admin:components.features.payment.modal.discardSubscription.message.period.format',
                    ),
                  ),
              })}
        </Text>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t('admin:components.features.payment.modal.discardSubscription.buttons.cancel'),
            onClick: onClose,
            variant: 'gray',
          },
          {
            text: isLoading ? (
              <Spinner />
            ) : (
              t('admin:components.features.payment.modal.discardSubscription.buttons.submit')
            ),
            disabled: isLoading,
            onClick: onSubmit,
            variant: 'error',
          },
        ]}
      />
    </Modal>
  );
}

export default DiscardSubscriptionModal;
