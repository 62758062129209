/* eslint-disable react/prop-types */
import { forwardRef, Ref } from 'react';
import axios from 'axios';
import ReactQuill, { Quill } from 'react-quill';
import { Text } from '@pickme/ui';
// @ts-ignore
import ImageResize from 'quill-image-resize';
// @ts-ignore
import ImageUploader from 'quill-image-uploader';

import 'react-quill/dist/quill.snow.css';
import './index.scss';

Quill.register('modules/ImageResize', ImageResize);
Quill.register('modules/imageUploader', ImageUploader);

const toolbar = [
  [{ header: [1, 2, false] }],
  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  ['image', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
];

const modules = {
  toolbar,
  ImageResize: {
    parchment: Quill.import('parchment'),
  },
  imageUploader: {
    upload: (file: File) =>
      new Promise((resolve, reject) => {
        const form = new FormData();
        form.append('file', file, encodeURIComponent(file.name));

        axios({
          method: 'POST',
          url: `${
            import.meta.env.MODE === 'dev.local' ? '' : import.meta.env.VITE_API_HOST_URL
          }/api/file`,
          data: form,
        })
          .then((response) =>
            resolve(`${import.meta.env.VITE_API_HOST_URL}/api/file/${response.data.fileOid}`),
          )
          .catch((error) => reject(error));
      }),
  },
};

function MarkUpEditor(
  { maxLength, ...props }: { maxLength: number; name?: string; value?: string },
  ref: Ref<any>,
) {
  return (
    <div className='application-field-markup'>
      <ReactQuill
        ref={ref}
        data-testid={props.name}
        className='markup-editor'
        modules={modules}
        theme='snow'
        {...props}
      />
      <Text className='application-field-markup__description' size='sm3' fontWeight={300}>
        {/* TODO: 글자 수 계산 로직 수정. */}
        {props?.value?.replace(/<[^>]*>?/g, '')?.length || 0}/{maxLength}
      </Text>
    </div>
  );
}

export default forwardRef(MarkUpEditor);
