import { Text } from '@pickme/design-system';

import { container } from './index.css';

type Props = {
  title: string;
  isRequired?: boolean;
};

function FieldTitle({ title, isRequired }: Props) {
  return (
    <div className={container}>
      <Text size={18} fontWeight={600} color='gray-800'>
        {title}
      </Text>

      {isRequired && (
        <Text size={18} fontWeight={600} color='red-500'>
          *
        </Text>
      )}
    </div>
  );
}

export default FieldTitle;
