import { useNavigate } from 'react-router-dom';
import { Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { t } from 'i18next';

type Props = {
  isVisible: boolean;
  onClose: () => void;
};

function BalanceModal({ isVisible, onClose }: Props) {
  const navigate = useNavigate();

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <ModalHeader
        title={t('admin:components.features.notification.modal.balance.title')}
        onClose={onClose}
      />

      <ModalBody>
        <Text type='b3' fontWeight={400} color='gray-400'>
          {t('admin:components.features.notification.modal.balance.message')}
        </Text>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t('admin:components.features.notification.modal.balance.buttons.cancel'),
            type: 'button',
            variant: 'gray',
            onClick: () => onClose(),
          },
          {
            text: t('admin:components.features.notification.modal.balance.buttons.submit'),
            type: 'submit',
            variant: 'primary',
            onClick: () => {
              onClose();
              navigate('/service/notification/point');
            },
          },
        ]}
      />
    </Modal>
  );
}

export default BalanceModal;
