import { useRef, useState } from 'react';
import { Text, PlainButton, useClickOutside } from '@pickme/design-system';

import ArrowIcon from 'resources/icons/payment/arrow-down.svg';

import { detail, point } from './index.css';

type Props = {
  total: string;
  charge: string;
  save: string;
};

function PointSummary({ total, charge, save }: Props) {
  const [isDetailOpened, setIsDetailOpened] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const toggleDetail = () => setIsDetailOpened((prev) => !prev);

  useClickOutside([ref], () => setIsDetailOpened(false));

  return (
    <div className={point.container}>
      <Text size={16} fontWeight={600}>
        보유 포인트
      </Text>

      <div className={point.amount} ref={ref}>
        <Text size={24} fontWeight={600} color='primary-500'>
          {total}
        </Text>

        <PlainButton onClick={toggleDetail}>
          <img src={ArrowIcon} alt='arrow' className={point.arrow({ rotate: isDetailOpened })} />
        </PlainButton>

        {isDetailOpened && (
          <div className={detail.container}>
            <div className={detail.item}>
              <Text size={14} color='gray-600'>
                충전 포인트
              </Text>
              <Text size={14} color='gray-600'>
                {charge}
              </Text>
            </div>

            <div className={detail.item}>
              <Text size={14} color='gray-600'>
                적립 포인트
              </Text>
              <Text size={14} color='gray-600'>
                {save}
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PointSummary;
