import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ReactHtmlParser from 'html-react-parser';
import { Input, Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { YouTubeURLParser } from '@pickme/core';
import { t } from 'i18next';

import './index.scss';

type Props = {
  isVisible: boolean;
  videoLink: string | null;
  onClose: () => void;
  onSubmit: (link: string) => void;
};

function VideoLinkModal({ isVisible, onClose, videoLink, onSubmit }: Props) {
  const methods = useForm<{ videoLink: string }>({
    defaultValues: {
      videoLink: '',
    },
  });

  const watchedVideoLink = methods.watch('videoLink');

  useEffect(() => {
    if (isVisible && videoLink) {
      methods.reset({
        videoLink,
      });
    } else {
      methods.reset({
        videoLink: '',
      });
    }
  }, [isVisible, videoLink]);

  return (
    <Modal className='video-link-modal' size='lg' isVisible={isVisible} onClose={onClose}>
      <ModalHeader
        title={t('admin:components.features.application.form.question.modals.video.title')}
        onClose={onClose}
      />

      <ModalBody>
        <div className='video-link-modal__body'>
          {!!watchedVideoLink &&
            ReactHtmlParser(
              new YouTubeURLParser(watchedVideoLink).getIframe({
                allowFullScreen: true,
                width: 455,
                height: 300,
              }) || '',
            )}
          <div className='video-link-modal__field'>
            <Text type='b3' fontWeight={500} color='gray-700'>
              {t('admin:components.features.application.form.question.modals.video.fields.link')}
            </Text>

            <Input
              placeholder='https://www.youtube.com/...'
              {...methods.register('videoLink', {
                validate: (value) => {
                  if (!value) {
                    return true;
                  }

                  const parser = new YouTubeURLParser(value);
                  return parser.isValid();
                },
              })}
            />
          </div>
        </div>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t(
              'admin:components.features.application.form.question.modals.video.actions.submit',
            ),
            type: 'button',
            onClick: () => onSubmit(methods.getValues('videoLink')),
            disabled: !methods.formState.isValid,
          },
        ]}
      />
    </Modal>
  );
}

export default VideoLinkModal;
