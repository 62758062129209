import dayjs from 'dayjs';
import { range } from 'lodash-es';
import { ComponentProps, Ref, forwardRef } from 'react';
import { Select } from '@pickme/design-system';

import CalenderIcon from 'resources/icons/application/calendar.svg';

import { select } from '../common.css';

type Props = Omit<ComponentProps<typeof Select>, 'items'>;

function MonthSelect(props: Props, ref: Ref<HTMLInputElement>) {
  return (
    <Select
      {...props}
      placeholder='월 선택'
      items={MONTH_SELECT_ITEMS}
      ref={ref}
      triggerIcon={CalenderIcon}
      className={select}
    />
  );
}

export default forwardRef(MonthSelect);

const createMonthSelectItems = () => {
  const thisMonth = dayjs().month() + 1;
  const months = range(1, thisMonth + 1)
    .map((month) => ({
      label: `${month}월`,
      value: String(month),
    }))
    .reverse();

  const monthSelectItems = [{ label: '전체 날짜', value: 'all' }, ...months];

  return monthSelectItems;
};

const MONTH_SELECT_ITEMS = createMonthSelectItems();
