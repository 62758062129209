import { AxiosError } from 'axios';
import { useSetRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  createSendAlimTalkRequest,
  createReserveAlimTalkRequest,
  createReserveLmsRequest,
  createSendLmsRequest,
  createReserveEmailRequest,
  createSendEmailRequest,
  createGetMessagesRequest,
  createCancelReservationRequest,
} from 'apis/message';

import { getDateTimeString } from 'functions/date-time';

import { toast } from 'states/toast';

import {
  AlimTalkForm,
  AlimTalkSendRequestBody,
  EmailForm,
  EmailSendRequestBody,
  LmsForm,
  LmsSendRequestBody,
  MessagingSearchForm,
  MessagingSearchQuery,
  MessagingType,
} from 'types/message';
import { Period } from 'types/common';
import dayjs from 'dayjs';

export const useSendAlimTalk = () => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ form, type }: { form: AlimTalkForm; type: MessagingType }) => {
      const parsedForm: AlimTalkSendRequestBody = {
        recipientType: form.recipientType,
        templateType: form.templateType,
        pollIds: form.pollIds,
      };
      const isReservation = type === MessagingType.Reserve;

      if (isReservation && form.dateTime) {
        parsedForm.date = getDateTimeString(form.dateTime);
      }

      const alimTalkRequest = isReservation
        ? createReserveAlimTalkRequest
        : createSendAlimTalkRequest;

      await alimTalkRequest(parsedForm);

      return isReservation;
    },
    {
      onSuccess: (isReservation) => {
        queryClient.invalidateQueries('notifications');
        queryClient.invalidateQueries('notification');
        queryClient.invalidateQueries('usages');
        queryClient.invalidateQueries('account');

        const message = isReservation
          ? '알림톡 전송이 예약되었습니다.'
          : '알림톡이 전송되었습니다.';

        setToast({
          isVisible: true,
          type: 'success',
          message,
        });

        const redirectTab = isReservation ? 'reserved' : 'sent';
        navigate(`/service/notification/history/${redirectTab}`, { replace: true });
      },
      onError: (error: AxiosError) => {
        if (error.code === 'MESSAGE-004' || error.code === 'WALLET-001') {
          return;
        }

        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });
      },
    },
  );
};

export const useSendLms = () => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ form, type }: { form: LmsForm; type: MessagingType }) => {
      const parsedForm: LmsSendRequestBody = {
        recipientType: form.recipientType,
        pollIds: form.pollIds,
        template: form.template,
        styledTemplate: form.styledTemplate,
        subject: form.subject,
      };
      const isReservation = type === MessagingType.Reserve;

      if (isReservation && form.dateTime) {
        parsedForm.date = getDateTimeString(form.dateTime);
      }

      const LmsRequest = isReservation ? createReserveLmsRequest : createSendLmsRequest;

      await LmsRequest(parsedForm);

      return isReservation;
    },
    {
      onSuccess: (isReservation) => {
        queryClient.invalidateQueries('notifications');
        queryClient.invalidateQueries('notification');
        queryClient.invalidateQueries('usages');
        queryClient.invalidateQueries('account');

        const message = isReservation ? '문자 전송이 예약되었습니다.' : '문자가 전송되었습니다.';

        setToast({
          isVisible: true,
          type: 'success',
          message,
        });

        const redirectTab = isReservation ? 'reserved' : 'sent';
        navigate(`/service/notification/history/${redirectTab}`, { replace: true });
      },
      onError: (error: AxiosError) => {
        if (error.code === 'MESSAGE-004' || error.code === 'WALLET-001') {
          return;
        }

        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });
      },
    },
  );
};

export const useSendEmail = () => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ form, type }: { form: EmailForm; type: MessagingType }) => {
      const parsedForm: EmailSendRequestBody = {
        recipientType: form.recipientType,
        pollIds: form.pollIds,
        template: form.template,
        subject: form.subject,
      };
      const isReservation = type === MessagingType.Reserve;

      if (isReservation && form.dateTime) {
        parsedForm.date = getDateTimeString(form.dateTime);
      }

      const EmailRequest = isReservation ? createReserveEmailRequest : createSendEmailRequest;

      await EmailRequest(parsedForm);

      return isReservation;
    },
    {
      onSuccess: (isReservation) => {
        queryClient.invalidateQueries('notifications');
        queryClient.invalidateQueries('notification');
        queryClient.invalidateQueries('usages');
        queryClient.invalidateQueries('account');

        const message = isReservation
          ? '이메일 전송이 예약되었습니다.'
          : '이메일이 전송되었습니다.';

        setToast({
          isVisible: true,
          type: 'success',
          message,
        });

        const redirectTab = isReservation ? 'reserved' : 'sent';
        navigate(`/service/notification/history/${redirectTab}`, { replace: true });
      },
      onError: (error: AxiosError) => {
        if (error.code === 'MESSAGE-004' || error.code === 'WALLET-001') {
          return;
        }

        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });
      },
    },
  );
};

export const useGetMessages = (form: MessagingSearchForm) => {
  const query: MessagingSearchQuery = {
    page: form.page,
    status: form.status,
  };

  if (form.month !== 'all') {
    const month = Number(form.month) - 1;
    const period: Period = {
      start: dayjs().month(month).startOf('M').format('YYYY-MM-DD'),
      end: dayjs().month(month).endOf('M').format('YYYY-MM-DD'),
    };

    query.period = JSON.stringify(period);
  }

  if (form.methodType !== 'all') {
    query.methodType = form.methodType;
  }

  return useQuery(
    ['notifications', query],
    async () => {
      const { data } = await createGetMessagesRequest(query);

      return data;
    },
    {
      staleTime: 30 * 1000,
      cacheTime: 30 * 1000,
    },
  );
};

export const useCancelReservation = () => {
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      const { data } = await createCancelReservationRequest(id);

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('notifications');
        queryClient.invalidateQueries('notification');
        queryClient.invalidateQueries('usages');
        queryClient.invalidateQueries('account');

        setToast({
          isVisible: true,
          type: 'success',
          message: '알림톡 예약이 성공적으로 취소되었습니다.',
        });
      },
    },
  );
};
