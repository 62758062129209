/* eslint-disable jsx-a11y/control-has-associated-label */
import { useSpring, animated } from 'react-spring';
import { NavLink } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useEffect } from 'react';

import PollViewer from 'components/features/poll/PollViewer';
import PollViewerButtonGroups from 'components/features/poll/PollViewerButtonGroups';

import { useGetPoll } from 'query-hooks/poll';

import { pollViewer as pollViewerState } from 'states/poll-viewer';

import FullIcon from 'resources/icons/poll-viewer/full.png';
import CloseIcon from 'resources/icons/poll-viewer/close.png';

import './index.scss';

function PollViewerLayout() {
  const [pollViewer, setPollViewer] = useRecoilState(pollViewerState);
  const { data: poll } = useGetPoll(pollViewer.id);
  const kind = poll?.kind;

  const slide = useSpring({
    transform: pollViewer.isVisible ? 'translateX(0%)' : 'translateX(100%)',
    opacity: pollViewer.isVisible ? 1 : 0,
    config: { duration: 300, opacity: { duration: 300 } },
  });

  const onClose = () => {
    setPollViewer({
      isVisible: false,
      id: '',
    });
  };

  useEffect(() => {
    document.body.style.overflowY = pollViewer.isVisible ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [pollViewer.isVisible]);

  return (
    <>
      {pollViewer.isVisible && (
        <div
          className='poll-viewer-layout__backdrop'
          tabIndex={0}
          onClick={() => onClose()}
          onKeyDown={() => onClose()}
          role='button'
        />
      )}

      <animated.div style={slide} className='poll-viewer-layout'>
        {/* TODO: Consider move header */}
        <div className='poll-viewer-layout__header'>
          <div className='poll-viewer-layout__header__buttons'>
            <button
              className='poll-viewer-layout__header__button'
              type='button'
              onClick={() => onClose()}
            >
              <img src={CloseIcon} alt='CloseIcon' />
            </button>

            <NavLink to={`/poll/${kind?.toLowerCase()}/${pollViewer.id}`} unstable_viewTransition>
              <button
                className='poll-viewer-layout__header__button'
                type='button'
                onClick={() => {
                  setPollViewer({
                    isVisible: false,
                    id: '',
                  });
                }}
              >
                <img src={FullIcon} alt='FullIcon' />
              </button>
            </NavLink>
          </div>

          <PollViewerButtonGroups kind={kind} id={pollViewer.id} onClose={onClose} />
        </div>

        <div className='poll-viewer-layout__body'>
          <PollViewer id={pollViewer.id} isModal />
        </div>
      </animated.div>
    </>
  );
}

export default PollViewerLayout;
