import { Controller, useFormContext } from 'react-hook-form';
import { t } from 'i18next';

import BoxButton from 'components/features/application/form/Basic/Public/BoxButton';

import PublicIcon from 'resources/icons/application/public.svg';
import PrivateIcon from 'resources/icons/application/private.svg';

import { BasicFormBody } from 'types/application';

import { container } from './index.css';

type Props = {
  isEdit?: boolean;
};

function PublicField({ isEdit }: Props) {
  const { control } = useFormContext<BasicFormBody>();

  return (
    <Controller
      control={control}
      name='isPublic'
      disabled={isEdit}
      render={({ field }) => (
        <div className={container}>
          <BoxButton
            item={{
              icon: PublicIcon,
              title: t(
                'admin:components.features.application.form.basic.fields.public.items.open.title',
              ),
              showBetaIcon: true,
              content: t(
                'admin:components.features.application.form.basic.fields.public.items.open.description',
              ),
            }}
            selected={field.value}
            onSelect={() => field.onChange(true)}
            disabled={field.disabled}
          />

          <BoxButton
            item={{
              icon: PrivateIcon,
              title: t(
                'admin:components.features.application.form.basic.fields.public.items.close.title',
              ),
              content: t(
                'admin:components.features.application.form.basic.fields.public.items.close.description',
              ),
            }}
            selected={!field.value}
            onSelect={() => field.onChange(false)}
            disabled={field.disabled}
          />
        </div>
      )}
    />
  );
}

export default PublicField;
