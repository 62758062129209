import { Text } from '@pickme/ui';
import { YouTubeURLParser } from '@pickme/core';
import { range } from 'lodash-es';
import { t } from 'i18next';
import ReactHtmlParser from 'html-react-parser';

import SubPollCard from 'components/features/poll/PollDetail/SubPollCard';

import { makeQuestionBadges } from 'functions/poll';

import { QUESTION_KIND_BADGES } from 'constants/poll';

import { Question } from 'types/poll';

import './index.scss';

type Props = {
  question: Question;
};

function QuestionCard({ question }: Props) {
  const questionBadges = makeQuestionBadges(question);

  return (
    <SubPollCard
      kindBadge={{
        variant: QUESTION_KIND_BADGES[question?.kind].variant,
        text: QUESTION_KIND_BADGES[question?.kind].text,
      }}
      title={question?.title}
      badges={questionBadges}
    >
      {question.videoUrl?.youtube && (
        <div className='poll-detail-question-card__image-container'>
          {ReactHtmlParser(
            new YouTubeURLParser(question.videoUrl?.youtube).getIframe({
              allowFullScreen: true,
              width: 455,
              height: 300,
            }) || '',
          )}
        </div>
      )}

      {question.fileUrl && (
        <div className='poll-detail-question-card__image-container'>
          <img className='poll-detail-question-card__image' src={question.fileUrl} alt='question' />
        </div>
      )}

      <div className='poll-detail-question-card__selections'>
        {question.kind === 'TextQuestion' && (
          <div className='poll-detail-question-card__selection'>
            <Text type='b3' fontWeight={400}>
              {t('admin:components.features.poll.pollDetail.questionCard.textQuestion')}
            </Text>
          </div>
        )}

        {question.kind === 'ScaleQuestion' && (
          <div className='poll-detail-question-card__scale'>
            <div className='poll-detail-question-card__scale__values'>
              {range(question.min?.value ?? 1, question.max?.value ?? 10).map((point) => (
                <Text type='b3' fontWeight={400}>
                  {point}
                </Text>
              ))}
            </div>
            <div className='poll-detail-question-card__scale__label'>
              <Text type='b3' fontWeight={400}>
                {question.min?.description || ''}
              </Text>

              <Text type='b3' fontWeight={400}>
                {question.max?.description || ''}
              </Text>
            </div>
          </div>
        )}

        {(question.kind === 'RankQuestion' || question.kind === 'ScoreQuestion') &&
          question?.subQuestions?.map((subQuestion) => (
            <>
              <div className='poll-detail-question-card__selection'>
                <Text type='b3' fontWeight={400}>
                  {subQuestion.description ||
                    t('admin:components.features.poll.pollDetail.questionCard.blank')}
                </Text>
              </div>

              {subQuestion?.videoUrl?.youtube && (
                <div className='poll-detail-question-card__image-container'>
                  {ReactHtmlParser(
                    new YouTubeURLParser(subQuestion.videoUrl.youtube).getIframe({
                      allowFullScreen: true,
                      width: 455,
                      height: 300,
                    }) || '',
                  )}
                </div>
              )}

              {subQuestion.fileUrl && (
                <img
                  className='poll-detail-question-card__image'
                  src={subQuestion.fileUrl}
                  alt='question'
                />
              )}
            </>
          ))}

        {(question.kind === 'RadioQuestion' || question.kind === 'CheckboxQuestion') &&
          question?.selections?.map((selection) => (
            <>
              <div className='poll-detail-question-card__selection'>
                <Text type='b3' fontWeight={400}>
                  {selection.description ||
                    t('admin:components.features.poll.pollDetail.questionCard.blank')}
                </Text>
              </div>

              {selection?.videoUrl?.youtube && (
                <div className='poll-detail-question-card__image-container'>
                  {ReactHtmlParser(
                    new YouTubeURLParser(selection.videoUrl.youtube).getIframe({
                      allowFullScreen: true,
                      width: 455,
                      height: 300,
                    }) || '',
                  )}
                </div>
              )}

              {selection.fileUrl && (
                <img
                  className='poll-detail-question-card__image'
                  src={selection.fileUrl}
                  alt='question'
                />
              )}
            </>
          ))}
      </div>
    </SubPollCard>
  );
}

export default QuestionCard;
