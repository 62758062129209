import dayjs from 'dayjs';
import { MessagingTransaction, TransferTransaction } from '@pickme/core';
import { Text, Table, Pagination } from '@pickme/design-system';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

import { useGetUsages } from 'query-hooks/payment';

import { useQueryString } from 'hooks/useQueryString';

import { parseUrlWithPage } from 'functions/url';

import { tableRow } from '../index.css';

function PointUsage() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const { page } = useQueryString({ page: 1 });
  const { data: usageData, isLoading } = useGetUsages(page);

  const onMovePage = (targetPage: number) => {
    const parsedPage = parseUrlWithPage(`${pathname}${search}`, targetPage);
    navigate(parsedPage);
  };

  return (
    <>
      <Table>
        <colgroup>
          <col />
          <col width={228} />
          <col width={228} />
          <col width={228} />
        </colgroup>

        <Table.Header>
          <Table.Tr>
            <Table.Th id='usage-date' textAlign='start'>
              일시
            </Table.Th>
            <Table.Th id='usage-category' textAlign='start'>
              분류
            </Table.Th>
            <Table.Th id='usage-change' textAlign='start'>
              포인트 변동
            </Table.Th>
            <Table.Th id='usage-point' textAlign='start'>
              변동 후 포인트
            </Table.Th>
          </Table.Tr>
        </Table.Header>

        <Table.Body>
          {isLoading && (
            <Table.Tr>
              <Table.Td colSpan={4}>
                <Spinner />
              </Table.Td>
            </Table.Tr>
          )}

          {!isLoading &&
            (usageData ? (
              usageData.transactions.map((usage) => (
                <Table.Tr key={usage._id} className={tableRow}>
                  <Table.Td headers='usage-date' textAlign='start'>
                    {dayjs(usage.createdAt).format('YY/MM/DD (dd) HH:mm')}
                  </Table.Td>

                  <Table.Td headers='usage-category' textAlign='start'>
                    {(usage instanceof TransferTransaction &&
                      t('admin:pages.payment.point.labels.usage.transfer')) ||
                      (usage instanceof MessagingTransaction && usage.printType())}
                  </Table.Td>

                  <Table.Td headers='usage-change' textAlign='start'>
                    <Text
                      size={14}
                      fontWeight={400}
                      color={
                        (usage.amount.value > 0 && 'primary-700') ||
                        (usage.amount.value < 0 && 'red-700') ||
                        'gray-400'
                      }
                    >
                      {usage.amount.value > 0 ? '+' : ''}
                      {usage.amount.print()}
                    </Text>
                  </Table.Td>

                  <Table.Td headers='usage-point' textAlign='start'>
                    {usage.changedAmount?.print()}
                  </Table.Td>
                </Table.Tr>
              ))
            ) : (
              <Table.Tr>
                <Table.Td colSpan={4}>포인트 사용 내역이 없어요</Table.Td>
              </Table.Tr>
            ))}
        </Table.Body>
      </Table>

      {!isLoading && (
        <Pagination currentPage={page} totalPage={usageData?.endPage || 1} onMove={onMovePage} />
      )}
    </>
  );
}

export default PointUsage;
