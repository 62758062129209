import { useQueryParams } from 'hooks/useQueryParams';

import { MessagingSearchForm, MessagingStatus } from 'types/message';

export const useMessagingSearchParams = (query: MessagingSearchForm) => {
  const parsedQuery = parseQuery(query);
  const [params, setParams] = useQueryParams(parsedQuery);

  const setMessagingSearchParams = (newQuery: MessagingSearchForm) => {
    const newParsedQuery = parseQuery(newQuery);
    setParams(newParsedQuery);
  };

  const parsedParams: MessagingSearchForm = {
    page: Number(params.page),
    methodType: params.methodType,
    month: params.month,
    status: params.status.split(',') as MessagingStatus[],
  };

  return [parsedParams, setMessagingSearchParams] as const;
};

const parseQuery = (query: MessagingSearchForm) => {
  const parsedQuery = {
    page: String(query.page),
    methodType: query.methodType,
    month: query.month,
    status: query.status.join(','),
  };

  return parsedQuery;
};
