import { OAuthState, SocialProvider } from 'types/auth';

export const socialSignIn = (provider: SocialProvider) => {
  const requestUrl = `${import.meta.env.VITE_API_HOST_URL}/api/auth/${provider}`;
  window.location.href = requestUrl;
};

export const socialSignInV2 = (
  provider: SocialProvider,
  { authRedirectUrl, successRedirectUrl, type }: OAuthState,
) => {
  const state = {
    id: `${Date.now()}`,
    authRedirectUrl,
    successRedirectUrl,
    type,
  };
  const encodedState = encodeState(state);

  const requestUrl = `${import.meta.env.VITE_API_HOST_URL}/v2/auth/${provider}?state=${encodedState}`;
  window.location.href = requestUrl;
};

export const encodeState = (state: OAuthState): string => {
  const encodedState = btoa(JSON.stringify(state));
  return encodedState;
};

export const decodeState = (state: string): OAuthState | null => {
  try {
    const decodedState = JSON.parse(atob(state)) as OAuthState;
    return decodedState;
  } catch {
    return null;
  }
};
