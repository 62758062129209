import dayjs from 'dayjs';
import { Button } from '@pickme/design-system';

import MessagingDetailPair from 'components/features/notification-v2/modal/messaging-detail/common/Pair';

import { RECIPIENT_TYPE_LABELS, TEMPLATE_TYPE_LABELS } from 'constants/notification-v2';

import { AlimTalkMessagingDetail, MessagingType, PollMessaging } from 'types/message';

import { pairContainer, reservationCancelButton } from '../../common.css';

type Props = {
  spaceName: string;
  type: MessagingType;
  messaging: PollMessaging;
  onReservationCancel?: () => void;
};

function AlimTalkDetail({ spaceName, type, messaging, onReservationCancel }: Props) {
  const detail = messaging.detail as AlimTalkMessagingDetail;
  const totalSendTryCount =
    detail.sendResult.failed + detail.sendResult.kakao + detail.sendResult.lms;

  return (
    <>
      <div>
        <div className={pairContainer}>
          <MessagingDetailPair title='스페이스 이름'>{spaceName}</MessagingDetailPair>
          <MessagingDetailPair title='전송 대상'>
            {RECIPIENT_TYPE_LABELS[messaging.recipientType]}
          </MessagingDetailPair>
          <MessagingDetailPair title='발신 프로필'>
            {/* NOTE: 채널 정보에 채널 이름 필드가 추가되면 채널 이름을 변경해야 합니다. */}
            {detail.customChannelId ? detail.customChannelId : '픽미 알림톡'}
          </MessagingDetailPair>
          <MessagingDetailPair title='템플릿'>
            {TEMPLATE_TYPE_LABELS[detail.templateType]}
          </MessagingDetailPair>
        </div>

        {type === MessagingType.Send && (
          <div className={pairContainer}>
            <MessagingDetailPair title='전송 일시'>
              {dayjs(messaging.sendRequestDate).format('YY/MM/DD (dd) HH:mm')}
            </MessagingDetailPair>
            <MessagingDetailPair title='전송 시도 건수'>{totalSendTryCount}</MessagingDetailPair>
            <MessagingDetailPair title='LMS 전환 건수'>{detail.sendResult.lms}</MessagingDetailPair>
            <MessagingDetailPair title='전송 실패 건수'>
              {detail.sendResult.failed}
            </MessagingDetailPair>
            <MessagingDetailPair title='사용 포인트'>{messaging.usageAmount}P</MessagingDetailPair>
          </div>
        )}

        {type === MessagingType.Reserve && (
          <div className={pairContainer}>
            <MessagingDetailPair title='전송 예정 일시'>
              {dayjs(messaging.sendRequestDate).format('YY/MM/DD (dd) HH:mm')}
            </MessagingDetailPair>
            <MessagingDetailPair title='사용 예정 포인트'>
              {messaging.usageAmount}P
            </MessagingDetailPair>
          </div>
        )}
      </div>

      {type === MessagingType.Reserve && (
        <Button variant='danger' onClick={onReservationCancel} className={reservationCancelButton}>
          예약취소
        </Button>
      )}
    </>
  );
}

export default AlimTalkDetail;
