import { DraftTemplate, DraftTemplateCategory } from 'types/template';

import Thumbnail1 from 'resources/images/templates/survey/club1.png';

const DRAFT_1 = {
  title: '동호회 하계 MT 사전 설문조사',
  _id: '64993324a5f0ec00830bec46',
  content: {
    name: '동호회 하계 MT 사전 설문조사',
    periods: [
      {
        startDate: '2023-06-26T17:16',
        endDate: '2023-06-30T15:22',
      },
    ],
    description:
      '<p>안녕하세요! 동호회 회원 여러분. 동호회 하계 MT 사전 설문조사 안내드립니다.</p><p>본 설문 결과를 바탕으로 이번 하계 MT를 떠날 예정이니 신중한 선택 부탁드립니다!</p><p>감사합니다.</p>',
    type: 'Survey',
    voterType: 'voterBook',
    hasOpenVotes: false,
    subscriptionType: 'enterprise',
    questions: [
      {
        kind: 'RadioQuestion',
        title: '선호하는 하계 MT 일정을 선택해주세요.',
        description: '',
        isRequired: true,
        selections: [
          {
            description: '7월 3주 토요일 - 일요일',
            number: 1,
            fileUrl: '',
          },
          {
            description: '7월 4주 토요일 - 일요일',
            number: 2,
            fileUrl: '',
          },
          {
            description: '7월 5주 토요일 - 일요일',
            number: 3,
            fileUrl: '',
          },
          {
            number: 4,
            description: '8월 1주 토요일 - 일요일',
            fileUrl: '',
          },
          {
            description: '8월 2주 토요일 - 일요일',
            number: 5,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'RadioQuestion',
        title: '하계 MT로 떠나고 싶은 장소를 선택해주세요.',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '대천',
            fileUrl: '',
          },
          {
            description: '고산',
            number: 2,
            fileUrl: '',
          },
          {
            description: '무주',
            number: 3,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'RankQuestion',
        title: '선호하는 순서대로 식사류를 선택해주세요.',
        description: '',
        isRequired: true,
        selections: [],
        subQuestions: [
          {
            description: '한식',
            fileUrl: '',
          },
          {
            description: '중식',
            fileUrl: '',
          },
          {
            description: '양식',
            fileUrl: '',
          },
          {
            description: '일식',
            fileUrl: '',
          },
          {
            description: '기타',
            fileUrl: '',
          },
        ],
        responseValidation: {
          rankLimit: 5,
        },
        fileUrl: '',
      },
      {
        kind: 'RadioQuestion',
        title: '하계MT 관련 추가 회비는 얼마가 적당한 것 같나요?',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '2만원~4만원',
            fileUrl: '',
          },
          {
            description: '4만원~6만원',
            number: 2,
            fileUrl: '',
          },
          {
            description: '6만원~8만원',
            number: 3,
            fileUrl: '',
          },
          {
            description: '8만원~10만원',
            number: 4,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'TextQuestion',
        title: '하계MT 관련하여 동호회장에게 하고싶은 말을 자유롭게 적어주세요.',
        description: '',
        isRequired: true,
        selections: [],
        subQuestions: [],
        responseValidation: {
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
        id: 'f63d282e-68eb-4fe9-98f6-2e2aac22bc7c',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-06-26T06:41:40.686Z',
  __v: 0,
};

export default [
  {
    title: '동호회 MT 사전 설문조사',
    description: '동호회 MT 사전 설문조사를 위한 템플릿입니다.',
    thumbnail: Thumbnail1,
    draft: DRAFT_1,
    category: DraftTemplateCategory.Club,
    kind: 'Survey',
  },
] as DraftTemplate[];
