import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { Text, Button, PlainButton, Spinner } from '@pickme/ui';
import { t } from 'i18next';

import MemberLevelBadge from 'components/features/badge/MemberLevel';

import { useGetOrganizations } from 'query-hooks/organization';

import { spaceState } from 'states/space';
import { createOrganizationModal } from 'states/modal';

import './Selector.scss';

function SpaceSelectPage() {
  const setCreateOrganizationModal = useSetRecoilState(createOrganizationModal);
  const setActiveSpaceId = useSetRecoilState(spaceState.activeSpaceId);

  const { data: spaces, isLoading } = useGetOrganizations();
  const availableSpaces = spaces?.filter((space) => space.isAvailable());
  const pendingSpaceCounts = spaces?.filter((space) => space.isNotAvailableYet()).length ?? 0;

  useEffect(() => {
    const isOnlySpace = availableSpaces?.length === 1;
    if (isOnlySpace) {
      setActiveSpaceId(availableSpaces[0]._id);
    }
  }, [availableSpaces]);

  return (
    <div className='auth organization-selector'>
      {isLoading ? (
        <div className='organization-selector__spinner-container'>
          <Spinner />
        </div>
      ) : (
        <>
          <Text size='md1'>{t('admin:pages.organization.selector.title')}</Text>

          {pendingSpaceCounts > 0 && (
            <Text type='b3' fontWeight={400} className='organization-selector__description'>
              {t('admin:pages.organization.selector.description.pending', {
                count: pendingSpaceCounts,
              })}
            </Text>
          )}

          {availableSpaces && availableSpaces?.length > 0 && (
            <div className='organization-selector__items'>
              {availableSpaces?.map((space) => (
                <PlainButton
                  className='organization-selector__item'
                  key={space._id}
                  onClick={() => setActiveSpaceId(space._id)}
                >
                  <MemberLevelBadge level={space.memberInfo.level} />

                  <Text className='organization-selector__item__label' type='b2' fontWeight={500}>
                    {space.name}
                  </Text>
                </PlainButton>
              ))}
            </div>
          )}

          <div className='organization-selector__button'>
            <Button
              width='100%'
              size='xl'
              variant='primary'
              type='submit'
              onClick={() => setCreateOrganizationModal({ isVisible: true })}
            >
              {t('admin:pages.organization.selector.button')}
            </Button>
          </div>

          <div className='organization-selector__description organization-selector__description--center'>
            <Text type='b3' fontWeight={400} color='gray-400'>
              {t('admin:pages.organization.selector.description.join')}
            </Text>
          </div>
        </>
      )}
    </div>
  );
}

export default SpaceSelectPage;
