import { useSearchParams, SetURLSearchParams } from 'react-router-dom';

type Params = Record<string, string | string[]>;

type QueryParamsTuple<T> = [T, SetURLSearchParams];

export const useQueryParams = <T extends Params>(params: T): QueryParamsTuple<T> => {
  const [searchParams, setSearchParams] = useSearchParams();

  const parsedParams = parseParamsFromSearchUrl(searchParams, params);

  return [parsedParams, setSearchParams];
};

const parseParamsFromSearchUrl = <T extends Params>(
  searchParams: URLSearchParams,
  baseParams: T,
) => {
  const result: Params = { ...baseParams };

  Object.entries(result).forEach(([key]) => {
    let param;

    if (Array.isArray(baseParams[key])) {
      const arrayParam = searchParams.getAll(key);
      param = arrayParam;
    } else {
      param = searchParams.get(key);
    }

    result[key] = param || baseParams[key];
  });

  return result as T;
};
