export default {
  title: {
    election: {
      cancel: '선거를 무효 처리 하시겠어요?',
      count: '선거를 개표하시겠어요?',
      pause: '선거를 일시정지 하시겠어요?',
      restart: '선거를 재시작 하시겠어요?',
      remove: '정말 삭제하시겠어요?',
      earlyExit: '선거를 즉시 종료하시겠어요?',
    },
    survey: {
      cancel: '설문을 무효 처리 하시겠어요?',
      count: '설문을 개표하시겠어요?',
      earlyCount: '설문을 종료하시겠어요?',
      pause: '설문을 일시정지 하시겠어요?',
      restart: '설문을 재시작 하시겠어요?',
      remove: '정말 삭제하시겠어요?',
      earlyExit: '설문을 즉시 종료하시겠어요?',
    },
  },
  description: {
    election: {
      cancel: '무효 처리한 선거는 되돌릴 수 없어요.',
      count: '선거를 종료하고 개표하면 이전 상태로 돌아갈 수 없어요.',
      pause: '선거를 일시 정지하면 유권자는 투표에 참여할 수 없어요.',
      restart: '선거를 재시작하면 유권자가 투표에 참여할 수 있어요.',
      remove: '삭제하면 데이터를 복구할 수 없어요.',
      earlyExit: '선거를 즉시 종료하면 유권자가 투표에 참여할 수 없어요.',
    },
    survey: {
      cancel: '무효 처리한 설문은 되돌릴 수 없어요.',
      count: '설문을 종료하고 결과를 확인하면 이전 상태로 돌아갈 수 없어요.',
      earlyCount: '설문을 종료하면 이전 상태로 돌아갈 수 없어요.',
      pause: '설문을 일시 정지하면 유권자는 투표에 참여할 수 없어요.',
      restart: '설문을 재시작하면 유권자가 투표에 참여할 수 있어요.',
      remove: '삭제하면 데이터를 복구할 수 없어요.',
      earlyExit: '설문을 즉시 종료하면 유권자는 투표에 참여할 수 없어요.',
    },
  },
  buttons: {
    earlyCount: '종료하기',
    cancel: '무효처리',
    count: {
      election: '개표하기',
      survey: '결과확인',
    },
    earlyExit: '즉시종료',
    pause: '일시정지',
    restart: '재시작',
    remove: '삭제',
    close: '취소',
  },
};
