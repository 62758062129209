import { PollGroup } from '@pickme/core';

export const setColors = (pollGroup?: PollGroup) => {
  if (pollGroup === 'canceled') {
    return { mainColor: '#787774A0', lightColor: '#F1F1F1A0' };
  }

  if (pollGroup === 'ended' || pollGroup === 'counted') {
    return { mainColor: '#03C94CA0', lightColor: '#ECFDF3A0' };
  }

  return { mainColor: '#3CA0FFA0', lightColor: '#EFF8FFA0' };
};

export function checkOrderable(rate: number, threshold: number) {
  return rate * 100 >= threshold;
}

export function calculateYAxisHeight(yValues: number[]) {
  let maxValue = 100;
  if (yValues.length === 0) {
    return maxValue;
  }

  if (yValues) {
    maxValue = Math.max(...yValues);
    if (!Number.isFinite(maxValue)) {
      return 100;
    }
  }

  return maxValue < 10 ? 10 : maxValue;
}
