import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { Controller, useForm } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody } from '@pickme/ui';
import { Text, OutlineButton, Button, DatePicker, TimePicker } from '@pickme/design-system';

import { SingleDate } from 'models/application-v2/common/single-date';

import { validateDateTime } from 'functions/date-time';

import { toast } from 'states/toast';

import { DateTime } from 'types/message';

import { buttons, modalBody, picker } from './index.css';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (value: DateTime) => void;
  value?: DateTime;
};

function SendReservationModal({ isVisible, onClose, onSubmit, value }: Props) {
  const setToast = useSetRecoilState(toast);
  const today = dayjs().toString();

  const methods = useForm<DateTime>({
    defaultValues: {
      date: today,
    },
  });

  const onFormSubmit = (dateTime: DateTime) => {
    const isValidDateTime = validateDateTime(dateTime);

    if (isValidDateTime) {
      onSubmit(dateTime);
      onClose();
      return;
    }

    setToast({
      isVisible: true,
      message: '현재 시간 이후의 시간을 선택해주세요.',
      type: 'error',
    });
  };

  useEffect(() => {
    if (value?.date && value.time) {
      methods.reset(value);
    }
  }, [value]);

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <form onSubmit={methods.handleSubmit(onFormSubmit)}>
        <ModalHeader title='발송 예약' onClose={onClose} hideCloseButton />

        <ModalBody className={modalBody}>
          <Text size={14} color='gray-700'>
            설정한 예약 시간에 이메일이 전송됩니다.
          </Text>

          <div className={picker.container}>
            <Controller
              control={methods.control}
              name='date'
              render={({ field }) => (
                <DatePicker
                  min={today}
                  value={field.value}
                  onChange={field.onChange}
                  ref={field.ref}
                  inputClassName={picker.date}
                />
              )}
            />

            <Controller
              control={methods.control}
              name='time'
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TimePicker
                  id='reservation'
                  baseDate={new Date(methods.watch('date'))}
                  min={new Date(methods.watch('date'))}
                  value={
                    field.value
                      ? new SingleDate({
                          date: new Date(methods.watch('date')),
                          time: field.value,
                        }).date
                      : null
                  }
                  onChange={(timeValue) => {
                    const { time } = new SingleDate({
                      date: timeValue,
                    });

                    field.onChange(time);
                  }}
                  containerClassName={picker.time}
                  triggerClassName={picker.timeTrigger}
                />
              )}
            />
          </div>
        </ModalBody>

        <div className={buttons.container}>
          <OutlineButton
            variant='gray'
            onClick={() => {
              methods.reset(value);
              onClose();
            }}
            className={buttons.cancel}
          >
            취소
          </OutlineButton>
          <Button type='submit' className={buttons.submit} disabled={!methods.formState.isValid}>
            확인
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default SendReservationModal;
