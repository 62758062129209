import { MemberLevel, Plan } from '@pickme/core';

export function setUser({
  userId,
  spaceId,
  memberLevel,
}: {
  userId: string;
  spaceId: string;
  memberLevel: MemberLevel;
}) {
  if (!window.gtag) {
    return;
  }

  window.gtag('set', {
    user_id: userId,
    user_properties: {
      space: spaceId,
      memberLevel,
    },
  });
}

export function setPlan({ plan, voterLimit }: { plan: Plan; voterLimit: number }) {
  if (!window.gtag) {
    return;
  }

  window.gtag('set', {
    user_properties: {
      plan,
      voterLimit,
    },
  });
}
