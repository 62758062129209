export default {
  otp: {
    email: {
      resend: {
        title: '인증번호 재전송',
        message: '{{email}}로 새로운 인증번호를 전송했어요',
      },
    },
  },
};
