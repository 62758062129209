import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';
import { Draft, DraftContent } from 'types/draft';
import { ElectionFormBody, SurveyFormBody } from 'types/application';

export const POLL_APIS: Apis = {
  getDraft: { method: 'GET', url: '/api/draft/:kind/:id' },
  getDrafts: { method: 'GET', url: '/api/draft/:kind' },
  writeDraft: { method: 'POST', url: '/api/draft/:kind' },
  removeDraft: { method: 'DELETE', url: '/api/draft/:kind/:id' },
};

export const createGetDraftRequest = (kind: 'election' | 'survey', id: string) =>
  createHttpRequest<{ content: DraftContent }>({ ...POLL_APIS.getDraft, params: { id, kind } });

export const createGetDraftsRequest = (kind: 'election' | 'survey' | 'all', page: number) =>
  createHttpRequest<{ drafts: Draft[]; endPage: number; total: number }>({
    ...POLL_APIS.getDrafts,
    query: { page },
    params: { kind },
  });

export const createWriteDraftRequest = (
  kind: 'election' | 'survey',
  form: ElectionFormBody | SurveyFormBody,
) =>
  createHttpRequest<{ draftId: string }>({
    ...POLL_APIS.writeDraft,
    data: {
      title: form.name,
      content: form,
    },
    params: { kind },
  });

export const createRemoveDraftRequest = (kind: 'election' | 'survey', id: string) =>
  createHttpRequest<{ message: 'ok' }>({ ...POLL_APIS.removeDraft, params: { id, kind } });
