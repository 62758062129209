import { useEffect } from 'react';

import { useGetEmailDuplication } from 'query-hooks/auth';

import { emailValidator } from 'functions/validator/auth';

export const useEmailDuplicated = (email: string) => {
  const isEmailValid = emailValidator(email);
  const { data, refetch, isFetched, isLoading } = useGetEmailDuplication(email);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isEmailValid) {
        refetch();
      }
    }, 800);

    return () => {
      clearTimeout(timer);
    };
  }, [email, isEmailValid]);

  return { isEmailDuplicated: data?.isDuplicated, isLoading, isCheckedEmail: isFetched };
};
