import { useEffect } from 'react';
import { Text, Modal, ModalHeader, ModalBody, ModalActions } from '@pickme/ui';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

import { useRemoveChannel } from 'query-hooks/channel';

function ChannelRemoveModal({
  isVisible,
  status,
  onClose,
}: {
  isVisible: boolean;
  status?: 'pending' | 'approved';
  onClose: () => void;
}) {
  const { isLoading, mutate, isSuccess } = useRemoveChannel();

  const onSubmit = () => mutate();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return (
    <Modal size='md' isVisible={isVisible} onClose={onClose}>
      <ModalHeader
        title={t('admin:components.features.notification.modal.channelRemove.title')}
        onClose={onClose}
      />

      <ModalBody>
        <Text type='b3' fontWeight={400} color='gray-400'>
          {status === 'pending' &&
            t('admin:components.features.notification.modal.channelRemove.message.pending')}
          {t('admin:components.features.notification.modal.channelRemove.message.default')}
        </Text>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t('admin:components.features.notification.modal.channelRemove.buttons.cancel'),
            type: 'button',
            variant: 'gray',
            onClick: () => onClose(),
          },
          {
            text: isLoading ? (
              <Spinner />
            ) : (
              t('admin:components.features.notification.modal.channelRemove.buttons.submit')
            ),
            type: 'button',
            variant: 'error',
            disabled: isLoading,
            onClick: () => onSubmit(),
          },
        ]}
      />
    </Modal>
  );
}

export default ChannelRemoveModal;
