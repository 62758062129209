import { DraftTemplates } from 'types/template';

import SCHOOL_TEMPLATES from './school';
import CORPORATION_TEMPLATES from './corporation';
import LABOR_TEMPLATES from './labor';
import APARTMENT_TEMPLATES from './apartment';
import ASSOCIATION_TEMPLATES from './association';
import CLUB_TEMPLATES from './club';
import RELIGION_TEMPLATES from './religion';
import CONTEST_TEMPLATES from './contest';

const SURVEY_TEMPLATES: DraftTemplates = {
  school: SCHOOL_TEMPLATES,
  corporation: CORPORATION_TEMPLATES,
  labor: LABOR_TEMPLATES,
  apartment: APARTMENT_TEMPLATES,
  association: ASSOCIATION_TEMPLATES,
  club: CLUB_TEMPLATES,
  religion: RELIGION_TEMPLATES,
  contest: CONTEST_TEMPLATES,
};

export default SURVEY_TEMPLATES;
