import './index.scss';

type Props = {
  size?: 'sm' | 'md';
};

function Spinner({ size = 'md' }: Props) {
  return (
    <div className={`loading loading--${size}`}>
      <div className='spinner' />
    </div>
  );
}

export default Spinner;
