import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';
import { UserRemoveRequestBody } from 'types/auth';

const APIS: Apis = {
  replaceName: { method: 'PUT', url: '/api/user/name' },
  replaceEmail: { method: 'PUT', url: '/api/user/email' },
  replacePhoneNumber: { method: 'PUT', url: '/api/user/phoneNumber' },
  replacePassword: { method: 'PUT', url: '/api/user/password' },
  removeUser: { method: 'DELETE', url: '/api/user' },
  toggleMarketingAgreement: { method: 'PUT', url: '/api/user/marketingAgreements' },
};

export const createReplaceUserNameRequest = (name: string) =>
  createHttpRequest<{ message: 'ok' }>({
    ...APIS.replaceName,
    data: { name },
    withoutOrganizationOid: true,
  });

export const createReplaceUserEmailRequest = (emailToken: string) =>
  createHttpRequest<{ message: 'ok' }>({
    ...APIS.replaceEmail,
    data: { emailToken },
    withoutOrganizationOid: true,
  });

export const createReplaceUserPhoneNumberRequest = ({
  phoneNumberToken,
  organizationOid,
}: {
  phoneNumberToken: string;
  organizationOid?: string;
}) => {
  const data: { phoneNumberToken: string; organizationOid?: string } = {
    phoneNumberToken,
  };

  if (organizationOid) {
    data.organizationOid = organizationOid;
  }

  return createHttpRequest<{ message: 'ok' }>({
    ...APIS.replacePhoneNumber,
    data,
    withoutOrganizationOid: true,
  });
};

export const createReplaceUserPasswordRequest = ({
  password,
  newPassword,
}: {
  password: string;
  newPassword: string;
}) =>
  createHttpRequest<{ message: 'ok' }>({
    ...APIS.replacePassword,
    data: { password, newPassword },
    withoutOrganizationOid: true,
  });

export const createRemoveUserRequest = (data: UserRemoveRequestBody) =>
  createHttpRequest<{ message: 'ok' }>({
    ...APIS.removeUser,
    data,
    withoutOrganizationOid: true,
  });

export const createToggleMarketingAgreement = (isAgree: boolean) => {
  const marketingAgreements = isAgree ? ['sms', 'email'] : [];

  return createHttpRequest({
    ...APIS.toggleMarketingAgreement,
    data: {
      marketingAgreements,
    },
    withoutOrganizationOid: true,
  });
};
