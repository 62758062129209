import { atom } from 'recoil';

const initialState: {
  isOpened: boolean;
} = {
  isOpened: true,
};

export const sidebarState = atom({
  key: 'sidebar-v2',
  default: initialState,
});
