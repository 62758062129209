import { isNumber, range } from 'lodash-es';
import { Dropdown, Text } from '@pickme/ui';
import { Controller, FieldPath, useFormContext } from 'react-hook-form';

import FieldTitle from 'components/features/application/label/old_FieldTitle';

import { QuestionFormBody, SubElectionFormBody } from 'types/application';

import DownIcon from 'resources/icons/application/down.png';

type Props = {
  title: string;
  tooltip?: string;
  unit: string;
  disabled?: boolean;
  fields: {
    title: string;
    min: number;
    max: number;
    fieldName: FieldPath<SubElectionFormBody | QuestionFormBody>;
    validate: (value: any) => any;
  }[];
};

function KindValueDropdownField({ title, tooltip, unit, disabled, fields }: Props) {
  const { control, watch } = useFormContext<SubElectionFormBody | QuestionFormBody>();

  return (
    <div className='application-form__row application-form__row--justify'>
      <FieldTitle title={title} isRequired tooltip={tooltip} />
      <div className='election-form__dropdown-row'>
        {fields.map((field) => (
          <div className='election-form__dropdown-group' key={field.fieldName}>
            <Text size='sm2'>{field.title}</Text>

            <Controller
              name={field.fieldName}
              control={control}
              rules={{
                validate: (value) => field.validate(value),
              }}
              render={({ field: controllerField }) => (
                <Dropdown
                  button={
                    <button
                      type='button'
                      className='election-form__dropdown-button'
                      disabled={disabled}
                    >
                      <Text size='sm1'>{`${
                        isNumber(watch(field.fieldName)) ? watch(field.fieldName) : 1
                      }${unit}`}</Text>
                      <img className='election-form__dropdown-icon' src={DownIcon} alt='dropdown' />
                    </button>
                  }
                  direction='down'
                  height={150}
                  items={range(field.min, field.max + 1).map((value) => ({
                    text: `${value}${unit}`,
                    type: 'button',
                    onClick: () => {
                      controllerField.onChange(value);
                    },
                  }))}
                />
              )}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default KindValueDropdownField;
