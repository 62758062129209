import clsx from 'clsx';
import dayjs from 'dayjs';

import { badge } from './index.css';

type Props = {
  startDate: string;
  endDate: string;
  unsetBorder?: boolean;
  className?: string;
};

function DdayBadge({ startDate, endDate, unsetBorder, className }: Props) {
  const now = dayjs();
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const isBefore = start.isAfter(now);
  const isOngoing = now.isBetween(start, end);
  const isEnded = end.isBefore(now);

  const withoutSuffix = true;

  return (
    <div className={clsx(badge({ isBefore, isOngoing, isEnded, unsetBorder }), className)}>
      {isBefore && `시작 ${start.fromNow(withoutSuffix)} 전`}
      {isOngoing && `종료 ${end.fromNow(withoutSuffix)} 전`}
      {isEnded && '종료'}
    </div>
  );
}

export default DdayBadge;
