import { Navigate, useOutletContext } from 'react-router-dom';

import { makeQueryString } from 'functions/url';

import { SocialUserInfo } from 'types/auth';

function SocialSignUpCallback() {
  const userInfo = useOutletContext<SocialUserInfo>();

  const queryString = makeQueryString({
    provider: userInfo.provider,
    name: userInfo.name,
    email: userInfo.email,
    socialKey: userInfo.socialKey,
    accessToken: userInfo.accessToken,
    refreshToken: userInfo.refreshToken,
  });

  return <Navigate to={`/auth/signup/social?${queryString}`} />;
}

export default SocialSignUpCallback;
