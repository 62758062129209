import { ButtonHTMLAttributes, forwardRef, useEffect, useState } from 'react';
import { Text } from '@pickme/design-system';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';

import PeriodModal from 'components/features/application-v2/form/Settings/Period/Modal';
import PeriodRow from 'components/features/application-v2/form/Settings/Period/Row';

import { usePollOutletContext } from 'hooks/poll-v2/usePollOutletContext';

import { PeriodGroup } from 'models/application-v2/common/period-group';

import { toast } from 'states/toast';

import { PeriodActionType, SettingsForm } from 'types/application-v2';

import PlayIcon from 'resources/icons/application-v2/play.svg';
import CalendarIcon from 'resources/icons/application-v2/calendar.svg';

import { button, buttonGroup, buttonTitleContainer, container } from './index.css';

function PeriodField() {
  const setToast = useSetRecoilState(toast);

  const [selectedPeriodGroupIndex, setSelectedPeriodGroupIndex] = useState<number | null>(null);
  const [isPeriodModalVisible, setIsPeriodModalVisible] = useState(false);

  const methods = useFormContext<SettingsForm>();
  const { checkFieldEditable } = usePollOutletContext();

  const periodGroups = useWatch<SettingsForm, 'periods.groups'>({
    control: methods.control,
    name: 'periods.groups',
  });

  const periodType = useWatch<SettingsForm, 'periods.type'>({
    control: methods.control,
    name: 'periods.type',
  });

  useEffect(() => {
    if (!isPeriodModalVisible && periodGroups.length === 0) {
      methods.setValue('periods.type', PeriodActionType.Instantly);
    }
  }, [isPeriodModalVisible, periodGroups]);

  return (
    <div className={container}>
      <div className={buttonGroup}>
        <Controller
          control={methods.control}
          name='periods.type'
          render={({ field }) => (
            <ForwardedPeriodTypeButton
              {...field}
              disabled={!checkFieldEditable('before')}
              onClick={() => field.onChange(PeriodActionType.Instantly)}
              isSelected={field.value === PeriodActionType.Instantly}
            >
              <div className={buttonTitleContainer}>
                <img src={PlayIcon} alt='즉시 시작' />
                <Text size={16} fontWeight={600}>
                  즉시 시작
                </Text>
              </div>

              <Text size={14} fontWeight={400} color='gray-700'>
                설문이 생성되는 즉시 시작되며, 종료 버튼을 통해 수동으로 설문을 마감할 수 있습니다.
              </Text>
            </ForwardedPeriodTypeButton>
          )}
        />

        <Controller
          control={methods.control}
          name='periods.type'
          render={({ field }) => (
            <ForwardedPeriodTypeButton
              {...field}
              disabled={!checkFieldEditable('before') && periodType !== PeriodActionType.Instantly}
              onClick={() => {
                field.onChange(PeriodActionType.Custom);
                setSelectedPeriodGroupIndex(null);
                setIsPeriodModalVisible(true);
              }}
              isSelected={field.value === PeriodActionType.Custom}
            >
              <div className={buttonTitleContainer}>
                <img src={CalendarIcon} alt='기간 설정' />
                <Text size={16} fontWeight={600}>
                  기간 설정
                </Text>
              </div>

              <Text size={14} fontWeight={400} color='gray-700'>
                설문의 시작일과 마감일을 직접 설정할 수있어요.
              </Text>
            </ForwardedPeriodTypeButton>
          )}
        />
      </div>

      {periodType === PeriodActionType.Custom &&
        periodGroups.map((group, index) => {
          const periodGroup = new PeriodGroup(group.values);

          return (
            <PeriodRow
              key={`${group.id}-${index}`}
              onClickRemoveButton={() => {
                methods.setValue(
                  'periods.groups',
                  periodGroups.filter((_, i) => i !== index),
                );

                setToast({
                  isVisible: true,
                  type: 'success',
                  message: `기간 ${index + 1}이 삭제되었습니다.`,
                });
              }}
              onClickEditButton={() => {
                setSelectedPeriodGroupIndex(index);
                setIsPeriodModalVisible(true);
              }}
              disabled={{
                edit: dayjs(periodGroup.endDate).isBefore(dayjs(), 'minute'),
                remove:
                  !checkFieldEditable('before') &&
                  dayjs().isSameOrBefore(dayjs(periodGroup.startDate), 'minutes'),
              }}
              group={group}
              index={index}
            />
          );
        })}

      <PeriodModal
        isVisible={isPeriodModalVisible}
        selectedPeriodIndex={selectedPeriodGroupIndex}
        onClose={() => {
          setSelectedPeriodGroupIndex(null);
          setIsPeriodModalVisible(false);
        }}
      />
    </div>
  );
}

function PeriodTypeButton(
  props: ButtonHTMLAttributes<HTMLButtonElement> & {
    children: React.ReactNode;
    isSelected: boolean;
  },
  ref: React.Ref<HTMLButtonElement>,
) {
  const { children, isSelected, ...rest } = props;
  return (
    <button
      className={button({
        isSelected,
        disabled: rest.disabled,
      })}
      type='button'
      ref={ref}
      {...rest}
    >
      {children}
    </button>
  );
}

const ForwardedPeriodTypeButton = forwardRef(PeriodTypeButton);

export default PeriodField;
