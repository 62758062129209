import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';
import { UserRemoveRequestBody } from 'types/auth';

const APIS: Apis = {
  deleteAccount: { method: 'DELETE', url: '/v2/user' },
};

export const createDeleteAccountRequest = (data: UserRemoveRequestBody) =>
  createHttpRequest<{ message: 'ok' }>({
    ...APIS.deleteAccount,
    data,
    withoutOrganizationOid: true,
  });
