export default {
  form: {
    fields: {
      otp: {
        placeholder: '인증번호 입력',
      },
      phoneNumber: {
        label: '새로운 전화번호',
        placeholder: '새 전화번호를 입력해주세요',
      },
    },
  },
  buttons: {
    verify: '인증하기',
    retry: '다시 시도해주세요',
  },
  labels: {
    retry: '인증번호를 받지 못하셨나요?',
  },
  messages: {
    wrongOTP: '올바른 인증번호를 입력해주세요',
  },
};
