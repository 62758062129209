import { SecondaryBadge, Text } from '@pickme/ui';

import Avatars from 'components/common/Avatars';

import './index.scss';

type Badge = { variant?: string; text: string };
type Props = {
  kindBadge: Badge;
  title: string;
  badges: Badge[];
  avatars?: string[];
};

function SubPollCardContents({ kindBadge, title, badges, avatars }: Props) {
  return (
    <div className='sub-poll-card-contents'>
      <SecondaryBadge variant={kindBadge.variant}>{kindBadge.text}</SecondaryBadge>

      <div className='sub-poll-card-contents__title'>
        <Text type='b1' fontWeight={500}>
          {title}
        </Text>
      </div>

      <div className='sub-poll-card-contents__fields'>
        <div className='sub-poll-card-contents__badges'>
          {badges.map((badge, index) => (
            <SecondaryBadge key={`${title}-badges-${index}`} variant={badge.variant}>
              {badge.text}
            </SecondaryBadge>
          ))}
        </div>

        {avatars && <Avatars className='sub-poll-card-contents__avatars' imageUrls={avatars} />}
      </div>
    </div>
  );
}

export default SubPollCardContents;
