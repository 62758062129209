export default {
  title: '선물하기',
  message: '선물 가능 포인트',
  tooltip: '충전 포인트만 선물할 수 있어요',
  field: {
    amount: {
      label: '선물할 포인트를 입력해주세요',
      placeholder: '포인트를 입력해주세요.',
      errorMessage: '선물 가능 포인트를 확인해주세요',
    },
    receiver: {
      label: '누구에게 포인트를 선물하시겠어요?',
    },
  },
  description: {
    title: '픽미 포인트 선물하기 안내',
    contents: [
      '동일 스페이스 소속의 매니저끼리만 포인트 선물이 가능합니다.',
      '충전 포인트만 선물할 수 있으며 적립 포인트는 선물할 수 없습니다.',
      '포인트 선물하기 완료 후 되돌릴 수 없습니다.',
    ],
  },
  buttons: {
    cancel: '취소',
    submit: '다음',
  },
};
