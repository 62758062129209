export default {
  title: '계정 탈퇴',
  buttons: {
    logout: '로그아웃',
  },
  modal: {
    title: '정말 탈퇴하시겠어요?',
    description:
      '삭제된 계정은 되돌릴 수 없어요. 영구적으로 계정을 삭제하기 전에 충분히 검토해주세요.',
    form: {
      fields: {
        password: {
          label: '비밀번호 확인',
          placeholder: '비밀번호를 입력해주세요',
        },
      },
      errorMessages: {
        password: '올바른 비밀번호를 입력해주세요',
      },
    },
    actions: {
      cancel: '취소',
      submit: '계정탈퇴',
    },
  },
};
