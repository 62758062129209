export default {
  errorMessages: {
    failure: {
      title: '결제에 실패했습니다.',
      message: '실패 사유: {message}}',
    },
    wrongPath: {
      message: '잘못된 접근입니다.',
    },
  },
};
