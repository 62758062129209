import { DraftTemplate, DraftTemplateCategory } from 'types/template';

import Thumbnail1 from 'resources/images/templates/election/apartment1.png';

const DRAFT_1 = {
  title: '아파트 동대표 선출 선거',
  _id: '64955f7947debe00488edcbc',
  content: {
    name: '아파트 동대표 선출 선거',
    periods: [
      {
        startDate: '2024-04-01T17:02',
        endDate: '2024-04-19T17:02',
      },
    ],
    description:
      '<p>입주민 여러분 안녕하십니까.</p><p>아파트 동대표 선출 선거를 실시합니다.</p><p>입주민 여러분들의 소중한 한 표 부탁드립니다.</p><p><br></p><p>감사합니다.</p>',
    type: 'Election',
    voterType: 'voterBook',
    subscriptionType: 'enterprise',
    subElections: [
      {
        kind: 'ChoiceElection',
        title: '아파트 동대표 선출 선거',
        shuffle: true,
        candidates: [
          {
            title: '나경희',
            description:
              '<p><strong>학력</strong></p><p>- 픽미대학교 대학원 경영학과 졸업</p><p>- 픽미대학교 상경대학 경영학과 졸업</p><p>- 픽미고등학교 졸업</p><p><br></p><p><strong>사회 경력</strong></p><p>- 퀘스 근무 (2006&nbsp;~ 현재)</p><p><br></p><p><strong>공약</strong></p><p>- 입주민과 원활한 소통을 위해 소통창구를 마련하겠습니다.</p><p>- 커뮤니티 시설, 어린이 놀이터, 노인정 시설을 보수 및 개선하겠습니다.</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '나경희 (50세)',
                fileUrl: 'https://api.ohpick.me/api/file/64955f5047debe00488edca1',
              },
            ],
            tags: [],
            id: 'fbd6ab95-8161-4dd3-bc01-89cb7f5d0ff6',
          },
          {
            title: '박옥',
            description:
              '<p><strong>학력</strong></p><p>- 픽미대학교 대학원 경제학과 졸업</p><p>- 픽미대학교 상경대학 경제학과 졸업</p><p>- 픽미고등학교 졸업</p><p><br></p><p><strong>사회 경력</strong></p><p>- 주식회사 퀘스 근무 (2009&nbsp;~ 현재)</p><p><br></p><p><strong>공약</strong></p><p>- 무인택배함을 설치하겠습니다.</p><p>- 아이들의 안전한 귀가를 위해 단지내 야간 순찰 시간을 실시하겠습니다.</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '박옥 (43세)',
                fileUrl: 'https://api.ohpick.me/api/file/64955f5747debe00488edca8',
              },
            ],
            tags: [],
            id: '0c72d02a-926b-4a6b-b930-96cd05c5296d',
          },
          {
            title: '서미옥',
            description:
              '<p><strong>학력</strong></p><p>- 픽미대학교 대학원 경제학과 졸업</p><p>- 픽미대학교 상경대학 경제학과 졸업</p><p>- 픽미고등학교 졸업</p><p><br></p><p><strong>사회 경력</strong></p><p>- 퀘스뱅크 근무 (1998&nbsp;~ 현재)</p><p><br></p><p><strong>공약</strong></p><p>- 단지내 CCTV 확충을 통해 안전한 주거 생활을 제공하겠습니다.</p><p>- 입주민 여러분들과 소통할 수 있는 창구를 확대하겠습니다.</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '서미옥 (59세)',
                fileUrl: 'https://api.ohpick.me/api/file/64955f5d47debe00488edcaf',
              },
            ],
            tags: [],
            id: '011274b2-51d8-40a3-9010-dbabc7335823',
          },
        ],
        responseValidation: {
          allowAbstentionVote: true,
          minResponse: 1,
          maxResponse: 1,
        },
        id: 'ff3f6b86-72a9-48db-9a5c-3f9022ddd468',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'election',
  createdAt: '2023-06-23T09:01:45.364Z',
  __v: 0,
};

export default [
  {
    title: '아파트 동대표 선출 투표',
    description: '아파트 동대표 선출 투표를 위한 템플릿입니다.',
    thumbnail: Thumbnail1,
    draft: DRAFT_1,
    category: DraftTemplateCategory.Apartment,
    kind: 'Election',
  },
] as DraftTemplate[];
