import { useSetRecoilState } from 'recoil';
import { NavLink } from 'react-router-dom';

import UserMenu from 'components/common/layout/Main/Navbar/UserMenu';

import { sidebarState } from 'states/layout';

import HamburgerIcon from 'resources/icons/nav/hamburger.svg';
import Logo from 'resources/icons/nav/pickme_logo.png';

import { hamburger, hamburgerButton, logo, navbar } from './index.css';

function Navbar() {
  const setSidebarState = useSetRecoilState(sidebarState);

  return (
    <nav className={navbar}>
      <button
        className={hamburgerButton}
        type='button'
        onClick={() => {
          setSidebarState((prevState) => ({
            isVisible: !prevState.isVisible,
          }));
        }}
      >
        <img className={hamburger} src={HamburgerIcon} alt='menu' />
      </button>

      <NavLink to='/' unstable_viewTransition>
        <img className={logo} src={Logo} alt='logo' />
      </NavLink>

      <UserMenu />
    </nav>
  );
}

export default Navbar;
