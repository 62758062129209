import { Plan } from '@pickme/core';

import { DraftTemplate, DraftTemplateCategory } from 'types/template';

import Thumbnail1 from 'resources/images/templates/survey/school1.png';
import Thumbnail2 from 'resources/images/templates/survey/school2.png';
import Thumbnail3 from 'resources/images/templates/survey/school3.png';
import Thumbnail4 from 'resources/images/templates/survey/school4.png';

const DRAFT_1 = {
  title: '픽미대학교 취업캠프 만족도 조사',
  _id: '645b67c56a7575018772324a',
  content: {
    name: '픽미대학교 취업캠프 만족도 조사',
    periods: [
      {
        startDate: '2024-02-10T16:51',
        endDate: '2024-02-20T16:51',
      },
    ],
    description:
      '<p>안녕하세요! 픽미대학교 취업지원센터입니다.</p><p>이번 픽미대학교 취업캠프 만족도 설문조사로 캠프 참여자를 대상으로 진행됩니다.</p><p>여러분들의 다양한 의견을 자유롭게 들려주세요!</p>',
    type: 'Survey',
    voterType: 'voterBook',
    subscriptionType: Plan.Free,
    questions: [
      {
        kind: 'RadioQuestion',
        title: '취업을 희망하는 기업 형태를 선택해 주세요.',
        description: '',
        isRequired: false,
        selections: [
          {
            number: 1,
            description: '대기업',
            fileUrl: '',
          },
          {
            description: '중소기업',
            number: 2,
            fileUrl: '',
          },
          {
            description: '외국계 기업 (국내 소재)',
            number: 3,
            fileUrl: '',
          },
          {
            description: '정부 기관 (공무원 / 공공기관)',
            number: 4,
            fileUrl: '',
          },
          {
            description: '교육기관',
            number: 5,
            fileUrl: '',
          },
          {
            description: '해외 취업',
            number: 6,
            fileUrl: '',
          },
          {
            description: '기타',
            number: 7,
            fileUrl: '',
          },
        ],
        subQuestions: [null, null],
        fileUrl: '',
        id: '07ea0030-8d27-44ff-af5d-4bbf587676a2',
      },
      {
        kind: 'RadioQuestion',
        title: '이번 취업 캠프를 알게된 경로를 선택해주세요.',
        description: '',
        isRequired: false,
        selections: [
          {
            number: 1,
            description: '지인 추천 및 소개',
            fileUrl: '',
          },
          {
            description: '학교 홈페이지',
            number: 2,
            fileUrl: '',
          },
          {
            description: '홍보물',
            number: 3,
            fileUrl: '',
          },
          {
            description: '기타',
            number: 4,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
        id: '81859066-93e8-4ecb-b94b-f6bb38684706',
      },
      {
        kind: 'RankQuestion',
        title: '만족도가 높았던 순서대로 프로그램을 선택해주세요.',
        description: '',
        isRequired: false,
        selections: [],
        subQuestions: [
          {
            description: '자기소개서 클리닉',
            fileUrl: '',
          },
          {
            description: 'AI 역량 검사',
            fileUrl: '',
          },
          {
            description: '1분 자기소개 스피치',
            fileUrl: '',
          },
        ],
        responseValidation: {
          rankLimit: 3,
        },
        fileUrl: '',
      },
      {
        kind: 'ScaleQuestion',
        title: '이번 취업 캠프에 만족하시나요?',
        description: '',
        isRequired: false,
        selections: [],
        subQuestions: [],
        min: {
          value: 1,
          description: '불만족',
        },
        max: {
          value: 10,
          description: '만족',
        },
        fileUrl: '',
        id: 'c1bfffcd-cfa8-42d1-a623-27c163e2fe72',
      },
      {
        kind: 'TextQuestion',
        title: '2023년 취업 캠프에 대한 소감 및 후기를 자유롭게 작성해주세요.',
        description: '',
        isRequired: false,
        selections: [],
        subQuestions: [],
        responseValidation: {
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-05-10T09:45:41.178Z',
  __v: 0,
};

const DRAFT_2 = {
  title: '픽미고등학교 방과후학교 프로그램 만족도 조사',
  _id: '6459d731f329da0137b8affa',
  content: {
    name: '픽미고등학교 방과후학교 프로그램 만족도 조사',
    periods: [
      {
        startDate: '2024-02-09T00:55',
        endDate: '2024-02-12T00:55',
      },
    ],
    description:
      '<p>본 설문은 학부모님들을 대상으로 진행하는 방과후 프로그램 만족도 설문조사입니다.</p><p>학부모님들의 의견을 자유롭게 들려주세요.</p><p><br></p><p>감사합니다.</p>',
    type: 'Survey',
    voterType: 'voterBook',
    subscriptionType: 'enterprise',
    questions: [
      {
        kind: 'RadioQuestion',
        title: '방과후학교 프로그램을 통해 사교육비를 줄이는데 도움이 되셨나요?',
        description: '',
        isRequired: false,
        selections: [
          {
            number: 1,
            description: '네',
            fileUrl: '',
          },
          {
            description: '아니오',
            number: 2,
            fileUrl: '',
          },
          {
            description: '모르겠습니다.',
            number: 3,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
        id: '840b658b-75b3-4420-88fc-00fd8cc74c2c',
      },
      {
        kind: 'ScoreQuestion',
        title: '각 항목별 만족도를 선택해주세요.',
        description: '',
        isRequired: false,
        selections: [],
        subQuestions: [
          {
            description: '강사진',
            fileUrl: '',
          },
          {
            description: '프로그램 구성',
            fileUrl: '',
          },
          {
            description: '프로그램별 수강 인원',
            fileUrl: '',
          },
          {
            description: '프로그램 수준별 반편성',
            fileUrl: '',
          },
        ],
        responseValidation: {
          minScore: 1,
          maxScore: 10,
        },
        fileUrl: '',
        id: 'b4cb5b00-4179-46ea-8782-7d2da906a382',
      },
      {
        kind: 'RadioQuestion',
        title: '다음 방과후학교 프로그램에 재참여할 의향이 있으신가요?',
        description: '',
        isRequired: false,
        selections: [
          {
            number: 1,
            description: '네',
            fileUrl: '',
          },
          {
            description: '아니오',
            number: 2,
            fileUrl: '',
          },
          {
            description: '모르겠습니다',
            number: 3,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'TextQuestion',
        title: '다음 방과후학교 프로그램에 참여를 희망할 경우 개설 희망 프로그램명을 적어주세요.',
        description: '',
        isRequired: false,
        selections: [],
        subQuestions: [],
        responseValidation: {
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
      },
      {
        kind: 'ScaleQuestion',
        title: '방과후학교 프로그램에 대하여 종합적인 만족도를 선택해주세요.',
        description: '',
        isRequired: false,
        selections: [],
        subQuestions: [],
        min: {
          value: 1,
          description: '매우 불만',
        },
        max: {
          value: 10,
          description: '매우 만족',
        },
        fileUrl: '',
        id: '67ea473e-6f92-4166-b9e9-65db84e6fc8c',
      },
      {
        kind: 'TextQuestion',
        title: '방과후학교 프로그램을 통해 느낀점 및 개선사항 등 의견을 자유롭게 적어주세요.',
        description: '',
        isRequired: false,
        selections: [],
        subQuestions: [],
        responseValidation: {
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
        id: '47e87793-78fc-4bce-af88-d80f97403e34',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-05-09T05:16:33.883Z',
  __v: 0,
};

const DRAFT_3 = {
  title: '금연교육 사전 설문조사',
  _id: '64b8bf3895059000479a23ef',
  content: {
    name: '금연교육 사전 설문조사',
    periods: [
      {
        startDate: '2023-07-21T13:54',
        endDate: '2023-07-28T13:54',
      },
    ],
    description:
      '<p>본 설문은 금연교육을 위한 사전 설문조사입니다.</p><p>솔직한 답변 부탁드립니다.</p><p>감사합니다.</p>',
    type: 'Survey',
    voterType: 'voterBook',
    hasOpenVotes: false,
    subscriptionType: 'enterprise',
    questions: [
      {
        kind: 'RadioQuestion',
        title: '담배를 처음 배운 것은 몇 세입니까?',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '14세 미만',
            fileUrl: '',
          },
          {
            description: '14~16세',
            number: 2,
            fileUrl: '',
          },
          {
            description: '17~19세',
            number: 3,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
        id: 'd57f4631-0d73-4ae4-8f18-f91ecd48781d',
      },
      {
        kind: 'TextQuestion',
        title: '처음 담배를 피우게 된 동기는 무엇이었습니까?',
        description: '',
        isRequired: true,
        selections: [],
        subQuestions: [],
        responseValidation: {
          kind: 'AnswerLengthValidation',
          threshold: 5000,
          validation: 'lt',
        },
        fileUrl: '',
      },
      {
        kind: 'RadioQuestion',
        title: '몇 세부터 지금처럼 규칙적으로 담배를 피우기 시작하셨습니까?',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '14세 미만',
            fileUrl: '',
          },
          {
            description: '14~16세',
            number: 2,
            fileUrl: '',
          },
          {
            description: '17~19세',
            number: 3,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'RadioQuestion',
        title: '하루에 보통 몇 개비 피우십니까?',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '1개~5개비',
            fileUrl: '',
          },
          {
            description: '6개~10개비',
            number: 2,
            fileUrl: '',
          },
          {
            description: '11개~15개비',
            number: 3,
            fileUrl: '',
          },
          {
            description: '16개~20개비',
            number: 4,
            fileUrl: '',
          },
          {
            description: '20개비 이상',
            number: 5,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
        id: '64ea0539-6f25-4cc3-94d5-d70fd1a04b8f',
      },
      {
        kind: 'RadioQuestion',
        title: '같이 사는 가족 중에 담배를 피우는 사람이 있습니까?',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '네',
            fileUrl: '',
          },
          {
            description: '아니오',
            number: 2,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        fileUrl: '',
      },
      {
        kind: 'CheckboxQuestion',
        title: '지금까지 담배를 끊기 위해서 시도했던 방법을 모두 선택해주세요.',
        description: '',
        isRequired: true,
        selections: [
          {
            number: 1,
            description: '자기 의지',
            fileUrl: '',
          },
          {
            description: '금연교육 프로그램 (금연학교, 금연클리닉 등)',
            number: 2,
            fileUrl: '',
          },
          {
            description: '금연보조제(금연껌, 금연패치 등)',
            number: 3,
            fileUrl: '',
          },
          {
            description: '금연침',
            number: 4,
            fileUrl: '',
          },
          {
            description: '기타',
            number: 5,
            fileUrl: '',
          },
        ],
        subQuestions: [],
        responseValidation: {
          validation: 'lte',
          threshold: 5,
        },
        fileUrl: '',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    hasInstantVoter: false,
    allowRealTimeResult: false,
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-07-20T04:59:36.449Z',
  __v: 0,
};

const DRAFT_4 = {
  _id: '65dd8d766af7bf00d12f0790',
  title: '교내 편의시설 만족도 조사',
  content: {
    name: '교내 편의시설 만족도 조사',
    description:
      '<p class="editor-paragraph" dir="ltr" style="text-align: justify;"><span style="white-space: pre-wrap;">안녕하세요. 재학 중인 학부 재학생을 대상으로 교내 편의시설 만족도 조사를 시시 하오니 학생 여러분들의 많은 참여 부탁드립니다.</span><br><br><span style="white-space: pre-wrap;">감사합니다.</span></p><p class="editor-paragraph"><br></p>',
    questions: [
      {
        min: {
          value: 1,
          description: '불편하다',
        },
        max: {
          value: 5,
          description: '만족스럽다',
        },
        videoUrl: {
          youtube: '',
        },
        isRequired: true,
        kind: 'ScaleQuestion',
        _id: '65dd8d766af7bf00d12f0791',
        title: '편의시설 1',
        description: '',
        fileUrl: '',
      },
      {
        min: {
          value: 1,
          description: '불편하다',
        },
        max: {
          value: 5,
          description: '만족스럽다',
        },
        videoUrl: {
          youtube: '',
        },
        isRequired: true,
        kind: 'ScaleQuestion',
        _id: '65dd8d766af7bf00d12f0792',
        title: '편의시설 2',
        description: '',
        fileUrl: '',
      },
      {
        min: {
          value: 1,
          description: '불편하다',
        },
        max: {
          value: 5,
          description: '만족스럽다',
        },
        videoUrl: {
          youtube: '',
        },
        isRequired: true,
        kind: 'ScaleQuestion',
        _id: '65dd8d766af7bf00d12f0793',
        title: '편의시설 3',
        description: '',
        fileUrl: '',
      },
    ],
    periods: [
      {
        startDate: '2024-02-27T08:58:00.000Z',
        endDate: '2024-02-28T06:58:00.000Z',
      },
    ],
    type: 'Survey',
    voterType: 'voterBook',
    hasOpenVotes: false,
    subscriptionType: 'enterprise',
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'survey',
  createdAt: '2023-06-26T06:41:40.686Z',
  __v: 0,
};

export default [
  {
    title: '대학교 취업캠프 참여 조사',
    description: '대학교 취업캠프 참여 조사를 위한 템플릿입니다.',
    thumbnail: Thumbnail1,
    draft: DRAFT_1,
    category: DraftTemplateCategory.School,
    kind: 'Survey',
  },
  {
    title: '학내 프로그램 만족도 조사',
    description: '학내 프로그램 만족도 조사를 위한 템플릿입니다.',
    thumbnail: Thumbnail2,
    draft: DRAFT_2,
    category: DraftTemplateCategory.School,
    kind: 'Survey',
  },
  {
    title: '금연교육 사전 조사',
    description: '금연교육 사전 조사를 위한 템플릿입니다.',
    thumbnail: Thumbnail3,
    draft: DRAFT_3,
    category: DraftTemplateCategory.School,
    kind: 'Survey',
  },
  {
    title: '교내 편의시설 만족도 조사',
    description: `${DRAFT_4.title}를 위한 템플릿입니다.`,
    thumbnail: Thumbnail4,
    draft: DRAFT_4,
    category: DraftTemplateCategory.School,
    kind: 'Survey',
  },
] as DraftTemplate[];
