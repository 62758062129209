import { PrimaryBadge } from '@pickme/ui';
import { MANAGER_ROLE_LABELS, ManagerRoleTitleV2 } from '@pickme/core';

type Props = {
  role: ManagerRoleTitleV2;
};

const BADGE_VALUES: {
  [level in ManagerRoleTitleV2]: {
    color: string;
    text: string;
  };
} = {
  [ManagerRoleTitleV2.Admin]: {
    color: 'vivid-blue',
    text: MANAGER_ROLE_LABELS[ManagerRoleTitleV2.Admin],
  },
  [ManagerRoleTitleV2.Editor]: {
    color: 'vivid-purple',
    text: MANAGER_ROLE_LABELS[ManagerRoleTitleV2.Editor],
  },
  [ManagerRoleTitleV2.Viewer]: {
    color: 'vivid-orange',
    text: MANAGER_ROLE_LABELS[ManagerRoleTitleV2.Viewer],
  },
};

function ManagerRoleBadge({ role }: Props) {
  return <PrimaryBadge variant={BADGE_VALUES[role].color}>{BADGE_VALUES[role].text}</PrimaryBadge>;
}

export default ManagerRoleBadge;
