import { PollProgress } from '@pickme/core';
import {
  ApplicationStepV2,
  AuthMethod,
  AuthType,
  CoverMediaType,
  CandidateElectionItem,
  IssueElectionItem,
  ElectionTypeCase,
  EndingForm,
  IntroForm,
  PeriodActionType,
  Person,
  SettingsForm,
  VoterBookForm,
  VoterType,
  Question,
  SurveyPage,
} from 'types/application-v2';
import { SurveyTypeCase } from 'types/application-v2/default';
import { PollKindV2 } from 'types/poll-v2';

export const APPLICATION_STEPS = {
  [ApplicationStepV2.Intro]: {
    id: ApplicationStepV2.Intro,
    label: '인트로',
    panels: {
      step: true,
      theme: true,
      size: 'md' as const,
    },
  },
  [ApplicationStepV2.Main]: {
    id: ApplicationStepV2.Main,
    label: '투표 만들기',
    panels: {
      step: true,
      theme: true,
      size: 'md' as const,
    },
  },
  [ApplicationStepV2.Ending]: {
    id: ApplicationStepV2.Ending,
    label: '엔딩',
    panels: {
      step: true,
      theme: true,
      size: 'md' as const,
    },
  },
  [ApplicationStepV2.Settings]: {
    id: ApplicationStepV2.Settings,
    label: '설정',
    panels: {
      step: true,
      theme: true,
      size: 'md' as const,
    },
  },
  [ApplicationStepV2.VoterBook]: {
    id: ApplicationStepV2.VoterBook,
    label: '명부',
    panels: {
      step: false,
      theme: false,
      size: 'lg' as const,
    },
  },
};

export const APPLICATION_STEPS_FOR_PANEL = {
  [PollKindV2.CandidateElection]: [
    APPLICATION_STEPS[ApplicationStepV2.Intro],
    APPLICATION_STEPS[ApplicationStepV2.Main],
    APPLICATION_STEPS[ApplicationStepV2.Ending],
    APPLICATION_STEPS[ApplicationStepV2.Settings],
  ],
  [PollKindV2.IssueElection]: [
    APPLICATION_STEPS[ApplicationStepV2.Intro],
    APPLICATION_STEPS[ApplicationStepV2.Main],
    APPLICATION_STEPS[ApplicationStepV2.Ending],
    APPLICATION_STEPS[ApplicationStepV2.Settings],
  ],
  [PollKindV2.Survey]: [
    APPLICATION_STEPS[ApplicationStepV2.Intro],
    {
      ...APPLICATION_STEPS[ApplicationStepV2.Main],
      label: '설문 만들기',
    },
    APPLICATION_STEPS[ApplicationStepV2.Ending],
    APPLICATION_STEPS[ApplicationStepV2.Settings],
  ],
};

export const INTRO_FORM_DEFAULT_VALUES: IntroForm = {
  title: '',
  buttonText: '',
  cover: {
    type: CoverMediaType.Image,
    url: '',
  },
  description: '',
};

export const SURVEY_PAGE_DEFAULT_VALUES: SurveyPage = {
  id: '',
  title: '',
  description: '',
  questions: [],
};

export const ENDING_FORM_DEFAULT_VALUES: EndingForm = {
  button: {
    text: '',
    link: '',
  },
  cover: {
    type: CoverMediaType.Image,
    url: '',
  },
  share: {
    enabled: {
      facebook: false,
      instagram: false,
      kakao: false,
      line: false,
    },
    text: '',
  },
  description: '',
};

export const MEMBER_DEFAULT_VALUES: Person = {
  id: '',
  name: '',
  department: {
    enabled: false,
    value: '',
  },
  position: {
    enabled: false,
    value: '',
  },
  biography: {
    enabled: false,
    value: [],
  },
  imageUrl: {
    enabled: false,
    value: '',
  },
};

export const POSITION_VALUES = [
  '정후보',
  '부후보',
  '회장',
  '부회장',
  '대표',
  '부대표',
  '반장',
  '부반장',
];

export const CANDIDATES_DEFAULT_VALUES = {
  [PollKindV2.CandidateElection]: {
    title: '',
    imageUrls: {
      main: '',
      others: [],
    },
    members: [],
  },
  [PollKindV2.IssueElection]: {
    title: '',
    imageUrls: {
      main: '',
    },
  },
};

export const SETTINGS_FORM_DEFAULT_VALUES: SettingsForm = {
  periods: {
    type: PeriodActionType.Instantly,
    groups: [],
  },
  voterType: VoterType.All,
  openVoteCounts: true,
  passCode: '',
  allowAnonymousVote: false,
  allowRealtimeResult: false,
  preventDuplicatedVote: false,
};

export const VOTER_BOOK_FORM_DEFAULT_VALUES: VoterBookForm = {
  authType: AuthType.OTP,
  authMethod: [AuthMethod.PhoneNumber],
  voters: [],
  additionalFieldNames: [],
};

export const QUESTION_DESCRIPTION_PLACEHOLDERS: { [key in SurveyTypeCase]: string } = {
  [SurveyTypeCase.객관식]: '설명을 입력해 주세요(선택사항)',
  [SurveyTypeCase.주관식]: '설명을 입력해 주세요(선택사항)',
  [SurveyTypeCase.이미지선택]: '설명을 입력해 주세요(선택사항)',
  [SurveyTypeCase.동영상선택]: '설명을 입력해 주세요(선택사항)',
  [SurveyTypeCase.선호도평가]: '설명을 입력해 주세요(선택사항)',
  [SurveyTypeCase.점수평가]: '설명을 입력해 주세요(선택사항)',
  [SurveyTypeCase.선형배율]: '설명을 입력해 주세요(선택사항)',
  [SurveyTypeCase.별점]: '설명을 입력해 주세요(선택사항)',
  [SurveyTypeCase.드롭다운]: '설명을 입력해 주세요(선택사항)',
  [SurveyTypeCase.이메일주소]: '설명을 입력해 주세요(선택사항)',
  [SurveyTypeCase.휴대폰번호]: '설명을 입력해 주세요(선택사항)',
  [SurveyTypeCase.날짜]: '설명을 입력해 주세요(선택사항)',
  [SurveyTypeCase.시간]: '설명을 입력해 주세요(선택사항)',
  [SurveyTypeCase.링크]: '설명을 입력해 주세요(선택사항)',
  [SurveyTypeCase.개인정보수집]:
    '설명을 입력해 주세요(선택사항) ex. 개인정보 수집에 대한 동의는 거부할 수 있으나 이 경우 서비스 이용이 제한됩니다.',
};

export const ELECTION_TITLE_MAX_LENGTH = 200;
export const ELECTION_DESCRIPTION_MAX_LENGTH = 20000;
export const ELECTION_START_BUTTON_MAX_LENGTH = 30;

export const ELECTIONS_TITLE_MAX_LENGTH = 200;
export const ELECTIONS_DESCRIPTION_MAX_LENGTH = 20000;

export const CANDIDATE_PROPOSAL_NAME_MAX_LENGTH = 1000;
export const CANDIDATE_TEAM_NAME_MAX_LENGTH = 1000;

export const ENDING_DESCRIPTION_MAX_LENGTH = 20000;
export const ENDING_LINK_TEXT_MAX_LENGTH = 30;
export const ENDING_SHARE_BUTTON_TEXT_MAX_LENGTH = 30;

export const ANSWER_DEFAULT_VALUES = {
  id: '',
  value: '',
};

export const SURVEY_PAGE_TITLE_MAX_LENGTH = 200;
export const SURVEY_PAGE_DESCRIPTION_MAX_LENGTH = 20000;

export const SURVEYS_TITLE_MAX_LENGTH = 200;
export const SURVEYS_DESCRIPTION_MAX_LENGTH = 20000;

export const QUESTION_TITLE_MAX_LENGTH = 200;
export const QUESTION_DESCRIPTION_MAX_LENGTH = 20000;

export const MULTIPLE_SELECTION_MIN_VALUE = 1;

export const SCORE_MIN_VALUE = 0;
export const SCORE_MAX_VALUE = 100;

export const SCALE_MIN_VALUE = 0;
export const SCALE_MAX_VALUE = 10;

export const RANK_MIN_VALUE = 0;

export const RANK_WEIGHT_MIN_VALUE = 1;
export const RANK_WEIGHT_MAX_VALUE = 10;

export const ANSWER_MAX_LENGTH = 1000;

export const SHUFFLE_ALLOWED_SURVEY_TYPE: SurveyTypeCase[] = [
  SurveyTypeCase.객관식,
  SurveyTypeCase.이미지선택,
  SurveyTypeCase.동영상선택,
  SurveyTypeCase.선호도평가,
  SurveyTypeCase.선형배율,
  SurveyTypeCase.점수평가,
];

export const MULTIPLE_SELECTION_ALLOWED_SURVEY_TYPE: SurveyTypeCase[] = [
  SurveyTypeCase.객관식,
  SurveyTypeCase.이미지선택,
  SurveyTypeCase.동영상선택,
];

export const SHOULD_HAVE_ANSWER_SURVEY_TYPE: SurveyTypeCase[] = [
  SurveyTypeCase.객관식,
  SurveyTypeCase.이미지선택,
  SurveyTypeCase.동영상선택,
  SurveyTypeCase.선호도평가,
  SurveyTypeCase.점수평가,
  SurveyTypeCase.드롭다운,
];

export const SHOULD_HAVE_TEXT_IN_ANSWER_SURVEY_TYPE: SurveyTypeCase[] = [
  SurveyTypeCase.객관식,
  SurveyTypeCase.선호도평가,
  SurveyTypeCase.점수평가,
  SurveyTypeCase.드롭다운,
];

export const SHOULD_HAVE_IMAGE_IN_ANSWER_SURVEY_TYPE: SurveyTypeCase[] = [
  SurveyTypeCase.이미지선택,
];

export const SHOULD_HAVE_VIDEO_IN_ANSWER_SURVEY_TYPE: SurveyTypeCase[] = [
  SurveyTypeCase.동영상선택,
];

export const EDIT_PRIORITY_FLAGS = {
  [PollProgress.Application]: 0,
  [PollProgress.Pending]: 1,
  [PollProgress.Before]: 2,
  [PollProgress.Ongoing]: 3,
  [PollProgress.Paused]: 4,
  [PollProgress.After]: 5,
  [PollProgress.Completed]: 6,
};

export const CANDIDATE_ELECTION_DEFAULT_VALUES: CandidateElectionItem = {
  key: '',
  type: ElectionTypeCase.선택투표,
  title: '',
  description: '',
  candidates: [],
  includeAbstention: false,
  allowMultipleSelectionEnabled: false,
  allowMultipleSelectionMinValue: MULTIPLE_SELECTION_MIN_VALUE,
  allowMultipleSelectionMaxValue: MULTIPLE_SELECTION_MIN_VALUE,
  minScore: SCORE_MIN_VALUE,
  maxScore: SCORE_MAX_VALUE,
};

export const ISSUE_ELECTION_DEFAULT_VALUES: IssueElectionItem = {
  key: '',
  type: ElectionTypeCase.선택투표,
  title: '',
  description: '',
  candidates: [],
  includeAbstention: false,
  allowMultipleSelectionEnabled: false,
  allowMultipleSelectionMinValue: MULTIPLE_SELECTION_MIN_VALUE,
  allowMultipleSelectionMaxValue: MULTIPLE_SELECTION_MIN_VALUE,
  minScore: SCORE_MIN_VALUE,
  maxScore: SCORE_MAX_VALUE,
};

export const QUESTION_DEFAULT_VALUES: Question = {
  id: '',
  type: SurveyTypeCase.객관식,
  title: '',
  description: '',
  allowMultipleSelectionEnabled: false,
  allowMultipleSelectionMinValue: MULTIPLE_SELECTION_MIN_VALUE,
  allowMultipleSelectionMaxValue: MULTIPLE_SELECTION_MIN_VALUE,
  minScore: SCORE_MIN_VALUE,
  maxScore: SCORE_MAX_VALUE,
  scaleMaxLabel: '',
  scaleMinLabel: '',
  scaleMaxValue: SCALE_MIN_VALUE,
  scaleMinValue: SCALE_MAX_VALUE,
  starTotal: 5,
  starUnit: 1,
  answers: [],
};
