import { htmlToText } from 'html-to-text';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Link, useOutletContext } from 'react-router-dom';
import { FormProvider, SubmitErrorHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Callout, Button, OutlineButton } from '@pickme/design-system';
import { Poll } from '@pickme/core';
import * as yup from 'yup';

import NotificationSendCard from 'components/features/notification-v2/cards/Send';
import TargetFields from 'components/features/notification-v2/fields/send/Target';
import PollSelectModal from 'components/features/notification-v2/modal/PollSelect';
import LmsEditorField from 'components/features/notification-v2/fields/LmsEditor';
import LmsPreview from 'components/features/notification-v2/LmsPreview';
import SendReservationModal from 'components/features/notification-v2/modal/Reservation';
import ReservationDate from 'components/features/notification-v2/ReservationDate';

import { useSendLms } from 'query-hooks/message';
import { useGetPollsWithIds } from 'query-hooks/poll-v2';

import { useGetFeatureEnabled } from 'hooks/useGetFeatures-v2';

import { toast } from 'states/toast';

import { FeatureTitleV2, MessagingAction } from 'types/features-v2';
import { RecipientType, LmsForm, MessagingType } from 'types/message';
import { type SendOutletContext } from 'pages/notification/Send';

import { calloutTitle, point, sendButtons } from '../common.css';
import { bottomSection, leftSection, rightSection } from './index.css';

function Lms() {
  const setToast = useSetRecoilState(toast);
  const enableToUseNotification = useGetFeatureEnabled({
    feature: FeatureTitleV2.메시지,
    actionName: MessagingAction.메시지보내기,
  });

  const { pollId } = useOutletContext<SendOutletContext>();

  const methods = useForm<LmsForm>({
    mode: 'onTouched',
    defaultValues: {
      pollIds: pollId ? [pollId] : [],
      recipientType: RecipientType.All,
      dateTime: {
        date: '',
        time: '',
      },
    },
    resolver: yupResolver(schema),
  });
  const pollIds = useWatch({ control: methods.control, name: 'pollIds' });
  const results = useGetPollsWithIds(pollIds);
  const polls = results.map((result) => result.data).filter(Boolean) as Poll[];

  const convertedText = htmlToText(methods.watch('template'));
  const isSubmitDisabled = convertedText.trim().length === 0;

  const [isPollSelectorModalVisible, setPollSelectorModalVisible] = useState(false);
  const [isSendReservationModalVisible, setIsSendReservationModalVisible] = useState(false);

  const { mutate, isLoading } = useSendLms();

  const onSubmit = (form: LmsForm) => {
    const text = htmlToText(form.template, {
      selectors: [{ selector: 'p', options: { leadingLineBreaks: 1, trailingLineBreaks: 1 } }],
    });
    const type =
      form.dateTime?.date && form.dateTime.time ? MessagingType.Reserve : MessagingType.Send;
    mutate({ form: { ...form, template: text, styledTemplate: form.template }, type });
  };

  const onError: SubmitErrorHandler<LmsForm> = (data) => {
    setToast({
      isVisible: true,
      type: 'error',
      message: data.pollIds?.message || data.subject?.message || '',
    });
  };

  return (
    <>
      <Callout>
        <Callout.Title className={calloutTitle}>
          문자 1건 : 30P
          <Link to='/service/notification/point' className={point} unstable_viewTransition>
            충전하기
          </Link>
        </Callout.Title>

        <Callout.Sentence>
          대상자 명단에 휴대전화번호가 등록되어 있어야 메시지를 전송할 수 있어요.
        </Callout.Sentence>
      </Callout>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <NotificationSendCard title='전송 대상 선택'>
            <TargetFields
              polls={polls}
              isRecipientTypeDisabled={!enableToUseNotification}
              onClickAddPolls={() => setPollSelectorModalVisible(true)}
              pointPerVoter={30}
            />
          </NotificationSendCard>

          <div className={bottomSection}>
            <NotificationSendCard title='문자 작성' className={leftSection}>
              <LmsEditorField />
            </NotificationSendCard>

            <div className={rightSection.container}>
              <NotificationSendCard title='미리 보기' className={rightSection.preview}>
                <LmsPreview />
              </NotificationSendCard>

              <div className={sendButtons.container}>
                <OutlineButton
                  size='lg'
                  variant='gray'
                  onClick={() => setIsSendReservationModalVisible(true)}
                  className={sendButtons.reserve}
                >
                  예약
                </OutlineButton>

                <Button
                  size='lg'
                  className={sendButtons.submit}
                  type='submit'
                  disabled={isSubmitDisabled || isLoading}
                >
                  전송하기
                </Button>
              </div>

              <ReservationDate />
            </div>
          </div>
        </form>
      </FormProvider>

      <PollSelectModal
        isVisible={isPollSelectorModalVisible}
        submittedPollIds={pollIds}
        onClose={() => setPollSelectorModalVisible(false)}
        onSubmit={(selectedPollIds) => {
          methods.setValue('pollIds', selectedPollIds);
          setPollSelectorModalVisible(false);
        }}
      />

      <SendReservationModal
        isVisible={isSendReservationModalVisible}
        onClose={() => setIsSendReservationModalVisible(false)}
        value={methods.watch('dateTime')}
        onSubmit={(value) => {
          methods.setValue('dateTime', value);
        }}
      />
    </>
  );
}

export default Lms;

const schema = yup.object().shape({
  pollIds: yup.array().required().min(1, '투표를 선택해주세요'),
  recipientType: yup.mixed<RecipientType>().oneOf(Object.values(RecipientType)).required(),
  subject: yup.string().required('제목을 입력해주세요.'),
});
