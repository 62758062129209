export default {
  forms: {
    email: {
      title: '픽미 계정을 만들어주세요',
      fields: {
        email: {
          placeholder: '이메일',
        },
      },
      errorMessages: {
        wrongEmail: '올바른 이메일 형식이 아닙니다',
        alreadySigned: '이미 존재하는 이메일입니다.',
      },
      buttons: {
        signin: '로그인',
        submit: '다음 단계로',
      },
      labels: {
        signin: '이미 계정이 있으신가요?',
      },
    },
    userInfo: {
      title: '기본 정보를 입력해주세요',
      fields: {
        phoneNumber: {
          placeholder: '전화번호',
        },
        name: { placeholder: '이름' },
        password: { placeholder: '비밀번호(8~20자리 영문 + 숫자 + 특수문자)' },
      },
      errorMessages: {
        phoneNumber: '전화번호 형식을 확인하세요',
        password: '8~20자리의 영문 + 숫자 + 특수문자(@,$,!,%,*,#,?,&)의 조합으로 입력 가능합니다',
      },
      buttons: {
        agree: '모두 동의합니다.',
        signin: '로그인',
        submit: '다음 단계로',
      },
      labels: {
        signin: '이미 계정이 있으신가요?',
      },
      terms: [
        ['이용약관', '에 동의합니다 (필수)'],
        ['개인정보 처리 방침', '에 동의합니다 (필수)'],
        ['마케팅 메시지 수신', '에 동의합니다 (선택)'],
      ],
    },
  },
};
