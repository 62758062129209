export default {
  additional: {
    status: '상태',
    admin: '관리자',
  },

  basic: {
    title: '기본 정보',
    openVote: '기명 설문조사',
    hiddenVote: '무기명 설문조사',
    period: {
      label: '기간',
      value: '{{startDate}} ~ {{endDate}}',
    },
    content: {
      label: '내용',
      blank: '입력한 내용이 없습니다.',
    },
    cover: {
      label: '커버 이미지',
    },
    public: {
      label: '공개 여부',
      open: '공개',
      hidden: '비공개',
    },
  },

  poll: {
    title: '투표 정보',
  },

  voter: {
    title: '대상자 명단',
    instantVoter: ['설문조사 개표 후 최종 참여자 명단을 다운로드 받을 수 있어요.'],
    voterBook: {
      format: 'YYYY년 M월 DD일 HH시',
      deleted:
        '{{date}} 개인정보가 파기됨에 따라 대상자 명단과 참여자 명단을 더 이상 다운로드 받을 수 없어요.',
      notDeleted:
        '{{date}} 개인정보가 파기될 예정이에요. 개인정보가 파기되면 {{prefix}}참여자 명단을 더 이상 다운로드할 수 없어요. ',
      target: '대상자 명단과 ',
    },
    remove: '개인정보 파기',
  },

  setting: {
    title: '고급 설정',
    result: '실시간 결과 보기',
    countable: '개표 가능한 투표율',
    authMethods: {
      label: '참여자 본인 인증 수단 설정',
      email: '이메일',
      phone: '전화번호',
      all: '전화번호 + 이메일',
    },
    realTime: {
      instantVoter: '실시간 참여자 수 공개',
      rate: '실시간 투표율 공개',
    },
    count: '결과 공개',
    openVotes: '응답 신원 매칭',
  },
};
