import { useEffect } from 'react';
import { Modal, ModalHeader } from '@pickme/ui';

import AlimTalkDetail from 'components/features/notification/modal/messaging-detail/common/AlimTalkDetail';
import LmsDetail from 'components/features/notification/modal/messaging-detail/common/LmsDetail';
import EmailDetail from 'components/features/notification/modal/messaging-detail/common/EmailDetail';

import { useCancelReservation } from 'query-hooks/message';

import { useGetActiveSpace } from 'hooks/useGetActiveSpace';

import { MessagingType, MessagingMethod, PollMessaging } from 'types/message';

import { modalBody } from '../common.css';

type Props = {
  messaging: PollMessaging | null;
  isVisible: boolean;
  onClose: () => void;
};

const MESSAGING_DETAIL = {
  [MessagingMethod.AlimTalk]: AlimTalkDetail,
  [MessagingMethod.Lms]: LmsDetail,
  [MessagingMethod.Email]: EmailDetail,
};

function ReservedMessagingDetailModal({ messaging, isVisible, onClose }: Props) {
  const space = useGetActiveSpace();
  const { mutate, isSuccess } = useCancelReservation();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  if (messaging === null) {
    return null;
  }

  const MessagingDetail = MESSAGING_DETAIL[messaging.detail.type];

  const onReservationCancel = () => {
    if (!messaging) {
      return;
    }

    mutate(messaging._id);
  };

  return (
    <Modal isVisible={isVisible} onClose={onClose} size='lg'>
      <ModalHeader title='예약 상세 내역' onClose={onClose} size='h4' />

      <div className={modalBody}>
        <MessagingDetail
          spaceName={space?.name || ''}
          type={MessagingType.Reserve}
          messaging={messaging}
          onReservationCancel={onReservationCancel}
        />
      </div>
    </Modal>
  );
}

export default ReservedMessagingDetailModal;
