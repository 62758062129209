import { Text } from '@pickme/ui';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { t } from 'i18next';

import { spaceState } from 'states/space';

import OptimizeIcon from 'resources/icons/common/optimize.png';

import './index.scss';

type Props = {
  type: 'subscribe' | 'contact';
};

function DisabledFeatureBanner({ type }: Props) {
  const activeSpaceId = useRecoilValue(spaceState.activeSpaceId);

  return (
    <div className='disabled-feature-banner'>
      <img src={OptimizeIcon} alt='plan' />

      <Text type='b3' fontWeight={400} color='white'>
        {type === 'subscribe'
          ? t('admin:components.features.banner.disabledFeature.description.default')
          : t('admin:components.features.banner.disabledFeature.description.enterprise')}
      </Text>

      <Link
        to={
          type === 'subscribe'
            ? `${import.meta.env.VITE_PAYMENT_URL}/guide?organizationId=${activeSpaceId}`
            : `${import.meta.env.VITE_INTRO_URL}/contact`
        }
        onClick={(event) => {
          if (type !== 'subscribe') {
            // TODO: 링크가 의미 없으므로 버튼으로 변경 할 것.
            event.preventDefault();
            // @ts-ignore
            window.ChannelIO('showMessenger');
          }
        }}
      >
        <Text type='b3' fontWeight={400} color='white'>
          {type === 'subscribe'
            ? t('admin:components.features.banner.disabledFeature.button.default')
            : t('admin:components.features.banner.disabledFeature.button.enterprise')}
        </Text>
      </Link>
    </div>
  );
}

export default DisabledFeatureBanner;
