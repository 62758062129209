/* eslint-disable max-classes-per-file */
export class ValidationError extends Error {}
export class CrossSessionError extends Error {}

export class VoterError extends Error {
  fields: string[] = [];

  constructor(message: string, fields: string[]) {
    super(message);
    this.fields = fields;
  }
}

export class InsufficientRequestHeaderError extends Error {
  code: string = '';

  constructor(code: string) {
    super('');
    this.code = code;
  }
}
