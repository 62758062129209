import account from './account';
import auth from './auth';
import board from './board';
import dashboard from './dashboard';
import integration from './integration';
import resources from './resources';
import notification from './notification';
import organization from './organization';
import payment from './payment';
import poll from './poll';

export default {
  account,
  auth,
  board,
  dashboard,
  integration,
  notification,
  organization,
  payment,
  poll,
  resources,
};
