export default {
  useGetRegisterToken: {
    onSuccess: {
      toast: '인증번호가 발송되었습니다.',
    },
    onError: {
      toast: '입력 내용을 확인해주세요',
    },
  },
  useRegisterChannel: {
    onSuccess: { toast: '성공적으로 업데이트되었습니다.' },
    onError: { toast: '인증번호를 확인해주세요' },
  },
};
