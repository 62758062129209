import { Plan } from '@pickme/core';
import { useForm } from 'react-hook-form';

import {
  BasicFormBody,
  SubElectionsFormBody,
  VoterFormBody,
  AdditionalFormBody,
  QuestionsFormBody,
  VoterAuthMethod,
} from 'types/application';

export function useElectionForm() {
  const basicForm = useForm<BasicFormBody>({
    defaultValues: {
      name: '',
      periods: [{ startDate: '', endDate: '' }],
      description: '',
      type: 'Election',
      voterType: 'voterBook',
      subscriptionType: Plan.Free,
      coverImageFileUrl: '',
      isPublic: false,
    },
    mode: 'onChange',
  });

  const subElectionsForm = useForm<SubElectionsFormBody>({
    defaultValues: {
      subElections: [],
    },
    mode: 'onChange',
  });

  const votersForm = useForm<VoterFormBody>({
    defaultValues: {
      voterBook: [],
      incorrectVoters: [],
    },
    mode: 'onChange',
  });

  const additionalForm = useForm<AdditionalFormBody>({
    defaultValues: {
      isOpenPollRate: false,
      isOpenPollResult: false,
      openThreshold: 0,
      voterAuthMethods: [VoterAuthMethod.Email, VoterAuthMethod.Phone],
    },
  });

  return {
    basicForm,
    subElectionsForm,
    votersForm,
    additionalForm,
  };
}

export function useSurveyForm() {
  const basicForm = useForm<BasicFormBody>({
    defaultValues: {
      name: '',
      periods: [{ startDate: '', endDate: '' }],
      description: '',
      type: 'Survey',
      voterType: 'voterBook',
      subscriptionType: Plan.Free,
      coverImageFileUrl: '',
      isPublic: false,
    },
    mode: 'onChange',
  });

  const questionsForm = useForm<QuestionsFormBody>({
    defaultValues: {
      questions: [],
    },
    mode: 'onChange',
  });

  const votersForm = useForm<VoterFormBody>({
    defaultValues: {
      voterBook: [],
      incorrectVoters: [],
      hasInstantVoter: false,
    },
    mode: 'onChange',
  });

  const additionalForm = useForm<AdditionalFormBody>({
    defaultValues: {
      allowRealTimeResult: false,
      isOpenPollRate: false,
      isOpenPollResult: false,
      openThreshold: 0,
      hasOpenVotes: false,
      voterAuthMethods: [VoterAuthMethod.Email, VoterAuthMethod.Phone],
    },
  });

  return {
    basicForm,
    questionsForm,
    votersForm,
    additionalForm,
  };
}
