import { Dropdown } from '@pickme/ui';
import { t } from 'i18next';

import MoreIcon from 'resources/icons/application/more.png';
import EditIcon from 'resources/icons/application/edit.png';
import RemoveIcon from 'resources/icons/application/remove.png';

import './index.scss';

type Props = {
  fileUrl: string;
  onEditButtonClick: () => void;
  onRemoveButtonClick: () => void;
};

function ImageField({ fileUrl, onEditButtonClick, onRemoveButtonClick }: Props) {
  return (
    <div className='poll-application__image-field'>
      <img className='poll-application__image-field__image' src={fileUrl} alt='img' />

      <div className='poll-application__image-field__dropdown'>
        <Dropdown
          directionBase='bottom'
          direction='down'
          button={
            <img
              className='poll-application__image-field__dropdown__icon'
              src={MoreIcon}
              alt='more'
            />
          }
          items={[
            {
              text: t('admin:components.features.application.fields.image.buttons.edit'),
              type: 'button',
              icon: EditIcon,
              onClick: () => {
                onEditButtonClick();
              },
            },
            {
              text: t('admin:components.features.application.fields.image.buttons.remove'),
              type: 'button',
              icon: RemoveIcon,
              color: 'red',
              onClick: () => {
                onRemoveButtonClick();
              },
            },
          ]}
        />
      </div>
    </div>
  );
}

export default ImageField;
