import { Text } from '@pickme/design-system';

import SocialIconButton from 'components/features/auth/SocialIconButton';

import { socialSignIn } from 'functions/auth';

import { SocialProvider } from 'types/auth';

import { social, title } from './index.css';

function SocialSignInButtons() {
  return (
    <div>
      <Text className={title} size={16} fontWeight={500}>
        SNS 계정으로 로그인하기
      </Text>

      <div className={social.container}>
        <SocialIconButton
          provider={SocialProvider.Google}
          className={social.icon}
          onClick={() => socialSignIn(SocialProvider.Google)}
        />

        <SocialIconButton
          provider={SocialProvider.Kakao}
          className={social.icon}
          onClick={() => socialSignIn(SocialProvider.Kakao)}
        />

        <SocialIconButton
          provider={SocialProvider.Naver}
          className={social.icon}
          onClick={() => socialSignIn(SocialProvider.Naver)}
        />
      </div>
    </div>
  );
}

export default SocialSignInButtons;
