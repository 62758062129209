import { DraftTemplate, DraftTemplateCategory } from 'types/template';

import Thumbnail1 from 'resources/images/templates/election/association1.png';
import Thumbnail2 from 'resources/images/templates/election/association2.png';

const DRAFT_1 = {
  title: '학회 차기회장 선출 투표',
  _id: '64991f12a5f0ec00830be97d',
  content: {
    name: '학회 차기회장 선출 투표',
    periods: [
      {
        startDate: '2024-04-01T17:02',
        endDate: '2024-04-19T17:02',
      },
    ],
    description:
      '<p>여러분 안녕하십니까.</p><p>학회 차기회장 선출 투표 안내드립니다.</p><p>여러분들의 소중한 한 표 부탁드립니다.</p><p><br></p><p>감사합니다.</p>',
    type: 'Election',
    voterType: 'voterBook',
    subscriptionType: 'enterprise',
    subElections: [
      {
        kind: 'ChoiceElection',
        title: '차기회장 선출 투표',
        shuffle: true,
        candidates: [
          {
            title: '기호 1. 나상혁',
            description:
              '<p>회원 여러분 안녕하세요. 차기 학회장 후보로 추천된 픽미대학교 나상혁 교수입니다.</p><p>제가 차기 학회장으로 선출된다면 우리 학회 발전을 위해 최선을 다할 것을 약속드립니다.</p><p>여러분들의 소중한 한 표 부탁드립니다. 감사합니다.</p><p><br></p><p><strong>후보자 정보</strong></p><p>- 픽미공과대학 산업공학 졸업</p><p>- 픽미과학기술원 경영대학 졸업</p><p>- 픽미대학교 교수 (~현재)</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '나상혁 (56세)',
                fileUrl: 'https://api.ohpick.me/api/file/64991d46a5f0ec00830be95f',
              },
            ],
            tags: [],
            id: '4629f6ad-bb6c-48d9-9c98-b547f70bbfb5',
          },
          {
            title: '기호 2. 박학웅',
            description:
              '<p>회원 여러분 안녕하세요. 차기 학회장 후보로 추천된 픽미대학교 박학웅 교수입니다.</p><p>먼저 학회를 아끼시고 헌신해 주신 학회 회원분들과 전임 학회장님들, 수고를 아끼지 않으시는 학회 임직원분들께 우선 감사의 말씀을 올립니다.&nbsp;</p><p>제가 차기 학회장으로 선출된다면 학회에 활기를 불어넣어 활발한 활동을 할 것을 약속드립니다.</p><p>여러분들의 소중한 한 표 부탁드립니다. 감사합니다.</p><p><br></p><p><strong>후보자 정보</strong></p><p>- 픽미대학교 경영공학 졸업</p><p>- 픽미대학교 교수 (~현재)</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '박학웅 (57세)',
                fileUrl: 'https://api.ohpick.me/api/file/64991c55a5f0ec00830be94c',
              },
            ],
            tags: [],
            id: '78bcd7b5-c36a-4ab5-8f55-77766ff2eca3',
          },
        ],
        responseValidation: {
          allowAbstentionVote: true,
          minResponse: 1,
          maxResponse: 1,
        },
        id: '1eef6e5d-72b9-4fa5-8c01-bd8afec1010d',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'election',
  createdAt: '2023-06-26T05:16:02.390Z',
  __v: 0,
};

const DRAFT_2 = {
  title: '협회 대의원 선출 투표',
  _id: '64b4c31175c32e0047480698',
  content: {
    name: '협회 대의원 선출 투표',
    periods: [
      {
        startDate: '2023-07-18T11:20',
        endDate: '2023-07-28T13:16',
      },
    ],
    description:
      '<p>협회원 여러분 안녕하세요.</p><p>본 투표는 협회 대의원 선출 투표입니다. 협회원님들의 소중한 한 표 부탁드립니다.</p><p><br></p><p>감사합니다.</p>',
    type: 'Election',
    voterType: 'voterBook',
    subscriptionType: 'enterprise',
    subElections: [
      {
        kind: 'ChoiceElection',
        title: '다음 입후보한 후보 중 3명을 선택해 주세요.',
        shuffle: false,
        candidates: [
          {
            title: '후보 1. 김수연',
            description:
              '<p>안녕하세요. 이번 협회 대의원 선거에 입후보한 사업팀 김수연입니다.</p><p>여러분들의 소중한 한 표 부탁드립니다. 감사합니다!</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '김수연',
                fileUrl: 'https://api.ohpick.me/api/file/64b4c23875c32e004748065a',
              },
            ],
            tags: ['#사업팀'],
            id: '2b9f2b8f-ad3d-4615-88dd-ef92d51658fd',
          },
          {
            title: '후보 2. 박성현',
            description:
              '<p>안녕하세요. 이번 협회 대의원 선거에 입후보한 기획팀 박성현입니다.</p><p>여러분들의 소중한 한 표 부탁드립니다. 감사합니다!</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '박성현',
                fileUrl: 'https://api.ohpick.me/api/file/64b4c23f75c32e0047480661',
              },
            ],
            tags: ['#기획팀'],
            id: '85f70300-9d35-4b51-98d0-e704ad753868',
          },
          {
            title: '후보 3. 한인준',
            description:
              '<p>안녕하세요. 이번 협회 대의원 선거에 입후보한 총무팀 한인준입니다.</p><p>여러분들의 소중한 한 표 부탁드립니다. 감사합니다!</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '한인준',
                fileUrl: 'https://api.ohpick.me/api/file/64b4c24675c32e0047480668',
              },
            ],
            tags: ['#총무팀'],
            id: 'e6189020-a1ca-4f89-8771-fd13fbd6ebba',
          },
          {
            title: '후보 4. 최수헌',
            description:
              '<p>안녕하세요. 이번 협회 대의원 선거에 입후보한 평가팀 최수헌입니다.</p><p>여러분들의 소중한 한 표 부탁드립니다. 감사합니다!</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '최수헌',
                fileUrl: 'https://api.ohpick.me/api/file/64b4c24c75c32e004748066f',
              },
            ],
            tags: ['#평가팀'],
            id: '95788175-3f21-45c6-8ffd-d7a97598c5fe',
          },
          {
            title: '후보 5. 박지혜',
            description:
              '<p>안녕하세요. 이번 협회 대의원 선거에 입후보한 법무팀 박지혜입니다.</p><p>여러분들의 소중한 한 표 부탁드립니다. 감사합니다!</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '박지혜',
                fileUrl: 'https://api.ohpick.me/api/file/64b4c25575c32e0047480676',
              },
            ],
            tags: ['#법무팀'],
            id: 'fcfed29e-e8d5-400c-9ded-b99abc485f42',
          },
          {
            title: '후보 6. 김현욱',
            description:
              '<p>안녕하세요. 이번 협회 대의원 선거에 입후보한 전산팀 김현욱입니다.</p><p>여러분들의 소중한 한 표 부탁드립니다. 감사합니다!</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '김현욱',
                fileUrl: 'https://api.ohpick.me/api/file/64b4c26975c32e004748067d',
              },
            ],
            tags: ['#전산팀'],
            id: '405e8310-eae4-4325-b15e-a9a595913534',
          },
          {
            title: '후보 7. 김아름',
            description:
              '<p>안녕하세요. 이번 협회 대의원 선거에 입후보한 미디어팀 김아름입니다.</p><p>여러분들의 소중한 한 표 부탁드립니다. 감사합니다!</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '김아름',
                fileUrl: 'https://api.ohpick.me/api/file/64b4c27b75c32e0047480684',
              },
            ],
            tags: ['#미디어팀'],
            id: 'dbf07802-20c4-4dfe-8260-07d85475101d',
          },
        ],
        responseValidation: {
          allowAbstentionVote: false,
          minResponse: 3,
          maxResponse: 3,
        },
        id: 'a8af0647-95d0-4a86-aaea-f6db4c622946',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'election',
  createdAt: '2023-07-17T04:26:57.302Z',
  __v: 0,
};

export default [
  {
    title: '학회 차기회장 선출 투표',
    description: '학회 차기회장 선출 투표를 위한 템플릿입니다.',
    thumbnail: Thumbnail1,
    draft: DRAFT_1,
    category: DraftTemplateCategory.Association,
    kind: 'Election',
  },
  {
    title: '협회 대의원 선출 투표',
    description: '협회 대의원 선출 투표를 위한 템플릿입니다.',
    thumbnail: Thumbnail2,
    draft: DRAFT_2,
    category: DraftTemplateCategory.Association,
    kind: 'Election',
  },
] as DraftTemplate[];
