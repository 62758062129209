import { createHttpRequest } from 'apis/common';

import { Channel, ChannelCategory, ChannelRegistrationFormBody } from 'types/channel';
import { Apis } from 'types/api';

export const CHANNEL_APIS: Apis = {
  getChannelCategories: { method: 'GET', url: '/api/messaging/channel/registration/category' },
  getRegistrationToken: { method: 'POST', url: '/api/messaging/channel/registration/token' },
  registerChannel: { method: 'POST', url: '/api/messaging/channel/registration' },
  getChannel: { method: 'GET', url: '/api/messaging/channel' },
  removeChannel: { method: 'DELETE', url: '/api/messaging/channel' },
};

export const createGetChannelCategoriesRequest = () =>
  createHttpRequest<ChannelCategory[]>({ ...CHANNEL_APIS.getChannelCategories });

export const createGetRegistrationTokenRequest = (form: {
  searchId: string;
  phoneNumber: string;
}) => createHttpRequest<{ message: 'ok' }>({ ...CHANNEL_APIS.getRegistrationToken, data: form });

export const createRegisterChannelRequest = (form: ChannelRegistrationFormBody) =>
  createHttpRequest<{ message: 'ok' }>({ ...CHANNEL_APIS.registerChannel, data: form });

export const createGetChannelRequest = () =>
  createHttpRequest<Channel>({ ...CHANNEL_APIS.getChannel });

export const createRemoveChannelRequest = () =>
  createHttpRequest<Channel>({ ...CHANNEL_APIS.removeChannel });
