import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

import { useCreatePurchasePoint, useReplaceCard } from 'query-hooks/payment';
import { useTakeOverSpaceWithPayment } from 'query-hooks/space';

import { toast } from 'states/toast';

import { usePaymentCallbackQueryString } from 'hooks/usePaymentCallbackQueryString';

import { PointOrderForm } from 'types/payment';

import './PaymentSuccessCallback.scss';

function PaymentSuccessCallback() {
  const navigate = useNavigate();
  const queryString = usePaymentCallbackQueryString();

  const setToast = useSetRecoilState(toast);

  const { mutate: mutateTakeOverSpace, isLoading: isLoadingTakeOverSpace } =
    useTakeOverSpaceWithPayment();
  const { mutate: mutatePurchasePoint, isLoading: isLoadingPurchasePoint } =
    useCreatePurchasePoint();
  const { mutate: mutateReplaceCard, isLoading: isLoadingReplaceCard } = useReplaceCard();

  const isLoading = isLoadingTakeOverSpace || isLoadingPurchasePoint || isLoadingReplaceCard;

  const payForPoint = (orderForm: PointOrderForm, method?: string) => {
    if (!isLoading) {
      if (method === '카드' || method === '계좌이체' || method === '가상계좌') {
        mutatePurchasePoint(orderForm);
      }
    }
  };

  const handleHandSpaceOverCallback = () => {
    if (!queryString.subscription.authKey || !queryString.handSpaceOver.customerKey) {
      setToast({
        isVisible: true,
        type: 'error',
        message: t('admin:pages.payment.callback.errorMessages.wrongPath.message'),
      });
      navigate(-1);
      return;
    }

    mutateTakeOverSpace({
      authKey: queryString.subscription.authKey,
      customerKey: queryString.handSpaceOver.customerKey,
      organizationOid: queryString.handSpaceOver.organizationOid,
    });
  };

  const handlePointCallback = () => {
    const orderForm = {
      amount: queryString.point.amount,
      orderId: queryString.point.orderId,
      paymentKey: queryString.point.paymentKey,
    };

    payForPoint(orderForm, queryString.point.method);
  };

  const handleReplaceCardCallback = () => {
    if (!queryString.subscription.authKey) {
      setToast({
        isVisible: true,
        type: 'error',
        message: t('admin:pages.payment.callback.errorMessages.wrongPath.message'),
      });
      navigate('/settings/payment/subscription', { replace: true });
      return;
    }

    mutateReplaceCard({ authKey: queryString.subscription.authKey });
  };

  useEffect(() => {
    setTimeout(() => {
      if (queryString.product === 'hand-space-over') {
        handleHandSpaceOverCallback();
        return;
      }

      if (queryString.product === 'point') {
        handlePointCallback();
        return;
      }

      if (queryString.product === 'replaceCard') {
        handleReplaceCardCallback();
      }

      setToast({
        isVisible: true,
        type: 'error',
        message: t('admin:pages.payment.callback.errorMessages.failure.title'),
      });
      navigate('/', { replace: true });
    }, 1000);
  }, []);

  return (
    <div className='payment-waiting'>
      <Spinner />
    </div>
  );
}

export default PaymentSuccessCallback;
