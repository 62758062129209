import { Fragment, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Text, Button, PlainButton } from '@pickme/design-system';
import { ManagerRoleTitleV2, PlanTitleV2, SocialTitleV2 } from '@pickme/core';

import ChannelName from 'components/features/channel-v2/section/setting/Name';
import ChannelLogo from 'components/features/channel-v2/section/setting/Logo';
import ChannelCover from 'components/features/channel-v2/section/setting/Cover';
import ChannelDescription from 'components/features/channel-v2/section/setting/Description';
import ChannelAlias from 'components/features/channel-v2/section/setting/Alias';
import ChannelSocialLink from 'components/features/channel-v2/section/setting/SocialLink';
import LeaveChannelModal from 'components/features/channel-v2/modal/Leave';
import RemoveChannelModal from 'components/features/channel-v2/modal/Remove';

import { useEditChannel, useLeaveChannel, useRemoveChannel } from 'query-hooks/channel-v2';
import { useGetProfile } from 'query-hooks/auth-v2';

import { useActiveChannel } from 'hooks/useActiveChannel';

import { toast } from 'states/toast';

import { ChannelEditForm } from 'types/channel-v2';

import Instagram from 'resources/icons/channel-v2/instagram.png';
import Facebook from 'resources/icons/channel-v2/facebook.png';
import Youtube from 'resources/icons/channel-v2/youtube.png';
import Arrow from 'resources/icons/channel-v2/arrow.svg';

import style from './index.css';

function ChannelSettingsV2() {
  const setToast = useSetRecoilState(toast);

  const { activeChannel: channel, activeChannelId } = useActiveChannel();

  const { data: profile } = useGetProfile({ suspense: false });
  const currentChannelAndSubscription = profile?.activeChannelSummary;

  const managerRole = currentChannelAndSubscription?.admin.role;
  const isChannelAdmin = managerRole === ManagerRoleTitleV2.Admin;
  const isAbleToRemoveChannel =
    currentChannelAndSubscription?.subscription.plan.type === PlanTitleV2.Basic;

  const editChannel = useEditChannel();
  const removeChannel = useRemoveChannel();
  const leaveChannel = useLeaveChannel();

  const [leaveModal, setLeaveModal] = useState({ isVisible: false });
  const [removeModal, setRemoveModal] = useState({ isVisible: false });

  const onSubmit = (data: Partial<ChannelEditForm>) => {
    if (!channel) {
      return;
    }

    const channelValues: ChannelEditForm = {
      alias: channel.alias,
      name: channel.name,
      description: channel.description || '',
      logo: channel.logo || '',
      cover: channel.cover || '',
      socials: channel.socials,
    };

    editChannel.mutate({ id: activeChannelId, form: { ...channelValues, ...data } });
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <Text size={32} fontWeight={600}>
          일반 설정
        </Text>

        <div className={style.buttons}>
          <a href={`${import.meta.env.VITE_MAIN_URL}/${channel?.alias || ''}/channel`}>
            <Button size='sm' variant='gray'>
              채널 바로가기
              <img src={Arrow} alt='arrow' />
            </Button>
          </a>
        </div>
      </div>

      <div className={style.body}>
        <div className={style.field.container()}>
          <Label>채널 이름</Label>
          <ChannelName
            name={channel?.name || ''}
            onSubmit={onSubmit}
            isLoading={editChannel.isLoading}
            canEdit={isChannelAdmin}
          />
        </div>

        <div className={style.field.container({ align: 'center' })}>
          <Label>채널 프로필</Label>
          <ChannelLogo
            logo={channel?.logo || ''}
            onSubmit={onSubmit}
            isLoading={editChannel.isLoading}
            canEdit={isChannelAdmin}
          />
        </div>

        <div className={style.field.container({ align: 'center' })}>
          <Label>커버 이미지</Label>

          <div className={style.field.main}>
            <ChannelCover
              cover={channel?.cover || ''}
              onSubmit={onSubmit}
              isLoading={editChannel.isLoading}
              canEdit={isChannelAdmin}
            />
          </div>
        </div>

        <div className={style.field.container()}>
          <Label>채널 소개글 </Label>

          <div className={style.field.main}>
            <ChannelDescription
              description={channel?.description || ''}
              onSubmit={onSubmit}
              isLoading={editChannel.isLoading}
              canEdit={isChannelAdmin}
            />
          </div>
        </div>

        <div className={style.field.container()}>
          <Label>채널 주소</Label>
          <ChannelAlias
            alias={channel?.alias || ''}
            onSubmit={onSubmit}
            isLoading={editChannel.isLoading}
            canEdit={isChannelAdmin}
          />
        </div>

        <div className={style.field.container()}>
          <Label>SNS 연동</Label>

          <div className={style.field.main}>
            {channel?.socials.map(({ platform, url }) => {
              if (!isChannelAdmin && !url) {
                return <Fragment key={platform} />;
              }

              return (
                <ChannelSocialLink
                  key={platform}
                  icon={SOCIAL_ICONS[platform]}
                  title={platform}
                  url={url || ''}
                  onSubmit={(data) => {
                    const socials = channel?.socials
                      .slice()
                      .map((social) => (social.platform === platform ? data : social));

                    onSubmit({ socials });
                  }}
                  isLoading={editChannel.isLoading}
                  isAbleToEdit={isChannelAdmin}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className={style.leave.container}>
        <PlainButton
          type='button'
          onClick={() => {
            if (isChannelAdmin && !isAbleToRemoveChannel) {
              setToast({
                isVisible: true,
                type: 'error',
                message: '유료 플랜 구독 중엔 채널을 삭제할 수 없습니다.',
              });
              return;
            }

            setLeaveModal({ isVisible: true });
          }}
          disabled={removeChannel.isLoading || leaveChannel.isLoading}
        >
          <Text size={14} color='gray-400' className={style.leave.text}>
            {isChannelAdmin ? '채널 삭제' : '채널 나가기'}
          </Text>
        </PlainButton>
      </div>

      {managerRole && (
        <LeaveChannelModal
          isVisible={leaveModal.isVisible}
          onClose={() => setLeaveModal({ isVisible: false })}
          managerRole={managerRole}
          onSubmitButtonClick={() => {
            setLeaveModal({ isVisible: false });

            if (managerRole === ManagerRoleTitleV2.Admin) {
              setRemoveModal({ isVisible: true });
              return;
            }

            if (leaveChannel.isLoading) {
              return;
            }

            leaveChannel.mutate();
          }}
        />
      )}

      <RemoveChannelModal
        isVisible={removeModal.isVisible}
        onClose={() => setRemoveModal({ isVisible: false })}
        onSubmitButtonClick={() => {
          removeChannel.mutate();
        }}
        isDisable={removeChannel.isLoading}
      />
    </div>
  );
}

export default ChannelSettingsV2;

function Label({ children }: { children: React.ReactNode }) {
  return (
    <Text size={14} className={style.field.label}>
      {children}
    </Text>
  );
}

const SOCIAL_ICONS: {
  [key in SocialTitleV2]: string;
} = {
  [SocialTitleV2.Instagram]: Instagram,
  [SocialTitleV2.Facebook]: Facebook,
  [SocialTitleV2.Youtube]: Youtube,
  [SocialTitleV2.Blog]: '',
  [SocialTitleV2.ETC]: '',
};
