export const organizationNameValidator = (value: string) =>
  /^[ㄱ-ㅎ|가-힣|\s\-_()[\]|A-Za-z0-9]{2,30}$/.test(value);

export const organizationAliasValidator = (value: string) => /^[A-Za-z0-9]{2,20}$/.test(value);

export const instagramLinkValidator = (value: string) => /^https:\/\/(www\.)?instagram\.com\/[^\s]*$/.test(value);

export const facebookLinkValidator = (value: string) => /^https:\/\/(www\.)?facebook\.com\/[^\s]*$/.test(value);

export const youtubeLinkValidator = (value: string) => /^https:\/\/(www\.)?(youtube\.com|youtu\.be)\/[^\s]*$/.test(value);
