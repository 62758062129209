import { useFormContext } from 'react-hook-form';
import { PlainButton, Input, Dropdown, OutlineButton } from '@pickme/ui';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { t } from 'i18next';
import ReactHtmlParser from 'html-react-parser';
import { YouTubeURLParser } from '@pickme/core';

import ImageField from 'components/features/application/field/ImageField';
import ImageInputButton from 'components/features/application/field/ImageInput';

import { QuestionFormBody } from 'types/application';

import DraggableIcon from 'resources/icons/application/draggable.png';
import RemoveIcon from 'resources/icons/application/remove.png';

import ImageIcon from 'resources/icons/application/image.png';
import VideoIcon from 'resources/icons/application/video.svg';
import { useEffect } from 'react';

type Props = {
  index: number;
  id: string;
  isRemoveButtonVisible: boolean;
  onRemoveButtonClick: () => void;
  isDragging: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  onVideoButtonClick: (link: string | null) => void;
};

function SubQuestion({
  index,
  id,
  isRemoveButtonVisible,
  onRemoveButtonClick,
  isDragging,
  dragHandleProps,
  onVideoButtonClick,
}: Props) {
  const methods = useFormContext<QuestionFormBody>();
  const selectionFileUrl = methods.watch(`subQuestions.${index}.fileUrl`);
  const selectionVideoUrl = methods.watch(`subQuestions.${index}.videoUrl.youtube`);

  useEffect(() => {
    if (selectionFileUrl) {
      methods.setValue(`subQuestions.${index}.videoUrl.youtube`, undefined);
    }
  }, [selectionFileUrl]);

  useEffect(() => {
    if (selectionVideoUrl) {
      methods.setValue(`subQuestions.${index}.fileUrl`, undefined);
    }
  }, [selectionVideoUrl]);

  return (
    <div className={`question-form__selection ${index === 0 ? '' : 'margin'}`}>
      <div className='question-form__selection__row'>
        <div
          className={`question-form__selection__row__draggable ${!isDragging ? '' : 'active'}`}
          {...(dragHandleProps || {})}
          draggable
        >
          <img
            className='question-form__selection__row__draggable__icon'
            src={DraggableIcon}
            alt='more'
          />
        </div>

        <Input
          id={`draggable-${id}`}
          className='question-form__selection__input'
          placeholder={t(
            'admin:components.features.application.form.question.forms.question.fields.subQuestions.placeholder',
          )}
          {...methods.register(`subQuestions.${index}.description`, {
            validate: (value) => {
              if (!value) {
                return t(
                  'admin:components.features.application.form.question.errorMessages.subQuestions.required',
                );
              }

              return true;
            },
          })}
        />

        <div className='question-form__selection__button-groups'>
          <Dropdown
            button={
              <OutlineButton type='button' width={130} size='md'>
                {t('admin:components.features.application.form.question.buttons.media')}
              </OutlineButton>
            }
            items={[
              {
                text: t('admin:components.features.application.form.question.buttons.image'),
                type: 'button',
                icon: ImageIcon,
                onClick: () => {
                  document.getElementById(id)?.click();
                },
              },
              {
                text: t('admin:components.features.application.form.question.buttons.video'),
                type: 'button',
                icon: VideoIcon,
                onClick: () =>
                  onVideoButtonClick(
                    methods.getValues(`selections.${index}.videoUrl.youtube`) || null,
                  ),
              },
            ]}
            direction='down'
            directionBase='left'
          />

          <ImageInputButton
            id={id}
            hideButton
            onSubmit={(imageFileUrl) => {
              methods.setValue(`subQuestions.${index}.fileUrl`, imageFileUrl);
            }}
          />

          {isRemoveButtonVisible && (
            <PlainButton
              className='question-form__selection__button-groups__remove-button'
              onClick={() => onRemoveButtonClick()}
            >
              <img src={RemoveIcon} alt='remove' />
            </PlainButton>
          )}
        </div>
      </div>

      <input hidden {...methods.register(`subQuestions.${index}.fileUrl`)} />
      <input hidden {...methods.register(`selections.${index}.videoUrl.youtube`)} />

      {selectionVideoUrl
        ? ReactHtmlParser(
            new YouTubeURLParser(selectionVideoUrl).getIframe({
              allowFullScreen: true,
              width: 455,
              height: 300,
            }) || '',
          )
        : ''}

      {selectionFileUrl && (
        <ImageField
          fileUrl={selectionFileUrl}
          onEditButtonClick={() => {
            document.getElementById(id)?.click();
          }}
          onRemoveButtonClick={() => methods.setValue(`subQuestions.${index}.fileUrl`, '')}
        />
      )}
    </div>
  );
}

export default SubQuestion;
