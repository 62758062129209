export default {
  tooltip: {
    rank: {
      title: '가중치 점수',
      message: '가중치 점수란? 1위=3점, 2위 2점, 3위=1점을 부과하여 계산한 값이에요',
    },
  },
  labels: {
    blank: '표시할 내용이 없습니다.',
  },
};
