import { Plan, PollGroup } from '@pickme/core';
import { first, last } from 'lodash-es';
import moment from 'moment-timezone';

import { sendEventLog } from 'functions/analytics/common';

import { ElectionFormBodyForEdit, VoterAuthMethod } from 'types/application';
import { PollKind } from 'types/poll';

export const sendSelectPollTemplate = (value: string) => {
  sendEventLog('poll/template', {
    value,
  });
};

export const sendCreatePollEvent = ({
  pollId,
  voterAuthMethods,
  allowRealTimeResult,
  openThreshold,
  isOpenPollRate,
  isOpenPollResult,
  subPollCounts,
  kind,
  voters,
  plan,
  periods,
}: {
  pollId: string;
  kind: PollKind;
  voterAuthMethods: VoterAuthMethod[];
  allowRealTimeResult?: boolean;
  openThreshold?: number;
  isOpenPollRate?: boolean;
  isOpenPollResult?: boolean;
  subPollCounts: number;
  voters: number;
  plan: Plan;
  periods: {
    startDate: string;
    endDate: string;
  }[];
}) => {
  const startDate = first(periods)?.startDate;
  const endDate = last(periods)?.endDate;

  const startDifferenceInHour = moment()
    .tz('Asia/Seoul')
    .diff(moment.tz(startDate, 'Asia/Seoul'), 'hours');
  const durationInHour = moment
    .tz(startDate, 'Asia/Seoul')
    .diff(moment.tz(endDate, 'Asia/Seoul'), 'hours');

  sendEventLog('poll/create', {
    pollId,
    kind,
    voterAuthMethods,
    allowRealTimeResult,
    openThreshold,
    isOpenPollRate,
    isOpenPollResult,
    subPollCounts,
    date: new Date(),
    voters,
    plan,
    startDate,
    endDate,
    startDifferenceInHour,
    durationInHour,
  });
};

export const sendDraftEvent = ({ kind }: { kind: any }) => {
  sendEventLog('poll/draft', {
    kind,
    date: new Date(),
  });
};

export const sendEditPollEvent = ({
  pollId,
  kind,
  editFields,
}: {
  pollId: string;
  kind: PollKind;
  editFields: (keyof ElectionFormBodyForEdit)[];
}) => {
  sendEventLog('poll/edit', {
    pollId,
    kind,
    date: new Date(),
    editFields,
  });
};

export const sendPollPauseEvent = ({
  pollId,
  kind,
  periods,
}: {
  pollId: string;
  kind: PollKind;
  periods: {
    startDate: string;
    endDate: string;
  }[];
}) => {
  const startDate = first(periods)?.startDate;
  const endDate = last(periods)?.endDate;

  sendEventLog('poll/pause', {
    pollId,
    kind,
    date: new Date(),
    startDate,
    endDate,
  });
};

export const sendPollRestartEvent = ({
  pollId,
  kind,
  periods,
}: {
  pollId: string;
  kind: PollKind;
  periods: {
    startDate: string;
    endDate: string;
  }[];
}) => {
  const startDate = first(periods)?.startDate;
  const endDate = last(periods)?.endDate;

  sendEventLog('poll/restart', {
    pollId,
    kind,
    date: new Date(),
    startDate,
    endDate,
  });
};

export const sendPollRemoveEvent = ({
  pollId,
  kind,
  group,
}: {
  pollId: string;
  kind: PollKind;
  group: PollGroup;
}) => {
  sendEventLog('poll/remove', {
    pollId,
    kind,
    date: new Date(),
    group,
  });
};

export const sendPollOrderEvent = ({
  pollId,
  kind,
  voters,
  plan,
  periods,
}: {
  pollId: string;
  kind: PollKind;
  voters: number;
  plan: Plan;
  periods: {
    startDate: string;
    endDate: string;
  }[];
}) => {
  const startDate = first(periods)?.startDate;
  const endDate = last(periods)?.endDate;

  sendEventLog('poll/order', {
    pollId,
    kind,
    date: new Date(),
    voters,
    plan,
    startDate,
    endDate,
  });
};

export const sendPollEarlyExitEvent = ({ pollId, kind }: { pollId: string; kind: PollKind }) => {
  sendEventLog('poll/early-exit', {
    pollId,
    kind,
  });
};
