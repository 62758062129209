import { Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { t } from 'i18next';

import FaceIcon from 'resources/icons/application/face.png';

import './index.scss';

type Props = {
  isVisible: boolean;
  kind: 'election' | 'survey';
  type: 'application' | 'edit';
  onClose: () => void;
};

// TODO: Consider edit.
function CompleteModal({ isVisible, kind, type, onClose }: Props) {
  return (
    <Modal
      className='application-form__success-modal'
      isVisible={isVisible}
      onClose={onClose}
      size='md'
    >
      <div className='application-form__success-modal__confetti'>
        <div className='application-form__success-modal__confetti-piece' />
        <div className='application-form__success-modal__confetti-piece' />
        <div className='application-form__success-modal__confetti-piece' />
        <div className='application-form__success-modal__confetti-piece' />
        <div className='application-form__success-modal__confetti-piece' />
        <div className='application-form__success-modal__confetti-piece' />
        <div className='application-form__success-modal__confetti-piece' />
        <div className='application-form__success-modal__confetti-piece' />
        <div className='application-form__success-modal__confetti-piece' />
        <div className='application-form__success-modal__confetti-piece' />
        <div className='application-form__success-modal__confetti-piece' />
        <div className='application-form__success-modal__confetti-piece' />
        <div className='application-form__success-modal__confetti-piece' />
      </div>

      <img className='application-form__success-modal__image' src={FaceIcon} alt='face' />

      <ModalHeader
        size='h4'
        centered
        hideCloseButton
        title={getTitle(kind, type)}
        onClose={onClose}
      />

      <ModalBody centered>
        <Text type='b3' fontWeight={400} color='gray-400'>
          {getMessage(kind, type)}
        </Text>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t('admin:components.features.application.modal.complete.actions.close'),
            onClick: onClose,
          },
        ]}
      />
    </Modal>
  );
}

export default CompleteModal;

function getTitle(kind: 'election' | 'survey', type: 'application' | 'edit') {
  if (kind === 'election') {
    if (type === 'application') {
      return t('admin:components.features.application.modal.complete.title.election.apply');
    }

    return t('admin:components.features.application.modal.complete.title.election.edit');
  }

  if (type === 'application') {
    return t('admin:components.features.application.modal.complete.title.survey.apply');
  }

  return t('admin:components.features.application.modal.complete.title.survey.edit');
}

function getMessage(kind: 'election' | 'survey', type: 'application' | 'edit') {
  if (kind === 'election') {
    if (type === 'application') {
      return t('admin:components.features.application.modal.complete.description.election.apply');
    }

    return t('admin:components.features.application.modal.complete.description.election.edit');
  }

  if (type === 'application') {
    return t('admin:components.features.application.modal.complete.description.survey.apply');
  }

  return t('admin:components.features.application.modal.complete.description.survey.edit');
}
