export default {
  useParticipatePromotion: {
    onError: {
      modal: {
        title: '설문조사 실패',
      },
    },
  },

  makeHTML: {
    h3: '관리자 설문조사 응답 결과',
    format: 'YYYY년 MM월 DD일 HH:mm',
  },

  makeDescriptionAboutUser: {
    h3: '참여자 정보',
    email: '이메일',
    name: '이름',
    phone: '전화번호',
    blank: '없음',
    member: '유저 유형',
    organizationName: '스페이스명',
  },
};
