import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Callout, Table, Pagination, PlainButton, Text } from '@pickme/design-system';
import { PrimaryBadge } from '@pickme/ui';

import Spinner from 'components/common/Spinner';
import MessagingMethodSelect from 'components/features/notification/history/MessagingMethodSelect';
import MonthSelect from 'components/features/notification/history/MonthSelect';
import NotificationEmptyStateView from 'components/features/notification/EmptyStateView';
import SentMessagingDetailModal from 'components/features/notification/modal/messaging-detail/Sent';

import { useGetMessages } from 'query-hooks/message';

import { MESSAGING_METHOD_LABELS, MESSAGING_STATUS_LABELS } from 'constants/notification';

import { MessagingSearchForm, MessagingStatus, PollMessaging } from 'types/message';

import { useMessagingSearchParams } from '../useMessagingSearchParams';

import { container, search, alignCenter, tableRow } from '../common.css';

function Sent() {
  const [detailModal, setDetailModal] = useState<{
    isVisible: boolean;
    messaging: PollMessaging | null;
  }>({
    isVisible: false,
    messaging: null,
  });

  const methods = useForm<MessagingSearchForm>({
    defaultValues: DEFAULT_VALUES,
  });

  const [params, setParams] = useMessagingSearchParams(DEFAULT_VALUES);
  const { data: messagesData, isLoading } = useGetMessages(params);
  const isTableVisible = isLoading || (messagesData && messagesData.results.length > 0);

  const onFormSubmit = methods.handleSubmit((data) => setParams({ ...data, page: 1 }));

  useEffect(() => {
    methods.reset(params);
  }, [params.page, params.methodType, params.month]);

  return (
    <div className={container}>
      <Callout>
        <Callout.Sentence>
          <b>전송 요청</b> : 접수가 완료된 메시지를 전송 요청한 상태입니다. 메시지 타입에 따라서
          해당 상태가 최대 72시간 동안 지속될 수 있어요.
        </Callout.Sentence>

        <Callout.Sentence>
          <b>전송 완료</b> : 전송 요청한 메시지의 결과를 모두 전달 받은 상태입니다. 전송 실패건은
          포인트 환급이 이루어집니다.
        </Callout.Sentence>

        <Callout.Sentence>최근 6개월 내의 전송 이력만 조회 가능합니다.</Callout.Sentence>
      </Callout>

      <form className={search} onSubmit={onFormSubmit}>
        <Controller
          control={methods.control}
          name='methodType'
          render={({ field }) => (
            <MessagingMethodSelect
              {...field}
              selected={field.value}
              onSelect={(methodType) => {
                field.onChange(methodType);
                onFormSubmit();
              }}
            />
          )}
        />

        <Controller
          control={methods.control}
          name='month'
          render={({ field }) => (
            <MonthSelect
              {...field}
              selected={field.value}
              onSelect={(month) => {
                field.onChange(month);
                onFormSubmit();
              }}
            />
          )}
        />
      </form>

      {isTableVisible ? (
        <>
          <Table>
            <colgroup>
              <col />
              <col width={108} />
              <col width={156} />
              <col width={96} />
              <col width={96} />
              <col width={96} />
            </colgroup>

            <Table.Header>
              <Table.Tr>
                <Table.Th textAlign='start'>제목</Table.Th>
                <Table.Th>타입</Table.Th>
                <Table.Th>전송 일시</Table.Th>
                <Table.Th>포인트</Table.Th>
                <Table.Th>상태</Table.Th>
                <Table.Th>내역</Table.Th>
              </Table.Tr>
            </Table.Header>

            <Table.Body>
              {isLoading ? (
                <Table.Tr>
                  <Table.Td colSpan={6}>
                    <Spinner />
                  </Table.Td>
                </Table.Tr>
              ) : (
                messagesData?.results.map((messaging) => (
                  <Table.Tr key={messaging._id} className={tableRow}>
                    <Table.Td textAlign='start'>{messaging.pollName}</Table.Td>

                    <Table.Td>{MESSAGING_METHOD_LABELS[messaging.detail.type]}</Table.Td>

                    <Table.Td>
                      {dayjs(messaging.sendRequestDate).format('YY.MM.DD (dd) HH:mm')}
                    </Table.Td>

                    <Table.Td>{messaging.usageAmount}P</Table.Td>

                    <Table.Td>
                      <PrimaryBadge
                        variant={
                          messaging.status === MessagingStatus.Sending ? 'primary' : 'success'
                        }
                      >
                        {MESSAGING_STATUS_LABELS[messaging.status]}
                      </PrimaryBadge>
                    </Table.Td>

                    <Table.Td className={alignCenter}>
                      <PlainButton onClick={() => setDetailModal({ isVisible: true, messaging })}>
                        <Text color='primary-500'>상세 내역</Text>
                      </PlainButton>
                    </Table.Td>
                  </Table.Tr>
                ))
              )}
            </Table.Body>
          </Table>

          {!isLoading && (
            <Pagination
              currentPage={params.page}
              totalPage={messagesData?.endPage || 1}
              onMove={(newPage) => setParams({ ...params, page: newPage })}
            />
          )}
        </>
      ) : (
        <NotificationEmptyStateView type='sent' />
      )}

      <SentMessagingDetailModal
        messaging={detailModal.messaging}
        isVisible={detailModal.isVisible}
        onClose={() => setDetailModal({ ...detailModal, isVisible: false })}
      />
    </div>
  );
}

export default Sent;

const DEFAULT_VALUES: MessagingSearchForm = {
  page: 1,
  month: 'all',
  methodType: 'all',
  status: [MessagingStatus.Complete, MessagingStatus.Sending],
};
