import pollDetail from './pollDetail';
import emptyStateView from './emptyStateView';
import pollViewer from './pollViewer';
import pollViewerButtonGroups from './pollViewerButtonGroups';
import progress from './progress';
import result from './result';

export default {
  emptyStateView,
  pollDetail,
  pollViewer,
  pollViewerButtonGroups,
  progress,
  result,
};
