import {
  Route,
  Navigate,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { t } from 'i18next';

import GlobalWrapper from 'components/common/layout/Global';
import MainLayout from 'components/common/layout/Main';
import MainLayoutV2Legacy from 'components/common/layout/MainV2Legacy';
import AuthLayout from 'components/common/layout/Auth';
import PaymentLayout from 'components/common/layout/Payment';
import SpaceSelectorLayout from 'components/common/layout/SpaceSelector';
import NavigateBackLayout from 'components/common/layout/NavigateBack';

import CredentialSignUp from 'pages/auth/signup/Credential';
import SocialSignUp from 'pages/auth/signup/Social';
import SocialCallback from 'pages/auth/SocialCallback';
import SocialSignUpCallback from 'pages/auth/SocialCallback/SignUp';
import SocialSignInCallback from 'pages/auth/SocialCallback/SignIn';
import SocialIntegrateCallback from 'pages/auth/SocialCallback/Integrate';
import SignIn from 'pages/auth/SignIn';
import SendResetEmail from 'pages/auth/SendResetEmail';
import RegisterEmail from 'pages/auth/RegisterEmail';
import ResetPassword from 'pages/auth/ResetPassword';

import Home from 'pages/Home';

import Polls from 'pages/poll/Polls';
import PollHistory from 'pages/poll/History';
import DiscardPrivacy from 'pages/poll/DiscardPrivacy';
import PollReport from 'pages/poll/Report';

import PollInitializationPage from 'pages/poll/Initialization';

import ElectionApplication from 'pages/poll/election/Application';
import ElectionEditPage from 'pages/poll/election/Edit';
import ElectionDraft from 'pages/poll/election/Draft';

import SurveyApplication from 'pages/poll/survey/Application';
import SurveyEditPage from 'pages/poll/survey/Edit';
import SurveyDraft from 'pages/poll/survey/Draft';

import PollViewerPage from 'pages/poll/PollViewer';
import PollPreviewer from 'pages/poll/PollPreviewer';

import NotificationPoint from 'pages/payment/Point';
import PointIncoming from 'pages/payment/Point/Incoming';
import PointUsage from 'pages/payment/Point/Usage';
import NotificationHistory from 'pages/notification/History';
import NotificationSend from 'pages/notification/Send';
import NotificationAlimTalk from 'pages/notification/Send/AlimTalk';
import NotificationLms from 'pages/notification/Send/LMS';
import NotificationEmail from 'pages/notification/Send/Email';
import SentHistory from 'pages/notification/History/Sent';
import ReservedHistory from 'pages/notification/History/Reserved';

import Subscription from 'pages/payment/Subscription';

import PaymentSuccessCallback from 'pages/payment/PaymentSuccessCallback';
import PaymentFailureCallback from 'pages/payment/PaymentFailureCallback';

import Resource from 'pages/etc/Resource';

import MyPage from 'pages/MyPage';

import Accept from 'pages/organization/Accept';
import OrganizationSelector from 'pages/organization/Selector';
import Join from 'pages/organization/Join';
import Invite from 'pages/organization/Invite';
import OrganizationSettings from 'pages/organization/Settings';
import Manager from 'pages/organization/Manager';

import IntegrationHome from 'pages/integration/Home';
import NotificationChannel from 'pages/integration/KakaoChannel';

import QRCode from 'pages/poll/QRCode';

import FeedBack from 'pages/Feedback';

import { useGetSessionAsUser } from 'query-hooks/auth';

import { useExpiredSessionErrorInterceptor } from 'hooks/useExpiredSessionErrorInterceptor';
import { useSendSentryReport, useSentryUser } from 'hooks/useSentry';
import { useResetRelatedQueries } from 'hooks/useResetQueries';
import { useAuthHeader } from 'hooks/useAuthHeader';

import { spaceState } from 'states/space';

function Navigator() {
  const activeSpaceId = useRecoilValue(spaceState.activeSpaceId);
  const invitationAlias = useRecoilValue(spaceState.invitationAlias);
  const isOrganizationSelected = !!activeSpaceId;

  useExpiredSessionErrorInterceptor();
  useAuthHeader(activeSpaceId);
  useResetRelatedQueries(activeSpaceId);

  const { data: userSessionData } = useGetSessionAsUser({ suspense: true });
  const isSigned = !!userSessionData?._id;
  const isEmailRegistered = !!userSessionData?.email;

  useSendSentryReport();
  useSentryUser();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route id='root' element={<GlobalWrapper />}>
        <Route id='auth-signup-root' element={<NavigateBackLayout title='회원가입' />}>
          <Route id='auth-signup' path='/auth/signup' element={<CredentialSignUp />} />
          <Route id='auth-signup-social' path='/auth/signup/social' element={<SocialSignUp />} />
        </Route>

        <Route id='auth-social-callback-layout' element={<AuthLayout />}>
          <Route
            id='auth-social-callback'
            path='/auth/social/callback'
            element={<SocialCallback />}
          >
            <Route
              id='auth-social-callback-signup'
              path='signup'
              element={<SocialSignUpCallback />}
            />
            <Route
              id='auth-social-callback-signin'
              path='signin'
              element={<SocialSignInCallback />}
            />
            <Route
              id='auth-social-callback-integrate'
              path='integrate'
              element={<SocialIntegrateCallback />}
            />
          </Route>
        </Route>

        {!isSigned && (
          <Route id='auth-password-layout' element={<NavigateBackLayout title='비밀번호 재설정' />}>
            <Route id='auth-reset' path='/auth/reset' element={<SendResetEmail />} />
            <Route id='auth-password' path='/auth/password' element={<ResetPassword />} />
          </Route>
        )}

        {!isSigned && (
          <>
            <Route id='auth' path='/auth' element={<AuthLayout />}>
              <Route id='auth-signin' path='signin' element={<SignIn />} />
            </Route>
            <Route id='auth-redirect' path='*' element={<Navigate to='/auth/signin' replace />} />
          </>
        )}

        {isSigned && !isEmailRegistered && (
          <Route id='auth-email' element={<AuthLayout />}>
            <Route id='auth-email-register' path='email' element={<RegisterEmail />} />
            <Route id='auth-email-redirect' path='*' element={<Navigate to='email' replace />} />
          </Route>
        )}

        <Route id='auth-invite' path='invite' element={<AuthLayout />}>
          <Route id='auth-invite-index' path=':alias' element={<Invite />} />
        </Route>

        {isSigned && isEmailRegistered && invitationAlias && (
          <Route id='auth-join' element={<AuthLayout />}>
            <Route id='auth-join-index' path='join' element={<Join />} />
            <Route id='auth-join-redirect' path='*' element={<Navigate to='join' replace />} />
          </Route>
        )}

        {isSigned && isEmailRegistered && (
          <Route
            id='accept'
            element={
              <PaymentLayout title={t('admin:components.common.navbar.labels.transferSpace')} />
            }
          >
            <Route id='accept-index' path='accept' element={<Accept />} />
          </Route>
        )}

        {isSigned && isEmailRegistered && !isOrganizationSelected && (
          <Route id='selector' element={<SpaceSelectorLayout />}>
            <Route id='selector-index' path='pickme' element={<OrganizationSelector />} />
            <Route id='selector-redirect' path='*' element={<Navigate to='pickme' replace />} />
          </Route>
        )}

        {isSigned && (
          <Route
            id='account'
            element={
              <NavigateBackLayout
                title={t('admin:components.common.navbar.labels.account')}
                to='/'
              />
            }
          >
            <Route id='account-index' path='account' element={<MyPage />} />
          </Route>
        )}

        {isSigned && (
          <Route id='payment-callback-layout' path='payment' element={<PaymentLayout />}>
            <Route id='main-payment-callback' path='callback'>
              <Route
                id='main-payment-callback-success'
                path='success'
                element={<PaymentSuccessCallback />}
              />
              <Route
                id='main-payment-callback-failure'
                path='failure'
                element={<PaymentFailureCallback />}
              />
            </Route>
          </Route>
        )}

        {isSigned && isEmailRegistered && isOrganizationSelected && (
          <>
            <Route id='poll' path='/poll'>
              <Route id='poll-init' path='init' element={<PollInitializationPage />} />
              <Route id='poll-report' path='report/:id' element={<PollReport />} />
              <Route id='poll-election'>
                <Route
                  id='poll-election-application'
                  path='election/application'
                  element={<ElectionApplication />}
                />
                <Route
                  id='poll-election-edit'
                  path='election/edit/:id'
                  element={<ElectionEditPage />}
                />
                <Route
                  id='poll-election-draft'
                  path='election/draft/:id'
                  element={<ElectionDraft />}
                />
                <Route
                  id='poll-election-template'
                  path='election/template/:id'
                  element={<ElectionDraft />}
                />
              </Route>

              <Route id='poll-survey'>
                <Route
                  id='poll-survey-application'
                  path='survey/application'
                  element={<SurveyApplication />}
                />
                <Route id='poll-survey-edit' path='survey/edit/:id' element={<SurveyEditPage />} />
                <Route id='poll-survey-draft' path='survey/draft/:id' element={<SurveyDraft />} />
                <Route
                  id='poll-survey-template'
                  path='survey/template/:id'
                  element={<SurveyDraft />}
                />
              </Route>
            </Route>

            <Route id='poll-qrcode' path='qrcode' element={<QRCode />} />

            <Route id='main-v2' element={<MainLayoutV2Legacy />}>
              <Route id='main-dashboard' path='/' element={<Home />} />
            </Route>

            <Route id='main' element={<MainLayout topSidePadded />}>
              <Route id='main-poll' path='/poll'>
                <Route
                  id='main-poll-election'
                  path='election'
                  element={<Polls kind='Election' />}
                />
                <Route id='main-poll-survey' path='survey' element={<Polls kind='Survey' />} />
              </Route>

              <Route id='main-service' path='service'>
                <Route id='main-service-notification' path='notification'>
                  <Route
                    id='main-service-notification-point'
                    path='point'
                    element={<NotificationPoint />}
                  >
                    <Route
                      id='main-service-notification-point-incoming'
                      path='incoming'
                      element={<PointIncoming />}
                    />
                    <Route
                      id='main-service-notification-point-usage'
                      path='usage'
                      element={<PointUsage />}
                    />
                    <Route
                      id='main-service-notification-point-redirect'
                      path=''
                      element={<Navigate to='incoming' replace />}
                    />
                  </Route>

                  <Route
                    id='main-service-notification-send'
                    path='send'
                    element={<NotificationSend />}
                  >
                    <Route
                      id='main-service-notification-send-alimTalk'
                      path='alimTalk'
                      element={<NotificationAlimTalk />}
                    />
                    <Route
                      id='main-service-notification-send-lms'
                      path='lms'
                      element={<NotificationLms />}
                    />
                    <Route
                      id='main-service-notification-send-email'
                      path='email'
                      element={<NotificationEmail />}
                    />
                    <Route
                      id='main-service-notification-send-redirect'
                      path=''
                      element={<Navigate to='alimTalk' replace />}
                    />
                  </Route>

                  <Route
                    id='main-service-notification-history'
                    path='history'
                    element={<NotificationHistory />}
                  >
                    <Route
                      id='main-service-notification-history-sent'
                      path='sent'
                      element={<SentHistory />}
                    />
                    <Route
                      id='main-service-notification-history-reserved'
                      path='reserved'
                      element={<ReservedHistory />}
                    />
                    <Route
                      id='main-service-notification-history-redirect'
                      path=''
                      element={<Navigate to='sent' replace />}
                    />
                  </Route>
                </Route>
              </Route>

              <Route id='main-settings' path='settings'>
                <Route id='main-settings-manager' path='manager' element={<Manager />} />
                <Route id='main-settings-resource' path='resource' element={<Resource />} />
                <Route
                  id='main-settings-organization'
                  path='organization'
                  element={<OrganizationSettings />}
                />
                <Route id='main-settings-integration-routes' path='integration'>
                  <Route id='main-settings-integration' path='' element={<IntegrationHome />} />
                  <Route id='main-settings-integration-kakao' path='kakao'>
                    <Route
                      id='main-settings-integration-kakao-channel'
                      path='channel'
                      element={<NotificationChannel />}
                    />
                  </Route>
                </Route>

                <Route id='main-settings-payment' path='payment'>
                  <Route
                    id='main-settings-payment-subscription'
                    path='subscription'
                    element={<Subscription />}
                  />
                </Route>

                <Route id='main-settings-poll' path='poll'>
                  <Route id='main-settings-poll-history' path='history' element={<PollHistory />} />
                  <Route
                    id='main-settings-poll-discard-data'
                    path='discard-data'
                    element={<DiscardPrivacy />}
                  />
                </Route>
              </Route>
            </Route>

            <Route id='main-poll-viewer' element={<MainLayout />}>
              <Route
                id='main-poll-viewer-election'
                path='poll/election/:id'
                element={<PollViewerPage />}
              />
              <Route
                id='main-poll-viewer-survey'
                path='poll/survey/:id'
                element={<PollViewerPage />}
              />
            </Route>

            <Route element={<MainLayout topSidePadded />}>
              <Route path='feedback' element={<FeedBack />} />
            </Route>

            <Route id='poll-preview' path='poll/:id/preview' element={<PollPreviewer />} />

            <Route id='main-redirect' path='*' element={<Navigate to='/' replace />} />
          </>
        )}
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
}

export default Navigator;
