import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timeZone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import localeData from 'dayjs/plugin/localeData';

import 'dayjs/locale/ko';

dayjs.extend(localizedFormat);
dayjs.locale('ko');

dayjs.extend(utc);
dayjs.extend(timeZone);
dayjs.tz.setDefault('Asia/Seoul');

dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

dayjs.extend(relativeTime);

dayjs.extend(updateLocale);
dayjs.extend(localeData);
dayjs.updateLocale('ko', {
  relativeTime: {
    future: '%s 후',
    past: '%s 전',
    s: '%d초',
    m: '1분',
    mm: '%d분',
    h: '1시간',
    hh: '%d시간',
    d: '1일',
    dd: '%d일',
    M: '한 달',
    MM: '%d달',
    y: '1년',
    yy: '%d년',
  },
  meridiem: (hour: number) => (hour < 12 ? '오전' : '오후'),
});
