import useAutoSizeTextArea from 'hooks/useAutoSizeTextArea';
import { forwardRef, Ref, TextareaHTMLAttributes, useImperativeHandle, useRef } from 'react';

import './index.scss';

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  isValid?: boolean;
};

function PlainTextArea({ isValid, ...props }: Props, ref: Ref<HTMLTextAreaElement>) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutoSizeTextArea(textAreaRef.current);
  useImperativeHandle(ref, () => textAreaRef.current as HTMLTextAreaElement);

  return (
    <textarea
      className={`plain-text-input ${isValid ? '' : 'invalid'}`}
      ref={textAreaRef}
      maxLength={props.maxLength}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
      {...props}
    />
  );
}

export default forwardRef(PlainTextArea);
