import { Text, PollGroupBadge } from '@pickme/design-system';
import { Link, useLocation } from 'react-router-dom';
import { PollGroup } from '@pickme/core';

import { parseSearchUrlWithPollGroup } from 'functions/url';

import { POLL_GROUPS } from 'constants/poll';

import { PollStatistics } from 'types/poll';

import { card, cardText, container } from './index.css';

type Props = { stats?: PollStatistics; group?: PollGroup };

function PollStats({ stats, group }: Props) {
  const { pathname, search } = useLocation();

  const items = [
    { ...POLL_GROUPS[0], count: stats?.ready || 0, color: 'yellow' as const },
    { ...POLL_GROUPS[1], count: stats?.ongoing || 0, color: 'blue' as const },
    { ...POLL_GROUPS[2], count: stats?.ended || 0, color: 'red' as const },
    { ...POLL_GROUPS[3], count: stats?.ended || 0, color: 'green' as const },
    { ...POLL_GROUPS[4], count: stats?.ended || 0, color: 'blue-gray' as const },
  ];

  return (
    <div className={container}>
      {items.map((item) => {
        const url = parseSearchUrlWithPollGroup(`${pathname}${search}`, item.value);
        const isActive = item.value === group;

        return (
          <Link
            to={url}
            key={`poll-group-${item.value}`}
            className={card({ color: isActive ? item.color : undefined, active: isActive })}
          >
            <PollGroupBadge group={item.value} />

            <Text size={18} fontWeight={600} className={cardText}>
              {item.count}
            </Text>
          </Link>
        );
      })}
    </div>
  );
}

export default PollStats;
