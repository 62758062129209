import { useEffect, useState } from 'react';
import { Navigate, useOutletContext } from 'react-router-dom';

import Spinner from 'components/common/Spinner';

import { useSocialSignIn } from 'query-hooks/auth';

import { SocialUserInfo } from 'types/auth';

function SocialSignInCallback() {
  const userInfo = useOutletContext<SocialUserInfo>();
  const socialSignIn = useSocialSignIn();

  const [redirectUrl, setRedirectUrl] = useState<string>();

  useEffect(() => {
    socialSignIn.mutate({
      socialKey: userInfo.socialKey,
      accessToken: userInfo.accessToken,
      refreshToken: userInfo.refreshToken,
    });
  }, []);

  useEffect(() => {
    if (socialSignIn.isSuccess) {
      setRedirectUrl('/pickme');
      return;
    }

    if (socialSignIn.isError) {
      setRedirectUrl('/');
    }
  }, [socialSignIn.isSuccess, socialSignIn.isError]);

  return redirectUrl ? <Navigate to={redirectUrl} replace /> : <Spinner />;
}

export default SocialSignInCallback;
