import { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

interface FocusContextType {
  focusedElement: string | null;
  setFocusedElement: (elementId: string | null) => void;
}

const FocusContext = createContext<FocusContextType | undefined>(undefined);

function FocusProvider({ children }: PropsWithChildren) {
  const [focusedElement, setFocusedElement] = useState<string | null>(null);
  const contextValue = useMemo(
    () => ({ focusedElement, setFocusedElement }),
    [focusedElement, setFocusedElement],
  );

  return <FocusContext.Provider value={contextValue}>{children}</FocusContext.Provider>;
}

export const useFocusContext = () => {
  const context = useContext(FocusContext);
  if (!context) {
    throw new Error('useFocusContext must be used within a FocusProvider');
  }
  return context;
};

export default FocusProvider;
