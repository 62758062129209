export default {
  title: '카카오 알림톡',
  card: {
    name: '카카오 채널 아이디',
    description: {
      pending: '채널 심사를 진행하고 있어요',
      complete: '채널 심사를 완료했어요',
    },
    phoneNumber: '관리자 전화번호',
    category: '사업자 카테고리',
    buttonText: '삭제',
  },
};
