export default {
  title: '소유자 변경',
  message: '스페이스의 소유자를 변경하기 전에 아래 내용을 확인해주세요',
  info: {
    organizationName: '스페이스 이름',
    newOwner: '새 소유자',
  },
  description: {
    leave: {
      title: '소유자 변경 안내',
      contents: [
        '소유자 변경 승인 전에 현 소유자가 진행을 취소할 수 있습니다.',
        '스페이스 소유자 변경이 완료되면 현 소유자는 더 이상 스페이스에 접근할 수 없습니다.',
        '소유자 변경이 완료되면 복원이 불가능하니 신중하게 결정해 주세요.',
        '스페이스 소유자 변경 전에 발생한 모든 결제 정보는 이전되지 않습니다. ',
      ],
    },
    relegation: {
      title: '소유자 변경 안내',
      contents: [
        '소유자 변경 승인 전에 현 소유자가 진행을 취소할 수 있습니다.',
        '스페이스 소유자 변경이 완료되면 현 소유자는 스페이스의 매니저로 역할이 변경됩니다.',
        '소유자 변경이 완료되면 복원이 불가능하니 신중하게 결정해 주세요.',
        '스페이스 소유자 변경 전에 발생한 모든 결제 정보는 이전되지 않습니다. ',
      ],
    },
  },
  buttons: {
    cancel: '취소',
    submit: '소유자 변경',
  },
};
