import { Select } from '@pickme/design-system';
import { Controller, useFormContext } from 'react-hook-form';
import { t } from 'i18next';

import SendFieldRow from 'components/features/notification/fields/send/Row';

import { useGetChannel } from 'query-hooks/channel';

import { AlimTalkForm } from 'types/message';

import { TEMPLATE_TYPE_SELECT_ITEMS } from 'constants/notification';

import { container } from '../index.css';

type Props = {
  isChannelIdDisabled: boolean;
  isSendingMethodDisabled: boolean;
};

function MessageFields({ isChannelIdDisabled }: Props) {
  const methods = useFormContext<AlimTalkForm>();
  const { data: channel } = useGetChannel(isChannelIdDisabled);

  const additionalChannels =
    !isChannelIdDisabled && channel
      ? [
          {
            label: `${channel.searchId || ''}${
              channel.status === 'pending'
                ? t('admin:pages.notification.send.form.fields.channel.pending')
                : ''
            }`,
            value: channel.channelId,
            disabled: channel.status !== 'approved',
          },
        ]
      : [];

  return (
    <div className={container}>
      <SendFieldRow
        title={t('admin:pages.notification.send.form.fields.channel.title')}
        align='center'
      >
        <Controller
          control={methods.control}
          name='channelId'
          render={({ field }) => (
            <Select
              height='lg'
              width='md'
              {...field}
              selected={field.value}
              items={[
                {
                  label: '픽미 알림톡',
                  value: '',
                },
                ...additionalChannels,
              ]}
              onSelect={field.onChange}
            />
          )}
        />
      </SendFieldRow>

      <SendFieldRow
        title={t('admin:pages.notification.send.form.fields.templates.title')}
        align='center'
      >
        <Controller
          control={methods.control}
          name='templateType'
          render={({ field }) => (
            <Select
              height='lg'
              width='md'
              placeholder={t('admin:pages.notification.send.form.fields.templates.placeholder')}
              selected={field.value}
              items={TEMPLATE_TYPE_SELECT_ITEMS}
              {...field}
              onSelect={field.onChange}
            />
          )}
        />
      </SendFieldRow>
    </div>
  );
}

export default MessageFields;
