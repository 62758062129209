import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { AxiosError } from 'axios';
import { t } from 'i18next';

import {
  createCheckEmailDuplicationRequest,
  createCredentialSignInRequest,
  createGetSessionAsMemberRequest,
  createCredentialSignUpRequest,
  createSendPasswordResetEmailRequest,
  createResetPasswordWithTokenRequest,
  createGetSessionAsUserRequest,
  createPasswordCheckRequest,
  createSocialSignInRequest,
  createSocialSignUpRequest,
  createSocialIntegrateRequest,
  createGetSocialUserInfoRequest,
  createSignOutRequest,
  createSocialDisconnectRequest,
} from 'apis/auth';

import { spaceState } from 'states/space';
import { messageModal } from 'states/modal';
import { toast } from 'states/toast';

import channelTalk from 'functions/channel-talk';
import { passwordValidator } from 'functions/validator/auth';
import { setUser } from 'functions/analytics/user';
import { sendSignUpEvent, sendSignInEvent } from 'functions/analytics/auth';

import { CrossSessionError } from 'utils/error';

import { UserProfile } from 'models/auth/user-profile';

import { SOCIAL_PROVIDER_LABELS } from 'constants/auth';

import {
  CredentialSignInBody,
  ProfileAsMember,
  SignUpRequestBody,
  SocialIntegrateRequestBody,
  SocialProvider,
  SocialSignInBody,
  SocialSignUpBody,
  SocialUserInfoQuery,
} from 'types/auth';

const assertCrossedSession = (data: ProfileAsMember) => {
  // 유권자 로그인을 유지한 채, 넘어온 경우
  // @ts-ignore
  if (data?.type === 'voter' || data?.type === 'system') {
    throw new CrossSessionError();
  }
};

export const useGetSessionAsMember = () => {
  const queryClient = useQueryClient();

  return useQuery(
    ['auth', 'session', 'member'],
    async () => {
      const { data } = await createGetSessionAsMemberRequest();

      return data;
    },
    {
      refetchOnReconnect: !!queryClient.getQueryData(['auth', 'session', 'member']),
      refetchOnWindowFocus: !!queryClient.getQueryData(['auth', 'session', 'member']),
      useErrorBoundary: false,
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      onSuccess: ({ _id, organizationId, level }) => {
        setUser({ userId: _id, spaceId: organizationId, memberLevel: level });
      },
    },
  );
};

export const useGetSessionAsUser = ({ suspense }: { suspense?: boolean }) => {
  const queryClient = useQueryClient();
  const setActiveSpaceId = useSetRecoilState(spaceState.activeSpaceId);
  const { mutate: signOutMutate } = useSignOut();

  return useQuery(
    ['auth', 'session', 'user'],
    async () => {
      const { data } = await createGetSessionAsUserRequest();
      // @ts-ignore
      assertCrossedSession(data);

      const userProfile = new UserProfile(data);

      return userProfile;
    },
    {
      onError: (error) => {
        if (error instanceof CrossSessionError) {
          signOutMutate({ redirectToIntroduction: false });
          return;
        }

        setActiveSpaceId('');
        // TODO: Add handle it.
      },
      suspense,
      refetchOnReconnect: !!queryClient.getQueryData(['auth', 'session', 'user']),
      refetchOnWindowFocus: !!queryClient.getQueryData(['auth', 'session', 'user']),
      useErrorBoundary: false,
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    },
  );
};

export const useCredentialSignIn = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ email, password }: CredentialSignInBody) => {
      const { data } = await createCredentialSignInRequest({ email, password });
      return data;
    },
    {
      onSuccess: () => {
        sendSignInEvent();
        queryClient.invalidateQueries('auth');
        navigate('/', { replace: true });
      },
      onError: (error: AxiosError) => {
        setToast({
          type: 'error',
          isVisible: true,
          message: error.message,
        });
      },
    },
  );
};

export const useCredentialSignUp = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async (form: SignUpRequestBody) => {
      const { data } = await createCredentialSignUpRequest(form);
      return data;
    },
    {
      onSuccess: (data) => {
        sendSignUpEvent('success');

        queryClient.invalidateQueries('auth');

        channelTalk.updateUser({
          profile: {
            name: data.name,
            email: data.email,
            mobileNumber: data.phoneNumber || '',
            level: data.level,
            organizationName: data.organizationName,
            organizationId: data.organizationId,
          },
        });
        channelTalk.track('SignUp', {
          name: data.name,
          email: data.email,
          mobileNumber: data.phoneNumber || '',
        });
      },
      onError: (error: AxiosError) => {
        sendSignUpEvent('fail');
        setToast({
          type: 'error',
          isVisible: true,
          message: error.message,
        });
      },
    },
  );
};

export const useSocialSignIn = () => {
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async (body: SocialSignInBody) => {
      await createSocialSignInRequest(body);
    },
    {
      onSuccess: () => {
        sendSignInEvent();
        queryClient.invalidateQueries('auth');
      },
      onError: () => {
        setToast({ isVisible: true, message: '로그인을 할 수 없습니다.', type: 'error' });
      },
    },
  );
};

export const useSocialSignUp = () => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async (body: SocialSignUpBody) => {
      const data = await createSocialSignUpRequest(body);
      return data;
    },
    {
      onSuccess: () => {
        sendSignUpEvent('success');
        queryClient.invalidateQueries('auth');
        navigate('/pickme');

        // TODO: 채널톡 갱신 로직 추가
      },
      onError: () => {
        setToast({
          isVisible: true,
          message: '이미 사용중인 이메일입니다. 해당 아이디로 로그인 후 소셜 연동을 추가해주세요.',
          type: 'error',
        });
      },
    },
  );
};

export const useGetSocialUserInfo = (query: SocialUserInfoQuery) =>
  useQuery([query.provider, query.accessToken, query.refreshToken], async () => {
    const { data } = await createGetSocialUserInfoRequest(query);
    return data;
  });

export const useSocialIntegrate = () => {
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async (body: SocialIntegrateRequestBody) => {
      await createSocialIntegrateRequest(body);
      return body.provider;
    },
    {
      onSuccess: (provider) => {
        queryClient.invalidateQueries('auth');
        setToast({
          isVisible: true,
          message: `${SOCIAL_PROVIDER_LABELS[provider]} 소셜 계정이 연동되었습니다.`,
          type: 'success',
        });
      },
      onError: () => {
        setToast({
          isVisible: true,
          message: '연동할 수 없는 소셜 계정입니다.',
          type: 'error',
        });
      },
    },
  );
};

export const useSocialDisconnect = () => {
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async (provider: SocialProvider) => {
      await createSocialDisconnectRequest(provider);
      return provider;
    },
    {
      onSuccess: (provider) => {
        queryClient.invalidateQueries('auth');
        setToast({
          isVisible: true,
          message: `${SOCIAL_PROVIDER_LABELS[provider]} 소셜 계정이 연동이 해제되었습니다.`,
          type: 'success',
        });
      },
      onError: () => {
        setToast({
          isVisible: true,
          message: `소셜 계정 연동 해제에 실패했습니다. 잠시 후 다시 시도해주세요.`,
          type: 'error',
        });
      },
    },
  );
};

export const useGetEmailDuplication = (email: string) =>
  useQuery(
    ['email-duplication', email],
    async () => {
      try {
        const { data } = await createCheckEmailDuplicationRequest(encodeURIComponent(email));

        return { isDuplicated: data.result, email };
      } catch (error: any) {
        if (error?.response?.data?.detailedStatusCode === 40017) {
          // TODO: 맥락을 찾아 수정할 것
          return { isDuplicated: false, email };
        }

        throw new Error(error);
      }
    },
    {
      enabled: false,
    },
  );

export const useSignOut = () => {
  const queryClient = useQueryClient();
  const setActiveSpaceId = useSetRecoilState(spaceState.activeSpaceId);

  return useMutation(
    async () => {
      await createSignOutRequest();
    },
    {
      onSuccess: (_, { redirectToIntroduction }: { redirectToIntroduction: boolean }) => {
        if (redirectToIntroduction) {
          window.location.href =
            import.meta.env.MODE === 'dev.local' ? '/' : import.meta.env.VITE_INTRO_URL;
        }

        setActiveSpaceId('');
        queryClient.resetQueries();
      },
    },
  );
};

export const useSendResetPasswordEmail = () => {
  const setMessageModal = useSetRecoilState(messageModal);

  return useMutation(
    async (email: string) => {
      const { data } = await createSendPasswordResetEmailRequest(email);
      return data;
    },
    {
      onSuccess: (_, email) => {
        setMessageModal({
          isVisible: true,
          title: t('admin:query-hooks.auth.useSendResetPasswordEmail.onSuccess.modal.title'),
          message: t('admin:query-hooks.auth.useSendResetPasswordEmail.onSuccess.modal.message', {
            email,
          }),
        });
      },
    },
  );
};

export const useResetPasswordWithToken = () => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ token, password }: { token: string; password: string }) => {
      if (!token) {
        setToast({
          isVisible: true,
          type: 'error',
          message: t('admin:query-hooks.auth.useResetPasswordWithToken.toast'),
        });
        navigate('/auth/signin');
        throw new Error();
      }

      const { data } = await createResetPasswordWithTokenRequest(token, password);

      return data;
    },
    {
      onSuccess: () => {
        setToast({
          isVisible: true,
          type: 'success',
          message: t('admin:query-hooks.auth.useResetPasswordWithToken.onSuccess.toast'),
        });
        navigate('/auth/signin', { replace: true });
      },
    },
  );
};

export const useCheckPassword = () =>
  useMutation(async ({ password }: { password: string }) => {
    if (!passwordValidator(password)) {
      return { result: false };
    }

    const { data } = await createPasswordCheckRequest(password);
    return data;
  });
