const O = {
  "AUTH-000": "Please check your password",
  "AUTH-001": "Please check your password",
  "AUTH-002": "Please check the verification code",
  "AUTH-004": "",
  "AUTH-005": "",
  "AUTH-006": "",
  "AUTH-007": "",
  "AUTH-008": "",
  "AUTH-009": "",
  "AUTH-010": "",
  "AUTH-011": "",
  "USER-001": "",
  "USER-000": "",
  "USER-003": "",
  "USER-004": "",
  "USER-005": "",
  "ADMIN-001": "",
  "ADMIN-002": "",
  "POLL-001": "",
  "POLL-002": "",
  "POLL-003": "",
  "POLL-004": "",
  "POLL-005": "",
  "POLL-006": "",
  "POLL-007": "",
  "POLL-008": "",
  "POLL-009": "",
  "POLL-010": "",
  "POLL-011": "",
  "POLL-012": "",
  "POLL-013": "",
  "POLL-014": "",
  "POLL-015": "",
  "WALLET-001": "",
  "WALLET-002": "",
  "WALLET-003": "",
  "WALLET-004": "",
  "WALLET-005": "",
  "WALLET-006": "",
  "WALLET-007": "",
  "INTERNAL-999": "",
  "NET-001": "",
  "NET-002": "",
  "NET-003": "",
  "NET-004": "",
  "DIRECT-001": "",
  "DIRECT-002": "",
  "DIRECT-003": "",
  "DIRECT-004": "",
  "INTERNAL-003": "",
  "MESSAGE-001": "",
  "MESSAGE-002": "",
  "MESSAGE-003": "",
  "MESSAGE-004": "",
  "OTP-001": "The number of verification requests has exceeded. Try again after 3 minutes",
  "OTP-002": "Please enter the correct verification code",
  "OTP-003": "The verification time has expired. Please try again.",
  "OTP-004": "Please enter the correct verification code",
  "SUBSCRIPTION-001": "",
  "SUBSCRIPTION-002": "",
  "SUBSCRIPTION-003": "",
  "SUBSCRIPTION-004": "",
  "SUBSCRIPTION-005": "",
  "SUBSCRIPTION-006": "",
  "SUBSCRIPTION-007": "",
  "SUBSCRIPTION-008": "",
  "SUBSCRIPTION-009": "",
  "SUBSCRIPTION-010": "",
  "SUBSCRIPTION-011": "",
  "PERMISSION-001": "",
  "PERMISSION-002": "",
  "PERMISSION-003": "",
  "RESULT-001": "",
  "RESULT-002": "",
  "RESULT-003": "",
  "VOTE-001": "",
  "VOTE-003": "",
  "VOTE-004": "",
  "VOTE-005": "",
  "VOTE-006": "",
  "VOTE-007": "",
  "VOTE-008": "",
  "VOTE-009": "",
  "VOTE-010": "",
  "VOTE-011": "",
  "VOTE-012": "",
  "VOTE-013": "",
  "VOTE-014": "",
  "VOTE-015": "You do not have the authority to vote.",
  "VOTE-016": "This election is canceled",
  "VOTE-017": "This election is temporally paused",
  "VOTE-018": "This election is already over",
  "VOTE-019": "Now voting is not available. Please check the time to vote available",
  "VOTER-001": "Already voted",
  "VOTER-002": "",
  "VOTER-003": "",
  "VBOOK-001": "",
  "VBOOK-002": "",
  "VBOOK-003": "",
  "VBOOK-004": "",
  "VBOOK-005": "You can no longer participate in the survey due to the limit.",
  "VBOOK-006": "You already participated in the survey.",
  "TPAY-000": "",
  "TPAY-001": "",
  "TPAY-002": "",
  "TPAY-003": "",
  "TPAY-004": "",
  "TPAY-005": "",
  "FILE-001": "",
  "FILE-002": "",
  "EVENT-000": "",
  "ORGAN-000": "",
  "PROMOTION-001": "",
  "PROMOTION-002": "",
  "PROMOTION-003": ""
}, E = {
  "AUTH-000": "비밀번호 형식을 확인해주세요",
  "AUTH-001": "비밀번호를 확인해주세요",
  "AUTH-002": "인증번호를 확인해주세요",
  "AUTH-004": "사용할 수 없는 ID입니다",
  "AUTH-005": "로그인이 만료되었어요",
  "AUTH-006": "",
  "AUTH-007": "접근 권한이 없어요",
  "AUTH-008": "접근 권한이 없어요",
  "AUTH-009": "소속을 선택해주세요",
  "AUTH-010": "계정 탈퇴를 하려면 속해있는 모든 스페이스에서 스페이스 나가기 및 삭제를 해주세요.",
  "AUTH-011": "이용 약관 위반으로 사용 중지된 계정입니다. 관리자에게 문의해주세요. 문의 1533-1912",
  "USER-001": "이미 사용중인 이메일이에요 다른 이메일을 사용해주세요",
  "USER-000": "아이디를 확인해주세요",
  "USER-003": "이미 등록된 이메일이에요 다른 이메일을 사용해주세요",
  "USER-004": "이미 등록된 전화번호에요 다른 전화번호를 사용해주세요",
  "USER-005": "등록되지 않은 이메일이에요",
  "ADMIN-001": "잘못된 요청입니다",
  "ADMIN-002": "이미 완료된 요청이에요",
  "POLL-001": "시작 시간을 현재 시간 이후로 설정해주세요",
  "POLL-002": "기간을 월 계약 기간 이내로 설정해주세요",
  "POLL-003": "개설 가능한 선거 수를 넘었어요",
  "POLL-004": "시간을 입력해주세요",
  "POLL-005": "시작시간과 종료시간을 확인해주세요",
  "POLL-006": "시작시간과 종료시간을 확인해주세요",
  "POLL-007": "이미 삭제된 항목입니다",
  "POLL-008": "존재하지 않는 항목입니다",
  "POLL-009": "이미 진행 중이기 때문에 시작 시간을 변경할 수 없어요",
  "POLL-010": "이미 완료된 요청이에요",
  "POLL-011": "",
  "POLL-012": "",
  "POLL-013": "",
  "POLL-014": "",
  "POLL-015": "",
  "WALLET-001": "포인트가 부족해요",
  "WALLET-002": "",
  "WALLET-003": "",
  "WALLET-004": "",
  "WALLET-005": "",
  "WALLET-006": "",
  "WALLET-007": "",
  "INTERNAL-999": "",
  "NET-001": "",
  "NET-002": "",
  "NET-003": "",
  "NET-004": "",
  "DIRECT-001": "",
  "DIRECT-002": "",
  "DIRECT-003": "",
  "DIRECT-004": "",
  "INTERNAL-003": "",
  "MESSAGE-001": "알림톡을 전송할 대상이 없습니다.",
  "MESSAGE-002": "존재하지 않는 항목입니다",
  "MESSAGE-003": "잘못된 요청입니다",
  "MESSAGE-004": "알림톡 전송을 위한 픽미 포인트가 부족해요. 픽미 포인트를 충전해주세요.",
  "OTP-001": "인증 요청 횟수를 초과하였습니다. 3분이 지난후에 다시 요청해주세요",
  "OTP-002": "올바른 인증번호를 입력해주세요",
  "OTP-003": "인증번호 유효시간이 지났어요. 다시 인증해주세요",
  "OTP-004": "올바른 인증번호를 입력해주세요",
  "SUBSCRIPTION-001": "",
  "SUBSCRIPTION-002": "",
  "SUBSCRIPTION-003": "",
  "SUBSCRIPTION-004": "로고를 변경하기위해 플랜을 업그레이드 해주세요",
  "SUBSCRIPTION-005": "ULR을 변경하기위해 플랜을 업그레이드 해주세요",
  "SUBSCRIPTION-006": "매니저를 추가하기위해 플랜을 업그레이드 해주세요",
  "SUBSCRIPTION-007": "",
  "SUBSCRIPTION-008": "",
  "SUBSCRIPTION-009": "",
  "SUBSCRIPTION-010": "유권자 수를 변경하기위해 플랜을 업그레이드 해주세요",
  "SUBSCRIPTION-011": "",
  "PERMISSION-001": "",
  "PERMISSION-002": "",
  "PERMISSION-003": "",
  "RESULT-001": "",
  "RESULT-002": "",
  "RESULT-003": "",
  "VOTE-001": "",
  "VOTE-003": "",
  "VOTE-004": "",
  "VOTE-005": "",
  "VOTE-006": "",
  "VOTE-007": "",
  "VOTE-008": "",
  "VOTE-009": "",
  "VOTE-010": "",
  "VOTE-011": "",
  "VOTE-012": "",
  "VOTE-013": "",
  "VOTE-014": "",
  "VOTE-015": "투표 할 수 있는 권한이 없어요",
  "VOTE-016": "취소된 투표에요",
  "VOTE-017": "일시 정지 중인 투표에요",
  "VOTE-018": "이미 종료된 투표에요",
  "VOTE-019": "지금은 투표 시간이 아니에요. 투표 시간을 확인해주세요",
  "VOTER-001": "이미 투표에 참여하셨어요",
  "VOTER-002": "존재하지 않는 유권자입니다",
  "VOTER-003": "이미 약관에 동의하셨어요",
  "VBOOK-001": "",
  "VBOOK-002": "",
  "VBOOK-003": "",
  "VBOOK-004": "",
  "VBOOK-005": "더이상 설문에 참여할 수 없어요",
  "VBOOK-006": "이미 참여한 설문조사이에요",
  "TPAY-000": "",
  "TPAY-001": "",
  "TPAY-002": "",
  "TPAY-003": "",
  "TPAY-004": "",
  "TPAY-005": "",
  "FILE-001": "지원하지 않는 확장자에요. 다른 파일 확장자를 사용해주세요",
  "FILE-002": "파일 용량이 초과됐어요",
  "EVENT-000": "이벤트는 한번만 참여할 수 있어요",
  "ORGAN-000": "이미 만료되었거나, 잘못된 요청이에요",
  "PROMOTION-001": "이미 참여했어요",
  "PROMOTION-002": "아직 시작 전인 프로모션이에요",
  "PROMOTION-003": "이미 종료된 프로모션이에요"
}, B = "Error occurred. Please try later again.", C = "요청에 실패했습니다. 잠시 후 다시 시도해주세요.", o = (T, d) => {
  var L, e, P, S, I, a, t, A, R, s, U, N, V, c, i, n;
  return (e = (L = T == null ? void 0 : T.response) == null ? void 0 : L.data) != null && e.code && ((S = (P = T == null ? void 0 : T.response) == null ? void 0 : P.data) != null && S.code.startsWith("DIRECT-")) ? {
    code: ((a = (I = T == null ? void 0 : T.response) == null ? void 0 : I.data) == null ? void 0 : a.code) || "XXX",
    message: (A = (t = T == null ? void 0 : T.response) == null ? void 0 : t.data) == null ? void 0 : A.message
  } : d === "en" ? {
    code: ((s = (R = T == null ? void 0 : T.response) == null ? void 0 : R.data) == null ? void 0 : s.code) || "XXX",
    message: (O == null ? void 0 : O[(N = (U = T == null ? void 0 : T.response) == null ? void 0 : U.data) == null ? void 0 : N.code]) || B
  } : {
    code: ((c = (V = T == null ? void 0 : T.response) == null ? void 0 : V.data) == null ? void 0 : c.code) || "XXX",
    message: (E == null ? void 0 : E[(n = (i = T == null ? void 0 : T.response) == null ? void 0 : i.data) == null ? void 0 : n.code]) || C
  };
}, l = (T) => {
  switch (T) {
    case "AUTH-005":
    case "WALLET-003":
      return !0;
    default:
      return !1;
  }
};
export {
  l as checkErrorExcludingFromSentryReport,
  o as parseErrorMessage
};
