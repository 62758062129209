import { POLL_LABELS, PollGroup } from '@pickme/core';
import { Text } from '@pickme/design-system';

import { getGroupColorFromPollGroup } from 'functions/poll';

import { container, pollGroup } from './index.css';

type Props = {
  group: PollGroup;
};

function PollGroupBadge({ group }: Props) {
  const color = getGroupColorFromPollGroup(group);

  return (
    <div className={container}>
      <Text color='white' size={14}>
        <div style={{ backgroundColor: color }} className={pollGroup}>
          {POLL_LABELS[group]}
        </div>
      </Text>
    </div>
  );
}

export default PollGroupBadge;
