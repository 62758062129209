export default {
  useSignUp: {
    onSuccess: {
      modal: {
        title: '회원가입 완료',
        message: '회원가입을 완료했어요. 픽미에 오신 것을 환영합니다!',
        buttonText: '확인',
      },
    },
  },
  useSendResetPasswordEmail: {
    onSuccess: {
      modal: {
        title: '이메일 전송',
        message: '{{email}}로 비밀번호를 설정할 수 있는 링크를 전송했어요',
      },
    },
  },
  useResetPasswordWithToken: {
    toast: '잘못된 접근입니다.',
    onSuccess: {
      toast: '비밀번호가 성공적으로 변경되었습니다',
    },
  },
};
