import report from './report';
import viewer from './viewer';
import pollHome from './home';
import pollHistory from './history';
import discard from './discard';
import survey from './survey';
import election from './election';
import initialization from './initialization';

export default {
  viewer,
  report,
  home: pollHome,
  history: pollHistory,
  discard,
  survey,
  election,
  initialization,
};
