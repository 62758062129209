import { Text, Select } from '@pickme/design-system';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { t } from 'i18next';

import ApplicationLayout from 'components/common/layout/Application';
import PollInitializationTemplateCard from 'components/features/template/cards/PollInitialization';
import ManualStartCard from 'components/features/template/cards/ManualStart';
import ManualStartModal from 'components/features/template/modals/ManualStart';

import { PollKind } from 'types/poll';

import ELECTION_TEMPLATES from 'resources/templates/election';
import SURVEY_TEMPLATES from 'resources/templates/survey';

import { container, title, cards } from './index.css';

function PollInitializationPage() {
  const navigate = useNavigate();
  const [selectedKind, selectKind] = useState<'all' | PollKind>('all');
  const [isManualStartModalVisible, setManualStartModalVisible] = useState(false);

  const [params] = useSearchParams();
  const kindFromParams = params.get('type');

  useEffect(() => {
    if (kindFromParams === 'Election') {
      selectKind('Election');
    } else if (kindFromParams === 'Survey') {
      selectKind('Survey');
    }
  }, [kindFromParams]);

  return (
    <ApplicationLayout
      title=''
      hideStepNavbar
      onClose={() => navigate('/', { replace: true, unstable_viewTransition: true })}
      onSelect={() => {}}
      items={[]}
    >
      <div className={container}>
        <div className={title}>
          <Text size={32} fontWeight={600}>
            {t('admin:pages.poll.initialization.title')}
          </Text>
          <Text size={16} fontWeight={400} color='gray-700'>
            {t('admin:pages.poll.initialization.description')}
          </Text>
        </div>

        <Select
          selected={selectedKind}
          onSelect={(selection) => selectKind(selection as 'all' | PollKind)}
          items={[
            { label: t('admin:terms.poll.kind.all'), value: 'all' as const },
            { label: t('admin:terms.poll.kind.election'), value: 'Election' as const },
            { label: t('admin:terms.poll.kind.survey'), value: 'Survey' as const },
          ]}
        />

        <div className={cards}>
          <ManualStartCard onClick={() => setManualStartModalVisible(true)} />

          {TEMPLATES.filter(({ kind }) => {
            if (selectedKind === 'all') {
              return true;
            }

            return selectedKind === kind;
          }).map((template) => (
            <PollInitializationTemplateCard
              key={`${template.kind}-${template.category}-${template.index}`}
              template={template}
              index={template.index}
            />
          ))}
        </div>
      </div>

      <ManualStartModal
        isVisible={isManualStartModalVisible}
        onClose={() => setManualStartModalVisible(false)}
      />
    </ApplicationLayout>
  );
}

export default PollInitializationPage;

// TODO: 현재, template 페이지로 이동 시, category 내에서 index를 달고 가게 되어있습니다.
// 그대로 사용하기 위해 index를 추가합니다.
// 추후, template 페이지에서 index 외에 다른 값을 사용하게 변경 후 index를 삭제할 예정입니다.

const TEMPLATES = [
  ...(ELECTION_TEMPLATES.school?.map((data, index) => ({ ...data, index })) || []),
  ...(SURVEY_TEMPLATES.school?.map((data, index) => ({ ...data, index })) || []),
  ...(ELECTION_TEMPLATES.company?.map((data, index) => ({ ...data, index })) || []),
  ...(SURVEY_TEMPLATES.corporation?.map((data, index) => ({ ...data, index })) || []),
  ...(ELECTION_TEMPLATES.labor?.map((data, index) => ({ ...data, index })) || []),
  ...(SURVEY_TEMPLATES.labor?.map((data, index) => ({ ...data, index })) || []),
  ...(ELECTION_TEMPLATES.apartment?.map((data, index) => ({ ...data, index })) || []),
  ...(SURVEY_TEMPLATES.apartment?.map((data, index) => ({ ...data, index })) || []),
  ...(ELECTION_TEMPLATES.association?.map((data, index) => ({ ...data, index })) || []),
  ...(SURVEY_TEMPLATES.association?.map((data, index) => ({ ...data, index })) || []),
  ...(ELECTION_TEMPLATES.club?.map((data, index) => ({ ...data, index })) || []),
  ...(SURVEY_TEMPLATES.club?.map((data, index) => ({ ...data, index })) || []),
  ...(ELECTION_TEMPLATES.religion?.map((data, index) => ({ ...data, index })) || []),
  ...(SURVEY_TEMPLATES.religion?.map((data, index) => ({ ...data, index })) || []),
  ...(ELECTION_TEMPLATES.contest?.map((data, index) => ({ ...data, index })) || []),
  ...(SURVEY_TEMPLATES.contest?.map((data, index) => ({ ...data, index })) || []),
];
