import { useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from '@pickme/ui';
import { Text, Input, OutlineButton, Button } from '@pickme/design-system';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

import { useDeleteAccount } from 'query-hooks/user';

import { passwordValidator } from 'functions/validator/auth';

import { SocialProvider } from 'types/auth';

import { modalButtons } from 'styles/page.css';
import { divider, note, passwordLabel } from './index.css';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  socialProvider?: SocialProvider;
  needPasswordConfirm: boolean;
};

function DeleteAccountModal({ isVisible, onClose, needPasswordConfirm }: Props) {
  const passwordForm = useForm({ mode: 'onBlur', defaultValues: { password: '' } });
  const { mutate, isLoading, isSuccess } = useDeleteAccount();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = passwordForm;

  const isDisabled = isLoading || !isValid;
  const onSubmit: SubmitHandler<{ password: string }> = (data) => {
    if (errors.password || isDisabled) {
      return;
    }

    const password = data.password || undefined;

    mutate({ password });
  };

  useEffect(() => {
    if (!isVisible) {
      reset();
    }

    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, isVisible]);

  return (
    <Modal size='lg' isVisible={isVisible} onClose={onClose}>
      <ModalHeader
        title={t('admin:components.features.myPage.deletion.modal.title')}
        size='h4'
        onClose={onClose}
      />

      <FormProvider {...passwordForm}>
        <form
          onSubmit={handleSubmit((data) => {
            onSubmit(data);
            onClose();
          })}
        >
          <ModalBody>
            <Text size={16} color='gray-700'>
              삭제된 계정은 되돌릴 수 없어요.
              <br />
              영구적으로 계정을 삭제하기 전에 충분히 검토해주세요.
            </Text>

            <div className={divider} />

            <Text size={16} color='gray-800' fontWeight={600}>
              탈퇴시 유의 사항
            </Text>

            <ul className={note.ul}>
              <li className={note.li}>더 이상 해당 계정으로 픽미에 로그인할 수 없게 됩니다.</li>
              <li className={note.li}>
                회원 탈퇴 시, 보유하고 있는 포인트는 즉시 자동 소멸됩니다.
              </li>
              <li className={note.li}>플랜을 구독 중인 경우 회원 탈퇴가 불가능합니다.</li>
            </ul>

            <div className={divider} />

            {needPasswordConfirm && (
              <>
                <Text size={16} color='gray-800' fontWeight={600} className={passwordLabel}>
                  비밀번호 확인
                </Text>

                <Input
                  {...register('password', {
                    required: true,
                    validate: passwordValidator,
                  })}
                  width='100%'
                  size='lg'
                  type='password'
                  placeholder={t(
                    'admin:components.features.myPage.deletion.modal.form.fields.password.placeholder',
                  )}
                  isValid={!errors.password}
                />
              </>
            )}
          </ModalBody>

          <div className={modalButtons.container}>
            <OutlineButton variant='gray' className={modalButtons.cancel} onClick={onClose}>
              닫기
            </OutlineButton>
            <Button
              className={modalButtons.submit}
              variant='danger'
              type='submit'
              disabled={isDisabled}
            >
              {isLoading ? <Spinner /> : '계정 탈퇴'}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default DeleteAccountModal;
