import { ReactNode } from 'react';
import { PlainButton, Text } from '@pickme/ui';

import CloseIcon from 'resources/icons/dashboard/close.svg';

import { rightContainer } from './index.css';

type Props = {
  title: string;
  onClose: () => void;
  children?: ReactNode;
};

function Navbar({ title, children, onClose }: Props) {
  return (
    <header className='application-header'>
      <div className='application-header__title-container'>
        <PlainButton onClick={() => onClose()} className='application-header__close-button'>
          <img className='application-header__close-icon' src={CloseIcon} alt='close' />
        </PlainButton>

        <Text size='md3' fontWeight={600}>
          {title}
        </Text>
      </div>

      <div className={rightContainer}>{children}</div>
    </header>
  );
}

export default Navbar;
