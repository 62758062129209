import clsx from 'clsx';
import { forwardRef, InputHTMLAttributes, Ref } from 'react';
import { Text } from '@pickme/design-system';

import { textarea, container } from '../index.css';

type CommonProps = {
  isValid?: boolean;
  errorMessage?: string;
  size?: 'sm' | 'md';
  containerClassName?: string;
  underlined?: boolean;
};

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}
type Props = Omit<InputProps, 'size'> & CommonProps;

function Input(
  {
    isValid = true,
    errorMessage,
    size = 'md',
    containerClassName,
    underlined = true,
    ...props
  }: Props,
  ref: Ref<HTMLInputElement>,
) {
  return (
    <div className={clsx(container, containerClassName)}>
      <input
        ref={ref as Ref<HTMLInputElement>}
        {...(props as InputProps)}
        className={clsx(
          textarea({
            size,
            isValid,
            underlined,
          }),
          props.className,
        )}
        maxLength={props.maxLength}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }}
      />

      {errorMessage && (
        <Text size={12} fontWeight={400} color='red-500'>
          {errorMessage}
        </Text>
      )}
    </div>
  );
}

const UnderlinedInput = forwardRef(Input);
export default UnderlinedInput;
