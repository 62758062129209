export default {
  title: '비밀번호 재설정하기',
  fields: {
    password: {
      placeholder: '새 비밀번호',
    },
    newPassword: {
      placeholder: '새 비밀번호 확인',
    },
  },
  errorMessages: {
    password: '영문, 숫자 기호를 조합하여 8자 이상을 입력해주세요.',
    passwordChecker: '비밀번호와 비밀번호 확인이 서로 다릅니다',
  },
  buttons: {
    submit: '재설정하기',
  },
};
