import { PollCard } from '@pickme/design-system';
import { Dropdown } from '@pickme/ui';
import moment from 'moment-timezone';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { pollActionModal, pollShareModal } from 'states/modal';
import { pollViewer } from 'states/poll-viewer';

import { PollListItem } from 'types/poll';

import RemoveGrayIcon from 'resources/icons/application/remove.png';
import EditIcon from 'resources/icons/table/buttons/edit.svg';
import ShareIcon from 'resources/icons/table/buttons/near_me.svg';
import MoreIcon from 'resources/icons/dashboard/vertical_dots.svg';

import { cards, more } from './index.css';

type Props = {
  polls: PollListItem[];
  showSkeleton?: boolean;
};

function PollCardView({ polls, showSkeleton }: Props) {
  const navigate = useNavigate();

  const setPollViewer = useSetRecoilState(pollViewer);
  const setPollActionModal = useSetRecoilState(pollActionModal);
  const setPollShareModal = useSetRecoilState(pollShareModal);

  return (
    <div className={cards}>
      {polls.map((poll) => (
        <PollCard
          key={`card-${poll._id}`}
          id={poll._id}
          name={poll.name}
          group={poll.group}
          imageUrl={poll.coverImageFileUrl || ''}
          fallbackImageUrl={`${import.meta.env.VITE_INDEX_URL}/card.png`}
          period={{
            start: moment(poll.startDate).tz('Asia/Seoul').format('YY/MM/DD(ddd) HH:mm'),
            end: moment(poll.endDate.date).tz('Asia/Seoul').format('YY/MM/DD(ddd) HH:mm'),
          }}
          onClick={(pollId) => {
            setPollViewer({
              id: pollId,
              isVisible: true,
            });
          }}
        >
          <Dropdown
            button={
              <button className={more} type='button'>
                <img src={MoreIcon} alt='more' />
              </button>
            }
            items={[
              {
                icon: ShareIcon,
                type: 'button',
                text: t('admin:components.features.dashboard.pollTable.buttons.share'),
                onClick: () => {
                  setPollShareModal({
                    isVisible: true,
                    pollName: poll.name,
                    pollId: poll._id,
                  });
                },
              },
              {
                icon: EditIcon,
                type: 'button',
                text: t('admin:components.features.dashboard.pollTable.buttons.edit'),
                disabled: poll.disabledActions.edit,
                onClick: () => {
                  navigate(
                    `/poll/${poll.kind === 'Election' ? 'election' : 'survey'}/edit/${poll._id}`,
                  );
                },
              },
              {
                icon: RemoveGrayIcon,
                text: t('admin:components.features.dashboard.pollTable.buttons.remove'),
                type: 'button',
                color: 'red',
                disabled: poll.disabledActions.remove,
                onClick: () => {
                  setPollActionModal({
                    isVisible: true,
                    kind: poll.kind,
                    type: 'remove',
                    id: poll._id,
                  });
                },
              },
            ]}
          />

          {showSkeleton && <div>{/* TODO: skeleton 구현이 필요합니다. */}</div>}
        </PollCard>
      ))}
    </div>
  );
}

export default PollCardView;
