import SendResetEmailForm from 'components/features/auth/form/SendResetEmail';

import { container } from '../common.css';

function SendResetEmail() {
  return (
    <div className={container}>
      <SendResetEmailForm />
    </div>
  );
}

export default SendResetEmail;
