import { DraftTemplate, DraftTemplateCategory } from 'types/template';

import Thumbnail1 from 'resources/images/templates/election/company1.png';
import Thumbnail2 from 'resources/images/templates/election/company2.png';

const DRAFT_1 = {
  title: '픽미 이사회 임원 선출 찬반투표',
  _id: '645c4c586a757501877248fc',
  content: {
    name: '픽미 이사회 임원 선출 찬반투표',
    periods: [
      {
        startDate: '2023-05-25T16:22',
        endDate: '2023-06-30T16:22',
      },
    ],
    description:
      '<p>안녕하십니까.</p><p><br></p><p>제28회 픽미 이사회 임원 선출 투표입니다.</p><p>여러분들의 소중한 한표 부탁드립니다.</p><p><br></p><p>감사합니다.</p>',
    type: 'Election',
    voterType: 'voterBook',
    subscriptionType: 'enterprise',
    subElections: [
      {
        kind: 'ProsOrConsElection',
        title: '픽미 이사회 임원 선출 투표',
        shuffle: false,
        candidates: [
          {
            title: '후보1. 김성수',
            description:
              '<p>성명 : 김성수</p><p>성별 : 남</p><p>나이 : 53</p><p><br></p><h2>주요 활동사항</h2><ul><li>픽미 대전 운동본부감사</li><li>픽미복지재단 이사</li></ul><p><br></p><h2>출마의 변</h2><p>안녕하세요.</p><p>픽미 이사회 임원 선출 투표에 출마한 김성수입니다.</p><p>여러분들의 소중한 한표 부탁드립니다.</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '김성수',
                fileUrl: 'https://api.ohpick.me/api/file/644792cf1a3d83033dab379b',
              },
            ],
            tags: [],
            id: 'f1804472-d651-4c53-aa25-1d6b56d25860',
          },
          {
            title: '후보2. 한정길',
            description:
              '<p>성명 : 한정길</p><p>성별 : 남</p><p>나이 : 48</p><p><br></p><h2>주요 활동사항</h2><ul><li>픽미아동복지관 사무국장</li><li>서울중구 퀘스사회실무협의체 의원</li></ul><p><br></p><h2>출마의 변</h2><p>안녕하세요.</p><p>픽미 이사회 임원 선출 투표에 출마한 한정길입니다.</p><p>여러분들의 소중한 한표 부탁드립니다.</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '한정길',
                fileUrl: 'https://api.ohpick.me/api/file/644793ae7bc5dd032df5784b',
              },
            ],
            tags: [],
            id: 'a1f6a9b3-a140-4e32-8232-0c187ca73a78',
          },
          {
            title: '후보3. 정규한',
            description:
              '<p>성명 : 정규한</p><p>성별 : 남</p><p>나이 : 58</p><p><br></p><h2>주요 활동사항</h2><ul><li>픽미대학교 경영학과 교수</li><li>퀘스 사회복지분과위원장</li></ul><p><br></p><h2>출마의 변</h2><p>안녕하세요.</p><p>픽미 이사회 임원 선출 투표에 출마한 정규한입니다.</p><p>여러분들의 소중한 한표 부탁드립니다.</p>',
            introductionVideoUrl: '',
            members: [
              {
                name: '정규한',
                fileUrl: 'https://api.ohpick.me/api/file/644795187bc5dd032df57859',
              },
            ],
            tags: [],
            id: '67626560-0745-4230-849a-989f08b2fc61',
          },
        ],
        responseValidation: {
          allowAbstentionVote: false,
        },
        id: '6acb4a87-b466-4edb-b5cd-255e455bf1ea',
      },
    ],
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'election',
  createdAt: '2023-05-11T02:00:56.547Z',
  __v: 0,
};

const DRAFT_2 = {
  _id: '65dd76366af7bf00d12eedd5',
  title: '주식회사 픽미 사외이사 선거',
  content: {
    name: '주식회사 픽미 사외이사 선거',
    description:
      '<p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">주식회사 픽미의 경영투명성을 확보하기 위해 사외이사를 선출하기 위한 투표입니다.</span></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">많은 참여 부탁드립니다.</span></p>',
    subElections: [
      {
        responseValidation: {
          allowAbstentionVote: true,
          minResponse: 1,
          maxResponse: 1,
        },
        kind: 'ChoiceElection',
        _id: '65dd76366af7bf00d12eedd6',
        title: '3명의 후보 중 하나를 선택해주세요.',
        shuffle: true,
        candidates: [
          {
            tags: [],
            _id: '65dd76366af7bf00d12eedd7',
            title: '박정정',
            description: '',
            introductionVideoUrl: '',
            members: [
              {
                _id: '65dd76366af7bf00d12eedd8',
                name: '박정정',
                fileUrl: '',
              },
            ],
          },
          {
            tags: [],
            _id: '65dd76366af7bf00d12eedd9',
            title: '김당당',
            description: '',
            introductionVideoUrl: '',
            members: [
              {
                _id: '65dd76366af7bf00d12eedda',
                name: '김당당',
                fileUrl: '',
              },
            ],
          },
          {
            tags: [],
            _id: '65dd76366af7bf00d12eeddb',
            title: '최정당',
            description: '',
            introductionVideoUrl: '',
            members: [
              {
                _id: '65dd76366af7bf00d12eeddc',
                name: '최정당',
                fileUrl: '',
              },
            ],
          },
        ],
      },
    ],
    periods: [
      {
        startDate: '2024-02-27T07:38:00.000Z',
        endDate: '2025-12-31T05:38:00.000Z',
      },
    ],
    type: 'Election',
    voterType: 'voterBook',
    subscriptionType: 'enterprise',
    voterBook: [],
    incorrectVoters: [],
    isOpenPollRate: false,
    isOpenPollResult: false,
    openThreshold: 0,
  },
  adminId: '643f6dffd4613f0c6fe5a9b6',
  type: 'election',
  createdAt: '2023-06-23T07:30:18.543Z',
  __v: 0,
};

export default [
  {
    title: '이사회 임원 선출 찬반투표',
    description: '이사회 임원 선출 찬반투표를 위한 템플릿입니다.',
    thumbnail: Thumbnail1,
    draft: DRAFT_1,
    category: DraftTemplateCategory.Company,
    kind: 'Election',
  },
  {
    title: '주식회사 픽미 사외이사 선거',
    description: `${DRAFT_2.title}를 위한 템플릿입니다.`,
    thumbnail: Thumbnail2,
    draft: DRAFT_2,
    category: DraftTemplateCategory.Company,
    kind: 'Election',
  },
] as DraftTemplate[];
