import { PlainButton, Text } from '@pickme/ui';
import { t } from 'i18next';

import GuideIcon from 'resources/icons/guide/guide.png';
import UpdateIcon from 'resources/icons/guide/update.png';
import BlogIcon from 'resources/icons/guide/blog.png';
import HomeIcon from 'resources/icons/guide/homepage.png';

import './Resource.scss';

const ITEMS = [
  {
    icon: GuideIcon,
    title: t('admin:pages.resources.cards.0.title'),
    description: t('admin:pages.resources.cards.0.description'),
    link: 'https://ohpick.me/help-center/',
  },
  {
    icon: UpdateIcon,
    title: t('admin:pages.resources.cards.1.title'),
    description: t('admin:pages.resources.cards.1.description'),
    link: 'https://ohpick.me/help-center/faq/',
  },
  {
    icon: BlogIcon,
    title: t('admin:pages.resources.cards.2.title'),
    description: t('admin:pages.resources.cards.2.description'),
    link: 'https://blog.ohpick.me/',
  },
  {
    icon: HomeIcon,
    title: t('admin:pages.resources.cards.3.title'),
    description: t('admin:pages.resources.cards.3.description'),
    link: 'https://ohpick.me',
  },
];

function Resource() {
  return (
    <div className='resource'>
      <Text type='h3' className='page-title'>
        {t('admin:pages.resources.title')}
      </Text>

      <div className='resource__cards'>
        {ITEMS.map(({ icon, title, description, link }, index) => (
          <ResourceCard
            key={`resource-card-${index}`}
            icon={icon}
            title={title}
            description={description}
            link={link}
          />
        ))}
      </div>
    </div>
  );
}

export default Resource;

function ResourceCard({
  icon,
  title,
  description,
  link,
}: {
  icon: string;
  title: string;
  description: string;
  link: string;
}) {
  return (
    <PlainButton className='resource__card' onClick={() => window.open(link, '_blank')}>
      <img className='resource__card__icon' src={icon} alt='icon' />

      <div className='resource__card__title'>
        <Text type='b1' fontWeight={600}>
          {title}
        </Text>
      </div>

      <div className='resource__card__description'>
        <Text type='b2' fontWeight={400} color='gray-400'>
          {description}
        </Text>
      </div>
    </PlainButton>
  );
}
