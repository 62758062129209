export default {
  title: '정말 삭제하시겠어요?',
  message: {
    pending: '채널 심사를 진행하고 있어요. ',
    default: '채널을 삭제하면 알림톡 전송시 발신 프로필로 설정할 수 없어요.',
  },
  buttons: {
    cancel: '취소',
    submit: '삭제',
  },
};
