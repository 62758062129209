import { Editor } from '@pickme/ui';
import { Input, Text } from '@pickme/design-system';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import FieldContainer from 'components/features/application-v2/form/containers/Field';
import CoverField from 'components/features/application-v2/form/fields/Cover';
import ButtonTextField from 'components/features/application-v2/form/Intro/Button';

import {
  ELECTION_TITLE_MAX_LENGTH,
  ELECTION_DESCRIPTION_MAX_LENGTH,
} from 'constants/application-v2';

import { PollKindV2 } from 'types/poll-v2';
import { IntroForm as IntroFormType } from 'types/application-v2';

import { container, editorContainer } from './index.css';

type Props = {
  kind: PollKindV2;
};

const LABELS = {
  title: {
    title: {
      [PollKindV2.IssueElection]: '투표 제목',
      [PollKindV2.CandidateElection]: '선거 제목',
      [PollKindV2.Survey]: '설문 제목',
    },
    placeholder: {
      [PollKindV2.IssueElection]: '투표의 제목을 작성해 주세요 (최대 55자)',
      [PollKindV2.CandidateElection]: '선거의 제목을 작성해 주세요 (최대 55자)',
      [PollKindV2.Survey]: '설문',
    },
  },
  description: {
    title: {
      [PollKindV2.IssueElection]: '투표 설명',
      [PollKindV2.CandidateElection]: '선거 설명',
      [PollKindV2.Survey]: '설문 설명',
    },
    placeholder: {
      [PollKindV2.IssueElection]: '투표의 설명을 작성해 주세요',
      [PollKindV2.CandidateElection]: '선거의 설명을 작성해 주세요',
      [PollKindV2.Survey]: '설문의 설명을 작성해 주세요',
    },
  },
  buttonText: {
    title: {
      [PollKindV2.IssueElection]: '시작 버튼',
      [PollKindV2.CandidateElection]: '시작 버튼',
      [PollKindV2.Survey]: '설문 시작 버튼',
    },
  },
};

function IntroForm({ kind }: Props) {
  const methods = useFormContext<IntroFormType>();
  const descriptionValue = useWatch<IntroFormType, 'description'>({
    control: methods.control,
    name: 'description',
  });

  return (
    <form className={container} onSubmit={(event) => event.preventDefault()}>
      <FieldContainer required title={LABELS.title.title[kind]}>
        <Input
          placeholder={LABELS.title.placeholder[kind]}
          maxLength={ELECTION_TITLE_MAX_LENGTH}
          {...methods.register('title')}
          isValid={!methods.formState.errors.title?.message}
          errorMessage={methods.formState.errors.title?.message || ''}
        />
      </FieldContainer>

      <FieldContainer title='커버 이미지'>
        <CoverField />
      </FieldContainer>

      <FieldContainer title={LABELS.description.title[kind]}>
        <Controller
          name='description'
          control={methods.control}
          render={({ field, fieldState }) => (
            <div className={editorContainer}>
              <Editor mode='initialize' {...field} />

              <Text size={12} fontWeight={400} color='gray-300'>
                {descriptionValue?.replace(/<[^>]*>/g, '')?.length || 0} /{' '}
                {ELECTION_DESCRIPTION_MAX_LENGTH}
              </Text>

              {fieldState.error?.message && (
                <Text size={12} fontWeight={400} color='red-500'>
                  {fieldState.error?.message}
                </Text>
              )}
            </div>
          )}
        />
      </FieldContainer>

      <FieldContainer title={LABELS.buttonText.title[kind]}>
        <ButtonTextField kind={kind} />
      </FieldContainer>
    </form>
  );
}

export default IntroForm;
