export default {
  title: '결제 이메일 수정',
  message: '결제 영수증이 아래 이메일 주소로 전송됩니다',
  error: {
    message: '올바른 이메일을 입력해주세요',
  },
  buttons: {
    submit: '확인',
  },
};
