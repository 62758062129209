export default {
  title: '알림톡',
  callout: [
    '투표 참여 대상자에게 카카오 알림톡 메시지를 보낼 수 있어요.',
    '대상자 명단에 휴대전화번호가 등록되어 있어야 메시지를 전송할 수 있어요.',
    '알림톡 전송을 위해서 픽미 포인트를 충전해주세요.',
    '충전하기',
    '발신 프로필 설정은 [설정>연동] 메뉴에서 카카오 알림톡 연동 기능을 이용해주세요. ',
    '연동하기',
  ],
  form: {
    header: ['전송 대상 선택', '메시지 선택'],
    fields: {
      target: {
        title: '대상 그룹 선택',
        button: '투표 추가하기',
      },
      recipientType: {
        title: '세부 대상 선택',
        radios: ['전체', '투표 미참여자'],
      },
      channel: {
        title: '발신 프로필 선택',
        builtIn: '온라인 투표 서비스 픽미',
        pending: '채널 심사 진행중입니다.',
      },
      templates: {
        title: '템플릿 선택',
        placeholder: '템플릿을 선택해주세요',
        items: [
          '투표 시작 안내',
          '투표 독려',
          '투표 결과 안내',
          '투표 일시정지 안내',
          '투표 재시작 안내',
        ],
      },
      sendingMethod: {
        title: '전송 설정',
        radios: ['즉시 전송', '예약 전송'],
      },
    },
    submitButtonText: '알림톡 전송하기',
  },
  errorMessage: {
    noPolls: '투표를 선택해주세요.',
    date: '현재 시간 이후의 시간을 선택해주세요.',
  },
};
