import { Method, AxiosRequestConfig } from 'axios';

type Api = { method: Method; url: string };
export type Apis = { [api: string]: Api };

export type Request = AxiosRequestConfig & {
  param?: string;
  query?: object;
  url: string;
  withoutOrganizationOid?: boolean;
  skipOrganizationOidCheck?: boolean;
  hostType?: HostType;
  delay?: number;
  apiVersion?: 1 | 2;
};

export enum HostType {
  Unsplash = 'unsplash',
  Pickme = 'pickme',
}
